var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./KRadioButton.css";
var KRadioButton = function (_a) {
    var title = _a.title, color = _a.color, marginTop = _a.marginTop, marginBottom = _a.marginBottom, imgSrc = _a.imgSrc, imgSize = _a.imgSize, name = _a.name, marginRight = _a.marginRight, marginLeft = _a.marginLeft, value = _a.value, fontSize = _a.fontSize, selected = _a.selected, _b = _a.onChange, onChange = _b === void 0 ? null : _b;
    var styleContainer = {
        "--kradiobutton-color": color ? color : "",
        "--kradiobutton-margin-top": marginTop || "0px",
        "--kradiobutton-margin-bottom": marginBottom || "10px",
        "--kradiobutton-margin-left": marginLeft || "0px",
        "--kradiobutton-margin-right": marginRight || "18px",
        "--kradiobutton-font-size": fontSize || "var(--app-font-size-regular)",
        "--kradiobutton-image-size-multiplier": imgSize || "3.5",
    };
    return (_jsxs("label", __assign({ style: styleContainer, className: "kradiobutton-container ".concat(selected == true ? "kradiobutton-selected" : "") }, { children: [_jsx("input", { className: "kradiobutton-input", type: "radio", name: name, value: value, onChange: onChange && onChange }), _jsx("div", __assign({ className: "kradiobutton-outerbox" }, { children: _jsx("div", { className: "kradiobutton-innermark", style: { display: selected == true ? "block" : "none" } }) })), imgSrc ? (_jsx("img", { className: "kradiobutton-image", src: imgSrc })) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "kradiobutton-title" }, { children: title || "KRadioButton" }))] })));
};
export default KRadioButton;
