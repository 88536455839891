var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import "./KToggle.css";
var KToggle = function (_a) {
    var _b = _a.labels, labels = _b === void 0 ? ["Color", "Light", "Dark"] : _b, onChange = _a.onChange, initialLabel = _a.initialLabel;
    var _c = useState(initialLabel ? initialLabel : labels && labels.length ? labels[0] : null), checked = _c[0], setChecked = _c[1];
    var getInputId = function (label) { return "ktoggle-label-".concat(label); };
    return (_jsx(_Fragment, { children: labels && labels.length ? (_jsx("div", __assign({ className: "ktoggle-container" }, { children: labels.map(function (label, key) { return (_jsxs("div", __assign({ className: "ktoggle-entry" }, { children: [_jsx("input", { id: getInputId(label), type: "radio", checked: checked === label, onChange: function () {
                            setChecked(label);
                            onChange(label);
                        } }), _jsx("label", __assign({ htmlFor: getInputId(label), style: {
                            backgroundColor: checked === label
                                ? "var(--app-primary-color)"
                                : "",
                            color: checked === label
                                ? "var(--app-font-color)"
                                : "",
                            fontWeight: checked === label ? "500" : "",
                        } }, { children: _jsx("span", { children: label }) }))] }), key)); }) }))) : (_jsx(_Fragment, {})) }));
};
export default KToggle;
