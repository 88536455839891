var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import "./CardBox.css";
var CardBox = function (_a) {
    var _b, _c;
    var width = _a.width, maxWidth = _a.maxWidth, paymentMethod = _a.paymentMethod, _d = _a.isDefault, isDefault = _d === void 0 ? false : _d, _e = _a.detachPaymentMethod, detachPaymentMethod = _e === void 0 ? function (f) { return f; } : _e, _f = _a.setDefaultPaymentMethod, setDefaultPaymentMethod = _f === void 0 ? function (f) { return f; } : _f;
    var getMonth = function (month) {
        return month < 10 ? "0".concat(month) : month;
    };
    return (_jsxs("div", __assign({ className: "cardbox-container ".concat(isDefault ? "cardbox-default" : ""), style: { width: width, maxWidth: maxWidth } }, { children: [_jsxs("div", __assign({ className: "cardbox-info-section" }, { children: [_jsxs("div", __assign({ className: "cardbox-title" }, { children: [_jsx("span", __assign({ style: { color: "var(--app-primary-colo)" } }, { children: ((_b = paymentMethod.brand) === null || _b === void 0 ? void 0 : _b.slice(0, 1).toUpperCase()) +
                                    ((_c = paymentMethod.brand) === null || _c === void 0 ? void 0 : _c.slice(1, 100).toLowerCase()) })), _jsxs("span", __assign({ style: { color: "var(--app-primary-colo)" } }, { children: [" ", "****", " "] })), _jsx("span", __assign({ style: { color: "var(--app-primary-color)" } }, { children: paymentMethod.last4 }))] })), _jsx("div", __assign({ className: "cardbox-subtitle" }, { children: "Expires ".concat(getMonth(paymentMethod.expMonth), "/").concat(paymentMethod.expYear) }))] })), _jsxs("div", __assign({ className: "cardbox-action-section" }, { children: [_jsx("div", __assign({ className: "cardbox-action-title" }, { children: isDefault ? "Default" : "" })), !isDefault && (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "cardbox-action-mark-button", onClick: function () {
                                    if (confirm("Are you sure you would like to set this card as your default payment method?"))
                                        setDefaultPaymentMethod(paymentMethod.id);
                                } }, { children: "Set as default" })), _jsx("div", __assign({ className: "cardbox-action-button", onClick: function () {
                                    if (confirm("Are you sure you would like to remove this card?"))
                                        detachPaymentMethod(paymentMethod.id);
                                } }, { children: "REMOVE" }))] }))] }))] })));
};
export default CardBox;
