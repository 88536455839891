var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import "../footer/footer.css";
import { useHistory, Link } from "react-router-dom";
function Title(props) {
    return (_jsxs("div", __assign({ className: "footer__footer-section" }, { children: [_jsx(Box, { width: "25px", height: "3px", bgcolor: "var(--app-font-color)", marginTop: 2 }), _jsx("h4", { children: props.title }), props.children] })));
}
export function Footer() {
    var history = useHistory();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "footer__section section" }, { children: [_jsxs("div", __assign({ className: "footer__sections" }, { children: [_jsxs(Title, __assign({ title: "Scriptofilm" }, { children: [_jsx(Link, __assign({ to: "/" }, { children: "Home" })), _jsx(Link, __assign({ to: "/about" }, { children: "About" }))] })), _jsx(Title, __assign({ title: "Terms" }, { children: _jsx(Link, __assign({ to: "/termsofservice" }, { children: "Terms of Service" })) })), _jsx(Title, __assign({ title: "Contacts" }, { children: _jsx(Link, __assign({ to: "/contactus" }, { children: "Email us" })) })), _jsxs(Title, __assign({ title: "Connect" }, { children: [_jsx(IconButton, __assign({ size: "small", style: { margin: "0", padding: "0" }, onClick: function () {
                                        return window.open("https://www.facebook.com/yourglobalmarketofficialpage/");
                                    } }, { children: _jsx(FacebookIcon, { htmlColor: "#4267B2" }) })), _jsx(IconButton, __assign({ size: "small", style: { margin: "0", padding: "0" }, onClick: function () {
                                        return window.open("https://www.instagram.com/scriptofilm/?hl=en");
                                    } }, { children: _jsx(InstagramIcon, { htmlColor: "#F56040" }) }))] }))] })), _jsx(Divider, {}), _jsxs("h6", __assign({ className: "color-grey" }, { children: ["Scriptofilm @", new Date().getFullYear(), ". All rights reserved"] }))] })) }));
}
export default Footer;
