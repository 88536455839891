var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Route } from "react-router-dom";
var KNavRoute = function (_a) {
    var exact = _a.exact, path = _a.path, componentProps = _a.componentProps, Component = _a.component;
    return (_jsx("div", __assign({ className: "knavroute-container" }, { children: _jsx(Route, { exact: exact, path: path, render: function () { return _jsx(Component, __assign({}, componentProps)); } }) })));
};
export default KNavRoute;
