var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import permissions from "../configs/permissions";
import { hasPermission } from "./permissionValidator";
import { getUserCurrentPlan, getUserTitle, } from "../redux/selectors/authSelectors";
import { isUserBuyer, isUserFilmmaker, isUserInvestor, isUserLawyer, isUserProducer, isUserScriptwriter, } from "../tools/userTools";
import store from "../redux/store";
import { getContracts } from "../redux/selectors/contractSelectors";
import { getUserBoostedProjects } from "../redux/selectors/projectSelectors";
import { thunkLoadUserBoostedProjects } from "../redux/thunks/projectThunks";
var isAuthUserProducer = function () { return isUserProducer(getUserTitle(store.getState())); };
var isAuthUserInvestor = function () { return isUserInvestor(getUserTitle(store.getState())); };
var isAuthUserBuyer = function () { return isUserBuyer(getUserTitle(store.getState())); };
var isAuthUserLawyer = function () { return isUserLawyer(getUserTitle(store.getState())); };
export var isAuthUserScriptwriter = function () {
    return isUserScriptwriter(getUserTitle(store.getState()));
};
export var isAuthUserFilmmaker = function () {
    return isUserFilmmaker(getUserTitle(store.getState()));
};
export var canHaveAwards = function () {
    return !isAuthUserLawyer();
};
export var canHaveInterests = function () {
    return !isAuthUserLawyer();
};
export var canHaveLegalities = function () {
    return isAuthUserInvestor();
};
export var canHaveOnlyBasics = function () {
    return isAuthUserLawyer();
};
export var canViewNetwork = function () {
    return (hasPermission(permissions.USER_LIST) ||
        hasPermission(permissions.USER_LIST_BASIC) ||
        hasPermission(permissions.USER_LIST_PREMIUM));
};
export var canViewMarket = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(isAuthUserScriptwriter() || isAuthUserFilmmaker())) return [3 /*break*/, 2];
                return [4 /*yield*/, thunkLoadUserBoostedProjects()];
            case 1:
                _a.sent();
                if (getUserBoostedProjects(store.getState()).length)
                    return [2 /*return*/, true];
                return [2 /*return*/, false];
            case 2: 
            // END NOTE
            return [2 /*return*/, (hasPermission(permissions.PROJECT_LIST) ||
                    hasPermission(permissions.PROJECT_LIST_BASIC) ||
                    hasPermission(permissions.PROJECT_LIST_PREMIUM))];
        }
    });
}); };
export var canViewMessaging = function () {
    return !isAuthUserLawyer();
};
export var canViewMyProjectsPage = function () {
    return (isAuthUserScriptwriter() ||
        isAuthUserFilmmaker() ||
        isAuthUserProducer());
};
export var canViewTransactions = function () {
    return isAuthUserProducer() || isAuthUserInvestor() || isAuthUserBuyer();
};
export var canViewContract = function () {
    if ((hasPermission(permissions.CONTRACT_CREATE) ||
        hasPermission(permissions.CONTRACT_CREATE_OWN)) &&
        !isAuthUserScriptwriter() &&
        !isAuthUserFilmmaker())
        return true;
    var contracts = getContracts(store.getState());
    if (contracts && contracts.length)
        return true;
    return false;
};
export var canViewProfile = function () {
    return true;
};
export var canViewNotifications = function () {
    return true;
};
export var canBoostProjects = function () {
    return (isAuthUserScriptwriter() ||
        isAuthUserFilmmaker() ||
        isAuthUserProducer());
};
export var canCreateProjects = function () {
    return (isAuthUserScriptwriter() ||
        isAuthUserFilmmaker() ||
        isAuthUserProducer());
};
export var canChooseSubscription = function () {
    return !isAuthUserLawyer();
};
export var hasPaymentMethods = function () {
    return !isAuthUserLawyer();
};
export var hasTeamPage = function () {
    return isAuthUserFilmmaker() || isAuthUserProducer();
};
export var isUserPremium = function () {
    return getUserCurrentPlan(store.getState()) === "premium";
};
export var canUserUploadDcp = function () {
    return isAuthUserFilmmaker() || isAuthUserProducer();
};
export var canUserDownloadDcp = function () {
    return isAuthUserBuyer();
};
export var hasAdminAccess = function () {
    return hasPermission(permissions.NOTIFICATION_CREATE);
};
