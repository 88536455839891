import { getFileUrl } from "../apis/fileApi";
Number.prototype.round = function (decimals) {
    return Number(Math.round(this + "e" + decimals) + "e-" + decimals);
};
Array.prototype.rotate = function (n) {
    n = n % this.length;
    while (this.length && n < 0)
        n += this.length;
    this.push.apply(this, this.splice(0, n));
    return this;
};
export var numberToString = function (num, decimals) {
    if (decimals === void 0) { decimals = 1; }
    if (num < Math.pow(10, 3))
        return num;
    if (num < Math.pow(10, 6))
        return (num / Math.pow(10, 3)).round(decimals) + "K";
    if (num < Math.pow(10, 9))
        return (num / Math.pow(10, 6)).round(decimals) + "M";
    if (num < Math.pow(10, 12))
        return (num / Math.pow(10, 9)).round(decimals) + "B";
    if (num < Math.pow(10, 15))
        return (num / Math.pow(10, 12)).round(decimals) + "T";
};
export var getValueInCurrency = function (value, currency) {
    if (currency === void 0) { currency = "$"; }
    if (!value)
        return;
    return currency + numberToString(value, 1);
};
export var getTransparentImagePath = function () {
    return "/images/transparent.png";
};
export var getImageUrl = function (imagePath) {
    if (imagePath)
        return getFileUrl(imagePath);
    return getTransparentImagePath();
};
export var getFileSizeInBytes = function (size, decimals) {
    if (decimals === void 0) { decimals = 2; }
    size = parseInt(size);
    if (size < Math.pow(10, 3))
        return size + "B";
    if (size < Math.pow(10, 6))
        return (size / Math.pow(10, 3)).toFixed(decimals) + "KB";
    if (size < Math.pow(10, 9))
        return (size / Math.pow(10, 6)).toFixed(decimals) + "MB";
    if (size < Math.pow(10, 12))
        return (size / Math.pow(10, 9)).toFixed(decimals) + "GB";
    return (size / Math.pow(10, 15)).toFixed(decimals) + "TB";
};
export var getMonthFromDate = function (date) {
    if (!date)
        return "";
    var dateParts = date.split("-");
    date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));
    var month = parseInt(date.toLocaleString("default", { month: "numeric" }));
    if (month > 9)
        return month;
    return "0" + month;
};
export var getYearFromDate = function (date) {
    if (!date)
        return "";
    var dateParts = date.split("-");
    date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));
    return date.toLocaleString("default", { year: "numeric" });
};
export var getMonthYearDate = function (date) {
    if (!date)
        return "";
    var dateParts = date.split("-");
    date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2].substr(0, 2));
    return date.toLocaleString("default", { month: "long", year: "numeric" });
};
export var getDayMonthYearDate = function (date) {
    if (!date)
        date = new Date();
    var datetime = new Date(date);
    return (datetime.getFullYear() +
        "-" +
        (datetime.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        datetime.getDate().toString().padStart(2, "0"));
};
export var getReadableDate = function (date) {
    if (!date)
        date = new Date();
    var datetime = new Date(date);
    return datetime.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};
export var getDateTimeSecond = function (date) {
    if (!date)
        date = new Date();
    var datetime = new Date(date);
    return (datetime.getFullYear() +
        "-" +
        (datetime.getMonth() + 1) +
        "-" +
        datetime.getDate() +
        " " +
        datetime.getHours().toString().padStart(2, "0") +
        ":" +
        datetime.getMinutes().toString().padStart(2, "0") +
        ":" +
        datetime.getSeconds().toString().padStart(2, "0"));
};
export var getDateTimeMinute = function (date) {
    if (!date)
        date = new Date();
    var datetime = new Date(date);
    return datetime.toLocaleString("sv-SE", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
};
export var getHourMinuteDuration = function (minutes) {
    var hours = Math.floor(minutes / 60);
    if (hours > 0)
        return hours + "h" + (minutes - hours * 60);
    return minutes + " min";
};
export var getMonthsArray = function () {
    var array = [];
    for (var i = 1; i < 13; i++) {
        if (i > 9)
            array.push(i);
        else
            array.push("0" + i);
    }
    return array;
};
export var getYearsArray = function () {
    var array = [];
    var date = new Date();
    for (var i = 2000; i <= date.getFullYear(); i++) {
        array.push(i);
    }
    return array;
};
export var getLocation = function (countryName, cityName) {
    if (countryName && cityName)
        return cityName + ", " + countryName;
    if (countryName)
        return countryName;
    if (cityName)
        return cityName;
};
export var arraysMatch = function (arr1, arr2) {
    if (arr1.length !== arr2.length)
        return false;
    for (var i = 0; arr1.length < i; i++) {
        if (arr1[i] !== arr2[i])
            return false;
    }
};
export function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1)
        return Math.floor(interval) + "y";
    interval = seconds / 2592000;
    if (interval > 1)
        return Math.floor(interval) + "m";
    interval = seconds / 86400;
    if (interval > 1)
        return Math.floor(interval) + "d";
    interval = seconds / 3600;
    if (interval > 1)
        return Math.floor(interval) + "h";
    interval = seconds / 60;
    if (interval > 1)
        return Math.floor(interval) + "min";
    return "< 1min";
}
export var getFormikErrorMessage = function (formik, name) {
    var err = formik === null || formik === void 0 ? void 0 : formik.errors;
    if (!err || !name)
        return "";
    for (var _i = 0, _a = name.split("."); _i < _a.length; _i++) {
        var val = _a[_i];
        if (!err[val])
            return "";
        err = err[val];
    }
    return err;
};
export var formLogoUrl = function (website) {
    website = website.slice(0, 4) === "http" ? website : "https://".concat(website);
    return "https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=".concat(website, "&size=128");
};
export var formFullUrl = function (website) {
    if (!website)
        return website;
    return website.slice(0, 4) === "http" ? website : "https://".concat(website);
};
