var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IoIosAddCircle as IconAdd } from "react-icons/io";
import { IoMdTrash as IconDelete } from "react-icons/io";
import KTextField from "../../base-components/KTextField/KTextField";
import KButton from "../../base-components/KButton/KButton";
import "./EditUserAwardsPage.css";
var EditUserAwardsPage = function (_a) {
    var userData = _a.userData, setUserData = _a.setUserData, scrollParent = _a.scrollParent, onSaveUpdates = _a.onSaveUpdates, _b = _a.nextTab, nextTab = _b === void 0 ? "Interests" : _b, editUserPopupOnceClosed = _a.editUserPopupOnceClosed;
    var width = "410px";
    var history = useHistory();
    var _c = useState([{}]), awardsArray = _c[0], setAwardsArray = _c[1];
    var addAward = function () {
        setUserData(__assign(__assign({}, userData), { awardsArray: __spreadArray(__spreadArray([], awardsArray, true), [{}], false) }));
    };
    var deleteAward = function (key) {
        setUserData(__assign(__assign({}, userData), { awardsArray: awardsArray.filter(function (award, k) { return k != key; }) }));
    };
    var updateAward = function (key, field, value) {
        var projectIndex = awardsArray.findIndex(function (award, k) { return k == key; });
        awardsArray[projectIndex][field] = value;
        setUserData(__assign(__assign({}, userData), { awardsArray: awardsArray }));
    };
    var onSubmit = function () {
        onSaveUpdates(editUserPopupOnceClosed ? "" : nextTab.toLowerCase());
    };
    useEffect(function () {
        if (scrollParent.scrollTo)
            scrollParent.scrollTo(0, 0);
        if (typeof userData.awardsArray == "undefined")
            setUserData(__assign(__assign({}, userData), { awardsArray: [{}] }));
    }, []);
    useEffect(function () {
        if (userData.awardsArray && userData.awardsArray.length > 0)
            setAwardsArray(userData.awardsArray);
        else
            setAwardsArray([{}]);
    }, [userData]);
    return (_jsxs("div", __assign({ className: "edituserawardspage-container" }, { children: [_jsx("div", __assign({ className: "edituserawardspage-title" }, { children: "Your awards" })), _jsx("div", __assign({ className: "edituserawardspage-section-title" }, { children: "Share your success with us (enter one or more awards)" })), _jsx("div", __assign({ className: "edituserawardspage-content" }, { children: _jsxs("div", __assign({ className: "edituserawardspage-info-section" }, { children: [awardsArray.map(function (award, key) { return (_jsx("div", __assign({ className: "edituserawardspage-project" }, { children: _jsxs("div", __assign({ className: "edituserawardspage-row" }, { children: [_jsxs("div", __assign({ className: "edituserawardspage-row-text-section ".concat(awardsArray.length > 1
                                            ? "edituserawardspage-row-delete-enabled"
                                            : "") }, { children: [_jsx(KTextField, { placeholder: "Festival or Award", label: "Festival or Award", width: width, marginVertical: "10px", marginRight: "20px", value: award.user_award_festival || "", onChange: function (event) {
                                                    return updateAward(key, "user_award_festival", event.target.value);
                                                } }), _jsx(KTextField, { placeholder: "Title", label: "Title", width: width, marginVertical: "10px", marginRight: "20px", value: award.user_award_title || "", onChange: function (event) {
                                                    return updateAward(key, "user_award_title", event.target.value);
                                                } }), _jsx(KTextField, { placeholder: "Prize", label: "Prize", width: width, marginVertical: "10px", marginRight: "20px", value: award.user_award_prize || "", onChange: function (event) {
                                                    return updateAward(key, "user_award_prize", event.target.value);
                                                } })] })), _jsx("div", __assign({ className: "edituserawardspage-row-actions-section" }, { children: _jsx(IconDelete, { className: "edituserawardspage-delete-icon", onClick: function () { return deleteAward(key); }, style: {
                                                display: awardsArray.length > 1
                                                    ? "block"
                                                    : "none",
                                            } }) }))] })) }), key)); }), _jsxs("div", __assign({ className: "edituserawardspage-add-icon-container", onClick: addAward }, { children: [_jsx(IconAdd, { className: "edituserawardspage-add-icon" }), _jsx("div", __assign({ className: "edituserawardspage-add-button-text" }, { children: "add another" }))] })), _jsx(KButton, { title: !editUserPopupOnceClosed
                                ? "Next | " + nextTab
                                : "Save", variant: "primary", marginVertical: "10px", onClick: onSubmit })] })) }))] })));
};
export default EditUserAwardsPage;
