var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getLocation } from "../../tools/generalTools";
import { getUserProfilePic, getUserTitleColor } from "../../tools/userTools";
import KImage from "../../base-components/KImage/KImage";
import { getFileUrl } from "../../apis/fileApi";
import "./UserCard.css";
var UserCard = function (_a) {
    var user = _a.user, _b = _a.isEditable, isEditable = _b === void 0 ? false : _b, _c = _a.onCollaborate, onCollaborate = _c === void 0 ? function (f) { return f; } : _c;
    var history = useHistory();
    var _d = useState(""), hoverColor = _d[0], setHoverColor = _d[1];
    var _e = useState(""), hoverTextColor = _e[0], setHoverTextColor = _e[1];
    var _f = useState({}), styleContainer = _f[0], setStyleContainer = _f[1];
    var onViewUser = function () {
        if (user.user_id) {
            history.push("/platform/network/user/".concat(user.user_id));
        }
        else {
            alert("Cannot view this user");
        }
    };
    var getProfilePicSrc = function () {
        if (user.user_image_path)
            return getFileUrl(user.user_image_path);
        return "/images/profile-pic.png";
    };
    useEffect(function () {
        setStyleContainer({
            "--usercard-primary-color": "var(--app-".concat(user.user_title_name.toLowerCase() || "unknown", "-color"),
        });
    }, []);
    return (_jsxs("div", __assign({ className: "usercard-container", style: styleContainer }, { children: [_jsx("div", __assign({ className: "usercard-image-section" }, { children: _jsxs("div", __assign({ className: "usercard-image-wrapper" }, { children: [_jsx("div", __assign({ className: "usercard-image-container", onClick: onViewUser }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(user.user_small_image_path), imgSrc: getUserProfilePic(user.user_image_path), alt: "profile-pic" }) })), _jsx("div", __assign({ className: "usercard-image-caption" }, { children: user.user_title_name || "unknown" }))] })) })), _jsx("div", __assign({ className: "usercard-info-section" }, { children: _jsxs("div", __assign({ className: "usercard-info-wrapper" }, { children: [_jsx("div", __assign({ className: "usercard-username-section" }, { children: _jsxs("div", __assign({ className: "usercard-username", onClick: onViewUser }, { children: [user.user_first_name, " ", user.user_last_name] })) })), _jsxs("div", __assign({ className: "usercard-maininfo-section" }, { children: [_jsx("div", __assign({ className: "usercard-userinfo" }, { children: getLocation(user.country_name, user.city_name) })), _jsx("div", __assign({ className: "usercard-userinfo" }, { children: user.user_languages }))] })), _jsx("div", __assign({ className: "usercard-extrainfo-section" }, { children: _jsx("div", { className: "usercard-username" }) })), !isEditable ? (_jsx("div", __assign({ className: "usercard-extrainfo-section" }, { children: _jsx("div", __assign({ className: "usercard-collaborate-button", onClick: onCollaborate, style: {
                                    backgroundColor: hoverColor,
                                    borderColor: hoverColor,
                                    color: hoverTextColor,
                                }, onMouseEnter: function () {
                                    setHoverColor(getUserTitleColor(user.user_title_name));
                                    setHoverTextColor("#111");
                                }, onMouseLeave: function () {
                                    setHoverColor("");
                                    setHoverTextColor("");
                                } }, { children: "connect" })) }))) : (_jsx(_Fragment, {}))] })) }))] })));
};
export default UserCard;
