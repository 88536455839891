var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONTACTUS_SUCCESS, CONTACTUS_IN_PROGRESS, CONTACTUS_FAILURE, CLEAR_SUCCESS_DATA, } from "../actions/emailActions";
var emailInitialState = {
    isLoading: false,
};
var clearingState = {
    data: {},
    errorMessage: "",
};
export var email = function (state, action) {
    if (state === void 0) { state = emailInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case CONTACTUS_SUCCESS: {
            var data = payload.data;
            return __assign(__assign({}, state), { data: data, isLoading: false, errorMessage: "" });
        }
        case CONTACTUS_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case CONTACTUS_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case CLEAR_SUCCESS_DATA: {
            return __assign(__assign({}, state), clearingState);
        }
        default: {
            return state;
        }
    }
};
