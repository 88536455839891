export var LOAD_PROJECT_IN_PROGRESS = "LOAD_PROJECT_IN_PROGRESS";
export var loadProjectInProgress = function () { return ({
    type: LOAD_PROJECT_IN_PROGRESS,
}); };
export var LOAD_PROJECT_SUCCESS = "LOAD_PROJECT_SUCCESS";
export var loadProjectSuccess = function (project) { return ({
    type: LOAD_PROJECT_SUCCESS,
    payload: { project: project },
}); };
export var LOAD_PROJECT_FAILURE = "LOAD_PROJECT_FAILURE";
export var loadProjectFailure = function () { return ({
    type: LOAD_PROJECT_FAILURE,
}); };
export var LOAD_PROJECTS_IN_PROGRESS = "LOAD_PROJECTS_IN_PROGRESS";
export var loadProjectsInProgress = function () { return ({
    type: LOAD_PROJECTS_IN_PROGRESS,
}); };
export var LOAD_PROJECTS_SUCCESS = "LOAD_PROJECTS_SUCCESS";
export var loadProjectsSuccess = function (projects) { return ({
    type: LOAD_PROJECTS_SUCCESS,
    payload: { projects: projects },
}); };
export var LOAD_PROJECTS_FAILURE = "LOAD_PROJECTS_FAILURE";
export var loadProjectsFailure = function () { return ({
    type: LOAD_PROJECTS_FAILURE,
}); };
export var LOAD_MESSAGING_PROJECTS_IN_PROGRESS = "LOAD_MESSAGING_PROJECTS_IN_PROGRESS";
export var loadMessagingProjectsInProgress = function () { return ({
    type: LOAD_MESSAGING_PROJECTS_IN_PROGRESS,
}); };
export var LOAD_MESSAGING_PROJECTS_SUCCESS = "LOAD_MESSAGING_PROJECTS_SUCCESS";
export var loadMessagingProjectsSuccess = function (projects) { return ({
    type: LOAD_MESSAGING_PROJECTS_SUCCESS,
    payload: { projects: projects },
}); };
export var LOAD_MESSAGING_PROJECTS_FAILURE = "LOAD_MESSAGING_PROJECTS_FAILURE";
export var loadMessagingProjectsFailure = function () { return ({
    type: LOAD_MESSAGING_PROJECTS_FAILURE,
}); };
export var APPEND_MARKET_PROJECTS_SUCCESS = "APPEND_MARKET_PROJECTS_SUCCESS";
export var appendMarketProjectsSuccess = function (projects) { return ({
    type: APPEND_MARKET_PROJECTS_SUCCESS,
    payload: { projects: projects },
}); };
export var APPEND_MARKET_PROJECTS_IN_PROGRESS = "APPEND_MARKET_PROJECTS_IN_PROGRESS";
export var appendMarketProjectsInProgress = function () { return ({
    type: APPEND_MARKET_PROJECTS_IN_PROGRESS,
}); };
export var LOAD_MARKET_PROJECTS_IN_PROGRESS = "LOAD_MARKET_PROJECTS_IN_PROGRESS";
export var loadMarketProjectsInProgress = function () { return ({
    type: LOAD_MARKET_PROJECTS_IN_PROGRESS,
}); };
export var LOAD_MARKET_PROJECTS_SUCCESS = "LOAD_MARKET_PROJECTS_SUCCESS";
export var loadMarketProjectsSuccess = function (projects) { return ({
    type: LOAD_MARKET_PROJECTS_SUCCESS,
    payload: { projects: projects },
}); };
export var LOAD_MARKET_PROJECTS_FAILURE = "LOAD_MARKET_PROJECTS_FAILURE";
export var loadMarketProjectsFailure = function () { return ({
    type: LOAD_MARKET_PROJECTS_FAILURE,
}); };
export var LOAD_BOOSTED_MARKET_PROJECTS_IN_PROGRESS = "LOAD_BOOSTED_MARKET_PROJECTS_IN_PROGRESS";
export var loadBoostedMarketProjectsInProgress = function () { return ({
    type: LOAD_BOOSTED_MARKET_PROJECTS_IN_PROGRESS,
}); };
export var LOAD_BOOSTED_MARKET_PROJECTS_SUCCESS = "LOAD_BOOSTED_MARKET_PROJECTS_SUCCESS";
export var loadBoostedMarketProjectsSuccess = function (projects) { return ({
    type: LOAD_BOOSTED_MARKET_PROJECTS_SUCCESS,
    payload: { projects: projects },
}); };
export var LOAD_USER_BOOSTED_PROJECTS_SUCCESS = "LOAD_USER_BOOSTED_PROJECTS_SUCCESS";
export var loadUserBoostedProjectsSuccess = function (projects) { return ({
    type: LOAD_USER_BOOSTED_PROJECTS_SUCCESS,
    payload: { projects: projects },
}); };
export var LOAD_USER_BOOSTED_PROJECTS_IN_PROGRESS = "LOAD_USER_BOOSTED_PROJECTS_IN_PROGRESS";
export var loadUserBoostedProjectsInProgress = function () { return ({
    type: LOAD_USER_BOOSTED_PROJECTS_IN_PROGRESS,
}); };
export var LOAD_USER_BOOSTED_PROJECTS_FAILURE = "LOAD_USER_BOOSTED_PROJECTS_FAILURE";
export var loadUserBoostedProjectsFailure = function () { return ({
    type: LOAD_USER_BOOSTED_PROJECTS_FAILURE,
}); };
export var LOAD_BOOSTED_MARKET_PROJECTS_FAILURE = "LOAD_BOOSTED_MARKET_PROJECTS_FAILURE";
export var loadBoostedMarketProjectsFailure = function () { return ({
    type: LOAD_BOOSTED_MARKET_PROJECTS_FAILURE,
}); };
export var CREATE_PROJECT_IN_PROGRESS = "CREATE_PROJECT_IN_PROGRESS";
export var createProjectInProgress = function () { return ({
    type: CREATE_PROJECT_IN_PROGRESS,
}); };
export var CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export var createProjectSuccess = function (project) { return ({
    type: CREATE_PROJECT_SUCCESS,
    payload: { project: project },
}); };
export var CREATE_PROJECT_FAILURE = "CREATE_PROJECT_FAILURE";
export var createProjectFailure = function () { return ({
    type: CREATE_PROJECT_FAILURE,
}); };
export var UPDATE_PROJECT_IN_PROGRESS = "UPDATE_PROJECT_IN_PROGRESS";
export var updateProjectInProgress = function () { return ({
    type: UPDATE_PROJECT_IN_PROGRESS,
}); };
export var UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export var updateProjectSuccess = function (project) { return ({
    type: UPDATE_PROJECT_SUCCESS,
    payload: { project: project },
}); };
export var UPDATE_PROJECT_STATUS_SUCCESS = "UPDATE_PROJECT_STATUS_SUCCESS";
export var updateProjectStatusSuccess = function (isClosed) { return ({
    type: UPDATE_PROJECT_STATUS_SUCCESS,
    payload: { isClosed: isClosed },
}); };
export var UPDATE_PROJECT_FAILURE = "UPDATE_PROJECT_FAILURE";
export var updateProjectFailure = function () { return ({
    type: UPDATE_PROJECT_FAILURE,
}); };
export var CLEAR_SCRIPT = "CLEAR_SCRIPT";
export var clearProject = function () { return ({
    type: CLEAR_SCRIPT,
}); };
export var CLEAR_PROJECTS_DATA = "CLEAR_PROJECTS_DATA";
export var clearProjectsData = function () { return ({
    type: CLEAR_PROJECTS_DATA,
}); };
export var UPDATE_PROJECT_IN_PROCESS = "UPDATE_PROJECT_IN_PROCESS";
export var updateProjectInProcess = function (project) { return ({
    type: UPDATE_PROJECT_IN_PROCESS,
    payload: { project: project },
}); };
export var CLEAR_PROJECT_IN_PROCESS = "CLEAR_PROJECT_IN_PROCESS";
export var clearProjectInProcess = function () { return ({
    type: CLEAR_PROJECT_IN_PROCESS,
}); };
export var DELETE_PROJECT_IN_PROGRESS = "DELETE_PROJECT_IN_PROGRESS";
export var deleteProjectInProgress = function () { return ({
    type: DELETE_PROJECT_IN_PROGRESS,
}); };
export var DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export var deleteProjectSuccess = function (data) { return ({
    type: DELETE_PROJECT_SUCCESS,
    payload: { data: data },
}); };
export var DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";
export var deleteProjectFailure = function () { return ({
    type: DELETE_PROJECT_FAILURE,
}); };
export var STOP_PROJECT_LOADING = "STOP_PROJECT_LOADING";
export var stopProjectLoading = function () { return ({
    type: STOP_PROJECT_LOADING,
}); };
export var CREATE_PROJECT_BOOST_FAILURE = "CREATE_PROJECT_BOOST_FAILURE";
export var createProjectBoostFailure = function () { return ({
    type: CREATE_PROJECT_BOOST_FAILURE,
}); };
export var CREATE_PROJECT_BOOST_SUCCESS = "CREATE_PROJECT_BOOST_SUCCESS";
export var createProjectBoostSuccess = function () { return ({
    type: CREATE_PROJECT_BOOST_SUCCESS,
}); };
export var CREATE_PROJECT_BOOST_IN_PROGRESS = "CREATE_PROJECT_BOOST_IN_PROGRESS";
export var createProjectBoostInProgress = function () { return ({
    type: CREATE_PROJECT_BOOST_IN_PROGRESS,
}); };
export var ACCESS_REQUEST_IN_PROGRESS = "ACCESS_REQUEST_IN_PROGRESS";
export var accessRequestInProgress = function () { return ({
    type: ACCESS_REQUEST_IN_PROGRESS,
}); };
export var ACCESS_REQUEST_SUCCESS = "ACCESS_REQUEST_SUCCESS";
export var accessRequestSuccess = function (accessRequest) { return ({
    payload: { accessRequest: accessRequest },
    type: ACCESS_REQUEST_SUCCESS,
}); };
export var ACCESS_REQUEST_FAILURE = "ACCESS_REQUEST_FAILURE";
export var accessRequestFailure = function () { return ({
    type: ACCESS_REQUEST_FAILURE,
}); };
export var ACCESS_REQUESTS_SUCCESS = "ACCESS_REQUESTS_SUCCESS";
export var accessRequestsSuccess = function (accessRequests) { return ({
    payload: { accessRequests: accessRequests },
    type: ACCESS_REQUESTS_SUCCESS,
}); };
export var ACCESS_STATUS_CHANGE_SUCCESS = "ACCESS_STATUS_CHANGE_SUCCESS";
export var accessStatusChangeSuccess = function (accessRequest) { return ({
    payload: { accessRequest: accessRequest },
    type: ACCESS_STATUS_CHANGE_SUCCESS,
}); };
export var LOAD_COLLABORATION_PROJECT_FAILURE = "LOAD_COLLABORATION_PROJECT_FAILURE";
export var loadCollaborationProjectFailure = function () { return ({
    type: LOAD_COLLABORATION_PROJECT_FAILURE,
}); };
export var LOAD_COLLABORATION_PROJECT_IN_PROGRESS = "LOAD_COLLABORATION_PROJECT_IN_PROGRESS";
export var loadCollaborationProjectsInProgress = function () { return ({
    type: LOAD_COLLABORATION_PROJECT_IN_PROGRESS,
}); };
export var LOAD_COLLABORATION_PROJECT_SUCCESS = "LOAD_COLLABORATION_PROJECT_SUCCESS";
export var loadCollaborationProjectsSuccess = function (projects) { return ({
    type: LOAD_COLLABORATION_PROJECT_SUCCESS,
    payload: { projects: projects },
}); };
export var LOAD_COLLABORATION_USERS_FAILURE = "LOAD_COLLABORATION_USERS_FAILURE";
export var loadCollaborationUsersFailure = function () { return ({
    type: LOAD_COLLABORATION_USERS_FAILURE,
}); };
export var LOAD_COLLABORATION_USERS_IN_PROGRESS = "LOAD_COLLABORATION_USERS_IN_PROGRESS";
export var loadCollaborationUsersInProgress = function () { return ({
    type: LOAD_COLLABORATION_USERS_IN_PROGRESS,
}); };
export var LOAD_COLLABORATION_USERS_SUCCESS = "LOAD_COLLABORATION_USERS_SUCCESS";
export var loadCollaborationUsersSuccess = function (collaborators) { return ({
    type: LOAD_COLLABORATION_USERS_SUCCESS,
    payload: { collaborators: collaborators },
}); };
export var LOAD_PROJECT_CHARACTERS_FAILURE = "LOAD_PROJECT_CHARACTERS_FAILURE";
export var loadProjectCharactersFailure = function () { return ({
    type: LOAD_PROJECT_CHARACTERS_FAILURE,
}); };
export var LOAD_PROJECT_CHARACTERS_IN_PROGRESS = "LOAD_PROJECT_CHARACTERS_IN_PROGRESS";
export var loadProjectCharactersInProgress = function () { return ({
    type: LOAD_PROJECT_CHARACTERS_IN_PROGRESS,
}); };
export var LOAD_PROJECT_CHARACTERS_SUCCESS = "LOAD_PROJECT_CHARACTERS_SUCCESS";
export var loadProjectCharactersSuccess = function (characters) { return ({
    type: LOAD_PROJECT_CHARACTERS_SUCCESS,
    payload: { characters: characters },
}); };
export var LOAD_PROJECT_MEMBERS_FAILURE = "LOAD_PROJECT_MEMBERS_FAILURE";
export var loadProjectMembersFailure = function () { return ({
    type: LOAD_PROJECT_MEMBERS_FAILURE,
}); };
export var LOAD_PROJECT_MEMBERS_IN_PROGRESS = "LOAD_PROJECT_MEMBERS_IN_PROGRESS";
export var loadProjectMembersInProgress = function () { return ({
    type: LOAD_PROJECT_MEMBERS_IN_PROGRESS,
}); };
export var LOAD_PROJECT_MEMBERS_SUCCESS = "LOAD_PROJECT_MEMBERS_SUCCESS";
export var loadProjectMembersSuccess = function (members) { return ({
    type: LOAD_PROJECT_MEMBERS_SUCCESS,
    payload: { members: members },
}); };
export var LOAD_PROJECT_SHOOTINGSET_FAILURE = "LOAD_PROJECT_SHOOTINGSET_FAILURE";
export var loadProjectShootingSetFailure = function () { return ({
    type: LOAD_PROJECT_SHOOTINGSET_FAILURE,
}); };
export var LOAD_PROJECT_SHOOTINGSET_IN_PROGRESS = "LOAD_PROJECT_SHOOTINGSET_IN_PROGRESS";
export var loadProjectShootingSetInProgress = function () { return ({
    type: LOAD_PROJECT_SHOOTINGSET_IN_PROGRESS,
}); };
export var LOAD_PROJECT_SHOOTINGSET_SUCCESS = "LOAD_PROJECT_SHOOTINGSET_SUCCESS";
export var loadProjectShootingSetSuccess = function (shootingSets) { return ({
    type: LOAD_PROJECT_SHOOTINGSET_SUCCESS,
    payload: { shootingSets: shootingSets },
}); };
export var LOAD_PROJECT_VIDEOSCENE_FAILURE = "LOAD_PROJECT_VIDEOSCENE_FAILURE";
export var loadProjectVideoSceneFailure = function () { return ({
    type: LOAD_PROJECT_VIDEOSCENE_FAILURE,
}); };
export var LOAD_PROJECT_VIDEOSCENE_IN_PROGRESS = "LOAD_PROJECT_VIDEOSCENE_IN_PROGRESS";
export var loadProjectVideoSceneInProgress = function () { return ({
    type: LOAD_PROJECT_VIDEOSCENE_IN_PROGRESS,
}); };
export var LOAD_PROJECT_VIDEOSCENE_SUCCESS = "LOAD_PROJECT_VIDEOSCENE_SUCCESS";
export var loadProjectVideoSceneSuccess = function (videoScenes) { return ({
    type: LOAD_PROJECT_VIDEOSCENE_SUCCESS,
    payload: { videoScenes: videoScenes },
}); };
export var LOAD_INTERESTED_PROJECTS_IN_PROGRESS = "LOAD_INTERESTED_PROJECTS_IN_PROGRESS";
export var loadInterestedProjectsInProgress = function () { return ({
    type: LOAD_INTERESTED_PROJECTS_IN_PROGRESS,
}); };
export var LOAD_INTERESTED_PROJECTS_SUCCESS = "LOAD_INTERESTED_PROJECTS_SUCCESS";
export var loadInterestedProjectsSuccess = function (projects) { return ({
    type: LOAD_INTERESTED_PROJECTS_SUCCESS,
    payload: { projects: projects },
}); };
export var LOAD_INTERESTED_PROJECTS_FAILURE = "LOAD_INTERESTED_PROJECTS_FAILURE";
export var loadInterestedProjectsFailure = function () { return ({
    type: LOAD_INTERESTED_PROJECTS_FAILURE,
}); };
export var PROJECTS_LOADING = "PROJECTS_LOADING";
export var projectsLoading = function () { return ({
    type: PROJECTS_LOADING,
    payload: {},
}); };
export var PROJECTS_LOADING_COMPLETE = "PROJECTS_LOADING_COMPLETE";
export var projectsLoadingComplete = function () { return ({
    type: PROJECTS_LOADING_COMPLETE,
}); };
