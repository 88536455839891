var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ImHappy2 as IconHappy } from "react-icons/im";
import { thunkLoadShameUsers } from "../../redux/thunks/userThunks";
import { getShameUsers } from "../../redux/selectors/userSelectors";
import WallUserCard from "../../components/WallUserCard/WallUserCard";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import ExtraInfo from "../ExtraInfo/ExtraInfo";
import "./WallOfShamePage.css";
var WallOfShamePage = function (_a) {
    var users = _a.users, onLoadShameUsers = _a.onLoadShameUsers;
    var getCount = function () {
        return users.length;
    };
    useDocumentTitle("Scriptofilm | Wall of Shame");
    useEffect(function () {
        onLoadShameUsers();
    }, []);
    return (_jsx("div", __assign({ className: "wallofshamepage-container" }, { children: _jsxs("div", __assign({ className: "wallofshamepage-sections" }, { children: [_jsx("div", __assign({ className: "wallofshamepage-main-section" }, { children: _jsxs("div", __assign({ className: "wallofshamepage-users-container" }, { children: [_jsx("div", { className: "wallofshamepage-background", style: {
                                    backgroundImage: "var(--app-wall-background)",
                                } }), _jsx("div", __assign({ className: "wallofshamepage-wall-title" }, { children: "Wall of Shame" })), (users || []).map(function (user, key) { return (_jsx("div", __assign({ className: "wallofshamepage-usercard" }, { children: _jsx(WallUserCard, { user: user }) }), key)); }), _jsxs("div", __assign({ className: "wallofshamepage-empty-title", style: {
                                    display: getCount() == 0 ? "flex" : " none",
                                    flexDirection: "column",
                                } }, { children: [_jsx("p", { children: "Any attempt to close a deal outside the platform would result in a penalty fee." }), _jsx("p", { children: "All our users are loyal so far! Let's keep it this way." }), _jsx("div", __assign({ className: "wallofshamepage-happy-icon" }, { children: _jsx(IconHappy, {}) }))] }))] })) })), _jsx("div", __assign({ className: "wallofshamepage-secondary-section" }, { children: _jsx("div", __assign({ className: "wallofshamepage-secondary-section-first-part" }, { children: _jsxs("div", __assign({ className: "wallofshamepage-filtering-card" }, { children: [_jsx("div", __assign({ className: "wallofshamepage-personalized" }, { children: _jsx("p", __assign({ className: "wallofshamepage-personalized-text" }, { children: "We work hard to help film industry professionals connect, find great projects, and close deals. Yet, some of us like to cut corners and do things their own way! In here, we are listing all those who used Scriptofilm to find projects or connect with people, but are closing deals externally, leaving us behind!" })) })), _jsxs("div", __assign({ className: "wallofshamepage-extrainfo" }, { children: [_jsx(ExtraInfo, {}), " "] }))] })) })) }))] })) })));
};
var mapStateToProps = function (state) { return ({
    users: getShameUsers(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadShameUsers: function () { return dispatch(thunkLoadShameUsers()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(WallOfShamePage);
