export var LOAD_USERS_IN_PROGRESS = "LOAD_USERS_IN_PROGRESS";
export var loadUsersInProgress = function () { return ({
    type: LOAD_USERS_IN_PROGRESS,
}); };
export var LOAD_USERS_SUCCESS = "LOAD_USERS_SUCCESS";
export var loadUsersSuccess = function (users) { return ({
    type: LOAD_USERS_SUCCESS,
    payload: { users: users },
}); };
export var LOAD_USERS_FAILURE = "LOAD_USERS_FAILURE";
export var loadUsersFailure = function (message) { return ({
    type: LOAD_USERS_FAILURE,
    payload: { message: message },
}); };
export var APPEND_USERS_IN_PROGRESS = "APPEND_USERS_IN_PROGRESS";
export var appendUsersInProgress = function () { return ({
    type: APPEND_USERS_IN_PROGRESS,
}); };
export var APPEND_USERS_SUCCESS = "APPEND_USERS_SUCCESS";
export var appendUsersSuccess = function (users) { return ({
    type: APPEND_USERS_SUCCESS,
    payload: { users: users },
}); };
export var LOAD_MESSAGING_USERS_IN_PROGRESS = "LOAD_MESSAGING_USERS_IN_PROGRESS";
export var loadMessagingUsersInProgress = function () { return ({
    type: LOAD_MESSAGING_USERS_IN_PROGRESS,
}); };
export var LOAD_MESSAGING_USERS_SUCCESS = "LOAD_MESSAGING_USERS_SUCCESS";
export var loadMessagingUsersSuccess = function (users) { return ({
    type: LOAD_MESSAGING_USERS_SUCCESS,
    payload: { users: users },
}); };
export var LOAD_MESSAGING_USERS_FAILURE = "LOAD_MESSAGING_USERS_FAILURE";
export var loadMessagingUsersFailure = function () { return ({
    type: LOAD_MESSAGING_USERS_FAILURE,
}); };
export var LOAD_USER_IN_PROGRESS = "LOAD_USER_IN_PROGRESS";
export var loadUserInProgress = function () { return ({
    type: LOAD_USER_IN_PROGRESS,
}); };
export var LOAD_USER_FAILURE = "LOAD_USER_FAILURE";
export var loadUserFailure = function (message) { return ({
    type: LOAD_USER_FAILURE,
    payload: { message: message },
}); };
export var LOAD_USER_BY_USER_ID = "LOAD_USER_BY_USER_ID";
export var loadUserByUserIdSuccess = function (user) { return ({
    type: LOAD_USER_BY_USER_ID,
    payload: { user: user },
}); };
export var LOAD_PROFILE_VIEWS_IN_PROGRESS = "LOAD_PROFILE_VIEWS_IN_PROGRESS";
export var loadProfileViewsInProgress = function () { return ({
    type: LOAD_PROFILE_VIEWS_IN_PROGRESS,
}); };
export var LOAD_PROFILE_VIEWS_FAILURE = "LOAD_PROFILE_VIEWS_FAILURE";
export var loadProfileViewsFailure = function (message) { return ({
    type: LOAD_PROFILE_VIEWS_FAILURE,
    payload: { message: message },
}); };
export var LOAD_PROFILE_VIEWS_BY_USER_ID = "LOAD_PROFILE_VIEWS_BY_USER_ID";
export var loadProfileViewsUserIdSuccess = function (views) { return ({
    type: LOAD_PROFILE_VIEWS_BY_USER_ID,
    payload: { views: views },
}); };
export var LOAD_USER_BY_PROJECT_ID = "LOAD_USER_BY_PROJECT_ID";
export var loadUserByProjectIdSuccess = function (user) { return ({
    type: LOAD_USER_BY_PROJECT_ID,
    payload: { user: user },
}); };
export var CLEAR_USERS_DATA = "CLEAR_USERS_DATA";
export var clearUsersData = function () { return ({
    type: CLEAR_USERS_DATA,
}); };
export var UPDATE_USER_IN_PROGRESS = "UPDATE_USER_IN_PROGRESS";
export var updateUserInProgress = function () { return ({
    type: UPDATE_USER_IN_PROGRESS,
}); };
export var UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export var updateUserSuccess = function (user) { return ({
    type: UPDATE_USER_SUCCESS,
    payload: { user: user },
}); };
export var UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export var updateUserFailure = function (message) { return ({
    type: UPDATE_USER_FAILURE,
    payload: { message: message },
}); };
export var LOAD_INTERESTED_USERS_IN_PROGRESS = "LOAD_INTERESTED_USERS_IN_PROGRESS";
export var loadInterestedUsersInProgress = function () { return ({
    type: LOAD_INTERESTED_USERS_IN_PROGRESS,
}); };
export var LOAD_INTERESTED_USERS_SUCCESS = "LOAD_INTERESTED_USERS_SUCCESS";
export var loadInterestedUsersSuccess = function (users) { return ({
    type: LOAD_INTERESTED_USERS_SUCCESS,
    payload: { users: users },
}); };
export var LOAD_INTERESTED_COWRITERS_SUCCESS = "LOAD_INTERESTED_COWRITERS_SUCCESS";
export var loadInterestedCowritersSuccess = function (cowriters) { return ({
    type: LOAD_INTERESTED_COWRITERS_SUCCESS,
    payload: { cowriters: cowriters },
}); };
export var SUBMIT_REPORT_IN_PROCESS = "SUBMIT_REPORT_IN_PROCESS";
export var submitReportInProgress = function () { return ({
    type: SUBMIT_REPORT_IN_PROCESS,
}); };
export var SUBMIT_REPORT_SUCCESS = "SUBMIT_REPORT_SUCCESS";
export var submitReportSuccess = function (message) { return ({
    type: SUBMIT_REPORT_SUCCESS,
    payload: { message: message },
}); };
export var SUBMIT_REPORT_FAILURE = "SUBMIT_REPORT_FAILURE";
export var submitReportFailure = function (message) { return ({
    type: SUBMIT_REPORT_FAILURE,
    payload: { message: message },
}); };
export var LOAD_IS_REPORTED_IN_PROCESS = "LOAD_IS_REPORTED_IN_PROCESS";
export var loadIsReportedInProgress = function () { return ({
    type: LOAD_IS_REPORTED_IN_PROCESS,
}); };
export var LOAD_IS_REPORTED_SUCCESS = "LOAD_IS_REPORTED_SUCCESS";
export var loadIsReportedSuccess = function (isReported) { return ({
    type: LOAD_IS_REPORTED_SUCCESS,
    payload: { isReported: isReported },
}); };
export var LOAD_IS_REPORTED_FAILURE = "LOAD_IS_REPORTED_FAILURE";
export var loadIsReportedFailure = function (message) { return ({
    type: LOAD_IS_REPORTED_FAILURE,
    payload: { message: message },
}); };
export var LOAD_INTERESTED_USERS_FAILURE = "LOAD_INTERESTED_USERS_FAILURE";
export var loadInterestedUsersFailure = function (message) { return ({
    type: LOAD_INTERESTED_USERS_FAILURE,
    payload: { message: message },
}); };
export var LOAD_USER_SELECTED_SEARCH_OPTIONS = "LOAD_USER_SELECTED_SEARCH_OPTIONS";
export var loadUserSelectedSearchOptions = function (searchOptions) { return ({
    type: LOAD_USER_SELECTED_SEARCH_OPTIONS,
    payload: { searchOptions: searchOptions },
}); };
export var LOAD_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS = "LOAD_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS";
export var loadLookingForUserSelectedSearchOptions = function (searchOptions) { return ({
    type: LOAD_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS,
    payload: { searchOptions: searchOptions },
}); };
export var LOAD_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS = "LOAD_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS";
export var loadMarketLookingForUserSelectedSearchOptions = function (searchOptions) { return ({
    type: LOAD_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS,
    payload: { searchOptions: searchOptions },
}); };
export var CLEAR_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS = "CLEAR_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS";
export var clearLookingForUserSelectedSearchOptions = function () { return ({
    type: CLEAR_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS,
}); };
export var CLEAR_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS = "CLEAR_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS";
export var clearMarketLookingForUserSelectedSearchOptions = function () { return ({
    type: CLEAR_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS,
}); };
export var LOAD_MARKET_SELECTED_SEARCH_OPTIONS = "LOAD_MARKET_SELECTED_SEARCH_OPTIONS";
export var loadMarketSelectedSearchOptions = function (searchOptions) {
    return {
        type: LOAD_MARKET_SELECTED_SEARCH_OPTIONS,
        payload: { searchOptions: searchOptions },
    };
};
export var LOAD_USER_TITLE_SUCCESS = "LOAD_USER_TITLE_SUCCESS";
export var loadUserTitleSuccess = function (data) { return ({
    type: LOAD_USER_TITLE_SUCCESS,
    payload: { data: data },
}); };
export var STOP_USER_LOADING = "STOP_USER_LOADING";
export var stopUserLoading = function () { return ({
    type: STOP_USER_LOADING,
}); };
export var ADD_RATING_PROGRESS = "ADD_RATING_PROGRESS";
export var addRatingProgress = function () { return ({
    type: ADD_RATING_PROGRESS,
}); };
export var ADD_RATING_SUCCESS = "ADD_RATING_SUCCESS";
export var addRatingSuccess = function (rating) { return ({
    type: ADD_RATING_SUCCESS,
    payload: { rating: rating },
}); };
export var ADD_RATING_FAILURE = "ADD_RATING_FAILURE";
export var addRatingFailure = function (message) { return ({
    type: ADD_RATING_FAILURE,
    payload: { message: message },
}); };
export var LOAD_USER_RATING_IN_PROGRESS = "LOAD_USER_RATING_IN_PROGRESS";
export var loadUserRatingByUserInProgress = function () { return ({
    type: LOAD_USER_RATING_IN_PROGRESS,
}); };
export var LOAD_USER_RATING_FAILURE = "LOAD_USER_RATING_FAILURE";
export var loadUserRatingByUserFailure = function (message) { return ({
    type: LOAD_USER_RATING_FAILURE,
    payload: { message: message },
}); };
export var LOAD_USER_RATING_BY_USER_ID = "LOAD_USER_RATING_BY_USER_ID";
export var loadUserRatingByUserSuccess = function (ratingByUserId) { return ({
    type: LOAD_USER_RATING_BY_USER_ID,
    payload: { ratingByUserId: ratingByUserId },
}); };
export var LOAD_USER_RATING_AVERAGE_IN_PROGRESS = "LOAD_USER_RATING_AVERAGE_IN_PROGRESS";
export var loadUserRatingAverageInProgress = function () { return ({
    type: LOAD_USER_RATING_AVERAGE_IN_PROGRESS,
}); };
export var LOAD_USER_RATING_AVERAGE_FAILURE = "LOAD_USER_RATING_AVERAGE_FAILURE";
export var loadUserRatingAverageFailure = function (message) { return ({
    type: LOAD_USER_RATING_AVERAGE_FAILURE,
    payload: { message: message },
}); };
export var LOAD_USER_RATING_AVERAGE_BY_USER_ID = "LOAD_USER_RATING_AVERAGE_BY_USER_ID";
export var loadUserRatingAverageSuccess = function (averageRating) { return ({
    type: LOAD_USER_RATING_AVERAGE_BY_USER_ID,
    payload: { averageRating: averageRating },
}); };
export var LOAD_PROJECT_VIEW_IN_PROGRESS = "LOAD_PROJECT_VIEW_IN_PROGRESS";
export var loadProjectViewsInProgress = function () { return ({
    type: LOAD_PROJECT_VIEW_IN_PROGRESS,
}); };
export var LOAD_PROJECT_VIEW_FAILURE = "LOAD_PROJECT_VIEW_FAILURE";
export var loadProjectViewsFailure = function (message) { return ({
    type: LOAD_PROJECT_VIEW_FAILURE,
    payload: { message: message },
}); };
export var LOAD_PROJECT_VIEW_BY_PROJECT_ID = "LOAD_PROJECT_VIEW_BY_PROJECT_ID";
export var loadProjectViewsByProjectIdSuccess = function (project_views) { return ({
    type: LOAD_PROJECT_VIEW_BY_PROJECT_ID,
    payload: { project_views: project_views },
}); };
export var LOAD_CONTRACT_USERS_SUCCESS = "LOAD_CONTRACT_USERS_SUCCESS";
export var loadContractUsersSuccess = function (users) { return ({
    type: LOAD_CONTRACT_USERS_SUCCESS,
    payload: { users: users },
}); };
export var LOAD_LAWYERS_SUCCESS = "LOAD_LAWYERS_SUCCESS";
export var loadLawyersSuccess = function (lawyers) { return ({
    type: LOAD_LAWYERS_SUCCESS,
    payload: { lawyers: lawyers },
}); };
export var APPEND_LAWYERS_SUCCESS = "APPEND_LAWYERS_SUCCESS";
export var appendLawyersSuccess = function (lawyers) { return ({
    type: APPEND_LAWYERS_SUCCESS,
    payload: { lawyers: lawyers },
}); };
export var LOAD_PROJECT_BOOSTS_FAILURE = "LOAD_PROJECT_BOOSTS_FAILURE";
export var loadProjectBoostsFailure = function () { return ({
    type: LOAD_PROJECT_BOOSTS_FAILURE,
}); };
export var LOAD_PROJECT_BOOSTS_SUCCESS = "LOAD_PROJECT_BOOSTS_SUCCESS";
export var loadProjectBoostSuccess = function (boosts) { return ({
    type: LOAD_PROJECT_BOOSTS_SUCCESS,
    payload: { boosts: boosts },
}); };
export var LOAD_PROJECT_BOOSTS_IN_PROGRESS = "LOAD_PROJECT_BOOSTS_IN_PROGRESS";
export var loadProjectBoostInProgress = function () { return ({
    type: LOAD_PROJECT_BOOSTS_IN_PROGRESS,
}); };
export var UPDATE_COVER_IMAGES_FAILURE = "LOAD_USERS_UPDATE_COVER_IMAGES_FAILUREFAILURE";
export var updateCoverImagesFailure = function (message) { return ({
    type: UPDATE_COVER_IMAGES_FAILURE,
    payload: { message: message },
}); };
export var UPDATE_COVER_IMAGES_SUCCESS = "UPDATE_COVER_IMAGES_SUCCESS";
export var updateCoverImagesSuccess = function () { return ({
    type: UPDATE_COVER_IMAGES_SUCCESS,
}); };
export var UPDATE_COVER_IMAGES_IN_PROGRESS = "UPDATE_COVER_IMAGES_IN_PROGRESS";
export var updateCoverImagesInProgress = function () { return ({
    type: UPDATE_COVER_IMAGES_IN_PROGRESS,
}); };
export var SET_NETWORK_PATH = "SET_NETWORK_PATH";
export var setNetworkPath = function (path) { return ({
    type: SET_NETWORK_PATH,
    payload: { path: path },
}); };
export var SET_MARKET_PATH = "SET_MARKET_PATH";
export var setMarketPath = function (path) { return ({
    type: SET_MARKET_PATH,
    payload: { path: path },
}); };
export var LOAD_SHAME_USERS_IN_PROGRESS = "LOAD_SHAME_USERS_IN_PROGRESS";
export var loadShameUsersInProgress = function () { return ({
    type: LOAD_SHAME_USERS_IN_PROGRESS,
}); };
export var LOAD_SHAME_USERS_SUCCESS = "LOAD_SHAME_USERS_SUCCESS";
export var loadShameUsersSuccess = function (users) { return ({
    type: LOAD_SHAME_USERS_SUCCESS,
    payload: { users: users },
}); };
export var LOAD_SHAME_USERS_FAILURE = "LOAD_SHAME_USERS_FAILURE";
export var loadShameUsersFailure = function (message) { return ({
    type: LOAD_SHAME_USERS_FAILURE,
    payload: { message: message },
}); };
export var LOAD_DCP_SIGNED_URL_SUCCESS = "LOAD_DCP_SIGNED_URL_SUCCESS";
export var loadDcpSignedUrlSuccess = function (signedUrl) { return ({
    type: LOAD_DCP_SIGNED_URL_SUCCESS,
    payload: { signedUrl: signedUrl },
}); };
export var LOAD_DCP_SIGNED_URL_IN_PROGRESS = "LOAD_DCP_SIGNED_URL_IN_PROGRESS";
export var loadDcpSignedUrlInProgress = function () { return ({
    type: LOAD_DCP_SIGNED_URL_IN_PROGRESS,
}); };
export var LOAD_DCP_SIGNED_URL_FAILURE = "LOAD_DCP_SIGNED_URL_FAILURE";
export var loadDcpSignedUrlFailure = function () { return ({
    type: LOAD_DCP_SIGNED_URL_FAILURE,
}); };
export var LOAD_UPLOADED_DCP_SUCCESS = "LOAD_UPLOADED_DCP_SUCCESS";
export var loadUploadedDcpSuccess = function (uploadedDcp) { return ({
    type: LOAD_UPLOADED_DCP_SUCCESS,
    payload: { uploadedDcp: uploadedDcp },
}); };
export var LOAD_UPLOADED_DCP_IN_PROGRESS = "LOAD_UPLOADED_DCP_IN_PROGRESS";
export var loadUploadedDcpInProgress = function () { return ({
    type: LOAD_UPLOADED_DCP_IN_PROGRESS,
}); };
export var LOAD_UPLOADED_DCP_FAILURE = "LOAD_UPLOADED_DCP_FAILURE";
export var loadUploadedDcpFailure = function () { return ({
    type: LOAD_UPLOADED_DCP_FAILURE,
}); };
