var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import KImage from "../../base-components/KImage/KImage";
import "./LawyerCard.css";
var LawyerCard = function (_a) {
    var fullName = _a.fullName, imagePath = _a.imagePath, smallImagePath = _a.smallImagePath, firstLine = _a.firstLine, secondLine = _a.secondLine, borderColor = _a.borderColor, _b = _a.thirdLine, thirdLine = _b === void 0 ? "" : _b;
    var _c = useState({}), styleContainer = _c[0], setStyleContainer = _c[1];
    useEffect(function () {
        setStyleContainer({
            "--lawyercard-primary-color": "var(--app-primary-color",
        });
    }, []);
    return (_jsxs("div", __assign({ className: "lawyercard-container", style: styleContainer }, { children: [_jsx("div", __assign({ className: "lawyercard-image-section" }, { children: _jsx("div", __assign({ className: "lawyercard-image-wrapper", style: { borderColor: borderColor } }, { children: _jsx("div", __assign({ className: "lawyercard-image-container" }, { children: _jsx(KImage, { smallImgSrc: smallImagePath, imgSrc: imagePath, alt: "profile-pic", rounded: true }) })) })) })), _jsx("div", __assign({ className: "lawyercard-info-section" }, { children: _jsxs("div", __assign({ className: "lawyercard-info-wrapper" }, { children: [_jsx("div", __assign({ className: "lawyercard-username-section" }, { children: _jsx("div", __assign({ className: "lawyercard-username" }, { children: fullName })) })), _jsxs("div", __assign({ className: "lawyercard-maininfo-section" }, { children: [_jsx("div", __assign({ className: "lawyercard-userinfo" }, { children: firstLine })), _jsx("div", __assign({ className: "lawyercard-userinfo" }, { children: secondLine })), _jsx("div", __assign({ className: "lawyercard-userinfo" }, { children: thirdLine }))] }))] })) }))] })));
};
export default LawyerCard;
