import { useEffect, useRef } from "react";
export var infiniteKeys = {
    MARKET: "market",
    NETWORK: "network",
    CONTRACT_PROJECT: "contract-project",
    CONTRACT_USER: "contract-user",
    NOTIFICATION: "notification",
};
var LoadingInfo = (function () {
    var shouldReload = {};
    var lastOffset = {};
    var scrollY = {};
    var getLastOffset = function (key) {
        return lastOffset[key] || 0;
    };
    var getScrollY = function (key) {
        return scrollY[key] || 0;
    };
    var getShouldReload = function (key) {
        if (key in shouldReload)
            return shouldReload[key];
        return true;
    };
    var setLastOffset = function (key, offset) {
        lastOffset[key] = offset;
    };
    var setScrollY = function (key, pos) {
        scrollY[key] = pos;
    };
    var setShouldReload = function (key, reload) {
        shouldReload[key] = reload;
    };
    return {
        getLastOffset: getLastOffset,
        getShouldReload: getShouldReload,
        getScrollY: getScrollY,
        setLastOffset: setLastOffset,
        setShouldReload: setShouldReload,
        setScrollY: setScrollY,
    };
})();
var useInfiniteLoading = function (key, scrollRef, dataCount, onLoadMore, batchSize, onFirstLoad, scrollParentRef, enableScrollToTop) {
    if (batchSize === void 0) { batchSize = 20; }
    if (onFirstLoad === void 0) { onFirstLoad = function (f) { return f; }; }
    if (scrollParentRef === void 0) { scrollParentRef = null; }
    if (enableScrollToTop === void 0) { enableScrollToTop = true; }
    var countRef = useRef(dataCount);
    var getParentScrollTop = function () {
        return scrollParentRef !== null
            ? scrollParentRef.current.scrollTop
            : window.scrollY;
    };
    var getParentHeight = function () {
        return scrollParentRef !== null
            ? scrollParentRef.current.clientHeight
            : window.innerHeight;
    };
    var loadMoreHandler = function () {
        var scrollParentTop = getParentScrollTop();
        if (scrollParentTop != 0)
            LoadingInfo.setScrollY(key, scrollParentTop);
        if (scrollParentTop >
            0.5 * (scrollRef.current.clientHeight - getParentHeight()) &&
            scrollParentTop > LoadingInfo.getLastOffset(key)) {
            LoadingInfo.setLastOffset(key, scrollRef.current.clientHeight);
            console.log("Loading", key, "batch from", countRef.current);
            onLoadMore(batchSize, countRef.current);
        }
    };
    useEffect(function () {
        if (dataCount < countRef.current && enableScrollToTop) {
            window.scrollTo(0, 0);
            LoadingInfo.setScrollY(key, 0);
            LoadingInfo.setLastOffset(key, 0);
        }
        countRef.current = dataCount;
    }, [dataCount]);
    useEffect(function () {
        if (LoadingInfo.getShouldReload(key)) {
            LoadingInfo.setScrollY(key, 0);
            LoadingInfo.setLastOffset(key, 0);
            LoadingInfo.setShouldReload(key, false);
            if (onFirstLoad)
                onFirstLoad();
        }
    }, [LoadingInfo.getShouldReload(key)]);
    useEffect(function () {
        setTimeout(function () {
            if (scrollParentRef !== null) {
                scrollParentRef.current.addEventListener("scroll", loadMoreHandler);
                scrollParentRef.current.scroll(0, LoadingInfo.getScrollY(key));
            }
            else {
                window.addEventListener("scroll", loadMoreHandler);
                window.scroll(0, LoadingInfo.getScrollY(key));
            }
        }, 0);
        return function () {
            if (scrollParentRef !== null) {
                scrollParentRef.current.removeEventListener("scroll", loadMoreHandler);
            }
            else {
                window.removeEventListener("scroll", loadMoreHandler);
            }
        };
    }, []);
};
export var infiniteLoadingReload = function (key) {
    LoadingInfo.setShouldReload(key, true);
};
export var infiniteLoadingReloadAll = function () {
    for (var _i = 0, _a = Object.values(infiniteKeys); _i < _a.length; _i++) {
        var key = _a[_i];
        infiniteLoadingReload(key);
    }
};
export default useInfiniteLoading;
