var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import fileApi from "../apis/fileApi";
import { dataURItoBlob, resizeAsync } from "../imaging/imageTransform";
var getFileBlobUsingURL = function (url, convertBlob) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
        convertBlob(xhr.response);
    });
    xhr.send();
};
var blobToFile = function (blob, name) {
    blob.lastModifiedDate = new Date();
    blob.name = name;
    return blob;
};
export var getFileObjectFromURL = function (filePathOrUrl, convertBlob) {
    getFileBlobUsingURL(filePathOrUrl, function (blob) {
        convertBlob(blobToFile(blob, "scripto-file"));
    });
};
export var uploadFiles = function (infoArray, user, type, isImage) {
    if (isImage === void 0) { isImage = false; }
    return __awaiter(void 0, void 0, void 0, function () {
        var filesArray, filesToAdd, formData, largeSize, smallSize, i, largeDataUrl, smallDataUrl, response, filepaths, i, i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    filesArray = [];
                    filesToAdd = [];
                    if (!(infoArray.length > 0)) return [3 /*break*/, 9];
                    formData = new FormData();
                    formData.append("user", user);
                    formData.append("type", type);
                    largeSize = parseInt(process.env.LARGE_IMAGE_SIZE);
                    smallSize = parseInt(process.env.SMALL_IMAGE_SIZE);
                    i = 0;
                    _a.label = 1;
                case 1:
                    if (!(i < infoArray.length)) return [3 /*break*/, 6];
                    if (!infoArray[i].file) {
                        filesToAdd.push(infoArray[i]);
                        return [3 /*break*/, 5];
                    }
                    filesArray.push({
                        key: infoArray[i].key,
                        title: infoArray[i].title,
                        name: infoArray[i].name,
                        role: infoArray[i].role,
                        category: infoArray[i].category,
                        type: infoArray[i].type,
                        brief: infoArray[i].brief,
                        size: infoArray[i].size,
                    });
                    if (!isImage) return [3 /*break*/, 4];
                    return [4 /*yield*/, resizeAsync(infoArray[i].file, largeSize, largeSize)];
                case 2:
                    largeDataUrl = _a.sent();
                    return [4 /*yield*/, resizeAsync(infoArray[i].file, smallSize, smallSize)];
                case 3:
                    smallDataUrl = _a.sent();
                    formData.append("file", dataURItoBlob(largeDataUrl));
                    formData.append("file", dataURItoBlob(smallDataUrl));
                    return [3 /*break*/, 5];
                case 4:
                    formData.append("file", infoArray[i].file);
                    _a.label = 5;
                case 5:
                    i++;
                    return [3 /*break*/, 1];
                case 6:
                    if (!infoArray.find(function (i) { return typeof i.file !== "undefined"; })) return [3 /*break*/, 8];
                    return [4 /*yield*/, fileApi.post("/upload", formData, {
                            headers: { "Content-Type": "multipart/form-data" },
                        })];
                case 7:
                    response = _a.sent();
                    filepaths = response.data.filepaths;
                    if (isImage) {
                        for (i = 0; i < filepaths.length; i += 2) {
                            filesArray[i / 2].path = filepaths[i];
                            filesArray[i / 2].smallPath = filepaths[i + 1];
                        }
                    }
                    else {
                        for (i = 0; i < filepaths.length; i += 1) {
                            filesArray[i].path = filepaths[i];
                        }
                    }
                    _a.label = 8;
                case 8:
                    filesArray.push.apply(filesArray, filesToAdd.map(function (f) {
                        return {
                            key: f.key,
                            title: f.title,
                            name: f.name,
                            role: f.role,
                            category: f.category,
                            type: f.type,
                            brief: f.brief,
                            path: f.path,
                            smallPath: f.smallPath,
                        };
                    }));
                    _a.label = 9;
                case 9: return [2 /*return*/, filesArray.sort(function (f, s) { return (f.key > s.key ? 1 : -1); })];
            }
        });
    });
};
export var getEditedObjects = function (data) {
    return data.filter(function (e) { return e.isEdited == true; });
};
export var getUnEditedObjectIds = function (data) {
    var uneditedData = (data || []).filter(function (e) { return e.isEdited == false; });
    return uneditedData.map(function (e) { return e.id; });
};
