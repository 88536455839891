var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { connect } from "react-redux";
import { Switch, Redirect, useRouteMatch } from "react-router-dom";
import MyProjectsListPage from "../MyProjectsListPage/MyProjectsListPage";
import KNavRoute from "../../base-components/KNavRoute/KNavRoute";
import EditProjectPage from "../EditProjectPage/EditProjectPage";
import { getUserId } from "../../redux/selectors/authSelectors";
import ProjectPage from "../ProjectPage/ProjectPage";
var MyProjectsPage = function (_a) {
    var userId = _a.userId;
    var path = useRouteMatch().path;
    var listProfileRoutesData = [
        {
            path: "".concat(path, "/project/:projectId"),
            component: ProjectPage,
            props: {},
            exact: false,
        },
        {
            path: "".concat(path, "/createproject"),
            component: EditProjectPage,
            props: { isEditMode: false },
            exact: false,
        },
        {
            path: "".concat(path, "/editproject"),
            component: EditProjectPage,
            props: { isEditMode: true },
            exact: false,
        },
        {
            path: "".concat(path),
            component: MyProjectsListPage,
            props: {
                baseUserId: userId,
                basePath: path,
                isEditable: true,
            },
            exact: false,
        },
        {
            path: "".concat(path, "/*"),
            component: Redirect,
            props: { to: "/notfound" },
            exact: false,
        },
    ];
    return (_jsx("div", __assign({ className: "MyProjectsPage-container" }, { children: _jsx(Switch, { children: listProfileRoutesData.map(function (routeData, key) { return (_jsx(KNavRoute, { path: routeData.path, component: routeData.component, componentProps: routeData.props, exact: routeData.exact }, key)); }) }) })));
};
var mapStateToProps = function (state) { return ({
    userId: getUserId(state),
}); };
export default connect(mapStateToProps)(MyProjectsPage);
