var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { resetNotifications, updateNotifications, updateNotification, addNotifications, notificationsLoading, notificationsLoadingComplete, } from "../actions/notificationActions";
import { getAuthId, getToken } from "../selectors/authSelectors";
import notificationApi from "../../apis/notificationApi";
import io from "socket.io-client";
import { infiniteKeys, infiniteLoadingReload, } from "../../hooks/useInfiniteLoading";
import userApi from "../../apis/userApi";
var socket;
var uniqueId = Math.floor(Math.random() * 1e10);
export var thunkConnectNotifier = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        socket = io(process.env.NOTIFICATION_SERVICE_SOCKET_API, {
            withCredentials: true,
            autoConnect: false,
            auth: {
                token: getToken(getState()),
            },
        });
        socket.on("connect", function () {
            console.log("Notification socket connected");
            infiniteLoadingReload(infiniteKeys.NOTIFICATION);
            socket.on("initial", function (data) {
                dispatch(resetNotifications(data));
                socket.emit("join", uniqueId);
            });
        });
        socket.on("disconnect", function (disconnectionType) {
            console.log("Notification socket disconnected:", disconnectionType);
            if (disconnectionType == "transport close") {
                socket.disconnect();
                dispatch(thunkConnectNotifier());
            }
        });
        socket.on("private-".concat(getAuthId(getState()), "-").concat(uniqueId), function (data) {
            console.log("New notifications received", data);
            dispatch(updateNotifications(data));
        });
        socket.on("status-".concat(getAuthId(getState()), "-").concat(uniqueId), function (data) {
            console.log("Notification update received");
            dispatch(updateNotification(data));
        });
        socket.on("cleared-".concat(getAuthId(getState()), "-").concat(uniqueId), function (data) {
            console.log("Notifications cleared received");
            dispatch(resetNotifications());
        });
        socket.open();
        return [2 /*return*/];
    });
}); }; };
export var thunkDisconnectNotifier = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        console.log("Disconnecting notification socket..");
        socket.disconnect(uniqueId);
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadMoreNotifications = function (limit, offset) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(notificationsLoading());
        notificationApi
            .get("/notification/user/".concat(getAuthId(getState())), {
            params: {
                limit: limit,
                offset: offset,
            },
        })
            .then(function (res) {
            dispatch(addNotifications(res));
        })
            .catch(function (err) {
            console.log(err);
            dispatch(notificationsLoadingComplete());
        });
        return [2 /*return*/];
    });
}); }; };
export var thunClearUserNotifications = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(notificationsLoading());
        notificationApi
            .put("/notification/clear/user/".concat(getAuthId(getState())))
            .then(function (res) {
            callback();
            dispatch(notificationsLoadingComplete());
        })
            .catch(function (err) {
            console.log(err);
            dispatch(notificationsLoadingComplete());
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkMarkAsRead = function (notificationId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        notificationApi
            .put("/notification/seen/".concat(notificationId, "/user/").concat(getAuthId(getState())))
            .then(function (res) {
            callback();
        })
            .catch(function (err) {
            console.log(err);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkMarkAsUnread = function (notificationId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        notificationApi
            .put("/notification/unread/".concat(notificationId, "/user/").concat(getAuthId(getState())))
            .then(function (res) {
            callback();
        })
            .catch(function (err) {
            console.log(err);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkClearNotification = function (notificationId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        notificationApi
            .put("/notification/clear/".concat(notificationId, "/user/").concat(getAuthId(getState())))
            .then(function (res) {
            callback();
        })
            .catch(function (err) {
            console.log(err);
        });
        return [2 /*return*/];
    });
}); }; };
// for admin use
export var thunkSendNotificationToAll = function (text, relativeUrl, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var maxUserId, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(notificationsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, userApi.get("/maxAuthId")];
            case 2:
                maxUserId = (_a.sent()).data;
                return [4 /*yield*/, notificationApi.post("/notification/all", {
                        data: {
                            maxUserId: maxUserId,
                            text: text,
                            relativeUrl: relativeUrl,
                        },
                    })];
            case 3:
                _a.sent();
                callback();
                dispatch(notificationsLoadingComplete());
                return [3 /*break*/, 5];
            case 4:
                err_1 = _a.sent();
                console.log(err_1);
                callback(err_1);
                dispatch(notificationsLoadingComplete());
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
