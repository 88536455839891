var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "./KStars.css";
var Star = function (_a) {
    var marked = _a.marked, starId = _a.starId, fontSize = _a.fontSize;
    var styleContainer = {
        "--KStars-fontSize": fontSize || "30px",
    };
    return (_jsx("span", __assign({ style: styleContainer, "data-star-id": starId, className: "star", role: "button" }, { children: marked ? "\u2605" : "\u2606" })));
};
var KStars = function (_a) {
    var value = _a.value, label = _a.label, onClick = _a.onClick, fontSize = _a.fontSize, fix = _a.fix, color = _a.color;
    var styleContainer = {
        "--kstars-color": color || "var(--app-loadeduser-color)",
    };
    var _b = React.useState(0), selection = _b[0], setSelection = _b[1];
    var hoverOver = function (event) {
        var val = 0;
        if (event && event.target && event.target.getAttribute("data-star-id"))
            val = event.target.getAttribute("data-star-id");
        if (fix == "normal") {
            setSelection(val);
            onClick(val);
        }
    };
    return (_jsx("div", __assign({ className: "KStars-container", style: styleContainer }, { children: _jsxs("div", __assign({ className: "KStars-wrapper" }, { children: [_jsx("div", __assign({ className: "KStars-label" }, { children: label })), _jsx("div", __assign({ onClick: fix == "normal" ? hoverOver : void 0 }, { children: Array.from({ length: 5 }, function (v, i) { return (_jsx(Star, { fontSize: fontSize, starId: i + 1, marked: selection
                            ? selection >= i + 1
                            : parseInt(value || 0) >= i + 1 }, "star_".concat(i + 1))); }) }))] })) })));
};
export default KStars;
