var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { connect } from "react-redux";
import { Switch, Redirect, useRouteMatch } from "react-router-dom";
import KNavRoute from "../../base-components/KNavRoute/KNavRoute";
import { getUserId } from "../../redux/selectors/authSelectors";
import ProjectsPage from "../ProjectsPage/ProjectsPage";
import ProjectPage from "../ProjectPage/ProjectPage";
import UserPage from "../UserPage/UserPage";
var MarketPage = function (_a) {
    var userId = _a.userId, _b = _a.showMarket, showMarket = _b === void 0 ? false : _b;
    var path = useRouteMatch().path;
    var listNetworkRoutesData = (showMarket
        ? [
            {
                path: "".concat(path),
                component: ProjectsPage,
                props: { userId: userId },
                exact: true,
            },
        ]
        : []).concat([
        {
            path: "".concat(path, "/user/:userId"),
            component: UserPage,
            props: { basePath: path },
            exact: false,
        },
        {
            path: "".concat(path, "/project/:projectId"),
            component: ProjectPage,
            props: {},
            exact: false,
        },
        {
            path: "".concat(path, "*"),
            component: Redirect,
            props: { to: "/notfound" },
            exact: false,
        },
    ]);
    return (_jsx("div", __assign({ className: "profile-container" }, { children: _jsx(Switch, { children: listNetworkRoutesData.map(function (routeData, key) { return (_jsx(KNavRoute, { path: routeData.path, component: routeData.component, componentProps: routeData.props, exact: routeData.exact }, key)); }) }) })));
};
var mapStateToProps = function (state) { return ({
    userId: getUserId(state),
}); };
export default connect(mapStateToProps)(MarketPage);
