import * as yup from "yup";
export var boostingSchema = function (dateRanges, selectedStartDate) {
    dateRanges = dateRanges.sort(function (a, b) {
        return new Date(a[0]).getTime() - new Date(b[0]).getTime();
    });
    return yup.object().shape({
        countries: yup
            .array()
            .of(yup.object().shape({
            country_id: yup.string().required("Countries required"),
        }))
            .min(0),
        languages: yup
            .array()
            .of(yup.object().shape({
            language_id: yup.string().required("Languages required"),
        }))
            .min(0),
        startDate: yup
            .date()
            .required("Start date is required")
            .min(new Date().toISOString(), "Start date has to be in the future")
            .test("startOverlap", "Date is overlapping with running boosts", function (value) {
            if (!dateRanges || !dateRanges.length)
                return true;
            var date = new Date(value);
            if (date < new Date(dateRanges[0][0]))
                return true;
            if (date > new Date(dateRanges[dateRanges.length - 1][1]))
                return true;
            var lastEndDate = new Date(dateRanges[0][1]);
            for (var _i = 0, _a = dateRanges.slice(1, dateRanges.length); _i < _a.length; _i++) {
                var dateRange = _a[_i];
                var startDate = new Date(dateRange[0]);
                if (date > lastEndDate && date < startDate)
                    return true;
                lastEndDate = new Date(dateRange[1]);
            }
            return false;
        }),
        endDate: yup
            .date()
            .required("End date is required")
            .min(yup.ref("startDate"), "End date can't be before start date")
            .test("endOverlap", "Date is overlapping with running boosts", function (value) {
            if (!dateRanges || !dateRanges.length)
                return true;
            var date = new Date(value);
            selectedStartDate = new Date(selectedStartDate);
            if (selectedStartDate < new Date(dateRanges[0][0])) {
                return date < new Date(dateRanges[0][0]);
            }
            if (selectedStartDate >
                new Date(dateRanges[dateRanges.length - 1][1]))
                return true;
            var lastEndDate = new Date(dateRanges[0][1]);
            for (var _i = 0, _a = dateRanges.slice(1, dateRanges.length); _i < _a.length; _i++) {
                var dateRange = _a[_i];
                var startDate = new Date(dateRange[0]);
                if (selectedStartDate > lastEndDate &&
                    selectedStartDate < startDate) {
                    return date < startDate;
                }
                lastEndDate = new Date(dateRange[1]);
            }
            return false;
        }),
    });
};
