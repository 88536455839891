import axios from "axios";
import store from "../redux/store";
import { getToken } from "../redux/selectors/authSelectors";
var defaultApi = axios.create({
    baseURL: "".concat(process.env.SCRIPTO_API_URL, "/scripto/api/v0/"),
    responseType: "json",
});
defaultApi.defaults.timeout = parseInt(process.env.SCRIPTO_API_TIMEOUT) * 1000;
defaultApi.interceptors.request.use(function (config) {
    config.headers.Authorization =
        process.env.SCRIPTO_API_KEY + "~ATK~" + getToken(store.getState());
    return config;
});
export default defaultApi;
