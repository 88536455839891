var KFilesUploader = /** @class */ (function () {
    function KFilesUploader(user, uploadUrl, chunkSize, onProgress, onFinish, onError) {
        if (chunkSize === void 0) { chunkSize = 4000000; }
        if (onProgress === void 0) { onProgress = function (progress) {
            return console.log("Upload progress: ", progress);
        }; }
        if (onFinish === void 0) { onFinish = function (outputData) {
            return console.log("Files uploaded: ", outputData);
        }; }
        if (onError === void 0) { onError = function () { return alert("Error"); }; }
        this.key = "";
        this.user = user;
        this.totalSize = 0;
        this.filesData = [];
        this.outputData = [];
        this.resumableUri = "";
        this.onError = onError;
        this.onFinish = onFinish;
        this.uploadUrl = uploadUrl;
        this.chunkSize = parseInt(chunkSize.toString());
        this.onProgress = onProgress;
        this.currentChunkStartByte = 0;
        this.currentChunkFinalByte = 0;
        this.accumulatedBytes = 0;
        this.request = new XMLHttpRequest();
    }
    // Public members
    KFilesUploader.prototype.addFile = function (file, key, fileType, isPrivate) {
        if (isPrivate === void 0) { isPrivate = false; }
        this.filesData.push({ file: file, key: key, fileType: fileType, isPrivate: isPrivate });
    };
    KFilesUploader.prototype.uploadFiles = function () {
        this.totalSize = 0;
        this.accumulatedBytes = 0;
        for (var _i = 0, _a = this.filesData; _i < _a.length; _i++) {
            var data = _a[_i];
            this.totalSize += data.file.size;
        }
        this._uploadNext();
    };
    // Private members
    KFilesUploader.prototype._onProgress = function () {
        this.onProgress(Math.max(0.1, Math.floor(100 *
            ((this.accumulatedBytes + this.currentChunkFinalByte) /
                this.totalSize))));
    };
    KFilesUploader.prototype._uploadNext = function () {
        var _this = this;
        var data = this.filesData.shift();
        if (!data) {
            console.log("All files uploaded");
            this.onProgress(100);
            return setTimeout(function () { return _this.onFinish(_this.outputData); }, 500);
        }
        this.currentChunkStartByte = 0;
        this.currentChunkFinalByte =
            this.chunkSize > data.file.size ? data.file.size : this.chunkSize;
        this._uploadFile(data.file, data.key, data.fileType, data.isPrivate);
    };
    KFilesUploader.prototype._uploadFile = function (file, key, fileType, isPrivate) {
        var _this = this;
        if (isPrivate === void 0) { isPrivate = false; }
        if (this.currentChunkStartByte === 0) {
            this.key = Date.now() + "-" + Math.round(Math.random() * 1e9);
        }
        this.request.open("POST", this.uploadUrl, true);
        var chunk = file.slice(this.currentChunkStartByte, this.currentChunkFinalByte);
        this.request.setRequestHeader("Content-Range", "bytes ".concat(this.currentChunkStartByte, "-").concat(this.currentChunkFinalByte, "/").concat(file.size));
        this.request.onerror = function (err) {
            console.error(err);
            return _this.onError("Upload failed");
        };
        this.request.onload = function () {
            var remainingBytes = file.size - _this.currentChunkFinalByte;
            if (_this.request.status !== 200) {
                console.error(_this.request.response);
                return _this.onError(JSON.parse(_this.request.response).message);
            }
            if (_this.currentChunkFinalByte === file.size) {
                _this._onProgress();
                console.log("File upload completed");
                var res = JSON.parse(_this.request.response);
                _this.accumulatedBytes += _this.currentChunkFinalByte;
                _this.outputData.push({ filepath: res.filepath, key: key });
                return _this._uploadNext();
            }
            else if (remainingBytes < _this.chunkSize) {
                _this.currentChunkStartByte = _this.currentChunkFinalByte;
                _this.currentChunkFinalByte =
                    _this.currentChunkStartByte + remainingBytes;
            }
            else {
                _this.currentChunkStartByte = _this.currentChunkFinalByte;
                _this.currentChunkFinalByte =
                    _this.currentChunkStartByte + _this.chunkSize;
            }
            _this._onProgress();
            _this._uploadFile(file, key, fileType, isPrivate);
        };
        var formData = new FormData();
        var isLast = this.currentChunkFinalByte === file.size;
        formData.append("type", fileType);
        formData.append("user", this.user);
        formData.append("isLast", isLast.toString());
        formData.append("isPrivate", isPrivate.toString());
        formData.append("filename", file.name);
        formData.append("file", chunk, file.name);
        formData.append("key", this.key);
        this.request.send(formData);
    };
    return KFilesUploader;
}());
export default KFilesUploader;
