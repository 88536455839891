var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import KButton from "../../base-components/KButton/KButton";
import KImage from "../../base-components/KImage/KImage";
import { thunkGetUserByUserId } from "../../redux/thunks/userThunks";
import { getFullName, getUserProfilePic, getUserTitleColor, } from "../../tools/userTools";
import "./ChatUserSelector.css";
var UserCard = function (_a) {
    var user = _a.user, title = _a.title, onClick = _a.onClick;
    return (_jsxs("div", __assign({ className: "chatuserselector-user-card", onClick: function () { return onClick(user); } }, { children: [_jsx("div", __assign({ className: "chatuserselector-user-image", style: { borderColor: getUserTitleColor(user.user_title_name) } }, { children: _jsx(KImage, { imgSrc: getUserProfilePic(user.user_small_image_path), smallImgSrc: getUserProfilePic(user.user_small_image_path), rounded: true }) })), _jsxs("div", __assign({ className: "chatuserselector-user-info" }, { children: [_jsx("div", __assign({ className: "chatuserselector-user-title" }, { children: getFullName(user) })), _jsx("div", __assign({ className: "chatuserselector-user-subtitle" }, { children: title }))] })), _jsx("div", __assign({ className: "chatuserselector-user-action" }, { children: _jsx("div", __assign({ className: "chatuserselector-user-action-button" }, { children: "select" })) }))] })));
};
var ChatUserSelector = function (_a) {
    var ownerId = _a.ownerId, directorId = _a.directorId, producerId = _a.producerId, onClose = _a.onClose, onClick = _a.onClick, onGetUser = _a.onGetUser;
    var _b = useState(), owner = _b[0], setOwner = _b[1];
    var _c = useState(), director = _c[0], setDirector = _c[1];
    var _d = useState(), producer = _d[0], setProducer = _d[1];
    useEffect(function () {
        if (ownerId)
            onGetUser(ownerId, function (err, user) {
                setOwner(user);
            });
    }, [ownerId]);
    useEffect(function () {
        if (directorId)
            onGetUser(directorId, function (err, user) {
                setDirector(user);
            });
    }, [directorId]);
    useEffect(function () {
        if (producerId)
            onGetUser(producerId, function (err, user) {
                setProducer(user);
            });
    }, [producerId]);
    return (_jsxs("div", __assign({ className: "chatuserselector-container" }, { children: [_jsx("div", __assign({ className: "chatuserselector-title" }, { children: "Chat with" })), _jsxs("div", __assign({ className: "chatuserselector-users" }, { children: [owner ? (_jsx(UserCard, { user: owner, title: "Project Creator", onClick: onClick })) : (_jsx(_Fragment, {})), owner &&
                        (director === null || director === void 0 ? void 0 : director.user_id) &&
                        director.user_id !== owner.user_id ? (_jsx(UserCard, { user: director, title: "Director", onClick: onClick })) : (_jsx(_Fragment, {})), owner &&
                        (producer === null || producer === void 0 ? void 0 : producer.user_id) &&
                        producer.user_id !== owner.user_id ? (_jsx(UserCard, { user: producer, title: "Producer", onClick: onClick })) : (_jsx(_Fragment, {}))] })), _jsx("div", __assign({ className: "chatuserselector-action" }, { children: _jsx(KButton, { title: "Cancel", variant: "primary", onClick: onClose }) }))] })));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    onGetUser: function (userId, callback) {
        return dispatch(thunkGetUserByUserId(userId, callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ChatUserSelector);
