var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { GrHistory as IconInvoices } from "react-icons/gr";
import KButton from "../../base-components/KButton/KButton";
import { thunkCancelFreeSubscription, thunkCancelSubscription, thunkCreateFreeSubscription, thunkCreateSubscription, thunkLoadSubscriptionPrices, thunkLoadUserSubscription, thunkReactivateFreeSubscription, thunkReactivateSubscription, thunkVerify, } from "../../redux/thunks/authThunks";
import PaymentCompletePage from "../PaymentCompletePage/PaymentCompletePage";
import SubscribePage from "../SubscribePage/SubscribePage";
import { getSubscriptionPrices, getUserCurrentPlan, getUserCurrentSubscription, getUserEmail, } from "../../redux/selectors/authSelectors";
import { thunkIsSubscriptionPaymentNeeded } from "../../redux/thunks/projectThunks";
import { getProjectSymbolSrc } from "../../tools/projectTools";
import { getReadableDate } from "../../tools/generalTools";
import { history } from "../../redux/store";
import "./SubscriptionsPage.css";
var SubscriptionsPage = function (_a) {
    var email = _a.email, prices = _a.prices, currentPlan = _a.currentPlan, currentSubscription = _a.currentSubscription, onCancelSubscription = _a.onCancelSubscription, onReactivateSubscription = _a.onReactivateSubscription, onLoadUserSubscription = _a.onLoadUserSubscription, onCreateSubscription = _a.onCreateSubscription, onLoadPrices = _a.onLoadPrices, onCheckPaymentNeeded = _a.onCheckPaymentNeeded, onCreateFreeSubscription = _a.onCreateFreeSubscription, onReactivateFreeSubscription = _a.onReactivateFreeSubscription, onCancelFreeSubscription = _a.onCancelFreeSubscription, onRefreshToken = _a.onRefreshToken;
    var location = useLocation();
    var query = new URLSearchParams(location.search);
    var _b = useState(""), transactionsTitle = _b[0], setTransactionsTitle = _b[1];
    var _c = useState(false), paymentCompletedOn = _c[0], setPaymentCompletedOn = _c[1];
    var _d = useState(false), isSubscriptionCancelled = _d[0], setIsSubscriptionCancelled = _d[1];
    var _e = useState(""), clientSecret = _e[0], setClientSecret = _e[1];
    var _f = useState(null), subscriptionId = _f[0], setSubscriptionId = _f[1];
    var _g = useState(true), isPaymentNeeded = _g[0], setIsPaymentNeeded = _g[1];
    var _h = useState(null), premiumPrice = _h[0], setPremiumPrice = _h[1];
    var _j = useState(false), isPremium = _j[0], setIsPremium = _j[1];
    var _k = useState(false), isPayMode = _k[0], setIsPayMode = _k[1];
    var handleResize = function () {
        setTransactionsTitle(window.innerWidth < 600 ? "" : "History");
    };
    var handleSubscriptionState = function () {
        setIsPremium(currentPlan === "premium");
    };
    var onChooseSubscription = function (plan) {
        onCheckPaymentNeeded(function (paymentNeeded, err) {
            if (err) {
                console.log(err);
                setIsPaymentNeeded(true);
                alert("Failing to take action at this time...");
                return;
            }
            setIsPaymentNeeded(paymentNeeded);
            switch (plan) {
                case "basic":
                    if (currentPlan == "premium") {
                        // cancel subscription
                    }
                    break;
                case "premium":
                    if (currentPlan === "premium") {
                        if (currentSubscription.cancelled) {
                            var reactivate = confirm("Are you sure you would like to re-activate your premium plan?");
                            if (reactivate) {
                                if (paymentNeeded) {
                                    // reactivate subscription
                                    onReactivateSubscription(currentSubscription.id, function () {
                                        console.log("loading user sub");
                                        alert("Your subscription has been re-activated!");
                                        onLoadUserSubscription();
                                    });
                                }
                                else {
                                    onReactivateFreeSubscription(function (data, err) {
                                        if (err) {
                                            return console.log(err);
                                        }
                                        alert("Your subscription has been re-activated!");
                                        onLoadUserSubscription();
                                    });
                                }
                            }
                        }
                        else {
                            var cancelPlan = confirm("Are you sure you would like to cancel your premium plan?");
                            if (cancelPlan) {
                                // cancel subscription
                                if (paymentNeeded) {
                                    onCancelSubscription(currentSubscription.id, function () {
                                        console.log("loading user sub");
                                        alert("Your subscription has been cancelled! You would still have access to premium features until ".concat(getReadableDate(currentSubscription.endDate), "."));
                                        onLoadUserSubscription();
                                    });
                                }
                                else {
                                    onCancelFreeSubscription(function (data, err) {
                                        if (err) {
                                            return console.log(err);
                                        }
                                        alert("Your subscription has been cancelled! You would still have access to premium features until ".concat(getReadableDate(currentSubscription.endDate), "."));
                                        onLoadUserSubscription();
                                    });
                                }
                            }
                        }
                    }
                    else {
                        // subscribe to premium
                        if (paymentNeeded) {
                            onCreateSubscription(premiumPrice.id, function (data) {
                                setClientSecret(data.clientSecret);
                                setSubscriptionId(data.subscriptionId);
                                // setIsPayMode(true);
                                history.push("/platform/subscriptions?paymode=true");
                            });
                        }
                        else {
                            onCreateFreeSubscription(function (data, err) {
                                if (err) {
                                    console.log(err);
                                    return;
                                }
                                onRefreshToken();
                                history.push("/platform/subscriptions?paymentCompleted=true&plan=Premium&amount=".concat(premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.amount, "$"));
                            });
                        }
                    }
                    break;
                default:
                    break;
            }
        });
    };
    useEffect(function () {
        onCheckPaymentNeeded(function (paymentNeeded, err) {
            if (err) {
                console.log(err);
                setIsPaymentNeeded(true);
                alert("Failing to take action at this time...");
                return;
            }
            setIsPaymentNeeded(paymentNeeded);
        });
    }, []);
    useEffect(function () {
        setIsSubscriptionCancelled(currentSubscription.cancelled);
    }, [currentSubscription]);
    useEffect(function () {
        setPremiumPrice(prices.find(function (price) { return price.name === "Premium"; }));
    }, [prices === null || prices === void 0 ? void 0 : prices.length]);
    useEffect(function () {
        handleSubscriptionState();
    }, [currentPlan]);
    useEffect(function () {
        setPaymentCompletedOn(query.get("paymentCompleted") === "true");
        setIsPayMode(query.get("paymode") === "true");
        onLoadUserSubscription();
    }, [location]);
    useEffect(function () {
        if (paymentCompletedOn) {
            var reload = query.get("reload");
            if (reload !== "false") {
                window.history.replaceState(null, "", "".concat(window.location.pathname).concat(window.location.search
                    ? window.location.search + "&reload=false"
                    : "?reload=false"));
                onRefreshToken();
            }
        }
    }, [paymentCompletedOn]);
    useEffect(function () {
        handleResize();
        onLoadPrices();
        onLoadUserSubscription();
        handleSubscriptionState();
        window.addEventListener("resize", handleResize);
        setIsPayMode(query.get("paymode") === "true");
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsx("div", __assign({ className: "subscriptionspage-container" }, { children: paymentCompletedOn ? (_jsx(PaymentCompletePage, { amount: query.get("amount"), description: "Congratulations, you have successfully subscribed to the ".concat(query.get("plan"), " Plan! You can check your subscription history at any time."), feesTitle: isPaymentNeeded ? "Paid Fees" : "Covered Charges", title: isPaymentNeeded
                ? "Thank you for your payment"
                : "Subscription purchased for free", onClose: function () {
                onLoadUserSubscription();
                setPaymentCompletedOn(false);
                window.history.replaceState(null, "", "/platform/subscriptions");
            } })) : (_jsx(_Fragment, { children: premiumPrice ? (_jsx(_Fragment, { children: isPayMode && premiumPrice && clientSecret ? (_jsx(SubscribePage, { email: email, clientSecret: clientSecret ||
                        "pi_3KJIBCFoirgnNpJ11doBUSJS_secret_OzludZF5OdL2uWAZPfKzF11Fo", onClose: function () {
                        setIsPayMode(false);
                        window.history.replaceState(null, null, "/platform/subscriptions");
                    }, currency: premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.currency, totalAmount: premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.amount, invoiceItem: {
                        name: (premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.name) + " Plan",
                        amount: premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.amount,
                        unitAmount: premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.amount,
                        currency: premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.currency,
                        unit: premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.period,
                        quantity: 1,
                    }, returnPathname: "".concat(process.env.SUBSCRIPTION_PAYMENT_REDIRECT_PATHNAME).concat(premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.name, "&amount=").concat(premiumPrice === null || premiumPrice === void 0 ? void 0 : premiumPrice.amount, "$") })) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "subscriptionspage-title" }, { children: [_jsx("div", { className: "subscriptionspage-title-text" }), _jsx(KButton, { title: transactionsTitle, variant: "primary", icon: IconInvoices, onClick: function () {
                                        return history.push("/platform/subscriptions/history");
                                    } })] })), _jsxs("div", __assign({ className: "subscriptionspage-info-section" }, { children: [_jsx("div", __assign({ className: "subscriptionspage-info-title" }, { children: "Professional area access" })), _jsxs("div", __assign({ className: "subscriptionspage-info-subtitle" }, { children: ["Expand your horizons ", _jsx("br", {}), "and get more market and network data"] })), _jsxs("div", __assign({ className: "subscriptionspage-mobile-info-box" }, { children: [_jsx("img", { src: "/images/scriptwriter-symbol.png", style: {
                                                width: "40px",
                                            } }), _jsx("img", { src: "/images/filmmaker-symbol.png", style: {
                                                width: "45px",
                                            } }), _jsx("img", { src: "/images/producer-symbol.png", style: {
                                                width: "30px",
                                            } }), _jsx("img", { src: "/images/investor-symbol.png", style: {
                                                width: "50px",
                                                marginBottom: "6px",
                                            } }), _jsx("img", { src: "/images/buyer-symbol.png", style: {
                                                width: "40px",
                                                marginBottom: "0px",
                                            } })] })), _jsxs("div", __assign({ className: "subscriptionspage-info-box" }, { children: [_jsxs("div", __assign({ className: "subscriptionspage-info-box-1" }, { children: [_jsxs("div", __assign({ className: "subscriptionspage-info-box-icons-1" }, { children: [_jsx("img", { src: getProjectSymbolSrc("script") }), _jsx("img", { src: getProjectSymbolSrc("film") })] })), _jsx("div", __assign({ className: "subscriptionspage-info-box-text" }, { children: "Search our wide database of potential scripts and films posted by creative people" }))] })), _jsxs("div", __assign({ className: "subscriptionspage-info-box-2" }, { children: [_jsxs("div", __assign({ className: "subscriptionspage-info-box-icons-2" }, { children: [_jsx("img", { src: "/images/scriptwriter-symbol.png", style: {
                                                                width: "55px",
                                                            } }), _jsx("img", { src: "/images/filmmaker-symbol.png", style: {
                                                                width: "60px",
                                                            } }), _jsx("img", { src: "/images/producer-symbol.png", style: {
                                                                width: "45px",
                                                            } }), _jsx("img", { src: "/images/investor-symbol.png", style: {
                                                                width: "65px",
                                                                marginBottom: "8px",
                                                            } }), _jsx("img", { src: "/images/buyer-symbol.png", style: {
                                                                width: "55px",
                                                                marginBottom: "0px",
                                                            } })] })), _jsx("div", __assign({ className: "subscriptionspage-info-box-text" }, { children: "Browse, explore, and connect with a network of industry professionals" }))] }))] })), _jsx("div", { className: "subscriptionspage-info-section" })] })), _jsx("div", __assign({ className: "subscriptionspage-wrapper" }, { children: premiumPrice && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "subscriptionspage-box ".concat(isPremium
                                            ? ""
                                            : "subscriptionspage-active") }, { children: [_jsx("div", { className: "subscriptionspage-box-cover" }), _jsxs("div", __assign({ className: "subscriptionspage-box-header" }, { children: [_jsx("div", __assign({ className: "subscriptionspage-box-title" }, { children: "Basic" })), _jsx("div", __assign({ className: "subscriptionspage-box-subtitle" }, { children: "Subscription" })), _jsx("div", __assign({ className: "subscriptionspage-box-your-plan" }, { children: isPremium
                                                            ? ""
                                                            : "(your plan)" }))] })), _jsx("div", __assign({ className: "subscriptionspage-box-info" }, { children: _jsxs("ul", { children: [_jsx("li", { children: "Tailored access to your profile" }), _jsx("li", { children: "Limited access to about 100 users" }), _jsx("li", { children: "Limited access to about 50 projects" }), _jsxs("li", { children: ["Ability to message any of the users you have access to", " "] }), _jsxs("li", { children: ["Ability to sign contracts with any of the users you communicated with", " "] }), _jsxs("li", { children: ["Access to a reshuffled batch of users and/or projects every 3 months", " "] })] }) })), _jsxs("div", __assign({ className: "subscriptionspage-box-action-wrapper", onClick: function () {
                                                    return onChooseSubscription("basic");
                                                } }, { children: [_jsx("div", __assign({ className: "subscriptionspage-box-status" }, { children: isPremium
                                                            ? "INACTIVE"
                                                            : "ACTIVE" })), _jsxs("div", __assign({ className: "subscriptionspage-box-cost" }, { children: [_jsx("div", __assign({ className: "subscriptionspage-box-cost-title" }, { children: "$0 / month" })), _jsx("div", __assign({ className: "subscriptionspage-box-cost-subtitle" }, { children: "(prices are in USD)" }))] }))] }))] })), _jsxs("div", __assign({ className: "subscriptionspage-box ".concat(isPremium
                                            ? "subscriptionspage-active"
                                            : "") }, { children: [_jsx("div", { className: "subscriptionspage-box-cover" }), _jsxs("div", __assign({ className: "subscriptionspage-box-header" }, { children: [_jsx("div", __assign({ className: "subscriptionspage-box-title" }, { children: premiumPrice.name })), _jsx("div", __assign({ className: "subscriptionspage-box-subtitle" }, { children: "Subscription" })), _jsx("div", __assign({ className: "subscriptionspage-box-your-plan" }, { children: isPremium
                                                            ? "(your plan)"
                                                            : "" }))] })), _jsx("div", __assign({ className: "subscriptionspage-box-info" }, { children: _jsxs("ul", { children: [_jsxs("li", { children: [_jsx("b", { children: "Full access" }), " ", "to all profile pages", " "] }), _jsxs("li", { children: [_jsx("b", { children: "3x" }), " more access to users in the network", " "] }), _jsxs("li", { children: [_jsx("b", { children: "3x" }), " more access to projects in the market", " "] }), _jsxs("li", { children: ["Ability to message any of the users you have access to", " "] }), _jsxs("li", { children: ["Ability to sign contracts with any of the users you communicated with", " "] }), _jsxs("li", { children: ["Access to a reshuffled batch of users and/or projects every 3 months", " "] })] }) })), _jsxs("div", __assign({ className: "subscriptionspage-box-action-wrapper", onClick: function () {
                                                    return onChooseSubscription("premium");
                                                } }, { children: [_jsxs("div", __assign({ className: "subscriptionspage-box-status" }, { children: [isPremium
                                                                ? isSubscriptionCancelled
                                                                    ? "RE-ACTIVATE"
                                                                    : "CANCEL"
                                                                : "SUBSCRIBE", isPremium &&
                                                                isSubscriptionCancelled ? (_jsxs("div", __assign({ className: "subscriptionspage-box-action-subtitle" }, { children: ["(expires", " ", getReadableDate(currentSubscription.endDate), ")"] }))) : (_jsx(_Fragment, {})), !isPaymentNeeded &&
                                                                !isPremium ? (_jsx("div", __assign({ className: "subscriptionspage-box-action-subtitle" }, { children: "FOR FREE" }))) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "subscriptionspage-box-cost" }, { children: [_jsxs("div", __assign({ className: "subscriptionspage-box-cost-title" }, { children: ["$", premiumPrice.amount, " ", "/", " ", premiumPrice.period] })), _jsx("div", __assign({ className: "subscriptionspage-box-cost-subtitle" }, { children: "(prices are in USD)" })), isPaymentNeeded ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "subscriptionspage-box-cost-subtitle active" }, { children: "offered by Scriptofilm" })))] }))] }))] }))] })) }))] })) })) : (_jsx(_Fragment, {})) })) })));
};
var mapStateToProps = function (state) { return ({
    email: getUserEmail(state),
    prices: getSubscriptionPrices(state),
    currentPlan: getUserCurrentPlan(state),
    currentSubscription: getUserCurrentSubscription(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onCreateSubscription: function (priceId, callback) {
        return dispatch(thunkCreateSubscription(priceId, callback));
    },
    onCancelSubscription: function (stripeSubId, callback) {
        return dispatch(thunkCancelSubscription(stripeSubId, callback));
    },
    onReactivateSubscription: function (stripeSubId, callback) {
        return dispatch(thunkReactivateSubscription(stripeSubId, callback));
    },
    onLoadPrices: function () { return dispatch(thunkLoadSubscriptionPrices()); },
    onLoadUserSubscription: function () { return dispatch(thunkLoadUserSubscription()); },
    onCheckPaymentNeeded: function (callback) {
        return dispatch(thunkIsSubscriptionPaymentNeeded(callback));
    },
    onCreateFreeSubscription: function (callback) {
        return dispatch(thunkCreateFreeSubscription(callback));
    },
    onCancelFreeSubscription: function (callback) {
        return dispatch(thunkCancelFreeSubscription(callback));
    },
    onReactivateFreeSubscription: function (callback) {
        return dispatch(thunkReactivateFreeSubscription(callback));
    },
    onRefreshToken: function (callback) { return dispatch(thunkVerify(callback)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPage);
