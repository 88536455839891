var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import KButton from "../../base-components/KButton/KButton";
import Banner from "../../mui-components/banner/banner";
import Collaborate from "../../mui-components/collaborate/collaborate";
import Footer from "../../mui-components/footer/footer";
import JoinNow from "../../mui-components/join-now/join-now";
import FindBest from "../../mui-components/find-best/find-best";
import TailoredExp from "../../mui-components/tailored-exp/tailored-exp";
import { thunkStopLoading } from "../../redux/thunks/generalThunks";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import NavBar from "../../components/NavBar/NavBar";
import { AiOutlineArrowUp as IconArrowUp } from "react-icons/ai";
import "./LandingPage.css";
var LandingPage = function (_a) {
    var onStopLoading = _a.onStopLoading;
    var history = useHistory();
    var _b = useState("landingpage-goUpBtn-hide"), showUpBtn = _b[0], setShowUpBtn = _b[1];
    useDocumentTitle("Scriptofilm | Welcome");
    useEffect(function () {
        window.scrollTo({ top: 0 });
        onStopLoading();
    }, []);
    useEffect(function () {
        function onScroll() {
            if (window.pageYOffset !== 0) {
                setShowUpBtn("landingpage-goUpBtn-show");
            }
            else {
                setShowUpBtn("landingpage-goUpBtn-hide");
            }
        }
        window.addEventListener("scroll", onScroll);
        return function () { return window.removeEventListener("scroll", onScroll); };
    }, [window.pageYOffset]);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "landingpage-container" }, { children: _jsxs(Fragment, { children: [_jsx(NavBar, {}), _jsx(Banner, {}), _jsx(JoinNow, {}), _jsx(FindBest, {}), _jsx(Collaborate, {}), _jsx(TailoredExp, {}), _jsx("div", __assign({ className: "landingpage-goUpBtn " + showUpBtn }, { children: _jsx(KButton, { title: "", borderRadius: "8px", variant: "primary", width: "20px", height: "20px", backgroundColor: "var(--app-scriptwriter-color)", icon: IconArrowUp, onClick: function () {
                                window.scrollTo({ top: 0, behavior: "smooth" });
                            } }) })), _jsx(Footer, {})] }) })) }));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    onStopLoading: function () { return dispatch(thunkStopLoading()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
