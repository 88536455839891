var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { IoMdEye as IconView } from "react-icons/io";
import { MdTextsms as IconCollaborate } from "react-icons/md";
import { getProjectAcquisitionBudget, getProjectDate, getProjectSymbolSrc, } from "../../tools/projectTools";
import KButton from "../../base-components/KButton/KButton";
import "./ScriptCard.css";
var ScriptCard = function (_a) {
    var script = _a.script, _b = _a.status, status = _b === void 0 ? "open for collaboration" : _b, href = _a.href, onViewClick = _a.onViewClick, onCollaborate = _a.onCollaborate, _c = _a.isEditable, isEditable = _c === void 0 ? true : _c;
    var isClosed = script.project_closed;
    status = isClosed
        ? "project successfully completed"
        : script.project_open_for_sale && script.project_open_for_collaboration
            ? "open for sale and collaboration"
            : script.project_open_for_sale || script.project_open_for_collaboration
                ? "open for ".concat(script.project_open_for_sale ? "sale" : "collaboration")
                : "not open for sale or collaboration";
    return (_jsxs("div", __assign({ className: "scriptcard-container" }, { children: [_jsxs("div", __assign({ className: "scriptcard-top-section" }, { children: [_jsx("div", __assign({ className: "scriptcard-image-section" }, { children: _jsx("img", { className: "scriptcard-image", src: getProjectSymbolSrc(script.project_type_name), alt: "project-symbol", onClick: function () { return onViewClick(script); } }) })), _jsx("div", __assign({ className: "scriptcard-title-section", onClick: function () { return onViewClick(script); } }, { children: _jsx("p", __assign({ className: "scriptcard-title" }, { children: script.project_title || "no title provided" })) })), _jsx("div", { className: "scriptcard-evaluation-section" })] })), _jsx("div", __assign({ className: "scriptcard-content-section" }, { children: _jsx("p", __assign({ className: "scriptcard-text" }, { children: script.project_synopsis ||
                        "No project description provided.." })) })), _jsxs("div", __assign({ className: "scriptcard-action-section" }, { children: [_jsx("div", __assign({ className: "scriptcard-action-button", style: { display: isClosed || isEditable ? "none" : "" } }, { children: _jsx(KButton, { title: "", icon: IconCollaborate, height: "17px", width: "17px", variant: "loadeduser", borderRadius: "50%", onClick: onCollaborate }) })), _jsx("div", __assign({ className: "scriptcard-action-button" }, { children: _jsx(KButton, { title: "", icon: IconView, height: "17px", width: "17px", variant: "loadeduser", borderRadius: "50%", href: href, onClick: function () { return onViewClick(script); } }) }))] })), _jsx("div", __assign({ className: "scriptcard-footer-section-1" }, { children: _jsx("p", __assign({ className: "scriptcard-footer-text-1" }, { children: "no collaborations" })) })), _jsxs("div", __assign({ className: "scriptcard-footer-section-2", style: { backgroundColor: isClosed ? "#ddd" : "" } }, { children: [(script.language_name ? [script.language_code] : []).map(function (lang, key) { return (_jsx("div", __assign({ className: "scriptcard-footer-tab-2" }, { children: _jsx("p", { children: lang }) }), key)); }), _jsx("div", __assign({ className: "scriptcard-footer-tab-2", style: {
                            display: getProjectAcquisitionBudget(script)
                                ? "flex"
                                : "none",
                        } }, { children: _jsx("p", { children: getProjectAcquisitionBudget(script) }) })), _jsx("div", __assign({ className: "scriptcard-footer-tab-2" }, { children: _jsx("p", { children: getProjectDate(script) }) })), _jsx("div", __assign({ className: "scriptcard-footer-tab-3 scriptcard-float-right" }, { children: _jsx("p", { children: status }) }))] }))] })));
};
ScriptCard.propTypes = {
    onViewClick: PropTypes.func.isRequired,
    onCollaborate: PropTypes.func.isRequired,
};
ScriptCard.defaultProps = {
    onViewClick: function (script) { return alert("View script: ".concat(JSON.stringify(script))); },
    onCollaborate: function () { return alert("Feature coming soon"); },
};
export default ScriptCard;
