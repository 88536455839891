var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { unauthenticateUser, signinSuccess, signupKachatSuccess, forgotPassInProgress, forgotPassSuccess, forgotPassFailure, resetPassInProgress, resetPassSuccess, resetPassFailure, clearUserSignedUp, setSignupInfo, signinFailure, signinInProgress, startAuthLoading, stopAuthLoading, subscriptionPricesSuccess, loadUserSubscriptionsSuccess, loadUserSubscriptionSuccess, loadPaymentMethodsSuccess, loadDefaultPaymentMethodSuccess, chatConnectFailure, chatConnectSuccess, } from "../actions/authActions";
import { infiniteLoadingReloadAll, } from "../../hooks/useInfiniteLoading";
import { updateUserInProgress, updateUserSuccess, updateUserFailure, loadProjectBoostInProgress, loadProjectBoostSuccess, loadProjectBoostsFailure, } from "../actions/userActions";
import kachat from "kachat";
import authApi from "../../apis/authApi";
import authUserApi from "../../apis/authUserApi";
import fileApi from "../../apis/fileApi";
import userApi from "../../apis/userApi";
import paymentApi from "../../apis/paymentApi";
import { getUserId, getUserAuthenticated, getUserEmail, } from "../selectors/authSelectors";
import { resizeAsync, dataURItoBlob } from "../../imaging/imageTransform";
import { getErrorMessage } from "../utils";
import { thunkClearData } from "./generalThunks";
import { push } from "connected-react-router";
import boostApi from "../../apis/boostApi";
import subscriptionApi from "../../apis/subscriptionApi";
export var thunkVerify = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState())) {
            return [2 /*return*/, dispatch(push("/signin"))];
        }
        authApi
            .get("/refreshtoken", {
            withCredentials: true,
        })
            .then(function (res) {
            if (callback)
                callback(res);
        })
            .catch(function (err) {
            console.log(err);
            dispatch(unauthenticateUser());
            dispatch(thunkLogout());
            dispatch(push("/"));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLogout = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        authApi.get("/logout", {
            withCredentials: true,
        });
        dispatch(unauthenticateUser());
        dispatch(push("/"));
        return [2 /*return*/];
    });
}); }; };
export var thunkUpdateSignupInfo = function (user) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(setSignupInfo(user));
        return [2 /*return*/];
    });
}); }; };
function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    var counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
export var thunkCreateUserWithAccess = function (userEmail, userTitleId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState())) {
            return [2 /*return*/, dispatch(push("/signin"))];
        }
        authUserApi
            .post("/", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            data: {
                email: userEmail,
                password: makeid(12),
                isActive: true,
                isVerified: true,
                titleId: userTitleId,
            },
        })
            .then(function (res) {
            callback();
        })
            .catch(function (err) {
            callback(err);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkSetupProfile = function (user) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(signinInProgress());
        userApi
            .post("/basic", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            data: __assign({}, user),
        })
            .then(function (res) {
            dispatch(thunkConnectKachat(res.data.user));
            dispatch(signinSuccess(res.data));
            dispatch(push("/platform/profile"));
        })
            .catch(function (err) {
            dispatch(signinFailure(getErrorMessage(err)));
            dispatch(unauthenticateUser());
            dispatch(push("/"));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkConnectKachat = function (user) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        kachat.registerUser("".concat(user.user_first_name, " ").concat(user.user_last_name), user.user_email, function (err, kachatId) {
            if (getUserAuthenticated(getState())) {
                kachat.connectUser(function (err, res) {
                    if (err)
                        return dispatch(chatConnectFailure());
                });
            }
            if (err) {
                console.log("Count not connect to chat service");
                return dispatch(chatConnectFailure());
            }
            userApi
                .put("/updatekachat", {
                data: { kachatId: kachatId },
            })
                .then(function (res) {
                if (!user.user_active)
                    dispatch(thunkClearData());
                dispatch(signupKachatSuccess(res.data));
                dispatch(chatConnectSuccess());
            })
                .catch(function (err) {
                // dispatch(signinFailure(getErrorMessage(err)));
                dispatch(chatConnectFailure());
            });
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkSetChatServiceError = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(chatConnectFailure());
        return [2 /*return*/];
    });
}); }; };
export var thunkForgotPassword = function (email) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(forgotPassInProgress());
        authApi
            .post("/forgot", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            data: { email: email },
        })
            .then(function (res) {
            dispatch(forgotPassSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(forgotPassFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkResetPassword = function (token, password) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(resetPassInProgress());
        authApi
            .post("/reset", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            data: { password: password, token: token },
        })
            .then(function (res) {
            dispatch(resetPassSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(resetPassFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkUpdateUserData = function (user) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var formData, largeSize, smallSize, largeDataUrl, smallDataUrl, response, filepaths, res, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                dispatch(updateUserInProgress());
                if (!user.profilePicFile) return [3 /*break*/, 4];
                formData = new FormData();
                formData.append("type", "profile");
                formData.append("user", getUserId(getState()));
                largeSize = parseInt(process.env.LARGE_IMAGE_SIZE);
                smallSize = parseInt(process.env.SMALL_IMAGE_SIZE);
                return [4 /*yield*/, resizeAsync(user.profilePicFile, largeSize, largeSize)];
            case 1:
                largeDataUrl = _a.sent();
                return [4 /*yield*/, resizeAsync(user.profilePicFile, smallSize, smallSize)];
            case 2:
                smallDataUrl = _a.sent();
                formData.append("file", dataURItoBlob(largeDataUrl));
                formData.append("file", dataURItoBlob(smallDataUrl));
                return [4 /*yield*/, fileApi.post("/upload", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    })];
            case 3:
                response = _a.sent();
                filepaths = response.data.filepaths;
                user.imagePath = filepaths[0];
                user.smallImagePath = filepaths[1];
                _a.label = 4;
            case 4: return [4 /*yield*/, userApi.put("/update", {
                    headers: { "Content-Type": "application/json" },
                    method: "PUT",
                    data: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        bio: user.bio,
                        imagePath: user.imagePath,
                        smallImagePath: user.smallImagePath,
                        cityId: user.cityId,
                        countryId: user.countryId,
                        awardsArray: user.awardsArray,
                        languagesArray: user.languagesArray,
                        interestsArray: user.interestsArray,
                        projectInterestsArray: user.projectInterestsArray,
                        company: user.company,
                        position: user.position,
                        twitterUrl: user.twitter,
                        youtubeUrl: user.youtube,
                        linkedinUrl: user.linkedin,
                        typeOfAcquisition: user.typeOfAcquisition,
                        userExperience: user.userExperience,
                        fundedProjectsCount: user.fundedProjectsCount,
                        legalName: user.legalName,
                        website: user.website,
                        investmentGenres: user.investmentGenres,
                        investmentCountries: user.investmentCountries,
                        investmentTypes: user.investmentTypes,
                        investmentTickets: user.investmentTickets,
                        investmentProjects: user.investmentProjects,
                    },
                })];
            case 5:
                res = _a.sent();
                dispatch(updateUserSuccess(res.data));
                return [3 /*break*/, 7];
            case 6:
                err_1 = _a.sent();
                dispatch(updateUserFailure(getErrorMessage(err_1)));
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); }; };
export var thunkClearUserSignedup = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(clearUserSignedUp());
        return [2 /*return*/];
    });
}); }; };
// boosting and subscriptions region
export var thunkLoadUserProjectBoosts = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_2, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectBoostInProgress());
                return [4 /*yield*/, boostApi.get("/".concat(getUserId(getState())), {
                        params: {}, // limit and offset
                    })];
            case 2:
                res = _a.sent();
                dispatch(loadProjectBoostSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_2 = _a.sent();
                errMessage = getErrorMessage(err_2);
                dispatch(loadProjectBoostsFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkFindOrCreateStripeCustomer = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_3, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.post("/stripe/customer/".concat(getUserEmail(getState())), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                    })];
            case 2:
                res = _a.sent();
                dispatch(stopAuthLoading());
                return [3 /*break*/, 4];
            case 3:
                err_3 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_3);
                console.log(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadUserSubscription = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var email, isPaymentNeeded, res, err_4, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 7, , 8]);
                email = getUserEmail(getState());
                if (!email) {
                    dispatch(stopAuthLoading());
                    return [2 /*return*/];
                }
                return [4 /*yield*/, subscriptionApi.get("/paymentNeeded")];
            case 2:
                isPaymentNeeded = (_a.sent())
                    .data;
                res = void 0;
                if (!isPaymentNeeded) return [3 /*break*/, 4];
                return [4 /*yield*/, paymentApi.get("/stripe/subscription/user/".concat(getUserEmail(getState()), "/current"), {
                        headers: { "Content-Type": "application/json" },
                        method: "GET",
                    })];
            case 3:
                res = _a.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, subscriptionApi.get("/user/current/".concat(getUserEmail(getState())))];
            case 5:
                res = _a.sent();
                _a.label = 6;
            case 6:
                dispatch(loadUserSubscriptionSuccess(res.data));
                return [3 /*break*/, 8];
            case 7:
                err_4 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_4);
                console.log(errMessage);
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadUserSubscriptions = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var isPaymentNeeded, res, err_5, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 7, , 8]);
                return [4 /*yield*/, subscriptionApi.get("/paymentNeeded")];
            case 2:
                isPaymentNeeded = (_a.sent())
                    .data;
                res = void 0;
                if (!isPaymentNeeded) return [3 /*break*/, 4];
                return [4 /*yield*/, paymentApi.get("/stripe/subscription/user/".concat(getUserEmail(getState())), {
                        headers: { "Content-Type": "application/json" },
                        method: "GET",
                    })];
            case 3:
                res = _a.sent();
                return [3 /*break*/, 6];
            case 4: return [4 /*yield*/, subscriptionApi.get("/user/all/".concat(getUserEmail(getState())))];
            case 5:
                res = _a.sent();
                _a.label = 6;
            case 6:
                dispatch(loadUserSubscriptionsSuccess(res.data));
                return [3 /*break*/, 8];
            case 7:
                err_5 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_5);
                dispatch(push("/platform/error"));
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}); }; };
// export const thunkLoadUserSubscription = () => async (dispatch, getState) => {
//     dispatch(startAuthLoading());
//     try {
//         const email = getUserEmail(getState());
//         if (!email) {
//             dispatch(stopAuthLoading());
//             return;
//         }
//         const res = await paymentApi.get(
//             `/stripe/subscription/user/${getUserEmail(getState())}/current`,
//             {
//                 headers: { "Content-Type": "application/json" },
//                 method: "GET",
//             }
//         );
//         dispatch(loadUserSubscriptionSuccess(res.data));
//     } catch (err) {
//         dispatch(stopAuthLoading());
//         const errMessage = getErrorMessage(err);
//         console.log(errMessage);
//     }
// };
// export const thunkLoadUserSubscriptions = () => async (dispatch, getState) => {
//     dispatch(startAuthLoading());
//     try {
//         const res = await paymentApi.get(
//             `/stripe/subscription/user/${getUserEmail(getState())}`,
//             {
//                 headers: { "Content-Type": "application/json" },
//                 method: "GET",
//             }
//         );
//         dispatch(loadUserSubscriptionsSuccess(res.data));
//     } catch (err) {
//         dispatch(stopAuthLoading());
//         const errMessage = getErrorMessage(err);
//         dispatch(push("/platform/error"));
//     }
// };
export var thunkLoadSubscriptionPrices = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_6, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.get("/stripe/price/all", {
                        headers: { "Content-Type": "application/json" },
                        method: "GET",
                    })];
            case 2:
                res = _a.sent();
                dispatch(subscriptionPricesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_6 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_6);
                dispatch(push("/platform/error"));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkCreateSubscription = function (priceId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_7, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.post("/stripe/subscription/".concat(getUserEmail(getState())), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                        data: {
                            priceId: priceId,
                        },
                    })];
            case 2:
                res = _a.sent();
                infiniteLoadingReloadAll();
                dispatch(stopAuthLoading());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_7 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_7);
                alert(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkCreateFreeSubscription = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_8, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, subscriptionApi.post("/", {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                        data: {
                            email: getUserEmail(getState()),
                        },
                    })];
            case 2:
                res = _a.sent();
                infiniteLoadingReloadAll();
                dispatch(stopAuthLoading());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_8 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_8);
                alert(errMessage);
                callback(null, err_8);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkCancelSubscription = function (subscriptionId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_9, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.put("/stripe/subscription/".concat(subscriptionId, "/cancel"), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                    })];
            case 2:
                res = _a.sent();
                dispatch(stopAuthLoading());
                callback();
                return [3 /*break*/, 4];
            case 3:
                err_9 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_9);
                alert(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkCancelFreeSubscription = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_10, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, subscriptionApi.put("/cancel/".concat(getUserEmail(getState())))];
            case 2:
                res = _a.sent();
                infiniteLoadingReloadAll();
                dispatch(stopAuthLoading());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_10 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_10);
                alert(errMessage);
                callback(null, err_10);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkReactivateSubscription = function (subscriptionId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_11, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.put("/stripe/subscription/".concat(subscriptionId, "/reactivate"), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                    })];
            case 2:
                res = _a.sent();
                dispatch(stopAuthLoading());
                callback();
                return [3 /*break*/, 4];
            case 3:
                err_11 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_11);
                alert(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkReactivateFreeSubscription = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_12, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, subscriptionApi.put("/reactivate/".concat(getUserEmail(getState())))];
            case 2:
                res = _a.sent();
                infiniteLoadingReloadAll();
                dispatch(stopAuthLoading());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_12 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_12);
                alert(errMessage);
                callback(null, err_12);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadPaymentMethods = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_13, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(startAuthLoading());
                return [4 /*yield*/, paymentApi.get("/stripe/paymentMethod/user/".concat(getUserEmail(getState())))];
            case 2:
                res = _a.sent();
                dispatch(loadPaymentMethodsSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_13 = _a.sent();
                errMessage = getErrorMessage(err_13);
                dispatch(stopAuthLoading());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadDefaultPaymentMethod = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_14, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(startAuthLoading());
                return [4 /*yield*/, paymentApi.get("/stripe/paymentMethod/user/".concat(getUserEmail(getState()), "/default"))];
            case 2:
                res = _a.sent();
                dispatch(loadDefaultPaymentMethodSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_14 = _a.sent();
                errMessage = getErrorMessage(err_14);
                dispatch(stopAuthLoading());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkCreateSetupIntent = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_15, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.post("/stripe/setupIntent/".concat(getUserEmail(getState())), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                    })];
            case 2:
                res = _a.sent();
                dispatch(stopAuthLoading());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_15 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_15);
                alert(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkDetachPaymentMethod = function (paymentMethodId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_16, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.delete("/stripe/paymentMethod/".concat(paymentMethodId), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                    })];
            case 2:
                res = _a.sent();
                dispatch(stopAuthLoading());
                callback();
                return [3 /*break*/, 4];
            case 3:
                err_16 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_16);
                alert(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkSetDefaultPaymentMethod = function (paymentMethodId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_17, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(startAuthLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, paymentApi.put("/stripe/customer/".concat(getUserEmail(getState()), "/defaultPayment/").concat(paymentMethodId), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                    })];
            case 2:
                res = _a.sent();
                dispatch(stopAuthLoading());
                callback();
                return [3 /*break*/, 4];
            case 3:
                err_17 = _a.sent();
                dispatch(stopAuthLoading());
                errMessage = getErrorMessage(err_17);
                alert(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
