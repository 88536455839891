var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { connect } from "react-redux";
import { RiEmotionSadFill as IconEmoji } from "react-icons/ri";
import "./ErrorPage.css";
var ErrorPage = function (_a) {
    return (_jsx("div", __assign({ className: "errorpage-container" }, { children: _jsxs("div", __assign({ className: "errorpage-box" }, { children: [_jsx("div", __assign({ className: "errorpage-title" }, { children: "Something went wrong" })), _jsx("div", __assign({ className: "errorpage-text" }, { children: "We're having trouble accessing this page for you. Please try again later! Or contact us to fix this error." })), _jsx("div", __assign({ className: "errorpage-emoji" }, { children: _jsx(IconEmoji, {}) }))] })) })));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({}); };
export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
