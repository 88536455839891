var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { MdAttachFile as IconAttach } from "react-icons/md";
import { getDownloadLinkUrl } from "../../apis/fileApi";
import { getFileSizeInBytes } from "../../tools/generalTools";
import "./ProjectTextCard.css";
var ProjectTextCard = function (_a) {
    var title = _a.title, text = _a.text, _b = _a.files, files = _b === void 0 ? [] : _b, _c = _a.pathKey, pathKey = _c === void 0 ? "" : _c, _d = _a.titleKey, titleKey = _d === void 0 ? "" : _d, _e = _a.sizeKey, sizeKey = _e === void 0 ? "" : _e, _f = _a.emptyText, emptyText = _f === void 0 ? "" : _f;
    var getFileSize = function (file) {
        if (sizeKey && file[sizeKey]) {
            return getFileSizeInBytes(file[sizeKey]);
        }
        return "Unknown size";
    };
    return (_jsxs("div", __assign({ className: "projecttextcard-container" }, { children: [_jsxs("div", __assign({ className: "projecttextcard-header" }, { children: ["_", title] })), _jsxs("div", __assign({ className: "projecttextcard-content-container" }, { children: [text || !files.length ? (_jsx("div", __assign({ className: "projecttextcard-text" }, { children: text || emptyText }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "projecttextcard-attachments" }, { children: files.map(function (file, key) { return (_jsx("a", __assign({ className: "projecttextcard-attachment-action", href: file[pathKey]
                                ? getDownloadLinkUrl(file[pathKey])
                                : "", download: true }, { children: _jsxs("div", __assign({ className: "projecttextcard-attachment" }, { children: [_jsx("div", __assign({ className: "projecttextcard-attachment-icon" }, { children: _jsx(IconAttach, {}) })), _jsxs("div", __assign({ className: "projecttextcard-attachment-text-section" }, { children: [_jsx("div", __assign({ className: "projecttextcard-attachment-text" }, { children: _jsx("span", { children: file[titleKey] }) })), _jsx("div", __assign({ className: "projecttextcard-attachment-size" }, { children: getFileSize(file) }))] }))] })) }), key)); }) }))] }))] })));
};
ProjectTextCard.propTypes = {};
ProjectTextCard.defaultProps = {};
export default ProjectTextCard;
