import { getFileUrl } from "../apis/fileApi";
export var isUserScriptwriter = function (title) {
    return (title || "").toLowerCase() == "scriptwriter";
};
export var isUserFilmmaker = function (title) {
    return (title || "").toLowerCase() == "filmmaker";
};
export var isUserProducer = function (title) {
    return (title || "").toLowerCase() == "producer";
};
export var isUserInvestor = function (title) {
    return (title || "").toLowerCase() == "investor";
};
export var isUserBuyer = function (title) {
    return (title || "").toLowerCase() == "buyer";
};
export var isUserLawyer = function (title) {
    return (title || "").toLowerCase() == "lawyer";
};
export var canUserCreateProject = function (title) {
    return (isUserScriptwriter(title) |
        isUserFilmmaker(title) |
        isUserProducer(title));
};
export var getUserProfilePic = function (imagePath) {
    if (imagePath)
        return getFileUrl(imagePath);
    return "/images/no-profile.png";
};
export var hasUserInterests = function (title) {
    return (isUserScriptwriter(title) ||
        isUserFilmmaker(title) ||
        isUserProducer(title));
};
export var hasProjectInterests = function (title) {
    return isUserBuyer(title) || isUserInvestor(title) || isUserProducer(title);
};
export var getUserTitleColor = function (title) {
    return "var(--app-".concat((title || "unknown").toLowerCase(), "-color)");
};
export var getFullName = function (user) {
    return user && (user.user_first_name || user.user_last_name)
        ? user.user_first_name + " " + user.user_last_name
        : "";
};
