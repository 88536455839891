import axios from "axios";
import store from "../redux/store";
import { getToken } from "../redux/selectors/authSelectors";
var boostApi = axios.create({
    baseURL: process.env.BOOST_SERVICE_API,
    responseType: "json",
});
boostApi.defaults.timeout = parseInt(process.env.SCRIPTO_API_TIMEOUT) * 1000;
boostApi.interceptors.request.use(function (config) {
    config.headers.Authorization =
        process.env.SCRIPTO_API_KEY + "~ATK~" + getToken(store.getState());
    return config;
});
export default boostApi;
