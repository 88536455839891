var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import KButton from "../../base-components/KButton/KButton";
import KTextField from "../../base-components/KTextField/KTextField";
import { thunkClearData } from "../../redux/thunks/generalThunks";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import authApi from "../../apis/authApi";
import "./ResetPasswordPage.css";
var ResetPasswordPage = function (_a) {
    var onClearData = _a.onClearData;
    var history = useHistory();
    var _b = useState(""), password = _b[0], setPassword = _b[1];
    var _c = useState(false), token = _c[0], setToken = _c[1];
    var _d = useState(false), success = _d[0], setSuccess = _d[1];
    var _e = useState(""), errorMessage = _e[0], setErrorMessage = _e[1];
    var query = new URLSearchParams(useLocation().search);
    useDocumentTitle("Scriptofilm | Reset-pass");
    useEffect(function () {
        setToken(query.get("token"));
        setErrorMessage(query.get("message"));
        setSuccess(query.get("success") === "true");
        onClearData();
    }, []);
    return (_jsx("div", __assign({ className: "resetpasspage-container" }, { children: _jsxs("div", __assign({ className: "resetpasspage-card" }, { children: [_jsx("div", __assign({ className: "resetpasspage-logo-div" }, { children: _jsx("a", __assign({ href: "/" }, { children: _jsx("img", { className: "resetpasspage-logo", alt: "scriptofilm" }) })) })), success ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "resetpasspage-header" }, { children: "Pasword has been reset" })), _jsxs("div", __assign({ className: "resetpasspage-welcome" }, { children: [_jsx("p", { children: "You can sign in with your newly set password." }), _jsx("p", { children: "We're glad to have you back!" }), _jsx("p", __assign({ className: "resetpasspage-tosignin" }, { children: _jsx("a", __assign({ onClick: function () { return history.push("/signin"); } }, { children: "Sign in" })) }))] }))] })) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "resetpasspage-header" }, { children: "Reset your password" })), _jsxs("p", __assign({ className: "resetpasspage-error", style: {
                                display: "".concat(errorMessage ? "block" : "none"),
                            } }, { children: [_jsx("b", { children: "ERROR:" }), " ", errorMessage] })), _jsxs("form", __assign({ action: "".concat(authApi.defaults.baseURL, "/reset"), method: "post" }, { children: [_jsxs("div", __assign({ className: "resetpasspage-input-container" }, { children: [_jsx(KTextField, { label: "Password", width: "100%", type: "password", name: "password", required: true, value: password, onChange: function (event) {
                                                return setPassword(event.target.value);
                                            }, pattern: "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}", title: "Must be 6 or more characters long and should contain at least one number, one uppercase and one lowercase letter" }), _jsx("input", { type: "text", name: "token", value: token, style: { display: "none" }, readOnly: true })] })), _jsx("p", __assign({ className: "resetpasspage-disclosure" }, { children: "Type in your new password." })), _jsx(KButton, { title: "Reset Password", variant: "primary", width: "100%", height: "44px", onClick: function (f) { return f; }, type: "submit" }), _jsxs("p", __assign({ className: "resetpasspage-tosignup" }, { children: [_jsxs("a", __assign({ style: {
                                                color: "var(--app-filmmaker-color)",
                                            }, onClick: function () { return history.push("/signin"); } }, { children: [" ", "Sign in", " "] })), "or", " ", _jsxs("a", __assign({ style: {
                                                color: "var(--app-producer-color)",
                                            }, onClick: function () { return history.push("/signup"); } }, { children: [" ", "Sign up", " "] }))] }))] }))] }))] })) })));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    onClearData: function () { return dispatch(thunkClearData()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
