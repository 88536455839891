import axios from "axios";
import store from "../redux/store";
import { getToken } from "../redux/selectors/authSelectors";
var notificationApi = axios.create({
    baseURL: process.env.NOTIFICATION_SERVICE_API,
    responseType: "json",
});
notificationApi.defaults.timeout =
    parseInt(process.env.SCRIPTO_API_TIMEOUT) * 1000;
notificationApi.interceptors.request.use(function (config) {
    config.headers.Authorization =
        process.env.SCRIPTO_API_KEY + "~ATK~" + getToken(store.getState());
    return config;
});
export default notificationApi;
