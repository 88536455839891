var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { MdEdit as IconEdit } from "react-icons/md";
import { getValueInCurrency } from "../../tools/generalTools";
import "./LegalitiesCard.css";
var LegalitiesCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var user = _a.user, _l = _a.countries, countries = _l === void 0 ? [] : _l, _m = _a.projectGenres, projectGenres = _m === void 0 ? [] : _m, _o = _a.projectSubtypes, projectSubtypes = _o === void 0 ? [] : _o, _p = _a.onEdit, onEdit = _p === void 0 ? null : _p;
    var _q = useState(false), displayProjects = _q[0], setDisplayProjects = _q[1];
    var getProjectsCount = function () {
        return (user.investment_projects || "").split(",").length;
    };
    return (_jsx("div", __assign({ className: "legalitiescard-container" }, { children: _jsxs("div", __assign({ className: "legalitiescard-wrapper" }, { children: [_jsx("div", __assign({ className: "legalitiescard-edit-button" }, { children: onEdit !== null ? (_jsx(_Fragment, { children: _jsx(IconEdit, { onClick: onEdit }) })) : (_jsx(_Fragment, {})) })), _jsx("div", __assign({ className: "legalitiescard-main-text" }, { children: user.user_detail_legal_name })), _jsx("a", __assign({ className: "legalitiescard-website", href: user.user_detail_website, target: "_blank" }, { children: user.user_detail_website })), _jsx("div", __assign({ className: "legalitiescard-section-title", style: {
                        display: ((_b = user.investment_genres) === null || _b === void 0 ? void 0 : _b.length) ||
                            ((_c = user.investment_countries) === null || _c === void 0 ? void 0 : _c.length)
                            ? ""
                            : "none",
                    } }, { children: "Investment" })), _jsx("div", __assign({ className: "legalitiescard-section" }, { children: (_d = user.investment_types) === null || _d === void 0 ? void 0 : _d.split(",").map(function (id, key) {
                        var _a;
                        return (_jsx("div", __assign({ className: "legalitiescard-type-chip" }, { children: (_a = projectSubtypes === null || projectSubtypes === void 0 ? void 0 : projectSubtypes.find(function (e) { return e.project_subtype_id === parseInt(id); })) === null || _a === void 0 ? void 0 : _a.project_subtype_name }), key));
                    }) })), ((_e = user.investment_genres) === null || _e === void 0 ? void 0 : _e.length) ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "legalitiescard-section-subtitle" }, { children: "Generes" })), _jsx("div", __assign({ className: "legalitiescard-section" }, { children: (_f = user.investment_genres) === null || _f === void 0 ? void 0 : _f.split(",").map(function (id, key) {
                                var _a;
                                return (_jsx("div", __assign({ className: "legalitiescard-chip" }, { children: (_a = projectGenres === null || projectGenres === void 0 ? void 0 : projectGenres.find(function (e) {
                                        return e.project_genre_id ===
                                            parseInt(id);
                                    })) === null || _a === void 0 ? void 0 : _a.project_genre_name }), key));
                            }) }))] })) : (_jsx(_Fragment, {})), ((_g = user.investment_countries) === null || _g === void 0 ? void 0 : _g.length) ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "legalitiescard-section-subtitle" }, { children: "Countries" })), _jsx("div", __assign({ className: "legalitiescard-section" }, { children: (_h = user.investment_countries) === null || _h === void 0 ? void 0 : _h.split(",").map(function (id, key) {
                                var _a;
                                return (_jsx("div", __assign({ className: "legalitiescard-chip" }, { children: (_a = countries === null || countries === void 0 ? void 0 : countries.find(function (e) {
                                        return e.country_id ===
                                            parseInt(id);
                                    })) === null || _a === void 0 ? void 0 : _a.country_name }), key));
                            }) }))] })) : (_jsx(_Fragment, {})), ((_j = user.investment_tickets) === null || _j === void 0 ? void 0 : _j.length) ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "legalitiescard-section-subtitle" }, { children: "Ticket Sizes" })), _jsx("div", __assign({ className: "legalitiescard-section" }, { children: (_k = user.investment_tickets) === null || _k === void 0 ? void 0 : _k.split(",").sort(function (a, b) { return parseInt(a) - parseInt(b); }).map(function (value, key) { return (_jsx("div", __assign({ className: "legalitiescard-chip" }, { children: getValueInCurrency(value) }), key)); }) }))] })) : (_jsx(_Fragment, {})), getProjectsCount() ? (_jsx("div", __assign({ className: "legalitiescard-funded-count", style: {
                        flexDirection: displayProjects ? "column" : "",
                        maxWidth: displayProjects ? "100%" : "",
                    }, onClick: function () { return setDisplayProjects(!displayProjects); } }, { children: displayProjects ? (_jsx(_Fragment, { children: (user.investment_projects || "")
                            .split(",")
                            .map(function (title, key) { return (_jsx("div", __assign({ className: "legalitiescard-funded-project" }, { children: title }), key)); }) })) : (_jsxs(_Fragment, { children: [_jsx("span", { children: getProjectsCount() }), " Funded Projects"] })) }))) : (_jsx(_Fragment, {}))] })) })));
};
export default LegalitiesCard;
