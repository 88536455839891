var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { MdEdit as IconEdit } from "react-icons/md";
import { resize, dataURItoBlob } from "../../imaging/imageTransform";
import KTextField from "../../base-components/KTextField/KTextField";
import KTextArea from "../../base-components/KTextArea/KTextArea";
import KSelector from "../../base-components/KSelector/KSelector";
import KButton from "../../base-components/KButton/KButton";
import { getUserProfilePic } from "../../tools/userTools";
import EditCropImage from "../../pages/EditCropImage/EditCropImage";
import KPopup from "../../base-components/KPopup/KPopup";
import { Twitter, LinkedIn, YouTube } from "@material-ui/icons";
import Icon from "@material-ui/core/Icon";
import KRadioButton from "../../base-components/KRadioButton/KRadioButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { knowYouSchema1, knowYouSchema2, } from "../../validations/KnowYouValidation";
import "./EditUserBasicsPage.css";
import { canHaveOnlyBasics } from "../../validators/profileValidator";
var EditUserBasicsPage = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var editUserPopupOnceClosed = _a.editUserPopupOnceClosed, scrollParent = _a.scrollParent, userData = _a.userData, setUserData = _a.setUserData, cities = _a.cities, countries = _a.countries, languages = _a.languages, onSaveUpdates = _a.onSaveUpdates, user = _a.user, userTitles = _a.userTitles, onExit = _a.onExit;
    var width = "410px";
    var _h = useState([]), validCities = _h[0], setValidCities = _h[1];
    var imageSize = parseInt(process.env.LARGE_IMAGE_SIZE);
    var _j = useState("schema1"), validationSchema = _j[0], setValidationSchema = _j[1];
    var _k = useState(false), cropProfileImage = _k[0], setCropProfileImage = _k[1];
    var uploadClick = function () {
        setCropProfileImage(true);
    };
    var saveProfilePicture = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, setUserData(__assign(__assign({}, userData), { profilePicSrc: URL.createObjectURL(file), profilePicFile: file }))];
                case 1:
                    _a.sent();
                    resize(file, imageSize, imageSize, 0, function (dataUrl, passedKey) {
                        setUserData(__assign(__assign({}, userData), { profilePicSrc: dataUrl, profilePicFile: dataURItoBlob(dataUrl) }));
                    });
                    setCropProfileImage(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var _l = useForm({
        resolver: yupResolver(validationSchema === "schema1" ? knowYouSchema1 : knowYouSchema2),
    }), register = _l.register, handleSubmit = _l.handleSubmit, setValue = _l.setValue, errors = _l.formState.errors;
    var onSubmit = function (data) {
        if (userData.countryId) {
            // onSaveUpdates(editUserPopupOnceClosed ? "" : "your-experience");
            // @TODO: bring back your experience page when ready
            if (canHaveOnlyBasics()) {
                onSaveUpdates("");
                onExit();
            }
            else {
                onSaveUpdates(editUserPopupOnceClosed ? "" : "awards");
            }
        }
        else {
            alert("Please select a country to proceed.");
        }
    };
    var socialContainer = useRef();
    var userTitleFirstGroup = [6];
    var userTitleSecondGroup = [];
    userTitles.forEach(function (userTitle) {
        switch (userTitle.user_title_name) {
            case "Buyer":
                userTitleFirstGroup.push(userTitle.user_title_id);
                break;
            case "Investor":
                userTitleFirstGroup.push(userTitle.user_title_id);
                break;
            case "Producer":
                userTitleFirstGroup.push(userTitle.user_title_id);
                break;
            case "Scriptwriter":
                userTitleSecondGroup.push(userTitle.user_title_id);
                break;
            case "Filmmaker":
                userTitleSecondGroup.push(userTitle.user_title_id);
                break;
        }
    });
    var onSocialFieldFocus = function () {
        socialContainer.current.classList.add("edituserbasicspagesocial-container-border-on-focus");
    };
    var onSocialFieldBlur = function () {
        socialContainer.current.classList.remove("edituserbasicspagesocial-container-border-on-focus");
    };
    var getCities = function () {
        if (userData.countryId) {
            var country_1 = countries.find(function (i) { return i.country_id == userData.countryId; }) || {};
            return cities.filter(function (i) { return i.city_country_code == country_1.country_code; });
        }
        return cities;
    };
    var getCityName = function () {
        if (userData.cityId)
            return (getCities().find(function (i) { return i.city_id == userData.cityId; }) || {})
                .city_name;
    };
    var getCountryName = function () {
        if (userData.countryId)
            return (countries.find(function (i) { return i.country_id == userData.countryId; }) || {}).country_name;
    };
    useEffect(function () {
        if (Object.keys(user).length) {
            if (userTitleFirstGroup.includes(user.user_user_title_id_fk)) {
                setValidationSchema("schema1");
            }
            else if (userTitleSecondGroup.includes(user.user_user_title_id_fk)) {
                setValidationSchema("schema2");
            }
        }
    }, [user, userTitleFirstGroup, userTitleSecondGroup]);
    useEffect(function () {
        if (scrollParent.scrollTo)
            scrollParent.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        setValue("firstName", userData.firstName);
        setValue("lastName", userData.lastName);
        setValue("company", userData.company);
        setValue("position", userData.position);
        setValue("bio", userData.bio);
        if (userData.profilePicSrc)
            return;
        setUserData(__assign(__assign({}, userData), { profilePicSrc: getUserProfilePic(userData.smallImagePath) }));
    }, [userData]);
    return (_jsxs(_Fragment, { children: [_jsx(KPopup, __assign({ active: cropProfileImage, onClose: function () { return setCropProfileImage(false); } }, { children: _jsx("div", __assign({ className: "edituserbasicpage-cropper-container" }, { children: cropProfileImage && (_jsx(EditCropImage, { image: userData.imagePath || {}, aspectRatio: 1, cropShape: "round", onSaveImage: function (img) { return saveProfilePicture(img); } })) })) })), _jsxs("div", __assign({ className: "edituserbasicspage-container" }, { children: [_jsx("div", __assign({ className: "edituserbasicspage-title" }, { children: "Let's get to know you better" })), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "edituserbasicspage-content" }, { children: [_jsx("div", __assign({ className: "edituserbasicspage-pic-section" }, { children: _jsxs("div", __assign({ className: "edituserbasicspage-pic-container" }, { children: [_jsx("img", { className: "edituserbasicspage-profile-pic", alt: "profile-pic", src: userData.profilePicSrc ||
                                                        getUserProfilePic(userData.smallImagePath) }), _jsxs("div", __assign({ className: "edituserbasicspage-edit-icon-container" }, { children: [_jsx(IconEdit, { className: "edituserbasicspage-edit-icon" }), _jsx("input", { type: "button", className: "edituserbasicspage-image-input", 
                                                            // accept="image/*"
                                                            onClick: function () { return uploadClick(); } })] }))] })) })), _jsxs("div", __assign({ className: "edituserbasicspage-info-section" }, { children: [_jsxs("div", __assign({ className: "edituserbasicspage-row" }, { children: [_jsx(KTextField, { placeholder: "First Name", label: "First Name", width: width, marginVertical: "10px", marginRight: "20px", extra: register("firstName", {
                                                            onChange: function (e) {
                                                                setUserData(__assign(__assign({}, userData), { firstName: e.target.value }));
                                                            },
                                                        }), errorMessage: (_b = errors.firstName) === null || _b === void 0 ? void 0 : _b.message }), _jsx(KTextField, { placeholder: "Last Name", label: "Last Name", width: width, marginVertical: "10px", marginRight: "20px", extra: register("lastName", {
                                                            onChange: function (e) {
                                                                return setUserData(__assign(__assign({}, userData), { lastName: e.target.value }));
                                                            },
                                                        }), errorMessage: (_c = errors.lastName) === null || _c === void 0 ? void 0 : _c.message })] })), userTitleFirstGroup.includes(user.user_title_id) && (_jsxs("div", __assign({ className: "edituserbasicspage-row" }, { children: [_jsx(KTextField, { placeholder: "Company Name", label: "Company Name", width: width, marginVertical: "10px", marginRight: "20px", extra: register("company", {
                                                            onChange: function (e) {
                                                                return setUserData(__assign(__assign({}, userData), { company: e.target.value }));
                                                            },
                                                        }), errorMessage: (_d = errors.company) === null || _d === void 0 ? void 0 : _d.message }), _jsx(KTextField, { placeholder: "Position", label: "Position", width: width, marginVertical: "10px", marginRight: "20px", extra: register("position", {
                                                            onChange: function (e) {
                                                                return setUserData(__assign(__assign({}, userData), { position: e.target.value }));
                                                            },
                                                        }), errorMessage: (_e = errors.position) === null || _e === void 0 ? void 0 : _e.message })] }))), _jsxs("div", __assign({ className: "edituserbasicspage-row" }, { children: [_jsx(KSelector, { identifier: "1", placeholder: "Country", label: "Country", width: width, marginVertical: "10px", marginRight: "20px", items: countries, titleKey: "country_name", scrollParent: scrollParent, onChange: function (item) {
                                                            setUserData(__assign(__assign({}, userData), { countryId: item === null || item === void 0 ? void 0 : item.country_id }));
                                                        }, selectedValue: getCountryName() }), _jsx(KSelector, { identifier: "0", placeholder: "City", label: "City", width: width, marginVertical: "10px", marginRight: "20px", items: getCities(), titleKey: "city_name", scrollParent: scrollParent, onChange: function (item) {
                                                            setUserData(__assign(__assign({}, userData), { cityId: item === null || item === void 0 ? void 0 : item.city_id }));
                                                        }, selectedValue: getCityName() })] }))] }))] })), _jsx("div", __assign({ className: "edituserbasicspage-extra-content" }, { children: _jsxs("div", __assign({ className: "edituserbasicspage-extra-section" }, { children: [user.user_title_id === 5 && (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "edituserbasicspage-social-network-fields-title", style: {
                                                        marginLeft: "92px",
                                                        marginBottom: "-10px",
                                                    } }, { children: "Type of Aquisition" })), _jsxs("div", __assign({ className: "edituserbasicspage-row edituserbasicspage-aquisition-type-row" }, { children: [_jsxs("div", __assign({ className: "edituserbasicspage-aquisition-type-container" }, { children: [_jsx("div", __assign({ onClick: function () {
                                                                        return setUserData(__assign(__assign({}, userData), { typeOfAcquisition: "cinema" }));
                                                                    } }, { children: _jsx(KRadioButton, { fontSize: "12px", title: "CINEMA", selected: userData.typeOfAcquisition ===
                                                                            "cinema" }) })), _jsx("div", __assign({ onClick: function () {
                                                                        return setUserData(__assign(__assign({}, userData), { typeOfAcquisition: "tv" }));
                                                                    } }, { children: _jsx(KRadioButton, { fontSize: "12px", title: "TV", selected: userData.typeOfAcquisition ===
                                                                            "tv" }) })), _jsx("div", __assign({ onClick: function () {
                                                                        return setUserData(__assign(__assign({}, userData), { typeOfAcquisition: "vod" }));
                                                                    } }, { children: _jsx(KRadioButton, { fontSize: "12px", title: "VOD", selected: userData.typeOfAcquisition ===
                                                                            "vod" }) }))] })), _jsx("div", { className: "edituserbasicspage-aquisition-type-neighbor" })] }))] })), _jsx("div", __assign({ className: "edituserbasicspage-extra-row" }, { children: _jsx(KSelector, { identifier: "2", placeholder: "Languages", label: "Languages", width: "1000px", multiple: true, marginVertical: "10px", marginRight: "20px", items: languages, titleKey: "language_name", scrollParent: scrollParent, itemKey: "language_id", onChange: function (items) {
                                                    return setUserData(__assign(__assign({}, userData), { languagesArray: items }));
                                                }, selectedItems: userData.languagesArray }) })), _jsx("div", __assign({ className: "edituserbasicspage-extra-row" }, { children: _jsx(KTextArea, { placeholder: userTitleSecondGroup.includes(user.user_title_id)
                                                    ? "Start typing your bio in.."
                                                    : "Start typing your company profile in..", marginRight: "20px", marginVertical: "10px", label: userTitleSecondGroup.includes(user.user_title_id)
                                                    ? "Bio (60 words max)"
                                                    : "Company profile (60 words max)", extra: register("bio", {
                                                    onChange: function (e) {
                                                        return setUserData(__assign(__assign({}, userData), { bio: e.target.value }));
                                                    },
                                                }), errorMessage: ((_f = errors.bio) === null || _f === void 0 ? void 0 : _f.message) &&
                                                    ((_g = errors.bio) === null || _g === void 0 ? void 0 : _g.message) }) })), _jsx("p", __assign({ className: "edituserbasicspage-social-network-fields-title" }, { children: "Social networks" })), _jsxs("div", __assign({ className: "edituserbasicspage-social-container", ref: socialContainer }, { children: [_jsxs("div", __assign({ className: "edituserbasicspage-social edituserbasicspage-social-twitter" }, { children: [_jsx("div", __assign({ className: "edituserbasicspage-social-icon" }, { children: _jsx(Icon, { component: Twitter, color: "inherit", fontSize: "large" }) })), _jsx("div", __assign({ className: "edituserbasicspage-social-textfield" }, { children: _jsx("input", { value: userData.twitter || "", type: "text", placeholder: "twitter link", onChange: function (event) {
                                                                    return setUserData(__assign(__assign({}, userData), { twitter: event.target.value }));
                                                                }, onFocusCapture: onSocialFieldFocus, onBlurCapture: onSocialFieldBlur }) }))] })), _jsxs("div", __assign({ className: "edituserbasicspage-social" }, { children: [_jsx("div", __assign({ className: "edituserbasicspage-social-icon" }, { children: _jsx(Icon, { component: YouTube, color: "inherit", fontSize: "large" }) })), _jsx("div", __assign({ className: "edituserbasicspage-social-textfield" }, { children: _jsx("input", { value: userData.youtube || "", placeholder: "youtube link", type: "text", onChange: function (event) {
                                                                    return setUserData(__assign(__assign({}, userData), { youtube: event.target.value }));
                                                                }, onFocusCapture: onSocialFieldFocus, onBlurCapture: onSocialFieldBlur }) }))] })), _jsxs("div", __assign({ className: "edituserbasicspage-social edituserbasicspage-social-linkedin" }, { children: [_jsx("div", __assign({ className: "edituserbasicspage-social-icon" }, { children: _jsx(Icon, { component: LinkedIn, color: "inherit", fontSize: "large" }) })), _jsx("div", __assign({ className: "edituserbasicspage-social-textfield" }, { children: _jsx("input", { value: userData.linkedin || "", placeholder: "linkedin link", type: "text", onChange: function (event) {
                                                                    return setUserData(__assign(__assign({}, userData), { linkedin: event.target.value }));
                                                                }, onFocus: onSocialFieldFocus, onBlur: onSocialFieldBlur }) }))] }))] })), _jsx(KButton, { title: !editUserPopupOnceClosed &&
                                                !user.user_country_id_fk
                                                ? canHaveOnlyBasics()
                                                    ? "Done"
                                                    : "Next | Your Experience"
                                                : "Save", variant: "primary", marginVertical: "10px", onClick: function (f) { return f; }, type: "submit" })] })) }))] }))] }))] }));
};
export default EditUserBasicsPage;
