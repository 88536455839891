var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import KClampBox from "../../base-components/KClampBox/KClampBox";
import KImage from "../../base-components/KImage/KImage";
import { getUserProfilePic } from "../../tools/userTools";
import "./ProjectCharactersCard.css";
var ProjectCharactersCard = function (_a) {
    var _b = _a.characters, characters = _b === void 0 ? [] : _b;
    return (_jsx("div", __assign({ className: "projectcharacterscard-container" }, { children: characters.length ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "projectcharacterscard-header" }, { children: "_characters" })), _jsx("div", __assign({ className: "projectcharacterscard-content-container" }, { children: characters.map(function (character, key) { return (_jsxs("div", __assign({ className: "projectcharacterscard-character" }, { children: [_jsxs("div", __assign({ className: "projectcharacterscard-character-section" }, { children: [_jsx("div", __assign({ className: "projectcharacterscard-character-image" }, { children: _jsx(KImage, { imgSrc: getUserProfilePic(character.character_image_path), smallImgSrc: getUserProfilePic(character.character_small_image_path), rounded: true }) })), _jsx("div", __assign({ className: "projectcharacterscard-character-name" }, { children: character.character_name }))] })), _jsxs("div", __assign({ className: "projectcharacterscard-character-brief" }, { children: [_jsx("div", { className: "projectcharacterscard-character-seperator" }), _jsx(KClampBox, { text: character.character_brief, buttonColor: "var(--app-loadeduser-color)" })] }))] }), key)); }) }))] })) : (_jsx(_Fragment, {})) })));
};
ProjectCharactersCard.propTypes = {};
ProjectCharactersCard.defaultProps = {};
export default ProjectCharactersCard;
