var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createDealInProgress, createDealSuccess, createDealFailure, updateNdaSuccess, updateNdaFailure, updateNdaInProgress, loadNdasInProgress, loadNdasSuccess, loadNdasFailure, loadDealsInProgress, loadDealsSuccess, loadDealsFailure, } from "../actions/dealActions";
import dealApi from "../../apis/dealApi";
import fileApi from "../../apis/fileApi";
import { getErrorMessage } from "../utils";
import { getUserAuthenticated, getUserId } from "../selectors/authSelectors";
export var thunkLoadUserDeals = function (userId, silent) {
    if (silent === void 0) { silent = false; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!getUserAuthenticated(getState()))
                return [2 /*return*/];
            if (!silent)
                dispatch(loadDealsInProgress());
            dealApi
                .get("/userdeals/", {
                params: { userId: userId },
            })
                .then(function (res) {
                dispatch(loadDealsSuccess(res.data));
            })
                .catch(function (err) {
                dispatch(loadDealsFailure(getErrorMessage(err)));
            });
            return [2 /*return*/];
        });
    }); };
};
export var thunkCreateDeal = function (userId, projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(createDealInProgress());
        dealApi
            .post("/create", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            data: {
                userId: userId,
                projectId: projectId,
                initiatorUserId: getUserId(getState()),
            },
        })
            .then(function (res) {
            dispatch(thunkLoadUserDeals(getUserId(getState())));
            dispatch(createDealSuccess());
        })
            .catch(function (err) {
            alert("Failed to create deal request: " + getErrorMessage(err));
            dispatch(createDealFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadUserNdas = function (userId, silent) {
    if (silent === void 0) { silent = false; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!getUserAuthenticated(getState()))
                return [2 /*return*/];
            if (!silent)
                dispatch(loadNdasInProgress());
            dealApi
                .get("/userndas/", {
                params: { userId: userId },
            })
                .then(function (res) {
                dispatch(loadNdasSuccess(res.data));
            })
                .catch(function (err) {
                dispatch(loadNdasFailure(getErrorMessage(err)));
            });
            return [2 /*return*/];
        });
    }); };
};
export var thunkRequestNda = function (userId, projectId, projectUserId, file, channelId) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, response, filePath, res, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    if (!file)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    dispatch(updateNdaInProgress());
                    formData = new FormData();
                    formData.append("type", "nda");
                    formData.append("user", projectUserId);
                    formData.append("file", file);
                    return [4 /*yield*/, fileApi.post("/upload", formData, {
                            headers: { "Content-Type": "multipart/form-data" },
                        })];
                case 2:
                    response = _a.sent();
                    filePath = response.data.filepaths[0];
                    return [4 /*yield*/, dealApi.post("/requestnda", {
                            headers: { "Content-Type": "application/json" },
                            method: "POST",
                            data: { userId: userId, projectId: projectId, channelId: channelId, ndaPath: filePath },
                        })];
                case 3:
                    res = _a.sent();
                    dispatch(thunkLoadUserNdas(getUserId(getState())));
                    dispatch(updateNdaSuccess(res.data));
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    alert(getErrorMessage(err_1));
                    dispatch(updateNdaFailure(getErrorMessage(err_1)));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkSignNda = function (userId, projectId, projectUserId, file, channelId) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, response, filePath, res, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    if (!file)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    dispatch(updateNdaInProgress());
                    formData = new FormData();
                    formData.append("type", "signed-nda");
                    formData.append("user", projectUserId);
                    formData.append("file", file);
                    return [4 /*yield*/, fileApi.post("/upload", formData, {
                            headers: { "Content-Type": "multipart/form-data" },
                        })];
                case 2:
                    response = _a.sent();
                    filePath = response.data.filepaths[0];
                    return [4 /*yield*/, dealApi.put("/signnda", {
                            headers: { "Content-Type": "application/json" },
                            method: "PUT",
                            data: { userId: userId, projectId: projectId, channelId: channelId, signedNdaPath: filePath },
                        })];
                case 3:
                    res = _a.sent();
                    dispatch(thunkLoadUserNdas(getUserId(getState())));
                    dispatch(updateNdaSuccess(res.data));
                    return [3 /*break*/, 5];
                case 4:
                    err_2 = _a.sent();
                    dispatch(updateNdaFailure(getErrorMessage(err_2)));
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkUpdateNdaTemplate = function (userId, projectId, projectUserId, file) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var formData, response, filePath, res, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (!file)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                dispatch(updateNdaInProgress());
                formData = new FormData();
                formData.append("type", "nda");
                formData.append("user", projectUserId);
                formData.append("file", file);
                return [4 /*yield*/, fileApi.post("/upload", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    })];
            case 2:
                response = _a.sent();
                filePath = response.data.filepaths[0];
                return [4 /*yield*/, dealApi.put("/updatenda", {
                        headers: { "Content-Type": "application/json" },
                        method: "PUT",
                        data: { userId: userId, projectId: projectId, ndaPath: filePath },
                    })];
            case 3:
                res = _a.sent();
                dispatch(thunkLoadUserNdas(getUserId(getState())));
                dispatch(updateNdaSuccess(res.data));
                alert("Nda updated successfully");
                return [3 /*break*/, 5];
            case 4:
                err_3 = _a.sent();
                dispatch(updateNdaFailure(getErrorMessage(err_3)));
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); }; };
export var thunkApproveNda = function (userId, projectId, giveAccess, expiryDate, channelId) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!getUserAuthenticated(getState()))
                return [2 /*return*/];
            dispatch(updateNdaInProgress());
            dealApi
                .put("/approvenda", {
                headers: { "Content-Type": "application/json" },
                method: "PUT",
                data: { userId: userId, projectId: projectId, giveAccess: giveAccess, expiryDate: expiryDate, channelId: channelId },
            })
                .then(function (res) {
                dispatch(thunkLoadUserNdas(getUserId(getState())));
                dispatch(updateNdaSuccess(res.data));
            })
                .catch(function (err) {
                dispatch(updateNdaFailure(getErrorMessage(err)));
            });
            return [2 /*return*/];
        });
    }); };
};
export var thunkRejectNdaSignature = function (userId, projectId, channelId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(updateNdaInProgress());
        dealApi
            .put("/rejectndasignature", {
            headers: { "Content-Type": "application/json" },
            method: "PUT",
            data: { userId: userId, projectId: projectId, channelId: channelId },
        })
            .then(function (res) {
            dispatch(thunkLoadUserNdas(getUserId(getState())));
            dispatch(updateNdaSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(updateNdaFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
