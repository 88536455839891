var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { connect } from "react-redux";
import { RiFileList3Line as IconContract } from "react-icons/ri";
import { getCountries, getLanguages, } from "../../redux/selectors/generalSelectors";
import KImage from "../../base-components/KImage/KImage";
import KButton from "../../base-components/KButton/KButton";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { BsFillBookmarkFill as IconSigned } from "react-icons/bs";
import { thunkGetSignatureRequestsByContract, thunkLoadContracts, } from "../../redux/thunks/contractThunks";
import { getContracts, getSignatureRequestsByContract, } from "../../redux/selectors/contractSelectors";
import { getUser } from "../../redux/selectors/authSelectors";
import { canCreateContract, canSignContract, } from "../../validators/contractValidator";
import { BsFillExclamationCircleFill as IconWarning } from "react-icons/bs";
import { getUserProfilePic, getUserTitleColor } from "../../tools/userTools";
import { getContractUsers } from "../../redux/selectors/userSelectors";
import { thunkLoadUsersByAuthIds } from "../../redux/thunks/userThunks";
import { isAuthUserFilmmaker, isAuthUserScriptwriter, } from "../../validators/profileValidator";
import { FaPlus as IconAdd } from "react-icons/fa";
import { history } from "../../redux/store";
import "./ContractsPage.css";
var ContractUser = function (_a) {
    var user = _a.user;
    return (_jsxs("div", __assign({ className: "contractspage-contract-user" }, { children: [_jsx("div", __assign({ className: "contractspage-user-image", style: {
                    borderColor: getUserTitleColor(user === null || user === void 0 ? void 0 : user.user_title_name),
                } }, { children: _jsx(KImage, { imgSrc: user
                        ? getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_image_path)
                        : "/images/transparent.png", smallImgSrc: user
                        ? getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_small_image_path)
                        : "/images/transparent.png" }) })), _jsxs("div", __assign({ className: "contractspage-user-info" }, { children: [_jsx("div", __assign({ className: "contractspage-user-info-line-1" }, { children: user && (user.user_first_name || user.user_last_name)
                            ? user.user_first_name + " " + user.user_last_name
                            : "" })), _jsx("div", __assign({ className: "contractspage-user-info-line-2" }, { children: user && user.user_title_name ? user.user_title_name : "" }))] }))] })));
};
var ContractLawyer = function (_a) {
    var user = _a.user;
    return (_jsxs("div", __assign({ className: "contractspage-contract-lawyer" }, { children: [_jsx("div", __assign({ className: "contractspage-lawyer-image", style: {
                    borderColor: getUserTitleColor(user === null || user === void 0 ? void 0 : user.user_title_name),
                } }, { children: _jsx(KImage, { imgSrc: user
                        ? getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_image_path)
                        : "/images/transparent.png", smallImgSrc: user
                        ? getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_small_image_path)
                        : "/images/transparent.png" }) })), _jsxs("div", __assign({ className: "contractspage-lawyer-info" }, { children: [_jsx("div", __assign({ className: "contractspage-lawyer-info-line-1" }, { children: user && (user.user_first_name || user.user_last_name)
                            ? user.user_first_name + " " + user.user_last_name
                            : "" })), _jsx("div", __assign({ className: "contractspage-lawyer-info-line-2" }, { children: user && user.user_title_name
                            ? user.user_title_name.toUpperCase()
                            : "" }))] }))] })));
};
var ContractsPage = function (_a) {
    var authUser = _a.authUser, contracts = _a.contracts, loadedUsers = _a.loadedUsers, requestsByContract = _a.requestsByContract, onLoadContracts = _a.onLoadContracts, onLoadUsersByAuthIds = _a.onLoadUsersByAuthIds, onLoadRequestsByContract = _a.onLoadRequestsByContract;
    var createRequest = function () { return history.push("/platform/contract-request"); };
    useDocumentTitle("Scriptofilm | Contracts");
    useEffect(function () {
        Promise.all([onLoadContracts(), onLoadRequestsByContract()]);
    }, []);
    useEffect(function () {
        if (!contracts)
            return;
        if (contracts.length === 0 && canCreateContract()) {
            history.push("/platform/contract-request");
        }
        var authIds = [];
        for (var _i = 0, _a = contracts || []; _i < _a.length; _i++) {
            var contract = _a[_i];
            authIds.push(contract.lawyerId);
            for (var _b = 0, _c = contract.userIds; _b < _c.length; _b++) {
                var userId = _c[_b];
                authIds.push(userId);
            }
        }
        onLoadUsersByAuthIds(authIds);
    }, [contracts === null || contracts === void 0 ? void 0 : contracts.length]);
    return (_jsxs("div", __assign({ className: "contractspage-container" }, { children: [_jsxs("div", __assign({ className: "contractspage-header" }, { children: [_jsx("div", __assign({ className: "contractspage-title-icon" }, { children: _jsx(IconContract, {}) })), _jsx("div", __assign({ className: "contractspage-title-text" }, { children: "Contracts" })), _jsx("div", __assign({ className: "contractspage-create-btn", style: { display: canCreateContract() ? "block" : "none" } }, { children: _jsx(KButton, { title: "Create", variant: "primary", icon: IconAdd, onClick: createRequest }) }))] })), _jsxs("div", __assign({ className: "contractspage-intro" }, { children: [_jsx("p", __assign({ className: "contractspage-intro-1" }, { children: "All contracts you start on any given project will be listed here." })), canSignContract() && (_jsx("p", __assign({ className: "contractspage-intro-2" }, { children: "Click on the box below to access the contract page. You'll be working with a lawyer to move forward from a first draft to a last signed contract." }))), isAuthUserFilmmaker() || isAuthUserScriptwriter() ? (_jsxs("div", __assign({ className: "contractspage-need-help" }, { children: ["Need help?", _jsx("span", __assign({ onClick: function () { return history.push("/contactus"); } }, { children: "Contact scriptofilm" }))] }))) : (_jsx(_Fragment, {}))] })), _jsx("div", __assign({ className: "contractspage-wrapper" }, { children: contracts && contracts.length ? (contracts.map(function (contract, key) { return (_jsxs("div", __assign({ className: "contractspage-contract-card", onClick: function () {
                        return history.push("/platform/contract/".concat(contract.id));
                    } }, { children: [_jsxs("div", __assign({ className: "contractspage-contract-header" }, { children: [_jsx("div", __assign({ className: "contractspage-contract-image", style: {
                                        display: contract.signedAt !== null
                                            ? "block"
                                            : "none",
                                    } }, { children: _jsx(IconSigned, {}) })), _jsx("div", __assign({ className: "contractspage-contract-title" }, { children: contract.title })), _jsx(ContractLawyer, { user: loadedUsers[contract.lawyerId] })] })), _jsx("div", __assign({ className: "contractspage-contract-summary" }, { children: _jsx("div", __assign({ className: "contractspage-contract-description" }, { children: contract.summary })) })), _jsx("div", __assign({ className: "contractspage-contract-parties" }, { children: contract.userIds
                                .filter(function (userId) {
                                return parseInt(userId) !==
                                    authUser.user_auth_id;
                            })
                                .map(function (userId, key) { return (_jsx(ContractUser, { user: loadedUsers[userId] }, key)); }) }))] }), key)); })) : (_jsxs("div", __assign({ className: "contractspage-no-revisions", onClick: canCreateContract() ? createRequest : function (f) { return f; } }, { children: [_jsx("div", __assign({ className: "contractspage-no-revisions-icon" }, { children: _jsx(IconWarning, {}) })), _jsx("div", __assign({ className: "contractspage-no-revisions-text" }, { children: canCreateContract()
                                ? "You don't have any contracts yet. Start by creating your first one!"
                                : "You don't have any contracts yet. They will appear here once they're created!" }))] }))) }))] })));
};
var mapStateToProps = function (state) { return ({
    countries: getCountries(state),
    languages: getLanguages(state),
    contracts: getContracts(state),
    loadedUsers: getContractUsers(state),
    requestsByContract: getSignatureRequestsByContract(state),
    authUser: getUser(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadContracts: function () { return dispatch(thunkLoadContracts()); },
    onLoadRequestsByContract: function () {
        return dispatch(thunkGetSignatureRequestsByContract());
    },
    onLoadUsersByAuthIds: function (authIds) {
        return dispatch(thunkLoadUsersByAuthIds(authIds));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ContractsPage);
