var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { setColorMode, setPrimaryColor, loadUserTitlesInProgress, loadUserTitlesSuccess, loadUserTitlesFailure, loadProjectKindsInProgress, loadProjectKindsSuccess, loadProjectKindsFailure, loadProjectGenresInProgress, loadProjectGenresSuccess, loadProjectGenresFailure, loadProjectTypesInProgress, loadProjectTypesSuccess, loadProjectTypesFailure, loadProjectSubtypesInProgress, loadProjectSubtypesSuccess, loadProjectSubtypesFailure, loadProjectStagesInProgress, loadProjectStagesSuccess, loadProjectStagesFailure, loadLanguagesInProgress, loadLanguagesSuccess, loadLanguagesFailure, loadCountriesInProgress, loadCountriesSuccess, loadCountriesFailure, loadCitiesInProgress, loadCitiesSuccess, loadCitiesFailure, stopGeneralLoading, setProgress, setProgressStatus, } from "../actions/generalActions";
import { getErrorMessage } from "../utils";
import generalApi from "../../apis/generalApi";
import defaultApi from "../../apis/defaultApi";
import { clearUserData, startAuthLoading, stopAuthLoading, } from "../actions/authActions";
import { clearUsersData, stopUserLoading } from "../actions/userActions";
import { clearProjectsData, stopProjectLoading, } from "../actions/projectActions";
import { getCities, getCountries, getLanguages, getProjectGenres, getProjectSubtypes, getProjectTypes, getUserTitles, getProjectKinds, } from "../selectors/generalSelectors";
import { getUserAuthenticated } from "../selectors/authSelectors";
import { stopDealsLoading, stopNdasLoading } from "../actions/dealActions";
export var thunkSetColorMode = function (colorMode) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(setColorMode(colorMode));
        return [2 /*return*/];
    });
}); }; };
export var thunkSetPrimaryColor = function (primaryColor) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(setPrimaryColor(primaryColor));
        return [2 /*return*/];
    });
}); }; };
export var thunkSetProgress = function (progress) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(setProgress(progress));
        return [2 /*return*/];
    });
}); }; };
export var thunkSetProgressStatus = function (progressStatus) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(setProgressStatus(progressStatus));
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadUserTitles = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (getUserTitles(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadUserTitlesInProgress());
                return [4 /*yield*/, defaultApi.get("/usertitles")];
            case 2:
                res = _a.sent();
                dispatch(loadUserTitlesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_1 = _a.sent();
                dispatch(loadUserTitlesFailure(getErrorMessage(err_1)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectTypes = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (getProjectTypes(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectTypesInProgress());
                return [4 /*yield*/, generalApi.get("/projecttypes")];
            case 2:
                res = _a.sent();
                dispatch(loadProjectTypesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_2 = _a.sent();
                dispatch(loadProjectTypesFailure(getErrorMessage(err_2)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectSubtypes = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (getProjectSubtypes(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectSubtypesInProgress());
                return [4 /*yield*/, generalApi.get("/projectsubtypes")];
            case 2:
                res = _a.sent();
                dispatch(loadProjectSubtypesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_3 = _a.sent();
                dispatch(loadProjectSubtypesFailure(getErrorMessage(err_3)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectKinds = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (getProjectKinds(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectKindsInProgress());
                return [4 /*yield*/, generalApi.get("/projectkinds")];
            case 2:
                res = _a.sent();
                dispatch(loadProjectKindsSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_4 = _a.sent();
                dispatch(loadProjectKindsFailure(getErrorMessage(err_4)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectGenres = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (getProjectGenres(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectGenresInProgress());
                return [4 /*yield*/, generalApi.get("/projectgenres")];
            case 2:
                res = _a.sent();
                dispatch(loadProjectGenresSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_5 = _a.sent();
                dispatch(loadProjectGenresFailure(getErrorMessage(err_5)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectStages = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectStagesInProgress());
                return [4 /*yield*/, generalApi.get("/projectstages")];
            case 2:
                res = _a.sent();
                dispatch(loadProjectStagesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_6 = _a.sent();
                dispatch(loadProjectStagesFailure(getErrorMessage(err_6)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadLanguages = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (getLanguages(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadLanguagesInProgress());
                return [4 /*yield*/, generalApi.get("/languages")];
            case 2:
                res = _a.sent();
                dispatch(loadLanguagesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_7 = _a.sent();
                dispatch(loadLanguagesFailure(getErrorMessage(err_7)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadCountries = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (getCountries(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadCountriesInProgress());
                return [4 /*yield*/, generalApi.get("/countries")];
            case 2:
                res = _a.sent();
                dispatch(loadCountriesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_8 = _a.sent();
                dispatch(loadCountriesFailure(getErrorMessage(err_8)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadCities = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (getCities(getState()).length > 0)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadCitiesInProgress());
                return [4 /*yield*/, generalApi.get("/cities")];
            case 2:
                res = _a.sent();
                dispatch(loadCitiesSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_9 = _a.sent();
                dispatch(loadCitiesFailure(getErrorMessage(err_9)));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkClearData = function () { return function (dispatch, getState) {
    dispatch(clearProjectsData());
    dispatch(clearUsersData());
    dispatch(clearUserData());
}; };
export var thunkStartLoading = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(startAuthLoading());
        return [2 /*return*/];
    });
}); }; };
export var thunkStopLoading = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(stopAuthLoading());
        dispatch(stopDealsLoading());
        dispatch(stopNdasLoading());
        dispatch(stopGeneralLoading());
        dispatch(stopProjectLoading());
        dispatch(stopUserLoading());
        dispatch(setProgress(0));
        return [2 /*return*/];
    });
}); }; };
