var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { BsLightningFill as IconBoost } from "react-icons/bs";
import { IoMdCalendar as IconCalendar } from "react-icons/io";
import { FaPlus as IconAdd } from "react-icons/fa";
import { BiCheck as IconCheck } from "react-icons/bi";
import { BiRefresh as IconInProcess } from "react-icons/bi";
import { BsFillExclamationCircleFill as IconWarning } from "react-icons/bs";
import { thunkLoadUserBoostedProjects } from "../../redux/thunks/projectThunks";
import { thunkLoadUserProjectBoosts } from "../../redux/thunks/authThunks";
import { getUserProjectBoosts } from "../../redux/selectors/authSelectors";
import { getReadableDate } from "../../tools/generalTools";
import PaymentCompletePage from "../PaymentCompletePage/PaymentCompletePage";
import KButton from "../../base-components/KButton/KButton";
import { history } from "../../redux/store";
import "./BoostsPage.css";
var BoostsPage = function (_a) {
    var boosts = _a.boosts, onLoadProjectBoosts = _a.onLoadProjectBoosts, onLoadUserBoosts = _a.onLoadUserBoosts;
    var location = useLocation();
    var query = new URLSearchParams(location.search);
    var _b = useState(false), paymentCompletedOn = _b[0], setPaymentCompletedOn = _b[1];
    var _c = useState(""), boostTitle = _c[0], setBoostTitle = _c[1];
    var handleResize = function () {
        setBoostTitle(window.innerWidth < 600 ? "" : "Add Boost");
    };
    var getIsCompleted = function (boost) {
        return (new Date().getTime() >
            new Date(boost.project_boost_end_date).getTime() +
                24 * 60 * 60 * 1000);
    };
    var getCompletePerc = function (boost) {
        return parseInt(100 *
            Math.max(0, Math.min((new Date().getTime() -
                new Date(boost.project_boost_start_date).getTime()) /
                (24 * 60 * 60 * 1000 +
                    new Date(boost.project_boost_end_date).getTime() -
                    new Date(boost.project_boost_start_date).getTime()), 1)));
    };
    useEffect(function () {
        handleResize();
        onLoadUserBoosts();
        onLoadProjectBoosts();
        window.addEventListener("resize", handleResize);
        setPaymentCompletedOn(query.get("paymentCompleted") === "true");
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsx("div", __assign({ className: "boostspage-container" }, { children: paymentCompletedOn ? (_jsx(PaymentCompletePage, { amount: query.get("amount"), title: query.get("freeMode") === "true"
                ? "Boosting purchased for free"
                : "Thank you for your payment", feesTitle: query.get("freeMode") === "true"
                ? "Covered Charges"
                : "Paid Fees", description: "Congratulations, your project has been boosted and will be showcased in the market.", onClose: function () {
                onLoadProjectBoosts();
                setPaymentCompletedOn(false);
                window.history.replaceState(null, "", "/platform/boosts");
            } })) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "boostspage-title" }, { children: [_jsx("div", __assign({ className: "boostspage-title-icon" }, { children: _jsx(IconBoost, {}) })), _jsx("div", __assign({ className: "boostspage-title-text" }, { children: "Running boosts" })), _jsx(KButton, { title: boostTitle, variant: "primary", icon: IconAdd, onClick: function () { return history.push("/platform/boosts/new"); } })] })), boosts.length ? (_jsx("div", __assign({ className: "boostspage-wrapper" }, { children: boosts.map(function (boost, key) { return (_jsxs("div", __assign({ className: "boostspage-boost-box" }, { children: [_jsx("div", { className: "boostspage-boost-box-overlap", style: {
                                    width: "".concat(getCompletePerc(boost), "%"),
                                } }), _jsx("div", __assign({ className: "boostspage-calendar-title-text" }, { children: boost.project_title })), _jsxs("div", __assign({ className: "boostspage-boost-box-data" }, { children: [_jsx("div", __assign({ className: "boostspage-calendar-icon" }, { children: _jsx(IconCalendar, {}) })), _jsxs("div", __assign({ className: "boostspage-calendar-date-text" }, { children: [getReadableDate(boost.project_boost_start_date), " ", "---", " ", getReadableDate(boost.project_boost_end_date)] })), _jsxs("div", __assign({ className: "boostspage-calendar-cost-text" }, { children: ["| Amount Paid $", boost.project_boost_cost] })), _jsx("div", __assign({ className: "boostspage-calendar-status-icon" }, { children: getIsCompleted(boost) ? (_jsx(IconCheck, {})) : (_jsx(IconInProcess, {})) }))] }))] }), key)); }) }))) : (_jsxs("div", __assign({ className: "boostspage-no-revisions", onClick: function () { return history.push("/platform/boosts/new"); } }, { children: [_jsx("div", __assign({ className: "boostspage-no-revisions-icon" }, { children: _jsx(IconWarning, {}) })), _jsx("div", __assign({ className: "boostspage-no-revisions-text" }, { children: "You don't have any boosts yet. Start by creating your first one!" }))] })))] })) })));
};
var mapStateToProps = function (state) { return ({
    boosts: getUserProjectBoosts(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadProjectBoosts: function () { return dispatch(thunkLoadUserProjectBoosts()); },
    onLoadUserBoosts: function () { return dispatch(thunkLoadUserBoostedProjects()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(BoostsPage);
