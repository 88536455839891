var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { BsThreeDotsVertical as IconDots } from "react-icons/bs";
import { IoMdNotificationsOutline as IconNotification } from "react-icons/io";
import { getLoadedNotificationsCount, getNotifications, } from "../../redux/selectors/notificationSelectors";
import useInfiniteLoading, { infiniteKeys, } from "../../hooks/useInfiniteLoading";
import { getUser } from "../../redux/selectors/authSelectors";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import useOutsideAlert from "../../hooks/useOutsideAlert";
import { timeSince } from "../../tools/generalTools";
import KButton from "../../base-components/KButton/KButton";
import KImage from "../../base-components/KImage/KImage";
import { getFileUrl } from "../../apis/fileApi";
import { thunkMarkAsRead, thunkMarkAsUnread, thunkClearNotification, thunClearUserNotifications, thunkLoadMoreNotifications, } from "../../redux/thunks/notificationThunks";
import "./NotificationPage.css";
var NotificationPage = function (_a) {
    var user = _a.user, notifications = _a.notifications, onMarkAsRead = _a.onMarkAsRead, onMarkAsUnread = _a.onMarkAsUnread, onClearNotification = _a.onClearNotification, onClearUserNotifications = _a.onClearUserNotifications, onLoadMoreNotifications = _a.onLoadMoreNotifications, notificationsCount = _a.notificationsCount;
    var countRef = useRef();
    var history = useHistory();
    var optionsRef = useRef(null);
    var scrollRef = useRef(notifications);
    var _b = useState(null), option = _b[0], setOption = _b[1];
    var _c = useState(0), _ = _c[0], setCount = _c[1];
    countRef.current = 0;
    var forceRerender = function () {
        for (var _1 in Array(5).fill(0)) {
            countRef.current = countRef.current + 1;
            setCount(countRef.current);
        }
    };
    var hasNotifications = function () { return notifications.length > 0; };
    var hasNewNotifications = function () {
        return notifications.filter(function (notification) { return notification.seenAt === null; })
            .length > 0;
    };
    var onView = function (notification) {
        onMarkAsRead(notification.id, forceRerender);
        history.push(notification.relativeUrl);
    };
    useDocumentTitle("Scriptofilm | Notification");
    useOutsideAlert(optionsRef, function () { return setOption(null); });
    useInfiniteLoading(infiniteKeys.NOTIFICATION, scrollRef, notificationsCount, onLoadMoreNotifications);
    useEffect(function () {
        var countInterval = setInterval(forceRerender, 3000);
        return function cleanup() {
            clearInterval(countInterval);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "notificationpage-container", ref: scrollRef }, { children: [_jsxs("div", __assign({ className: "notificationpage-title" }, { children: [_jsx("div", __assign({ className: "notificationpage-title-icon" }, { children: _jsx(IconNotification, {}) })), _jsx("div", __assign({ className: "notificationpage-title-text" }, { children: "Your notifications" }))] })), _jsxs("div", __assign({ className: "notificationpage-base-container", ref: optionsRef }, { children: [notifications.length == 0 ? (_jsx("div", __assign({ className: "notificationpage-main" }, { children: _jsx("div", __assign({ className: "notificationpage-empty-title" }, { children: "The upcoming notifications will be listed here" })) }))) : (_jsx("div", __assign({ className: "notificationpage-main" }, { children: notifications.map(function (notification, key) { return (_jsxs("div", __assign({ className: "notificationpage-notification-box ".concat(notification.seenAt
                                ? "notificationpage-seen"
                                : "") }, { children: [_jsxs("div", __assign({ className: "notificationpage-link-container", onClick: function () { return onView(notification); } }, { children: [_jsx("div", { className: "notificationpage-status" }), _jsx("div", __assign({ className: "notificationpage-image" }, { children: _jsx(KImage, { smallImgSrc: getFileUrl(notification.smallImagePath ||
                                                    process.env
                                                        .NOTIFICATION_DEFAULT_IMAGE_PATH), imgSrc: getFileUrl(notification.imagePath ||
                                                    process.env
                                                        .NOTIFICATION_DEFAULT_IMAGE_PATH), alt: "profile-pic" }) })), _jsxs("div", __assign({ className: "notificationpage-info" }, { children: [_jsx("div", __assign({ className: "notificationpage-description" }, { children: notification.text })), _jsx("div", __assign({ className: "notificationpage-date" }, { children: timeSince(new Date(new Date(notification.createdAt).getTime())) + " ago" }))] }))] })), _jsxs("div", __assign({ className: "notificationpage-options ".concat(option == key
                                        ? "notifactionpage-options-clicked"
                                        : ""), onClick: function (e) {
                                        if (option === key)
                                            setOption(null);
                                        else
                                            setOption(key);
                                    } }, { children: [_jsx(IconDots, {}), _jsxs("div", __assign({ ref: optionsRef, className: "notificationpage-dropdown ".concat(option == key
                                                ? "notifactionpage-options-open"
                                                : "") }, { children: [_jsx("a", __assign({ onMouseDown: function (e) {
                                                        e.preventDefault();
                                                        onView(notification);
                                                    } }, { children: "View" })), _jsx("a", __assign({ style: {
                                                        display: notification.seenAt
                                                            ? "none"
                                                            : "block",
                                                    }, onMouseDown: function (e) {
                                                        e.preventDefault();
                                                        onMarkAsRead(notification.id, forceRerender);
                                                    } }, { children: "Mark as read" })), _jsx("a", __assign({ style: {
                                                        display: notification.seenAt
                                                            ? "block"
                                                            : "none",
                                                    }, onMouseDown: function (e) {
                                                        e.preventDefault();
                                                        onMarkAsUnread(notification.id, forceRerender);
                                                    } }, { children: "Mark as unread" })), _jsx("a", __assign({ onMouseDown: function (e) {
                                                        e.preventDefault();
                                                        onClearNotification(notification.id, forceRerender);
                                                    } }, { children: "Clear" }))] }))] }))] }), key)); }) }))), _jsxs("div", __assign({ className: "notificationpage-secondary" }, { children: [_jsxs("div", __assign({ className: "notificationpage-info-card ".concat(hasNewNotifications()
                                    ? ""
                                    : "notificationpage-hidden") }, { children: [_jsx("div", __assign({ className: "notificationpage-info-card-header" }, { children: "You have new notifications" })), _jsx("div", __assign({ className: "notificationpage-info-card-description" }, { children: "Feel free to clear all notifications by clicking on the button below" })), _jsx(KButton, { title: "Clear All", color: "#333", backgroundColor: "var(--app-primary-color)", marginVertical: "6px", onClick: function () {
                                            return onClearUserNotifications(forceRerender);
                                        } })] })), _jsxs("div", __assign({ className: "notificationpage-info-card ".concat(hasNotifications() ? "notificationpage-hidden" : "") }, { children: [_jsx("div", __assign({ className: "notificationpage-info-card-header" }, { children: "You have no notifications" })), _jsx("div", __assign({ className: "notificationpage-info-card-description" }, { children: "The new notifications you'll be receiving soon will appear here" }))] })), _jsxs("div", __assign({ className: "notificationpage-info-card ".concat(hasNotifications() && !hasNewNotifications()
                                    ? ""
                                    : "notificationpage-hidden") }, { children: [_jsx("div", __assign({ className: "notificationpage-info-card-header" }, { children: "You have no new notifications" })), _jsx("div", __assign({ className: "notificationpage-info-card-description" }, { children: "Feel free to clear your old notifications by clicking on the button below" })), _jsx(KButton, { title: "Clear All", color: "#333", backgroundColor: "var(--app-primary-color)", marginVertical: "6px", onClick: function () {
                                            return onClearUserNotifications(forceRerender);
                                        } })] }))] }))] }))] })));
};
var mapStateToProps = function (state) { return ({
    user: getUser(state),
    notifications: getNotifications(state),
    notificationsCount: getLoadedNotificationsCount(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onMarkAsRead: function (notificationId, forceRerender) {
        return dispatch(thunkMarkAsRead(notificationId, forceRerender));
    },
    onMarkAsUnread: function (notificationId, forceRerender) {
        return dispatch(thunkMarkAsUnread(notificationId, forceRerender));
    },
    onClearNotification: function (notificationId, forceRerender) {
        return dispatch(thunkClearNotification(notificationId, forceRerender));
    },
    onClearUserNotifications: function (forceRerender) {
        return dispatch(thunClearUserNotifications(forceRerender));
    },
    onLoadMoreNotifications: function (limit, offset) {
        return dispatch(thunkLoadMoreNotifications(limit, offset));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(NotificationPage);
