var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import "./NavBar.css";
import KButton from "../../base-components/KButton/KButton";
import { useHistory } from "react-router-dom";
var NavBar = function (props) {
    var history = useHistory();
    var _a = useState(""), showLineState = _a[0], setShowLineState = _a[1];
    useEffect(function () {
        if (props.showLine === "orange") {
            setShowLineState("orange-line");
        }
        else if (props.showLine === "blue") {
            setShowLineState("blue-line");
        }
    }, []);
    return (_jsx("div", __assign({ className: "landingpage-tabbar " + showLineState }, { children: _jsxs("div", __assign({ className: "landingpage-access-actions" }, { children: [_jsx(KButton, { title: "Log in", variant: "primary", outline: true, color: "var(--app-scriptwriter-color)", width: "100px", height: "38px", onClick: function () {
                        history.push("/signin");
                    } }), _jsx(KButton, { title: "Sign up", variant: "primary", backgroundColor: "var(--app-scriptwriter-color)", width: "100px", height: "38px", onClick: function () {
                        history.push("/signup");
                    } })] })) })));
};
export default NavBar;
