import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { thunkSetupProfile } from "../../redux/thunks/authThunks";
// temporary until we make use of a messaging queue
var SetupPage = function (_a) {
    var onSetupProfile = _a.onSetupProfile;
    var history = useHistory();
    useEffect(function () {
        onSetupProfile();
    }, []);
    return _jsx("div", { className: "setuppaeg-container" });
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    onSetupProfile: function () { return dispatch(thunkSetupProfile()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SetupPage);
