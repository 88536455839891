var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { IoMdArrowDropdown as IconDropdown } from "react-icons/io";
import { IoMdArrowDropup as IconDropup } from "react-icons/io";
import { IoIosCheckmark as IconCheck } from "react-icons/io";
import { IoIosClose as IconClose } from "react-icons/io";
import { getFormikErrorMessage } from "../../tools/generalTools";
import "./KSelector.css";
var KSelector = function (_a) {
    var _b = _a.identifier, identifier = _b === void 0 ? 0 : _b, name = _a.name, label = _a.label, placeholder = _a.placeholder, _c = _a.onChange, onChange = _c === void 0 ? function (f) { return f; } : _c, width = _a.width, height = _a.height, itemKey = _a.itemKey, labelMargin = _a.labelMargin, marginVertical = _a.marginVertical, marginHorizontal = _a.marginHorizontal, marginRight = _a.marginRight, borderless = _a.borderless, marginLeft = _a.marginLeft, marginTop = _a.marginTop, marginBottom = _a.marginBottom, scrollParent = _a.scrollParent, _d = _a.selectedValue, selectedValue = _d === void 0 ? "" : _d, _e = _a.selectedItems, selectedItems = _e === void 0 ? [] : _e, _f = _a.titleKey, titleKey = _f === void 0 ? "title" : _f, _g = _a.items, items = _g === void 0 ? [] : _g, _h = _a.multiple, multiple = _h === void 0 ? false : _h, _j = _a.required, required = _j === void 0 ? false : _j, _k = _a.stack, stack = _k === void 0 ? "none" : _k, _l = _a.hideLabel, hideLabel = _l === void 0 ? false : _l, errorMessage = _a.errorMessage, backgroundColor = _a.backgroundColor, _m = _a.noDataMessage, noDataMessage = _m === void 0 ? "no data found" : _m, extra = _a.extra, formik = _a.formik;
    var containerRef = useRef(null);
    var inputRef = useRef(null);
    var _o = useState(false), editMode = _o[0], setEditMode = _o[1];
    var _p = useState(items), itemsArray = _p[0], setItemsArray = _p[1];
    var _q = useState(itemsArray), filteredItemsArray = _q[0], setFilteredItemsArray = _q[1];
    var _r = useState(selectedItems), selectedItemsArray = _r[0], setSelectedItemsArray = _r[1];
    var _s = useState(false), preventBlur = _s[0], setPreventBlur = _s[1];
    var _t = useState(selectedValue), value = _t[0], setValue = _t[1];
    errorMessage = errorMessage || getFormikErrorMessage(formik, name);
    var errorOrNot = errorMessage ? "err" : "";
    var styleContainer = {
        "--kselector-width": width || "200px",
        "--kselector-height": height || "42px",
        "--kselector-label-margin": labelMargin || "5px",
        "--kselector-border-radius": borderless ? "0" : "8px",
        "--kselector-border": borderless ? "0" : "",
        "--kselector-margin-vertical": marginVertical || "0px",
        "--kselector-margin-horizontal": marginHorizontal || "0px",
        "--kselector-margin-right": marginRight || "var(--kselector-margin-horizontal)",
        "--kselector-margin-left": marginLeft || "var(--kselector-margin-horizontal)",
        "--kselector-margin-bottom": marginBottom || "var(--kselector-margin-vertical)",
        "--kselector-margin-top": marginTop || "var(--kselector-margin-vertical)",
        "--kselector-background-color": backgroundColor || "var(--app-tertiary-background-color)",
    };
    useEffect(function () {
        if (selectedValue)
            setValue(selectedValue);
    }, [selectedValue]);
    useEffect(function () {
        if (selectedItems)
            setSelectedItemsArray(__spreadArray([], selectedItems, true));
    }, [JSON.stringify(selectedItems)]);
    useEffect(function () {
        if (JSON.stringify(items) != JSON.stringify(itemsArray)) {
            if (!items.find(function (i) { return i[titleKey] == selectedValue; }))
                setValue("");
            var selectedArray = selectedItemsArray.filter(function (i) {
                return includes(items, i);
            });
            setSelectedItemsArray(selectedArray);
            setFilteredItemsArray(items);
            setItemsArray(items);
            onChangeHandler(multiple == true
                ? selectedArray
                : items.find(function (i) { return i[titleKey] == selectedValue; }));
        }
    }, [JSON.stringify(items)]);
    var filterItems = function (text) {
        var filteredArray = itemsArray.filter(function (item) {
            return item[titleKey].toLowerCase().indexOf(text.toLowerCase()) > -1;
        });
        setFilteredItemsArray(filteredArray);
        setValue(text);
        if (multiple == false) {
            var exactArray = filteredArray.filter(function (item) { return item[titleKey] == text; });
            if (exactArray.length > 0) {
                onChangeHandler(exactArray[0]);
            }
            else {
                onChangeHandler("");
            }
        }
    };
    var onChangeHandler = function (data) {
        onChange(data);
        formik === null || formik === void 0 ? void 0 : formik.handleChange({ target: { name: name, value: data } });
    };
    var includes = function (itemsArray, item) {
        return itemsArray.some(function (i) {
            return i[titleKey] === item[titleKey] && i[itemKey] === item[itemKey];
        });
    };
    var toggleItem = function (item) {
        if (multiple == true) {
            var updatedArray = selectedItemsArray;
            if (includes(selectedItemsArray, item)) {
                updatedArray = updatedArray.filter(function (i) { return i[itemKey] != item[itemKey]; });
            }
            else {
                updatedArray.push(item);
            }
            setValue("");
            filterItems("");
            setSelectedItemsArray(__spreadArray([], updatedArray, true));
            onChangeHandler(updatedArray.length ? updatedArray : "");
        }
        else {
            setValue(item[titleKey]);
            onChangeHandler(item);
            // formik?.handleChange({ target: { name, value: item[titleKey] } });
            inputRef.current.blur();
        }
    };
    var onBlurCapture = function () {
        if (preventBlur == false)
            setEditMode(false);
        else
            inputRef.current.focus();
        setPreventBlur(false);
    };
    var handleKeyPress = function (event) {
        if ((event.target.value === "") &
            (event.keyCode == 8) &
            (selectedItemsArray.length > 0)) {
            selectedItemsArray.pop();
            setSelectedItemsArray(__spreadArray([], selectedItemsArray, true));
        }
    };
    var onFocusCapture = function () {
        setEditMode(true);
        if (multiple)
            filterItems(value);
        else if (itemsArray.find(function (item) { return item[titleKey] == value; }) == -1)
            filterItems(value);
    };
    var showContentBelow = function () {
        var neededHeight = 350;
        var element = document.getElementById("kselector-container-".concat(identifier));
        if (element) {
            var scrollY_1 = scrollParent
                ? scrollParent.scrollTop
                : window.scrollY;
            return (scrollY_1 + window.innerHeight - element.offsetTop > neededHeight);
        }
        return true;
    };
    var getContainerHeight = function () {
        var container = document.getElementById("kselector-container-".concat(identifier));
        if (container)
            return container.clientHeight || 69;
        return 69;
    };
    return (_jsx("div", __assign({ className: "kselector-container", style: styleContainer, id: "kselector-container-".concat(identifier) }, { children: _jsxs("div", __assign({ className: "kselector-wrapper", ref: containerRef }, { children: [_jsx("label", __assign({ className: "kselector-label " + errorOrNot, style: { opacity: hideLabel ? "0" : "1" } }, { children: label })), _jsxs("div", __assign({ className: "kselector-input-container ".concat(errorOrNot, " ").concat(editMode ? "kselector-edit-mode" : ""), onFocusCapture: function () { return onFocusCapture(); }, onBlurCapture: function () { return onBlurCapture(); }, style: {
                        borderTopRightRadius: (stack == "left") | (stack == "middle") ? "0" : "",
                        borderBottomRightRadius: (stack == "left") | (stack == "middle") ? "0" : "",
                        borderTopLeftRadius: (stack == "right") | (stack == "middle") ? "0" : "",
                        borderBottomLeftRadius: (stack == "right") | (stack == "middle") ? "0" : "",
                    } }, { children: [_jsxs("div", __assign({ className: "kselector-input-wrapper" }, { children: [selectedItemsArray.map(function (item, key) { return (_jsxs("div", __assign({ className: "kselector-chip-container" }, { children: [_jsx("div", __assign({ className: "kselector-chip" }, { children: item[titleKey] })), _jsx(IconClose, { className: "kselector-chip-icon", onMouseDown: function () { return setPreventBlur(true); }, onClick: function () { return toggleItem(item); }, onMouseUp: function () { return setPreventBlur(false); } })] }), key)); }), _jsx("div", { className: "kselector-input-action " + errorOrNot, onMouseDown: function () { return setPreventBlur(true); }, onMouseUp: function () { return setPreventBlur(false); }, onClick: function (e) {
                                        if (editMode)
                                            inputRef.current.blur();
                                        else
                                            inputRef.current.focus();
                                    } }), _jsxs("div", __assign({ className: "kselector-input-block" }, { children: [_jsx("input", { className: "kselector-input", required: required, ref: inputRef, name: name, type: "text", placeholder: placeholder, onFocusCapture: function () { return onFocusCapture(); }, onBlurCapture: function () { return onBlurCapture(); }, onKeyDown: function (event) { return handleKeyPress(event); }, onChange: function (event) {
                                                return filterItems(event.target.value);
                                            }, value: value || "" }), _jsx(IconDropdown, { className: "kseletor-dropdown-icon", style: { display: editMode ? "none" : "block" }, onClick: function () { return inputRef.current.focus(); } }), _jsx(IconDropup, { className: "kseletor-dropdown-icon", style: { display: editMode ? "block" : "none" }, onClick: function () { return inputRef.current.blur(); } })] }))] })), _jsx("div", __assign({ className: "kselector-content kselect-below ".concat(editMode ? "kselector-edit-mode" : ""), style: {
                                bottom: showContentBelow()
                                    ? ""
                                    : "".concat(getContainerHeight() - 25, "px"),
                                top: showContentBelow()
                                    ? "".concat(getContainerHeight() + 1, "px")
                                    : "",
                            } }, { children: filteredItemsArray.length > 0 ? ((editMode ? filteredItemsArray : []).map(function (item, key) { return (_jsxs("div", __assign({ onMouseDown: function () { return setPreventBlur(true); }, onClick: function () { return toggleItem(item); }, onMouseUp: function () { return setPreventBlur(false); }, className: "kselector-item-container ".concat(includes(selectedItemsArray, item) |
                                    ((multiple == false) &
                                        (value == item[titleKey]))
                                    ? "kselector-item-selected"
                                    : "") }, { children: [_jsx("a", { children: item[titleKey] }), _jsx(IconCheck, { className: "kselector-check-icon" })] }), key)); })) : (_jsx("div", __assign({ className: "kselector-item-container" }, { children: _jsx("a", { children: _jsx("i", { children: noDataMessage }) }) }))) }))] })), errorMessage ? (_jsx("div", __assign({ className: "kselector-errorbox errorMsg" }, { children: errorMessage || "" }))) : (_jsx(_Fragment, {}))] })) })));
};
export default KSelector;
