var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from "prop-types";
import KImage from "../../base-components/KImage/KImage";
import { getUserProfilePic } from "../../tools/userTools";
import { RiVipCrownFill as IconActors } from "react-icons/ri";
import { RiFileUserFill as IconCrews } from "react-icons/ri";
import "./MembersCard.css";
var MembersCard = function (_a) {
    var crewMembers = _a.crewMembers, actors = _a.actors;
    return (_jsx(_Fragment, { children: crewMembers.length || actors.length ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "memberscard-container" }, { children: [crewMembers.length ? (_jsxs("div", __assign({ className: "memberscard-acquired-container", style: { marginBottom: "24px" } }, { children: [_jsxs("div", __assign({ className: "memberscard-acquired-header" }, { children: [_jsx("div", __assign({ className: "memberscard-title-icon" }, { children: _jsx(IconCrews, {}) })), "Crew"] })), _jsx("div", __assign({ className: "memberscard-acquired-content-container memberscard-crew-content-container" }, { children: crewMembers.map(function (member, key) { return (_jsx("div", __assign({ className: "memberscard-acquired-content" }, { children: _jsxs("div", __assign({ className: "memberscard-acquired-info" }, { children: [_jsx("div", __assign({ className: "memberscard-acquired-info-title" }, { children: member.member_name || "" })), _jsx("div", __assign({ className: "memberscard-acquired-info-subtitle", style: {
                                                    color: "var(--app-loadeduser-color)",
                                                } }, { children: member.member_role || "" }))] })) }), key)); }) }))] }))) : (_jsx(_Fragment, {})), actors.length ? (_jsxs("div", __assign({ className: "memberscard-acquired-container" }, { children: [_jsxs("div", __assign({ className: "memberscard-acquired-header" }, { children: [_jsx("div", __assign({ className: "memberscard-title-icon" }, { children: _jsx(IconActors, {}) })), "Actors"] })), _jsx("div", __assign({ className: "memberscard-acquired-content-container memberscard-actor-content-container" }, { children: actors.map(function (member, key) { return (_jsxs("div", __assign({ className: "memberscard-actor-content" }, { children: [_jsx("div", __assign({ className: "memberscard-entry-image-container" }, { children: _jsx(KImage, { imgSrc: getUserProfilePic(member.member_image_path), smallImgSrc: getUserProfilePic(member.member_small_image_path), rounded: true }) })), _jsxs("div", __assign({ className: "memberscard-acquired-info" }, { children: [_jsx("div", __assign({ className: "memberscard-acquired-info-title", style: {
                                                        textAlign: "center",
                                                    } }, { children: member.member_name || "" })), _jsx("div", __assign({ className: "memberscard-acquired-info-subtitle memberscard-actor-info-subtitle" }, { children: member.member_role || "" })), _jsx("div", __assign({ className: "memberscard-acquired-info-subtitle memberscard-actor-info-category", style: {
                                                        color: "var(--app-loadeduser-color)",
                                                    } }, { children: member.member_category ||
                                                        "" }))] }))] }), key)); }) }))] }))) : (_jsx(_Fragment, {}))] })) })) : (_jsx(_Fragment, {})) }));
};
MembersCard.propTypes = {
    project: PropTypes.object.isRequired,
};
MembersCard.defaultProps = {
    project: {},
};
export default MembersCard;
