var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { MdSubscriptions as IconSubscriptions } from "react-icons/md";
import { IoMdCalendar as IconCalendar } from "react-icons/io";
import { BiCheck as IconCheck } from "react-icons/bi";
import { BiRefresh as IconInProcess } from "react-icons/bi";
import { MdCancel as IconCanceled } from "react-icons/md";
import { BsFillExclamationCircleFill as IconWarning } from "react-icons/bs";
import { thunkLoadUserSubscriptions } from "../../redux/thunks/authThunks";
import { getUserSubscriptions } from "../../redux/selectors/authSelectors";
import KButton from "../../base-components/KButton/KButton";
import { getReadableDate } from "../../tools/generalTools";
import { history } from "../../redux/store";
import "./SubscriptionHistoryPage.css";
var SubscriptionHistoryPage = function (_a) {
    var subscriptions = _a.subscriptions, onLoadUserSubscriptions = _a.onLoadUserSubscriptions;
    var _b = useState(""), subscriptionsTitle = _b[0], setSubscriptionsTitle = _b[1];
    var handleResize = function () {
        setSubscriptionsTitle(window.innerWidth < 800 ? "" : "Subscriptions");
    };
    var getIsCompleted = function (subscription) {
        return new Date().getTime() > new Date(subscription.endDate).getTime();
    };
    var getCompletePerc = function (subscription) {
        return parseInt(100 *
            Math.max(0, Math.min(((subscription.canceledAt
                ? new Date().getTime() //new Date(subscription.canceledAt).getTime()
                : new Date().getTime()) -
                new Date(subscription.startDate).getTime()) /
                (new Date(subscription.endDate).getTime() -
                    new Date(subscription.startDate).getTime()), 1)));
    };
    useEffect(function () {
        handleResize();
        onLoadUserSubscriptions();
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "subscriptionhistorypage-container" }, { children: [_jsxs("div", __assign({ className: "subscriptionhistorypage-title" }, { children: [_jsx("div", __assign({ className: "subscriptionhistorypage-title-icon" }, { children: _jsx(IconSubscriptions, {}) })), _jsx("div", __assign({ className: "subscriptionhistorypage-title-text" }, { children: "Subscription History" })), _jsx(KButton, { title: subscriptionsTitle, variant: "primary", icon: IconSubscriptions, onClick: function () { return history.push("/platform/subscriptions"); } })] })), _jsx("div", { children: subscriptions.length ? (_jsx("div", __assign({ className: "boostspage-wrapper" }, { children: subscriptions.map(function (subscription, key) { return (_jsxs("div", __assign({ className: "boostspage-boost-box" }, { children: [_jsx("div", { className: "boostspage-boost-box-overlap", style: {
                                    width: "".concat(getCompletePerc(subscription), "%"),
                                } }), _jsx("div", __assign({ className: "boostspage-calendar-title-text" }, { children: subscription.name
                                    ? "".concat(subscription.name, " Plan")
                                    : "Premium Plan" })), _jsxs("div", __assign({ className: "boostspage-boost-box-data" }, { children: [_jsx("div", __assign({ className: "boostspage-calendar-icon" }, { children: _jsx(IconCalendar, {}) })), _jsxs("div", __assign({ className: "boostspage-calendar-date-text" }, { children: [getReadableDate(subscription.startDate), " ", "---", " ", getReadableDate(subscription.endDate)] })), _jsxs("div", __assign({ className: "boostspage-calendar-cost-text" }, { children: ["| Amount Paid $", subscription.amount] })), _jsx("div", __assign({ className: "boostspage-calendar-status-icon" }, { children: getIsCompleted(subscription) ? (_jsx(IconCheck, {})) : subscription.status ===
                                            "past_due" ? (_jsx(IconWarning, {})) : subscription.canceledAt &&
                                            subscription.canceledAt <=
                                                subscription.endDate ? (_jsx(IconCanceled, {})) : (_jsx(IconInProcess, {})) }))] }))] }), key)); }) }))) : (_jsxs("div", __assign({ className: "boostspage-no-revisions", onClick: function () { return history.push("/platform/subscriptions"); } }, { children: [_jsx("div", __assign({ className: "boostspage-no-revisions-icon" }, { children: _jsx(IconWarning, {}) })), _jsx("div", __assign({ className: "boostspage-no-revisions-text" }, { children: "You don't have any subscriptions yet. Start by trying out our premium plan!" }))] }))) })] })));
};
var mapStateToProps = function (state) { return ({
    subscriptions: getUserSubscriptions(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadUserSubscriptions: function () { return dispatch(thunkLoadUserSubscriptions()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionHistoryPage);
