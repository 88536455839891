export var CONTACTUS_IN_PROGRESS = "CONTACTUS_IN_PROGRESS";
export var contactUsInProgress = function () { return ({
    type: CONTACTUS_IN_PROGRESS,
}); };
export var CONTACTUS_SUCCESS = "CONTACTUS_SUCCESS";
export var contactUsSuccess = function (data) { return ({
    type: CONTACTUS_SUCCESS,
    payload: { data: data },
}); };
export var CONTACTUS_FAILURE = "CONTACTUS_FAILURE";
export var contactUsFailure = function (message) { return ({
    type: CONTACTUS_FAILURE,
    payload: { message: message },
}); };
export var CLEAR_SUCCESS_DATA = "CLEAR_SUCCESS_DATA";
export var clearSuccessData = function () { return ({
    type: CLEAR_SUCCESS_DATA,
}); };
