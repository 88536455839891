var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { AiFillLeftCircle as IconLeft } from "react-icons/ai";
import { AiFillRightCircle as IconRight } from "react-icons/ai";
import { getImageUrl } from "../../tools/generalTools";
import KImage from "../../base-components/KImage/KImage";
import "./MultiImageCard.css";
var MultiImageCard = function (_a) {
    var title = _a.title, _b = _a.pathKey, pathKey = _b === void 0 ? "" : _b, _c = _a.smallPathKey, smallPathKey = _c === void 0 ? "" : _c, _d = _a.numPerPage, numPerPage = _d === void 0 ? 10 : _d, _e = _a.images, images = _e === void 0 ? [] : _e;
    var _f = useState(1), totalPages = _f[0], setTotalPages = _f[1];
    var _g = useState(1), currentPage = _g[0], setCurrentPage = _g[1];
    var _h = useState([]), imagesArray = _h[0], setImagesArray = _h[1];
    var setPage = function (page) {
        setImagesArray(images.slice((page - 1) * numPerPage, page * numPerPage));
        setCurrentPage(page);
    };
    var nextPage = function () {
        setPage(currentPage == totalPages ? 1 : currentPage + 1);
    };
    var prevPage = function () {
        setPage(currentPage == 1 ? totalPages : currentPage - 1);
    };
    useEffect(function () {
        setTotalPages(Math.ceil(images.length / numPerPage));
        setPage(1);
    }, [images.length]);
    var getSmallImageSrc = function (image) {
        return getImageUrl(image ? image[smallPathKey] : "");
    };
    var getImageSrc = function (image) {
        return getImageUrl(image ? image[pathKey] : "");
    };
    return (_jsxs("div", __assign({ className: "multiimagecard-container", style: { display: imagesArray.length > 0 ? "block" : "none" } }, { children: [_jsxs("div", __assign({ className: "multiimagecard-header" }, { children: ["_", title] })), _jsxs("div", __assign({ className: "multiimagecard-images-container" }, { children: [_jsxs("div", __assign({ className: "multiimagecard-actions", style: { display: totalPages > 1 ? "block" : "none" } }, { children: [_jsxs("div", __assign({ className: "multiimagecard-actions-text" }, { children: [currentPage, " / ", totalPages] })), _jsxs("div", __assign({ className: "multiimagecard-actions-container" }, { children: [_jsx(IconLeft, { className: "multiimagecard-actions-icon", onClick: prevPage }), _jsx(IconRight, { className: "multiimagecard-actions-icon", onClick: nextPage })] }))] })), _jsxs("div", __assign({ className: "multiimagecard-section-1" }, { children: [_jsx("div", __assign({ className: "multiimagecard-section-1-1" }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[0]), imgSrc: getImageSrc(imagesArray[0]) }) })), _jsx("div", __assign({ className: "multiimagecard-section-1-2", style: {
                                    display: imagesArray.length > 3 ? "flex" : "none",
                                } }, { children: _jsxs("div", __assign({ className: "multiimagecard-sections-container" }, { children: [_jsxs("div", __assign({ className: "multiimagecard-section-1" }, { children: [_jsx("div", __assign({ className: "multiimagecard-section-1-2" }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[3]), imgSrc: getImageSrc(imagesArray[3]) }) })), _jsx("div", __assign({ className: "multiimagecard-section-1-1", style: {
                                                        display: imagesArray.length > 8
                                                            ? "flex"
                                                            : "none",
                                                    } }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[8]), imgSrc: getImageSrc(imagesArray[8]) }) }))] })), _jsxs("div", __assign({ className: "multiimagecard-section-2", style: {
                                                display: imagesArray.length > 4
                                                    ? "flex"
                                                    : "none",
                                            } }, { children: [_jsx("div", __assign({ className: "multiimagecard-section-2-1" }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[4]), imgSrc: getImageSrc(imagesArray[4]) }) })), _jsx("div", __assign({ className: "multiimagecard-section-2-2", style: {
                                                        display: imagesArray.length > 5
                                                            ? "flex"
                                                            : "none",
                                                    } }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[5]), imgSrc: getImageSrc(imagesArray[5]) }) }))] }))] })) }))] })), _jsxs("div", __assign({ className: "multiimagecard-section-2", style: {
                            display: imagesArray.length > 1 ? "flex" : "none",
                        } }, { children: [_jsx("div", __assign({ className: "multiimagecard-section-2-1" }, { children: _jsxs("div", __assign({ className: "multiimagecard-sections-container" }, { children: [_jsxs("div", __assign({ className: "multiimagecard-section-1" }, { children: [_jsx("div", __assign({ className: "multiimagecard-section-1-2" }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[1]), imgSrc: getImageSrc(imagesArray[1]) }) })), _jsx("div", __assign({ className: "multiimagecard-section-1-1", style: {
                                                        display: imagesArray.length > 7
                                                            ? "flex"
                                                            : "none",
                                                    } }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[7]), imgSrc: getImageSrc(imagesArray[7]) }) }))] })), _jsxs("div", __assign({ className: "multiimagecard-section-2", style: {
                                                display: imagesArray.length > 6
                                                    ? "flex"
                                                    : "none",
                                            } }, { children: [_jsx("div", __assign({ className: "multiimagecard-section-2-1" }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[6]), imgSrc: getImageSrc(imagesArray[6]) }) })), _jsx("div", __assign({ className: "multiimagecard-section-2-2", style: {
                                                        display: imagesArray.length > 9
                                                            ? "flex"
                                                            : "none",
                                                    } }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[9]), imgSrc: getImageSrc(imagesArray[9]) }) }))] }))] })) })), _jsx("div", __assign({ className: "multiimagecard-section-2-2", style: {
                                    display: imagesArray.length > 2 ? "flex" : "none",
                                } }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[2]), imgSrc: getImageSrc(imagesArray[2]) }) }))] }))] }))] })));
};
MultiImageCard.propTypes = {};
MultiImageCard.defaultProps = {};
export default MultiImageCard;
