var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { CONTRACTS_LOADING, CONTRACTS_LOADING_COMPLETE, CONTRACTS_LOAD_FAILURE, CONTRACTS_LOAD_SUCCESS, CONTRACT_COMMENTS_LOAD_FAILURE, CONTRACT_COMMENTS_LOAD_SUCCESS, CONTRACT_LOAD_FAILURE, CONTRACT_LOAD_SUCCESS, CONTRACT_NEW_COMMENTS_KEY, CONTRACT_NEW_COMMENTS_LOADED, CONTRACT_RENEW_CANCEL_SOURCE, CONTRACT_REVISIONS_LOAD_FAILURE, CONTRACT_REVISIONS_LOAD_SUCCESS, CONTRACT_TYPES_LOADED, REQUEST_SIGNATURES_LOADED, SIGNATURE_REQUESTS_BY_CONTRACT_LOADED, SIGNATURE_REQUEST_LOADED, TRANSACTIONS_LOADING, TRANSACTIONS_LOADING_COMPLETE, TRANSACTIONS_LOAD_ALL_FAILURE, TRANSACTIONS_LOAD_ALL_SUCCESS, TRANSACTIONS_LOAD_FAILURE, TRANSACTIONS_LOAD_SUCCESS, TRANSACTION_STATUS_SUCCESS, } from "../actions/contractActions";
import axios from "axios";
var contractsInitialState = {
    data: [],
    types: [],
    comments: [],
    transactions: [],
    allTransactions: [],
    revisions: [],
    commentsKey: "",
    isLoading: false,
    loadedContract: {},
    requestsByContract: {},
    signatureRequest: {},
    signatures: [],
    cancelSource: axios.CancelToken.source(),
};
export var contract = function (state, action) {
    if (state === void 0) { state = contractsInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case CONTRACT_TYPES_LOADED: {
            var contractTypes = payload.contractTypes;
            return __assign(__assign({}, state), { types: contractTypes, isLoading: false });
        }
        case CONTRACTS_LOADING: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case CONTRACTS_LOADING_COMPLETE: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case CONTRACTS_LOAD_SUCCESS: {
            var contracts = payload.contracts;
            return __assign(__assign({}, state), { data: __spreadArray([], contracts, true), isLoading: false });
        }
        case CONTRACTS_LOAD_FAILURE: {
            return __assign(__assign({}, state), { data: [], isLoading: false });
        }
        case CONTRACT_LOAD_SUCCESS: {
            var contract_1 = payload.contract;
            return __assign(__assign({}, state), { loadedContract: contract_1, isLoading: false });
        }
        case CONTRACT_LOAD_FAILURE: {
            return __assign(__assign({}, state), { loadedContract: {}, isLoading: false });
        }
        case CONTRACT_REVISIONS_LOAD_SUCCESS: {
            var revisions = payload.revisions;
            return __assign(__assign({}, state), { revisions: revisions, isLoading: false });
        }
        case CONTRACT_REVISIONS_LOAD_FAILURE: {
            return __assign(__assign({}, state), { revisions: [], isLoading: false });
        }
        case CONTRACT_COMMENTS_LOAD_SUCCESS: {
            var comments = payload.comments;
            return __assign(__assign({}, state), { comments: comments, isLoading: false });
        }
        case CONTRACT_COMMENTS_LOAD_FAILURE: {
            return __assign(__assign({}, state), { comments: [], isLoading: false });
        }
        case CONTRACT_NEW_COMMENTS_LOADED: {
            var comments = payload.comments;
            return __assign(__assign({}, state), { comments: __spreadArray(__spreadArray([], state.comments, true), comments, true), isLoading: false });
        }
        case CONTRACT_NEW_COMMENTS_KEY: {
            var key = payload.key;
            return __assign(__assign({}, state), { commentsKey: key, isLoading: false });
        }
        case CONTRACT_RENEW_CANCEL_SOURCE: {
            var cancelSource = axios.CancelToken.source();
            return __assign(__assign({}, state), { cancelSource: cancelSource, isLoading: false });
        }
        case SIGNATURE_REQUESTS_BY_CONTRACT_LOADED: {
            var requestsByContract = payload.requestsByContract;
            return __assign(__assign({}, state), { requestsByContract: requestsByContract, isLoading: false });
        }
        case SIGNATURE_REQUEST_LOADED: {
            var signatureRequest = payload.signatureRequest;
            return __assign(__assign({}, state), { signatureRequest: signatureRequest, isLoading: false });
        }
        case REQUEST_SIGNATURES_LOADED: {
            var signatures = payload.signatures;
            return __assign(__assign({}, state), { signatures: signatures, isLoading: false });
        }
        case TRANSACTIONS_LOADING_COMPLETE: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case TRANSACTIONS_LOADING: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case TRANSACTIONS_LOAD_SUCCESS: {
            var transactions = payload.transactions;
            return __assign(__assign({}, state), { transactions: transactions, isLoading: false });
        }
        case TRANSACTIONS_LOAD_FAILURE: {
            return __assign(__assign({}, state), { transactions: [], isLoading: false });
        }
        case TRANSACTION_STATUS_SUCCESS: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case TRANSACTIONS_LOAD_ALL_SUCCESS: {
            var transactions = payload.transactions;
            return __assign(__assign({}, state), { allTransactions: transactions, isLoading: false });
        }
        case TRANSACTIONS_LOAD_ALL_FAILURE: {
            return __assign(__assign({}, state), { allTransactions: [], isLoading: false });
        }
        default: {
            return state;
        }
    }
};
