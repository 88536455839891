var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import "./KProgressBar.css";
var KProgressBar = function (_a) {
    var isLoading = _a.isLoading;
    var _b = useState(0), width = _b[0], setWidth = _b[1];
    var _c = useState(2), wait = _c[0], setWait = _c[1];
    var _d = useState(0.1), step = _d[0], setStep = _d[1];
    useEffect(function () {
        if ((width > 105) | ((width == 0) & (isLoading == false))) {
            setWidth(0);
        }
        else {
            if (isLoading == true) {
                setStep(0.1);
                setWait(Math.pow(1.05, width));
            }
            else {
                setStep(0.5);
                setWait(1);
            }
            setTimeout(function () { return setWidth(width + step); }, wait);
        }
    }, [width]);
    useEffect(function () {
        if ((isLoading == true) & (width == 0)) {
            setStep(0.1);
            setWidth(0.0001);
        }
    }, [isLoading]);
    return (_jsxs("div", __assign({ id: "kprogressbar-container" }, { children: [_jsx("div", __assign({ id: "kprogressbar-bar" }, { children: _jsx("div", { id: "kprogressbar-progress", style: { width: "".concat(width, "%") } }) })), _jsx("div", { id: "kprogressbar-fullcover", style: { display: isLoading ? "flex" : "none" } })] })));
};
export default KProgressBar;
