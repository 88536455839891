var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { getFullName, getUserProfilePic, getUserTitleColor, } from "../../tools/userTools";
import KImage from "../../base-components/KImage/KImage";
import { RiFileUserFill as IconCrews } from "react-icons/ri";
import "./ProjectMainCrewCard.css";
import { history } from "../../redux/store";
var ProjectMainCrewCard = function (_a) {
    var director = _a.director, producer = _a.producer;
    return (_jsxs("div", __assign({ className: "projectmaincrewcard-container" }, { children: [_jsxs("div", __assign({ className: "projectmaincrewcard-acquired-header" }, { children: [_jsx("div", __assign({ className: "projectmaincrewcard-title-icon" }, { children: _jsx(IconCrews, {}) })), "Main Crew"] })), director.name || director.user_id ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "projectmaincrewcard-acquired-content ".concat(director.user_id ? "selectable" : ""), onClick: function () {
                        if (director.user_id)
                            history.push("/platform/network/user/".concat(director.user_id));
                    } }, { children: [director.user_id ? (_jsx("div", __assign({ className: "projectmaincrewcard-entry-image-container", style: {
                                borderColor: getUserTitleColor(director.user_title_name),
                            } }, { children: _jsx(KImage, { imgSrc: getUserProfilePic(director.user_small_image_path), smallImgSrc: getUserProfilePic(director.user_small_image_path), rounded: true }) }))) : (_jsx("div", { className: "projectmaincrewcard-entry-image-container empty" })), _jsxs("div", __assign({ className: "projectmaincrewcard-acquired-info" }, { children: [_jsx("div", __assign({ className: "projectmaincrewcard-acquired-info-title" }, { children: director.user_id
                                        ? getFullName(director)
                                        : director.name })), _jsx("div", __assign({ className: "projectmaincrewcard-acquired-info-subtitle" }, { children: "Director" }))] }))] })) })) : (_jsx(_Fragment, {})), producer.name || producer.user_id ? (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "projectmaincrewcard-acquired-content ".concat(producer.user_id ? "selectable" : ""), onClick: function () {
                        if (producer.user_id)
                            history.push("/platform/network/user/".concat(producer.user_id));
                    } }, { children: [producer.user_id ? (_jsx("div", __assign({ className: "projectmaincrewcard-entry-image-container", style: {
                                borderColor: getUserTitleColor(producer.user_title_name),
                            } }, { children: _jsx(KImage, { imgSrc: getUserProfilePic(producer.user_small_image_path), smallImgSrc: getUserProfilePic(producer.user_small_image_path), rounded: true }) }))) : (_jsx("div", { className: "projectmaincrewcard-entry-image-container empty" })), _jsxs("div", __assign({ className: "projectmaincrewcard-acquired-info" }, { children: [_jsx("div", __assign({ className: "projectmaincrewcard-acquired-info-title" }, { children: producer.user_id
                                        ? getFullName(producer)
                                        : producer.name })), _jsx("div", __assign({ className: "projectmaincrewcard-acquired-info-subtitle" }, { children: "Producer" }))] }))] })) })) : (_jsx(_Fragment, {}))] })));
};
ProjectMainCrewCard.propTypes = {
    project: PropTypes.object.isRequired,
};
ProjectMainCrewCard.defaultProps = {
    project: {},
};
export default ProjectMainCrewCard;
