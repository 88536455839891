var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { MdEdit as IconEdit } from "react-icons/md";
import { IoIosAdd as IconPlus } from "react-icons/io";
import KImage from "../../base-components/KImage/KImage";
import "./ListingCard.css";
import { getTransparentImagePath } from "../../tools/generalTools";
var ListingCard = function (_a) {
    var title = _a.title, onClick = _a.onClick, emptyTitle = _a.emptyTitle, _b = _a.onEdit, onEdit = _b === void 0 ? null : _b, _c = _a.actionColor, actionColor = _c === void 0 ? "var(--app-loadeduser-color)" : _c, _d = _a.buttonTitle, buttonTitle = _d === void 0 ? "view more" : _d, _e = _a.full, full = _e === void 0 ? false : _e, _f = _a.items, items = _f === void 0 ? [] : _f;
    var history = useHistory();
    var _g = useState(0), index = _g[0], setIndex = _g[1];
    var _h = useState(full ? 5 : 2), numPerPage = _h[0], setNumPerPage = _h[1];
    var _j = useState(items), itemsArray = _j[0], setItemsArray = _j[1];
    var styleContainer = { "--listing-card-action-color": actionColor };
    useEffect(function () {
        setItemsArray(items.slice(index, index + numPerPage));
    }, [JSON.stringify(items)]);
    return (_jsxs("div", __assign({ className: "listingcard-container ".concat(full ? "listingcard-view-all" : ""), style: styleContainer }, { children: [_jsxs("div", __assign({ className: "listingcard-header" }, { children: [_jsxs("div", __assign({ className: "listingcard-header-title" }, { children: ["_", title] })), _jsx("div", __assign({ className: "listingcard-header-edit-button" }, { children: onEdit !== null ? (_jsx(_Fragment, { children: _jsx(IconEdit, { onClick: onEdit }) })) : (_jsx(_Fragment, {})) }))] })), _jsxs("div", __assign({ className: "listingcard-content-container" }, { children: [_jsxs("div", __assign({ className: "listingcard-action", onClick: onClick, style: {
                            display: full || items.length < 3 ? "none" : "flex",
                        } }, { children: [_jsx(IconPlus, { className: "listtingcard-action-icon" }), _jsx("div", __assign({ className: "listingcard-action-title" }, { children: buttonTitle }))] })), _jsx("div", __assign({ className: "listingcard-content" }, { children: itemsArray.length > 0 ? (itemsArray.map(function (item, key) { return (_jsxs(Link, __assign({ className: "listingcard-section", style: {
                                cursor: item.href ? "pointer" : "default",
                            }, to: item.href || "#" }, { children: [item.imgSrc ? (_jsx("div", __assign({ className: "listingcard-image-section" }, { children: _jsxs("div", __assign({ className: "listingcard-image-container", style: {
                                            borderColor: item.imgBorderColor,
                                        } }, { children: [item.backgroundImage ? (_jsx("div", __assign({ className: "listingcard-background-image-container" }, { children: _jsx(KImage, { imgSrc: item.backgroundImage, smallImgSrc: getTransparentImagePath(), rounded: true }) }))) : (_jsx(_Fragment, {})), _jsx(KImage, { imgSrc: item.imgSrc, smallImgSrc: item.smallImgSrc, rounded: true })] })) }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "listingcard-content-section" }, { children: [_jsx("div", __assign({ className: "listingcard-section-header ".concat(full ? "" : "listingcard-nowrap") }, { children: item.title })), _jsx("div", __assign({ className: "listingcard-section-info ".concat(full ? "" : "listingcard-nowrap") }, { children: item.info1 })), _jsx("div", __assign({ className: "listingcard-section-info ".concat(full ? "" : "listingcard-nowrap") }, { children: item.info2 }))] }))] }), key)); })) : (_jsx("div", __assign({ className: "listingcard-empty" }, { children: emptyTitle || "no " + title + " yet" }))) }))] }))] })));
};
ListingCard.propTypes = {};
ListingCard.defaultProps = {};
export default ListingCard;
