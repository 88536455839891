var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, createRef, useRef } from "react";
import { connect } from "react-redux";
import { Switch, Redirect, useParams, useLocation, useRouteMatch, useHistory, } from "react-router-dom";
import EditProjectBasicsPage from "../EditProjectBasicsPage/EditProjectBasicsPage";
import EditProjectDetailsPage from "../EditProjectDetailsPage/EditProjectDetailsPage";
import EditProjectContentPage from "../EditProjectContentPage/EditProjectContentPage";
import EditProjectTeamPage from "../EditProjectTeamPage/EditProjectTeamPage";
import KNavRoute from "../../base-components/KNavRoute/KNavRoute";
import EditProjectNav from "../../components/EditProjectNav/EditProjectNav";
import EditScriptHeader from "../../components/EditScriptHeader/EditScriptHeader";
import { getProjectInProcess, getProject, getProjectCharacters, getProjectActors, getProjectCrews, getProjectShootingSets, getProjectVideoScenes, } from "../../redux/selectors/projectSelectors";
import { getUserTitle, getUserId } from "../../redux/selectors/authSelectors";
import { thunkCreateProject, thunkClearProjectInProcess, thunkUpdateProjectInProcess, thunkLoadProject, thunkDeleteProject, thunkUpdateProject, thunkChangeProjectStatus, } from "../../redux/thunks/projectThunks";
import { thunkLoadProjectKinds, thunkLoadProjectGenres, thunkLoadProjectStages, thunkLoadCountries, thunkLoadLanguages, thunkStartLoading, thunkStopLoading, } from "../../redux/thunks/generalThunks";
import { getProjectKinds, getProjectStages, getCountries, getProjectGenres, getLanguages, getProjectTypes, } from "../../redux/selectors/generalSelectors";
import { getMonthFromDate, getYearFromDate, getImageUrl, } from "../../tools/generalTools";
import { getUserProfilePic, isUserBuyer, isUserInvestor, } from "../../tools/userTools";
import "./EditProjectPage.css";
import KFilesUploader from "../../base-components/KFilesUploader/KFilesUploader";
import { getEditedObjects } from "../../tools/fileTools";
import { getStorageVideoUrl } from "../../apis/fileApi";
import { canCreateProjects, hasTeamPage, } from "../../validators/profileValidator";
import { thunkLoadUserByUserId } from "../../redux/thunks/userThunks";
import { setProgress } from "../../redux/actions/generalActions";
export var EditProjectPage = function (_a) {
    var project = _a.project, userTitle = _a.userTitle, createProject = _a.createProject, loadedProject = _a.loadedProject, loadProject = _a.loadProject, countries = _a.countries, languages = _a.languages, projectKinds = _a.projectKinds, projectGenres = _a.projectGenres, projectStages = _a.projectStages, isEditMode = _a.isEditMode, videoScenes = _a.videoScenes, shootingSets = _a.shootingSets, characters = _a.characters, crewMembers = _a.crewMembers, actors = _a.actors, loadCountries = _a.loadCountries, loadLanguages = _a.loadLanguages, onLoadUser = _a.onLoadUser, onSetProgress = _a.onSetProgress, onChangeStatus = _a.onChangeStatus, loadProjectKinds = _a.loadProjectKinds, authUserId = _a.authUserId, loadProjectGenres = _a.loadProjectGenres, loadProjectStages = _a.loadProjectStages, onStartLoading = _a.onStartLoading, onStopLoading = _a.onStopLoading, updateProjectInProcess = _a.updateProjectInProcess, updateProject = _a.updateProject, deleteProject = _a.deleteProject, clearProjectInProcess = _a.clearProjectInProcess;
    var history = useHistory();
    var directorRef = useRef();
    var producerRef = useRef();
    var state = useLocation().state;
    var _b = useRouteMatch(), path = _b.path, url = _b.url;
    var projectTitle = useParams().projectTitle;
    var _c = useState(false), loaded = _c[0], setLoaded = _c[1];
    var _d = useState({}), basicsData = _d[0], setBasicsData = _d[1];
    var _e = useState({}), detailsData = _e[0], setDetailsData = _e[1];
    var _f = useState({}), contentData = _f[0], setContentData = _f[1];
    var _g = useState({}), teamData = _g[0], setTeamData = _g[1];
    var _h = useState({}), director = _h[0], _setDirector = _h[1];
    var _j = useState({}), producer = _j[0], _setProducer = _j[1];
    var _k = useState("/platform/profile"), basePathname = _k[0], setBasePathname = _k[1];
    var projectId = state ? state.projectId : "";
    var setDirector = function (val) {
        directorRef.current = val;
        _setDirector(val);
    };
    var setProducer = function (val) {
        producerRef.current = val;
        _setProducer(val);
    };
    var onUpdate = function (data) {
        updateProjectInProcess(data);
    };
    var onSave = function (data, nextTab) {
        onUpdate(data);
        history.push("".concat(url, "/").concat(nextTab), { projectId: projectId });
    };
    var onPublishUpdate = function (project) { return __awaiter(void 0, void 0, void 0, function () {
        var runUpdate, uploader, _i, _a, videoScene;
        return __generator(this, function (_b) {
            runUpdate = function (outputData) { return __awaiter(void 0, void 0, void 0, function () {
                var _loop_1, i, projectUpdated;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (contentData.editedVideoScenesArray) {
                                _loop_1 = function (i) {
                                    var data = outputData.find(function (e) {
                                        return e.key ===
                                            "vs-".concat(contentData.editedVideoScenesArray[i].key);
                                    });
                                    if (data)
                                        contentData.editedVideoScenesArray[i].path =
                                            data.filepath;
                                };
                                for (i = 0; i < contentData.editedVideoScenesArray.length; i++) {
                                    _loop_1(i);
                                }
                            }
                            return [4 /*yield*/, updateProject(__assign(__assign(__assign(__assign(__assign(__assign({}, project), teamData), contentData), basicsData), detailsData), { fullProjectPath: contentData.fullProjectFile &&
                                        outputData.find(function (e) { return e.key === "fp"; })
                                        ? outputData.find(function (e) { return e.key === "fp"; }).filepath
                                        : project.fullProjectPath }))];
                        case 1:
                            projectUpdated = _a.sent();
                            onSetProgress(0);
                            onStopLoading();
                            if (projectUpdated)
                                history.push("".concat(basePathname, "/project/").concat(projectId));
                            return [2 /*return*/];
                    }
                });
            }); };
            // TODO: MOVE THIS TO PROJECT THUNKS AND PROGRESSBAR TO ITS OWN MODULE !!!
            contentData.editedVideoScenesArray = getEditedObjects(contentData.videoSceneArray || []);
            if (contentData.fullProjectFile ||
                contentData.editedVideoScenesArray.length) {
                uploader = new KFilesUploader(authUserId, process.env.STREAM_UPLOAD_URL, process.env.STREAM_UPLOAD_CHUNK);
                uploader.onProgress = function (progress) { return onSetProgress(progress); };
                uploader.onError = function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        onStopLoading();
                        onSetProgress(0);
                        alert("Failed to publish your project");
                        return [2 /*return*/];
                    });
                }); };
                uploader.onFinish = function (outputData) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        runUpdate(outputData);
                        return [2 /*return*/];
                    });
                }); };
                if (contentData.fullProjectFile)
                    uploader.addFile(contentData.fullProjectFile, "fp", "full-project", true);
                for (_i = 0, _a = contentData.editedVideoScenesArray; _i < _a.length; _i++) {
                    videoScene = _a[_i];
                    if (videoScene.file)
                        uploader.addFile(videoScene.file, "vs-".concat(videoScene.key), "video-scenes", false);
                }
                onStartLoading();
                onSetProgress(0.1);
                uploader.uploadFiles();
            }
            else {
                runUpdate();
            }
            return [2 /*return*/];
        });
    }); };
    var onPublish = function (project) { return __awaiter(void 0, void 0, void 0, function () {
        var runCreate, uploader, _i, _a, videoScene;
        var _b;
        return __generator(this, function (_c) {
            runCreate = function (outputData) { return __awaiter(void 0, void 0, void 0, function () {
                var _loop_2, i, createdProjectId;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (contentData.videoSceneArray) {
                                _loop_2 = function (i) {
                                    var data = outputData.find(function (e) {
                                        return e.key === "vs-".concat(contentData.videoSceneArray[i].key);
                                    });
                                    if (data)
                                        contentData.videoSceneArray[i].path = data.filepath;
                                };
                                for (i = 0; i < contentData.videoSceneArray.length; i++) {
                                    _loop_2(i);
                                }
                            }
                            return [4 /*yield*/, createProject(__assign(__assign(__assign(__assign(__assign(__assign({}, project), teamData), contentData), basicsData), detailsData), { fullProjectPath: contentData.fullProjectFile &&
                                        outputData.find(function (e) { return e.key === "fp"; })
                                        ? outputData.find(function (e) { return e.key === "fp"; }).filepath
                                        : project.fullProjectPath }))];
                        case 1:
                            createdProjectId = _a.sent();
                            onSetProgress(0);
                            onStopLoading();
                            // TODO: check if success or failure
                            //  if failure don't push to profile
                            if (createdProjectId)
                                history.push("".concat(basePathname, "/project/").concat(createdProjectId));
                            return [2 /*return*/];
                    }
                });
            }); };
            if (contentData.fullProjectFile ||
                ((_b = contentData.videoSceneArray) === null || _b === void 0 ? void 0 : _b.length)) {
                uploader = new KFilesUploader(authUserId, process.env.STREAM_UPLOAD_URL, process.env.STREAM_UPLOAD_CHUNK);
                uploader.onProgress = function (progress) { return onSetProgress(progress); };
                uploader.onError = function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        onStopLoading();
                        onSetProgress(0);
                        alert("Failed to publish your project");
                        return [2 /*return*/];
                    });
                }); };
                uploader.onFinish = function (outputData) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        runCreate(outputData);
                        return [2 /*return*/];
                    });
                }); };
                if (contentData.fullProjectFile)
                    uploader.addFile(contentData.fullProjectFile, "fp", "full-project", true);
                for (_i = 0, _a = contentData.videoSceneArray || []; _i < _a.length; _i++) {
                    videoScene = _a[_i];
                    uploader.addFile(videoScene.file, "vs-".concat(videoScene.key), "video-scenes", false);
                }
                onStartLoading();
                onSetProgress(0.1);
                uploader.uploadFiles();
            }
            else {
                runCreate();
            }
            return [2 /*return*/];
        });
    }); };
    var listLinksData = [];
    var listRoutesData = [];
    if (canCreateProjects()) {
        listLinksData = listLinksData.concat([
            {
                path: "".concat(url, "/basics"),
                prefix: "_01",
                title: "the basics",
                state: { projectId: projectId },
            },
            {
                path: "".concat(url, "/details"),
                prefix: "_02",
                title: "the details",
                state: { projectId: projectId },
            },
            {
                path: "".concat(url, "/content"),
                prefix: "_03",
                title: "the content",
                state: { projectId: projectId },
            },
        ]);
        listRoutesData = listRoutesData.concat([
            {
                path: "".concat(path, "/basics"),
                component: EditProjectBasicsPage,
                props: {
                    projectTitle: projectTitle,
                    userTitle: userTitle,
                    project: project,
                    onSave: onSave,
                    onChange: setBasicsData,
                    isEditMode: isEditMode,
                    onPublish: function () { return onPublish(project); },
                    onUpdate: function () { return onPublishUpdate(project); },
                },
                exact: false,
            },
            {
                path: "".concat(path, "/details"),
                component: EditProjectDetailsPage,
                props: {
                    project: project,
                    onSave: onSave,
                    onChange: setDetailsData,
                    isEditMode: isEditMode,
                    onPublish: function () { return onPublish(project); },
                    onUpdate: function () { return onPublishUpdate(project); },
                },
                exact: false,
            },
            {
                path: "".concat(path, "/content"),
                component: EditProjectContentPage,
                props: {
                    userId: authUserId,
                    project: project,
                    onSave: onSave,
                    nextPage: hasTeamPage() ? "team" : "content",
                    onChange: setContentData,
                    isEditMode: isEditMode,
                    onPublish: function () { return onPublish(project); },
                    onUpdate: function () { return onPublishUpdate(project); },
                    hasNext: hasTeamPage(),
                },
                exact: false,
            },
        ]);
    }
    if (hasTeamPage()) {
        listLinksData.push({
            path: "".concat(url, "/team"),
            prefix: "_04",
            title: "the team",
            state: { projectId: projectId },
        });
        listRoutesData.push({
            path: "".concat(path, "/team"),
            component: EditProjectTeamPage,
            props: {
                userId: authUserId,
                project: project,
                onSave: onSave,
                onChange: setTeamData,
                isEditMode: isEditMode,
                onPublish: function () { return onPublish(project); },
                onUpdate: function () { return onPublishUpdate(project); },
                hasNext: false,
            },
            exact: false,
        });
    }
    listRoutesData.push({
        path: "".concat(path, "/*"),
        component: Redirect,
        props: { to: "/notfound" },
        exact: false,
    });
    var component = (_jsx(Switch, { children: listRoutesData.map(function (routeData, key) { return (_jsx(KNavRoute, { path: routeData.path, component: routeData.component, componentProps: routeData.props, exact: routeData.exact }, key)); }) }));
    var onExitEditMode = function () {
        var proceed = true;
        if (project.saved)
            proceed =
                proceed &&
                    confirm("Your saved data will be lost. Would you like to proceed?");
        if (proceed) {
            clearProjectInProcess();
            if (projectId)
                history.push("".concat(basePathname, "/project/").concat(projectId));
            else
                history.push(basePathname);
        }
    };
    var onDeleteProject = function () { return __awaiter(void 0, void 0, void 0, function () {
        var proceed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    proceed = confirm("Your project will be permanantly deleted. Would you like to proceed?");
                    if (!proceed) return [3 /*break*/, 2];
                    clearProjectInProcess();
                    return [4 /*yield*/, deleteProject(projectId)];
                case 1:
                    _a.sent();
                    history.push(basePathname);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var onCloseProject = function () { return __awaiter(void 0, void 0, void 0, function () {
        var proceed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    proceed = confirm("Are you sure you would like to mark your project as completed?");
                    if (!proceed) return [3 /*break*/, 2];
                    return [4 /*yield*/, onChangeStatus(projectId, true)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var onOpenProject = function () { return __awaiter(void 0, void 0, void 0, function () {
        var proceed;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    proceed = confirm("Are you sure you would like to mark your project as uncompleted?");
                    if (!proceed) return [3 /*break*/, 2];
                    return [4 /*yield*/, onChangeStatus(projectId, false)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var prepareData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (isUserBuyer(userTitle) || isUserInvestor(userTitle)) {
                            alert("Forbidden");
                            history.push(basePathname);
                        }
                        return [4 /*yield*/, clearProjectInProcess()];
                    case 1:
                        _a.sent();
                        if (!projectId) return [3 /*break*/, 4];
                        return [4 /*yield*/, loadProjectGenres()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, loadProjectStages()];
                    case 3:
                        _a.sent();
                        // await loadProjectKinds();
                        // await loadCountries();
                        // await loadLanguages();
                        // await loadProject(projectId);
                        Promise.all([
                            loadProjectKinds(),
                            loadCountries(),
                            loadLanguages(),
                            loadProjectGenres(),
                            loadProjectStages(),
                            loadProject(projectId),
                        ])
                            .then(function (data) {
                            console.log("project ready");
                        })
                            .catch(function (err) {
                            alert("Failed to loda project data: " + err);
                        });
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        prepareData();
    }, []);
    useEffect(function () {
        if (window.location.pathname &&
            window.location.pathname.split("/").length > 2) {
            setBasePathname(window.location.pathname.split("/").slice(0, 3).join("/"));
        }
    }, []);
    var prepareProject = function () {
        if (loaded || loadedProject.project_id != projectId)
            return;
        if (authUserId != loadedProject.project_user_id_fk) {
            clearProjectInProcess();
            alert("Forbidden");
            history.push("/platform/market");
            return;
        }
        var interestsArray = [];
        if (loadedProject.project_user_interest_names) {
            interestsArray =
                loadedProject.project_user_interest_names.split(",");
        }
        var lookingForBuyer = interestsArray.includes("Buyer");
        var lookingForProducer = interestsArray.includes("Producer");
        var lookingForInvestor = interestsArray.includes("Investor");
        var fundingArray = [];
        if (loadedProject.project_funding_investors) {
            var fundingInvestors = loadedProject.project_funding_investors.split(",");
            var fundingAmounts = (loadedProject.project_funding_amounts || "").split(",");
            var fundingPrivates = loadedProject.project_funding_privates.split(",");
            for (var i = 0; i < fundingInvestors.length; i++) {
                fundingArray.push({
                    investor: fundingInvestors[i],
                    amount: fundingAmounts[i],
                    isPrivate: fundingPrivates[i] == true ? true : false,
                });
            }
        }
        else
            fundingArray.push({ investor: "", amount: "", isPrivate: false });
        var presalesArray = [];
        if (loadedProject.project_presale_investors) {
            var presaleInvestors = loadedProject.project_presale_investors.split(",");
            var presaleAmounts = (loadedProject.project_presale_amounts || "").split(",");
            var presaleCountries = (loadedProject.project_presale_countries || "").split(",");
            var presaleTerritories = (loadedProject.project_presale_territories || "").split(",");
            var presaleCountryIds = (loadedProject.project_presale_country_ids || "").split(",");
            for (var i = 0; i < presaleInvestors.length; i++) {
                presalesArray.push({
                    investor: presaleInvestors[i],
                    amount: presaleAmounts[i],
                    country: presaleCountries[i],
                    territory: presaleTerritories[i],
                    countryId: presaleCountryIds[i],
                });
            }
        }
        else
            presalesArray.push({
                investor: "",
                amount: "",
                countryId: "",
                territory: "",
                country: "",
            });
        var coverInfoKey = 0;
        var coverInfoArray = [];
        if (loadedProject.project_coverimages) {
            for (var i = 0; i < loadedProject.project_coverimages.length; i++) {
                coverInfoKey += 1;
                coverInfoArray.push({
                    key: coverInfoKey,
                    fileInput: createRef(),
                    src: getImageUrl(loadedProject.project_coverimages[i]
                        .project_coverimage_path),
                    path: loadedProject.project_coverimages[i]
                        .project_coverimage_path,
                    title: loadedProject.project_coverimages[i]
                        .project_coverimage_title,
                    id: loadedProject.project_coverimages[i]
                        .project_coverimage_id,
                    isEdited: false,
                });
            }
        }
        var moodboardKey = 0;
        var moodboardArray = [];
        if (loadedProject.project_moodimages) {
            for (var i = 0; i < loadedProject.project_moodimages.length; i++) {
                moodboardKey += 1;
                moodboardArray.push({
                    key: moodboardKey,
                    fileInput: createRef(),
                    src: getImageUrl(loadedProject.project_moodimages[i]
                        .project_moodimage_path),
                    path: loadedProject.project_moodimages[i]
                        .project_moodimage_path,
                    smallPath: loadedProject.project_moodimages[i]
                        .project_moodimage_smallpath,
                    title: loadedProject.project_moodimages[i]
                        .project_moodimage_title,
                    id: loadedProject.project_moodimages[i]
                        .project_moodimage_id,
                    isEdited: false,
                });
            }
        }
        var storyboardKey = 0;
        var storyboardArray = [];
        if (loadedProject.project_storyimages) {
            for (var i = 0; i < loadedProject.project_storyimages.length; i++) {
                storyboardKey += 1;
                storyboardArray.push({
                    key: storyboardKey,
                    fileInput: createRef(),
                    src: getImageUrl(loadedProject.project_storyimages[i]
                        .project_storyimage_path),
                    path: loadedProject.project_storyimages[i]
                        .project_storyimage_path,
                    smallPath: loadedProject.project_storyimages[i]
                        .project_storyimage_smallpath,
                    title: loadedProject.project_storyimages[i]
                        .project_storyimage_title,
                    id: loadedProject.project_storyimages[i]
                        .project_storyimage_id,
                    isEdited: false,
                });
            }
        }
        var synopsisFiles = [];
        if (loadedProject.project_synopsisfiles) {
            for (var i = 0; i < loadedProject.project_synopsisfiles.length; i++) {
                synopsisFiles.push({
                    file: "",
                    title: loadedProject.project_synopsisfiles[i]
                        .project_synopsisfile_title,
                    size: loadedProject.project_synopsisfiles[i]
                        .project_synopsisfile_size,
                    id: loadedProject.project_synopsisfiles[i]
                        .project_synopsisfile_id,
                    isEdited: false,
                });
            }
        }
        var treatmentFiles = [];
        if (loadedProject.project_treatmentfiles) {
            for (var i = 0; i < loadedProject.project_treatmentfiles.length; i++) {
                treatmentFiles.push({
                    file: "",
                    title: loadedProject.project_treatmentfiles[i]
                        .project_treatmentfile_title,
                    size: loadedProject.project_treatmentfiles[i]
                        .project_treatmentfile_size,
                    id: loadedProject.project_treatmentfiles[i]
                        .project_treatmentfile_id,
                    isEdited: false,
                });
            }
        }
        var videoScenesKey = 0;
        var videoSceneArray = [];
        if (videoScenes) {
            for (var i = 0; i < videoScenes.length; i++) {
                videoScenesKey += 1;
                videoSceneArray.push({
                    key: videoScenesKey,
                    fileInput: createRef(),
                    src: getStorageVideoUrl(videoScenes[i].project_videoscene_path),
                    path: videoScenes[i].project_videoscene_path,
                    title: videoScenes[i].project_videoscene_title,
                    description: videoScenes[i].project_videoscene_description,
                    id: videoScenes[i].project_videoscene_id,
                    isEdited: false,
                });
            }
        }
        var shootingSetKey = 0;
        var shootingSetArray = [];
        if (shootingSets) {
            for (var i = 0; i < shootingSets.length; i++) {
                shootingSetKey += 1;
                shootingSetArray.push({
                    key: shootingSetKey,
                    fileInput: createRef(),
                    src: getImageUrl(shootingSets[i].shootingset_image_path),
                    path: shootingSets[i].shootingset_image_path,
                    smallPath: shootingSets[i].shootingset_small_image_path,
                    title: shootingSets[i].shootingset_title,
                    id: shootingSets[i].shootingset_id,
                    isEdited: false,
                });
            }
        }
        var characterKey = 0;
        var updatedCharacters = [];
        if (characters) {
            for (var i_1 = 0; i_1 < characters.length; i_1++) {
                characterKey++;
                updatedCharacters.push({
                    key: characterKey,
                    fileInput: createRef(),
                    src: getUserProfilePic(characters[i_1].character_image_path),
                    path: characters[i_1].character_image_path,
                    smallPath: characters[i_1].character_small_image_path,
                    id: characters[i_1].character_id,
                    name: characters[i_1].character_name,
                    brief: characters[i_1].character_brief,
                    isEdited: false,
                });
            }
        }
        var actorKey = 0;
        var updatedActors = [];
        if (actors) {
            for (var i_2 = 0; i_2 < actors.length; i_2++) {
                actorKey++;
                updatedActors.push({
                    key: actorKey,
                    fileInput: createRef(),
                    src: getUserProfilePic(actors[i_2].member_image_path),
                    path: actors[i_2].member_image_path,
                    smallPath: actors[i_2].member_small_image_path,
                    id: actors[i_2].member_id,
                    name: actors[i_2].member_name,
                    role: actors[i_2].member_role,
                    category: actors[i_2].member_category || "main",
                    type: actors[i_2].member_type,
                    isEdited: false,
                });
            }
        }
        var crewKey = 0;
        var updatedCrewMembers = [];
        if (crewMembers) {
            for (var i_3 = 0; i_3 < crewMembers.length; i_3++) {
                crewKey++;
                updatedCrewMembers.push({
                    key: crewKey,
                    fileInput: createRef(),
                    src: getUserProfilePic(crewMembers[i_3].member_image_path),
                    path: crewMembers[i_3].member_image_path,
                    smallPath: crewMembers[i_3].member_small_image_path,
                    id: crewMembers[i_3].member_id,
                    name: crewMembers[i_3].member_name,
                    role: crewMembers[i_3].member_role,
                    type: crewMembers[i_3].member_type,
                    isEdited: false,
                });
            }
        }
        var projectToEdit = {
            id: loadedProject.project_id,
            title: loadedProject.project_title,
            projectType: loadedProject.project_type_name,
            projectTypeId: loadedProject.project_type_id,
            projectSubtypeId: loadedProject.project_subtype_id,
            lookingForBuyer: lookingForBuyer,
            lookingForProducer: lookingForProducer,
            lookingForInvestor: lookingForInvestor,
            openForSale: loadedProject.project_open_for_sale,
            openForCollaboration: loadedProject.project_open_for_collaboration,
            acquisitionBudget: loadedProject.project_acquisition_budget,
            productionBudget: loadedProject.project_production_budget,
            financingBudget: loadedProject.project_financing_budget,
            investmentBudget: loadedProject.project_investment_budget,
            country: countries.find(function (i) { return i.country_id == loadedProject.country_id; }),
            language: languages.find(function (i) { return i.language_id == loadedProject.language_id; }),
            kind: projectKinds.find(function (i) { return i.project_kind_id == loadedProject.project_kind_id; }),
            genre: projectGenres.find(function (i) { return i.project_genre_id == loadedProject.project_genre_id; }),
            stage: projectStages.find(function (i) { return i.project_stage_id == loadedProject.project_stage_id; }),
            duration: loadedProject.project_duration,
            pages: loadedProject.project_pages,
            fundingArray: fundingArray,
            presalesArray: presalesArray,
            coverInfoArray: coverInfoArray,
            storyboardArray: storyboardArray,
            moodboardArray: moodboardArray,
            month: getMonthFromDate(loadedProject.project_date),
            year: getYearFromDate(loadedProject.project_date),
            treatment: loadedProject.project_treatment,
            treatmentFiles: treatmentFiles,
            synopsis: loadedProject.project_synopsis,
            synopsisFiles: synopsisFiles,
            fullProjectPath: loadedProject.project_full_project_path,
            productionCompanyName: loadedProject.productionCompanyName || "",
            productionCompanyWebsite: loadedProject.productionCompanyWebsite || "",
            coProductionCompanyName: loadedProject.coProductionCompanyName || "",
            coProductionCompanyWebsite: loadedProject.coProductionCompanyWebsite || "",
            directorName: loadedProject.project_director_name,
            producerName: loadedProject.project_producer_name,
            director: directorRef.current,
            producer: producerRef.current,
            videoSceneArray: videoSceneArray,
            shootingSetArray: shootingSetArray,
            characters: updatedCharacters,
            crewMembers: updatedCrewMembers,
            actors: updatedActors,
        };
        onUpdate(__assign({}, projectToEdit));
        setLoaded(true);
    };
    useEffect(function () {
        prepareProject();
    }, __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
        loaded,
        loadedProject
    ], characters, true), crewMembers, true), actors, true), videoScenes, true), shootingSets, true));
    useEffect(function () {
        if (!directorRef.current) {
            if (loadedProject.project_director_id_fk)
                onLoadUser(loadedProject.project_director_id_fk, function (err, user) {
                    setDirector(user);
                    prepareProject();
                });
            else
                setDirector({
                    isCustom: true,
                    name: loadedProject.project_director_name,
                });
        }
        if (!producerRef.current) {
            if (loadedProject.project_producer_id_fk)
                onLoadUser(loadedProject.project_producer_id_fk, function (err, user) {
                    setProducer(user);
                    prepareProject();
                });
            else
                setProducer({
                    isCustom: true,
                    name: loadedProject.project_producer_name,
                });
        }
    }, [loadedProject]);
    return (_jsxs("div", { children: [_jsx(EditScriptHeader, { isEditMode: isEditMode, onClose: onExitEditMode, onPublish: function () { return onPublish(project); }, onUpdate: function () { return onPublishUpdate(project); } }), _jsx("div", __assign({ style: { display: "block" } }, { children: _jsx(EditProjectNav, { listLinksData: listLinksData, component: component, onDelete: onDeleteProject, onClose: onCloseProject, onOpen: onOpenProject, onChangePage: function () {
                        return onUpdate(__assign(__assign(__assign(__assign(__assign({}, project), contentData), basicsData), detailsData), teamData));
                    }, isCreated: isEditMode, isClosed: loadedProject.project_closed }) }))] }));
};
var mapStateToProps = function (state) { return ({
    authUserId: getUserId(state),
    project: getProjectInProcess(state),
    userTitle: getUserTitle(state),
    loadedProject: getProject(state),
    projectKinds: getProjectKinds(state),
    projectGenres: getProjectGenres(state),
    projectStages: getProjectStages(state),
    countries: getCountries(state),
    languages: getLanguages(state),
    projectTypes: getProjectTypes(state),
    videoScenes: getProjectVideoScenes(state),
    shootingSets: getProjectShootingSets(state),
    characters: getProjectCharacters(state),
    actors: getProjectActors(state),
    crewMembers: getProjectCrews(state),
    // loadedUser: getLoadedUser(state),
    // coverImages: getProjectCoverImages(state),
    // moodImages: getProjectMoodImages(state),
    // storyImages: getProjectStoryImages(state),
    // synopsisFiles: getProjectSynopsisFiles(state),
    // treatmentFiles: getProjectTreatmentFiles(state),
}); };
var dispatchStateToProps = function (dispatch) { return ({
    clearProjectInProcess: function () { return dispatch(thunkClearProjectInProcess()); },
    updateProjectInProcess: function (project) {
        return dispatch(thunkUpdateProjectInProcess(project));
    },
    createProject: function (project) { return dispatch(thunkCreateProject(project)); },
    updateProject: function (project) { return dispatch(thunkUpdateProject(project)); },
    loadProject: function (projectId) { return dispatch(thunkLoadProject(projectId)); },
    loadProjectKinds: function () { return dispatch(thunkLoadProjectKinds()); },
    loadProjectGenres: function () { return dispatch(thunkLoadProjectGenres()); },
    loadProjectStages: function () { return dispatch(thunkLoadProjectStages()); },
    loadCountries: function () { return dispatch(thunkLoadCountries()); },
    loadLanguages: function () { return dispatch(thunkLoadLanguages()); },
    onChangeStatus: function (projectId, isClosed) {
        return dispatch(thunkChangeProjectStatus(projectId, isClosed));
    },
    deleteProject: function (projectId) { return dispatch(thunkDeleteProject(projectId)); },
    onStartLoading: function () { return dispatch(thunkStartLoading()); },
    onStopLoading: function () { return dispatch(thunkStopLoading()); },
    onSetProgress: function (progress) { return dispatch(setProgress(progress)); },
    onLoadUser: function (userId, callback) {
        return dispatch(thunkLoadUserByUserId(userId, callback));
    },
}); };
export default connect(mapStateToProps, dispatchStateToProps)(EditProjectPage);
