import { getUser } from "./authSelectors";
export var getUsers = function (state) {
    return state.users.data;
};
export var getLoadedUser = function (state) {
    return state.users.loadedUser;
};
export var getLoadedProfileViews = function (state) {
    return state.users.views;
};
export var getMessagingUsers = function (state) {
    return state.users.messagingUsers;
};
export var isReported = function (state) {
    return state.users.isReported;
};
export var getInterestedUsers = function (state) {
    return state.users.interested;
};
export var getInterestedCowriters = function (state) {
    return state.users.cowriters;
};
export var getUserUserInterests = function (state) {
    return (getUser(state).user_interests || []).map(function (o) {
        var interests = { TitleId: o.user_title_id };
        if (o.countries)
            interests["CountryIds"] = o.countries.map(function (l) { return l.country_id; });
        if (o.languages)
            interests["LanguageIds"] = o.languages.map(function (l) { return l.language_id; });
        return interests;
    });
};
export var getUserProjectInterests = function (state) {
    return (getUser(state).user_project_interests || []).map(function (o) {
        var interests = { TypeId: o.project_type_id };
        if (o.projectGenres)
            interests["GenreIds"] = o.projectGenres.map(function (l) { return l.project_genre_id; });
        if (o.projectSubtypes)
            interests["SubtypeIds"] = o.projectSubtypes.map(function (l) { return l.project_subtype_id; });
        if (o.languages)
            interests["LanguageIds"] = o.languages.map(function (l) { return l.language_id; });
        return interests;
    });
};
export var getUserTitleFromEmail = function (state) {
    return state.users.userTitle;
};
export var getSelectedSearchOptions = function (state) {
    return state.users.selectedSearchOptions;
};
export var getLookingForSelectedSearchOptions = function (state) {
    return state.users.lookingForUserSelectedSearchOptions;
};
export var getMarketSelectedSearchOptions = function (state) {
    return state.users.selectedMarketSearchOptions;
};
export var getMarketLookingForSelectedSearchOptions = function (state) {
    return state.users.marketLookingForUserSelectedSearchOptions;
};
export var getRatingSuccess = function (state) {
    return state.users.rating_success;
};
export var getLoadedUserRatingByUserId = function (state) {
    return state.users.ratingByUserId;
};
export var getLoadedUserRatingAverage = function (state) {
    return state.users.averageRating;
};
export var getLoadedProjectViews = function (state) {
    return state.users.project_views;
};
export var getContractUsers = function (state) {
    return state.users.contractUsers;
};
export var getLawyers = function (state) {
    return state.users.lawyers;
};
export var getNetworkPath = function (state) {
    return state.users.networkPath;
};
export var getMarketPath = function (state) {
    return state.users.marketPath;
};
export var getShameUsers = function (state) {
    return state.users.shameUsers;
};
export var getDcpSignedUrl = function (state) {
    return state.users.dcpSignedUrl;
};
export var getUploadedDcp = function (state) {
    return state.users.uploadedDcp;
};
