var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCountries, getLanguages, } from "../../redux/selectors/generalSelectors";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { IoMdDownload as IconDownload } from "react-icons/io";
import { BiTransferAlt as IconTransaction } from "react-icons/bi";
import { BsThreeDotsVertical as IconOptions } from "react-icons/bs";
import { GiTrophyCup as IconTrophy } from "react-icons/gi";
import { getContracts, getSignatureRequestsByContract, getTransactions, } from "../../redux/selectors/contractSelectors";
import { getUser } from "../../redux/selectors/authSelectors";
import { BsFillExclamationCircleFill as IconWarning } from "react-icons/bs";
import { getContractUsers } from "../../redux/selectors/userSelectors";
import { history } from "../../redux/store";
import KImage from "../../base-components/KImage/KImage";
import { thunkLoadTransactions } from "../../redux/thunks/contractThunks";
import { thunkLoadProjectsByIds } from "../../redux/thunks/projectThunks";
import { getMessagingProjects } from "../../redux/selectors/projectSelectors";
import { getDefaultCover, getProjectImage, getProjectSmallImage, } from "../../tools/projectTools";
import { getTransparentImagePath, getValueInCurrency, } from "../../tools/generalTools";
import KPopup from "../../base-components/KPopup/KPopup";
import KButton from "../../base-components/KButton/KButton";
import useOutsideAlert from "../../hooks/useOutsideAlert";
import { getSignedUrl } from "../../apis/storageApi";
import "./TransactionsPage.css";
var TransactionCard = function (_a) {
    var _b, _c;
    var transaction = _a.transaction, project = _a.project, _d = _a.onClick, onClick = _d === void 0 ? function (f) { return f; } : _d;
    var optionsRef = useRef();
    var _e = useState(""), status = _e[0], setStatus = _e[1];
    var _f = useState(false), optionsOn = _f[0], setOptionsOn = _f[1];
    var _g = useState(false), highlightRequested = _g[0], setHighlightRequested = _g[1];
    var _h = useState(false), highlightInitiated = _h[0], setHighlightInitiated = _h[1];
    var _j = useState(false), highlightCompleted = _j[0], setHighlightCompleted = _j[1];
    useOutsideAlert(optionsRef, function () { return setOptionsOn(false); });
    useEffect(function () {
        switch (transaction.status) {
            case "requested":
                setStatus("PAYMENT REQUESTED");
                setHighlightRequested(true);
                setHighlightInitiated(false);
                setHighlightCompleted(false);
                break;
            case "initiated":
                setHighlightRequested(true);
                setHighlightInitiated(true);
                setHighlightCompleted(false);
                setStatus("PAYMENT SENT");
                break;
            case "completed":
                setHighlightRequested(true);
                setHighlightInitiated(true);
                setHighlightCompleted(true);
                setStatus("PAYMENT RECEIVED");
                break;
            default:
                setHighlightRequested(false);
                setHighlightInitiated(false);
                setHighlightCompleted(false);
                setStatus("PAYMENT REQUESTED");
                break;
        }
    }, [transaction]);
    return (_jsxs("div", __assign({ className: "transactionspage-card-container", onClick: function () { return onClick(transaction); } }, { children: [_jsxs("div", __assign({ className: "transactionspage-options-button", ref: optionsRef, onMouseDown: function (e) {
                    setOptionsOn(!optionsOn);
                }, onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                } }, { children: [_jsx(IconOptions, {}), _jsxs("div", __assign({ className: "transactionspage-options-list", style: { display: optionsOn ? "" : "none" }, onClick: function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                        } }, { children: [_jsx("div", __assign({ className: "transactionspage-select-option", onMouseDown: function () { return onClick(transaction); } }, { children: "View" })), transaction.invoicePath ? (_jsx("div", __assign({ className: "transactionspage-select-option", onMouseDown: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var signedUrl;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, getSignedUrl(transaction.invoicePath)];
                                            case 1:
                                                signedUrl = _a.sent();
                                                window.open(signedUrl);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }, { children: "Download invoice" }))) : (_jsx(_Fragment, {})), transaction.receiptPath ? (_jsx("div", __assign({ className: "transactionspage-select-option", onMouseDown: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var signedUrl;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, getSignedUrl(transaction.receiptPath)];
                                            case 1:
                                                signedUrl = _a.sent();
                                                window.open(signedUrl);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }, { children: "Download receipt" }))) : (_jsx(_Fragment, {}))] }))] })), _jsxs("div", __assign({ className: "transactionspage-card-top-section" }, { children: [_jsxs("div", __assign({ className: "transactionspage-card-image-section" }, { children: [_jsx("div", __assign({ className: "transactionspage-card-image-background" }, { children: _jsx(KImage, { imgSrc: getDefaultCover(project), smallImgSrc: getTransparentImagePath() }) })), _jsx("div", __assign({ className: "transactionspage-card-image" }, { children: _jsx(KImage, { imgSrc: getProjectImage(project), smallImgSrc: getProjectSmallImage(project) }) }))] })), _jsxs("div", __assign({ className: "transactionspage-card-info-section" }, { children: [_jsx("div", { children: _jsxs("div", __assign({ className: "transactionspage-card-info-title" }, { children: [(transaction === null || transaction === void 0 ? void 0 : transaction.description)
                                            ? "".concat(transaction === null || transaction === void 0 ? void 0 : transaction.description, " | ")
                                            : "", " ", (_b = transaction === null || transaction === void 0 ? void 0 : transaction.contract) === null || _b === void 0 ? void 0 : _b.title] })) }), _jsx("div", { children: _jsx("div", __assign({ className: "transactionspage-card-info-subtitle" }, { children: project === null || project === void 0 ? void 0 : project.project_title })) })] }))] })), _jsx("div", __assign({ className: "transactionspage-card-bottom-section" }, { children: _jsxs("div", __assign({ className: "transactionspage-card-status-section" }, { children: [_jsxs("div", __assign({ className: "transactionspage-card-status" }, { children: [_jsx("div", __assign({ className: "transactionspage-card-status-description" }, { children: status })), _jsx("table", __assign({ className: "transactionspage-table" }, { children: _jsx("tbody", { children: _jsxs("tr", { children: [_jsxs("td", __assign({ className: "transactionspage-first-cell ".concat(highlightRequested ? "active" : "") }, { children: [_jsx("div", { className: "transactionspage-bullet" }), _jsx("div", { className: "transactionspage-dotted-line" })] })), _jsxs("td", __assign({ className: highlightInitiated ? "active" : "" }, { children: [_jsx("div", { className: "transactionspage-bullet" }), _jsx("div", { className: "transactionspage-dotted-line" })] })), _jsxs("td", __assign({ className: highlightCompleted ? "active" : "" }, { children: [_jsx("div", { className: "transactionspage-bullet" }), _jsx("div", { className: "transactionspage-dotted-line" })] }))] }) }) }))] })), _jsx("div", __assign({ className: "transactionspage-card-amount ".concat(highlightCompleted ? "active" : "") }, { children: getValueInCurrency(0.01 *
                                (transaction === null || transaction === void 0 ? void 0 : transaction.percFee) *
                                ((_c = transaction === null || transaction === void 0 ? void 0 : transaction.contract) === null || _c === void 0 ? void 0 : _c.dealBudget), "$") }))] })) }))] })));
};
var TransactionsPage = function (_a) {
    var authUser = _a.authUser, contracts = _a.contracts, loadedUsers = _a.loadedUsers, projects = _a.projects, transactions = _a.transactions, onLoadProjects = _a.onLoadProjects, onLoadTransactions = _a.onLoadTransactions;
    var location = useLocation();
    var query = new URLSearchParams(location.search);
    var _b = useState(), selectedTransaction = _b[0], setSelectedTransaction = _b[1];
    var _c = useState(false), isReady = _c[0], setIsReady = _c[1];
    var onTransactionClick = function (transaction) {
        if (transaction.status === "requested")
            history.push("/platform/transaction/".concat(transaction === null || transaction === void 0 ? void 0 : transaction.id));
        else {
            setSelectedTransaction(transaction);
            history.push("/platform/transaction?transactionId=".concat(transaction.id));
        }
    };
    useDocumentTitle("Scriptofilm | Transactions");
    useEffect(function () {
        if (!isReady)
            return;
        if (transactions.length === 0) {
            return history.push("/platform/transaction/request");
        }
        onLoadProjects(transactions.map(function (t) { return t.contract.projectId; }));
        var transactionId = query.get("transactionId");
        if (transactionId) {
            setSelectedTransaction(transactions.find(function (t) { return t.id === parseInt(transactionId); }));
        }
    }, [isReady, JSON.stringify(transactions)]);
    useEffect(function () {
        var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, onLoadTransactions()];
                    case 1:
                        _a.sent();
                        setIsReady(true);
                        return [2 /*return*/];
                }
            });
        }); };
        loadData();
    }, []);
    return (_jsxs("div", __assign({ className: "transactionspage-container" }, { children: [_jsx(KPopup, __assign({ active: selectedTransaction &&
                    ["initiated", "completed"].includes(selectedTransaction === null || selectedTransaction === void 0 ? void 0 : selectedTransaction.status), onClose: function () {
                    setSelectedTransaction();
                    history.push("/platform/transaction");
                } }, { children: _jsxs("div", __assign({ className: "transactionspage-popup-container" }, { children: [(selectedTransaction === null || selectedTransaction === void 0 ? void 0 : selectedTransaction.status) === "completed" ? (_jsxs("div", __assign({ className: "transactionspage-popup-background" }, { children: [_jsx(IconTrophy, {}), _jsxs("div", __assign({ className: "transactionspage-popup-congrats" }, { children: ["Congratulations!", [
                                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                            13,
                                        ].map(function (num, key) { return (
                                        // {[...Array(14).keys()].map((num, key) => ( // not working on prod
                                        _jsx("div", __assign({ className: "transactionspage-popup-background-plus-".concat(num) }, { children: "+" }))); })] })), _jsx("div", __assign({ className: "transactionspage-popup-text-header" }, { children: "Your transaction is marked as completed." })), _jsx("div", __assign({ className: "transactionspage-popup-text-subheader" }, { children: "You can always download your receipt by clicking on the button below:" }))] }))) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "transactionspage-popup-title" }, { children: ["Payment", " ", (selectedTransaction === null || selectedTransaction === void 0 ? void 0 : selectedTransaction.status) === "initiated"
                                            ? "Sent"
                                            : "Received"] })), _jsx("div", __assign({ className: "transactionspage-popup-text" }, { children: "Your transaction is marked as sent. Processing time usually take up to 10 days. We will notify you when your transaction is completed." }))] })), _jsx("div", __assign({ className: "transactionspage-popup-action" }, { children: (selectedTransaction === null || selectedTransaction === void 0 ? void 0 : selectedTransaction.status) === "completed" ? (_jsx(KButton, { title: "Download Receipt", variant: "primary", icon: IconDownload, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var signedUrl;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, getSignedUrl(selectedTransaction.receiptPath)];
                                            case 1:
                                                signedUrl = _a.sent();
                                                window.open(signedUrl);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })) : (_jsx(KButton, { title: "Download Invoice", variant: "primary", icon: IconDownload, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var signedUrl;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, getSignedUrl(selectedTransaction.invoicePath)];
                                            case 1:
                                                signedUrl = _a.sent();
                                                window.open(signedUrl);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })) }))] })) })), _jsxs("div", __assign({ className: "transactionspage-header" }, { children: [_jsx("div", __assign({ className: "transactionspage-title-icon" }, { children: _jsx(IconTransaction, {}) })), _jsx("div", __assign({ className: "transactionspage-title-text" }, { children: "Transactions" }))] })), _jsx("div", __assign({ className: "transactionspage-intro" }, { children: _jsx("p", { children: "All of your transactions whether completed or pending are listed here." }) })), transactions && transactions.length ? (_jsxs(_Fragment, { children: [_jsx(_Fragment, { children: transactions.filter(function (t) { return !t.completedAt; }).length ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "transactionspage-section-title" }, { children: "Pending Transactions" })), _jsx("div", __assign({ className: "transactionspage-wrapper" }, { children: transactions
                                        .filter(function (t) { return !t.completedAt; })
                                        .map(function (transaction, key) {
                                        var _a;
                                        return (_jsx(TransactionCard, { transaction: transaction, onClick: onTransactionClick, project: projects[(_a = transaction === null || transaction === void 0 ? void 0 : transaction.contract) === null || _a === void 0 ? void 0 : _a.projectId] }, key));
                                    }) }))] })) : (_jsx(_Fragment, {})) }), _jsx(_Fragment, { children: transactions.filter(function (t) { return t.completedAt && t.completedAt !== null; }).length ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "transactionspage-section-title" }, { children: "Completed Transactions" })), _jsx("div", __assign({ className: "transactionspage-wrapper" }, { children: transactions
                                        .filter(function (t) {
                                        return t.completedAt &&
                                            t.completedAt !== null;
                                    })
                                        .map(function (transaction, key) {
                                        var _a;
                                        return (_jsx(TransactionCard, { transaction: transaction, onClick: onTransactionClick, project: projects[(_a = transaction === null || transaction === void 0 ? void 0 : transaction.contract) === null || _a === void 0 ? void 0 : _a.projectId] }, key));
                                    }) }))] })) : (_jsx(_Fragment, {})) })] })) : (_jsxs("div", __assign({ className: "transactionspage-empty" }, { children: [_jsx("div", __assign({ className: "transactionspage-empty-icon" }, { children: _jsx(IconWarning, {}) })), _jsx("div", __assign({ className: "transactionspage-empty-text" }, { children: "You don't have any transactions yet. We'll notify you of any incoming ones!" }))] })))] })));
};
var mapStateToProps = function (state) { return ({
    countries: getCountries(state),
    languages: getLanguages(state),
    contracts: getContracts(state),
    loadedUsers: getContractUsers(state),
    requestsByContract: getSignatureRequestsByContract(state),
    authUser: getUser(state),
    transactions: getTransactions(state),
    projects: getMessagingProjects(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadTransactions: function () { return dispatch(thunkLoadTransactions()); },
    onLoadProjects: function (projectIds) {
        return dispatch(thunkLoadProjectsByIds(projectIds));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
