var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import validator from "validator";
import { IoIosAddCircle as IconAdd } from "react-icons/io";
import { IoMdTrash as IconDelete } from "react-icons/io";
import { MdEdit as IconEdit } from "react-icons/md";
import KTextField from "../../base-components/KTextField/KTextField";
import UserAutocomplete from "../../components/UserAutocomplete/UserAutocomplete";
import KRadioButton from "../../base-components/KRadioButton/KRadioButton";
import { dataURItoBlob, resize } from "../../imaging/imageTransform";
import KButton from "../../base-components/KButton/KButton";
import KImage from "../../base-components/KImage/KImage";
import { getUserProfilePic } from "../../tools/userTools";
import EditCropImage from "../EditCropImage/EditCropImage";
import KPopup from "../../base-components/KPopup/KPopup";
import { formLogoUrl } from "../../tools/generalTools";
import "./EditProjectTeamPage.css";
var EditProjectTeamPage = function (_a) {
    var project = _a.project, onSave = _a.onSave, onChange = _a.onChange, isEditMode = _a.isEditMode, onPublish = _a.onPublish, onUpdate = _a.onUpdate, _b = _a.hasNext, hasNext = _b === void 0 ? false : _b;
    var textfieldWidth = "400px";
    var maxActors = process.env.MAX_ACTORS || 10;
    var maxCrewMembers = process.env.MAX_CREW_MEMBERS || 10;
    var imageSize = parseInt(process.env.LARGE_IMAGE_SIZE);
    var _c = useState(""), productionCompanyWebsite = _c[0], setProductionCompanyWebsite = _c[1];
    var _d = useState(""), productionLogoUrl = _d[0], setProductionLogoUrl = _d[1];
    var _e = useState(""), productionCompanyName = _e[0], setProductionCompanyName = _e[1];
    var _f = useState(""), productionLogoTimeout = _f[0], setProductionLogoTimeout = _f[1];
    var _g = useState(""), coProductionCompanyWebsite = _g[0], setCoProductionCompanyWebsite = _g[1];
    var _h = useState(""), coProductionLogoUrl = _h[0], setCoProductionLogoUrl = _h[1];
    var _j = useState(""), coProductionCompanyName = _j[0], setCoProductionCompanyName = _j[1];
    var _k = useState(""), coProductionLogoTimeout = _k[0], setCoProductionLogoTimeout = _k[1];
    var _l = useState([
        { key: 1, name: "", role: "", type: "crew" },
    ]), crewMembers = _l[0], setCrewMembers = _l[1];
    var _m = useState([
        { key: 1, name: "", role: "", type: "actor", category: "main" },
    ]), actors = _m[0], _setActors = _m[1];
    var _o = useState(false), cropModeOn = _o[0], setCropModeOn = _o[1];
    var _p = useState(), selectedImage = _p[0], setSelectedImage = _p[1];
    var _q = useState(), selectedKey = _q[0], setSelectedKey = _q[1];
    var _r = useState({}), director = _r[0], setDirector = _r[1];
    var _s = useState({}), producer = _s[0], setProducer = _s[1];
    var setActors = function (updatedActors) {
        onActorsUpdate(updatedActors);
        _setActors(updatedActors);
    };
    var updateActorImage = function (file, key) { return __awaiter(void 0, void 0, void 0, function () {
        var actor;
        return __generator(this, function (_a) {
            actor = actors.find(function (c) { return c.key === key; });
            actor["src"] = URL.createObjectURL(file);
            actor["file"] = file;
            actor["isEdited"] = true;
            resize(file, imageSize, imageSize, 0, function (dataUrl, passedKey) {
                actor["src"] = dataUrl;
                actor["file"] = dataURItoBlob(dataUrl);
            });
            onActorsUpdate(actors);
            setCropModeOn(false);
            return [2 /*return*/];
        });
    }); };
    var onSubmit = function () {
        onSave(__assign(__assign({}, getData()), { saved: true }), "team");
        window.scrollTo(0, 0);
    };
    var onActorsUpdate = function (updatedArray) {
        onChange(__assign(__assign({}, getData()), { actors: updatedArray }));
    };
    var onCrewMembersUpdate = function (updatedArray) {
        onChange(__assign(__assign({}, getData()), { crewMembers: updatedArray }));
    };
    var getData = function () {
        return {
            productionCompanyName: productionCompanyName,
            productionCompanyWebsite: productionCompanyWebsite,
            coProductionCompanyName: coProductionCompanyName,
            coProductionCompanyWebsite: coProductionCompanyWebsite,
            director: director,
            producer: producer,
            crewMembers: crewMembers,
            actors: actors,
        };
    };
    var updateLogoUrl = function (logoTimeout, website, setLogoUrl, setLogoTimeout) {
        if (logoTimeout)
            clearTimeout(logoTimeout);
        if (website.length < 10 || !validator.isURL(website)) {
            setLogoUrl("");
        }
        else {
            setLogoTimeout(setTimeout(function () { return setLogoUrl(formLogoUrl(website)); }, 500));
        }
    };
    useEffect(function () {
        window.scrollTo(0, 0);
        if (typeof project.productionCompanyName !== "undefined")
            setProductionCompanyName(project.productionCompanyName);
        if (typeof project.productionCompanyWebsite !== "undefined")
            setProductionCompanyWebsite(project.productionCompanyWebsite);
        if (typeof project.coProductionCompanyName !== "undefined")
            setCoProductionCompanyName(project.coProductionCompanyName);
        if (typeof project.coProductionCompanyWebsite !== "undefined")
            setCoProductionCompanyWebsite(project.coProductionCompanyWebsite);
        if (typeof project.actors !== "undefined" && project.actors.length)
            setActors(project.actors);
        if (typeof project.crewMembers !== "undefined" &&
            project.crewMembers.length)
            setCrewMembers(project.crewMembers);
        if (typeof project.director !== "undefined")
            setDirector(project.director);
        else if (typeof project.directorName !== "undefined" &&
            project.directorName)
            setDirector({ isCustom: true, name: project.directorName });
        if (typeof project.producer !== "undefined")
            setProducer(project.producer);
        else if (typeof project.producerName !== "undefined" &&
            project.producerName)
            setProducer({ isCustom: true, name: project.producerName });
    }, [project]);
    useEffect(function () {
        onChange(__assign({}, getData()));
    }, [
        productionCompanyName,
        productionCompanyWebsite,
        coProductionCompanyName,
        coProductionCompanyWebsite,
    ]);
    useEffect(function () {
        updateLogoUrl(productionLogoTimeout, productionCompanyWebsite, setProductionLogoUrl, setProductionLogoTimeout);
    }, [productionCompanyWebsite]);
    useEffect(function () {
        updateLogoUrl(coProductionLogoTimeout, coProductionCompanyWebsite, setCoProductionLogoUrl, setCoProductionLogoTimeout);
    }, [coProductionCompanyWebsite]);
    return (_jsxs("div", __assign({ className: "editprojectteampage-container" }, { children: [_jsx(KPopup, __assign({ active: cropModeOn, onClose: function () { return setCropModeOn(false); } }, { children: _jsx("div", __assign({ className: "edituserbasicpage-cropper-container" }, { children: cropModeOn && (_jsx(EditCropImage, { image: selectedImage || {}, aspectRatio: 1, cropShape: "round", onSaveImage: function (img) {
                            return updateActorImage(img, selectedKey);
                        } })) })) })), _jsx("div", __assign({ className: "editprojectteampage-header" }, { children: "Tell us more about your team" })), _jsx("div", __assign({ className: "editprojectteampage-header-2" }, { children: "Director" })), _jsx("div", __assign({ className: "editprojectteampage-row editprojectteampage-separator editprojectteampage-fullrow" }, { children: _jsx(UserAutocomplete, { label: "Director's name", placeholder: "Director's name", selectedValue: (director === null || director === void 0 ? void 0 : director.user_id) || (director === null || director === void 0 ? void 0 : director.isCustom)
                        ? director
                        : undefined, onSelectedValueChange: function (director) {
                        onChange(__assign(__assign({}, getData()), { director: director }));
                        setDirector(director);
                    } }) })), _jsx("div", __assign({ className: "editprojectteampage-header-2" }, { children: "Producer" })), _jsx("div", __assign({ className: "editprojectteampage-row editprojectteampage-separator editprojectteampage-fullrow" }, { children: _jsx(UserAutocomplete, { label: "Producer's name", placeholder: "Producer's name", selectedValue: (producer === null || producer === void 0 ? void 0 : producer.user_id) || (producer === null || producer === void 0 ? void 0 : producer.isCustom)
                        ? producer
                        : undefined, onSelectedValueChange: function (producer) {
                        onChange(__assign(__assign({}, getData()), { producer: producer }));
                        setProducer(producer);
                    } }) })), _jsx("div", __assign({ className: "editprojectteampage-header-2" }, { children: "Production company" })), _jsxs("div", __assign({ className: "editprojectteampage-row editprojectteampage-separator" }, { children: [_jsx(KTextField, { placeholder: "Company name", label: "Company name", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", value: productionCompanyName || "", backgroundColor: "var(--app-primary-background-color)", onChange: function (event) {
                            return setProductionCompanyName(event.target.value);
                        } }), _jsx(KTextField, { placeholder: "https://", label: "Official website", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", autoCapitalize: "none", value: productionCompanyWebsite || "", backgroundColor: "var(--app-primary-background-color)", onChange: function (event) {
                            return setProductionCompanyWebsite(event.target.value);
                        }, imgSrc: productionLogoUrl })] })), _jsx("div", __assign({ className: "editprojectteampage-header-2" }, { children: "Co-production company" })), _jsxs("div", __assign({ className: "editprojectteampage-row editprojectteampage-separator" }, { children: [_jsx(KTextField, { placeholder: "Company name", label: "Company name", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", value: coProductionCompanyName || "", backgroundColor: "var(--app-primary-background-color)", onChange: function (event) {
                            return setCoProductionCompanyName(event.target.value);
                        } }), _jsx(KTextField, { placeholder: "https://", label: "Official website", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", autoCapitalize: "none", value: coProductionCompanyWebsite || "", backgroundColor: "var(--app-primary-background-color)", onChange: function (event) {
                            return setCoProductionCompanyWebsite(event.target.value);
                        }, imgSrc: coProductionLogoUrl })] })), _jsx("div", __assign({ className: "editprojectteampage-header-2" }, { children: "Actors" })), _jsx("p", __assign({ className: "editprojectteampage-text" }, { children: "Add in some of your film's actors." })), _jsxs("div", __assign({ className: "editprojectteampage-member-wrapper" }, { children: [(actors || []).map(function (member, key) { return (_jsxs("div", __assign({ className: "editprojectteampage-actor-container" }, { children: [_jsxs("div", __assign({ className: "editprojectteampage-actor-wrapper" }, { children: [_jsxs("div", __assign({ className: "editprojectteampage-actor-box" }, { children: [_jsxs("div", __assign({ className: "editprojectteampage-row-actor ".concat(actors.length > 1
                                                    ? "editprojectteampage-actor-delete-on"
                                                    : "") }, { children: [_jsx(KTextField, { placeholder: "Actor's name", label: "Name", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", value: member.name || "", onChange: function (e) {
                                                            member["name"] = e.target.value;
                                                            member["isEdited"] = true;
                                                            var updatedArray = __spreadArray([], actors, true);
                                                            onActorsUpdate(updatedArray);
                                                        } }), _jsx(KTextField, { label: "Role", placeholder: "Actor's role", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", value: member.role || "", onChange: function (e) {
                                                            member["role"] = e.target.value;
                                                            member["isEdited"] = true;
                                                            var updatedArray = __spreadArray([], actors, true);
                                                            onActorsUpdate(updatedArray);
                                                        } })] }), key), _jsxs("div", __assign({ className: "editprojectteampage-actor-image-container" }, { children: [_jsx(KImage, { imgSrc: member.src
                                                            ? member.src
                                                            : getUserProfilePic(member.imagePath), smallImgSrc: member.src
                                                            ? member.src
                                                            : getUserProfilePic(member.smallImagePath), rounded: true }), _jsx("div", __assign({ className: "editprojectteampage-actor-image-edit-button", onClick: function () {
                                                            setCropModeOn(true);
                                                            setSelectedKey(member.key);
                                                            setSelectedImage(member.path || member.src);
                                                        } }, { children: _jsx(IconEdit, {}) }))] }))] })), actors.length > 1 && (_jsx("div", __assign({ className: "editprojectteampage-delete-button", onClick: function () {
                                            return setActors(__spreadArray([], actors.filter(function (c) { return c.key !== member.key; }), true));
                                        } }, { children: _jsx(IconDelete, {}) })))] })), _jsxs("div", __assign({ className: "editprojectteampage-actor-categories" }, { children: [_jsx(KRadioButton, { title: "Main", value: "main", name: "category", selected: member.category === "main", fontSize: "var(--app-font-size-small-1", onChange: function (e) {
                                            member["category"] = e.target.value;
                                            member["isEdited"] = true;
                                            var updatedArray = __spreadArray([], actors, true);
                                            onActorsUpdate(updatedArray);
                                        } }), _jsx(KRadioButton, { title: "Secondary", value: "secondary", name: "category", selected: member.category === "secondary", fontSize: "var(--app-font-size-small-1", onChange: function (e) {
                                            member["category"] = e.target.value;
                                            member["isEdited"] = true;
                                            var updatedArray = __spreadArray([], actors, true);
                                            onActorsUpdate(updatedArray);
                                        } }), _jsx(KRadioButton, { title: "Appearance", value: "appearance", name: "category", selected: member.category === "appearance", fontSize: "var(--app-font-size-small-1", onChange: function (e) {
                                            member["category"] = e.target.value;
                                            member["isEdited"] = true;
                                            var updatedArray = __spreadArray([], actors, true);
                                            onActorsUpdate(updatedArray);
                                        } })] }))] }), key)); }), _jsxs("div", __assign({ className: "editprojectteampage-icon-container", onClick: function () {
                            if (actors.length >= maxActors)
                                return alert("No more than ".concat(maxActors, " actors allowed"));
                            setActors(__spreadArray(__spreadArray([], actors, true), [
                                {
                                    name: "",
                                    role: "",
                                    type: "actor",
                                    category: "main",
                                    key: 1 +
                                        Math.max.apply(Math, actors.map(function (e) { return e.key; })),
                                },
                            ], false));
                        } }, { children: [_jsx(IconAdd, { className: "editprojectteampage-icon" }), _jsx("div", __assign({ className: "editprojectteampage-button-text" }, { children: "add another actor" }))] }))] })), _jsx("div", __assign({ className: "editprojectteampage-header-2" }, { children: "Crew" })), _jsx("p", __assign({ className: "editprojectteampage-text" }, { children: "Add in some of your film's crew members." })), _jsxs("div", __assign({ className: "editprojectteampage-member-wrapper" }, { children: [(crewMembers || []).map(function (member, key) { return (_jsxs("div", __assign({ className: "editprojectteampage-crew-box" }, { children: [_jsxs("div", __assign({ className: "editprojectteampage-row ".concat(crewMembers.length > 1
                                    ? "editprojectteampage-crew-delete-on"
                                    : "") }, { children: [_jsx(KTextField, { placeholder: "Crew member's name", label: "Name", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", value: member.name || "", onChange: function (e) {
                                            member["name"] = e.target.value;
                                            member["isEdited"] = true;
                                            var updatedArray = __spreadArray([], crewMembers, true);
                                            onCrewMembersUpdate(updatedArray);
                                        } }), _jsx(KTextField, { label: "Title", placeholder: "Crew member's title", width: textfieldWidth, marginVertical: "10px", marginRight: "20px", value: member.role || "", onChange: function (e) {
                                            member["role"] = e.target.value;
                                            member["isEdited"] = true;
                                            var updatedArray = __spreadArray([], crewMembers, true);
                                            onCrewMembersUpdate(updatedArray);
                                        } })] })), crewMembers.length > 1 && (_jsx("div", __assign({ className: "editprojectteampage-delete-button", onClick: function () {
                                    return setCrewMembers(__spreadArray([], crewMembers.filter(function (c) { return c.key !== member.key; }), true));
                                } }, { children: _jsx(IconDelete, {}) })))] }), key)); }), _jsxs("div", __assign({ className: "editprojectteampage-icon-container", onClick: function () {
                            if (crewMembers.length >= maxCrewMembers)
                                return alert("No more than ".concat(maxCrewMembers, " crew members allowed"));
                            setCrewMembers(__spreadArray(__spreadArray([], crewMembers, true), [
                                {
                                    name: "",
                                    role: "",
                                    type: "crew",
                                    key: 1 +
                                        Math.max.apply(Math, crewMembers.map(function (e) { return e.key; })),
                                },
                            ], false));
                        } }, { children: [_jsx(IconAdd, { className: "editprojectteampage-icon" }), _jsx("div", __assign({ className: "editprojectteampage-button-text" }, { children: "add another crew member" }))] }))] })), _jsxs("div", __assign({ className: "editprojectteampage-actions" }, { children: [hasNext ? (_jsx(KButton, { title: "Next", variant: "primary", onClick: onSubmit })) : (_jsx(_Fragment, {})), isEditMode ? (_jsx(KButton, { title: "Update", variant: "primary", onClick: onUpdate, outline: hasNext })) : hasNext ? (_jsx(_Fragment, {})) : (_jsx(KButton, { title: "Publish", variant: "primary", onClick: onPublish, outline: hasNext }))] }))] })));
};
export default EditProjectTeamPage;
