var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useRouteMatch } from "react-router";
import { useParams, useHistory, useLocation } from "react-router-dom";
import kachat from "kachat";
import ScriptsList from "../../components/ScriptsList/ScriptsList";
import ListingCard from "../../components/ListingCard/ListingCard";
import EditUserPage from "../EditUserPage/EditUserPage";
import UserSummaryCard from "../../components/UserSummaryCard/UserSummaryCard";
import { getClosedScriptProjects, getClosedFilmProjects, getScriptProjects, getFilmProjects, getScriptCollaborationProjects, getFilmCollaborationProjects, getInterestedProjects, } from "../../redux/selectors/projectSelectors";
import AddProjectForm from "../../components/AddProjectForm/AddProjectForm";
import { thunkLoadUserByUserId, thunkLoadInterestedUsers, thunkUpdateUserCoverImage, thunkSubmitReport, thunkLoadReported, thunkLoadProfileViewsByUserId, thunkLoadUserRatingByUserId, thunkLoadUserRatingAverage, thunkLoadInterestedCowriters, } from "../../redux/thunks/userThunks";
import { thunkLoadProjects, thunkClearProjectInProcess, thunkLoadCollaborationProjects, thunkLoadInterestedProjects, } from "../../redux/thunks/projectThunks";
import { getLoadedUser, getInterestedUsers, isReported, getInterestedCowriters, } from "../../redux/selectors/userSelectors";
import { getUser, getUserTitle, getUserId, } from "../../redux/selectors/authSelectors";
import { isUserScriptwriter, isUserInvestor, isUserFilmmaker, isUserProducer, isUserBuyer, getUserProfilePic, isUserLawyer, } from "../../tools/userTools";
import FilmsList from "../../components/FilmsList/FilmsList";
import KPopup from "../../base-components/KPopup/KPopup";
import { getLocation, getValueInCurrency } from "../../tools/generalTools";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import KButton from "../../base-components/KButton/KButton";
import KTextArea from "../../base-components/KTextArea/KTextArea";
import { canViewMarket, canViewNetwork, } from "../../validators/profileValidator";
import { clearLookingForUserSelectedSearchOptions, clearMarketLookingForUserSelectedSearchOptions, } from "../../redux/actions/userActions";
import { infiniteKeys, infiniteLoadingReload, } from "../../hooks/useInfiniteLoading";
import { getProjectImage, getProjectSmallImage, getProjectSymbolSrc, isScriptProject, } from "../../tools/projectTools";
import "./UserPage.css";
import ExtraInfo from "../ExtraInfo/ExtraInfo";
import { getCountries, getProjectGenres, getProjectSubtypes, } from "../../redux/selectors/generalSelectors";
import LegalitiesCard from "../../components/LegalitiesCard/LegalitiesCard";
import { thunkLoadCountries, thunkLoadProjectGenres, thunkLoadProjectSubtypes, } from "../../redux/thunks/generalThunks";
var UserPage = function (_a) {
    var _b, _c, _d, _e;
    var baseUserId = _a.baseUserId, basePath = _a.basePath, loadedUser = _a.loadedUser, onLoadUser = _a.onLoadUser, onLoadProfileViews = _a.onLoadProfileViews, onLoadUserRatingByUserId = _a.onLoadUserRatingByUserId, onLoadUserRatingAverage = _a.onLoadUserRatingAverage, user = _a.user, countries = _a.countries, projectGenres = _a.projectGenres, projectSubtypes = _a.projectSubtypes, currentUserId = _a.currentUserId, userIsReported = _a.userIsReported, userTitle = _a.userTitle, loadScripts = _a.loadScripts, openScripts = _a.openScripts, scriptCollaborations = _a.scriptCollaborations, filmCollaborations = _a.filmCollaborations, isEditable = _a.isEditable, clearProjectInProcess = _a.clearProjectInProcess, onLoadInterestedUsers = _a.onLoadInterestedUsers, onLoadInterestedCowriters = _a.onLoadInterestedCowriters, onLoadInterestedProjects = _a.onLoadInterestedProjects, onLoadProjectSubtypes = _a.onLoadProjectSubtypes, onLoadProjectGenres = _a.onLoadProjectGenres, onLoadCountries = _a.onLoadCountries, interestedCowriters = _a.interestedCowriters, interestedProjects = _a.interestedProjects, interestedUsers = _a.interestedUsers, onSubmitReport = _a.onSubmitReport, onLoadIsReported = _a.onLoadIsReported, openFilms = _a.openFilms, onClearLookingForOptions = _a.onClearLookingForOptions, onClearMarketLookingForOptions = _a.onClearMarketLookingForOptions, onLoadCollaborationProjects = _a.onLoadCollaborationProjects;
    var location = useLocation();
    var history = useHistory();
    var userId = useParams().userId;
    var _f = useState([]), awardsArray = _f[0], setAwardsArray = _f[1];
    var _g = useState(false), isReady = _g[0], setIsReady = _g[1];
    var _h = useState(false), editMode = _h[0], setEditMode = _h[1];
    var _j = useState(false), report = _j[0], setReport = _j[1];
    var _k = useState(""), reportDescription = _k[0], setReportDescription = _k[1];
    var _l = useState(false), displayAwards = _l[0], setDisplayAwards = _l[1];
    var _m = useState([]), cowritersArray = _m[0], setCowritersArray = _m[1];
    var _o = useState([]), lookingForArray = _o[0], setLookingForArray = _o[1];
    var _p = useState([]), projectsForArray = _p[0], setProjectsForArray = _p[1];
    var _q = useState(false), isMobileMode = _q[0], setIsMobileMode = _q[1];
    var _r = useState(true), editUserPopupOnceClosed = _r[0], setEditUserPopupOnceClosed = _r[1];
    var _s = useState(0), projectCount = _s[0], setProjectCount = _s[1];
    var _t = useState([]), collaborationIds = _t[0], setCollaborationIds = _t[1];
    useEffect(function () {
        setCollaborationIds(scriptCollaborations
            .map(function (p) { return p.project_id; })
            .concat(filmCollaborations.map(function (p) { return p.project_id; })));
    }, [
        JSON.stringify(scriptCollaborations),
        JSON.stringify(filmCollaborations),
    ]);
    useEffect(function () {
        setProjectCount(openScripts.filter(function (script) { return !collaborationIds.includes(script.project_id); }).length +
            openFilms.filter(function (script) { return !collaborationIds.includes(script.project_id); }).length +
            scriptCollaborations.length +
            filmCollaborations.length);
    }, [
        JSON.stringify(collaborationIds),
        JSON.stringify(openScripts),
        JSON.stringify(openFilms),
    ]);
    useDocumentTitle("Scriptofilm | ".concat(isEditable ? "My Profile" : "Profile"));
    var styleContainer = {
        "--userpage-main-section-margin-top": isEditable ? "-74px" : "0px",
    };
    var onEditUser = function () {
        setEditMode(true);
        history.push("/platform/profile/basics");
    };
    var getInvestmentBudget = function (project) {
        return project.project_investment_budget;
    };
    var getCollectedInvestment = function (project) {
        if (!project.project_funding_amounts)
            return 0;
        var collected = 0;
        var amounts = project.project_funding_amounts.split(",");
        var isPrivateArray = project.project_funding_privates.split(",");
        for (var i = 0; i < amounts.length; i++) {
            if (parseInt(isPrivateArray[i]))
                collected += parseInt(amounts[i]);
        }
        return collected;
    };
    var getValueWithCurrency = function (val) {
        return getValueInCurrency(val) || "";
    };
    var getCollectedPercentage = function (collected, total) {
        if (!total)
            return 100;
        return Math.min((100 * collected) / total, 100);
    };
    var handleResize = function () {
        setIsMobileMode(window.innerWidth < 650 ? true : false);
    };
    var submitReport = function (targetId) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSubmitReport(targetId, reportDescription)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, onLoadIsReported(loadedUser.user_id)];
                case 2:
                    _a.sent();
                    setReport(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onReport = function () {
        setReport(true);
    };
    useEffect(function () {
        if (location.pathname.startsWith("/platform/profile") &&
            !user.user_country_id_fk &&
            editMode == false &&
            isReady) {
            onEditUser();
        }
    }, [location, isReady]);
    useEffect(function () {
        var _a;
        if (((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.editProfile) && isReady) {
            onEditUser();
        }
    }, [location, isReady]);
    var getSideSectionsCount = function () {
        var _a;
        return (_a = document
            .getElementsByClassName("userpage-secondary-section")[0]) === null || _a === void 0 ? void 0 : _a.getElementsByClassName("userpage-listing-card").length;
    };
    var getHeader = function () {
        var title = (userTitle || "").toLowerCase();
        if (title == "buyer" || title == "investor")
            return (_jsx(AddProjectForm, { title: "Find new projects", buttonTitle: "visit the market", onClick: function () {
                    clearProjectInProcess();
                    history.push("/platform/market");
                } }));
        return (_jsx(AddProjectForm, { title: "Add new project to collaborate", onClick: function () {
                clearProjectInProcess();
                history.push("/platform/profile/createproject/basics");
            } }));
    };
    var header = (_jsx("div", __assign({ className: "userpage-header-container" }, { children: isEditable ? (_jsx("div", __assign({ className: "userpage-header-form" }, { children: getHeader() }))) : (_jsx(_Fragment, {})) })));
    var buyerData = (_jsx(_Fragment, { children: scriptCollaborations.length === 0 &&
            filmCollaborations.length === 0 ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                            ? "my"
                            : (loadedUser.user_first_name || "").toLowerCase() +
                                "'s", " ", "collaborations"] })), _jsx(FilmsList, { projects: [], emptyTitle: "no collaborations", basePath: basePath })] })) : (_jsxs(_Fragment, { children: [scriptCollaborations.length ||
                    (scriptCollaborations.length === 0 &&
                        filmCollaborations.length === 0) ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "script collaborations"] })), _jsx(ScriptsList, { scripts: isReady ? scriptCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no script collaborations", basePath: basePath })] })) : (_jsx(_Fragment, {})), filmCollaborations.length ||
                    (scriptCollaborations.length === 0 &&
                        filmCollaborations.length === 0) ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "film collaborations"] })), _jsx(FilmsList, { projects: isReady ? filmCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no film collaborations", basePath: basePath })] })) : (_jsx(_Fragment, {}))] })) }));
    var scriptwriterData = (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                        ? "my"
                        : (loadedUser.user_first_name || "").toLowerCase() +
                            "'s", " ", "scripts"] })), _jsx(ScriptsList, { scripts: isReady
                    ? openScripts.filter(function (script) {
                        return !collaborationIds.includes(script.project_id);
                    })
                    : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no scripts", basePath: basePath }), scriptCollaborations.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                                ? ""
                                : (loadedUser.user_first_name || "").toLowerCase() +
                                    "'s", " ", "script deals"] })), _jsx(ScriptsList, { scripts: isReady ? scriptCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no script deals", basePath: basePath })] })) : (_jsx(_Fragment, {}))] }));
    var producerData = (_jsx(_Fragment, { children: openFilms.length === 0 &&
            openScripts.length === 0 &&
            filmCollaborations.length === 0 &&
            scriptCollaborations.length === 0 ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                            ? "my"
                            : (loadedUser.user_first_name || "").toLowerCase() +
                                "'s", " ", "projects"] })), _jsx(FilmsList, { projects: isReady ? openFilms : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no projects", basePath: basePath })] })) : (_jsxs(_Fragment, { children: [openScripts.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "scripts"] })), _jsx(ScriptsList, { scripts: isReady
                                ? openScripts.filter(function (script) {
                                    return !collaborationIds.includes(script.project_id);
                                })
                                : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no scripts", basePath: basePath })] })) : (_jsx(_Fragment, {})), openFilms.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "films"] })), _jsx(FilmsList, { projects: isReady
                                ? openFilms.filter(function (film) {
                                    return !collaborationIds.includes(film.project_id);
                                })
                                : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no films", basePath: basePath })] })) : (_jsx(_Fragment, {})), scriptCollaborations.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                                    ? ""
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "script deals"] })), _jsx(ScriptsList, { scripts: isReady ? scriptCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no script deals", basePath: basePath })] })) : (_jsx(_Fragment, {})), filmCollaborations.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userpage-title" }, { children: ["_", isEditable
                                    ? ""
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "film deals"] })), _jsx(FilmsList, { projects: isReady ? filmCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no film deals", basePath: basePath })] })) : (_jsx(_Fragment, {}))] })) }));
    useEffect(function () {
        onLoadProjectSubtypes();
        onLoadProjectGenres();
        onLoadCountries();
    }, []);
    useEffect(function () {
        if (!isReady)
            return;
        if (loadedUser.user_awards) {
            var array = [];
            for (var i = 0; i < loadedUser.user_awards.length; i++) {
                array.push({
                    title: loadedUser.user_awards[i].user_award_festival,
                    info1: loadedUser.user_awards[i].user_award_prize,
                    info2: loadedUser.user_awards[i].user_award_title,
                });
            }
            setAwardsArray(array);
        }
        if (loadedUser.user_id) {
            onLoadIsReported(loadedUser.user_id);
            onLoadInterestedUsers(loadedUser.user_user_title_id_fk);
            onLoadInterestedProjects(loadedUser.user_user_title_id_fk);
            onLoadCollaborationProjects(loadedUser.user_auth_id);
            if (isUserScriptwriter(user.user_title_name) &&
                isUserScriptwriter(loadedUser.user_title_name))
                onLoadInterestedCowriters(loadedUser.user_user_title_id_fk);
        }
    }, [loadedUser, isReady]);
    useEffect(function () {
        if (isReady && interestedUsers) {
            var array = [];
            for (var i = 0; i < interestedUsers.length; i++) {
                if (interestedUsers[i].user_id != loadedUser.user_id) {
                    array.push({
                        title: interestedUsers[i].user_first_name +
                            " " +
                            interestedUsers[i].user_last_name,
                        info1: getLocation(interestedUsers[i].country_name, interestedUsers[i].city_name),
                        info2: interestedUsers[i].user_title_name,
                        imgBorderColor: "var(--app-".concat(interestedUsers[i].user_title_name.toLowerCase(), "-color)"),
                        smallImgSrc: getUserProfilePic(interestedUsers[i].user_small_image_path),
                        imgSrc: getUserProfilePic(interestedUsers[i].user_image_path),
                        href: "/platform/network/user/".concat(interestedUsers[i].user_id),
                    });
                }
            }
            setLookingForArray(array);
        }
    }, [isReady, JSON.stringify(interestedUsers)]);
    useEffect(function () {
        if (isReady && interestedCowriters) {
            var array = [];
            for (var i = 0; i < interestedCowriters.length; i++) {
                if (interestedCowriters[i].user_id != loadedUser.user_id) {
                    array.push({
                        title: interestedCowriters[i].user_first_name +
                            " " +
                            interestedCowriters[i].user_last_name,
                        info1: getLocation(interestedCowriters[i].country_name, interestedCowriters[i].city_name),
                        info2: interestedCowriters[i].user_title_name,
                        imgBorderColor: "var(--app-".concat(interestedCowriters[i].user_title_name.toLowerCase(), "-color)"),
                        smallImgSrc: getUserProfilePic(interestedCowriters[i].user_small_image_path),
                        imgSrc: getUserProfilePic(interestedCowriters[i].user_image_path),
                        href: "/platform/network/user/".concat(interestedCowriters[i].user_id),
                    });
                }
            }
            setCowritersArray(array);
        }
    }, [isReady, JSON.stringify(interestedCowriters)]);
    useEffect(function () {
        if (isReady && interestedProjects) {
            var array = [];
            for (var i = 0; i < interestedProjects.length; i++) {
                if (interestedProjects[i].project_user_id_fk !=
                    loadedUser.user_id) {
                    array.push({
                        title: interestedProjects[i].project_title,
                        info1: getLocation(interestedProjects[i].country_name, interestedProjects[i].city_name),
                        info2: interestedProjects[i].project_type_name,
                        imgBorderColor: "var(--app-".concat(isScriptProject(interestedProjects[i].project_type_name)
                            ? "scriptwriter"
                            : "filmmaker", "-color)"),
                        backgroundImage: getProjectSymbolSrc(interestedProjects[i].project_type_name),
                        smallImgSrc: getProjectImage(interestedProjects[i]),
                        imgSrc: getProjectSmallImage(interestedProjects[i]),
                        href: "/platform/market/project/".concat(interestedProjects[i].project_id),
                    });
                }
            }
            setProjectsForArray(array);
        }
    }, [isReady, JSON.stringify(interestedProjects)]);
    useEffect(function () {
        var id = userId || baseUserId;
        if (id) {
            var loadData = function (id) { return __awaiter(void 0, void 0, void 0, function () {
                var allPromise;
                return __generator(this, function (_a) {
                    allPromise = Promise.all([onLoadUser(id)]);
                    allPromise.then(function () {
                        setIsReady(true);
                        loadScripts(id);
                        onLoadProfileViews(id);
                        onLoadUserRatingByUserId(id);
                        onLoadUserRatingAverage(id);
                    });
                    return [2 /*return*/];
                });
            }); };
            window.scrollTo(0, 0);
            loadData(id);
        }
    }, [userId, baseUserId]);
    useEffect(function () {
        if (Object.keys(user).length) {
            setEditUserPopupOnceClosed(user.user_country_id_fk);
        }
    }, [user]);
    var routeMatch = useRouteMatch();
    useEffect(function () {
        handleResize();
        window.addEventListener("resize", handleResize);
        if (user.user_id === parseInt(routeMatch.params.userId)) {
            history.push("/platform/profile");
        }
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (_jsx("div", __assign({ className: "userpage-container", style: styleContainer }, { children: isReady && loadedUser.user_id ? (_jsxs(_Fragment, { children: [_jsx(KPopup, __assign({ active: displayAwards, onClose: function () { return setDisplayAwards(false); } }, { children: _jsx(ListingCard, { title: "awards", buttonTitle: "view all", items: awardsArray, full: true }) })), _jsx(KPopup, __assign({ active: editMode, onClose: function () {
                        setEditMode(false);
                    }, closable: editUserPopupOnceClosed }, { children: _jsx(EditUserPage, { onEdit: onEditUser, visible: editMode, onExit: function () {
                            setEditMode(false);
                            window.history.replaceState(null, null, "/platform/profile");
                        }, editUserPopupOnceClosed: editUserPopupOnceClosed, setEditUserPopupOnceClosed: setEditUserPopupOnceClosed }) })), _jsx(KPopup, __assign({ active: report, onClose: function () { return setReport(false); } }, { children: _jsx("div", __assign({ className: "report-container" }, { children: _jsxs("div", __assign({ className: "report-content" }, { children: [_jsxs("p", { children: ["Report ", loadedUser.user_first_name] }), _jsx(KTextArea, { label: "Description (optional)", value: reportDescription, marginVertical: "10px", onChange: function (event) {
                                        return setReportDescription(event.target.value);
                                    }, placeholder: "Description (optional)" }), _jsx(KButton, { title: "Submit", variant: "primary", marginVertical: "20px", onClick: function () {
                                        return submitReport(loadedUser.user_id);
                                    } })] })) })) })), _jsx("h1", { children: userIsReported }), _jsx("div", __assign({ className: "userpage-header-section" }, { children: _jsx(UserSummaryCard, { user: loadedUser || {}, isEditable: isEditable, onEdit: onEditUser, isReported: userIsReported, projectCount: projectCount, onCollaborate: function () {
                            kachat.createNewChat(loadedUser.user_kachat_id);
                            history.push("/platform/messaging");
                        }, onReport: onReport }) })), isUserInvestor(loadedUser.user_title_name) &&
                    isMobileMode &&
                    ((loadedUser.user_detail_legal_name &&
                        loadedUser.user_detail_website) ||
                        ((_b = loadedUser.investment_genres) === null || _b === void 0 ? void 0 : _b.length) ||
                        ((_c = loadedUser.investment_countries) === null || _c === void 0 ? void 0 : _c.length)) ? (_jsx("div", __assign({ className: "userpage-listing-card userpage-listing-legalities" }, { children: _jsx(LegalitiesCard, { user: loadedUser, countries: countries, projectGenres: projectGenres, projectSubtypes: projectSubtypes, onEdit: isEditable
                            ? function () {
                                setEditMode(true);
                                history.push("/platform/profile/legalities");
                            }
                            : null }) }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "userpage-body-sections", style: {
                        display: isUserLawyer(loadedUser.user_title_name || "")
                            ? "none"
                            : "flex",
                    } }, { children: [_jsxs("div", __assign({ className: "userpage-main-section" }, { children: [header, isUserInvestor(loadedUser.user_title_name) &&
                                    scriptCollaborations.concat(filmCollaborations)
                                        .length ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "userpage-title" }, { children: "_investment pool" })), _jsx("div", __assign({ className: "userpage-investment-section" }, { children: scriptCollaborations
                                                .concat(filmCollaborations)
                                                .map(function (project, key) {
                                                var _a, _b, _c, _d;
                                                return (_jsxs("div", __assign({ className: "userpage-investment-entry" }, { children: [_jsx("div", __assign({ className: "userpage-investment-title" }, { children: project.project_title })), _jsxs("div", __assign({ className: "userpage-investment-fill-box" }, { children: [_jsx("div", __assign({ className: "userpage-investment-collected-fill", style: {
                                                                        width: "".concat(getCollectedPercentage(getCollectedInvestment(project), getInvestmentBudget(project)), "%"),
                                                                    } }, { children: _jsx("div", __assign({ className: "userpage-investment-collected" }, { children: getValueWithCurrency(getCollectedInvestment(project)) })) })), _jsx("div", __assign({ className: "userpage-investment-budget", style: {
                                                                        top: ((_a = document.getElementsByClassName("userpage-investment-collected-fill")[key]) === null || _a === void 0 ? void 0 : _a.clientWidth) >
                                                                            ((_b = document.getElementsByClassName("userpage-investment-fill-box")[key]) === null || _b === void 0 ? void 0 : _b.clientWidth) -
                                                                                80
                                                                            ? "-36px"
                                                                            : "",
                                                                        right: ((_c = document.getElementsByClassName("userpage-investment-collected-fill")[key]) === null || _c === void 0 ? void 0 : _c.clientWidth) >
                                                                            ((_d = document.getElementsByClassName("userpage-investment-fill-box")[key]) === null || _d === void 0 ? void 0 : _d.clientWidth) -
                                                                                80
                                                                            ? "0px"
                                                                            : "",
                                                                    } }, { children: getValueWithCurrency(getInvestmentBudget(project)) }))] }))] }), key));
                                            }) }))] })) : (_jsx(_Fragment, {})), isUserScriptwriter(loadedUser.user_title_name || "") ? (scriptwriterData) : (_jsx(_Fragment, {})), isUserFilmmaker(loadedUser.user_title_name || "") ? (producerData) : (_jsx(_Fragment, {})), isUserProducer(loadedUser.user_title_name || "") ? (producerData) : (_jsx(_Fragment, {})), isUserInvestor(loadedUser.user_title_name || "") ? (buyerData) : (_jsx(_Fragment, {})), isUserBuyer(loadedUser.user_title_name || "") ? (buyerData) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "userpage-secondary-section ".concat(getSideSectionsCount() === 1
                                ? "userpage-single-side-section"
                                : ""), style: {
                                display: getSideSectionsCount() === 0 ? "none" : "",
                                marginTop: isEditable
                                    ? "0px"
                                    : "calc(40px + 2 * var(--app-section-spacing))",
                            } }, { children: [isUserInvestor(loadedUser.user_title_name) &&
                                    !isMobileMode &&
                                    ((loadedUser.user_detail_legal_name &&
                                        loadedUser.user_detail_website) ||
                                        ((_d = loadedUser.investment_genres) === null || _d === void 0 ? void 0 : _d.length) ||
                                        ((_e = loadedUser.investment_countries) === null || _e === void 0 ? void 0 : _e.length)) ? (_jsx("div", __assign({ className: "userpage-listing-card" }, { children: _jsx(LegalitiesCard, { user: loadedUser, countries: countries, projectGenres: projectGenres, projectSubtypes: projectSubtypes, onEdit: isEditable
                                            ? function () {
                                                setEditMode(true);
                                                history.push("/platform/profile/legalities");
                                            }
                                            : null }) }))) : (_jsx(_Fragment, {})), awardsArray.length ? (_jsx("div", __assign({ className: "userpage-listing-card" }, { children: _jsx(ListingCard, { title: "awards", buttonTitle: "view all", items: awardsArray, onEdit: isEditable
                                            ? function () {
                                                setEditMode(true);
                                                history.push("/platform/profile/awards");
                                            }
                                            : null, onClick: function (event) {
                                            return setDisplayAwards(true);
                                        } }) }))) : (_jsx(_Fragment, {})), canViewNetwork() && lookingForArray.length ? (_jsx("div", __assign({ className: "userpage-listing-card" }, { children: _jsx(ListingCard, { emptyTitle: "no interested users yet", title: "looking for ".concat((loadedUser.user_title_name || "").toLowerCase(), "s"), items: lookingForArray, onClick: function () {
                                            infiniteLoadingReload(infiniteKeys.NETWORK);
                                            onClearLookingForOptions();
                                            history.push("/platform/network?lookingfor=".concat(loadedUser.user_title_id));
                                        } }) }))) : (_jsx(_Fragment, {})), cowritersArray.length &&
                                    canViewNetwork() &&
                                    isUserScriptwriter(user.user_title_name) &&
                                    isUserScriptwriter(loadedUser.user_title_name) ? (_jsx("div", __assign({ className: "userpage-listing-card" }, { children: _jsx(ListingCard, { emptyTitle: "no interested users yet", title: "find co-writers", items: cowritersArray, onClick: function () {
                                            infiniteLoadingReload(infiniteKeys.NETWORK);
                                            onClearLookingForOptions();
                                            history.push("/platform/network?lookingfor=".concat(loadedUser.user_title_id, "&cowriters=true"));
                                        } }) }))) : (_jsx(_Fragment, {})), canViewMarket() &&
                                    projectsForArray.length &&
                                    (isUserProducer(loadedUser.user_title_name) ||
                                        isUserInvestor(loadedUser.user_title_name) ||
                                        isUserBuyer(loadedUser.user_title_name)) ? (_jsx("div", __assign({ className: "userpage-listing-card" }, { children: _jsx(ListingCard, { emptyTitle: "no interested users yet", title: "projects for ".concat((loadedUser.user_title_name || "").toLowerCase(), "s"), items: projectsForArray, onClick: function () {
                                            infiniteLoadingReload(infiniteKeys.NETWORK);
                                            onClearMarketLookingForOptions();
                                            history.push("/platform/market?lookingfor=".concat(loadedUser.user_title_id));
                                        } }) }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "userpage-extrainfo" }, { children: [_jsx(ExtraInfo, {}), " "] }))] }))] }))] })) : (_jsx(_Fragment, {})) })));
};
var mapStateToProps = function (state) { return ({
    user: getUser(state),
    countries: getCountries(state),
    projectGenres: getProjectGenres(state),
    projectSubtypes: getProjectSubtypes(state),
    userIsReported: isReported(state),
    currentUserId: getUserId(state),
    userTitle: getUserTitle(state),
    loadedUser: getLoadedUser(state),
    interestedUsers: getInterestedUsers(state),
    interestedCowriters: getInterestedCowriters(state),
    interestedProjects: getInterestedProjects(state),
    openScripts: getScriptProjects(state),
    closedScripts: getClosedScriptProjects(state),
    openFilms: getFilmProjects(state),
    closedFilms: getClosedFilmProjects(state),
    scriptCollaborations: getScriptCollaborationProjects(state),
    filmCollaborations: getFilmCollaborationProjects(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    loadScripts: function (userId) { return dispatch(thunkLoadProjects(userId)); },
    onLoadUser: function (userId) { return dispatch(thunkLoadUserByUserId(userId)); },
    onLoadProjectGenres: function () { return dispatch(thunkLoadProjectGenres()); },
    onLoadProjectSubtypes: function () { return dispatch(thunkLoadProjectSubtypes()); },
    onLoadCountries: function () { return dispatch(thunkLoadCountries()); },
    onSubmitReport: function (targetId, reportDescription) {
        return dispatch(thunkSubmitReport(targetId, reportDescription));
    },
    onLoadIsReported: function (targetId) { return dispatch(thunkLoadReported(targetId)); },
    onLoadProfileViews: function (userId) {
        return dispatch(thunkLoadProfileViewsByUserId(userId));
    },
    onLoadInterestedUsers: function (titleId) {
        return dispatch(thunkLoadInterestedUsers(titleId));
    },
    onLoadInterestedCowriters: function (titleId) {
        return dispatch(thunkLoadInterestedCowriters(titleId));
    },
    onLoadInterestedProjects: function (titleId) {
        return dispatch(thunkLoadInterestedProjects(titleId));
    },
    clearProjectInProcess: function () { return dispatch(thunkClearProjectInProcess()); },
    updateUserCover: function (file) { return dispatch(thunkUpdateUserCoverImage(file)); },
    onLoadUserRatingByUserId: function (userId) {
        return dispatch(thunkLoadUserRatingByUserId(userId));
    },
    onLoadUserRatingAverage: function (userId) {
        return dispatch(thunkLoadUserRatingAverage(userId));
    },
    onLoadCollaborationProjects: function (authId) {
        return dispatch(thunkLoadCollaborationProjects(authId));
    },
    onClearLookingForOptions: function () {
        return dispatch(clearLookingForUserSelectedSearchOptions());
    },
    onClearMarketLookingForOptions: function () {
        return dispatch(clearMarketLookingForUserSelectedSearchOptions());
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UserPage);
