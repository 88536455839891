import * as yup from "yup";
export var revisionSchema = yup.object().shape({
    title: yup.string().required("Title is required").nullable().trim(),
    summary: yup.string().required("Summary is required").nullable().trim(),
});
export var recommendLawyerSchema = yup.object().shape({
    lawyerName: yup.string().required("Lawyer Name is required"),
    lawyerEmail: yup
        .string()
        .email("Wrong email format")
        .required("Lawyer Email is required"),
    lawyerPhone: yup.string().required("Lawyer phone is required"),
});
export var contractRequestSchema = yup.object().shape({
    parties: yup.array().of(yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        profession: yup.string().required("Profession is required"),
        country: yup.object().required("Country is required"),
        address: yup.string().required("Address is required"),
        mobile: yup.string().required("Mobile is required"),
        phone: yup.string().required("Phone is required"),
    })),
    title: yup.string().required("Title is required"),
    summary: yup.string().required("Summary is required"),
    typeName: yup.object().required("Type is required"),
    language: yup.object().required("Language is required"),
    dealBudget: yup.string().required("Deal budget is required"),
    timeframe: yup.string().required("Timeframe is required"),
    country: yup.object().required("Country is required"),
    territory: yup.string().notRequired(),
    // territory: yup.string().required("Territory is required"),
});
