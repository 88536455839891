var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Footer from "../../mui-components/footer/footer";
import ContactUsBody from "../../components/ContactUsBody/ContactUsBody";
import NavBar from "../../components/NavBar/NavBar";
import { thunkStopLoading } from "../../redux/thunks/generalThunks";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import KPopup from "../../base-components/KPopup/KPopup";
import ReferalCard from "../../components/ReferalCard/ReferalCard";
import { history } from "../../redux/store";
import { useLocation } from "react-router";
var ContactUsPage = function (_a) {
    var onStopLoading = _a.onStopLoading;
    var location = useLocation();
    var query = new URLSearchParams(location.search);
    useDocumentTitle("Scriptofilm | Contact Us");
    useEffect(function () {
        window.scrollTo({ top: 0 });
        onStopLoading();
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "landingpage-container" }, { children: [_jsx(KPopup, __assign({ active: query.get("refer") === "true", onClose: function () { return history.push(location.pathname); } }, { children: _jsx(ReferalCard, { onClose: function () { return history.push(location.pathname); } }) })), _jsxs(Fragment, { children: [_jsx(NavBar, { showLine: "blue" }), _jsx(ContactUsBody, {}), _jsx(Footer, {})] })] })) }));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    onStopLoading: function () { return dispatch(thunkStopLoading()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ContactUsPage);
