import { getUserTitle } from "./authSelectors";
export var getIsLoading = function (state) {
    return (state.general.isLoading |
        state.projects.isLoading |
        state.users.isLoading |
        state.user.isLoading |
        state.deals.isLoading |
        state.ndas.isLoading |
        state.email.isLoading |
        state.notification.isLoading |
        state.contract.isLoading);
};
export var getProgress = function (state) {
    return state.general.progress;
};
export var getProgressStatus = function (state) {
    return state.general.progressStatus;
};
export var getColorMode = function (state) {
    return state.general.colorMode;
};
export var getPrimaryColor = function (state) {
    return getUserTitle(state) || state.general.primaryColor;
};
export var getUserTitles = function (state) {
    return state.general.userTitles;
};
export var getProjectTypes = function (state) {
    return state.general.projectTypes;
};
export var getProjectSubtypes = function (state) {
    return state.general.projectSubtypes;
};
export var getProjectKinds = function (state) {
    return state.general.projectKinds;
};
export var getProjectGenres = function (state) {
    return state.general.projectGenres;
};
export var getProjectStages = function (state) {
    return state.general.projectStages;
};
export var getLanguages = function (state) {
    return state.general.languages;
};
export var getCountries = function (state) {
    return state.general.countries;
};
export var getCities = function (state) {
    return state.general.cities;
};
