var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from "react";
import PropTypes from "prop-types";
import useOutsideAlert from "../../hooks/useOutsideAlert";
import "./KButton.css";
var KButton = function (_a) {
    var _b = _a.title, title = _b === void 0 ? "K-Button" : _b, onClick = _a.onClick, href = _a.href, icon = _a.icon, height = _a.height, width = _a.width, paddingVertical = _a.paddingVertical, paddingHorizontal = _a.paddingHorizontal, marginVertical = _a.marginVertical, marginHorizontal = _a.marginHorizontal, color = _a.color, backgroundColor = _a.backgroundColor, _c = _a.type, type = _c === void 0 ? "button" : _c, _d = _a.borderRadius, borderRadius = _d === void 0 ? "5px" : _d, _e = _a.variant, variant = _e === void 0 ? "dark" : _e, _f = _a.iconRight, iconRight = _f === void 0 ? false : _f, _g = _a.outline, outline = _g === void 0 ? false : _g, _h = _a.listItems, listItems = _h === void 0 ? [] : _h, _j = _a.applyDisabled, applyDisabled = _j === void 0 ? null : _j, _k = _a.target, target = _k === void 0 ? "" : _k;
    var iconLeftClass = "kbutton-icon-left";
    var iconRightClass = "kbutton-icon-right";
    var noTitleClass = title === "" ? "kbutton-no-title" : "";
    var buttonStyleClass = "kbutton-".concat(variant, " kbutton-").concat(outline ? "outline" : "full");
    var dropdownRef = useRef(null);
    var dropdownClosed = "kbutton-closed", dropdownOpen = "kbutton-open";
    var _l = useState(dropdownClosed), dropdownState = _l[0], setDropdownState = _l[1];
    var Icon = icon ? (icon({
        className: "kbutton-icon ".concat(title === ""
            ? "kbutton-no-title"
            : iconRight
                ? iconRightClass
                : iconLeftClass),
    })) : (_jsx(_Fragment, {}));
    var handleClick = function (event) {
        if (href && onClick === KButton.defaultProps.onClick) {
            return;
        }
        else {
            event.preventDefault();
            onClick(event);
        }
    };
    var toggleDropdown = function () {
        setDropdownState(dropdownState === dropdownClosed ? dropdownOpen : dropdownClosed);
    };
    var closeDropdown = function (operation) {
        operation();
        setDropdownState(dropdownClosed);
    };
    var styleContainer = {
        "--kbutton-height": height || "14px",
        "--kbutton-width": width || "14px",
        "--kbutton-border-radius": borderRadius,
        "--kbutton-padding-vertical": paddingVertical || "7px",
        "--kbutton-padding-horizontal": paddingHorizontal || "10px",
        "--kbutton-margin-vertical": marginVertical || "0px",
        "--kbutton-margin-horizontal": marginHorizontal || "2px",
        "--kbutton-text-color": color,
        "--kbutton-background-color": backgroundColor,
    };
    var handleOnClick = function () {
        if (listItems.length > 0)
            toggleDropdown();
        else
            onClick();
    };
    useOutsideAlert(dropdownRef, function () { return setDropdownState(dropdownClosed); });
    return (_jsxs("div", __assign({ className: "kbutton-main-container", ref: dropdownRef }, { children: [href ? (_jsx("a", __assign({ className: "kbutton-container ".concat(buttonStyleClass, " ").concat(noTitleClass), href: href, target: target, style: styleContainer, onClick: handleClick }, { children: _jsxs("button", __assign({ className: "kbutton-button", type: type }, { children: [Icon, _jsx("div", __assign({ className: title === "" || !icon
                                ? "kbutton-single"
                                : iconRight
                                    ? iconRightClass
                                    : iconLeftClass }, { children: title }))] })) }))) : (_jsxs("a", __assign({ className: "kbutton-container ".concat(buttonStyleClass, " ").concat(noTitleClass), style: styleContainer }, { children: [applyDisabled === null && (_jsxs("button", __assign({ className: "kbutton-button", onClick: handleOnClick, type: type }, { children: [Icon, _jsx("div", __assign({ className: title === "" || !icon
                                    ? "kbutton-single"
                                    : iconRight
                                        ? iconRightClass
                                        : iconLeftClass }, { children: title }))] }))), applyDisabled !== null && (_jsxs("button", __assign({ className: "kbutton-button ".concat(applyDisabled && "kbutton-disabled"), onClick: function () { return !applyDisabled && handleOnClick(); }, type: type }, { children: [Icon, _jsx("div", __assign({ className: title === "" || !icon
                                    ? "kbutton-single"
                                    : iconRight
                                        ? iconRightClass
                                        : iconLeftClass }, { children: title }))] })))] }))), _jsx("div", __assign({ className: "kbutton-content ".concat(dropdownState) }, { children: listItems
                    .filter(function (item) { return !item.hidden; })
                    .map(function (item, key) { return (_jsx("a", __assign({ href: item.href, target: item.target, onClick: item.href
                        ? function (f) { return f; }
                        : !item.disabled
                            ? function () { return closeDropdown(item.onClick); }
                            : function (event) { return event.preventDefault(); }, style: {
                        color: item.disabled
                            ? "var(--app-font-color-lightest)"
                            : "",
                    } }, { children: item.title }), key)); }) }))] })));
};
KButton.propTypes = {
    href: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
KButton.defaultProps = {
    href: "",
    onClick: function () { return alert("Feature coming soon"); },
};
export default KButton;
