var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { AiFillProject as IconProjects } from "react-icons/ai";
import ScriptsList from "../../components/ScriptsList/ScriptsList";
import { getClosedScriptProjects, getClosedFilmProjects, getScriptProjects, getFilmProjects, getScriptCollaborationProjects, getFilmCollaborationProjects, } from "../../redux/selectors/projectSelectors";
import AddProjectForm from "../../components/AddProjectForm/AddProjectForm";
import { thunkLoadUserByUserId, thunkUpdateUserCoverImage, thunkLoadProfileViewsByUserId, } from "../../redux/thunks/userThunks";
import { thunkLoadProjects, thunkClearProjectInProcess, thunkLoadCollaborationProjects, } from "../../redux/thunks/projectThunks";
import { getLoadedUser } from "../../redux/selectors/userSelectors";
import { getUser, getUserTitle, getUserId, } from "../../redux/selectors/authSelectors";
import { isUserScriptwriter, isUserInvestor, isUserFilmmaker, isUserProducer, isUserBuyer, isUserLawyer, } from "../../tools/userTools";
import FilmsList from "../../components/FilmsList/FilmsList";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import "./MyProjectsListPage.css";
import { thunkLoadCountries, thunkLoadProjectGenres, thunkLoadProjectSubtypes, } from "../../redux/thunks/generalThunks";
var MyProjectsListPage = function (_a) {
    var baseUserId = _a.baseUserId, basePath = _a.basePath, loadedUser = _a.loadedUser, onLoadUser = _a.onLoadUser, onLoadProfileViews = _a.onLoadProfileViews, user = _a.user, currentUserId = _a.currentUserId, userTitle = _a.userTitle, loadScripts = _a.loadScripts, openScripts = _a.openScripts, scriptCollaborations = _a.scriptCollaborations, filmCollaborations = _a.filmCollaborations, onLoadCollaborationProjects = _a.onLoadCollaborationProjects, isEditable = _a.isEditable, clearProjectInProcess = _a.clearProjectInProcess, onLoadProjectSubtypes = _a.onLoadProjectSubtypes, onLoadProjectGenres = _a.onLoadProjectGenres, onLoadCountries = _a.onLoadCountries, openFilms = _a.openFilms;
    var location = useLocation();
    var history = useHistory();
    var userId = useParams().userId;
    var _b = useState(false), isReady = _b[0], setIsReady = _b[1];
    var _c = useState(false), editMode = _c[0], setEditMode = _c[1];
    var _d = useState([]), collaborationIds = _d[0], setCollaborationIds = _d[1];
    useEffect(function () {
        setCollaborationIds(scriptCollaborations
            .map(function (p) { return p.project_id; })
            .concat(filmCollaborations.map(function (p) { return p.project_id; })));
    }, [
        JSON.stringify(scriptCollaborations),
        JSON.stringify(filmCollaborations),
    ]);
    useDocumentTitle("Scriptofilm | My Projects");
    var styleContainer = {
        "--myprojectslistpage-main-section-margin-top": isEditable
            ? "-74px"
            : "0px",
    };
    var onEditUser = function () {
        setEditMode(true);
        history.push("/platform/profile/basics");
    };
    useEffect(function () {
        if (location.pathname.startsWith("/platform/profile") &&
            !user.user_country_id_fk &&
            editMode == false &&
            isReady) {
            onEditUser();
        }
    }, [location, isReady]);
    useEffect(function () {
        var _a;
        if (((_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.editProfile) && isReady) {
            onEditUser();
        }
    }, [location, isReady]);
    var getHeader = function () {
        var title = (userTitle || "").toLowerCase();
        if (title == "buyer" || title == "investor")
            return (_jsx(AddProjectForm, { title: "Find new projects", buttonTitle: "visit the market", onClick: function () {
                    clearProjectInProcess();
                    history.push("/platform/market");
                } }));
        return (_jsx(AddProjectForm, { title: "Add new project to collaborate", onClick: function () {
                clearProjectInProcess();
                history.push("/platform/myprojects/createproject/basics");
            } }));
    };
    var header = (_jsx("div", __assign({ className: "myprojectslistpage-header-container" }, { children: isEditable ? (_jsx("div", __assign({ className: "myprojectslistpage-header-form" }, { children: getHeader() }))) : (_jsx(_Fragment, {})) })));
    var buyerData = (_jsx(_Fragment, { children: scriptCollaborations.length === 0 &&
            filmCollaborations.length === 0 ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                            ? "my"
                            : (loadedUser.user_first_name || "").toLowerCase() +
                                "'s", " ", "collaborations"] })), _jsx(FilmsList, { projects: [], emptyTitle: "no collaborations", basePath: basePath })] })) : (_jsxs(_Fragment, { children: [scriptCollaborations.length ||
                    (scriptCollaborations.length === 0 &&
                        filmCollaborations.length === 0) ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "script collaborations"] })), _jsx(ScriptsList, { scripts: isReady ? scriptCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no script collaborations", basePath: basePath })] })) : (_jsx(_Fragment, {})), filmCollaborations.length ||
                    (scriptCollaborations.length === 0 &&
                        filmCollaborations.length === 0) ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "film collaborations"] })), _jsx(FilmsList, { projects: isReady ? filmCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no film collaborations", basePath: basePath })] })) : (_jsx(_Fragment, {}))] })) }));
    var scriptwriterData = (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                        ? "my"
                        : (loadedUser.user_first_name || "").toLowerCase() +
                            "'s", " ", "scripts"] })), _jsx(ScriptsList, { scripts: isReady
                    ? openScripts.filter(function (script) {
                        return !collaborationIds.includes(script.project_id);
                    })
                    : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no scripts", basePath: basePath }), scriptCollaborations.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                                ? ""
                                : (loadedUser.user_first_name || "").toLowerCase() +
                                    "'s", " ", "script deals"] })), _jsx(ScriptsList, { scripts: isReady ? scriptCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no script deals", basePath: basePath })] })) : (_jsx(_Fragment, {}))] }));
    var producerData = (_jsx(_Fragment, { children: openFilms.length === 0 &&
            openScripts.length === 0 &&
            filmCollaborations.length === 0 &&
            scriptCollaborations.length === 0 ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                            ? "my"
                            : (loadedUser.user_first_name || "").toLowerCase() +
                                "'s", " ", "projects"] })), _jsx(FilmsList, { projects: isReady ? openFilms : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no projects", basePath: basePath })] })) : (_jsxs(_Fragment, { children: [openScripts.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "scripts"] })), _jsx(ScriptsList, { scripts: isReady
                                ? openScripts.filter(function (script) {
                                    return !collaborationIds.includes(script.project_id);
                                })
                                : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no scripts", basePath: basePath })] })) : (_jsx(_Fragment, {})), openFilms.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                                    ? "my"
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "films"] })), _jsx(FilmsList, { projects: isReady
                                ? openFilms.filter(function (film) {
                                    return !collaborationIds.includes(film.project_id);
                                })
                                : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no films", basePath: basePath })] })) : (_jsx(_Fragment, {})), scriptCollaborations.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                                    ? ""
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "script deals"] })), _jsx(ScriptsList, { scripts: isReady ? scriptCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, emptyTitle: "no script deals", basePath: basePath })] })) : (_jsx(_Fragment, {})), filmCollaborations.length ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-title" }, { children: ["_", isEditable
                                    ? ""
                                    : (loadedUser.user_first_name || "").toLowerCase() + "'s", " ", "film deals"] })), _jsx(FilmsList, { projects: isReady ? filmCollaborations : [], authUserId: currentUserId, loadedUser: loadedUser, currentUserId: currentUserId, emptyTitle: "no film deals", basePath: basePath })] })) : (_jsx(_Fragment, {}))] })) }));
    useEffect(function () {
        onLoadProjectSubtypes();
        onLoadProjectGenres();
        onLoadCountries();
    }, []);
    useEffect(function () {
        if (currentUserId) {
            var loadData = function (id) { return __awaiter(void 0, void 0, void 0, function () {
                var allPromise;
                return __generator(this, function (_a) {
                    allPromise = Promise.all([onLoadUser(currentUserId)]);
                    allPromise.then(function () {
                        setIsReady(true);
                        loadScripts(currentUserId);
                        onLoadProfileViews(currentUserId);
                        if (user.user_auth_id)
                            onLoadCollaborationProjects(user.user_auth_id);
                    });
                    return [2 /*return*/];
                });
            }); };
            window.scrollTo(0, 0);
            loadData(currentUserId);
        }
    }, [currentUserId]);
    return (_jsx("div", __assign({ className: "myprojectslistpage-container", style: styleContainer }, { children: isReady && loadedUser.user_id ? (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "myprojectslistpage-body-sections", style: {
                    display: isUserLawyer(loadedUser.user_title_name || "")
                        ? "none"
                        : "flex",
                } }, { children: _jsxs("div", __assign({ className: "myprojectslistpage-main-section" }, { children: [_jsxs("div", __assign({ className: "myprojectslistpage-main-title" }, { children: [_jsx("div", __assign({ className: "myprojectslistpage-title-icon" }, { children: _jsx(IconProjects, {}) })), _jsx("div", __assign({ className: "myprojectslistpage-title-text" }, { children: "Manage your projects" }))] })), header, isUserScriptwriter(loadedUser.user_title_name || "") ? (scriptwriterData) : (_jsx(_Fragment, {})), isUserFilmmaker(loadedUser.user_title_name || "") ? (producerData) : (_jsx(_Fragment, {})), isUserProducer(loadedUser.user_title_name || "") ? (producerData) : (_jsx(_Fragment, {})), isUserInvestor(loadedUser.user_title_name || "") ? (buyerData) : (_jsx(_Fragment, {})), isUserBuyer(loadedUser.user_title_name || "") ? (buyerData) : (_jsx(_Fragment, {}))] })) })) })) : (_jsx(_Fragment, {})) })));
};
var mapStateToProps = function (state) { return ({
    user: getUser(state),
    currentUserId: getUserId(state),
    userTitle: getUserTitle(state),
    loadedUser: getLoadedUser(state),
    openScripts: getScriptProjects(state),
    closedScripts: getClosedScriptProjects(state),
    openFilms: getFilmProjects(state),
    closedFilms: getClosedFilmProjects(state),
    scriptCollaborations: getScriptCollaborationProjects(state),
    filmCollaborations: getFilmCollaborationProjects(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    loadScripts: function (userId) { return dispatch(thunkLoadProjects(userId)); },
    onLoadUser: function (userId) { return dispatch(thunkLoadUserByUserId(userId)); },
    onLoadProjectGenres: function () { return dispatch(thunkLoadProjectGenres()); },
    onLoadProjectSubtypes: function () { return dispatch(thunkLoadProjectSubtypes()); },
    onLoadCountries: function () { return dispatch(thunkLoadCountries()); },
    onLoadProfileViews: function (userId) {
        return dispatch(thunkLoadProfileViewsByUserId(userId));
    },
    clearProjectInProcess: function () { return dispatch(thunkClearProjectInProcess()); },
    updateUserCover: function (file) { return dispatch(thunkUpdateUserCoverImage(file)); },
    onLoadCollaborationProjects: function (authId) {
        return dispatch(thunkLoadCollaborationProjects(authId));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(MyProjectsListPage);
