var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { PropTypes } from "prop-types";
import { IoMdTrash as IconDelete } from "react-icons/io";
import { MdAttachFile as IconAttach } from "react-icons/md";
import { getFileSizeInBytes } from "../../tools/generalTools";
import "./ProjectFilesUploader.css";
var ProjectFilesUploader = function (_a) {
    var _b = _a.files, files = _b === void 0 ? [] : _b, onChange = _a.onChange;
    var _c = useState(files), filesArray = _c[0], setFilesArray = _c[1];
    var onUpdateFilesArray = function (updatedArray) {
        setFilesArray(__spreadArray([], updatedArray, true));
        onChange(updatedArray);
    };
    var uploadMultipleFiles = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var i;
        return __generator(this, function (_a) {
            event.persist();
            if (filesArray.length + event.target.files.length > 6) {
                alert("No more than 6 files allowed..");
                return [2 /*return*/];
            }
            for (i = 0; i < event.target.files.length; i++) {
                filesArray.push({
                    isEdited: true,
                    file: event.target.files[i],
                    title: event.target.files[i].name,
                    size: event.target.files[i].size,
                });
            }
            onUpdateFilesArray(filesArray);
            return [2 /*return*/];
        });
    }); };
    var onDelete = function (key) {
        var updatedArray = filesArray.filter(function (item, k) { return key != k; });
        onUpdateFilesArray(updatedArray);
    };
    return (_jsxs("div", __assign({ className: "projectfilesuploader-container" }, { children: [filesArray.map(function (fileInfo, key) { return (_jsxs("div", __assign({ className: "projectfilesuploader-file-container" }, { children: [_jsx("div", __assign({ className: "projectfilesuploader-file-icon" }, { children: _jsx(IconAttach, {}) })), _jsxs("div", __assign({ className: "projectfilesuploader-file-content" }, { children: [_jsx("div", __assign({ className: "projectfilesuploader-file-title" }, { children: fileInfo.title })), _jsx("div", __assign({ className: "projectfilesuploader-file-info" }, { children: getFileSizeInBytes(fileInfo.size) }))] })), _jsx("div", __assign({ className: "projectfilesuploader-file-delete-icon" }, { children: _jsx(IconDelete, { onClick: function () { return onDelete(key); } }) }))] }), key)); }), _jsxs("div", __assign({ className: "projectfilesuploader-file-container projectfilesuploader-attach-container" }, { children: [_jsx("input", { type: "file", className: "projectfilesuploader-input", accept: ".doc,.docx,application/pdf", onChange: function (event) { return uploadMultipleFiles(event); }, multiple: true }), _jsx("div", __assign({ className: "projectfilesuploader-file-attach-icon" }, { children: _jsx(IconAttach, {}) })), _jsxs("div", __assign({ className: "projectfilesuploader-file-content" }, { children: [_jsx("div", __assign({ className: "projectfilesuploader-file-attach-title" }, { children: "Add Attachment" })), _jsx("div", __assign({ className: "projectfilesuploader-file-info" }, { children: "PDF, DOCX (20MB max)" }))] }))] }))] })));
};
ProjectFilesUploader.propTypes = {
    onChange: PropTypes.func.isRequired,
};
ProjectFilesUploader.defaultProps = {
    onChange: function (f) { return f; },
};
export default ProjectFilesUploader;
