var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { FaPlus as IconAdd } from "react-icons/fa";
import KButton from "../../base-components/KButton/KButton";
import "./AddProjectForm.css";
var AddProjectForm = function (_a) {
    var title = _a.title, onClick = _a.onClick, _b = _a.buttonTitle, buttonTitle = _b === void 0 ? "" : _b, _c = _a.buttonIcon, buttonIcon = _c === void 0 ? IconAdd : _c;
    var _d = useState(""), text = _d[0], setText = _d[1];
    var addCharToText = function (event) {
        if (event.keyCode === 13)
            onSubmit(); // 13: enter key code
    };
    var onSubmit = function () {
        setText("");
        onClick(text);
    };
    return (_jsx("div", __assign({ className: "addprojectform-container", onClick: function (event) { return onSubmit(event); } }, { children: _jsxs("div", __assign({ className: "addprojectform-container-cover" }, { children: [_jsx("div", __assign({ className: "addprojectform-text-container" }, { children: _jsx("textarea", { className: "addprojectform-textarea", maxLength: "100", placeholder: title, value: text, onKeyUp: function (event) { return addCharToText(event); }, onChange: function (event) { return setText(event.target.value); }, rows: "1" }) })), _jsx("div", __assign({ className: "addprojectform-button" }, { children: _jsx(KButton, { title: buttonTitle, icon: buttonIcon, variant: "loadeduser", borderRadius: "var(--addprojectform-border-radius)", onClick: function (event) { return onSubmit(event); } }) }))] })) })));
};
export default AddProjectForm;
