var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import { FaBars as IconMenu } from "react-icons/fa";
import { IoMdClose as IconClose } from "react-icons/io";
import useOutsideAlert from "../../hooks/useOutsideAlert";
import "./KNavBar.css";
// https://react-icons.github.io/react-icons
var KNavBar = function (_a) {
    var _b = _a.listLinksData, listLinksData = _b === void 0 ? [] : _b, _c = _a.listButtonsData, listButtonsData = _c === void 0 ? [] : _c, _d = _a.variant, variant = _d === void 0 ? "primary" : _d;
    var navRef = useRef(null);
    var path = useRouteMatch().path;
    var navBarClosed = "knavbar-closed", navBarOpen = "knavbar-open";
    var _e = useState("knavbar-closed"), navBarState = _e[0], setNavBarState = _e[1];
    var isTabActive = function (match, location, toPath) {
        return (match !== null) |
            ((toPath !== path) &
                (toPath === location.pathname.slice(0, toPath.length)));
    };
    var toggleMenuState = function () {
        setNavBarState(navBarState === navBarClosed ? navBarOpen : navBarClosed);
    };
    useEffect(function () {
        if (navBarState === navBarClosed) {
            document.body.style.overflow = "auto";
        }
        else {
            document.body.style.overflow = "hidden";
        }
    }, [navBarState]);
    useEffect(function () {
        document.body.style.overflow = "auto";
    }, []);
    useOutsideAlert(navRef, function () { return setNavBarState(navBarClosed); });
    return (_jsx("nav", __assign({ className: "knavbar-container knavbar-".concat(variant), ref: navRef }, { children: _jsxs("ul", __assign({ className: navBarState }, { children: [_jsx("img", { className: "knavbar-background-image", src: "/images/Scriptofilm_background.png" }), _jsx("li", __assign({ className: "knavbar-li" }, { children: _jsx("a", __assign({ className: "knavbar-menu", onClick: function () { return toggleMenuState(); } }, { children: navBarState === navBarClosed ? (_jsx(IconMenu, { style: { fontSize: "17px" } })) : (_jsx(IconClose, { style: { fontSize: "17px" } })) })) })), _jsx("li", __assign({ style: { padding: "0px !important" } }, { children: _jsx(Link, __assign({ className: "knavbar-trademark", to: "/" }, { children: _jsx("img", { style: { width: "36px" } }) })) })), listLinksData.map(function (linkData, key) { return (_jsx("li", __assign({ className: "knavbar-li" }, { children: _jsx(NavLink, __assign({ className: navBarState, to: linkData.path, onClick: function () { return setNavBarState(navBarClosed); }, isActive: function (match, location) {
                            return isTabActive(match, location, linkData.path);
                        }, activeClassName: "knavbar-active", exact: true }, { children: linkData.title })) }), key)); }), listButtonsData.map(function (buttonData, key) { return (_jsx("li", __assign({ className: "knavbar-li" }, { children: _jsx("a", __assign({ className: navBarState, onClick: function () {
                            buttonData.onClick();
                            setNavBarState(navBarClosed);
                        } }, { children: buttonData.title })) }), key)); })] })) })));
};
export default KNavBar;
