var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState, useRef } from "react";
import Box from "@material-ui/core/Box";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import ArrowRight from "@material-ui/icons/ArrowRight";
import KPopup from "../../base-components/KPopup/KPopup";
import { getVideoUrl } from "../../apis/fileApi";
import "../banner/banner.css";
export function Banner() {
    var _a = useState(false), watchMode = _a[0], setWatchMode = _a[1];
    var videoRef = useRef(null);
    return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: "banner__section section" }, { children: [_jsx("img", { className: "banner__background-logo" }), _jsx("img", { src: "/images/Scriptofilm_background.png", className: "banner__background-img" }), _jsxs("div", __assign({ className: "banner__welcome-container" }, { children: [_jsx("h1", __assign({ className: "banner__welcome" }, { children: "Welcome to your" })), _jsx("h1", __assign({ className: "banner__welcome-market" }, { children: "global films and series' market" }))] })), _jsxs("div", __assign({ className: "banner__buttons-container" }, { children: [_jsx(Button, __assign({ className: "banner__button", variant: "contained", size: "medium", disableRipple: true, style: {
                                backgroundColor: "var(--app-primary-background-color)",
                                color: "var(--app-filmmaker-color-base)",
                            }, startIcon: _jsx("img", { height: "30px", src: "/images/Scriptofilm_icon_colored_network option 4.png" }) }, { children: "Connect" })), _jsx(Button, __assign({ className: "banner__button", variant: "contained", size: "medium", disableRipple: true, style: {
                                backgroundColor: "var(--app-primary-background-color)",
                                color: "var(--app-producer-color-base)",
                            }, startIcon: _jsx("img", { height: "30px", src: "/images/Scriptofilm_icon_colored_network option 2.png" }) }, { children: "Collaborate" })), _jsx(Button, __assign({ className: "banner__button", variant: "contained", size: "medium", disableRipple: true, style: {
                                backgroundColor: "var(--app-primary-background-color)",
                                color: "var(--app-investor-color-base)",
                            }, startIcon: _jsx("img", { height: "30px", src: "/images/Scriptofilm_icon_colored_money.png" }) }, { children: "Close Deals" }))] })), _jsx("h6", __assign({ className: "color-grey banner__info-text" }, { children: "Projects are locked with a personal code. Designs and features are protected by law and should not be copied." })), _jsxs(Box, __assign({ display: "flex", alignItems: "center", className: "banner__action-section cursor" }, { children: [_jsx(Icon, { component: ArrowRight, color: "inherit", fontSize: "large" }), _jsx("a", __assign({ className: "banner__action-showreel", href: "mailto:".concat(process.env.EMAIL_US, "?subject=").concat("Requesting Showreel", "&body=").concat("") }, { children: _jsx("h4", __assign({ className: "banner__action-title" }, { children: "request showreel" })) }))] })), _jsx(KPopup, __assign({ active: watchMode, onClose: function () { return setWatchMode(false); }, centered: true, darken: true }, { children: _jsx("div", __assign({ className: "scripto-video-container" }, { children: _jsxs("video", __assign({ controls: true, playsInline: true, width: "100%" }, { children: [_jsx("source", { src: getVideoUrl(process.env.SCRIPTO_SHOWREAL_PATH), type: "video/mp4" }), "Your browser does not support the video tag."] })) })) }))] })) }));
}
export default Banner;
