var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { LOAD_IS_REPORTED_SUCCESS, LOAD_USERS_SUCCESS, LOAD_USERS_IN_PROGRESS, LOAD_USERS_FAILURE, LOAD_USER_FAILURE, LOAD_USER_BY_USER_ID, LOAD_USER_BY_PROJECT_ID, CLEAR_USERS_DATA, LOAD_USER_IN_PROGRESS, LOAD_INTERESTED_USERS_SUCCESS, LOAD_INTERESTED_USERS_FAILURE, LOAD_INTERESTED_USERS_IN_PROGRESS, LOAD_MESSAGING_USERS_SUCCESS, LOAD_MESSAGING_USERS_IN_PROGRESS, LOAD_MESSAGING_USERS_FAILURE, APPEND_USERS_SUCCESS, APPEND_USERS_IN_PROGRESS, LOAD_USER_TITLE_SUCCESS, STOP_USER_LOADING, LOAD_USER_SELECTED_SEARCH_OPTIONS, LOAD_MARKET_SELECTED_SEARCH_OPTIONS, LOAD_IS_REPORTED_FAILURE, LOAD_IS_REPORTED_IN_PROCESS, SUBMIT_REPORT_IN_PROCESS, SUBMIT_REPORT_SUCCESS, SUBMIT_REPORT_FAILURE, LOAD_PROFILE_VIEWS_IN_PROGRESS, LOAD_PROFILE_VIEWS_FAILURE, LOAD_PROFILE_VIEWS_BY_USER_ID, ADD_RATING_SUCCESS, ADD_RATING_FAILURE, ADD_RATING_PROGRESS, LOAD_USER_RATING_IN_PROGRESS, LOAD_USER_RATING_FAILURE, LOAD_USER_RATING_BY_USER_ID, LOAD_USER_RATING_AVERAGE_BY_USER_ID, LOAD_USER_RATING_AVERAGE_FAILURE, LOAD_USER_RATING_AVERAGE_IN_PROGRESS, LOAD_PROJECT_VIEW_IN_PROGRESS, LOAD_PROJECT_VIEW_FAILURE, LOAD_PROJECT_VIEW_BY_PROJECT_ID, LOAD_CONTRACT_USERS_SUCCESS, LOAD_LAWYERS_SUCCESS, APPEND_LAWYERS_SUCCESS, UPDATE_COVER_IMAGES_FAILURE, UPDATE_COVER_IMAGES_SUCCESS, UPDATE_COVER_IMAGES_IN_PROGRESS, SET_NETWORK_PATH, SET_MARKET_PATH, LOAD_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS, CLEAR_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS, LOAD_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS, CLEAR_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS, LOAD_INTERESTED_COWRITERS_SUCCESS, LOAD_SHAME_USERS_FAILURE, LOAD_SHAME_USERS_IN_PROGRESS, LOAD_SHAME_USERS_SUCCESS, LOAD_DCP_SIGNED_URL_SUCCESS, LOAD_DCP_SIGNED_URL_IN_PROGRESS, LOAD_DCP_SIGNED_URL_FAILURE, LOAD_UPLOADED_DCP_SUCCESS, LOAD_UPLOADED_DCP_FAILURE, LOAD_UPLOADED_DCP_IN_PROGRESS, } from "../actions/userActions";
import _ from "lodash";
var usersInitialState = {
    data: [],
    loadedUser: {},
    isReported: false,
    views: 0,
    cowriters: [],
    interested: [],
    shameUsers: [],
    marketPath: "",
    networkPath: "",
    project_views: 0,
    ratingByUserId: 0,
    averageRating: 0,
    contractUsers: {},
    messagingUsers: {},
    dcpSignedUrl: {},
    uploadedDcp: { dcpPath: "-" },
    isLoading: false,
    userTitle: "",
    selectedSearchOptions: {},
    selectedMarketSearchOptions: {},
    lookingForUserSelectedSearchOptions: {},
    marketLookingForUserSelectedSearchOptions: {},
};
export var users = function (state, action) {
    if (state === void 0) { state = usersInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case SUBMIT_REPORT_IN_PROCESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case SUBMIT_REPORT_SUCCESS: {
            var message = payload.message;
            return __assign(__assign({}, state), { message: message, isLoading: false });
        }
        case SUBMIT_REPORT_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { message: message, isLoading: false });
        }
        case LOAD_IS_REPORTED_IN_PROCESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case LOAD_IS_REPORTED_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false });
        }
        case LOAD_IS_REPORTED_SUCCESS: {
            var isReported = payload.isReported;
            return __assign(__assign({}, state), { isReported: isReported, isLoading: false });
        }
        case LOAD_USERS_SUCCESS: {
            var users_1 = payload.users;
            return __assign(__assign({}, state), { data: users_1, isLoading: false });
        }
        case LOAD_USERS_IN_PROGRESS: {
            return __assign(__assign({}, state), { data: [], isLoading: true });
        }
        case LOAD_USERS_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { data: [], isLoading: false });
        }
        case APPEND_USERS_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case APPEND_USERS_SUCCESS: {
            var users_2 = payload.users;
            return __assign(__assign({}, state), { data: __spreadArray(__spreadArray([], state.data, true), users_2, true), isLoading: false });
        }
        case LOAD_MESSAGING_USERS_SUCCESS: {
            var users_3 = payload.users;
            var messagingUsers = _.cloneDeep(state.messagingUsers);
            if (users_3) {
                for (var i = 0; i < users_3.length; i++) {
                    messagingUsers[users_3[i].user_kachat_id] = users_3[i];
                }
            }
            return __assign(__assign({}, state), { messagingUsers: messagingUsers, isLoading: false });
        }
        case LOAD_MESSAGING_USERS_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case LOAD_MESSAGING_USERS_FAILURE: {
            return __assign(__assign({}, state), { messagingUsers: usersInitialState.messagingUsers, isLoading: false });
        }
        case LOAD_USER_IN_PROGRESS: {
            return __assign(__assign({}, state), { loadedUser: {}, isLoading: false });
        }
        case LOAD_USER_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { loadedUser: {}, isLoading: false });
        }
        case LOAD_USER_BY_USER_ID: {
            var user = payload.user;
            return __assign(__assign({}, state), { loadedUser: user, isLoading: false });
        }
        case LOAD_PROFILE_VIEWS_IN_PROGRESS: {
            return __assign(__assign({}, state), { views: 0, isLoading: false });
        }
        case LOAD_PROFILE_VIEWS_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { views: 0, isLoading: false });
        }
        case LOAD_PROFILE_VIEWS_BY_USER_ID: {
            var views = payload.views;
            return __assign(__assign({}, state), { views: views, isLoading: false });
        }
        case LOAD_USER_BY_PROJECT_ID: {
            var user = payload.user;
            return __assign(__assign({}, state), { loadedUser: user, isLoading: false });
        }
        case LOAD_INTERESTED_USERS_SUCCESS: {
            var users_4 = payload.users;
            return __assign(__assign({}, state), { interested: users_4, isLoading: false });
        }
        case LOAD_INTERESTED_COWRITERS_SUCCESS: {
            var cowriters = payload.cowriters;
            return __assign(__assign({}, state), { cowriters: cowriters, isLoading: false });
        }
        case LOAD_INTERESTED_USERS_IN_PROGRESS: {
            return __assign(__assign({}, state), { interested: [], isLoading: true });
        }
        case LOAD_INTERESTED_USERS_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { interested: [], isLoading: false });
        }
        case CLEAR_USERS_DATA: {
            return usersInitialState;
        }
        case LOAD_USER_TITLE_SUCCESS: {
            var data = payload.data;
            return __assign(__assign({}, state), { userTitle: data.title });
        }
        case STOP_USER_LOADING: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case LOAD_MARKET_SELECTED_SEARCH_OPTIONS: {
            return __assign(__assign({}, state), { selectedMarketSearchOptions: payload });
        }
        case LOAD_USER_SELECTED_SEARCH_OPTIONS: {
            return __assign(__assign({}, state), { selectedSearchOptions: payload });
        }
        case LOAD_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS: {
            return __assign(__assign({}, state), { lookingForUserSelectedSearchOptions: payload });
        }
        case LOAD_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS: {
            return __assign(__assign({}, state), { marketLookingForUserSelectedSearchOptions: payload });
        }
        case CLEAR_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS: {
            return __assign(__assign({}, state), { lookingForUserSelectedSearchOptions: {
                    searchOptions: {
                        userTitleIds: [],
                        countryIds: [],
                        languageIds: [],
                        personalized: [false],
                    },
                } });
        }
        case CLEAR_MARKET_LOOKING_FOR_USER_SELECTED_SEARCH_OPTIONS: {
            return __assign(__assign({}, state), { marketLookingForUserSelectedSearchOptions: {
                    searchOptions: {
                        projectTypeIds: [],
                        projectSubtypeIds: [],
                        projectKindIds: [],
                        projectGenreIds: [],
                        countryIds: [],
                        languageIds: [],
                        sale_collaboration: [],
                        min_acquisition_budget: "",
                        max_acquisition_budget: "",
                        min_production_budget: "",
                        max_production_budget: "",
                        personalized: [false],
                    },
                } });
        }
        case ADD_RATING_PROGRESS: {
            return __assign(__assign({}, state), { rating_success: {}, isLoading: false });
        }
        case ADD_RATING_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { rating_success: message, isLoading: false });
        }
        case ADD_RATING_SUCCESS: {
            var rating = payload.rating;
            return __assign(__assign({}, state), { rating_success: rating, isLoading: false });
        }
        case LOAD_USER_RATING_IN_PROGRESS: {
            return __assign(__assign({}, state), { ratingByUserId: 0, isLoading: false });
        }
        case LOAD_USER_RATING_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { ratingByUserId: 0, isLoading: false });
        }
        case LOAD_USER_RATING_BY_USER_ID: {
            var ratingByUserId = payload.ratingByUserId;
            return __assign(__assign({}, state), { ratingByUserId: ratingByUserId, isLoading: false });
        }
        case LOAD_USER_RATING_AVERAGE_IN_PROGRESS: {
            return __assign(__assign({}, state), { averageRating: 0, isLoading: false });
        }
        case LOAD_USER_RATING_AVERAGE_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { averageRating: 0, isLoading: false });
        }
        case LOAD_USER_RATING_AVERAGE_BY_USER_ID: {
            var averageRating = payload.averageRating;
            return __assign(__assign({}, state), { averageRating: averageRating, isLoading: false });
        }
        case LOAD_PROJECT_VIEW_IN_PROGRESS: {
            return __assign(__assign({}, state), { project_views: 0, isLoading: false });
        }
        case LOAD_PROJECT_VIEW_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { project_views: 0, isLoading: false });
        }
        case LOAD_PROJECT_VIEW_BY_PROJECT_ID: {
            var project_views = payload.project_views;
            return __assign(__assign({}, state), { project_views: project_views, isLoading: false });
        }
        case LOAD_CONTRACT_USERS_SUCCESS: {
            var users_5 = payload.users;
            var contractUsers = _.cloneDeep(state.contractUsers);
            if (users_5) {
                for (var i = 0; i < users_5.length; i++) {
                    contractUsers[users_5[i].user_auth_id] = users_5[i];
                }
            }
            return __assign(__assign({}, state), { contractUsers: contractUsers, isLoading: false });
        }
        case LOAD_LAWYERS_SUCCESS: {
            var lawyers = payload.lawyers;
            return __assign(__assign({}, state), { lawyers: lawyers, isLoading: false });
        }
        case APPEND_LAWYERS_SUCCESS: {
            var lawyers = payload.lawyers;
            return __assign(__assign({}, state), { lawyers: __spreadArray(__spreadArray([], state.lawyers, true), lawyers, true), isLoading: false });
        }
        case UPDATE_COVER_IMAGES_FAILURE: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case UPDATE_COVER_IMAGES_SUCCESS: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case UPDATE_COVER_IMAGES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case SET_NETWORK_PATH: {
            var path = payload.path;
            return __assign(__assign({}, state), { networkPath: path });
        }
        case SET_MARKET_PATH: {
            var path = payload.path;
            return __assign(__assign({}, state), { marketPath: path });
        }
        case LOAD_SHAME_USERS_SUCCESS: {
            var users_6 = payload.users;
            return __assign(__assign({}, state), { shameUsers: users_6, isLoading: false });
        }
        case LOAD_SHAME_USERS_IN_PROGRESS: {
            return __assign(__assign({}, state), { shameUsers: [], isLoading: true });
        }
        case LOAD_SHAME_USERS_FAILURE: {
            return __assign(__assign({}, state), { shameUsers: [], isLoading: false });
        }
        case LOAD_DCP_SIGNED_URL_IN_PROGRESS: {
            return __assign(__assign({}, state), { dcpSignedUrl: {}, isLoading: true });
        }
        case LOAD_DCP_SIGNED_URL_SUCCESS: {
            var signedUrl = payload.signedUrl;
            return __assign(__assign({}, state), { dcpSignedUrl: signedUrl || {}, isLoading: false });
        }
        case LOAD_DCP_SIGNED_URL_FAILURE: {
            return __assign(__assign({}, state), { dcpSignedUrl: {}, isLoading: false });
        }
        case LOAD_UPLOADED_DCP_IN_PROGRESS: {
            return __assign(__assign({}, state), { uploadedDcp: usersInitialState.uploadedDcp, isLoading: true });
        }
        case LOAD_UPLOADED_DCP_SUCCESS: {
            var uploadedDcp = payload.uploadedDcp;
            return __assign(__assign({}, state), { uploadedDcp: uploadedDcp || {}, isLoading: false });
        }
        case LOAD_UPLOADED_DCP_FAILURE: {
            return __assign(__assign({}, state), { uploadedDcp: usersInitialState.uploadedDcp, isLoading: false });
        }
        default: {
            return state;
        }
    }
};
