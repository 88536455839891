var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, createRef } from "react";
import { MdFileUpload as IconUpload } from "react-icons/md";
import { IoMdTrash as IconDelete } from "react-icons/io";
import { MdEdit as IconEdit } from "react-icons/md";
import KTextField from "../../base-components/KTextField/KTextField";
import { resize, dataURItoBlob, validateImageSize, } from "../../imaging/imageTransform";
import { getFileSizeInBytes } from "../../tools/generalTools";
import EditCropImage from "../../pages/EditCropImage/EditCropImage";
import KPopup from "../../base-components/KPopup/KPopup";
import "./CoverImageUploader.css";
var CoverImageUploader = function (_a) {
    var _b = _a.max, max = _b === void 0 ? -1 : _b, _c = _a.adjustable, adjustable = _c === void 0 ? false : _c, _d = _a.aspectRatio, aspectRatio = _d === void 0 ? 4 / 3 : _d, _e = _a.uploadTitle, uploadTitle = _e === void 0 ? "upload cover images" : _e, _f = _a.uploadSubtitle, uploadSubtitle = _f === void 0 ? "jpg/png - 6 max" : _f, _g = _a.textPlaceholder, textPlaceholder = _g === void 0 ? "Location, mood, actors, etc.." : _g, _h = _a.infoArray, infoArray = _h === void 0 ? [] : _h, onChange = _a.onChange, _j = _a.minAllowedSize, minAllowedSize = _j === void 0 ? process.env.IMAGE_MIN_ALLOWED_SIZE || 50000 : _j, _k = _a.minAllowedWidth, minAllowedWidth = _k === void 0 ? process.env.IMAGE_MIN_ALLOWED_WIDTH || 200 : _k, _l = _a.minAllowedHeight, minAllowedHeight = _l === void 0 ? process.env.IMAGE_MIN_ALLOWED_HEIGHT || 200 : _l;
    var maxNum = parseInt(max);
    var coverInfoKey = 0;
    var imageSize = parseInt(process.env.LARGE_IMAGE_SIZE);
    var _m = useState([]), coverInfoArray = _m[0], setCoverInfoArray = _m[1];
    var _o = useState({}), srcCover = _o[0], setSrcCover = _o[1];
    var onUpdateCoverInfoArray = function (updatedArray) {
        setCoverInfoArray(__spreadArray([], updatedArray.sort(function (f, s) { return (f.key > s.key ? 1 : -1); }), true));
        onChange(updatedArray);
    };
    var _p = useState(false), cropContentImage = _p[0], setCropContentImage = _p[1];
    var uploadClick = function (file, key) {
        setSrcCover({ file: file, key: key });
        setCropContentImage(true);
    };
    useEffect(function () {
        onUpdateCoverInfoArray(infoArray);
    }, [infoArray.length]);
    var uploadMultipleFiles = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var promiseArray, i;
        return __generator(this, function (_a) {
            event.persist();
            if ((maxNum > 0) &
                (coverInfoArray.length + event.target.files.length > maxNum)) {
                alert("No more than " + maxNum + " files allowed..");
                return [2 /*return*/];
            }
            coverInfoKey = Math.max(0, Math.max.apply(Math, coverInfoArray.map(function (coverInfo) {
                return coverInfo.key;
            })));
            promiseArray = [];
            for (i = 0; i < event.target.files.length; i++) {
                promiseArray.push(validateImageSize(event.target.files[i], minAllowedSize, minAllowedWidth, minAllowedHeight));
            }
            Promise.all(promiseArray)
                .then(function (res) {
                for (var i = 0; i < event.target.files.length; i++) {
                    var file = event.target.files[i];
                    coverInfoKey += 1;
                    coverInfoArray.push({
                        key: coverInfoKey,
                        src: URL.createObjectURL(file),
                        file: file,
                        title: "",
                        fileInput: createRef(),
                        isEdited: true,
                    });
                    resize(file, imageSize, imageSize, coverInfoKey, function (dataUrl, passedKey) {
                        var coverInfo = coverInfoArray.find(function (coverInfo) { return coverInfo.key == passedKey; });
                        coverInfo.src = dataUrl;
                        coverInfo.file = dataURItoBlob(dataUrl);
                        onUpdateCoverInfoArray(coverInfoArray);
                    });
                }
                onUpdateCoverInfoArray(coverInfoArray);
            })
                .catch(function (err) {
                console.log(err);
                alert("Image minimum requirements not met: \n    - Size: ".concat(getFileSizeInBytes(minAllowedSize), " \n    - Dimensions: ").concat(minAllowedWidth, "x").concat(minAllowedHeight));
            });
            return [2 /*return*/];
        });
    }); };
    var updateCoverImageProject = function (key, file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            validateImageSize(file, minAllowedSize, minAllowedWidth, minAllowedHeight)
                .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                var coverInfo;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            coverInfo = coverInfoArray.find(function (coverInfo) { return coverInfo.key == key; });
                            coverInfo.src = URL.createObjectURL(file);
                            coverInfo.file = file;
                            coverInfo.isEdited = true;
                            return [4 /*yield*/, onUpdateCoverInfoArray(coverInfoArray)];
                        case 1:
                            _a.sent();
                            resize(file, imageSize, imageSize, key, function (dataUrl, coverInfoKey) {
                                var coverInfo = coverInfoArray.find(function (coverInfo) { return coverInfo.key == coverInfoKey; });
                                coverInfo.src = dataUrl;
                                coverInfo.file = dataURItoBlob(dataUrl);
                                onUpdateCoverInfoArray(coverInfoArray);
                            });
                            setCropContentImage(false);
                            return [2 /*return*/];
                    }
                });
            }); })
                .catch(function (err) {
                console.log(err);
                alert("Image minimum requirements not met: \n    - Size: ".concat(getFileSizeInBytes(minAllowedSize), " \n    - Dimensions: ").concat(minAllowedWidth, "x").concat(minAllowedHeight));
            });
            return [2 /*return*/];
        });
    }); };
    var updateCoverImage = function (key, event) {
        var file = event.target.files[0];
        validateImageSize(file, minAllowedSize, minAllowedWidth, minAllowedHeight)
            .then(function () { return __awaiter(void 0, void 0, void 0, function () {
            var coverInfo;
            return __generator(this, function (_a) {
                coverInfo = coverInfoArray.find(function (coverInfo) { return coverInfo.key == key; });
                coverInfo.src = URL.createObjectURL(event.target.files[0]);
                coverInfo.file = file;
                coverInfo.isEdited = true;
                onUpdateCoverInfoArray(coverInfoArray);
                resize(file, imageSize, imageSize, key, function (dataUrl, coverInfoKey) {
                    var coverInfo = coverInfoArray.find(function (coverInfo) { return coverInfo.key == coverInfoKey; });
                    coverInfo.src = dataUrl;
                    coverInfo.file = dataURItoBlob(dataUrl);
                    onUpdateCoverInfoArray(coverInfoArray);
                });
                return [2 /*return*/];
            });
        }); })
            .catch(function (err) {
            console.log(err);
            alert("Image minimum requirements not met: \n    - Size: ".concat(getFileSizeInBytes(minAllowedSize), " \n    - Dimensions: ").concat(minAllowedWidth, "x").concat(minAllowedHeight));
        });
    };
    var updateCoverInfo = function (key, title) {
        var updatedArray = coverInfoArray.map(function (coverInfo, k) {
            if (k === key)
                return __assign(__assign({}, coverInfo), { title: title, isEdited: true });
            return coverInfo;
        });
        onUpdateCoverInfoArray(updatedArray);
    };
    var DivNoImagesUploaded = function () {
        if (coverInfoArray.length > 0)
            return _jsx(_Fragment, {});
        return (_jsx("div", __assign({ className: "coverimageuploader-no-images" }, { children: "No images uploaded" })));
    };
    return (_jsxs("div", __assign({ className: "coverimageuploader-container" }, { children: [_jsx(KPopup, __assign({ active: cropContentImage, onClose: function () { return setCropContentImage(false); } }, { children: cropContentImage && (_jsx(EditCropImage, { image: srcCover.file || {}, aspectRatio: aspectRatio, cropShape: "rect", onSaveImage: function (img) {
                        return updateCoverImageProject(srcCover.key, img);
                    } })) })), _jsxs("div", __assign({ className: "coverimageuploader-images-section" }, { children: [_jsx(DivNoImagesUploaded, {}), coverInfoArray.map(function (coverInfo, key) { return (_jsxs("div", __assign({ className: "coverimageuploader-item-section" }, { children: [_jsx("div", __assign({ className: "coverimageuploader-image-section" }, { children: _jsx("img", { className: "coverimageuploader-image", src: coverInfo.src }) })), _jsxs("div", __assign({ className: "coverimageuploader-data-section" }, { children: [_jsxs("div", __assign({ className: "coverimageuploader-actions" }, { children: [_jsx("input", { style: {
                                                    opacity: 0,
                                                    zIndex: -1,
                                                    position: "absolute",
                                                }, type: "file", ref: coverInfo.fileInput, accept: "image/*", onChange: function (event) {
                                                    return updateCoverImage(coverInfo.key, event);
                                                } }), _jsx(IconEdit, { className: "coverimageuploader-action-icon", onClick: function () {
                                                    if (adjustable) {
                                                        if (!coverInfo.path) {
                                                            uploadClick(coverInfo.src, coverInfo.key);
                                                        }
                                                        else {
                                                            uploadClick(coverInfo.path, coverInfo.key);
                                                        }
                                                    }
                                                    else {
                                                        coverInfo.fileInput.current.click();
                                                    }
                                                } }), _jsx(IconDelete, { className: "coverimageuploader-action-icon", onClick: function () {
                                                    return onUpdateCoverInfoArray(coverInfoArray.filter(function (coverInfo, k) { return k !== key; }));
                                                } })] })), _jsx("div", __assign({ className: "coverimageuploader-textfield" }, { children: _jsx(KTextField, { placeholder: textPlaceholder, value: coverInfo.title || "", onChange: function (event) {
                                                return updateCoverInfo(key, event.target.value);
                                            } }) }))] }))] }), key)); })] })), _jsxs("div", __assign({ className: "coverimageuploader-upload-section" }, { children: [_jsx("input", { type: "file", className: "coverimageuploader-input", accept: "image/*", onChange: function (event) { return uploadMultipleFiles(event); }, multiple: parseInt(max) === 1 ? false : true }), _jsxs("div", __assign({ className: "coverimageuploader-upload-wrapper" }, { children: [_jsx(IconUpload, { className: "coverimageuploader-upload-icon" }), _jsx("p", __assign({ className: "coverimageuploader-upload-title" }, { children: uploadTitle })), _jsx("p", __assign({ className: "coverimageuploader-upload-info" }, { children: uploadSubtitle }))] }))] }))] })));
};
export default CoverImageUploader;
