var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getFileSizeInBytes } from "../tools/generalTools";
export var resizeAsync = function (file, maxWidth, maxHeight, key) {
    return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (event) {
            var dataUrl = event.target.result;
            var image = new Image();
            image.src = dataUrl;
            image.onload = function () {
                var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
                resolve(resizedDataUrl, key);
            };
        };
        reader.onerror = reject;
    });
};
export var validateImageSize = function (file, minSize, minWidth, minHeight) {
    (minSize = parseInt(minSize)),
        (minWidth = parseInt(minWidth)),
        (minHeight = parseInt(minHeight));
    return new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
        var reader;
        return __generator(this, function (_a) {
            if (file.size < minSize) {
                return [2 /*return*/, reject(new Error("Image size should be greater than ".concat(getFileSizeInBytes(minSize, 0))))];
            }
            reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (event) {
                var dataUrl = event.target.result;
                var image = new Image();
                image.src = dataUrl;
                image.onload = function () {
                    if (image.width < minWidth || image.height < minHeight) {
                        return reject(new Error("Image dimensions should be ".concat(minWidth, "x").concat(minHeight, " or greater")));
                    }
                    resolve();
                };
            };
            return [2 /*return*/];
        });
    }); });
};
export var resize = function (file, maxWidth, maxHeight, key, callback) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
        var dataUrl = event.target.result;
        var image = new Image();
        image.src = dataUrl;
        image.onload = function () {
            var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
            callback(resizedDataUrl, key);
        };
    };
};
var resizeImage = function (image, maxWidth, maxHeight, quality) {
    var width = image.width;
    var height = image.height;
    var canvas = document.createElement("canvas");
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height * maxWidth) / width);
            width = maxWidth;
        }
    }
    else if (height > maxHeight) {
        width = Math.round((width * maxHeight) / height);
        height = maxHeight;
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);
    return canvas.toDataURL("image/jpeg", quality);
};
export var dataURItoBlob = function (dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
        byteString = atob(dataURI.split(",")[1]);
    }
    else {
        byteString = unescape(dataURI.split(",")[1]);
    }
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
};
var createImage = function (url) {
    return new Promise(function (resolve, reject) {
        var image = new Image();
        image.addEventListener("load", function () { return resolve(image); });
        image.addEventListener("error", function (error) { return reject(error); });
        image.setAttribute("crossOrigin", "anonymous");
        image.src = url;
    });
};
function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
}
export function getCroppedImg(imageSrc, pixelCrop, rotation) {
    if (rotation === void 0) { rotation = 0; }
    return __awaiter(this, void 0, void 0, function () {
        var image, canvas, ctx, maxSize, safeArea, data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createImage(imageSrc)];
                case 1:
                    image = _a.sent();
                    canvas = document.createElement("canvas");
                    ctx = canvas.getContext("2d");
                    maxSize = Math.max(image.width, image.height);
                    safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
                    // set each dimensions to double largest dimension to allow for a safe area for the
                    // image to rotate in without being clipped by canvas context
                    canvas.width = safeArea;
                    canvas.height = safeArea;
                    // translate canvas context to a central location on image to allow rotating around the center.
                    ctx.translate(safeArea / 2, safeArea / 2);
                    ctx.rotate(getRadianAngle(rotation));
                    ctx.translate(-safeArea / 2, -safeArea / 2);
                    // draw rotated image and store data.
                    ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
                    data = ctx.getImageData(0, 0, safeArea, safeArea);
                    // set canvas width to final desired crop size - this will clear existing context
                    canvas.width = pixelCrop.width;
                    canvas.height = pixelCrop.height;
                    // paste generated rotate image with correct offsets for x,y crop values.
                    ctx.putImageData(data, Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x), Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y));
                    // As Base64 string
                    // return canvas.toDataURL("image/jpeg");
                    // As a blob As a link
                    // return new Promise((resolve) => {
                    //     canvas.toBlob((file) => {
                    //         resolve(URL.createObjectURL(file));
                    //     }, "image/jpeg");
                    // });
                    return [2 /*return*/, new Promise(function (resolve) {
                            canvas.toBlob(function (blob) {
                                resolve(new File([blob], "fileName.jpg", { type: "image/jpeg" }));
                            }, "image/jpeg");
                        })];
            }
        });
    });
}
export function getDataUrl(img) {
    return __awaiter(this, void 0, void 0, function () {
        var image, canvas, ctx;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, createImage(img)];
                case 1:
                    image = _a.sent();
                    canvas = document.createElement("canvas");
                    ctx = canvas.getContext("2d");
                    // Set width and height
                    canvas.width = image.width;
                    canvas.height = image.height;
                    // Draw the image
                    ctx.drawImage(image, 0, 0);
                    return [2 /*return*/, canvas.toDataURL("image/jpeg")];
            }
        });
    });
}
