export var RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";
export var resetNotifications = function (notifications) { return ({
    type: RESET_NOTIFICATIONS,
    payload: { notifications: notifications },
}); };
export var UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export var updateNotifications = function (notifications) { return ({
    type: UPDATE_NOTIFICATIONS,
    payload: { notifications: notifications },
}); };
export var ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export var addNotifications = function (notifications) { return ({
    type: ADD_NOTIFICATIONS,
    payload: { notifications: notifications },
}); };
export var UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export var updateNotification = function (notification) { return ({
    type: UPDATE_NOTIFICATION,
    payload: { notification: notification },
}); };
export var NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
export var notificationsLoading = function () { return ({
    type: NOTIFICATIONS_LOADING,
    payload: {},
}); };
export var NOTIFICATIONS_LOADING_COMPLETE = "NOTIFICATIONS_LOADING_COMPLETE";
export var notificationsLoadingComplete = function () { return ({
    type: NOTIFICATIONS_LOADING_COMPLETE,
}); };
