var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./KSlider.css";
var KSlider = function (_a) {
    var name = _a.name, label = _a.label, placeholder = _a.placeholder, onChange = _a.onChange, width = _a.width, height = _a.height, labelMargin = _a.labelMargin, marginVertical = _a.marginVertical, marginHorizontal = _a.marginHorizontal, marginRight = _a.marginRight, marginLeft = _a.marginLeft, backgroundColor = _a.backgroundColor, borderless = _a.borderless, value = _a.value, max = _a.max, min = _a.min, step = _a.step;
    var styleContainer = {
        "--KSlider-width": width || "100%",
        "--KSlider-height": height || "180px",
        "--KSlider-label-margin": labelMargin || "5px",
        "--KSlider-border-radius": borderless ? "0" : "5px",
        "--KSlider-border": borderless ? "0" : "",
        "--KSlider-margin-vertical": marginVertical || "0px",
        "--KSlider-margin-horizontal": marginHorizontal || "0px",
        "--KSlider-margin-right": marginRight || "0px",
        "--KSlider-margin-left": marginLeft || "0px",
        "--KSlider-background-color": backgroundColor || "var(--app-tertiary-background-color)",
    };
    return (_jsx("div", __assign({ className: "KSlider-container", style: styleContainer }, { children: _jsxs("div", __assign({ className: "KSlider-wrapper" }, { children: [_jsx("label", __assign({ className: "KSlider-label" }, { children: label })), _jsx(Slider, { className: "KSlider-input", value: value, min: min, max: max, step: step, onChange: onChange, trackStyle: {
                        backgroundColor: backgroundColor,
                        height: 6,
                    }, handleStyle: {
                        borderColor: backgroundColor,
                        height: 15,
                        width: 15,
                        marginTop: -5,
                        backgroundColor: backgroundColor,
                    }, railStyle: {
                        backgroundColor: "var(--app-tertiary-background-color)",
                        height: 6,
                    } })] })) })));
};
export default KSlider;
