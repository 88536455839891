var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MdTextsms as IconCollaborate } from "react-icons/md";
import { MdAssignment as IconRequest } from "react-icons/md";
import { MdEdit as IconEdit } from "react-icons/md";
import { BsLightningFill as IconBoost } from "react-icons/bs";
import { BsFillCaretDownFill as IconMore } from "react-icons/bs";
import { AiFillFileText as IconEvaluate } from "react-icons/ai";
import KButton from "../../base-components/KButton/KButton";
import { getProjectSymbolSrc, isFilmProject } from "../../tools/projectTools";
import { getLocation } from "../../tools/generalTools";
import { getLoadedProjectViews } from "../../redux/selectors/userSelectors";
import { IoMdEye as IconView } from "react-icons/io";
import { history } from "../../redux/store";
import { connect } from "react-redux";
import "./ProjectHeader.css";
var ProjectHeader = function (_a) {
    var project = _a.project, user = _a.user, signedUrl = _a.signedUrl, onEditProject = _a.onEditProject, loadedProjectViews = _a.loadedProjectViews, _b = _a.accessStatus, accessStatus = _b === void 0 ? "" : _b, _c = _a.isEditable, isEditable = _c === void 0 ? false : _c, _d = _a.onAccessRequest, onAccessRequest = _d === void 0 ? function (f) { return f; } : _d, _e = _a.onCollaborate, onCollaborate = _e === void 0 ? function (f) { return f; } : _e;
    var _f = useState([]), extraArray = _f[0], setExtraArray = _f[1];
    var evaluationHref = "https://jaydreams.net/#requestservices";
    var accessButton = function () {
        var buttonBlock;
        var projectType = isFilmProject(project.project_type_name)
            ? "Film"
            : "Script";
        switch (accessStatus) {
            case "pending":
                buttonBlock = (_jsx(KButton, { title: "Request Pending", icon: IconRequest, variant: "loadeduser", 
                    // backgroundColor={"coral"}
                    backgroundColor: "var(--app-filmmaker-color)", onClick: function () {
                        return alert("Your access request is pending. We'll notify you when it gets approved.");
                    } }));
                break;
            case "accepted":
                buttonBlock = (_jsx(KButton, { title: "Access Full ".concat(projectType), icon: IconRequest, variant: "loadeduser", 
                    // backgroundColor={"LimeGreen"}
                    backgroundColor: "var(--app-investor-color)", href: signedUrl, target: "_blank" }));
                break;
            default:
                buttonBlock = (_jsx(KButton, { title: "Request Full ".concat(projectType), icon: IconRequest, variant: "loadeduser", onClick: function () {
                        if (project.hasFullProject)
                            onAccessRequest();
                        else
                            alert("This project does not have a full ".concat(projectType));
                    } }));
                break;
        }
        return buttonBlock;
    };
    useEffect(function () {
        var projectType = isFilmProject(project.project_type_name)
            ? "Film"
            : "Script";
        switch (accessStatus) {
            case "pending":
                setExtraArray([
                    {
                        title: "Request Pending",
                        onClick: function () {
                            return alert("Your access request is pending. We'll notify you when it gets approved.");
                        },
                    },
                ]);
                break;
            case "accepted":
                setExtraArray([
                    {
                        title: "Access Full ".concat(projectType),
                        href: signedUrl,
                        target: "_blank",
                    },
                ]);
                break;
            default:
                setExtraArray([
                    {
                        title: "Request Full ".concat(projectType),
                        onClick: function () {
                            if (project.hasFullProject)
                                onAccessRequest();
                            else
                                alert("This project does not have a full ".concat(projectType));
                        },
                    },
                ]);
                break;
        }
    }, [accessStatus]);
    return (_jsxs("div", __assign({ className: "projectheader-container" }, { children: [_jsxs("div", __assign({ className: "projectheader-left-section" }, { children: [_jsx("div", __assign({ className: "projectheader-image-container" }, { children: _jsx("img", { className: "projectheader-image", src: getProjectSymbolSrc(project.project_type_name) }) })), _jsxs("div", __assign({ className: "projectheader-title-conainer" }, { children: [_jsx("div", __assign({ className: "projectheader-title" }, { children: project.project_title })), _jsx("div", __assign({ className: "projectheader-subtitle" }, { children: (project.project_genre_name || "A") +
                                    " " +
                                    (project.project_type_name
                                        ? project.project_type_name.toLowerCase()
                                        : "") +
                                    " by " +
                                    user.user_first_name +
                                    " " +
                                    user.user_last_name +
                                    " from " +
                                    getLocation(user.country_name, user.city_name) })), isEditable ? (_jsxs("div", __assign({ className: "projectheader-edit-project-container", onClick: onEditProject }, { children: [_jsx("div", __assign({ className: "projectheader-edit-project-icon" }, { children: _jsx(IconEdit, {}) })), _jsx("div", __assign({ className: "projectheader-edit-project-text" }, { children: "edit project" }))] }))) : (_jsx(_Fragment, {}))] }))] })), user.user_last_name ? (_jsxs("div", __assign({ className: "projectheader-right-section" }, { children: [_jsxs("div", __assign({ className: "projectheader-views-section" }, { children: [_jsx("div", __assign({ className: "projectheader-views-text" }, { children: loadedProjectViews || 0 })), _jsx(IconView, { className: "projectheader-views-icon" })] })), isEditable ? (_jsxs("div", __assign({ className: "projectheader-action-buttons" }, { children: [_jsx(KButton, { title: "Request Evaluation", icon: IconEvaluate, variant: "loadeduser", href: evaluationHref }), _jsx(KButton, { title: "Boost Project", icon: IconBoost, variant: "custom", color: "white", backgroundColor: "black", onClick: function () {
                                    return history.push("/platform/boosts/new?projectId=".concat(project.project_id));
                                } })] }))) : (_jsxs("div", __assign({ className: "projectheader-action-buttons" }, { children: [accessButton(), _jsx(KButton, { title: "Collaborate", icon: IconCollaborate, variant: "loadeduser", onClick: onCollaborate, outline: true })] }))), _jsx("div", __assign({ className: "projectheader-action-dropdown" }, { children: isEditable ? (_jsx(KButton, { title: "", icon: IconMore, variant: "loadeduser", iconRight: true, listItems: [
                                {
                                    title: "Request Evaluation",
                                    href: evaluationHref,
                                },
                                {
                                    title: "Boost Project",
                                    onClick: function () {
                                        return history.push("/platform/boosts/new?projectId=".concat(project.project_id));
                                    },
                                },
                            ] })) : (_jsx(KButton, { title: "", icon: IconMore, variant: "loadeduser", iconRight: true, listItems: (true || project.hasFullProject // for always show extra array
                                ? extraArray
                                : []).concat([
                                {
                                    title: "Collaborate",
                                    onClick: onCollaborate,
                                },
                                // {
                                //     title: "Track",
                                //     onClick: () =>
                                //         alert("Feature coming soon"),
                                // },
                            ]) })) }))] }))) : (_jsx("div", {}))] })));
};
ProjectHeader.propTypes = {
    onEditProject: PropTypes.func.isRequired,
};
ProjectHeader.defaultProps = {
    onEditProject: function (f) { return f; },
};
var mapStateToProps = function (state) { return ({
    loadedProjectViews: getLoadedProjectViews(state),
}); };
export default connect(mapStateToProps, null)(ProjectHeader);
