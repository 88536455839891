var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import KSelector from "../../base-components/KSelector/KSelector";
import KButton from "../../base-components/KButton/KButton";
import "./EditUserInterestsPage.css";
import { hasUserInterests, hasProjectInterests } from "../../tools/userTools";
var EditUserInterestsPage = function (_a) {
    var scrollParent = _a.scrollParent, onExit = _a.onExit, onSave = _a.onSave, userData = _a.userData, setUserData = _a.setUserData, userTitle = _a.userTitle, countries = _a.countries, languages = _a.languages, projectSubtypes = _a.projectSubtypes, projectGenres = _a.projectGenres, editUserPopupOnceClosed = _a.editUserPopupOnceClosed;
    var history = useHistory();
    var width = "410px";
    var getInterestColor = function (interest) {
        var text = interest.user_title_name.toLowerCase();
        return "var(--app-".concat(text, "-color)");
    };
    var getProjectInterestColor = function (interest) {
        var text = interest.project_type_name == "Script"
            ? "scriptwriter"
            : "filmmaker";
        return "var(--app-".concat(text, "-color)");
    };
    var getInterestsArray = function () {
        return userData.interestsArray || [];
    };
    var getProjectInterestsArray = function () {
        return userData.projectInterestsArray || [];
    };
    var getActiveInterestKey = function () {
        return userData.activeInterestKey || 0;
    };
    var getActiveProjectInterestKey = function () {
        return userData.activeProjectInterestKey || 0;
    };
    var activeTabClass = function (key) {
        return key == getActiveInterestKey()
            ? "edituserinterestspage-activetab"
            : "";
    };
    var activeProjectTabClass = function (key) {
        return key == getActiveProjectInterestKey()
            ? "edituserinterestspage-activetab"
            : "";
    };
    var updateInterest = function (key, field, value, setChecked) {
        if (setChecked === void 0) { setChecked = false; }
        var interestIndex = getInterestsArray().findIndex(function (interest, k) { return k == key; });
        getInterestsArray()[interestIndex][field] = value;
        if (setChecked)
            getInterestsArray()[interestIndex].checked = true;
        setUserData(__assign(__assign({}, userData), { interestsArray: getInterestsArray() }));
    };
    var updateProjectInterest = function (key, field, value, setChecked) {
        if (setChecked === void 0) { setChecked = false; }
        var interestIndex = getProjectInterestsArray().findIndex(function (interest, k) { return k == key; });
        getProjectInterestsArray()[interestIndex][field] = value;
        if (setChecked)
            getProjectInterestsArray()[interestIndex].checked = true;
        setUserData(__assign(__assign({}, userData), { projectInterestsArray: getProjectInterestsArray() }));
    };
    var onStartConnecting = function () {
        if ((hasUserInterests(userTitle) &&
            !getInterestsArray().filter(function (i) { return i.checked; }).length) ||
            (hasProjectInterests(userTitle) &&
                !getProjectInterestsArray().filter(function (i) { return i.checked; }).length))
            return alert("Please select at least one interest");
        onSave();
        if (!editUserPopupOnceClosed) {
            window.history.replaceState(null, "", "/platform/profile");
            onExit();
        }
    };
    useEffect(function () {
        if (scrollParent.scrollTo)
            scrollParent.scrollTo(0, 0);
    }, []);
    return (_jsxs("div", __assign({ className: "edituserinterestspage-container" }, { children: [_jsx("div", __assign({ className: "edituserinterestspage-title" }, { children: userData.userInterestsPageTitle })), _jsxs("div", __assign({ className: "edituserinterestspage-content" }, { children: [hasUserInterests(userTitle) ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "edituserinterestspage-section-title" }, { children: "Choose who you would like to match with" })), _jsx("div", __assign({ className: "edituserinterestspage-tabbar" }, { children: getInterestsArray().map(function (interest, key) { return (_jsxs("div", __assign({ className: "edituserinterestspage-tab ".concat(activeTabClass(key)) }, { children: [_jsx("div", { className: "edituserinterestspage-tabselector", onClick: function () {
                                                return setUserData(__assign(__assign({}, userData), { activeInterestKey: key }));
                                            } }), _jsx(KCheckbox, { title: interest.user_title_name, checked: interest.checked || false, onChange: function (event) {
                                                return updateInterest(key, "checked", event.target.checked);
                                            }, color: getInterestColor(interest), round: true })] }), key)); }) })), _jsxs("div", __assign({ className: "edituserinterestspage-info-section" }, { children: [_jsx("div", __assign({ className: "edituserinterestspage-criteria-title" }, { children: "Select some of the following criteria" })), getInterestsArray().map(function (interest, key) { return (_jsxs("div", __assign({ className: "edituserinterestspage-row", style: {
                                            display: key == getActiveInterestKey()
                                                ? "flex"
                                                : "none",
                                        } }, { children: [_jsx(KSelector, { identifier: "0-" + key, placeholder: "Country", label: "Country", width: width, marginVertical: "10px", marginRight: "20px", items: countries, titleKey: "country_name", scrollParent: scrollParent, multiple: true, itemKey: "country_id", onChange: function (items) {
                                                    return updateInterest(key, "countries", items, items.length > 0);
                                                }, selectedItems: userData.interestsArray[key]
                                                    .countries }), _jsx(KSelector, { identifier: "1-" + key, placeholder: "Language", label: "Language", width: width, marginVertical: "10px", marginRight: "20px", items: languages, titleKey: "language_name", scrollParent: scrollParent, multiple: true, itemKey: "language_id", onChange: function (items) {
                                                    return updateInterest(key, "languages", items, items.length > 0);
                                                }, selectedItems: userData.interestsArray[key]
                                                    .languages })] }), key)); }), hasProjectInterests(userTitle) ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "edituserinterestspage-action-section" }, { children: _jsx(KButton, { title: !editUserPopupOnceClosed
                                                ? "Start Connecting"
                                                : "Save", variant: "primary", marginVertical: "10px", onClick: onStartConnecting }) })))] }))] })) : (_jsx(_Fragment, {})), hasProjectInterests(userTitle) ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "edituserinterestspage-section-title" }, { children: "Choose which type of projects you're interested in" })), _jsx("div", __assign({ className: "edituserinterestspage-tabbar" }, { children: getProjectInterestsArray().map(function (interest, key) { return (_jsxs("div", __assign({ className: "edituserinterestspage-tab ".concat(activeProjectTabClass(key)) }, { children: [_jsx("div", { className: "edituserinterestspage-tabselector", onClick: function () {
                                                return setUserData(__assign(__assign({}, userData), { activeProjectInterestKey: key }));
                                            } }), _jsx(KCheckbox, { title: interest.project_type_name, checked: interest.checked || false, onChange: function (event) {
                                                return updateProjectInterest(key, "checked", event.target.checked);
                                            }, color: getProjectInterestColor(interest), round: true })] }), key)); }) })), _jsxs("div", __assign({ className: "edituserinterestspage-info-section" }, { children: [_jsx("div", __assign({ className: "edituserinterestspage-criteria-title" }, { children: "Select some of the following criteria" })), getProjectInterestsArray().map(function (interest, key) { return (_jsxs("div", __assign({ className: "edituserinterestspage-row", style: {
                                            display: key == getActiveProjectInterestKey()
                                                ? "flex"
                                                : "none",
                                        } }, { children: [_jsx(KSelector, { identifier: "2-" + key, placeholder: "Type", label: "Type", width: width, marginVertical: "10px", marginRight: "20px", items: projectSubtypes, titleKey: "project_subtype_name", scrollParent: scrollParent, multiple: true, itemKey: "project_subtype_id", onChange: function (items) {
                                                    return updateProjectInterest(key, "projectSubtypes", items, items.length > 0);
                                                }, selectedItems: userData.projectInterestsArray[key]
                                                    .projectSubtypes }), _jsx(KSelector, { identifier: "3-" + key, placeholder: "Genre", label: "Genre", width: width, marginVertical: "10px", marginRight: "20px", items: projectGenres, titleKey: "project_genre_name", scrollParent: scrollParent, multiple: true, itemKey: "project_genre_id", onChange: function (items) {
                                                    return updateProjectInterest(key, "projectGenres", items, items.length > 0);
                                                }, selectedItems: userData.projectInterestsArray[key]
                                                    .projectGenres }), _jsx(KSelector, { identifier: "4-" + key, placeholder: "Language", label: "Languages", width: width, marginVertical: "10px", marginRight: "20px", items: languages, titleKey: "language_name", scrollParent: scrollParent, multiple: true, itemKey: "language_id", onChange: function (items) {
                                                    return updateProjectInterest(key, "languages", items, items.length > 0);
                                                }, selectedItems: userData.projectInterestsArray[key]
                                                    .languages })] }), key)); }), _jsx("div", __assign({ className: "edituserinterestspage-action-section" }, { children: _jsx(KButton, { title: !editUserPopupOnceClosed
                                                ? "Start Connecting"
                                                : "Save", variant: "primary", marginVertical: "10px", onClick: onStartConnecting }) }))] }))] })) : (_jsx(_Fragment, {}))] }))] })));
};
export default EditUserInterestsPage;
