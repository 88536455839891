var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { FaPlus as IconAdd } from "react-icons/fa";
import KTextField from "../../base-components/KTextField/KTextField";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import KImage from "../../base-components/KImage/KImage";
import KPopup from "../../base-components/KPopup/KPopup";
import KButton from "../../base-components/KButton/KButton";
import useInfiniteLoading, { infiniteKeys, infiniteLoadingReload, } from "../../hooks/useInfiniteLoading";
import { getProjectImage, getProjectSmallImage, getDefaultCover, } from "../../tools/projectTools";
import { getMarketProjects, getProject, getProjects, } from "../../redux/selectors/projectSelectors";
import { thunkLoadMarketProjects, thunkLoadProjects, } from "../../redux/thunks/projectThunks";
import { getUser } from "../../redux/selectors/authSelectors";
import { thunkLoadUserByUserId } from "../../redux/thunks/userThunks";
import "./ProjectSelector.css";
var ContractProject = function (_a) {
    var _b;
    var project = _a.project, authUser = _a.authUser, validateOn = _a.validateOn, selectedProject = _a.selectedProject, onSelectProject = _a.onSelectProject, _c = _a.skeleton, skeleton = _c === void 0 ? false : _c, _d = _a.selectable, selectable = _d === void 0 ? false : _d, _e = _a.backgroundColor, backgroundColor = _e === void 0 ? null : _e;
    return (_jsxs("div", __assign({ className: "projectselector-contract-project", style: {
            backgroundColor: validateOn && !project ? "#cc003322" : "",
            border: validateOn && !project
                ? "1px solid var(--app-error-color)"
                : "",
        } }, { children: [selectable ? (_jsx("div", __assign({ className: "projectselector-project-check" }, { children: _jsx(KCheckbox, { title: " ", round: true, checked: (selectedProject === null || selectedProject === void 0 ? void 0 : selectedProject.project_id) === (project === null || project === void 0 ? void 0 : project.project_id), onChange: function () { return onSelectProject(project); } }) }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "projectselector-project-image", style: { backgroundColor: backgroundColor } }, { children: skeleton ? (_jsx("div", __assign({ className: "projectselector-project-add-button" }, { children: _jsx(IconAdd, {}) }))) : (_jsxs(_Fragment, { children: [_jsx("img", { src: getDefaultCover(project) }), _jsx(KImage, { imgSrc: getProjectImage(project), smallImgSrc: getProjectSmallImage(project) })] })) })), _jsxs("div", __assign({ className: "projectselector-project-info" }, { children: [_jsx("div", __assign({ className: "projectselector-project-info-line-1 ".concat(skeleton ? "projectselector-project-skeleton" : ""), style: { backgroundColor: skeleton ? backgroundColor : "" } }, { children: project === null || project === void 0 ? void 0 : project.project_title })), _jsx("div", __assign({ className: "projectselector-project-info-line-2 ".concat(skeleton ? "projectselector-project-skeleton" : ""), style: { backgroundColor: skeleton ? backgroundColor : "" } }, { children: project &&
                            (project.user_first_name || project.user_last_name)
                            ? "A ".concat((_b = project.project_type_name) === null || _b === void 0 ? void 0 : _b.toLowerCase(), " by ").concat((project.user_first_name || "") +
                                " " +
                                (project.user_last_name || ""))
                            : "" }))] }))] })));
};
var ProjectSelector = function (_a) {
    var _b;
    var projects = _a.projects, authUser = _a.authUser, allProjects = _a.allProjects, onSelectProject = _a.onSelectProject, onLoadUser = _a.onLoadUser, onLoadProjects = _a.onLoadProjects, onLoadAllProjects = _a.onLoadAllProjects, _c = _a.backgroundColor, backgroundColor = _c === void 0 ? null : _c, _d = _a.validateOn, validateOn = _d === void 0 ? false : _d, _e = _a.personal, personal = _e === void 0 ? false : _e;
    var limit = 10;
    var location = useLocation();
    var query = new URLSearchParams(location.search);
    var _f = useState(null), selectedProject = _f[0], _setSelectedProject = _f[1];
    var _g = useState([]), filteredProjects = _g[0], setFilteredProjects = _g[1];
    var _h = useState(false), projectSelectionActive = _h[0], setProjectSelectionActive = _h[1];
    var _j = useState(""), projectSearchText = _j[0], _setProjectSearchText = _j[1];
    var projectSearchTextRef = useState(projectSearchText);
    var setProjectSearchText = function (data) {
        projectSearchTextRef.current = data;
        _setProjectSearchText(data);
    };
    var setSelectedProject = function (project) {
        _setSelectedProject(project);
        onSelectProject(project);
    };
    var onFilterProjects = function () {
        if (personal) {
            setFilteredProjects(projects.filter(function (project) {
                return project.project_title
                    .toLowerCase()
                    .indexOf(projectSearchText) > -1;
            }));
        }
        else {
            infiniteLoadingReload(infiniteKeys.MARKET); // reload when projects get displayed
            infiniteLoadingReload(infiniteKeys.CONTRACT_PROJECT);
            onLoadAllProjects(projectSearchText, limit, 0, true);
        }
    };
    useEffect(function () {
        if (personal)
            onFilterProjects();
        var projectId = query.get("projectId");
        if (projectId) {
            projectId = parseInt(projectId);
            if (personal) {
                var project = projects.find(function (project) { return project.project_id === projectId; });
                setSelectedProject(project);
            }
            else {
                var project = allProjects.find(function (project) { return project.project_id === projectId; });
                setSelectedProject(project);
            }
        }
    }, [projects]);
    // infinite loading section
    var scrollRef = useRef(null);
    var scrollParentRef = useRef(null);
    var onLoadMore = function (count, offset) {
        if (personal)
            return;
        onLoadAllProjects(projectSearchTextRef.current, count, offset, false);
    };
    var onFirstLoad = function () {
        if (personal)
            onLoadProjects(authUser.user_id);
        else
            onLoadAllProjects(projectSearchTextRef.current, limit, 0, true);
    };
    infiniteLoadingReload(infiniteKeys.MARKET); // reload when projects get displayed
    infiniteLoadingReload(infiniteKeys.CONTRACT_PROJECT);
    useInfiniteLoading(infiniteKeys.CONTRACT_PROJECT, scrollRef, (personal ? projects : allProjects).length, onLoadMore, limit, onFirstLoad, scrollParentRef);
    return (_jsxs("div", __assign({ className: "projectselector-container" }, { children: [_jsx(KPopup, __assign({ active: projectSelectionActive, onClose: function () { return setProjectSelectionActive(false); } }, { children: _jsxs("div", __assign({ className: "projectselector-project-searchbox" }, { children: [_jsx("div", __assign({ className: "projectselector-project-title" }, { children: "Select a project" })), _jsx("div", __assign({ className: "projectselector-project-description" }, { children: "Pick a project for the contract to be based on." })), _jsxs("div", __assign({ className: "projectselector-project-search" }, { children: [_jsx(KTextField, { placeholder: "Find a project..", width: "calc(100% - 100px)", height: "36px", value: projectSearchText, onChange: function (e) {
                                        return setProjectSearchText(e.target.value);
                                    } }), _jsx(KButton, { title: "Search", height: "36px", variant: "primary", onClick: onFilterProjects })] })), _jsx("div", __assign({ className: "projectselector-project-options", ref: scrollParentRef }, { children: ((_b = (personal ? filteredProjects : allProjects)) === null || _b === void 0 ? void 0 : _b.length) ? (_jsx("div", __assign({ ref: scrollRef }, { children: (personal
                                    ? filteredProjects
                                    : allProjects).map(function (project, key) { return (_jsx(ContractProject, { authUser: authUser, project: project, selectedProject: selectedProject, onSelectProject: function (project) {
                                        if (!personal)
                                            onLoadUser(project.user_id);
                                        setSelectedProject(project);
                                        history.replaceState(null, "", "/platform/boosts/new?projectId=".concat(project.project_id));
                                    }, selectable: true }, key)); }) }))) : (_jsx("div", __assign({ className: "projectselector-empty" }, { children: "You don't have any projects yet." }))) })), _jsx("div", __assign({ className: "projectselector-project-actions" }, { children: _jsx(KButton, { title: "Done", variant: "primary", onClick: function () { return setProjectSelectionActive(false); }, outline: true }) }))] })) })), _jsx("div", __assign({ className: "projectselector-project-container" }, { children: _jsx("div", __assign({ className: "projectselector-project-selection", onClick: function () { return setProjectSelectionActive(true); } }, { children: _jsx(ContractProject, { project: selectedProject, backgroundColor: backgroundColor, skeleton: selectedProject ? false : true, validateOn: validateOn }) })) }))] })));
};
var mapStateToProps = function (state) { return ({
    authUser: getUser(state),
    projects: getProjects(state),
    allProjects: getMarketProjects(state),
    loadedProject: getProject(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadAllProjects: function (text, limit, offset, reload) {
        return dispatch(thunkLoadMarketProjects(text, limit, offset, reload, {}));
    },
    onLoadProjects: function (userId) { return dispatch(thunkLoadProjects(userId)); },
    onLoadUser: function (userId) { return dispatch(thunkLoadUserByUserId(userId)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ProjectSelector);
