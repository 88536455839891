var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { history } from "../../redux/store";
import { loadStripe } from "@stripe/stripe-js";
import { MdPayment as IconPayment } from "react-icons/md";
import { Elements, CardElement, useElements, useStripe, } from "@stripe/react-stripe-js";
import KButton from "../../base-components/KButton/KButton";
import KPopup from "../../base-components/KPopup/KPopup";
import { getUserDefaultPaymentMethod, getUserPaymentMethods, } from "../../redux/selectors/authSelectors";
import { thunkCreateSetupIntent, thunkDetachPaymentMethod, thunkLoadDefaultPaymentMethod, thunkLoadPaymentMethods, thunkSetDefaultPaymentMethod, } from "../../redux/thunks/authThunks";
import { thunkStartLoading, thunkStopLoading, } from "../../redux/thunks/generalThunks";
import { getColorMode } from "../../redux/selectors/generalSelectors";
import KTextField from "../../base-components/KTextField/KTextField";
import CardBox from "../../components/CardBox/CardBox";
import "./PaymentMethodsPage.css";
var stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
var AddBox = function (_a) {
    var onAdd = _a.onAdd;
    return (_jsx("div", __assign({ className: "addbox-container" }, { children: _jsx("div", __assign({ className: "addbox-action-box" }, { children: _jsx("div", __assign({ className: "addbox-title", onClick: onAdd }, { children: "Add new payment method" })) })) })));
};
var PaymentForm = function (_a) {
    var clientSecret = _a.clientSecret, returnPathname = _a.returnPathname, onStartLoading = _a.onStartLoading, onStopLoading = _a.onStopLoading, colorMode = _a.colorMode, onClose = _a.onClose;
    // Get the lookup key for the price from the previous page redirect.
    var cardRef = useRef(null);
    var _b = useState(""), name = _b[0], setName = _b[1];
    var _c = useState(""), message = _c[0], setMessage = _c[1];
    var _d = useState(""), cardFontColor = _d[0], setCardFontColor = _d[1];
    var _e = useState(""), cardPlaceholderColor = _e[0], setCardPlaceholderColor = _e[1];
    var _f = useState(), paymentIntent = _f[0], setPaymentIntent = _f[1];
    var _g = useState(false), isFocused = _g[0], setIsFocused = _g[1];
    // Initialize an instance of stripe.
    var stripe = useStripe();
    var elements = useElements();
    useEffect(function () {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return function (f) { return f; };
        }
    }, [stripe]);
    // When the subscribe-form is submitted we do a few things:
    //
    //   1. Tokenize the payment method
    //   2. Create the subscription
    //   3. Handle any next actions like 3D Secure that are required for SCA.
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var cardElement, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    onStartLoading();
                    cardElement = elements.getElement(CardElement);
                    return [4 /*yield*/, stripe.confirmCardSetup(clientSecret, {
                            payment_method: {
                                card: cardElement,
                                billing_details: { name: name },
                            },
                        })];
                case 1:
                    res = _a.sent();
                    console.log(res);
                    if (res.error) {
                        // show error and collect new card details.
                        setMessage(error.message);
                        onStopLoading();
                        return [2 /*return*/];
                    }
                    setPaymentIntent(res.setupIntent);
                    onStopLoading();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var focusInterval = setInterval(function () {
            var elements = document.getElementsByClassName("StripeElement--focus");
            setIsFocused(elements && elements.length);
        }, 100);
        return function () {
            clearTimeout(focusInterval);
        };
    }, []);
    useEffect(function () {
        setTimeout(function () {
            var cardStyle = getComputedStyle(cardRef.current);
            setCardPlaceholderColor(cardStyle["text-decoration-color"]);
            setCardFontColor(cardStyle.color);
        }, 10);
    }, [colorMode]);
    useEffect(function () {
        if (paymentIntent && paymentIntent.status === "succeeded") {
            history.push(returnPathname);
            onClose();
        }
    }, [paymentIntent]);
    return (_jsxs("div", __assign({ style: {
            backgroundColor: "var(--app-base-secondary-background-color)",
            padding: "20px 30px",
            paddingTop: "5px",
            maxWidth: "600px",
            width: "calc(100vw - 60px - 12px)",
            borderRadius: "var(--app-border-radius)",
        } }, { children: [_jsxs("div", __assign({ className: "subscribepage-title" }, { children: [_jsx("div", __assign({ className: "subscribepage-title-icon" }, { children: _jsx(IconPayment, {}) })), _jsx("div", __assign({ className: "subscribepage-title-text" }, { children: "Add Card" }))] })), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(KTextField, { label: "Name on card", placeholder: "Name on card", variant: "primary", value: name, backgroundColor: "var(--app-primary-background-color)", onChange: function (e) { return setName(e.target.value); }, marginVertical: "20px", width: "240px" }), _jsx("label", __assign({ className: "subscribepage-cardelement-label" }, { children: "Card Details" })), _jsx("div", __assign({ className: "subscribepage-cardelement-container ".concat(isFocused ? "subscribepage-cardelement-focused" : ""), ref: cardRef }, { children: _jsx(CardElement, { options: {
                                style: {
                                    base: {
                                        fontSize: "14px",
                                        backgroundColor: "transparent",
                                        color: cardFontColor,
                                        "::placeholder": {
                                            color: cardPlaceholderColor,
                                            fontStyle: "italic",
                                            fontSize: "13.5px",
                                            fontWeight: "300",
                                        },
                                    },
                                },
                            } }) })), _jsx(KButton, { title: "Submit", variant: "primary", marginVertical: "20px", type: "submit", onClick: function (f) { return f; } }), _jsx("div", __assign({ className: "checkoutform-message-box" }, { children: message }))] }))] })));
};
var PaymentMethodsPage = function (_a) {
    var paymentMethods = _a.paymentMethods, defaultPaymentMethod = _a.defaultPaymentMethod, colorMode = _a.colorMode, onCreateSetupIntent = _a.onCreateSetupIntent, onStopLoading = _a.onStopLoading, onStartLoading = _a.onStartLoading, onLoadPaymentMethods = _a.onLoadPaymentMethods, onLoadDefaultPayemntMethod = _a.onLoadDefaultPayemntMethod, onSetDefaultPaymentMethod = _a.onSetDefaultPaymentMethod, onDetachPaymentMethod = _a.onDetachPaymentMethod;
    // Get the lookup key for the price from the previous page redirect.
    var _b = useState(null), options = _b[0], setOptions = _b[1];
    var _c = useState(false), editMode = _c[0], setEditMode = _c[1];
    var _d = useState(null), clientSecret = _d[0], setClientSecret = _d[1];
    var closeEditMode = function () {
        setEditMode(false);
        setClientSecret(null);
        onLoadPaymentMethods();
        onLoadDefaultPayemntMethod();
    };
    var updateOptions = function (clientSecret) {
        var style = getComputedStyle(document.body);
        var appearance = {
            theme: "flat",
            variables: {
                colorDanger: "#cc0033",
                fontWeightNormal: "400",
                fontSizeSm: style.getPropertyValue("--app-font-size-small-2"),
                focusOutline: "1.5px solid ".concat(style.getPropertyValue("--app-primary-color")),
                focusBoxShadow: "none",
                fontFamily: "Sohne, system-ui, sans-serif",
                fontWeightNormal: "500",
                borderRadius: "8px",
                colorBackground: style.getPropertyValue("--app-primary-background-color"),
                colorPrimary: style.getPropertyValue("--app-primary-color"),
                colorPrimaryText: "#1A1B25",
                colorText: "white",
                colorTextSecondary: style.getPropertyValue("--app-font-color-light"),
                colorTextPlaceholder: style.getPropertyValue("--app-font-color-light"),
                colorIconTab: "white",
                colorLogo: "dark",
            },
            rules: {
                ".Input": {
                    padding: "13px",
                    backgroundColor: "var(--colorBackground)",
                    fontSize: style.getPropertyValue("--app-font-size-regular"),
                    color: style.getPropertyValue("--app-font-color"),
                    marginBottom: "10px",
                },
                ".Input--invalid": {
                    boxShadow: "0 0 0 1px var(--colorDanger)",
                    backgroundColor: style.getPropertyValue("--app-textfield-error-color"),
                },
                ".Label": {
                    color: style.getPropertyValue("--app-font-color-light"),
                    fontSize: style.getPropertyValue("--app-font-size-small-2"),
                    marginBottom: "6px",
                    fontWeight: "400",
                },
            },
        };
        setOptions({ clientSecret: clientSecret, appearance: appearance });
    };
    var onAddNewCard = function () {
        onCreateSetupIntent(function (res) {
            setClientSecret(res.clientSecret);
            updateOptions(res.clientSecret);
            setEditMode(true);
        });
    };
    var loadAllPaymentData = function () {
        onLoadPaymentMethods();
        onLoadDefaultPayemntMethod();
    };
    var detachPaymentMethod = function (paymentId) {
        onDetachPaymentMethod(paymentId, function () { return loadAllPaymentData(); });
    };
    var setDefaultPaymentMethod = function (paymentId) {
        onSetDefaultPaymentMethod(paymentId, function () { return loadAllPaymentData(); });
    };
    useEffect(function () {
        loadAllPaymentData();
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(KPopup, __assign({ active: editMode, onClose: closeEditMode }, { children: clientSecret && options && (_jsx(Elements, __assign({ options: options, stripe: stripePromise }, { children: _jsx(PaymentForm, { onStopLoading: onStopLoading, onStartLoading: onStartLoading, returnPathname: "/platform/paymentMethod", clientSecret: clientSecret, colorMode: colorMode, onClose: closeEditMode }) }))) })), _jsxs("div", __assign({ className: "paymentmethodspage-container" }, { children: [_jsxs("div", __assign({ className: "paymentmethodspage-title" }, { children: [_jsx("div", __assign({ className: "paymentmethodspage-title-icon" }, { children: _jsx(IconPayment, {}) })), _jsx("div", __assign({ className: "paymentmethodspage-title-text" }, { children: "Manage your payment methods" }))] })), defaultPaymentMethod.id ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "paymentmethodspage-section-title" }, { children: "Default" })), _jsx(CardBox, { paymentMethod: defaultPaymentMethod, isDefault: true })] })) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "paymentmethodspage-section-title" }, { children: "Other Payment Methods" })), _jsxs("div", __assign({ className: "paymentmethodspage-wrapper" }, { children: [paymentMethods
                                .filter(function (pm) { return pm.id !== defaultPaymentMethod.id; })
                                .map(function (pm, key) { return (_jsx(CardBox, { paymentMethod: pm, setDefaultPaymentMethod: setDefaultPaymentMethod, detachPaymentMethod: detachPaymentMethod }, key)); }), _jsx(AddBox, { onAdd: function () { return onAddNewCard(); } })] }))] }))] }));
};
var mapStateToProps = function (state) { return ({
    colorMode: getColorMode(state),
    paymentMethods: getUserPaymentMethods(state),
    defaultPaymentMethod: getUserDefaultPaymentMethod(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onCreateSetupIntent: function (callback) {
        return dispatch(thunkCreateSetupIntent(callback));
    },
    onStopLoading: function () { return dispatch(thunkStopLoading()); },
    onStartLoading: function () { return dispatch(thunkStartLoading()); },
    onLoadPaymentMethods: function () { return dispatch(thunkLoadPaymentMethods()); },
    onLoadDefaultPayemntMethod: function () { return dispatch(thunkLoadDefaultPaymentMethod()); },
    onDetachPaymentMethod: function (paymentMethodId, callback) {
        return dispatch(thunkDetachPaymentMethod(paymentMethodId, callback));
    },
    onSetDefaultPaymentMethod: function (paymentMethodId, callback) {
        return dispatch(thunkSetDefaultPaymentMethod(paymentMethodId, callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsPage);
