var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { IoIosAdd as IconOpen } from "react-icons/io";
import { IoIosRemove as IconClose } from "react-icons/io";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import "./FilteringCard.css";
import KTextField from "../../base-components/KTextField/KTextField";
import KButton from "../../base-components/KButton/KButton";
var FilteringCard = function (_a) {
    var _b = _a.items, items = _b === void 0 ? [] : _b, _c = _a.onFilteringOptionsChange, onFilteringOptionsChange = _c === void 0 ? null : _c, _d = _a.textFieldValues, textFieldValues = _d === void 0 ? null : _d, _e = _a.selectedItems, selectedItems = _e === void 0 ? [] : _e, _f = _a.onClearSectionItems, onClearSectionItems = _f === void 0 ? null : _f, _g = _a.onApply, onApply = _g === void 0 ? null : _g, _h = _a.errorMessages, errorMessages = _h === void 0 ? null : _h;
    var _j = useState(items), itemsArray = _j[0], setItemsArray = _j[1];
    var errMessagesCount = function () {
        var count = 0;
        for (var err in errorMessages) {
            if (errorMessages[err])
                count++;
        }
        return count;
    };
    var _k = useState(errMessagesCount()), applyDisabled = _k[0], setApplyDisabled = _k[1];
    useEffect(function () {
        setApplyDisabled(errMessagesCount());
    }, [errorMessages]);
    var onToggle = function (key) {
        var updatedArray = itemsArray;
        var isOpen = updatedArray[key].isOpen;
        for (var _i = 0, updatedArray_1 = updatedArray; _i < updatedArray_1.length; _i++) {
            var entry = updatedArray_1[_i];
            entry.isOpen = false;
        }
        updatedArray[key].isOpen = !isOpen;
        setItemsArray(__spreadArray([], updatedArray, true));
    };
    useEffect(function () {
        setItemsArray(items);
    }, [
        JSON.stringify(itemsArray),
        JSON.stringify(selectedItems),
        JSON.stringify(items),
    ]);
    return (_jsxs("div", __assign({ className: "filteringcard-container" }, { children: [itemsArray.map(function (item, key) {
                var _a, _b;
                return (_jsxs("div", __assign({ className: "filteringcard-section" }, { children: [_jsxs("div", __assign({ className: "filteringcard-section-header", onClick: function () { return onToggle(key); } }, { children: [_jsx("div", __assign({ className: "filteringcard-section-header-icon" }, { children: item.isOpen ? _jsx(IconClose, {}) : _jsx(IconOpen, {}) })), _jsxs("div", __assign({ className: "filteringcard-section-header-title" }, { children: [item.title, (!item.data.optionType ||
                                            item.data.optionType === "checkbox") &&
                                            (((_a = selectedItems[item.data.searchOption]) === null || _a === void 0 ? void 0 : _a.length) === 0 ? (_jsx("span", __assign({ className: "filteringcard-section-selected-options" }, { children: "All" }))) : (_jsxs("span", __assign({ className: "filteringcard-section-selected-options filteringcard-section-selected-options-selected" }, { children: [_jsx("span", __assign({ className: "filteringcard-section-clear-selected-items", onClick: function () {
                                                            onClearSectionItems &&
                                                                onClearSectionItems(item.data.searchOption);
                                                            onToggle(key);
                                                        } }, { children: "clear" })), _jsx("span", { children: (_b = selectedItems[item.data.searchOption]) === null || _b === void 0 ? void 0 : _b.length })] }))))] }))] })), _jsxs("div", __assign({ className: "filteringcard-section-body ".concat(item.isOpen ? "filteringcard-section-open" : "") }, { children: [item.data.toString() === "[object Object]" &&
                                    item.data.content.map(function (entry, key) {
                                        var _a, _b, _c, _d, _e, _f, _g;
                                        if (((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.optionType) === "text") {
                                            return (_jsx(KTextField, { marginVertical: "6px", defaultBorderColor: "var(--app-primary-color)", placeholder: ((_b = item === null || item === void 0 ? void 0 : item.data) === null || _b === void 0 ? void 0 : _b.placeholder)
                                                    ? (_c = item === null || item === void 0 ? void 0 : item.data) === null || _c === void 0 ? void 0 : _c.placeholder
                                                    : "", type: ((_d = item === null || item === void 0 ? void 0 : item.data) === null || _d === void 0 ? void 0 : _d.type)
                                                    ? (_e = item === null || item === void 0 ? void 0 : item.data) === null || _e === void 0 ? void 0 : _e.type
                                                    : "text", value: entry === null || entry === void 0 ? void 0 : entry.textValue, onChange: function (e) {
                                                    onFilteringOptionsChange &&
                                                        onFilteringOptionsChange(item.data.searchOption, entry[item.data
                                                            .itemValueTitle], item.data
                                                            .itemValueTitle, true, e.target.value);
                                                }, width: "150px", backgroundColor: "var(--app-primary-background-color)", height: "30px", label: entry[item.data.itemTitle], errorMessage: errorMessages &&
                                                    errorMessages[entry[item.data.itemValueTitle]], errorMessageFontSize: "11px" }, key));
                                        }
                                        else if (!((_f = item === null || item === void 0 ? void 0 : item.data) === null || _f === void 0 ? void 0 : _f.optionType) ||
                                            ((_g = item === null || item === void 0 ? void 0 : item.data) === null || _g === void 0 ? void 0 : _g.optionType) === "checkbox") {
                                            return (_jsx(KCheckbox, { checked: entry.checked, title: entry[item.data.itemTitle], onChange: function () {
                                                    return onFilteringOptionsChange &&
                                                        onFilteringOptionsChange(item.data.searchOption, entry[item.data.itemValueTitle], item.data.itemValueTitle);
                                                } }, key));
                                        }
                                    }), Array.isArray(item.data) &&
                                    item.data.map(function (entry, key) {
                                        return _jsx(KCheckbox, { title: entry }, key);
                                    })] }))] }), key));
            }), onApply && (_jsx("div", __assign({ className: "apply-btn-container" }, { children: _jsx(KButton, { title: "Apply", variant: "primary", backgroundColor: "var(--app-primary-color)", applyDisabled: applyDisabled, onClick: onApply && onApply }) })))] })));
};
FilteringCard.propTypes = {};
FilteringCard.defaultProps = {};
export default FilteringCard;
