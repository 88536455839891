var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./KCheckbox.css";
var KCheckbox = function (_a) {
    var round = _a.round, title = _a.title, color = _a.color, disabled = _a.disabled, marginTop = _a.marginTop, marginBottom = _a.marginBottom, marginRight = _a.marginRight, marginLeft = _a.marginLeft, checked = _a.checked, defaultChecked = _a.defaultChecked, fontSize = _a.fontSize, onChange = _a.onChange;
    var styleContainer = {
        "--kcheckbox-color": color ? color : "",
        "--kcheckbox-margin-top": marginTop || "0px",
        "--kcheckbox-margin-bottom": marginBottom || "10px",
        "--kcheckbox-margin-left": marginLeft || "0px",
        "--kcheckbox-margin-right": marginRight || "18px",
        "--kcheckbox-border-radius": round ? "50%" : "4px",
        "--kcheckbox-font-size": fontSize || "var(--app-font-size-regular)",
    };
    return (_jsxs("label", __assign({ style: styleContainer, className: "kcheckbox-container ".concat(disabled ? "" : "kcheckbox-enabled") }, { children: [_jsx("input", { className: "kcheckbox-input", type: "checkbox", checked: checked, defaultChecked: defaultChecked, disabled: disabled, onChange: onChange }), _jsx("div", __assign({ className: "kcheckbox-outerbox" }, { children: _jsx("div", { className: "kcheckbox-innermark" }) })), _jsx("div", __assign({ className: "kcheckbox-title" }, { children: title || "KCheckbox" }))] })));
};
export default KCheckbox;
