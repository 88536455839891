var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from "react";
import kachat from "kachat";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { IoIosAdd as IconAdd } from "react-icons/io";
import { RiSearchEyeLine as IconExplore } from "react-icons/ri";
import { IoIosRemove as IconRemove } from "react-icons/io";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import BoostedCard from "../../components/BoostedCard/BoostedCard";
import FilteringCard from "../../components/FilteringCard/FilteringCard";
import SearchNetworkForm from "../../components/SearchNetworkForm/SearchNetworkForm";
import { getMarketProjects, getBoostedMarketProjects, getUserBoostedProjects, } from "../../redux/selectors/projectSelectors";
import { thunkLoadMarketProjects, thunkLoadBoostedProjects, } from "../../redux/thunks/projectThunks";
import { getUserId } from "../../redux/selectors/authSelectors";
import { getLoadedUser, getMarketLookingForSelectedSearchOptions, getMarketPath, getMarketSelectedSearchOptions, } from "../../redux/selectors/userSelectors";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { getProjectTypes, getProjectSubtypes, getProjectKinds, getLanguages, getProjectGenres, getCountries, getUserTitles, } from "../../redux/selectors/generalSelectors";
import KSwitch from "../../base-components/KSwitch/KSwitch";
import { loadMarketLookingForUserSelectedSearchOptions, loadMarketSelectedSearchOptions, setMarketPath, } from "../../redux/actions/userActions";
import { thunkLoadProjectKinds, thunkLoadUserTitles, } from "../../redux/thunks/generalThunks";
import useInfiniteLoading, { infiniteKeys, infiniteLoadingReload, } from "../../hooks/useInfiniteLoading";
import "./ProjectsPage.css";
import KCarousel from "../../base-components/KCarousel/KCarousel";
import ExtraInfo from "../ExtraInfo/ExtraInfo";
import { collaborate } from "../ProjectPage/ProjectPage";
var ProjectsPage = function (_a) {
    var userTitles = _a.userTitles, projectTypes = _a.projectTypes, projectSubtypes = _a.projectSubtypes, projectKinds = _a.projectKinds, marketPath = _a.marketPath, languages = _a.languages, countries = _a.countries, projectGenres = _a.projectGenres, projects = _a.projects, userBoosts = _a.userBoosts, boostedProjects = _a.boostedProjects, currentUserId = _a.currentUserId, loadedUser = _a.loadedUser, onLoadProjects = _a.onLoadProjects, onSetMarketPath = _a.onSetMarketPath, onLoadUserTitles = _a.onLoadUserTitles, onLoadProjectKinds = _a.onLoadProjectKinds, onLoadBoostedProjects = _a.onLoadBoostedProjects, selectedMarketSearchOptions = _a.selectedMarketSearchOptions, selectedMarketLookingForSearchOptions = _a.selectedMarketLookingForSearchOptions, onLoadMarketSelectedSearchOptions = _a.onLoadMarketSelectedSearchOptions, onLoadMarketLookingForSelectedSearchOptions = _a.onLoadMarketLookingForSelectedSearchOptions;
    var limit = 10;
    var history = useHistory();
    var location = useLocation();
    var query = new URLSearchParams(location.search);
    var _b = useState(false), isReady = _b[0], setIsReady = _b[1];
    var _c = useState(false), showFilters = _c[0], setShowFilters = _c[1];
    // const [showFilters, setShowFilters] = useState(
    //     window.innerWidth < 600 ? false : true
    // );
    var _d = useState(""), lookingForTitle = _d[0], setLookingForTitle = _d[1];
    var lookingfor = query.get("lookingfor") && parseInt(query.get("lookingfor"))
        ? parseInt(query.get("lookingfor"))
        : null;
    var emptySearchOptions = {
        projectTypeIds: [],
        projectSubtypeIds: [],
        projectKindIds: [],
        projectGenreIds: [],
        countryIds: [],
        languageIds: [],
        sale_collaboration: [],
        min_acquisition_budget: "",
        max_acquisition_budget: "",
        min_production_budget: "",
        max_production_budget: "",
        personalized: [false],
    };
    var loadInitialSearchOptions = function () {
        if (lookingfor) {
            if (Object.keys(selectedMarketLookingForSearchOptions).length)
                return selectedMarketLookingForSearchOptions.searchOptions;
        }
        else if (Object.keys(selectedMarketSearchOptions).length)
            return selectedMarketSearchOptions.searchOptions;
        return emptySearchOptions;
    };
    var _e = useState({
        min_acquisition_budget: "",
        max_acquisition_budget: "",
        min_production_budget: "",
        max_production_budget: "",
    }), validationErrors = _e[0], setValidationErrors = _e[1];
    var _f = useState({
        min_acquisition_budget: "",
        max_acquisition_budget: "",
        min_production_budget: "",
        max_production_budget: "",
    }), textFieldValues = _f[0], _setTextFieldValues = _f[1];
    var _g = useState(""), searchText = _g[0], _setSearchText = _g[1];
    var _h = useState([]), allBoosts = _h[0], setAllBoosts = _h[1];
    var _j = useState([]), filteringData = _j[0], _setFilteringData = _j[1];
    var _k = useState(loadInitialSearchOptions()), searchOptions = _k[0], _setSearchOptions = _k[1];
    var _l = useState({}), appliedSearchOptions = _l[0], setAppliedSearchOptions = _l[1];
    var searchTextRef = useRef(searchText);
    var searchOptionsRef = useRef(searchOptions);
    var filteringDataRef = useRef(filteringData);
    var textFieldValuesRef = useRef(textFieldValues);
    var setSearchText = function (data) {
        searchTextRef.current = data;
        _setSearchText(data);
    };
    var setSearchOptions = function (data) {
        searchOptionsRef.current = data;
        _setSearchOptions(data);
    };
    var setFilteringData = function (data) {
        filteringDataRef.current = data;
        _setFilteringData(data);
    };
    var setTextFieldValues = function (data) {
        textFieldValuesRef.current = data;
        _setTextFieldValues(data);
    };
    useEffect(function () {
        var newValidationErrors = {};
        for (var i in textFieldValues) {
            if (isNaN(textFieldValues[i])) {
                newValidationErrors[i] = "Numeric values only allowed";
            }
            else {
                newValidationErrors[i] = "";
            }
        }
        if (textFieldValues.min_acquisition_budget !== "" &&
            textFieldValues.max_acquisition_budget !== "" &&
            Number(textFieldValues.min_acquisition_budget) >=
                Number(textFieldValues.max_acquisition_budget)) {
            newValidationErrors.min_acquisition_budget =
                "Min should be less than Max";
        }
        if (textFieldValues.min_production_budget !== "" &&
            textFieldValues.max_production_budget !== "" &&
            Number(textFieldValues.min_production_budget) >=
                Number(textFieldValues.max_production_budget)) {
            newValidationErrors.min_production_budget =
                "Min should be less than Max";
        }
        setValidationErrors(__assign(__assign({}, validationErrors), newValidationErrors));
    }, [textFieldValues]);
    var clearTextOptions = function () {
        var filteredData = filteringData.map(function (item) {
            if (["production_budget", "acquisition_budget"].includes(item.data.searchOption)) {
                item.data.content.map(function (entry) {
                    entry["textValue"] = "";
                    return entry;
                });
            }
            return item;
        });
        setFilteringData(filteredData);
        setTextFieldValues({
            min_acquisition_budget: "",
            max_acquisition_budget: "",
            min_production_budget: "",
            max_production_budget: "",
        });
    };
    var onSearchOptionsChange = function (dataTitle, itemValue, itemTitle, isText, textValue) {
        var _a, _b, _c, _d;
        if (itemTitle === void 0) { itemTitle = ""; }
        if (isText === void 0) { isText = false; }
        if (textValue === void 0) { textValue = ""; }
        if (isText) {
            var filteredData_1 = filteringData.map(function (item) {
                if (item.data.searchOption === dataTitle) {
                    item.data.content.map(function (entry) {
                        if (entry[itemTitle] === itemValue) {
                            entry["textValue"] = textValue;
                        }
                        return entry;
                    });
                }
                return item;
            });
            setFilteringData(filteredData_1);
            setTextFieldValues(__assign(__assign({}, textFieldValues), (_a = {}, _a[itemValue] = textValue, _a)));
            return;
        }
        var dataToSet;
        if (searchOptions[dataTitle].includes(itemValue)) {
            dataToSet = searchOptions[dataTitle].filter(function (entry) { return entry !== itemValue; });
        }
        else {
            dataToSet = __spreadArray(__spreadArray([], searchOptions[dataTitle], true), [itemValue], false);
        }
        var filteredData = filteringData.map(function (item) {
            if (item.data.searchOption === dataTitle) {
                item.data.content.map(function (entry) {
                    if (entry[itemTitle] === itemValue) {
                        entry.checked = !entry.checked;
                        return entry;
                    }
                    return entry;
                });
            }
            return item;
        });
        setFilteringData(filteredData);
        setSearchOptions(__assign(__assign({}, searchOptions), (_b = {}, _b[dataTitle] = dataToSet, _b)));
        if (lookingfor)
            onLoadMarketLookingForSelectedSearchOptions(__assign(__assign({}, searchOptions), (_c = {}, _c[dataTitle] = dataToSet, _c)));
        else
            onLoadMarketSelectedSearchOptions(__assign(__assign({}, searchOptions), (_d = {}, _d[dataTitle] = dataToSet, _d)));
    };
    var onClearSectionItems = function (dataTitle) {
        var _a, _b, _c;
        var clearedSection = filteringData.map(function (item) {
            if (item.data.searchOption === dataTitle) {
                item.isOpen = false;
                item.data.content.map(function (entry) { return (entry.checked = false); });
                return item;
            }
            return item;
        });
        setFilteringData(clearedSection);
        onLoadMarketSelectedSearchOptions(__assign(__assign({}, searchOptions), (_a = {}, _a[dataTitle] = [], _a)));
        setSearchOptions(__assign(__assign({}, searchOptions), (_b = {}, _b[dataTitle] = [], _b)));
        setAppliedSearchOptions(__assign(__assign({}, searchOptions), (_c = {}, _c[dataTitle] = [], _c)));
    };
    useDocumentTitle("Scriptofilm | Market");
    var onApply = function () { return __awaiter(void 0, void 0, void 0, function () {
        var errorsCount, i;
        return __generator(this, function (_a) {
            setTextFieldValues(__assign(__assign({}, textFieldValues), { min_acquisition_budget: "" }));
            errorsCount = 0;
            for (i in validationErrors) {
                if (validationErrors[i])
                    errorsCount++;
            }
            if (errorsCount)
                return [2 /*return*/];
            window.scrollTo(0, 0);
            setLastTriggerOffset(0);
            // await onLoadProjects(searchText, limit, 0, true, {
            //     ...searchOptions,
            //     ...textFieldValues,
            // });
            infiniteLoadingReload(infiniteKeys.MARKET);
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        var _a;
        setLookingForTitle((_a = userTitles.find(function (t) { return t.user_title_id === lookingfor; })) === null || _a === void 0 ? void 0 : _a.user_title_name);
    }, [userTitles.length]);
    var updateFilteringData = function () {
        var markCheckedValues = function (objArr, searchOption, itemValueTitle) {
            for (var item in objArr) {
                if (searchOptions[searchOption].includes(objArr[item][itemValueTitle])) {
                    objArr[item].checked = true;
                }
                else {
                    objArr[item].checked = false;
                }
            }
            return objArr;
        };
        var saleCollaborationContent = [
            {
                sale_collaboration_id: 2,
                sale_collaboration_name: "Collaboration",
            },
            {
                sale_collaboration_id: 1,
                sale_collaboration_name: "Sale",
            },
        ];
        var acquisitionBudgetContent = [
            {
                acquisition_budget_name: "min_acquisition_budget",
                acquisition_budget_label: "Min Budget ($)",
            },
            {
                acquisition_budget_name: "max_acquisition_budget",
                acquisition_budget_label: "Max Budget ($)",
            },
        ];
        var productionBudgetContent = [
            {
                production_budget_name: "min_production_budget",
                production_budget_label: "Min Budget ($)",
            },
            {
                production_budget_name: "max_production_budget",
                production_budget_label: "Max Budget ($)",
            },
        ];
        setFilteringData([
            {
                isOpen: searchOptions.projectTypeIds.length,
                title: "Type",
                data: {
                    searchOption: "projectTypeIds",
                    content: markCheckedValues(projectTypes, "projectTypeIds", "project_type_id"),
                    itemTitle: "project_type_name",
                    itemValueTitle: "project_type_id",
                },
            },
            // {
            //     isOpen: searchOptions.projectSubtypeIds.length,
            //     title: "Subtype",
            //     data: {
            //         searchOption: "projectSubtypeIds",
            //         content: markCheckedValues(
            //             projectSubtypes,
            //             "projectSubtypeIds",
            //             "project_subtype_id"
            //         ),
            //         itemTitle: "project_subtype_name",
            //         itemValueTitle: "project_subtype_id",
            //     },
            // },
            // {
            //     isOpen: searchOptions.projectKindIds.length,
            //     title: "Kind",
            //     data: {
            //         searchOption: "projectKindIds",
            //         content: markCheckedValues(
            //             projectKinds,
            //             "projectKindIds",
            //             "project_kind_id"
            //         ),
            //         itemTitle: "project_kind_name",
            //         itemValueTitle: "project_kind_id",
            //     },
            // },
            {
                isOpen: searchOptions.projectGenreIds.length &&
                    !searchOptions.projectTypeIds.length,
                title: "Genre",
                data: {
                    searchOption: "projectGenreIds",
                    content: markCheckedValues(projectGenres, "projectGenreIds", "project_genre_id"),
                    itemTitle: "project_genre_name",
                    itemValueTitle: "project_genre_id",
                },
            },
            {
                isOpen: searchOptions.countryIds.length &&
                    !searchOptions.projectGenreIds.length &&
                    !searchOptions.projectTypeIds.length,
                title: "Country",
                data: {
                    searchOption: "countryIds",
                    content: markCheckedValues(countries, "countryIds", "country_id"),
                    itemTitle: "country_name",
                    itemValueTitle: "country_id",
                },
            },
            {
                isOpen: searchOptions.languageIds.length &&
                    !searchOptions.countryIds.length &&
                    !searchOptions.projectGenreIds.length &&
                    !searchOptions.projectTypeIds.length,
                title: "Language",
                data: {
                    searchOption: "languageIds",
                    content: markCheckedValues(languages, "languageIds", "language_id"),
                    itemTitle: "language_name",
                    itemValueTitle: "language_id",
                },
            },
            {
                isOpen: searchOptions.sale_collaboration.length &&
                    !searchOptions.languageIds.length &&
                    !searchOptions.countryIds.length &&
                    !searchOptions.projectGenreIds.length &&
                    !searchOptions.projectTypeIds.length,
                title: "Collaboration/Sale",
                data: {
                    searchOption: "sale_collaboration",
                    content: markCheckedValues(saleCollaborationContent, "sale_collaboration", "sale_collaboration_id"),
                    itemTitle: "sale_collaboration_name",
                    itemValueTitle: "sale_collaboration_id",
                },
            },
            {
                isOpen: false,
                title: "Production Budget",
                data: {
                    searchOption: "production_budget",
                    optionType: "text",
                    type: "number",
                    defaultBorder: true,
                    content: productionBudgetContent,
                    itemTitle: "production_budget_label",
                    itemValueTitle: "production_budget_name",
                },
            },
            {
                isOpen: false,
                title: "Acquisition Budget",
                data: {
                    searchOption: "acquisition_budget",
                    optionType: "text",
                    type: "number",
                    defaultBorder: true,
                    content: acquisitionBudgetContent,
                    itemTitle: "acquisition_budget_label",
                    itemValueTitle: "acquisition_budget_name",
                },
            },
        ]);
    };
    var _m = useState(0), lastTriggerOffset = _m[0], _setLastTriggerOffset = _m[1];
    var lastTriggerOffsetRef = React.useRef(lastTriggerOffset);
    var setLastTriggerOffset = function (data) {
        lastTriggerOffsetRef.current = data;
        _setLastTriggerOffset(data);
    };
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    window.scrollTo(0, 0);
                    setLastTriggerOffset(0);
                    return [4 /*yield*/, onLoadProjects(searchText, limit, 0, true, __assign(__assign({}, searchOptionsRef.current), textFieldValuesRef.current), lookingfor)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSearchChange = function (text) {
        setSearchText(text);
    };
    var getProjects = function () {
        return projects || []; //.filter((project) => project != getBoostedProject());
    };
    var getBoostedProject = function () {
        if (!(userBoosts === null || userBoosts === void 0 ? void 0 : userBoosts.length))
            return boostedProjects;
        var userBoostIds = userBoosts
            // .filter((p) => p.project_user_id_fk !== currentUserId)
            .map(function (p) { return p.project_id; });
        return __spreadArray(__spreadArray([], userBoosts, true), ((boostedProjects === null || boostedProjects === void 0 ? void 0 : boostedProjects.filter(function (p) { return !userBoostIds.includes(p.project_id); })) || []), true);
    };
    useEffect(function () {
        setAllBoosts(getBoostedProject());
    }, [JSON.stringify(boostedProjects), JSON.stringify(userBoosts)]);
    var shouldDisplay = function (project) {
        // if (searchText) {
        //   const projecttext = project.project_title + " " + project.project_synopsis;
        //   if (projecttext.toLowerCase().indexOf(searchText.toLowerCase()) == -1) {
        //     return false;
        //   }
        // }
        return true;
    };
    var getCount = function () {
        if (searchText) {
            var filteredProjects = getProjects().filter(function (project) {
                return shouldDisplay(project);
            });
            return filteredProjects.length;
        }
        return getProjects().length;
    };
    var getBasePath = function (project) {
        if (project.project_user_id_fk == currentUserId) {
            return "/platform/profile/project";
        }
        return "/platform/market/project";
    };
    useEffect(function () {
        var isSubscribed = true;
        Promise.all([
            onLoadProjectKinds(),
            // onLoadBoostedProjects(),
            onLoadUserTitles(),
        ]).then(function () {
            if (isSubscribed)
                setIsReady(true);
        });
        var fullPath = location.pathname + (location.search || "");
        if (marketPath !== fullPath) {
            infiniteLoadingReload(infiniteKeys.MARKET);
            onSetMarketPath(fullPath);
        }
    }, []);
    useEffect(function () {
        updateFilteringData();
    }, [searchOptions]);
    // infinite loading section
    var scrollRef = useRef(null);
    var onLoadMore = function (count, offset) {
        onLoadProjects(searchTextRef.current, count, offset, false, __assign(__assign({}, searchOptionsRef.current), textFieldValuesRef.current), lookingfor);
    };
    var onFirstLoad = function () {
        window.scroll(0, 0);
        onLoadBoostedProjects();
        onLoadProjects(searchTextRef.current, limit, 0, true, __assign(__assign({}, searchOptionsRef.current), textFieldValuesRef.current), lookingfor);
    };
    useInfiniteLoading(infiniteKeys.MARKET, scrollRef, projects.length, onLoadMore, limit, onFirstLoad);
    return (_jsx("div", __assign({ className: "projectspage-container", ref: scrollRef }, { children: isReady ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "projectspage-title" }, { children: [_jsx("div", __assign({ className: "projectspage-title-icon" }, { children: _jsx(IconExplore, {}) })), _jsx("div", __assign({ className: "projectspage-title-text" }, { children: lookingfor
                                ? "Projects for ".concat(lookingForTitle, "s")
                                : "Explore the global film market" }))] })), allBoosts ? (_jsx(KCarousel, { children: allBoosts.map(function (project, key) { return (_jsx(BoostedCard, { project: project, onViewClick: function () {
                            history.push("".concat(getBasePath(project), "/").concat(project.project_id));
                        }, onCollaborate: function (project) {
                            kachat.createNewGroupChat(project.project_title, "".concat(project.project_id, "_").concat(currentUserId), [project.user_kachat_id]);
                            history.push("/platform/messaging");
                        }, canCollaborate: parseInt(currentUserId) !==
                            parseInt(project.project_user_id_fk), href: "".concat(getBasePath(project), "/").concat(project.project_id) }, key)); }) })) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "projectspage-searchform" }, { children: _jsx(SearchNetworkForm, { placeholder: "Search the global market", onClick: onClick, onChange: onSearchChange }) })), _jsxs("div", __assign({ className: "projectspage-advanced-filters", onClick: function () { return setShowFilters(!showFilters); } }, { children: [_jsx("div", { children: showFilters ? _jsx(IconRemove, {}) : _jsx(IconAdd, {}) }), _jsx("div", { children: _jsx("b", { children: "Advanced filters" }) })] })), _jsxs("div", __assign({ className: "projectspage-sections" }, { children: [_jsx("div", __assign({ className: "projectspage-main-section" }, { children: _jsxs("div", __assign({ className: "projectspage-projects-container" }, { children: [getProjects().map(function (project, key) { return (_jsx("div", __assign({ className: "projectspage-projectcard", style: {
                                            display: shouldDisplay(project)
                                                ? "block"
                                                : "none",
                                        } }, { children: _jsx(ProjectCard, { project: project, authUserId: currentUserId, href: "".concat(getBasePath(project), "/").concat(project.project_id), onViewClick: function () {
                                                history.push("".concat(getBasePath(project), "/").concat(project.project_id));
                                            }, onCollaborate: function () {
                                                return collaborate(project);
                                            }, isEditable: currentUserId ===
                                                project.project_user_id_fk }) }), key)); }), _jsx("div", __assign({ className: "projectspage-empty-title", style: {
                                            display: getCount() == 0 ? "flex" : " none",
                                        } }, { children: "no projects found" }))] })) })), _jsx("div", __assign({ className: "projectspage-secondary-section", style: { display: showFilters ? "block" : "none" } }, { children: _jsxs("div", __assign({ className: "projectspage-secondary-section-first-part" }, { children: [_jsxs("div", __assign({ className: "projectspage-filtering-card", style: {
                                            display: showFilters ? "flex" : "none",
                                            flexDirection: showFilters
                                                ? "column"
                                                : null,
                                        } }, { children: [_jsxs("div", __assign({ className: "projectspage-personalized" }, { children: [_jsx("p", __assign({ className: "projectspage-personalized-text" }, { children: "Turning on the personalized option would enable the filtering of the data based on your profile settings." })), _jsxs("div", __assign({ className: "projectspage-personalized-content" }, { children: [_jsx(KSwitch, { onChange: function () {
                                                                    onClearSectionItems("sale_collaboration");
                                                                    clearTextOptions();
                                                                    setSearchOptions(__assign(__assign({}, emptySearchOptions), { personalized: [
                                                                            !searchOptions
                                                                                .personalized[0],
                                                                        ] }));
                                                                    onLoadMarketSelectedSearchOptions(__assign(__assign({}, emptySearchOptions), { personalized: [
                                                                            !searchOptions
                                                                                .personalized[0],
                                                                        ] }));
                                                                }, checked: searchOptions
                                                                    .personalized[0] }), _jsx("span", { children: "Enable personalized search" })] }))] })), _jsx(FilteringCard, { items: filteringData, onFilteringOptionsChange: onSearchOptionsChange, textFieldValues: textFieldValues, selectedItems: searchOptions, onClearSectionItems: onClearSectionItems, onApply: onApply, errorMessages: validationErrors })] })), _jsxs("div", __assign({ className: "projectspage-extrainfo" }, { children: [_jsx(ExtraInfo, {}), " "] }))] })) }))] }))] })) : (_jsx(_Fragment, {})) })));
};
var mapStateToProps = function (state) { return ({
    userTitles: getUserTitles(state),
    projectTypes: getProjectTypes(state),
    projectSubtypes: getProjectSubtypes(state),
    projectKinds: getProjectKinds(state),
    languages: getLanguages(state),
    projectGenres: getProjectGenres(state),
    countries: getCountries(state),
    projects: getMarketProjects(state),
    marketPath: getMarketPath(state),
    selectedMarketSearchOptions: getMarketSelectedSearchOptions(state),
    selectedMarketLookingForSearchOptions: getMarketLookingForSelectedSearchOptions(state),
    boostedProjects: getBoostedMarketProjects(state),
    userBoosts: getUserBoostedProjects(state),
    currentUserId: getUserId(state),
    loadedUser: getLoadedUser(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadUserTitles: function () { return dispatch(thunkLoadUserTitles()); },
    onLoadProjects: function (text, limit, offset, reload, appliedSearchOptions, lookingfor) {
        return dispatch(thunkLoadMarketProjects(text, limit, offset, reload, appliedSearchOptions, lookingfor));
    },
    onLoadProjectKinds: function () { return dispatch(thunkLoadProjectKinds()); },
    onLoadBoostedProjects: function () { return dispatch(thunkLoadBoostedProjects()); },
    onLoadMarketSelectedSearchOptions: function (selectedSearchOptions) {
        return dispatch(loadMarketSelectedSearchOptions(selectedSearchOptions));
    },
    onLoadMarketLookingForSelectedSearchOptions: function (selectedSearchOptions) {
        return dispatch(loadMarketLookingForUserSelectedSearchOptions(selectedSearchOptions));
    },
    onSetMarketPath: function (path) { return dispatch(setMarketPath(path)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);
