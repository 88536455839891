import permissions from "../configs/permissions";
import { hasPermission } from "./permissionValidator";
import { isAuthUserFilmmaker, isAuthUserScriptwriter, } from "./profileValidator";
export var canCreateContract = function () {
    return ((hasPermission(permissions.CONTRACT_CREATE) ||
        hasPermission(permissions.CONTRACT_CREATE_OWN)) &&
        !isAuthUserScriptwriter() &&
        !isAuthUserFilmmaker());
};
export var canCreateRevision = function () {
    return (hasPermission(permissions.CONTRACTREVISION_CREATE) ||
        hasPermission(permissions.CONTRACTREVISION_CREATE_OWN));
};
export var canCreateSignatureRequest = function () {
    return (hasPermission(permissions.CONTRACTSIGREQUEST_CREATE) ||
        hasPermission(permissions.CONTRACTSIGREQUEST_CREATE_OWN));
};
export var canListContracts = function () {
    return (hasPermission(permissions.CONTRACT_LIST) ||
        hasPermission(permissions.CONTRACT_LIST_OWN));
};
export var canSignContract = function () {
    return (hasPermission(permissions.CONTRACTSIGNATURE_SIGN) ||
        hasPermission(permissions.CONTRACTSIGNATURE_SIGN_OWN));
};
