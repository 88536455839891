var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, Fragment } from 'react';
import Footer from '../../mui-components/footer/footer';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import NavBar from '../../components/NavBar/NavBar';
import { useHistory } from 'react-router-dom';
import './AboutPage.css';
var AboutPageItem = function (_a) {
    var title = _a.title, text = _a.text, _b = _a.underline, underline = _b === void 0 ? false : _b;
    return (_jsxs("div", __assign({ className: 'aboutpage-item' }, { children: [_jsx("p", __assign({ className: 'aboutpage-item-title' }, { children: title })), _jsx("p", __assign({ className: 'aboutpage-item-text' }, { children: text })), underline && _jsx("span", { className: 'aboutpage-item-underline' })] })));
};
var AboutPage = function () {
    var history = useHistory();
    var aboutPageItemList = [
        {
            title: 'Mission',
            text: 'Our mission is to bridge the gap between talents and industry professionals beyond borders.',
            underline: true,
        },
        {
            title: 'Vision',
            text: 'Our aim is to provide equal opportunities to all members regardless of their national origin.',
            underline: true,
        },
        {
            title: 'Founders',
            text: 'Scriptofilm began with Gaëlle Fayad and Karl Fayad.',
        },
    ];
    useEffect(function () {
        window.scrollTo(0, 0);
    }, [history.location]);
    useDocumentTitle('Scriptofilm | About');
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'aboutpage-main-div' }, { children: _jsxs(Fragment, { children: [_jsx(NavBar, { showLine: 'orange' }), _jsx("img", { className: 'aboutpage-background', src: '/images/about-background.png' }), _jsx("div", __assign({ className: 'aboutpage-container' }, { children: _jsxs("div", __assign({ className: 'aboutpage' }, { children: [_jsx("h2", __assign({ className: 'aboutpage-title' }, { children: "About us" })), _jsx("p", __assign({ className: 'aboutpage-text' }, { children: "Welcome to Scriptofilm - Your Global Film & Series Market - where scriptwriters and filmmakers connect with producers, investors, and buyers for scripts and movies collaborations." })), aboutPageItemList.map(function (item, index) { return (_jsx(AboutPageItem, { title: item.title, text: item.text, underline: item.underline }, index + item.title)); })] })) })), _jsx(Footer, {})] }) })) }));
};
export default AboutPage;
