export default {
    PERMISSION_LIST: 1,
    PERMISSION_CREATE: 2,
    PERMISSION_UPDATE: 3,
    PERMISSION_DELETE: 4,
    ROLE_LIST: 5,
    ROLE_CREATE: 6,
    ROLE_UPDATE: 7,
    ROLE_DELETE: 8,
    USER_LIST: 9,
    USER_CREATE: 10,
    USER_UPDATE: 11,
    USER_DELETE: 12,
    ROLEPERMISSION_CREATE: 13,
    ROLEPERMISSION_DELETE: 14,
    USERROLE_LIST: 15,
    USERROLE_CREATE: 16,
    USERROLE_DELETE: 17,
    USERPERMISSION_LIST: 18,
    PROFILE_VIEW_OWN: 19,
    NOTIFICATION_LIST: 20,
    NOTIFICATION_CREATE: 21,
    NOTIFICATION_UPDATE: 22,
    NOTIFICATION_DELETE: 23,
    NOTIFICATION_MARK: 24,
    NOTIFICATION_LIST_OWN: 25,
    NOTIFICATION_MARK_OWN: 26,
    NOTIFICATIONTYPE_LIST: 27,
    NOTIFICATIONTYPE_CREATE: 28,
    NOTIFICATIONTYPE_UPDATE: 29,
    NOTIFICATIONTYPE_DELETE: 30,
    NOTIFUSERSTATUS_LIST: 31,
    NOTIFUSERSTATUS_CREATE: 32,
    NOTIFUSERSTATUS_UPDATE: 33,
    NOTIFUSERSTATUS_DELETE: 34,
    CONTRACT_LIST: 35,
    CONTRACT_CREATE: 36,
    CONTRACT_UPDATE: 37,
    CONTRACT_DELETE: 38,
    CONTRACT_LIST_OWN: 39,
    CONTRACT_CREATE_OWN: 40,
    CONTRACTTYPE_LIST: 41,
    CONTRACTTYPE_CREATE: 42,
    CONTRACTTYPE_UPDATE: 43,
    CONTRACTTYPE_DELETE: 44,
    CONTRACTPARTY_LIST: 45,
    CONTRACTPARTY_CREATE: 46,
    CONTRACTPARTY_UPDATE: 47,
    CONTRACTPARTY_DELETE: 48,
    CONTRACTLAWYER_LIST: 49,
    CONTRACTLAWYER_CREATE: 50,
    CONTRACTLAWYER_UPDATE: 51,
    CONTRACTLAWYER_DELETE: 52,
    CONTRACTREVISION_LIST: 53,
    CONTRACTREVISION_CREATE: 54,
    CONTRACTREVISION_UPDATE: 55,
    CONTRACTREVISION_DELETE: 56,
    CONTRACTREVISION_LIST_OWN: 57,
    CONTRACTREVISION_CREATE_OWN: 58,
    CONTRACTCOMMENT_LIST: 59,
    CONTRACTCOMMENT_CREATE: 60,
    CONTRACTCOMMENT_UPDATE: 61,
    CONTRACTCOMMENT_DELETE: 62,
    CONTRACTCOMMENT_LIST_OWN: 63,
    CONTRACTCOMMENT_CREATE_OWN: 64,
    CONTRACTSIGREQUEST_LIST: 65,
    CONTRACTSIGREQUEST_CREATE: 66,
    CONTRACTSIGREQUEST_UPDATE: 67,
    CONTRACTSIGREQUEST_DELETE: 68,
    CONTRACTSIGREQUEST_LIST_OWN: 69,
    CONTRACTSIGREQUEST_CREATE_OWN: 70,
    CONTRACTSIGREQUEST_COMPLETE: 71,
    CONTRACTSIGREQUEST_COMPLETE_OWN: 72,
    CONTRACTSIGNATURE_LIST: 73,
    CONTRACTSIGNATURE_CREATE: 74,
    CONTRACTSIGNATURE_UPDATE: 75,
    CONTRACTSIGNATURE_DELETE: 76,
    CONTRACTSIGNATURE_SIGN: 77,
    CONTRACTSIGNATURE_LIST_OWN: 78,
    CONTRACTSIGNATURE_SIGN_OWN: 79,
    USER_LIST: 80,
    USER_CREATE: 81,
    USER_UPDATE: 82,
    USER_DELETE: 83,
    USER_LIST_BASIC: 84,
    USER_LIST_PREMIUM: 85,
    USERREPORT_LIST: 86,
    USERREPORT_CREATE: 87,
    USERREPORT_UPDATE: 88,
    USERREPORT_DELETE: 89,
    USERREPORT_CHECK: 90,
    USERREPORT_CHECK_BASIC: 91,
    USERREPORT_CHECK_PREMIUM: 92,
    USERREPORT_CREATE_BASIC: 93,
    USERREPORT_CREATE_PREMIUM: 94,
    PROJECT_LIST: 95,
    PROJECT_CREATE: 96,
    PROJECT_UPDATE: 97,
    PROJECT_DELETE: 98,
    PROJECT_LIST_BASIC: 99,
    PROJECT_LIST_PREMIUM: 100,
    PROJECT_CREATE_OWN: 101,
    PROJECT_UPDATE_OWN: 102,
    PROJECT_DELETE_OWN: 103,
};
