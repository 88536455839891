var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, createRef } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FcGoogle as IconGoogle } from "react-icons/fc";
import TermsPopup from "../../components/TermsPopup/TermsPopup";
import KButton from "../../base-components/KButton/KButton";
import KTextField from "../../base-components/KTextField/KTextField";
import KSelector from "../../base-components/KSelector/KSelector";
import { getUser, getUserAuthenticated, getUserError, getUserSignedUp, } from "../../redux/selectors/authSelectors";
import { getUserTitles } from "../../redux/selectors/generalSelectors";
import { thunkClearUserSignedup, thunkUpdateSignupInfo, } from "../../redux/thunks/authThunks";
import { thunkSetPrimaryColor, thunkLoadUserTitles, thunkClearData, } from "../../redux/thunks/generalThunks";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import authApi from "../../apis/authApi";
import "./SignupPage.css";
var SignupPage = function (_a) {
    var onSignup = _a.onSignup, onClearData = _a.onClearData, userTitles = _a.userTitles, onLoadUserTitles = _a.onLoadUserTitles, user = _a.user, userAuthenticated = _a.userAuthenticated, onClearUserSignedup = _a.onClearUserSignedup, onUpdateSignUpInfo = _a.onUpdateSignUpInfo, onSetPrimaryColor = _a.onSetPrimaryColor, errorMessage = _a.errorMessage, userSignedUp = _a.userSignedUp;
    var history = useHistory();
    var googleRef = createRef();
    var facebookRef = createRef();
    var query = new URLSearchParams(useLocation().search);
    var _b = useState(""), firstName = _b[0], setFirstName = _b[1];
    var _c = useState(""), lastName = _c[0], setLastName = _c[1];
    var _d = useState(""), email = _d[0], setEmail = _d[1];
    var _e = useState(""), password = _e[0], setPassword = _e[1];
    var _f = useState(""), titleId = _f[0], setTitleId = _f[1];
    // the promo code has been added to allow automatic access when a user signs up as in this phase we're locking the access to all users but the data entry team needs to register users and fill in their profiles so the promo code is used to offer them this ability
    var _g = useState(""), promoCode = _g[0], setPromoCode = _g[1];
    var _h = useState(""), localError = _h[0], setLocalError = _h[1];
    var _j = useState(false), isTermsOpen = _j[0], setIsTermsOpen = _j[1];
    var _k = useState(""), companyName = _k[0], setCompanyName = _k[1];
    var _l = useState(""), companyWebsite = _l[0], setCompanyWebsite = _l[1];
    var _m = useState(""), position = _m[0], setPosition = _m[1];
    var _o = useState(""), projectName = _o[0], setProjectName = _o[1];
    var _p = useState(""), festivalName = _p[0], setFestivalName = _p[1];
    var _q = useState(false), displayCheck = _q[0], setDisplayCheck = _q[1];
    var resetState = function () {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setTitleId("");
        setLocalError("");
        setIsTermsOpen("");
        setCompanyName("");
        setCompanyWebsite("");
        setPosition("");
        setProjectName("");
        setFestivalName("");
        onClearData();
        onLoadUserTitles();
        onSetPrimaryColor("unknown");
    };
    var onSubmit = function (event) {
        event.preventDefault();
        setLocalError("");
        if (titleId)
            onSignup({
                firstName: firstName,
                lastName: lastName,
                email: email,
                password: password,
                titleId: titleId,
                position: position,
                companyName: companyName,
                companyWebsite: companyWebsite,
                projectName: projectName,
                festivalName: festivalName,
                promoCode: promoCode,
            });
        else
            setLocalError("please select a valid title");
    };
    var getError = function () {
        return localError || errorMessage || query.get("message");
    };
    var onTitleChange = function (item) {
        if (item === null || item === void 0 ? void 0 : item.user_title_id) {
            setTitleId(item.user_title_id);
            onSetPrimaryColor(item.user_title_name);
        }
    };
    useDocumentTitle("Scriptofilm | Sign-up");
    useEffect(function () {
        if (userAuthenticated)
            history.push("/signin");
    }, [user]);
    useEffect(function () {
        onClearData();
        onLoadUserTitles();
        // history.push("/signup");
        onSetPrimaryColor("unknown");
    }, []);
    useEffect(function () {
        onUpdateSignUpInfo({
            firstName: firstName,
            lastName: lastName,
            email: email,
            titleId: titleId,
            promoCode: promoCode,
        });
    }, [firstName, lastName, email, titleId, promoCode]);
    return (_jsxs("div", __assign({ className: "signuppage-container" }, { children: [_jsx(TermsPopup, { active: isTermsOpen, onClose: function () { return setIsTermsOpen(false); } }), _jsxs("div", __assign({ className: "signuppage-card" }, { children: [_jsx("div", __assign({ className: "signuppage-logo-div" }, { children: _jsx("a", __assign({ href: "/" }, { children: _jsx("img", { className: "signuppage-logo", alt: "scriptofilm" }) })) })), _jsx("div", __assign({ className: "signuppage-header" }, { children: "Your global films and series' market" })), _jsx("p", __assign({ className: "signuppage-error", style: { display: "".concat(getError() ? "block" : "none") } }, { children: getError() })), query.get("welcome") !== "true" ? (_jsxs(_Fragment, { children: [_jsxs("form", __assign({ action: "".concat(authApi.defaults.baseURL, "/register?firstName=").concat(firstName, "&lastName=").concat(lastName, "&titleId=").concat(titleId, "&promoCode=").concat(promoCode), method: "post" }, { children: [_jsx("div", __assign({ className: "signuppage-input-container" }, { children: _jsx(KSelector, { label: "Title", width: "100%", required: true, titleKey: "user_title_name", items: userTitles, onChange: onTitleChange, errorMessage: displayCheck
                                                ? titleId
                                                    ? ""
                                                    : "Title is required"
                                                : "" }) })), _jsx("div", __assign({ className: "signuppage-input-container" }, { children: _jsx(KTextField, { label: "Email", width: "100%", type: "email", name: "email", required: true, value: email, onChange: function (event) {
                                                return setEmail(event.target.value);
                                            } }) })), _jsx("div", __assign({ className: "signuppage-input-container" }, { children: _jsx(KTextField, { label: "Password", width: "100%", type: "password", name: "password", value: password, onChange: function (event) {
                                                return setPassword(event.target.value);
                                            }, pattern: "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,}", title: "Must be 6 or more characters long and should contain at least one number, one uppercase and one lowercase letter" }) })), _jsx("div", __assign({ className: "signuppage-input-container" }, { children: _jsx(KTextField, { label: "Promo Code", width: "100%", value: promoCode, onChange: function (event) {
                                                return setPromoCode(event.target.value);
                                            } }) })), _jsxs("p", __assign({ className: "signuppage-disclosure" }, { children: ["By signing up, you're agreeing to Scriptofilm's", " ", _jsx(_Fragment, { children: " " }), _jsx("a", __assign({ onClick: function () {
                                                    return history.push("/termsofservice");
                                                } }, { children: "terms of service" })), ",", _jsx("span", { children: " privacy policy" }), ", and", _jsx("span", { children: " cookie compliance" }), "."] })), _jsx(KButton, { title: "Agree & Sign up", variant: "primary", width: "100%", height: "44px", onClick: function (f) { return f; }, type: "submit" })] })), _jsxs("div", __assign({ className: "signuppage-separator" }, { children: [_jsx("div", { className: "signuppage-line" }), _jsx("h2", { children: "OR CONTINUE WITH" }), _jsx("div", { className: "signuppage-line" })] })), _jsx(KButton, { title: "", 
                                // title="Sign up with Google"
                                variant: "primary", width: "100%", height: "26px", color: "white", icon: IconGoogle, onClick: function () {
                                    if (titleId) {
                                        setDisplayCheck(false);
                                        googleRef.current.click();
                                    }
                                    else {
                                        setDisplayCheck(true);
                                    }
                                }, backgroundColor: "#f1f1f1", 
                                // backgroundColor="#4285f4"
                                marginVertical: "8px" }), _jsx("a", { ref: facebookRef, style: { display: "none" }, href: "".concat(authApi.defaults.baseURL, "/facebook?&titleId=").concat(titleId, "&promoCode=").concat(promoCode) }), _jsx("a", { ref: googleRef, style: { display: "none" }, href: "".concat(authApi.defaults.baseURL, "/google?&titleId=").concat(titleId, "&promoCode=").concat(promoCode) }), _jsxs("p", __assign({ className: "signuppage-tosignin" }, { children: ["Already a Scriptofilm member?", " ", _jsx("a", __assign({ style: {
                                            color: "var(--app-filmmaker-color)",
                                        }, onClick: function () { return history.push("/signin"); } }, { children: "Sign in" }))] })), _jsx("p", __assign({ className: "signinpage-tosignup" }, { children: _jsx("a", __assign({ style: {
                                        color: "var(--app-producer-color)",
                                    }, onClick: function () { return history.push("/"); } }, { children: "Visit our landing page" })) }))] })) : (_jsxs("div", __assign({ className: "signuppage-welcome" }, { children: [_jsx("div", { dangerouslySetInnerHTML: {
                                    __html: process.env.WELCOME_PAGE_HTML,
                                } }), _jsx("p", __assign({ className: "signuppage-tosignin" }, { children: _jsx("a", __assign({ style: { color: "var(--app-producer-color)" }, onClick: function () { return history.push("/"); } }, { children: "Visit our landing page" })) })), _jsx("p", __assign({ className: "signuppage-tosignin" }, { children: _jsx("a", __assign({ style: { color: "var(--app-guest-color)" }, onClick: function () {
                                        resetState();
                                        onClearUserSignedup();
                                        history.push("/signup");
                                    } }, { children: "Sign up a different account" })) })), _jsxs("p", __assign({ className: "signuppage-tosignin" }, { children: ["or", " ", _jsx("a", __assign({ style: { color: "var(--app-filmmaker-color)" }, onClick: function () { return history.push("/signin"); } }, { children: "Sign in" }))] }))] })))] }))] })));
};
var mapStateToProps = function (state) { return ({
    user: getUser(state),
    userSignedUp: getUserSignedUp(state),
    userAuthenticated: getUserAuthenticated(state),
    errorMessage: getUserError(state),
    userTitles: getUserTitles(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onSetPrimaryColor: function (primaryColor) {
        return dispatch(thunkSetPrimaryColor(primaryColor));
    },
    onClearUserSignedup: function () { return dispatch(thunkClearUserSignedup()); },
    onLoadUserTitles: function () { return dispatch(thunkLoadUserTitles()); },
    onClearData: function () { return dispatch(thunkClearData()); },
    onUpdateSignUpInfo: function (user) { return dispatch(thunkUpdateSignupInfo(user)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
