var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IoIosCloseCircle as IconClose } from "react-icons/io";
import "./EditScriptHeader.css";
var EditScriptHeader = function (_a) {
    var onClose = _a.onClose, onPublish = _a.onPublish, onUpdate = _a.onUpdate, onPreview = _a.onPreview, _b = _a.isEditMode, isEditMode = _b === void 0 ? false : _b;
    return (_jsxs("div", __assign({ className: "editscriptheader-container" }, { children: [_jsx("div", __assign({ className: "editscriptheader-icon-container" }, { children: _jsx(IconClose, { className: "editscriptheader-icon", onClick: onClose }) })), _jsx("div", { className: "editscriptheader-buttons" })] })));
};
export default EditScriptHeader;
