import { createSelector } from "reselect";
export var getProject = function (state) {
    return state.projects.project;
};
export var getProjectCoverImages = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.project.project_coverimages;
};
export var getProjectMoodImages = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.project.project_moodimages;
};
export var getProjectStoryImages = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.project.project_storyimages;
};
export var getProjectSynopsisFiles = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.project.project_synopsisfiles;
};
export var getProjectTreatmentFiles = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.project.project_treatmentfiles;
};
export var getProjectVideoScenes = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.videoScenes || [];
};
export var getProjectShootingSets = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.shootingSets || [];
};
export var getProjectCharacters = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return state.projects.characters || [];
};
export var getProjectActors = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return (state.projects.members || []).filter(function (e) { return e.member_type === "actor"; });
};
export var getProjectCrews = function (state) {
    if (typeof state.projects.project === "undefined")
        return [];
    return (state.projects.members || []).filter(function (e) { return e.member_type === "crew"; });
};
export var getProjects = function (state) {
    return state.projects.data;
};
export var getMarketProjects = function (state) {
    return state.projects.marketData;
};
export var getBoostedMarketProjects = function (state) {
    return state.projects.boostedMarketData;
};
export var getUserBoostedProjects = function (state) {
    return state.projects.userBoosts;
};
export var getCreatedProject = function (state) {
    return state.projects.createdProject;
};
export var getProjectInProcess = function (state) {
    return state.projects.projectInProcess;
};
export var getMessagingProjects = function (state) {
    return state.projects.messagingProjects;
};
var getOpenProjects = function (projects) {
    return projects.filter(function (project) {
        return project.project_open_for_sale |
            project.project_open_for_collaboration;
    });
};
var getClosedProjects = function (projects) {
    return projects.filter(function (project) {
        return !(project.project_open_for_sale |
            project.project_open_for_collaboration);
    });
};
export var getScriptProjects = function (state) {
    return state.projects.data.filter(function (project) { return (project.project_type_name || "").toLowerCase() == "script"; });
};
export var getOpenScriptProjects = createSelector(getScriptProjects, function (projects) { return getOpenProjects(projects); });
export var getClosedScriptProjects = createSelector(getScriptProjects, function (projects) { return getClosedProjects(projects); });
export var getFilmProjects = function (state) {
    return state.projects.data.filter(function (project) { return (project.project_type_name || "").toLowerCase() == "film"; });
};
export var getOpenFilmProjects = createSelector(getFilmProjects, function (projects) {
    return getOpenProjects(projects);
});
export var getClosedFilmProjects = createSelector(getFilmProjects, function (projects) { return getClosedProjects(projects); });
export var getAccessRequestStatus = function (state) {
    return state.projects.accessRequestStatus;
};
export var getAccessRequests = function (state) {
    return state.projects.accessRequests;
};
// Check why this is not reloading data on change of projects
// import { createSelector } from "reselect";
//
// export const getOpenProjects = createSelector(
//   getProjects,
//   (projects) => projects.filter((project) => (
//     project.project_open_for_sale | project.project_open_for_collaboration)),
// );
//
// export const getClosedProjects = createSelector(
//   [getProjects],
//   (projects) => projects.filter((project) => !(
//     project.project_open_for_sale | project.project_open_for_collaboration)),
// );
export var getScriptCollaborationProjects = function (state) {
    return state.projects.collaborationData.filter(function (project) { return (project.project_type_name || "").toLowerCase() == "script"; });
};
export var getFilmCollaborationProjects = function (state) {
    return state.projects.collaborationData.filter(function (project) { return (project.project_type_name || "").toLowerCase() == "film"; });
};
export var getProjectCollaborators = function (state) {
    return state.projects.collaborators;
};
export var getInterestedProjects = function (state) {
    return state.projects.interestedProjects;
};
