var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Redirect, NavLink, useRouteMatch, useHistory, } from "react-router-dom";
import KNavRoute from "../../base-components/KNavRoute/KNavRoute";
import EditUserBasicsPage from "../EditUserBasicsPage/EditUserBasicsPage";
import EditUserInterestsPage from "../EditUserInterestsPage/EditUserInterestsPage";
import EditUserProjectsPage from "../EditUserProjectsPage/EditUserProjectsPage";
import EditUserAwardsPage from "../EditUserAwardsPage/EditUserAwardsPage";
import { getUserId, getUserTitle } from "../../redux/selectors/authSelectors";
import { thunkLoadUserByUserId } from "../../redux/thunks/userThunks";
import { thunkUpdateUserData } from "../../redux/thunks/authThunks";
import { thunkLoadCities, thunkLoadCountries, thunkLoadLanguages, thunkLoadUserTitles, thunkLoadProjectTypes, thunkLoadProjectGenres, thunkLoadProjectSubtypes, thunkLoadProjectStages, } from "../../redux/thunks/generalThunks";
import { getLanguages, getCountries, getCities, getUserTitles, getProjectTypes, getProjectSubtypes, getProjectGenres, getProjectStages, } from "../../redux/selectors/generalSelectors";
import { getMonthsArray, getValueInCurrency, getYearsArray, } from "../../tools/generalTools";
import EditUserLegalitiesPage from "../EditUserLegalitiesPage/EditUserLegalitiesPage";
import { canHaveAwards, canHaveInterests, canHaveLegalities, } from "../../validators/profileValidator";
import "./EditUserPage.css";
import { getLoadedUser } from "../../redux/selectors/userSelectors";
var EditUserPage = function (_a) {
    var visible = _a.visible, user = _a.user, onExit = _a.onExit, onEdit = _a.onEdit, onUpdateUser = _a.onUpdateUser, onLoadUser = _a.onLoadUser, userTitles = _a.userTitles, userTitle = _a.userTitle, cities = _a.cities, countries = _a.countries, languages = _a.languages, loadUserTitles = _a.loadUserTitles, loadCities = _a.loadCities, loadCountries = _a.loadCountries, loadLanguages = _a.loadLanguages, loadProjectTypes = _a.loadProjectTypes, loadProjectStages = _a.loadProjectStages, projectStages = _a.projectStages, projectTypes = _a.projectTypes, projectSubtypes = _a.projectSubtypes, projectGenres = _a.projectGenres, loadProjectGenres = _a.loadProjectGenres, loadProjectSubtypes = _a.loadProjectSubtypes, userId = _a.userId, editUserPopupOnceClosed = _a.editUserPopupOnceClosed, setEditUserPopupOnceClosed = _a.setEditUserPopupOnceClosed;
    var history = useHistory();
    var _b = useRouteMatch(), path = _b.path, url = _b.url;
    var _c = useState({}), userData = _c[0], setUserData = _c[1];
    var _d = useState(false), isReady = _d[0], setIsReady = _d[1];
    var _e = useState([]), scrollParent = _e[0], setScrollParent = _e[1];
    // const isTabActive = (match, location, toPath) =>
    //     (match !== null) |
    //     ((toPath !== path) &
    //         (toPath === location.pathname.slice(0, toPath.length)));
    var firstTabPathname = "/platform/profile/basics";
    var isTabActive = function (match, location, toPath) {
        return location.pathname === toPath;
    };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    userData.interestsArray = (_a = userData.interestsArray) === null || _a === void 0 ? void 0 : _a.filter(function (i) { return i.checked == true; });
                    userData.projectInterestsArray = (_b = userData.projectInterestsArray) === null || _b === void 0 ? void 0 : _b.filter(function (i) { return i.checked == true; });
                    setUserData(__assign(__assign({}, userData), { interestsArray: userData.interestsArray, projectInterestsArray: userData.projectInterestsArray }));
                    return [4 /*yield*/, onUpdateUser(userData)];
                case 1:
                    _c.sent();
                    return [4 /*yield*/, onLoadUser(user.user_id)];
                case 2:
                    _c.sent();
                    resetUserInterests();
                    onExit();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSaveUpdates = function (page) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (page)
                history.push("/platform/profile/".concat(page));
            else
                onSave();
            return [2 /*return*/];
        });
    }); };
    function getPageTitles(user_title_name) {
        var yourExperiencePageTitle = "";
        var userInterestsPageTitle = "";
        switch (user.user_title_name) {
            case "Buyer":
                yourExperiencePageTitle = "Company's previous acquisitions";
                userInterestsPageTitle = "Your company's interests";
                break;
            case "Filmmaker":
                yourExperiencePageTitle = "Your previous projects";
                userInterestsPageTitle = "Your interests";
                break;
            case "Investor":
                yourExperiencePageTitle = "Company's previous investments";
                userInterestsPageTitle = "Your company's interests";
                break;
            case "Producer":
                yourExperiencePageTitle = "Company's previous productions";
                userInterestsPageTitle = "Your company's interests";
                break;
            case "Scriptwriter":
                yourExperiencePageTitle = "Your previous projects";
                userInterestsPageTitle = "Your interests";
                break;
        }
        return { yourExperiencePageTitle: yourExperiencePageTitle, userInterestsPageTitle: userInterestsPageTitle };
    }
    var experienceRoutesData = [
        {
            path: "".concat(path, "/your-experience"),
            component: EditUserProjectsPage,
            props: {
                editUserPopupOnceClosed: editUserPopupOnceClosed,
                userData: userData,
                user: user,
                setUserData: setUserData,
                projectTypes: projectTypes,
                projectStages: projectStages,
                countries: countries,
                languages: languages,
                projectGenres: projectGenres,
                scrollParent: scrollParent,
                onSaveUpdates: onSaveUpdates,
            },
            exact: false,
        },
    ];
    var basicsRoutesData = [
        {
            path: "".concat(path, "/basics"),
            component: EditUserBasicsPage,
            props: {
                editUserPopupOnceClosed: editUserPopupOnceClosed,
                userData: userData,
                user: user,
                setUserData: setUserData,
                onEdit: onEdit,
                onExit: onExit,
                onSave: onSave,
                onSaveUpdates: onSaveUpdates,
                cities: cities,
                countries: countries,
                languages: languages,
                userTitles: userTitles,
                scrollParent: scrollParent,
            },
            exact: false,
        },
    ];
    var awardsRoutesData = [
        {
            path: "".concat(path, "/awards"),
            component: EditUserAwardsPage,
            props: {
                editUserPopupOnceClosed: editUserPopupOnceClosed,
                user: user,
                userData: userData,
                setUserData: setUserData,
                onExit: onExit,
                onSaveUpdates: onSaveUpdates,
                scrollParent: scrollParent,
                nextTab: canHaveLegalities() ? "Legalities" : "Interests",
            },
            exact: false,
        },
    ];
    var legalitiesRoutesData = [
        {
            path: "".concat(path, "/legalities"),
            component: EditUserLegalitiesPage,
            props: {
                editUserPopupOnceClosed: editUserPopupOnceClosed,
                scrollParent: scrollParent,
                userData: userData,
                setUserData: setUserData,
                countries: countries,
                projectGenres: projectGenres,
                projectSubtypes: projectSubtypes,
                onSaveUpdates: onSaveUpdates,
            },
            exact: false,
        },
    ];
    var interestsRoutesData = [
        {
            path: "".concat(path, "/interests"),
            component: EditUserInterestsPage,
            props: {
                user: user,
                onExit: onExit,
                userData: userData,
                setUserData: setUserData,
                countries: countries,
                languages: languages,
                onSave: onSave,
                userTitle: userTitle,
                scrollParent: scrollParent,
                projectSubtypes: projectSubtypes,
                projectGenres: projectGenres,
                userTitles: userTitles,
                editUserPopupOnceClosed: editUserPopupOnceClosed,
            },
            exact: false,
        },
    ];
    var notFoundRoutesData = [
        {
            path: "".concat(path, "/*"),
            component: Redirect,
            props: { to: "/notfound" },
            exact: false,
        },
    ];
    var listRoutesData = [].concat(basicsRoutesData, canHaveAwards() ? awardsRoutesData : [], canHaveLegalities() ? legalitiesRoutesData : [], canHaveInterests() ? interestsRoutesData : [], notFoundRoutesData);
    var basicsLinksData = [{ path: "".concat(url, "/basics"), title: "1" }];
    // @TODO: bring back your experience page when ready
    var experienceLinksData = [
        { path: "".concat(url, "/your-experience"), title: "2" },
    ];
    var awardsLinksData = [{ path: "".concat(url, "/awards"), title: "2" }];
    var legalitiesLinksData = [{ path: "".concat(url, "/legalities"), title: "3" }];
    var interestsLinksData = [
        { path: "".concat(url, "/interests"), title: canHaveLegalities() ? "4" : "3" },
    ];
    var listLinksData = [].concat(basicsLinksData, canHaveAwards() ? awardsLinksData : [], canHaveLegalities() ? legalitiesLinksData : [], canHaveInterests() ? interestsLinksData : []);
    var monthsArray = Array.from(getMonthsArray(), function (i) {
        return { title: i.toString() };
    });
    var yearsArray = Array.from(getYearsArray(), function (i) {
        return { title: i.toString() };
    }).reverse();
    /*reset user interests*/
    var resetUserInterests = function () {
        var interestsArray = [];
        // let initialInterestsArray = []
        for (var i = 0; i < (userTitles || []).length; i++) {
            interestsArray.push({
                user_title_id: userTitles[i].user_title_id,
                user_title_name: userTitles[i].user_title_name,
                checked: false,
            });
        }
        for (var i = 0; i < (user.user_interests || []).length; i++) {
            var index = interestsArray.findIndex(function (j) { return j.user_title_id == user.user_interests[i].user_title_id; });
            if (interestsArray[index]) {
                interestsArray[index].countries =
                    user.user_interests[i].countries;
                interestsArray[index].languages =
                    user.user_interests[i].languages;
                interestsArray[index].checked = true;
            }
        }
        var projectInterestsArray = [];
        for (var i = 0; i < (projectTypes || []).length; i++) {
            projectInterestsArray.push({
                project_type_id: projectTypes[i].project_type_id,
                project_type_name: projectTypes[i].project_type_name,
                checked: false,
            });
        }
        for (var i = 0; i < (user.user_project_interests || []).length; i++) {
            var index = projectInterestsArray.findIndex(function (j) {
                return j.project_type_id ==
                    user.user_project_interests[i].project_type_id;
            });
            if (projectInterestsArray[index]) {
                projectInterestsArray[index].projectSubtypes =
                    user.user_project_interests[i].projectSubtypes;
                projectInterestsArray[index].projectGenres =
                    user.user_project_interests[i].projectGenres;
                projectInterestsArray[index].languages =
                    user.user_project_interests[i].languages;
                projectInterestsArray[index].checked = true;
            }
        }
        var languagesArray = [];
        for (var i = 0; i < (user.user_languages || []).length; i++) {
            var language = languages.find(function (j) { return j.language_id == user.user_languages[i].language_id; });
            if (language)
                languagesArray.push(language);
        }
        setUserData(__assign(__assign({}, userData), { languagesArray: languagesArray, interestsArray: interestsArray, projectInterestsArray: projectInterestsArray }));
    };
    /**/
    var resetUserData = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var interestsArray = [];
        for (var i = 0; i < (userTitles || []).length; i++) {
            interestsArray.push({
                user_title_id: userTitles[i].user_title_id,
                user_title_name: userTitles[i].user_title_name,
                checked: false,
            });
        }
        for (var i = 0; i < (user.user_interests || []).length; i++) {
            var index = interestsArray.findIndex(function (j) { return j.user_title_id == user.user_interests[i].user_title_id; });
            if (interestsArray[index]) {
                interestsArray[index].countries =
                    user.user_interests[i].countries;
                interestsArray[index].languages =
                    user.user_interests[i].languages;
                interestsArray[index].checked = true;
            }
        }
        var projectInterestsArray = [];
        for (var i = 0; i < (projectTypes || []).length; i++) {
            projectInterestsArray.push({
                project_type_id: projectTypes[i].project_type_id,
                project_type_name: projectTypes[i].project_type_name,
                checked: false,
            });
        }
        for (var i = 0; i < (user.user_project_interests || []).length; i++) {
            var index = projectInterestsArray.findIndex(function (j) {
                return j.project_type_id ==
                    user.user_project_interests[i].project_type_id;
            });
            if (projectInterestsArray[index]) {
                projectInterestsArray[index].projectSubtypes =
                    user.user_project_interests[i].projectSubtypes;
                projectInterestsArray[index].projectGenres =
                    user.user_project_interests[i].projectGenres;
                projectInterestsArray[index].languages =
                    user.user_project_interests[i].languages;
                projectInterestsArray[index].checked = true;
            }
        }
        var languagesArray = [];
        for (var i = 0; i < (user.user_languages || []).length; i++) {
            var language = languages.find(function (j) { return j.language_id == user.user_languages[i].language_id; });
            if (language)
                languagesArray.push(language);
        }
        var userExperience = { selectFieldValues: [{}], textFieldValues: [{}] };
        var _loop_1 = function (i_1) {
            var uExperience = user.user_experience[i_1];
            uExperience.selectFieldValues[i_1].projectType = projectTypes.find(function (prType) {
                return prType.project_type_id ===
                    user.user_experience[i_1].project_type_id;
            });
            uExperience.textFieldValues[i_1].trailerVideo =
                user.user_experience[i_1].trailer_video;
            uExperience.textFieldValues[i_1].title =
                user.user_experience[i_1].title;
            uExperience.textFieldValues[i_1].role = user.user_experience[i_1].role;
            uExperience.selectFieldValues[i_1].country = countries.find(function (country) {
                return country.country_id === user.user_experience[i_1].country;
            });
            uExperience.selectFieldValues[i_1].language = languages.find(function (language) {
                return language.language_id === user.user_experience[i_1].language;
            });
            uExperience.selectFieldValues[i_1].genre = projectGenres.find(function (genre) {
                return genre.project_genre_id === user.user_experience[i_1].genre;
            });
            uExperience.textFieldValues[i_1].budget =
                user.user_experience[i_1].budget;
            uExperience.selectFieldValues[i_1].stage = projectStages.find(function (stage) {
                return stage.project_stage_id === user.user_experience[i_1].stage;
            });
            uExperience.selectFieldValues[i_1].month = monthsArray.find(function (month) { return month.title === user.user_experience[i_1].month; });
            uExperience.selectFieldValues[i_1].year = yearsArray.find(function (year) { return year.title === user.user_experience[i_1].year; });
            uExperience.textFieldValues[i_1].synopsis =
                user.user_experience[i_1].synopsis;
            userExperience.push(uExperience);
        };
        for (var i_1 = 0; i_1 < (user.user_experience || []).length; i_1++) {
            _loop_1(i_1);
        }
        var investmentGenres = (_b = (_a = user.investment_genres) === null || _a === void 0 ? void 0 : _a.split(",")) === null || _b === void 0 ? void 0 : _b.map(function (id) {
            return projectGenres.find(function (e) { return e.project_genre_id === parseInt(id); });
        });
        var investmentCountries = (_d = (_c = user.investment_countries) === null || _c === void 0 ? void 0 : _c.split(",")) === null || _d === void 0 ? void 0 : _d.map(function (id) {
            return countries.find(function (e) { return e.country_id === parseInt(id); });
        });
        var _k = getPageTitles(user.user_title_name), yourExperiencePageTitle = _k.yourExperiencePageTitle, userInterestsPageTitle = _k.userInterestsPageTitle;
        var investmentTypes = (_e = user.investment_types) === null || _e === void 0 ? void 0 : _e.split(",").map(function (id) {
            return parseInt(id);
        });
        var investmentTickets = (_g = (_f = user.investment_tickets) === null || _f === void 0 ? void 0 : _f.split(",")) === null || _g === void 0 ? void 0 : _g.map(function (value) {
            return {
                value: parseInt(value),
                name: getValueInCurrency(value),
            };
        });
        var investmentProjects = ((_j = (_h = user.investment_projects) === null || _h === void 0 ? void 0 : _h.split(",")) === null || _j === void 0 ? void 0 : _j.map(function (title, key) {
            return {
                title: title,
                key: key,
            };
        })) || [];
        if (!investmentProjects.length)
            investmentProjects.push({ title: "", key: 1 });
        setUserData({
            firstName: user.user_first_name,
            lastName: user.user_last_name,
            imagePath: user.user_image_path,
            smallImagePath: user.user_small_image_path,
            bio: user.user_bio,
            cityId: user.user_city_id_fk,
            countryId: user.user_country_id_fk,
            activeInterestKey: 0,
            languagesArray: languagesArray,
            awardsArray: user.user_awards,
            interestsArray: interestsArray,
            projectInterestsArray: projectInterestsArray,
            userExperience: userExperience,
            company: user.company_name,
            position: user.position_name,
            twitter: user.user_detail_twitter_url,
            youtube: user.user_detail_youtube_url,
            linkedin: user.user_detail_linkedin_url,
            typeOfAcquisition: user.user_detail_type_acquisition,
            legalName: user.user_detail_legal_name,
            website: user.user_detail_website,
            yourExperiencePageTitle: yourExperiencePageTitle,
            userInterestsPageTitle: userInterestsPageTitle,
            investmentCountries: investmentCountries,
            investmentTickets: investmentTickets,
            investmentGenres: investmentGenres,
            investmentTypes: investmentTypes,
            investmentProjects: investmentProjects,
        });
    };
    useEffect(function () {
        var container = window.document.getElementsByClassName("edituserpage-container")[0];
        var scrollContainer = container.parentElement.parentElement;
        setScrollParent(scrollContainer);
        scrollContainer.scrollTo(0, 0);
    }, []);
    useEffect(function () {
        var loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Promise.all([
                    loadProjectTypes(),
                    loadProjectSubtypes(),
                    loadProjectGenres(),
                    loadProjectStages(),
                    loadUserTitles(),
                    loadLanguages(),
                    loadCountries(),
                    loadCities(),
                ]).then(function (data) {
                    setIsReady(true);
                });
                return [2 /*return*/];
            });
        }); };
        loadData();
    }, [visible]);
    useEffect(function () {
        if (isReady)
            resetUserData();
    }, [isReady, JSON.stringify(userTitles), JSON.stringify(projectTypes)]);
    return (_jsxs("div", __assign({ className: "edituserpage-container" }, { children: [_jsx("div", __assign({ className: "edituserpage-navbar" }, { children: _jsx("nav", __assign({ className: "edituserpage-nav" }, { children: _jsx("ul", { children: listLinksData.map(function (linkData, key) {
                            var isClassActive = linkData.path !== firstTabPathname &&
                                (!user.user_country_id_fk ||
                                    !userData.countryId)
                                ? "edituserpage-disabled"
                                : "";
                            return (_jsx("li", __assign({ className: "edituserpage-li edituserpage-active ".concat(isClassActive) }, { children: _jsx(NavLink, __assign({ to: userData.countryId &&
                                        user.user_country_id_fk
                                        ? linkData.path
                                        : "#", 
                                    // to={linkData.path}
                                    activeClassName: "edituserpage-active", exact: true, isActive: function (match, location) {
                                        return isTabActive(match, location, linkData.path);
                                    } }, { children: _jsx("div", __assign({ className: "edituserpage-title" }, { children: linkData.title })) })) }), key));
                        }) }) })) })), _jsx("div", __assign({ className: "edituserpage-content" }, { children: _jsx(Switch, { children: listRoutesData.map(function (routeData, key) { return (_jsx(KNavRoute, { path: routeData.path, component: routeData.component, componentProps: routeData.props, exact: routeData.exact }, key)); }) }) }))] })));
};
var mapStateToProps = function (state) { return ({
    userTitles: getUserTitles(state),
    cities: getCities(state),
    countries: getCountries(state),
    languages: getLanguages(state),
    userId: getUserId(state),
    userTitle: getUserTitle(state),
    projectTypes: getProjectTypes(state),
    projectSubtypes: getProjectSubtypes(state),
    projectGenres: getProjectGenres(state),
    projectStages: getProjectStages(state),
    user: getLoadedUser(state),
    // user: getUser(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onUpdateUser: function (user) { return dispatch(thunkUpdateUserData(user)); },
    onLoadUser: function (userId) { return dispatch(thunkLoadUserByUserId(userId)); },
    loadUserTitles: function () { return dispatch(thunkLoadUserTitles()); },
    loadProjectTypes: function () { return dispatch(thunkLoadProjectTypes()); },
    loadProjectGenres: function () { return dispatch(thunkLoadProjectGenres()); },
    loadProjectSubtypes: function () { return dispatch(thunkLoadProjectSubtypes()); },
    loadProjectStages: function () { return dispatch(thunkLoadProjectStages()); },
    loadLanguages: function () { return dispatch(thunkLoadLanguages()); },
    loadCountries: function () { return dispatch(thunkLoadCountries()); },
    loadCities: function () { return dispatch(thunkLoadCities()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage);
