var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";
var cookies = new Cookies();
export var getUser = function (state) {
    return state.user.data;
};
export var getUserId = function (state) {
    return state.user.data.user_id;
};
export var getKachatId = function (state) {
    return state.user.data.user_kachat_id;
};
export var getUserTitleId = function (state) {
    return (state.user.data || {}).user_user_title_id_fk;
};
export var getUserTitle = function (state) {
    return (state.user.data || {}).user_title_name;
};
export var getAuthId = function (state) {
    return getJwtData(state).authId;
    return state.user.data.user_auth_id;
};
export var getUserFlaggedNum = function (state) {
    return getJwtData(state).flaggedNum;
};
export var getToken = function (state) {
    return cookies.get("token");
};
export var getTokenExpiry = function (state) {
    return cookies.get("token-expiry");
};
export var getUserError = function (state) {
    return state.user.errorMessage;
};
export var getUserEmail = function (state) {
    return state.user.data.user_email;
};
export var getUserAuthenticated = function (state) {
    return state.user.isAuthenticated;
};
export var getJwtData = function (state) {
    if (!getToken(state))
        return {};
    var jwtData = jwt_decode(getToken(state));
    return __assign(__assign({}, jwtData.data), { iat: jwtData.iat, exp: jwtData.exp });
};
export var getFlaggedNum = function (state) {
    var jwtData = getJwtData(state);
    return jwtData.flaggedNum;
};
export var getUserSignedUp = function (state) {
    return state.user.userSignedUp;
};
export var getForgotPassSuccess = function (state) {
    return state.user.forgotPassSuccess;
};
export var getResetPassSuccess = function (state) {
    return state.user.resetPassSuccess;
};
export var getResetExpiry = function (state) {
    return state.user.resetExpiry;
};
export var getUserProjectBoosts = function (state) {
    return state.user.projectBoosts;
};
export var getSubscriptionPrices = function (state) {
    return state.user.prices;
};
export var getUserCurrentPlan = function (state) {
    var _a;
    return (_a = state.user.currentPlan) === null || _a === void 0 ? void 0 : _a.toLowerCase();
};
export var getUserCurrentSubscription = function (state) {
    return state.user.currentSubscription;
};
export var getUserSubscriptions = function (state) {
    return state.user.subscriptions;
};
export var getUserPaymentMethods = function (state) {
    return state.user.paymentMethods;
};
export var getUserDefaultPaymentMethod = function (state) {
    return state.user.defaultPaymentMethod;
};
export var getChatServiceMessage = function (state) {
    return state.user.chatServiceMessage;
};
