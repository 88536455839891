var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import { getProjectSymbolSrc } from "../../tools/projectTools";
import { getValueInCurrency, getImageUrl } from "../../tools/generalTools";
import { canViewNetwork } from "../../validators/profileValidator";
import { getUserProfilePic } from "../../tools/userTools";
import KImage from "../../base-components/KImage/KImage";
import "./ProjectSummaryCard.css";
var ProjectSummaryCard = function (_a) {
    var user = _a.user, project = _a.project, coverImages = _a.coverImages, _b = _a.authUserId, authUserId = _b === void 0 ? -1 : _b;
    var history = useHistory();
    var _c = useRouteMatch(), url = _c.url, path = _c.path;
    var _d = useState(0), imageIndex = _d[0], setImageIndex = _d[1];
    var getBaseUrl = function () {
        return url.split("/", 3).join("/");
    };
    var getCoverImageTitle = function (index) {
        if (coverImages.length > 0) {
            return "" + coverImages[index].project_coverimage_title;
        }
        return "";
    };
    var onViewUser = function () {
        if (user.user_id == authUserId)
            history.push("/platform/profile");
        else {
            if (getBaseUrl() === "/platform/profile") {
                if (canViewNetwork())
                    history.push("/platform/network/user/".concat(user.user_id));
                else
                    history.push("/platform/market/user/".concat(user.user_id));
            }
            else
                history.push("".concat(getBaseUrl(), "/user/").concat(user.user_id));
        }
    };
    var getViewsCount = function () {
        return "20,112";
    };
    var getOpenForText = function () {
        if (project.project_open_for_sale |
            project.project_open_for_collaboration) {
            var text = "";
            if (project.project_open_for_sale) {
                text += " sale";
                if (project.project_open_for_collaboration)
                    text += " and collaboration";
            }
            else
                text += " collaboration";
            return (_jsxs(_Fragment, { children: [_jsx("b", { children: "open for" }), text] }));
        }
        return _jsx("b", { children: "project closed" });
    };
    var getValueWithCurrency = function (val) {
        return getValueInCurrency(val) || "N/A";
    };
    var getAcquisitionBudget = function () {
        return project.project_acquisition_budget;
    };
    var getFinancingBudget = function () {
        return project.project_financing_budget;
    };
    var getInvestmentBudget = function () {
        return project.project_investment_budget;
    };
    var getProductionBudget = function () {
        return project.project_production_budget;
    };
    var getCollectedAcquisition = function () {
        var _a;
        return (_a = project.project_presale_amounts) === null || _a === void 0 ? void 0 : _a.split(",").reduce(function (s, a) { return s + parseInt(a); }, 0);
    };
    var getCollectedInvestment = function () {
        if (!project.project_funding_amounts)
            return 0;
        var collected = 0;
        var amounts = project.project_funding_amounts.split(",");
        var isPrivateArray = project.project_funding_privates.split(",");
        for (var i = 0; i < amounts.length; i++) {
            if (parseInt(isPrivateArray[i]))
                collected += parseInt(amounts[i]);
        }
        return collected;
    };
    var getCollectedProduction = function () {
        var _a;
        return (_a = project.project_funding_amounts) === null || _a === void 0 ? void 0 : _a.split(",").reduce(function (s, a) { return s + parseInt(a); }, 0);
    };
    var displayCollectedAcquisition = function () {
        return getCollectedAcquisition() > 0;
    };
    var displayCollectedInvestment = function () {
        return getCollectedInvestment() > 0;
    };
    var displayCollectedProduction = function () {
        return getCollectedProduction() > 0;
    };
    var getCollectedPercentage = function (collected, total) {
        if (!total)
            return 100;
        return Math.min((100 * collected) / total, 100);
    };
    var hasInterestIn = function (title) {
        var interests = project.project_user_interest_names;
        if (!interests)
            return false;
        return interests.toLowerCase().indexOf(title.toLowerCase()) >= 0;
    };
    return (_jsxs("div", __assign({ className: "projectsummarycard-container" }, { children: [_jsxs("div", __assign({ className: "projectsummarycard-image-section" }, { children: [_jsxs("div", __assign({ className: "projectsummarycard-image-container" }, { children: [_jsx("div", { className: "projectsummarycard-image-action-container", style: {
                                    display: coverImages.length > 0 ? "block" : "none",
                                } }), _jsx("div", __assign({ className: "projectsummarycard-views-container projectsummarycard-views-imageside-container" }, { children: _jsx("div", __assign({ className: "projectsummarycard-openfor-text" }, { children: getOpenForText() })) })), _jsx("img", { className: "projectsummarycard-coverimage ".concat(coverImages.length == 0
                                    ? "projectsummarycard-visible"
                                    : ""), alt: "project", src: "/images/transparent.png" }), _jsx("img", { className: "projectsummarycard-coverimage projectsummarycard-visible", alt: "project", style: {
                                    height: "30%",
                                    width: "30%",
                                    objectFit: "contain",
                                    position: "absolute",
                                    marginTop: "40px",
                                }, src: getProjectSymbolSrc(project.project_type_name) }), coverImages.map(function (coverImage, key) { return (_jsx("div", __assign({ className: "projectsummarycard-coverimage ".concat(imageIndex == key
                                    ? "projectsummarycard-visible"
                                    : "") }, { children: _jsx(KImage, { smallImgSrc: getImageUrl(coverImage.project_coverimage_small_path), imgSrc: getImageUrl(coverImage.project_coverimage_path) }) }), key)); })] })), _jsxs("div", __assign({ className: "projectsummarycard-image-title-container" }, { children: [_jsx("div", __assign({ className: "projectsummarycard-image-title" }, { children: getCoverImageTitle(imageIndex) })), _jsx("div", { className: "projectsummarycard-imagedots" })] }))] })), _jsxs("div", __assign({ className: "projectsummarycard-content-section" }, { children: [_jsx("div", __assign({ className: "projectsummarycard-views-container" }, { children: _jsx("div", __assign({ className: "projectsummarycard-openfor-text" }, { children: getOpenForText() })) })), _jsxs("div", __assign({ className: "projectsummarycard-summary-container" }, { children: [_jsxs("div", __assign({ className: "projectsummarycard-user-summary" }, { children: [_jsxs("div", __assign({ className: "projectsummarycard-user-header" }, { children: [_jsx("div", __assign({ className: "projectsummarycard-user-image", onClick: function () {
                                                    if (canViewNetwork())
                                                        onViewUser();
                                                }, style: {
                                                    borderColor: "var(--app-".concat((user.user_title_name || "").toLowerCase(), "-color)"),
                                                } }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(user.user_small_image_path), imgSrc: getUserProfilePic(user.user_image_path), alt: "profile-pic" }) })), _jsxs("div", __assign({ className: "projectsummarycard-user-name", onClick: function () {
                                                    if (canViewNetwork())
                                                        onViewUser();
                                                } }, { children: [user.user_first_name, " ", user.user_last_name] }))] })), _jsx("div", __assign({ className: "projectsummarycard-user-bio" }, { children: user.user_bio || "No user bio provided.." }))] })), _jsx("div", { className: "projectsummarycard-review-summary" })] })), _jsxs("div", __assign({ className: "projectsummarycard-lookingfor-container" }, { children: [_jsx("div", __assign({ className: "projectsummarycard-lookingfor-text" }, { children: "Looking for" })), _jsxs("div", __assign({ className: "projectsummarycard-lookingfor-checkbox-container" }, { children: [_jsx(KCheckbox, { title: "Investor", color: "var(--app-investor-color)", fontSize: "var(--projectsummarycard-font-size-small)", disabled: true, defaultChecked: hasInterestIn("investor"), marginRight: "12px", round: true }), _jsx(KCheckbox, { title: "Producer", color: "var(--app-producer-color)", fontSize: "var(--projectsummarycard-font-size-small)", disabled: true, defaultChecked: hasInterestIn("producer"), marginRight: "12px", round: true }), _jsx(KCheckbox, { title: "Buyer", color: "var(--app-buyer-color)", fontSize: "var(--projectsummarycard-font-size-small)", disabled: true, defaultChecked: hasInterestIn("buyer"), marginRight: "12px", round: true })] }))] })), _jsxs("div", __assign({ className: "projectsummarycard-budget-container" }, { children: [_jsxs("div", __assign({ className: "projectsummarycard-budget-wrapper", style: {
                                    display: getProductionBudget() ? "flex" : "none",
                                } }, { children: [_jsx("div", __assign({ className: "projectsummarycard-budget-text" }, { children: "Production budget" })), _jsx("div", __assign({ className: "projectsummarycard-budget-price" }, { children: getValueWithCurrency(getProductionBudget()) })), displayCollectedProduction() ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "projectsummarycard-budget-collected-text" }, { children: ["collected so far", " ", getValueWithCurrency(getCollectedProduction())] })), _jsx("div", __assign({ className: "projectsummarycard-budget-collected" }, { children: _jsx("div", { className: "projectsummarycard-budget-collected-fill", style: {
                                                        width: "".concat(getCollectedPercentage(getCollectedProduction(), getProductionBudget()), "%"),
                                                    } }) }))] })) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "projectsummarycard-budget-wrapper", style: {
                                    display: hasInterestIn("investor")
                                        ? getInvestmentBudget()
                                            ? "flex"
                                            : "none"
                                        : "none",
                                } }, { children: [_jsx("div", __assign({ className: "projectsummarycard-budget-text" }, { children: "Investment budget" })), _jsx("div", __assign({ className: "projectsummarycard-budget-price" }, { children: getValueWithCurrency(getInvestmentBudget()) })), displayCollectedInvestment() ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "projectsummarycard-budget-collected-text" }, { children: ["collected so far", " ", getValueWithCurrency(getCollectedInvestment())] })), _jsx("div", __assign({ className: "projectsummarycard-budget-collected" }, { children: _jsx("div", { className: "projectsummarycard-budget-collected-fill", style: {
                                                        width: "".concat(Math.min((100 *
                                                            getCollectedInvestment()) /
                                                            getInvestmentBudget(), 100), "%"),
                                                    } }) }))] })) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "projectsummarycard-budget-wrapper", style: {
                                    display: hasInterestIn("buyer")
                                        ? getAcquisitionBudget()
                                            ? "flex"
                                            : "none"
                                        : "none",
                                } }, { children: [_jsx("div", __assign({ className: "projectsummarycard-budget-text" }, { children: "Acquisition budget" })), _jsx("div", __assign({ className: "projectsummarycard-budget-price" }, { children: getValueWithCurrency(getAcquisitionBudget()) })), displayCollectedAcquisition() ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "projectsummarycard-budget-collected-text" }, { children: ["collected so far", " ", getValueWithCurrency(getCollectedAcquisition())] })), _jsx("div", __assign({ className: "projectsummarycard-budget-collected" }, { children: _jsx("div", { className: "projectsummarycard-budget-collected-fill", style: {
                                                        width: "".concat(Math.min((100 *
                                                            getCollectedAcquisition()) /
                                                            getAcquisitionBudget(), 100), "%"),
                                                    } }) }))] })) : (_jsx(_Fragment, {}))] }))] }))] }))] })));
};
export default ProjectSummaryCard;
