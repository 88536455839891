var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import "./KCircularProgress.css";
var KCircularProgress = function (_a) {
    var _b = _a.progress, progress = _b === void 0 ? 0 : _b, status = _a.status;
    var _c = useState(status || "Uploading"), progressStatus = _c[0], setProgressStatus = _c[1];
    var _d = useState(-180), rot2 = _d[0], setRot2 = _d[1];
    var _e = useState(-180), rot3 = _e[0], setRot3 = _e[1];
    var getDegrees = function (perc) {
        return (perc * 360) / 100;
    };
    useEffect(function () {
        var val = parseInt(progress);
        setRot2(Math.max(-180 + getDegrees(val - 50), -180));
        setRot3(Math.min(-180 + getDegrees(val), 0));
        setProgressStatus(val === 100 ? "Finalizing" : status || "Uploading");
    }, [progress]);
    useEffect(function () {
        setProgressStatus(status);
    }, [status]);
    return (_jsx(_Fragment, { children: progress > 0 ? (_jsxs("div", __assign({ className: "kcircularprogress-container" }, { children: [_jsxs("div", __assign({ className: "kcircularprogress-wrapper-0" }, { children: [_jsx("div", { className: "kcircularprogress-wrapper-1" }), _jsx("div", { className: "kcircularprogress-wrapper-2", style: {
                                transform: "rotate(".concat(rot2, "deg)"),
                            } }), _jsx("div", { className: "kcircularprogress-wrapper-3", style: {
                                transform: "rotate(".concat(rot3, "deg)"),
                                display: progress <= 50 ? "" : "none",
                            } })] })), _jsx("div", __assign({ className: "kcircularprogress-wrapper" }, { children: _jsxs("div", __assign({ className: "kcircularprogress-info" }, { children: [_jsx("div", __assign({ className: "kcircularprogress-title" }, { children: progressStatus })), _jsxs("div", __assign({ className: "kcircularprogress-subtitle" }, { children: [parseInt(progress), "%"] }))] })) }))] }))) : (_jsx(_Fragment, {})) }));
};
export default KCircularProgress;
