var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRef, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import KImage from "../../base-components/KImage/KImage";
import KTextField from "../../base-components/KTextField/KTextField";
import useInfiniteLoading, { infiniteKeys, infiniteLoadingReload, } from "../../hooks/useInfiniteLoading";
import { getUsers } from "../../redux/selectors/userSelectors";
import { thunkLoadUsers } from "../../redux/thunks/userThunks";
import { getFullName, getUserProfilePic } from "../../tools/userTools";
import "./UserAutocomplete.css";
var AutocompleteEntry = function (_a) {
    var user = _a.user, _b = _a.actionVisible, actionVisible = _b === void 0 ? false : _b, _c = _a.actionText, actionText = _c === void 0 ? "select" : _c, _d = _a.onClick, onClick = _d === void 0 ? function (f) { return f; } : _d;
    return (_jsxs("div", __assign({ className: "userautocomplete-entry", onMouseDown: onClick }, { children: [(user === null || user === void 0 ? void 0 : user.isCustom) ? (_jsx("div", __assign({ className: "userautocomplete-tag", style: { marginRight: "0px" } }, { children: "custom" }))) : (_jsx("div", __assign({ className: "userautocomplete-entry-image", style: { display: (user === null || user === void 0 ? void 0 : user.isCustom) ? "none" : "block" } }, { children: _jsx(KImage, { imgSrc: getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_small_image_path), smallImgSrc: getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_small_image_path), rounded: true }) }))), _jsxs("div", __assign({ className: "userautocomplete-info-box" }, { children: [_jsx("div", __assign({ className: "userautocomplete-entry-text" }, { children: (user === null || user === void 0 ? void 0 : user.isCustom) ? user === null || user === void 0 ? void 0 : user.name : getFullName(user) })), _jsx("div", __assign({ className: "userautocomplete-info-title" }, { children: user === null || user === void 0 ? void 0 : user.user_title_name }))] })), _jsx("div", __assign({ className: "userautocomplete-action-tag ".concat(actionVisible ? "visible" : "") }, { children: actionText }))] })));
};
var UserAutocomplete = function (_a) {
    var label = _a.label, users = _a.users, placeholder = _a.placeholder, backgroundColor = _a.backgroundColor, onLoadUsers = _a.onLoadUsers, _b = _a.minChars, minChars = _b === void 0 ? 3 : _b, selectedValue = _a.selectedValue, _c = _a.onSelectedValueChange, onSelectedValueChange = _c === void 0 ? function (f) { return f; } : _c;
    var limit = 20;
    var timeoutRef = useRef();
    var textInputRef = createRef();
    var searchTextRef = useState(value);
    var _d = useState(""), value = _d[0], _setValue = _d[1];
    var _e = useState(false), isFocused = _e[0], setIsFocused = _e[1];
    var _f = useState(), selectedEntry = _f[0], _setSelectedEntry = _f[1];
    var _g = useState(false), isReady = _g[0], setIsReady = _g[1];
    var setValue = function (data) {
        var _a;
        _setValue(data);
        searchTextRef.current = data;
        if (((_a = searchTextRef.current) === null || _a === void 0 ? void 0 : _a.length) < minChars)
            setIsReady(false);
        if (timeoutRef.current)
            clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(function () { return reloadData(); }, 500);
    };
    var setSelectedEntry = function (data) {
        _setSelectedEntry(data);
        onSelectedValueChange(data);
    };
    var reloadData = function () {
        var _a;
        if (((_a = searchTextRef.current) === null || _a === void 0 ? void 0 : _a.length) < minChars)
            return;
        onFirstLoadUsers(function () { return setIsReady(true); });
    };
    var focusText = function () {
        textInputRef.current.focus();
        setSelectedEntry();
    };
    useEffect(function () {
        _setSelectedEntry(selectedValue);
    }, [selectedValue]);
    // infinite loading section
    var userScrollRef = useRef(null);
    var userScrollParentRef = useRef(null);
    infiniteLoadingReload(infiniteKeys.NETWORK);
    infiniteLoadingReload(infiniteKeys.CONTRACT_USER);
    var onLoadMoreUsers = function (count, offset) {
        onLoadUsers(searchTextRef.current, count, offset, false);
    };
    var onFirstLoadUsers = function (callback) {
        onLoadUsers(searchTextRef.current, limit, 0, true, callback);
    };
    useInfiniteLoading(infiniteKeys.CONTRACT_USER, userScrollRef, users.length || 0, onLoadMoreUsers, limit, onFirstLoadUsers, userScrollParentRef, false);
    return (_jsxs("div", __assign({ className: "userautocomplete-container" }, { children: [_jsx("div", __assign({ className: "userautocomplete-selected-entry", style: {
                    display: selectedEntry ? "" : "none",
                    marginTop: "12px",
                    marginBottom: "7px",
                } }, { children: _jsx(AutocompleteEntry, { user: selectedEntry, actionText: "edit", actionVisible: true, onClick: function () {
                        setTimeout(function () { return focusText(); }, 1);
                    } }) })), _jsx("div", __assign({ style: {
                    /** NASTY-HACK: Couldn't focus input on click
                     * of the selected entry. This hack made it work
                     * but should be revisited and heavily tested!
                     */
                    position: selectedEntry ? "absolute" : "",
                    top: selectedEntry ? -100000 : "",
                } }, { children: _jsx(KTextField, { inputRef: textInputRef, label: label, value: value, placeholder: placeholder, onChange: function (e) { return setValue(e.target.value); }, backgroundColor: backgroundColor || "var(--app-primary-background-color)", onFocus: function () { return setIsFocused(true); }, onBlurCapture: function () { return setIsFocused(false); }, width: "100%" }) })), _jsxs("div", __assign({ className: "userautocomplete-selection-box below", style: {
                    display: isReady && isFocused && value.length >= minChars
                        ? ""
                        : "none",
                    backgroundColor: backgroundColor ||
                        "var(--app-primary-background-color)",
                } }, { children: [_jsx("div", __assign({ className: "userautocomplete-list" }, { children: _jsx("div", __assign({ className: "userautocomplete-scrollable", ref: userScrollParentRef }, { children: isReady && users.length ? (_jsx("div", __assign({ ref: userScrollRef }, { children: users.map(function (user, key) { return (_jsx(AutocompleteEntry, { user: user, actionText: "select", onClick: function () {
                                        setSelectedEntry(user);
                                        setValue("");
                                    } }, key)); }) }))) : (_jsx("div", __assign({ className: "userautocomplete-empty" }, { children: "No users found" }))) })) })), _jsxs("div", __assign({ className: "userautocomplete-entry userautocomplete-custom", onMouseDown: function () {
                            setValue("");
                            setSelectedEntry({
                                isCustom: true,
                                name: value,
                            });
                        } }, { children: [_jsx("div", __assign({ className: "userautocomplete-tag" }, { children: "custom" })), _jsx("div", __assign({ className: "userautocomplete-entry-text" }, { children: value })), _jsx("div", __assign({ className: "userautocomplete-action-tag" }, { children: "select" }))] }))] }))] })));
};
var mapStateToProps = function (state) { return ({
    users: getUsers(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadUsers: function (searchText, limit, offset, reload, callback) {
        return dispatch(thunkLoadUsers(searchText, limit, offset, reload, {
            userTitleIds: [2, 3],
        }, undefined, callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UserAutocomplete);
