var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IoMdEye as IconView } from "react-icons/io";
import { MdTextsms as IconCollaborate } from "react-icons/md";
import KButton from "../../base-components/KButton/KButton";
import { getProjectDate, getProjectAcquisitionBudget, getProjectSymbolSrc, } from "../../tools/projectTools";
import KImage from "../../base-components/KImage/KImage";
import { getImageUrl } from "../../tools/generalTools";
import "./BoostedCard.css";
var BoostedCard = function (_a) {
    var project = _a.project, href = _a.href, _b = _a.status, status = _b === void 0 ? "open for collaboration" : _b, onViewClick = _a.onViewClick, onCollaborate = _a.onCollaborate, _c = _a.canCollaborate, canCollaborate = _c === void 0 ? false : _c;
    var history = useHistory();
    var url = useRouteMatch().url;
    var getBaseUrl = function () { return url.split("/", 3).join("/"); };
    var getUserTitle = function () {
        if (project)
            return (project.user_title_name || "unknown").toLowerCase();
        return "unknown";
    };
    var getDefaultCover = function (project) {
        return getProjectSymbolSrc(project.project_type_name);
    };
    var getProjectImage = function () {
        if (project.project_coverimage_paths != null) {
            return getImageUrl(project.project_coverimage_paths.split(",", 1)[0]);
        }
        return "/images/transparent.png";
    };
    var getProjectSmallImage = function () {
        if (project.project_coverimage_small_paths != null) {
            return getImageUrl(project.project_coverimage_small_paths.split(",", 1)[0]);
        }
        return "/images/transparent.png";
    };
    var styleContainer = {
        "--boostedcard-primary-color": "var(--app-".concat(getUserTitle(), "-color)"),
    };
    var isClosed = !(project.project_open_for_sale | project.project_open_for_collaboration);
    status = isClosed
        ? ""
        : project.project_open_for_sale & project.project_open_for_collaboration
            ? "open for sale and collaboration"
            : "open for ".concat(project.project_open_for_sale ? "sale" : "collaboration");
    return (_jsxs("div", __assign({ className: "boostedcard-container", style: styleContainer }, { children: [_jsxs("div", __assign({ className: "boostedcard-image-container", style: styleContainer }, { children: [_jsx("img", { className: "boostedcard-image-base", src: getDefaultCover(project), alt: "project-type", onClick: function () { return onViewClick(project); } }), _jsx(KImage, { smallImgSrc: getProjectSmallImage(project), imgSrc: getProjectImage(project), alt: "profile-pic" })] })), _jsx("div", __assign({ className: "boostedcard-boosted-stamp" }, { children: "boosted" })), _jsx("div", __assign({ className: "boostedcard-main-section-container" }, { children: _jsxs("div", __assign({ className: "boostedcard-main-section" }, { children: [_jsxs("div", __assign({ className: "boostedcard-top-section" }, { children: [_jsx("div", __assign({ className: "boostedcard-title-section", onClick: function () { return onViewClick(project); } }, { children: _jsx("p", __assign({ className: "boostedcard-title" }, { children: project.project_title || "no title provided" })) })), _jsx("div", { className: "boostedcard-evaluation-section" })] })), _jsx("div", __assign({ className: "boostedcard-content-section" }, { children: _jsx("p", __assign({ className: "boostedcard-text" }, { children: project.project_synopsis ||
                                    "No project description provided.." })) })), _jsxs("div", __assign({ className: "boostedcard-action-section" }, { children: [_jsx("div", __assign({ className: "boostedcard-action-button", style: {
                                        display: isClosed || !canCollaborate ? "none" : "",
                                    } }, { children: _jsx(KButton, { title: "", icon: IconCollaborate, height: "17px", width: "17px", variant: "custom", backgroundColor: "var(--app-primary-color)", borderRadius: "50%", onClick: function () { return onCollaborate(project); } }) })), _jsx("div", __assign({ className: "boostedcard-action-button" }, { children: _jsx(KButton, { title: "", icon: IconView, height: "17px", width: "17px", href: href, variant: "custom", backgroundColor: "var(--app-primary-color)", borderRadius: "50%", onClick: function () { return onViewClick(project); } }) }))] })), _jsx("div", __assign({ className: "boostedcard-footer-section-1" }, { children: _jsx("p", __assign({ className: "boostedcard-footer-text-1" }, { children: "no collaborations" })) })), _jsxs("div", __assign({ className: "boostedcard-footer-section-2", style: { backgroundColor: isClosed ? "#ddd" : "" } }, { children: [(project.language_name
                                    ? [project.language_code]
                                    : []).map(function (lang, key) { return (_jsx("div", __assign({ className: "boostedcard-footer-tab-2" }, { children: _jsx("p", { children: lang }) }), key)); }), _jsx("div", __assign({ className: "boostedcard-footer-tab-2", style: {
                                        display: getProjectAcquisitionBudget(project)
                                            ? "flex"
                                            : "none",
                                    } }, { children: _jsx("p", { children: getProjectAcquisitionBudget(project) }) })), _jsx("div", __assign({ className: "boostedcard-footer-tab-2" }, { children: _jsx("p", { children: getProjectDate(project) }) })), _jsx("div", __assign({ className: "boostedcard-footer-tab-3 boostedcard-float-right" }, { children: _jsx("p", { children: status }) }))] }))] })) }))] })));
};
BoostedCard.propTypes = {
    onViewClick: PropTypes.func.isRequired,
    onMessageClick: PropTypes.func.isRequired,
};
BoostedCard.defaultProps = {
    onViewClick: function (project) { return alert("View project: ".concat(JSON.stringify(project))); },
    onMessageClick: function (project) { return alert("Feature coming soon"); },
};
export default BoostedCard;
