var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { IoMdTrash as IconDelete } from "react-icons/io";
import { RiInboxArchiveFill as IconClose } from "react-icons/ri";
import { RiInboxUnarchiveFill as IconOpen } from "react-icons/ri";
import useOutsideAlert from "../../hooks/useOutsideAlert";
import "./EditProjectNav.css";
var EditProjectNav = function (_a) {
    var _b = _a.listLinksData, listLinksData = _b === void 0 ? [] : _b, _c = _a.variant, variant = _c === void 0 ? "light" : _c, component = _a.component, onDelete = _a.onDelete, onClose = _a.onClose, onOpen = _a.onOpen, onChangePage = _a.onChangePage, _d = _a.isCreated, isCreated = _d === void 0 ? false : _d, _e = _a.isClosed, isClosed = _e === void 0 ? false : _e;
    var navRef = useRef(null);
    var path = useRouteMatch().path;
    var navBarClosed = "editprojectnav-closed";
    var _f = useState(navBarClosed), navBarState = _f[0], setNavBarState = _f[1];
    var isTabActive = function (match, location, toPath) {
        return (match !== null) |
            ((toPath !== path) &
                (toPath === location.pathname.slice(0, toPath.length)));
    };
    useOutsideAlert(navRef, function () { return setNavBarState(navBarClosed); });
    return (_jsxs("div", __assign({ className: "editprojectnav-container" }, { children: [_jsx("div", __assign({ className: "editprojectnav-bar" }, { children: _jsxs("nav", __assign({ className: "editprojectnav-".concat(variant), ref: navRef }, { children: [_jsx("ul", { children: listLinksData.map(function (linkData, key) { return (_jsx("li", __assign({ className: "editprojectnav-li" }, { children: _jsx(NavLink, __assign({ className: navBarState, to: {
                                        pathname: linkData.path,
                                        state: linkData.state,
                                    }, onClick: function () {
                                        onChangePage();
                                        setNavBarState(navBarClosed);
                                    }, activeClassName: "editprojectnav-active", isActive: function (match, location) {
                                        return isTabActive(match, location, linkData.path);
                                    }, exact: true }, { children: _jsxs("div", __assign({ className: "editprojectnav-title-container" }, { children: [_jsx("div", __assign({ className: "editprojectnav-prefix" }, { children: linkData.prefix })), _jsx("div", __assign({ className: "editprojectnav-title" }, { children: linkData.title }))] })) })) }), key)); }) }), _jsxs("div", __assign({ className: "editprojectnav-delete", onClick: onDelete }, { children: [_jsx(IconDelete, {}), _jsx("div", __assign({ className: "editprojectnav-delete-title" }, { children: "delete project" }))] })), isCreated ? (_jsx(_Fragment, { children: isClosed ? (_jsxs("div", __assign({ className: "editprojectnav-close-open", onClick: onOpen }, { children: [_jsx(IconOpen, {}), _jsx("div", __assign({ className: "editprojectnav-close-open-title" }, { children: "mark as opened" }))] }))) : (_jsxs("div", __assign({ className: "editprojectnav-close-open", onClick: onClose }, { children: [_jsx(IconClose, {}), _jsx("div", __assign({ className: "editprojectnav-close-open-title" }, { children: "mark as closed" }))] }))) })) : (_jsx(_Fragment, {}))] })) })), _jsx("div", __assign({ className: "editprojectnav-content" }, { children: component }))] })));
};
export default EditProjectNav;
