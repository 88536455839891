var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useHistory } from "react-router-dom";
import ArrowForward from "@material-ui/icons/ArrowForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PhoneIcon from "@material-ui/icons/Phone";
import VideocamIcon from "@material-ui/icons/Videocam";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import "../collaborate/collaborate.css";
function ChatWindow() {
    return (_jsxs("div", __assign({ className: "collaborate__chat-window box-shadow " }, { children: [_jsx("img", { className: "collaborate__chat-top-icon", src: "/images/Scriptofilm_icon_colored_message option 2.png" }), _jsxs(Grid, __assign({ container: true, spacing: 0, style: {
                    marginBottom: "0px",
                } }, { children: [_jsxs(Grid, __assign({ item: true, md: 8, sm: 12, className: "collaborate__chat-left" }, { children: [_jsxs(Box, __assign({ display: "flex", style: {
                                    padding: "10px 16px",
                                    paddingRight: "8px",
                                } }, { children: [_jsx("img", { className: "collaborate__chat-profile", src: "/images/Juan_Perdomo.jpg", style: {
                                            borderRadius: "50%",
                                            border: "2px solid var(--app-investor-color)",
                                            marginTop: "5px",
                                        } }), _jsxs(Box, __assign({ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", bgcolor: "#F5F5F5", borderRadius: "14px", style: {
                                            borderTopLeftRadius: "0",
                                            padding: "6px 18px",
                                            margin: "6px 10px",
                                            marginRight: "0px",
                                            marginBottom: "-10px",
                                        } }, { children: [_jsx("h5", __assign({ className: "collaborate__chat-text" }, { children: "We are all in for investing in your project" })), _jsx("h6", __assign({ className: "collaborate__chat-time" }, { children: "20:00 PM" })), _jsx(Divider, {})] }))] })), _jsxs(Box, __assign({ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", bgcolor: "#FDF7EB", m: 1, borderRadius: "14px", style: {
                                    borderTopRightRadius: "0",
                                    padding: "6px 18px",
                                } }, { children: [_jsx("h5", __assign({ className: "collaborate__chat-text" }, { children: "Thank you. I'll be waiting for your contract" })), _jsx("h6", __assign({ className: "collaborate__chat-time" }, { children: "20:00 PM" })), _jsx(Divider, {})] }))] })), _jsxs(Grid, __assign({ item: true, md: 4, sm: 12, style: {
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "auto",
                            position: "relative",
                        } }, { children: [_jsxs("div", __assign({ style: {
                                    paddingTop: "28px",
                                    height: "90px",
                                    borderBottom: "0.5px solid #ccc5",
                                } }, { children: [_jsx("img", { width: "30px", src: "/images/Scriptofilm_icon_black_profile.png" }), _jsx("img", { width: "14px", className: "collaborate-check-icon", src: "/images/green-check.png" }), _jsx("h6", __assign({ className: "collaborate__chat-actions" }, { children: "NDA" }))] })), _jsxs("div", __assign({ style: {
                                    paddingTop: "28px",
                                    height: "90px",
                                } }, { children: [_jsx("img", { width: "30px", src: "/images/Scriptofilm_icon_black_money.png" }), _jsx("h6", __assign({ className: "collaborate__chat-actions" }, { children: "CONTRACT" }))] }))] }))] })), _jsxs(Box, __assign({ position: "absolute", display: "flex", width: "100%", height: "60px", bottom: "0", bgcolor: "#F4F4F4", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx("h5", __assign({ className: "collaborate__type-here" }, { children: _jsx("i", { children: "type here" }) })), _jsxs(Box, __assign({ className: "collaborate__play-icons" }, { children: [_jsx(PlayArrowIcon, {}), _jsx(PhoneIcon, {}), _jsx(VideocamIcon, {})] }))] }))] })));
}
export function Collaborate() {
    var history = useHistory();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "collaborate__section section" }, { children: [_jsxs(Box, __assign({ maxWidth: "330px", margin: "auto" }, { children: [_jsxs("h1", { children: [_jsx("b", { children: "Collaborate" }), " or sell your screenplays and films"] }), _jsx("h4", __assign({ className: "collaborate__subtitle", style: {} }, { children: "find producers, investors and buyers" }))] })), _jsxs("div", __assign({ style: {
                        width: "100wh",
                        maxWidth: "1300px",
                        position: "relative",
                        display: "flex",
                        padding: "0px",
                        margin: "auto",
                        marginBottom: "100px",
                        marginTop: "40px",
                        textAlign: "left",
                    } }, { children: [_jsxs("div", __assign({ className: "collaborate__images-div", style: {
                                width: "50%",
                                height: "100%",
                                marginTop: "30px",
                                position: "relative",
                            } }, { children: [_jsx("img", { className: "collaborate__left-image box-radius", src: "/images/the_novice.jpg" }), _jsxs(Box, __assign({ display: "flex", alignItems: "flex-start", justifyContent: "center", flexDirection: "column", className: "collaborate__bottom-div box-radius ", padding: "12px 24px" }, { children: [_jsx("h5", { children: "Expected Production Budget" }), _jsx("h3", __assign({ className: "color-green" }, { children: "$4M" })), _jsx("h6", { children: "collected so far 20% (0.8M)" }), _jsx(Divider, {})] })), _jsxs(Box, __assign({ className: "collaborate__right-div box-radius", alignItems: "center", display: "table", justifyContent: "center", flexDirection: "column", padding: "12px 24px" }, { children: [_jsx("div", __assign({ className: "collaborate__leftdiv", style: { marginBottom: "10px" } }, { children: _jsx("h5", { children: "Investment needed" }) })), _jsx("div", __assign({ className: "collaborate__leftdiv" }, { children: _jsx("h3", { children: "$3.2M" }) })), _jsx("img", { className: "collaborate__top-icon", src: "/images/Scriptofilm_icon_colored_message option 2.png" })] }))] })), _jsx("div", __assign({ className: "collaborate__chat-div", style: {
                                width: "50%",
                                height: "100%",
                                position: "relative",
                            } }, { children: _jsx("div", __assign({ style: {
                                    marginLeft: "30%",
                                    maxWidth: "50%",
                                } }, { children: _jsx(ChatWindow, {}) })) }))] })), _jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "center", className: "collaborate__action-section cursor", marginTop: "64px" }, { children: [_jsx(Icon, { component: ArrowForward, color: "inherit", fontSize: "large" }), _jsxs("a", __assign({ onClick: function () { return history.push("/signin"); } }, { children: [_jsx("span", __assign({ className: "collaborate__action-title" }, { children: "Upload" })), _jsx("span", { children: "your project" })] }))] }))] })) }));
}
export default Collaborate;
