var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from "react-router-dom";
import ScriptCard from "../ScriptCard/ScriptCard";
import KImage from "../../base-components/KImage/KImage";
import { getProjectSymbolSrc } from "../../tools/projectTools";
import { getTransparentImagePath } from "../../tools/generalTools";
import { collaborate } from "../../pages/ProjectPage/ProjectPage";
import "./ScriptsList.css";
var ScriptsList = function (_a) {
    var basePath = _a.basePath, emptyTitle = _a.emptyTitle, loadedUser = _a.loadedUser, authUserId = _a.authUserId, _b = _a.scripts, scripts = _b === void 0 ? [] : _b;
    var history = useHistory();
    return (_jsx("div", __assign({ className: "scriptslist-container" }, { children: _jsxs("div", __assign({ className: "scriptslist-section" }, { children: [_jsx("div", __assign({ className: "scriptslist-cards-container" }, { children: (scripts.length % 2 === 0
                        ? scripts
                        : scripts.concat({})).map(function (script, key) { return (_jsx("div", __assign({ className: "scriptslist-card-container ".concat(key === scripts.length
                            ? "scriptslist-card-empty"
                            : "") }, { children: key !== scripts.length ? (_jsx(ScriptCard, { script: script, imgSrc: "/images/script-symbol.png", href: "".concat(basePath, "/project/").concat(script.project_id), onViewClick: function () {
                                history.push("".concat(basePath, "/project/").concat(script.project_id));
                            }, onCollaborate: function () { return collaborate(script); }, isEditable: authUserId == script.project_user_id_fk })) : (_jsx(_Fragment, {})) }), key)); }) })), _jsxs("div", __assign({ className: "scriptslist-empty-title", style: { display: scripts.length > 0 ? " none" : "flex" } }, { children: [_jsx("div", __assign({ className: "scriptslist-empty-image" }, { children: _jsx(KImage, { smallImgSrc: getTransparentImagePath(), imgSrc: getProjectSymbolSrc("script") }) })), emptyTitle] }))] })) })));
};
export default ScriptsList;
