export var CREATE_DEAL_IN_PROGRESS = "CREATE_DEAL_IN_PROGRESS";
export var createDealInProgress = function () { return ({
    type: CREATE_DEAL_IN_PROGRESS,
}); };
export var CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS";
export var createDealSuccess = function () { return ({
    type: CREATE_DEAL_SUCCESS,
}); };
export var CREATE_DEAL_FAILURE = "CREATE_DEAL_FAILURE";
export var createDealFailure = function (message) { return ({
    type: CREATE_DEAL_FAILURE,
    payload: { message: message },
}); };
export var LOAD_DEALS_IN_PROGRESS = "LOAD_DEALS_IN_PROGRESS";
export var loadDealsInProgress = function () { return ({
    type: LOAD_DEALS_IN_PROGRESS,
}); };
export var LOAD_DEALS_SUCCESS = "LOAD_DEALS_SUCCESS";
export var loadDealsSuccess = function (userDeals) { return ({
    type: LOAD_DEALS_SUCCESS,
    payload: { userDeals: userDeals },
}); };
export var LOAD_DEALS_FAILURE = "LOAD_DEALS_FAILURE";
export var loadDealsFailure = function (message) { return ({
    type: LOAD_DEALS_FAILURE,
    payload: { message: message },
}); };
export var STOP_DEALS_LOADING = "STOP_DEALS_LOADING";
export var stopDealsLoading = function () { return ({
    type: STOP_DEALS_LOADING,
}); };
export var UPDATE_NDA_IN_PROGRESS = "UPDATE_NDA_IN_PROGRESS";
export var updateNdaInProgress = function () { return ({
    type: UPDATE_NDA_IN_PROGRESS,
}); };
export var UPDATE_NDA_SUCCESS = "UPDATE_NDA_SUCCESS";
export var updateNdaSuccess = function () { return ({
    type: UPDATE_NDA_SUCCESS,
}); };
export var UPDATE_NDA_FAILURE = "UPDATE_NDA_FAILURE";
export var updateNdaFailure = function (message) { return ({
    type: UPDATE_NDA_FAILURE,
    payload: { message: message },
}); };
export var LOAD_NDAS_IN_PROGRESS = "LOAD_NDAS_IN_PROGRESS";
export var loadNdasInProgress = function () { return ({
    type: LOAD_NDAS_IN_PROGRESS,
}); };
export var LOAD_NDAS_SUCCESS = "LOAD_NDAS_SUCCESS";
export var loadNdasSuccess = function (userNdas) { return ({
    type: LOAD_NDAS_SUCCESS,
    payload: { userNdas: userNdas },
}); };
export var LOAD_NDAS_FAILURE = "LOAD_NDAS_FAILURE";
export var loadNdasFailure = function (message) { return ({
    type: LOAD_NDAS_FAILURE,
    payload: { message: message },
}); };
export var STOP_NDAS_LOADING = "STOP_NDAS_LOADING";
export var stopNdasLoading = function () { return ({
    type: STOP_NDAS_LOADING,
}); };
