var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFormikErrorMessage } from "../../tools/generalTools";
import "./KTextArea.css";
var KTextArea = function (_a) {
    var name = _a.name, label = _a.label, placeholder = _a.placeholder, _b = _a.onChange, onChange = _b === void 0 ? function (f) { return f; } : _b, width = _a.width, height = _a.height, labelMargin = _a.labelMargin, marginVertical = _a.marginVertical, marginHorizontal = _a.marginHorizontal, marginRight = _a.marginRight, marginLeft = _a.marginLeft, backgroundColor = _a.backgroundColor, borderless = _a.borderless, value = _a.value, extra = _a.extra, errorMessage = _a.errorMessage, formik = _a.formik, onFocus = _a.onFocus, onKeyDown = _a.onKeyDown, onBlurCapture = _a.onBlurCapture, _c = _a.borderRadius, borderRadius = _c === void 0 ? "5px" : _c, inputRef = _a.inputRef, resize = _a.resize, _d = _a.rows, rows = _d === void 0 ? 1 : _d;
    var styleContainer = {
        "--ktextarea-width": width || "100%",
        "--ktextarea-height": height || "180px",
        "--ktextarea-label-margin": labelMargin || "5px",
        "--ktextarea-border-radius": borderless ? "0" : borderRadius,
        "--ktextarea-border": borderless ? "0" : "",
        "--ktextarea-margin-vertical": marginVertical || "0px",
        "--ktextarea-margin-horizontal": marginHorizontal || "0px",
        "--ktextarea-margin-right": marginRight || "0px",
        "--ktextarea-margin-left": marginLeft || "0px",
        "--ktextarea-background-color": backgroundColor || "var(--app-tertiary-background-color)",
        "--ktextarea-resize": resize ? resize : "none",
    };
    errorMessage = errorMessage || getFormikErrorMessage(formik, name);
    var errorOrNot = errorMessage ? "err" : "";
    return (_jsx("div", __assign({ className: "ktextarea-container", style: styleContainer }, { children: _jsxs("div", __assign({ className: "ktextarea-wrapper grow-wrap " + errorOrNot }, { children: [_jsx("label", __assign({ className: "ktextarea-label" }, { children: label })), _jsx("textarea", __assign({ className: "ktextarea-input", type: "text", name: name, ref: inputRef, placeholder: placeholder, rows: rows, onChange: function (e) {
                        onChange(e);
                        formik === null || formik === void 0 ? void 0 : formik.handleChange(e);
                    }, onBlurCapture: onBlurCapture, onKeyDown: onKeyDown, onFocus: onFocus }, (typeof value !== "undefined" ? { value: value } : {}), extra)), errorMessage ? (_jsx("div", __assign({ className: "errorMsg" }, { children: errorMessage || "" }))) : (_jsx(_Fragment, {}))] })) })));
};
export default KTextArea;
