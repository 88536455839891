var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import ProjectHeader from "../../components/ProjectHeader/ProjectHeader";
import MultiImageCard from "../../components/MultiImageCard/MultiImageCard";
import ProjectTextCard from "../../components/ProjectTextCard/ProjectTextCard";
import ProjectSummaryCard from "../../components/ProjectSummaryCard/ProjectSummaryCard";
import { getAccessRequestStatus, getProject, getProjectActors, getProjectCharacters, getProjectCollaborators, getProjectCoverImages, getProjectCrews, getProjectMoodImages, getProjectShootingSets, getProjectStoryImages, getProjectSynopsisFiles, getProjectTreatmentFiles, getProjectVideoScenes, } from "../../redux/selectors/projectSelectors";
import { isScriptProject, isFilmProject } from "../../tools/projectTools";
import AcquiredFundsCard from "../../components/AcquiredFundsCard/AcquiredFundsCard";
import ProjectInfoCard from "../../components/ProjectInfoCard/ProjectInfoCard";
import { thunkGetUserByUserId, thunkLoadProjectViewsByProjectId, thunkLoadUserByProjectId, } from "../../redux/thunks/userThunks";
import { thunkAccessFullProjectUrl, thunkLoadCollaborationUsers, thunkLoadCurrentNda, thunkLoadProject, thunkLoadProjectCharacters, thunkLoadProjectMembers, thunkLoadProjectShootingSets, thunkLoadProjectUserViews, thunkLoadProjectVideoScenes, thunkLoadUserCurrentAccessRequest, thunkRequestProjectAccess, } from "../../redux/thunks/projectThunks";
import { getFullName } from "../../tools/userTools";
import { getUserId } from "../../redux/selectors/authSelectors";
import { getLoadedUser } from "../../redux/selectors/userSelectors";
import CollaboratorsCard from "../../components/CollaboratorsCard/CollaboratorsCard";
import ProjectCharactersCard from "../../components/ProjectCharactersCard/ProjectCharactersCard";
import ScrollableVideoCard from "../../components/ScrollableVideoCard/ScrollableVideoCard";
import SingleImageCard from "../../components/SingleImageCard/SingleImageCard";
import FullAccessCard from "../../components/FullAccessCard/FullAccessCard";
import MembersCard from "../../components/MembersCard/MembersCard";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import ProjectMainCrewCard from "../../components/ProjectMainCrewCard/ProjectMainCrewCard";
import { getColorMode } from "../../redux/selectors/generalSelectors";
import KButton from "../../base-components/KButton/KButton";
import KPopup from "../../base-components/KPopup/KPopup";
import ExtraInfo from "../ExtraInfo/ExtraInfo";
import { history } from "../../redux/store";
import kachat from "kachat";
import "./ProjectPage.css";
export var collaborate = function (project) {
    if (project.project_director_id_fk || project.project_producer_id_fk) {
        history.push("".concat(window.location.pathname, "?collaborate=true&projectId=").concat(project.project_id, "&projectTitle=").concat(project.project_title, "&ownerId=").concat(project.project_user_id_fk).concat(project.project_director_id_fk
            ? "&directorId=".concat(project.project_director_id_fk)
            : "").concat(project.project_producer_id_fk
            ? "&producerId=".concat(project.project_producer_id_fk)
            : ""));
    }
    else {
        var channelKey = "".concat(project.project_id, "_").concat(project.user_kachat_id);
        kachat.createNewGroupChat(project.project_title, channelKey, [
            project.user_kachat_id,
        ]);
        history.push("/platform/messaging");
    }
    /** TODO: Later on, we would want to support group chats.
     * Like for instance, chatting with the project owner,
     * the director and the producer at the same time. Note
     * that this feature is currently possible as a draft and
     * should not be used as is, it should be further elaborated.
     */
    // const linkedUserIds = []
    //     .concat(
    //         director.user_kachat_id
    //             ? [director.user_kachat_id]
    //             : []
    //     )
    //     .concat(
    //         producer.user_kachat_id
    //             ? [producer.user_kachat_id]
    //             : []
    //     )
    //     .sort((a, b) => a - b);
    // const channelKey = `${project.project_id}_${
    //     project.user_kachat_id
    // }${
    //     linkedUserIds.length
    //         ? "_" + linkedUserIds.join("_")
    //         : ""
    // }`;
    // kachat.createNewGroupChat(
    //     project.project_title,
    //     channelKey,
    //     [project.user_kachat_id].concat(
    //         linkedUserIds
    //     )
    // );
    // history.push("/platform/messaging");
};
export var ProjectPage = function (_a) {
    var _b = _a.project, project = _b === void 0 ? {} : _b, _c = _a.coverImages, coverImages = _c === void 0 ? [] : _c, _d = _a.moodImages, moodImages = _d === void 0 ? [] : _d, _e = _a.storyImages, storyImages = _e === void 0 ? [] : _e, _f = _a.synopsisFiles, synopsisFiles = _f === void 0 ? [] : _f, _g = _a.treatmentFiles, treatmentFiles = _g === void 0 ? [] : _g, accessRequestStatus = _a.accessRequestStatus, collaborators = _a.collaborators, shootingSets = _a.shootingSets, videoScenes = _a.videoScenes, characters = _a.characters, crewMembers = _a.crewMembers, colorMode = _a.colorMode, actors = _a.actors, onGetUser = _a.onGetUser, authUserId = _a.authUserId, loadedUser = _a.loadedUser, onLoadUser = _a.onLoadUser, onLoadProject = _a.onLoadProject, onLoadMembers = _a.onLoadMembers, onLoadVideoScenes = _a.onLoadVideoScenes, onLoadShootingSets = _a.onLoadShootingSets, onLoadCharacters = _a.onLoadCharacters, onLoadProjectViews = _a.onLoadProjectViews, onLoadCollaborators = _a.onLoadCollaborators, onProjectAccessRequest = _a.onProjectAccessRequest, onLoadCurrentAccessRequest = _a.onLoadCurrentAccessRequest, onLoadProjectUserViews = _a.onLoadProjectUserViews, onLoadNdaData = _a.onLoadNdaData, onGetSignedUrl = _a.onGetSignedUrl;
    var sideRef = useRef(null);
    var url = useRouteMatch().url;
    var projectId = useParams().projectId;
    var _h = useState(false), isReady = _h[0], setIsReady = _h[1];
    var _j = useState(false), isAllReady = _j[0], setIsAllReady = _j[1];
    var _k = useState({}), ndaData = _k[0], setNdaData = _k[1];
    var _l = useState({}), director = _l[0], setDirector = _l[1];
    var _m = useState({}), producer = _m[0], setProducer = _m[1];
    var _o = useState(""), projectSignedUrl = _o[0], setProjectSignedUrl = _o[1];
    var _p = useState(false), fullAccessCardActive = _p[0], setFullAccessCardActive = _p[1];
    var _q = useState(false), fillProjectCardActive = _q[0], setFillProjectCardActive = _q[1];
    var _r = useState("/platform/profile"), basePathname = _r[0], setBasePathname = _r[1];
    var _s = useState("Treatment"), treatmentTitle = _s[0], setTreatmentTitle = _s[1];
    var _t = useState(0), sidebarTop = _t[0], setSidebarTop = _t[1];
    var history = useHistory();
    var isEditable = function () {
        return project.user_id == authUserId;
    };
    var onFullAccessRequest = function () {
        setFullAccessCardActive(true);
    };
    var handleResize = function () {
        var _a, _b;
        if (((_a = sideRef.current) === null || _a === void 0 ? void 0 : _a.clientHeight) &&
            ((_b = sideRef.current) === null || _b === void 0 ? void 0 : _b.clientHeight) > window.innerHeight) {
            setSidebarTop(window.innerHeight - sideRef.current.clientHeight - 40);
        }
        else {
            setSidebarTop("var(--app-sticky-section-top)");
        }
    };
    var onSendRequest = function (ndaFile, signedNdaFile, optionalMessage) {
        onProjectAccessRequest({
            projectId: projectId,
            ndaFile: ndaFile,
            signedNdaFile: signedNdaFile,
            optionalMessage: optionalMessage,
            projectUserId: project.project_user_id_fk,
        }, function () { return setFullAccessCardActive(false); });
    };
    useDocumentTitle("Scriptofilm | ".concat(isEditable() ? "My Project" : "Project"));
    useEffect(function () {
        if (window.location.pathname &&
            window.location.pathname.split("/").length > 2) {
            setBasePathname(window.location.pathname.split("/").slice(0, 3).join("/"));
        }
    }, []);
    useEffect(function () {
        var _a;
        setTreatmentTitle(project.project_subtype_name &&
            ((_a = project.project_subtype_name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "series"
            ? "Episodes Overview"
            : "Treatment");
    }, [project === null || project === void 0 ? void 0 : project.projectSubtypeId]);
    useEffect(function () {
        var isSubscribed = true;
        window.addEventListener("resize", handleResize);
        var loadData = function (projectId) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Promise.all([
                    onLoadProject(projectId),
                    onLoadUser(projectId),
                    onLoadProjectViews(projectId),
                ]).then(function (data) {
                    if (isSubscribed)
                        setIsReady(true);
                    Promise.all([
                        onLoadMembers(projectId),
                        onLoadVideoScenes(projectId),
                        onLoadShootingSets(projectId),
                        onLoadCharacters(projectId),
                        onLoadCollaborators(projectId),
                        onLoadCurrentAccessRequest(projectId),
                        onLoadNdaData(projectId, function (data) { return setNdaData(data); }),
                        onGetSignedUrl(projectId, function (signedUrl) {
                            return setProjectSignedUrl(signedUrl);
                        }),
                    ]).then(function (data) {
                        setIsAllReady(true);
                    });
                });
                return [2 /*return*/];
            });
        }); };
        handleResize();
        window.scrollTo(0, 0);
        loadData(projectId);
        return function () {
            isSubscribed = false;
            window.removeEventListener("resize", handleResize);
        };
    }, [projectId]);
    useEffect(function () {
        handleResize();
    }, [director, producer]);
    useEffect(function () {
        if (!isAllReady)
            return;
        if (project.project_director_id_fk)
            onGetUser(project.project_director_id_fk, function (err, user) {
                return setDirector(user);
            });
        else if (project.project_director_name)
            setDirector({ name: project.project_director_name });
        if (project.project_producer_id_fk)
            onGetUser(project.project_producer_id_fk, function (err, user) {
                return setProducer(user);
            });
        else if (project.project_producer_name)
            setProducer({ name: project.project_producer_name });
        onLoadProjectUserViews(project.project_id, function (err, viewCount) {
            if (err)
                return console.error(err);
            if (viewCount !== 30)
                return;
            var basicFilledCount = (project.country_name !== null) +
                (project.language_name !== null) +
                (project.project_genre_name !== null) +
                (project.project_stage_name !== null) +
                (project.project_kind_name !== null) +
                (project.project_duration !== null ||
                    project.project_pages !== null);
            var extrasFilledCount = (videoScenes.length > 0) +
                (shootingSets.length > 0) +
                (characters.length > 0) +
                (actors.length > 0) +
                (crewMembers.length > 0) +
                (coverImages.length > 0) +
                (moodImages.length > 0) +
                (storyImages.length > 0) +
                (synopsisFiles.length > 0) +
                (treatmentFiles.length > 0) +
                (project.project_synopsis !== null);
            if (basicFilledCount < 4 ||
                extrasFilledCount < 4 ||
                !project.project_title)
                setFillProjectCardActive(true);
        });
    }, [project, isAllReady]);
    useEffect(function () {
        handleResize();
    }, [collaborators.length, actors.length, crewMembers.length, project]);
    useEffect(function () {
        if (project.project_id != projectId)
            return;
        // if (isReady && !isEditable() && url.split("/")[2] == "profile") {
        //     window.history.replaceState(
        //         null,
        //         "",
        //         `/platform/market/project/${projectId}`
        //     );
        // }
    }, [project, isReady]);
    return (_jsx("div", __assign({ className: "projectpage-container" }, { children: isReady ? (_jsxs(_Fragment, { children: [_jsx(KPopup, __assign({ active: fullAccessCardActive, onClose: function () { return setFullAccessCardActive(false); } }, { children: _jsx(FullAccessCard, { projectType: project.project_type_name, projectName: project.project_title, projectGenre: project.project_genre_name, projectUserName: getFullName(loadedUser), userTitle: loadedUser.user_user_title_name, ndaSignedOrApproved: ndaData.nda_status === "signed" ||
                            ndaData.nda_status === "approved", ndaPath: ndaData.nda_path, requestingUser: loadedUser || {}, onCancel: function () { return setFullAccessCardActive(false); }, onSendRequest: onSendRequest, isRequest: true }) })), _jsx(KPopup, __assign({ active: fillProjectCardActive, onClose: function () { return setFillProjectCardActive(false); } }, { children: _jsxs("div", __assign({ className: "projectpage-fill-project" }, { children: [_jsx("div", __assign({ className: "projectpage-fill-project-title" }, { children: "Friendly Recommendation" })), _jsx("p", { children: "Filling your project with all of the required information would increase your chances of closing deals. Make sure to fill as much information as possible!" }), _jsx("div", __assign({ className: "projectpage-fill-project-button" }, { children: _jsx(KButton, { title: "Okay", onClick: function () {
                                        return setFillProjectCardActive(false);
                                    }, variant: "primary" }) }))] })) })), _jsx("div", __assign({ style: { marginBottom: "10px" } }, { children: _jsx(ProjectHeader, { project: isReady ? project : {}, user: isReady ? loadedUser : {}, accessStatus: accessRequestStatus, signedUrl: projectSignedUrl, isEditable: authUserId == project.project_user_id_fk, onAccessRequest: onFullAccessRequest, onCollaborate: function () { return collaborate(project); }, onEditProject: function () {
                            return history.push("".concat(basePathname, "/editproject/basics"), {
                                projectId: projectId,
                            });
                        } }) })), _jsx("div", { children: _jsx(ProjectSummaryCard, { user: isReady ? loadedUser : {}, project: isReady ? project : {}, coverImages: coverImages, authUserId: authUserId }) }), _jsxs("div", __assign({ className: "projectpage-body-sections" }, { children: [_jsxs("div", __assign({ className: "projectpage-main-section" }, { children: [_jsx("div", __assign({ style: { marginBottom: "24px" } }, { children: _jsx(ProjectTextCard, { title: "synopsis", files: isReady ? synopsisFiles : [], sizeKey: "project_synopsisfile_size", pathKey: "project_synopsisfile_path", titleKey: "project_synopsisfile_title", text: isReady ? project.project_synopsis : "", emptyText: "No synopsis provided..." }) })), _jsx("div", __assign({ style: {
                                        marginBottom: "24px",
                                        display: isScriptProject(project.project_type_name)
                                            ? "block"
                                            : "none",
                                    } }, { children: _jsx(ProjectTextCard, { title: treatmentTitle === null || treatmentTitle === void 0 ? void 0 : treatmentTitle.toLowerCase(), files: isReady ? treatmentFiles : [], sizeKey: "project_treatmentfile_size", pathKey: "project_treatmentfile_path", titleKey: "project_treatmentfile_title", text: isReady ? project.project_treatment : "", emptyText: "No ".concat(treatmentTitle === null || treatmentTitle === void 0 ? void 0 : treatmentTitle.toLowerCase(), " provided...") }) })), _jsx("div", __assign({ style: {
                                        marginBottom: "24px",
                                    } }, { children: _jsx(ProjectCharactersCard, { characters: characters }) })), _jsx("div", __assign({ style: {
                                        marginBottom: "24px",
                                        display: isScriptProject(project.project_type_name)
                                            ? "block"
                                            : "none",
                                    } }, { children: _jsx(MultiImageCard, { title: "moodboard", pathKey: "project_moodimage_path", smallPathKey: "project_moodimage_small_path", images: isReady ? moodImages : [] }) })), _jsx("div", __assign({ style: {
                                        marginBottom: "24px",
                                        display: isFilmProject(project.project_type_name)
                                            ? "block"
                                            : "none",
                                    } }, { children: _jsx(MultiImageCard, { title: "storyboard", pathKey: "project_storyimage_path", images: isReady ? storyImages : [] }) })), _jsx("div", __assign({ style: {
                                        marginBottom: "24px",
                                        display: isFilmProject(project.project_type_name)
                                            ? "block"
                                            : "block",
                                    } }, { children: _jsx(ScrollableVideoCard, { colorMode: colorMode, title: "video scenes", pathKey: "project_videoscene_path", titleKey: "project_videoscene_title", descriptionKey: "project_videoscene_description", videos: isReady ? videoScenes : [] }) })), _jsx("div", __assign({ style: {
                                        marginBottom: "24px",
                                        display: isFilmProject(project.project_type_name)
                                            ? "block"
                                            : "none",
                                    } }, { children: _jsx(SingleImageCard, { title: "shooting stills", pathKey: "shootingset_image_path", smallPathKey: "shootingset_small_image_path", titlePathKey: "shootingset_title", images: isReady ? shootingSets : [] }) }))] })), _jsxs("div", __assign({ className: "projectpage-secondary-section", ref: sideRef, style: {
                                top: sidebarTop,
                            } }, { children: [_jsxs("div", __assign({ className: "projectpage-secondary-section-first-part" }, { children: [director.name ||
                                            director.user_id ||
                                            producer.name ||
                                            producer.user_id ? (_jsx("div", __assign({ className: "projectpage-listing-card" }, { children: _jsx(ProjectMainCrewCard, { director: director, producer: producer }) }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "projectpage-listing-card" }, { children: _jsx(ProjectInfoCard, { project: project }) }))] })), _jsxs("div", __assign({ className: "projectpage-secondary-section-second-part" }, { children: [_jsx("div", __assign({ className: "projectpage-listing-card" }, { children: _jsx(MembersCard, { actors: actors, crewMembers: crewMembers }) })), _jsx("div", __assign({ className: "projectpage-listing-card" }, { children: _jsx(AcquiredFundsCard, { project: project }) })), _jsx("div", __assign({ className: "projectpage-listing-card" }, { children: _jsx(CollaboratorsCard, { collaborators: collaborators.filter(function (user) {
                                                    return user.user_id !==
                                                        project.project_user_id_fk;
                                                }) }) }))] })), _jsx("div", __assign({ className: "projectpage-secondary-section-info" }, { children: _jsx(ExtraInfo, {}) }))] }))] }))] })) : (_jsx(_Fragment, {})) })));
};
var mapStateToProps = function (state) { return ({
    project: getProject(state),
    colorMode: getColorMode(state),
    loadedUser: getLoadedUser(state),
    coverImages: getProjectCoverImages(state),
    moodImages: getProjectMoodImages(state),
    storyImages: getProjectStoryImages(state),
    synopsisFiles: getProjectSynopsisFiles(state),
    treatmentFiles: getProjectTreatmentFiles(state),
    accessRequestStatus: getAccessRequestStatus(state),
    collaborators: getProjectCollaborators(state),
    videoScenes: getProjectVideoScenes(state),
    shootingSets: getProjectShootingSets(state),
    characters: getProjectCharacters(state),
    crewMembers: getProjectCrews(state),
    actors: getProjectActors(state),
    authUserId: getUserId(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onGetUser: function (userId, callback) {
        return dispatch(thunkGetUserByUserId(userId, callback));
    },
    onLoadProject: function (projectId) { return dispatch(thunkLoadProject(projectId)); },
    onLoadUser: function (projectId) { return dispatch(thunkLoadUserByProjectId(projectId)); },
    onLoadVideoScenes: function (projectId) {
        return dispatch(thunkLoadProjectVideoScenes(projectId));
    },
    onLoadShootingSets: function (projectId) {
        return dispatch(thunkLoadProjectShootingSets(projectId));
    },
    onLoadCharacters: function (projectId) {
        return dispatch(thunkLoadProjectCharacters(projectId));
    },
    onLoadMembers: function (projectId) { return dispatch(thunkLoadProjectMembers(projectId)); },
    onLoadProjectViews: function (projectId) {
        return dispatch(thunkLoadProjectViewsByProjectId(projectId));
    },
    onProjectAccessRequest: function (data, callback) {
        return dispatch(thunkRequestProjectAccess(data, callback));
    },
    onLoadCurrentAccessRequest: function (projectId) {
        return dispatch(thunkLoadUserCurrentAccessRequest(projectId));
    },
    onGetSignedUrl: function (projectId, callback) {
        return dispatch(thunkAccessFullProjectUrl(projectId, callback));
    },
    onLoadNdaData: function (projectId, callback) {
        return dispatch(thunkLoadCurrentNda(projectId, callback));
    },
    onLoadCollaborators: function (projectId) {
        return dispatch(thunkLoadCollaborationUsers(projectId));
    },
    onLoadProjectUserViews: function (projectId, callback) {
        return dispatch(thunkLoadProjectUserViews(projectId, callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);
