export var SET_COLOR_MODE = "SET_COLOR_MODE";
export var setColorMode = function (colorMode) { return ({
    type: SET_COLOR_MODE,
    payload: { colorMode: colorMode },
}); };
export var SET_PROGRESS = "SET_PROGRESS";
export var setProgress = function (progress) { return ({
    type: SET_PROGRESS,
    payload: { progress: progress },
}); };
export var SET_PROGRESS_STATUS = "SET_PROGRESS_STATUS";
export var setProgressStatus = function (progressStatus) { return ({
    type: SET_PROGRESS_STATUS,
    payload: { progressStatus: progressStatus },
}); };
export var SET_PRIMARY_COLOR = "SET_PRIMARY_COLOR";
export var setPrimaryColor = function (primaryColor) { return ({
    type: SET_PRIMARY_COLOR,
    payload: { primaryColor: primaryColor },
}); };
export var LOAD_USER_TITLES_IN_PROGRESS = "LOAD_USER_TITLES_IN_PROGRESS";
export var loadUserTitlesInProgress = function () { return ({
    type: LOAD_USER_TITLES_IN_PROGRESS,
}); };
export var LOAD_USER_TITLES_SUCCESS = "LOAD_USER_TITLES_SUCCESS";
export var loadUserTitlesSuccess = function (userTitles) { return ({
    type: LOAD_USER_TITLES_SUCCESS,
    payload: { userTitles: userTitles },
}); };
export var LOAD_USER_TITLES_FAILURE = "LOAD_USER_TITLES_FAILURE";
export var loadUserTitlesFailure = function (message) { return ({
    type: LOAD_USER_TITLES_FAILURE,
    payload: { message: message },
}); };
export var LOAD_PROJECT_KINDS_IN_PROGRESS = "LOAD_PROJECT_KINDS_IN_PROGRESS";
export var loadProjectKindsInProgress = function () { return ({
    type: LOAD_PROJECT_KINDS_IN_PROGRESS,
}); };
export var LOAD_PROJECT_KINDS_SUCCESS = "LOAD_PROJECT_KINDS_SUCCESS";
export var loadProjectKindsSuccess = function (projectKinds) { return ({
    type: LOAD_PROJECT_KINDS_SUCCESS,
    payload: { projectKinds: projectKinds },
}); };
export var LOAD_PROJECT_KINDS_FAILURE = "LOAD_PROJECT_KINDS_FAILURE";
export var loadProjectKindsFailure = function (message) { return ({
    type: LOAD_PROJECT_KINDS_FAILURE,
    payload: { message: message },
}); };
export var LOAD_PROJECT_GENRES_IN_PROGRESS = "LOAD_PROJECT_GENRES_IN_PROGRESS";
export var loadProjectGenresInProgress = function () { return ({
    type: LOAD_PROJECT_GENRES_IN_PROGRESS,
}); };
export var LOAD_PROJECT_GENRES_SUCCESS = "LOAD_PROJECT_GENRES_SUCCESS";
export var loadProjectGenresSuccess = function (projectGenres) { return ({
    type: LOAD_PROJECT_GENRES_SUCCESS,
    payload: { projectGenres: projectGenres },
}); };
export var LOAD_PROJECT_GENRES_FAILURE = "LOAD_PROJECT_GENRES_FAILURE";
export var loadProjectGenresFailure = function (message) { return ({
    type: LOAD_PROJECT_GENRES_FAILURE,
    payload: { message: message },
}); };
export var LOAD_PROJECT_TYPES_IN_PROGRESS = "LOAD_PROJECT_TYPES_IN_PROGRESS";
export var loadProjectTypesInProgress = function () { return ({
    type: LOAD_PROJECT_TYPES_IN_PROGRESS,
}); };
export var LOAD_PROJECT_TYPES_SUCCESS = "LOAD_PROJECT_TYPES_SUCCESS";
export var loadProjectTypesSuccess = function (projectTypes) { return ({
    type: LOAD_PROJECT_TYPES_SUCCESS,
    payload: { projectTypes: projectTypes },
}); };
export var LOAD_PROJECT_TYPES_FAILURE = "LOAD_PROJECT_TYPES_FAILURE";
export var loadProjectTypesFailure = function (message) { return ({
    type: LOAD_PROJECT_TYPES_FAILURE,
    payload: { message: message },
}); };
export var LOAD_PROJECT_SUBTYPES_IN_PROGRESS = "LOAD_PROJECT_SUBTYPES_IN_PROGRESS";
export var loadProjectSubtypesInProgress = function () { return ({
    type: LOAD_PROJECT_SUBTYPES_IN_PROGRESS,
}); };
export var LOAD_PROJECT_SUBTYPES_SUCCESS = "LOAD_PROJECT_SUBTYPES_SUCCESS";
export var loadProjectSubtypesSuccess = function (projectSubtypes) { return ({
    type: LOAD_PROJECT_SUBTYPES_SUCCESS,
    payload: { projectSubtypes: projectSubtypes },
}); };
export var LOAD_PROJECT_SUBTYPES_FAILURE = "LOAD_PROJECT_SUBTYPES_FAILURE";
export var loadProjectSubtypesFailure = function (message) { return ({
    type: LOAD_PROJECT_SUBTYPES_FAILURE,
    payload: { message: message },
}); };
export var LOAD_PROJECT_STAGES_IN_PROGRESS = "LOAD_PROJECT_STAGES_IN_PROGRESS";
export var loadProjectStagesInProgress = function () { return ({
    type: LOAD_PROJECT_STAGES_IN_PROGRESS,
}); };
export var LOAD_PROJECT_STAGES_SUCCESS = "LOAD_PROJECT_STAGES_SUCCESS";
export var loadProjectStagesSuccess = function (projectStages) { return ({
    type: LOAD_PROJECT_STAGES_SUCCESS,
    payload: { projectStages: projectStages },
}); };
export var LOAD_PROJECT_STAGES_FAILURE = "LOAD_PROJECT_STAGES_FAILURE";
export var loadProjectStagesFailure = function (message) { return ({
    type: LOAD_PROJECT_STAGES_FAILURE,
    payload: { message: message },
}); };
export var LOAD_COUNTRIES_IN_PROGRESS = "LOAD_COUNTRIES_IN_PROGRESS";
export var loadCountriesInProgress = function () { return ({
    type: LOAD_COUNTRIES_IN_PROGRESS,
}); };
export var LOAD_COUNTRIES_SUCCESS = "LOAD_COUNTRIES_SUCCESS";
export var loadCountriesSuccess = function (countries) { return ({
    type: LOAD_COUNTRIES_SUCCESS,
    payload: { countries: countries },
}); };
export var LOAD_COUNTRIES_FAILURE = "LOAD_COUNTRIES_FAILURE";
export var loadCountriesFailure = function (message) { return ({
    type: LOAD_COUNTRIES_FAILURE,
    payload: { message: message },
}); };
export var LOAD_CITIES_IN_PROGRESS = "LOAD_CITIES_IN_PROGRESS";
export var loadCitiesInProgress = function () { return ({
    type: LOAD_CITIES_IN_PROGRESS,
}); };
export var LOAD_CITIES_SUCCESS = "LOAD_CITIES_SUCCESS";
export var loadCitiesSuccess = function (cities) { return ({
    type: LOAD_CITIES_SUCCESS,
    payload: { cities: cities },
}); };
export var LOAD_CITIES_FAILURE = "LOAD_CITIES_FAILURE";
export var loadCitiesFailure = function (message) { return ({
    type: LOAD_CITIES_FAILURE,
    payload: { message: message },
}); };
export var LOAD_LANGUAGES_IN_PROGRESS = "LOAD_LANGUAGES_IN_PROGRESS";
export var loadLanguagesInProgress = function () { return ({
    type: LOAD_LANGUAGES_IN_PROGRESS,
}); };
export var LOAD_LANGUAGES_SUCCESS = "LOAD_LANGUAGES_SUCCESS";
export var loadLanguagesSuccess = function (languages) { return ({
    type: LOAD_LANGUAGES_SUCCESS,
    payload: { languages: languages },
}); };
export var LOAD_LANGUAGES_FAILURE = "LOAD_LANGUAGES_FAILURE";
export var loadLanguagesFailure = function (message) { return ({
    type: LOAD_LANGUAGES_FAILURE,
    payload: { message: message },
}); };
export var STOP_GENERAL_LOADING = "STOP_GENERAL_LOADING";
export var stopGeneralLoading = function () { return ({
    type: STOP_GENERAL_LOADING,
}); };
export var START_GENERAL_LOADING = "START_GENERAL_LOADING";
export var startGeneralLoading = function () { return ({
    type: START_GENERAL_LOADING,
}); };
