var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FcGoogle as IconGoogle } from "react-icons/fc";
import KButton from "../../base-components/KButton/KButton";
import TermsPopup from "../../components/TermsPopup/TermsPopup";
import KTextField from "../../base-components/KTextField/KTextField";
import { thunkClearData, thunkSetPrimaryColor, } from "../../redux/thunks/generalThunks";
import { getUserAuthenticated, getUserError, getToken, getUser, } from "../../redux/selectors/authSelectors";
import { thunkUpdateSignupInfo } from "../../redux/thunks/authThunks";
import { thunkGetUserTitle } from "../../redux/thunks/userThunks";
import { getUserTitleFromEmail } from "../../redux/selectors/userSelectors";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import authApi from "../../apis/authApi";
import "./SigninPage.css";
var SigninPage = function (_a) {
    var onSignin = _a.onSignin, onClearData = _a.onClearData, onSetPrimaryColor = _a.onSetPrimaryColor, userTitle = _a.userTitle, errorMessage = _a.errorMessage, token = _a.token, onGetUserTitle = _a.onGetUserTitle, onUpdateSignUpInfo = _a.onUpdateSignUpInfo, isAuthenticated = _a.isAuthenticated, user = _a.user;
    var history = useHistory();
    var query = new URLSearchParams(useLocation().search);
    var _b = useState(""), email = _b[0], setEmail = _b[1];
    var _c = useState(""), password = _c[0], setPassword = _c[1];
    var _d = useState(false), shouldVerify = _d[0], setShouldVerify = _d[1];
    var _e = useState(false), isTermsOpen = _e[0], setIsTermsOpen = _e[1];
    var _f = useState(""), lastCheckedEmail = _f[0], setLastCheckedEmail = _f[1];
    useDocumentTitle("Scriptofilm | Sign-in");
    useEffect(function () {
        if (token && isAuthenticated) {
            history.push("/setup");
        }
        else if (token && !isAuthenticated && user.user_id) {
            setShouldVerify(true);
        }
    }, [token, isAuthenticated]);
    useEffect(function () {
        onSetPrimaryColor((userTitle || "unknown").toLowerCase());
    }, [userTitle]);
    useEffect(function () {
        setShouldVerify(false);
        if (!token)
            onClearData();
        onSetPrimaryColor("unknown");
    }, []);
    useEffect(function () {
        onUpdateSignUpInfo({ email: email });
    }, [email]);
    return (_jsxs("div", __assign({ className: "signinpage-container" }, { children: [_jsx(TermsPopup, { active: isTermsOpen, onClose: function () { return setIsTermsOpen(false); } }), _jsxs("div", __assign({ className: "signinpage-card" }, { children: [_jsx("div", __assign({ className: "signinpage-logo-div" }, { children: _jsx("a", __assign({ href: "/" }, { children: _jsx("img", { className: "signinpage-logo", alt: "scriptofilm" }) })) })), _jsx("div", __assign({ className: "signinpage-header" }, { children: "Welcome back" })), shouldVerify ? (_jsxs("div", __assign({ className: "signinpage-welcome" }, { children: [_jsx("div", { dangerouslySetInnerHTML: {
                                    __html: process.env.VERIFICATION_REQUIRED_HTML,
                                } }), _jsx("p", __assign({ className: "signinpage-tosignin" }, { children: _jsx("a", __assign({ onClick: function () { return history.push("/"); } }, { children: "Visit our landing page" })) })), _jsxs("p", __assign({ className: "signinpage-tosignin" }, { children: ["or", " ", _jsx("a", __assign({ onClick: function () { return history.push("/signup"); } }, { children: "Sign up" }))] }))] }))) : (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "signinpage-error", style: {
                                    display: "".concat(query.get("message") ? "block" : "none"),
                                } }, { children: query.get("message") })), _jsxs("form", __assign({ action: "".concat(authApi.defaults.baseURL, "/login"), method: "post" }, { children: [_jsx("div", __assign({ className: "signinpage-input-container" }, { children: _jsx(KTextField, { label: "Email", width: "100%", type: "email", name: "email", required: true, value: email, onChange: function (event) {
                                                return setEmail(event.target.value);
                                            }, onBlurCapture: function () {
                                                if (email != lastCheckedEmail) {
                                                    onGetUserTitle(email);
                                                    setLastCheckedEmail(email);
                                                }
                                            } }) })), _jsx("div", __assign({ className: "signinpage-input-container" }, { children: _jsx(KTextField, { label: "Password", width: "100%", type: "password", name: "password", required: true, value: password, onChange: function (event) {
                                                return setPassword(event.target.value);
                                            } }) })), _jsxs("p", __assign({ className: "signinpage-disclosure" }, { children: ["By signing up, you're agreeing to Scriptofilm's", " ", _jsx(_Fragment, { children: " " }), _jsx("a", __assign({ onClick: function () {
                                                    return history.push("/termsofservice");
                                                } }, { children: "terms of service" })), ",", _jsx("span", { children: " privacy policy" }), ", and", _jsx("span", { children: " cookie compliance" }), "."] })), _jsx(KButton, { title: "Sign in", variant: "primary", width: "100%", height: "44px", onClick: function (f) { return f; }, type: "submit" })] })), _jsxs("div", __assign({ className: "signinpage-separator" }, { children: [_jsx("div", { className: "signinpage-line" }), _jsx("h2", { children: "OR CONTINUE WITH" }), _jsx("div", { className: "signinpage-line" })] })), _jsx(KButton, { title: "", 
                                // title="Sign in with Google"
                                variant: "primary", width: "100%", height: "26px", color: "white", icon: IconGoogle, href: "".concat(authApi.defaults.baseURL, "/google"), backgroundColor: "#f1f1f1", 
                                // backgroundColor="#4285f4"
                                marginVertical: "8px" }), _jsxs("p", __assign({ className: "signinpage-tosignup" }, { children: ["Not a Scriptofilm member yet?", " ", _jsx("a", __assign({ onClick: function () { return history.push("/signup"); } }, { children: "Sign up" }))] })), _jsx("p", __assign({ className: "signinpage-tosignup" }, { children: _jsx("a", __assign({ style: { color: "var(--app-buyer-color)" }, onClick: function () { return history.push("/forgot"); } }, { children: "Forgot your password?" })) })), _jsx("p", __assign({ className: "signinpage-tosignup" }, { children: _jsx("a", __assign({ style: { color: "var(--app-producer-color)" }, onClick: function () { return history.push("/"); } }, { children: "Visit our landing page" })) }))] }))] }))] })));
};
var mapStateToProps = function (state) { return ({
    isAuthenticated: getUserAuthenticated(state),
    errorMessage: getUserError(state),
    userTitle: getUserTitleFromEmail(state),
    token: getToken(state),
    user: getUser(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onClearData: function () { return dispatch(thunkClearData()); },
    onGetUserTitle: function (email) { return dispatch(thunkGetUserTitle(email)); },
    onSetPrimaryColor: function (primaryColor) {
        return dispatch(thunkSetPrimaryColor(primaryColor));
    },
    onUpdateSignUpInfo: function (user) { return dispatch(thunkUpdateSignupInfo(user)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(SigninPage);
