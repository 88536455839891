var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import KButton from "../../base-components/KButton/KButton";
import KTextField from "../../base-components/KTextField/KTextField";
import { thunkClearData } from "../../redux/thunks/generalThunks";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import authApi from "../../apis/authApi";
import "./ForgotPasswordPage.css";
var ForgotPasswordPage = function (_a) {
    var onClearData = _a.onClearData;
    var history = useHistory();
    var _b = useState(""), email = _b[0], setEmail = _b[1];
    var _c = useState(false), success = _c[0], setSuccess = _c[1];
    var _d = useState(0), resetExpiry = _d[0], setResetExpiry = _d[1];
    var _e = useState(""), errorMessage = _e[0], setErrorMessage = _e[1];
    var query = new URLSearchParams(useLocation().search);
    var getExpiryDate = function () {
        return (parseInt(Math.max(0, resetExpiry - new Date().getTime() / 1000) / 60) + " minutes");
    };
    useDocumentTitle("Scriptofilm | Forgot-pass");
    useEffect(function () {
        setResetExpiry(query.get("expiry"));
        setErrorMessage(query.get("message"));
        setSuccess(query.get("success") === "true");
        onClearData();
    }, []);
    return (_jsx("div", __assign({ className: "forgotpage-container" }, { children: _jsxs("div", __assign({ className: "forgotpage-card" }, { children: [_jsx("div", __assign({ className: "forgotpage-logo-div" }, { children: _jsx("a", __assign({ href: "/" }, { children: _jsx("img", { className: "forgotpage-logo", alt: "scriptofilm" }) })) })), success ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "forgotpage-header" }, { children: "Reset instructions sent" })), _jsxs("div", __assign({ className: "forgotpage-welcome" }, { children: [_jsxs("p", { children: ["Make sure to check your ", _jsx("u", { children: "SPAM" }), " and follow the instructions to reset your password."] }), _jsx("p", { children: "We're looking forward to having you back on the platform with us." }), _jsxs("p", { children: ["Your link expires in: ", getExpiryDate()] }), _jsx("p", __assign({ className: "forgotpage-tosignin" }, { children: _jsx("a", __assign({ style: {
                                            color: "var(--app-producer-color)",
                                        }, onClick: function () { return history.push("/"); } }, { children: "Visit our landing page" })) })), _jsxs("p", __assign({ className: "forgotpage-tosignin" }, { children: ["or", " ", _jsx("a", __assign({ style: {
                                                color: "var(--app-filmmaker-color)",
                                            }, onClick: function () { return history.push("/signin"); } }, { children: "Sign in" }))] }))] }))] })) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "forgotpage-header" }, { children: "Forgot your password?" })), _jsxs("p", __assign({ className: "forgotpage-error", style: {
                                display: "".concat(errorMessage ? "block" : "none"),
                            } }, { children: [_jsx("b", { children: "ERROR:" }), " ", errorMessage] })), _jsxs("form", __assign({ action: "".concat(authApi.defaults.baseURL, "/forgot"), method: "post" }, { children: [_jsx("div", __assign({ className: "forgotpage-input-container" }, { children: _jsx(KTextField, { label: "Email", width: "100%", type: "email", name: "email", required: true, value: email, onChange: function (event) {
                                            return setEmail(event.target.value);
                                        } }) })), _jsx("p", __assign({ className: "forgotpage-disclosure" }, { children: "An email will be sent with a link to reset it." })), _jsx(KButton, { title: "Request Password Reset", variant: "primary", width: "100%", height: "44px", onClick: function (f) { return f; }, type: "submit" }), _jsxs("p", __assign({ className: "forgotpage-tosignup" }, { children: [_jsxs("a", __assign({ style: {
                                                color: "var(--app-filmmaker-color)",
                                            }, onClick: function () { return history.push("/signin"); } }, { children: [" ", "Sign in", " "] })), "or", " ", _jsxs("a", __assign({ style: {
                                                color: "var(--app-producer-color)",
                                            }, onClick: function () { return history.push("/signup"); } }, { children: [" ", "Sign up", " "] }))] }))] }))] }))] })) })));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    onClearData: function () { return dispatch(thunkClearData()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
