var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import KClampBox from "../../base-components/KClampBox/KClampBox";
import { BsChevronCompactRight as IconRight } from "react-icons/bs";
import { BsChevronCompactLeft as IconLeft } from "react-icons/bs";
import { getStorageVideoUrl } from "../../apis/fileApi";
import "./ScrollableVideoCard.css";
var ScrollableVideoCard = function (_a) {
    var title = _a.title, _b = _a.pathKey, pathKey = _b === void 0 ? "" : _b, _c = _a.titleKey, titleKey = _c === void 0 ? "" : _c, _d = _a.descriptionKey, descriptionKey = _d === void 0 ? "" : _d, _e = _a.colorMode, colorMode = _e === void 0 ? "dark" : _e, _f = _a.videos, videos = _f === void 0 ? [] : _f;
    var scrollRef = useRef(null);
    var _g = useState(), activeKey = _g[0], setActiveKey = _g[1];
    var _h = useState(false), leftVisible = _h[0], setLeftVisible = _h[1];
    var _j = useState(false), rightVisible = _j[0], setRightVisible = _j[1];
    var getVideoUrl = function (video) {
        return video ? getStorageVideoUrl(video[pathKey]) || "" : "";
    };
    var updateActions = function () {
        var _a, _b, _c, _d, _e, _f;
        setLeftVisible(((_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollLeft) > 50);
        setRightVisible(((_b = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollWidth) > ((_c = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _c === void 0 ? void 0 : _c.clientWidth) &&
            ((_d = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _d === void 0 ? void 0 : _d.scrollWidth) -
                ((_e = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _e === void 0 ? void 0 : _e.clientWidth) -
                ((_f = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _f === void 0 ? void 0 : _f.scrollLeft) >
                50);
    };
    useEffect(function () {
        var _a;
        if (!(scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current))
            return;
        (_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener("scroll", updateActions);
        updateActions();
        return function () {
            var _a;
            (_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("scroll", updateActions);
        };
    }, [scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current]);
    return (_jsx(_Fragment, { children: videos && videos.length ? (_jsxs("div", __assign({ className: "scrollablemediacard-container" }, { children: [_jsxs("div", __assign({ className: "scrollablemediacard-header" }, { children: ["_", title] })), _jsxs("div", __assign({ className: "scrollablemediacard-body-container" }, { children: [_jsx("div", __assign({ className: "scrollablemediacard-left-action ".concat(colorMode), onClick: function () {
                                var _a, _b;
                                return (_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                                    left: ((_b = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollLeft) - 392,
                                    behavior: "smooth",
                                });
                            }, style: {
                                display: leftVisible ? "flex" : "none",
                            } }, { children: _jsx(IconLeft, {}) })), _jsx("div", __assign({ className: "scrollablemediacard-right-action ".concat(colorMode), onClick: function () {
                                var _a, _b;
                                return (_a = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
                                    left: ((_b = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollLeft) + 392,
                                    behavior: "smooth",
                                });
                            }, style: {
                                display: rightVisible ? "flex" : "none",
                            } }, { children: _jsx(IconRight, {}) })), _jsx("div", __assign({ className: "scrollablemediacard-scrollable", ref: scrollRef }, { children: _jsx("div", __assign({ className: "scrollablemediacard-videos-wrapper" }, { children: videos.map(function (video, key) { return (_jsxs("div", __assign({ className: "scrollablemediacard-video-box" }, { children: [_jsx("div", __assign({ className: "scrollablemediacard-video" }, { children: _jsxs("video", __assign({ 
                                                // poster=""
                                                onMouseOver: function () {
                                                    return setActiveKey(key);
                                                }, onMouseOut: function () {
                                                    return setActiveKey(null);
                                                }, onTouchStart: function () {
                                                    return setActiveKey(key);
                                                }, controls: activeKey === key, playsInline: true, width: "100%", height: "100%" }, { children: [_jsx("source", { src: getVideoUrl(video) +
                                                            "#t=0.1", type: "video/mp4" }), "Your browser does not support the video tag."] })) })), video[titleKey] ? (_jsxs("div", __assign({ className: "scrollablemediacard-title" }, { children: [" ", _jsx("div", { children: video[titleKey] || "" })] }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "scrollablemediacard-description" }, { children: _jsx(KClampBox, { text: video[descriptionKey] || "", buttonColor: "var(--app-loadeduser-color)" }) }))] }), key)); }) })) }))] }))] }))) : (_jsx(_Fragment, {})) }));
};
ScrollableVideoCard.propTypes = {};
ScrollableVideoCard.defaultProps = {};
export default ScrollableVideoCard;
