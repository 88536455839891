var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import PropTypes from "prop-types";
import { MdLocationOn as IconLocation } from "react-icons/md";
import { MdDateRange as IconDate } from "react-icons/md";
import { MdInfo as IconInfo } from "react-icons/md";
import { getProjectDate, isFilmProject, isScriptProject, } from "../../tools/projectTools";
import { formLogoUrl, getHourMinuteDuration, } from "../../tools/generalTools";
import "./ProjectInfoCard.css";
var ProjectInfoCard = function (_a) {
    var project = _a.project;
    var getWebsiteUrl = function (website) {
        return website.slice(0, 4) === "http" ? website : "https://".concat(website);
    };
    return (_jsxs("div", __assign({ className: "projectinfocard-container" }, { children: [_jsxs("div", __assign({ className: "projectinfocard-tags" }, { children: [_jsx("div", __assign({ className: "projectinfocard-tag", style: {
                            display: project.language_code ? "block" : "none",
                        } }, { children: project.language_code })), _jsx("div", __assign({ className: "projectinfocard-tag", style: {
                            display: isScriptProject(project.project_type_name) &&
                                project.project_pages
                                ? "block"
                                : "none",
                        } }, { children: project.project_pages + " pages" })), _jsx("div", __assign({ className: "projectinfocard-tag", style: {
                            display: isFilmProject(project.project_type_name) &&
                                project.project_duration
                                ? "block"
                                : "none",
                        } }, { children: getHourMinuteDuration(project.project_duration) }))] })), _jsxs("div", __assign({ className: "projectinfocard-entry" }, { children: [_jsx("div", __assign({ className: "projectinfocard-entry-icon-container" }, { children: _jsx(IconLocation, { className: "projectinfocard-entry-icon" }) })), _jsx("div", __assign({ className: "projectinfocard-entry-text" }, { children: project.country_name || "Country not set" }))] })), _jsxs("div", __assign({ className: "projectinfocard-entry" }, { children: [_jsx("div", __assign({ className: "projectinfocard-entry-icon-container" }, { children: _jsx(IconInfo, { className: "projectinfocard-entry-icon" }) })), _jsxs("div", __assign({ className: "projectinfocard-entry-text" }, { children: [project.project_genre_name || "Genre not set", _jsx("br", {}), (project.project_kind_name || "") +
                                " " +
                                (project.project_subtype_name || "Type not set")] }))] })), _jsxs("div", __assign({ className: "projectinfocard-entry" }, { children: [_jsx("div", __assign({ className: "projectinfocard-entry-icon-container" }, { children: _jsx(IconDate, { className: "projectinfocard-entry-icon" }) })), _jsxs("div", __assign({ className: "projectinfocard-entry-text" }, { children: [project.project_stage_name || "Stage not set", _jsx("br", {}), getProjectDate(project)] }))] })), project.productionCompanyName ||
                project.coProductionCompanyName ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "projectinfocard-production-title" }, { children: "Production" })), project.productionCompanyName ? (_jsx("a", __assign({ className: "projectinfocard-entry-action", href: getWebsiteUrl(project.productionCompanyWebsite), target: "_blank" }, { children: _jsxs("div", __assign({ className: "projectinfocard-entry" }, { children: [project.productionCompanyWebsite ? (_jsx("div", __assign({ className: "projectinfocard-entry-image-container" }, { children: _jsx("img", { src: formLogoUrl(project.productionCompanyWebsite) }) }))) : (_jsx("div", { className: "projectinfocard-padding-box" })), _jsxs("div", __assign({ className: "projectinfocard-entry-text" }, { children: [_jsx("span", { children: project.productionCompanyName }), _jsx("br", {}), _jsx("div", __assign({ className: "projectinfocard-entry-subtitle" }, { children: "Production" }))] }))] })) }))) : (_jsx(_Fragment, {})), project.coProductionCompanyName ? (_jsx("a", __assign({ className: "projectinfocard-entry-action", href: getWebsiteUrl(project.coProductionCompanyWebsite), target: "_blank" }, { children: _jsxs("div", __assign({ className: "projectinfocard-entry" }, { children: [project.coProductionCompanyWebsite ? (_jsx("div", __assign({ className: "projectinfocard-entry-image-container" }, { children: _jsx("img", { src: formLogoUrl(project.coProductionCompanyWebsite) }) }))) : (_jsx("div", { className: "projectinfocard-padding-box" })), _jsxs("div", __assign({ className: "projectinfocard-entry-text" }, { children: [_jsx("span", { children: project.coProductionCompanyName }), _jsx("br", {}), _jsx("div", __assign({ className: "projectinfocard-entry-subtitle" }, { children: "Co-Production" }))] }))] })) }))) : (_jsx(_Fragment, {}))] })) : (_jsx(_Fragment, {}))] })));
};
ProjectInfoCard.propTypes = {
    project: PropTypes.object.isRequired,
};
ProjectInfoCard.defaultProps = {
    project: {},
};
export default ProjectInfoCard;
