var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { BsChevronCompactLeft as IconLeft } from "react-icons/bs";
import { BsChevronCompactRight as IconRight } from "react-icons/bs";
import "./KCarousel.css";
var KCarousel = function (_a) {
    var _b;
    var children = _a.children, _c = _a.flipDuration, flipDuration = _c === void 0 ? 7000 : _c;
    var indexRef = useRef(null);
    var itemsRef = useRef(null);
    var scrollRef = useRef(null);
    var _d = useState(0), index = _d[0], _setIndex = _d[1];
    var _e = useState([]), items = _e[0], _setItems = _e[1];
    var setIndex = function (data) {
        indexRef.current = data;
        _setIndex(data);
    };
    var setItems = function (data) {
        itemsRef.current = data;
        _setItems(data);
    };
    var incrementIndex = function () {
        return setIndex((itemsRef.current.length + indexRef.current + 1) %
            itemsRef.current.length);
    };
    var decrementIndex = function () {
        return setIndex((itemsRef.current.length + indexRef.current - 1) %
            itemsRef.current.length);
    };
    useEffect(function () {
        var _a;
        (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
            left: index * scrollRef.current.clientWidth,
            // behavior: "smooth", // casues issues in safari
        });
    }, [indexRef.current]);
    useEffect(function () {
        if (children.length)
            setItems(children);
        else
            setItems([]);
        var intervalKey = setInterval(incrementIndex, flipDuration);
        return function () {
            clearInterval(intervalKey);
        };
    }, []);
    return (_jsx(_Fragment, { children: ((_b = itemsRef.current) === null || _b === void 0 ? void 0 : _b.length) ? (_jsxs("div", __assign({ className: "kcarousel-wrapper" }, { children: [_jsx("div", __assign({ className: "kcarousel-container", ref: scrollRef }, { children: items.map(function (item, key) { return (_jsx("div", __assign({ className: "kcarousel-item" }, { children: item }), key)); }) })), items.length > 1 ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "kcarousel-control" }, { children: [_jsx("div", __assign({ className: "kcarousel-goleft", onClick: decrementIndex }, { children: _jsx(IconLeft, {}) })), _jsx("div", __assign({ className: "kcarousel-goright", onClick: incrementIndex }, { children: _jsx(IconRight, {}) }))] })), _jsx("div", __assign({ className: "kcarousel-status-box" }, { children: _jsx("div", __assign({ className: "kcarousel-status", style: { width: "".concat(items.length * 15, "px") } }, { children: items.map(function (item, key) { return (_jsx("div", { className: "kcarousel-indicator ".concat(index === key
                                        ? "kcarousel-active"
                                        : "") }, key)); }) })) }))] })) : (_jsx(_Fragment, {}))] }))) : (_jsx(_Fragment, {})) }));
};
export default KCarousel;
