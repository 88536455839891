import * as yup from "yup";
var phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export var contactusSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    surname: yup.string().required("Surname is required"),
    email: yup
        .string()
        .email("Wrong email format")
        .required("Email is required"),
    phoneNumber: yup
        .string()
        .matches(phoneRegExp, "Phone Number is not valid")
        .min(8, "Phone Number must be at least 8 characters")
        .max(15, "Phone Number must be at most 15 characters")
        .required("Phone Number is required"),
    subject: yup
        .string()
        .min(5, "Subject must be at least 5 characters")
        .max(50, "Subject must be at most 50 characters")
        .required("Subject is required"),
    message: yup
        .string()
        .min(20, "Message must be at least 20 characters")
        .required("Message is required"),
});
