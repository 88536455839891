var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { IoIosCloseCircle as IconClose } from "react-icons/io";
import "./KPopup.css";
var KPopup = function (_a) {
    var children = _a.children, onClose = _a.onClose, _b = _a.centered, centered = _b === void 0 ? false : _b, _c = _a.active, active = _c === void 0 ? false : _c, _d = _a.darken, darken = _d === void 0 ? false : _d, _e = _a.closable, closable = _e === void 0 ? true : _e;
    var _f = useState(active), isActive = _f[0], setIsActive = _f[1];
    var setActiveState = function (active) {
        if (!active && isActive)
            onClose();
        document.body.style.overflow = active ? "hidden" : "auto";
        setIsActive(active);
    };
    useEffect(function () {
        setActiveState(active);
    }, [active]);
    return (_jsx("div", __assign({ className: "kpopup-container ".concat(darken ? "kpopup-darken" : ""), style: { display: isActive ? "" : "none" } }, { children: _jsxs("div", __assign({ className: "kpopup-inner-container", style: { height: centered ? "" : "calc(100% - 142px)" } }, { children: [_jsx(IconClose, { className: "kpopup-iconclose", style: { display: closable ? "" : "none" }, onClick: function (event) { return setActiveState(false); } }), children, _jsx("div", { className: "kpopup-margin" })] })) })));
};
export default KPopup;
