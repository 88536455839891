var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import "./ExtraInfo.css";
var ExtraInfo = function () { return (_jsxs("div", __assign({ className: "extrainfo-section" }, { children: [_jsx("span", { children: _jsx(Link, __assign({ to: "/about" }, { children: "About Us" })) }), " • ", _jsx("span", { children: _jsx(Link, __assign({ to: "/termsofservice" }, { children: "Terms of Service" })) }), " • ", _jsx("span", { children: _jsx(Link, __assign({ to: "/contactus" }, { children: "Contact Us" })) }), _jsx("br", {}), "Scriptofilm @", new Date().getFullYear(), ". All rights reserved"] }))); };
export default ExtraInfo;
