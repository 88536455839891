var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import lodash from "lodash";
import "./KClampBox.css";
var KClampBox = function (_a) {
    var _b = _a.text, text = _b === void 0 ? "Some text goes here." : _b, _c = _a.buttonColor, buttonColor = _c === void 0 ? "var(--app-primary-color)" : _c, _d = _a.lineClamp, lineClamp = _d === void 0 ? "3" : _d, _e = _a.key, key = _e === void 0 ? null : _e;
    var containerRef = useRef(key);
    var _f = useState(true), clamped = _f[0], setClamped = _f[1];
    var _g = useState(true), showButton = _g[0], setShowButton = _g[1];
    var handleClick = function () { return setClamped(!clamped); };
    useEffect(function () {
        var hasClamping = function (el) {
            var clientHeight = el.clientHeight, scrollHeight = el.scrollHeight;
            console.log(clientHeight, scrollHeight);
            return clientHeight < scrollHeight;
        };
        var checkButtonAvailability = function () {
            if (containerRef.current) {
                // Save current state to reapply later if necessary.
                var hadClampClass = containerRef.current.classList.contains("kclampbox-clamped");
                // Make sure that CSS clamping is applied if aplicable.
                if (!hadClampClass)
                    containerRef.current.classList.add("kclampbox-clamped");
                // Check for clamping and show or hide button accordingly.
                setShowButton(hasClamping(containerRef.current));
                // Sync clamping with local state.
                if (!hadClampClass)
                    containerRef.current.classList.remove("kclampbox-clamped");
            }
        };
        var debouncedCheck = lodash.debounce(checkButtonAvailability, 50);
        checkButtonAvailability();
        window.addEventListener("resize", debouncedCheck);
        return function () {
            window.removeEventListener("resize", debouncedCheck);
        };
    }, [containerRef]);
    return (_jsxs("div", __assign({ className: "kclampbox-container ".concat(showButton && "kclampbox-button-shown") }, { children: [_jsx("div", __assign({ className: "kclampbox-text ".concat(clamped && "kclampbox-clamped"), style: { WebkitLineClamp: lineClamp }, ref: containerRef }, { children: text })), _jsxs("div", __assign({ className: "kclampbox-button", style: {
                    display: showButton ? "block" : "none",
                    color: buttonColor,
                }, onClick: handleClick }, { children: ["Read ", clamped ? "more >>" : "less <<"] }))] })));
};
export default KClampBox;
