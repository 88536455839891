var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { loadUsersInProgress, loadUsersSuccess, loadUsersFailure, loadUserByUserIdSuccess, loadUserByProjectIdSuccess, loadUserInProgress, loadUserFailure, loadInterestedUsersInProgress, loadInterestedUsersSuccess, loadInterestedUsersFailure, updateUserFailure, updateUserSuccess, updateUserInProgress, loadMessagingUsersInProgress, loadMessagingUsersSuccess, loadMessagingUsersFailure, appendUsersSuccess, appendUsersInProgress, loadUserTitleSuccess, loadIsReportedSuccess, loadIsReportedInProgress, loadIsReportedFailure, submitReportSuccess, submitReportFailure, submitReportInProgress, loadProfileViewsInProgress, loadProfileViewsUserIdSuccess, loadProfileViewsFailure, addRatingFailure, addRatingSuccess, addRatingProgress, loadUserRatingByUserFailure, loadUserRatingByUserSuccess, loadUserRatingByUserInProgress, loadUserRatingAverageFailure, loadUserRatingAverageSuccess, loadUserRatingAverageInProgress, loadProjectViewsFailure, loadProjectViewsByProjectIdSuccess, loadProjectViewsInProgress, loadContractUsersSuccess, loadLawyersSuccess, appendLawyersSuccess, updateCoverImagesInProgress, updateCoverImagesFailure, loadInterestedCowritersSuccess, loadShameUsersInProgress, loadShameUsersSuccess, loadShameUsersFailure, loadDcpSignedUrlSuccess, loadDcpSignedUrlFailure, loadDcpSignedUrlInProgress, loadUploadedDcpInProgress, loadUploadedDcpSuccess, loadUploadedDcpFailure, } from "../actions/userActions";
import { resizeAsync, dataURItoBlob } from "../../imaging/imageTransform";
import { getUserAuthenticated, getUserId } from "../selectors/authSelectors";
import { getErrorMessage, displayAlert, handleError } from "../utils";
import defaultApi from "../../apis/defaultApi";
import userApi from "../../apis/userApi";
import profileViewApi from "../../apis/profileViewApi";
import projectViewApi from "../../apis/projectViewApi";
import userRatingApi from "../../apis/userRatingApi";
import fileApi from "../../apis/fileApi";
import { getUserUserInterests } from "../selectors/userSelectors";
import { getEditedObjects, getUnEditedObjectIds, uploadFiles, } from "../../tools/fileTools";
import KFilesUploader from "../../base-components/KFilesUploader/KFilesUploader";
import { setProgress } from "../actions/generalActions";
export var thunkSubmitReport = function (targetId, reportDescription) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!targetId)
                    return [2 /*return*/];
                dispatch(submitReportInProgress());
                return [4 /*yield*/, userApi
                        .post("/report", {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                        data: { targetId: targetId, description: reportDescription, typeId: 1 }, // todo change typeId with select
                    })
                        .then(function (res) {
                        dispatch(submitReportSuccess(res.data));
                    })
                        .catch(function (err) {
                        dispatch(submitReportFailure(getErrorMessage(err)));
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadReported = function (targetId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!targetId)
                    return [2 /*return*/];
                dispatch(loadIsReportedInProgress());
                return [4 /*yield*/, userApi
                        .get("/report/".concat(targetId))
                        .then(function (res) {
                        dispatch(loadIsReportedSuccess(res.data.isReported));
                    })
                        .catch(function (err) {
                        dispatch(loadIsReportedFailure(getErrorMessage(err)));
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadUsers = function (searchText, limit, offset, reload, searchOptions, lookingfor, callback) {
    if (limit === void 0) { limit = 10; }
    if (offset === void 0) { offset = 0; }
    if (reload === void 0) { reload = true; }
    if (searchOptions === void 0) { searchOptions = {}; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _e.label = 1;
                case 1:
                    _e.trys.push([1, 3, , 4]);
                    if (reload)
                        dispatch(loadUsersInProgress());
                    else
                        dispatch(appendUsersInProgress());
                    return [4 /*yield*/, userApi.get("/all/", {
                            params: {
                                searchText: searchText,
                                limit: limit,
                                offset: offset,
                                lookingfor: lookingfor,
                                userTitleIds: ((_a = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.userTitleIds) === null || _a === void 0 ? void 0 : _a.length)
                                    ? JSON.stringify(searchOptions.userTitleIds)
                                    : null,
                                languageIds: ((_b = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.languageIds) === null || _b === void 0 ? void 0 : _b.length)
                                    ? JSON.stringify(searchOptions.languageIds)
                                    : null,
                                countryIds: ((_c = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.countryIds) === null || _c === void 0 ? void 0 : _c.length)
                                    ? JSON.stringify(searchOptions.countryIds)
                                    : null,
                                personalized: (_d = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.personalized) === null || _d === void 0 ? void 0 : _d[0],
                                userInterests: JSON.stringify(getUserUserInterests(getState())),
                            },
                        })];
                case 2:
                    response = _e.sent();
                    // used this to register all users
                    // for (const user of response.data){
                    //     kachat.registerUser(`${user.user_first_name} ${user.user_last_name}`, user.user_email, user.user_password, '', (err, kachatId) => {})
                    // }
                    if (reload)
                        dispatch(loadUsersSuccess(response.data));
                    else
                        dispatch(appendUsersSuccess(response.data));
                    callback && callback();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _e.sent();
                    dispatch(loadUsersFailure(getErrorMessage(err_1)));
                    dispatch(displayAlert(err_1));
                    callback && callback(err_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkLoadUsersByIds = function (kachatIds) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var remainingIds, data, response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                /** TODO: Load in batches just like network and market pages
                 * Currently patched to combine the users as number of threads
                 * in the messaging page should be reasonably low.
                 */
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                remainingIds = kachatIds;
                dispatch(loadMessagingUsersInProgress());
                data = [];
                _a.label = 2;
            case 2:
                if (!remainingIds.length) return [3 /*break*/, 4];
                return [4 /*yield*/, userApi.get("/ukids/", {
                        params: { user_kachat_ids: remainingIds.splice(0, 100) },
                    })];
            case 3:
                response = _a.sent();
                data.push.apply(data, response.data);
                return [3 /*break*/, 2];
            case 4:
                dispatch(loadMessagingUsersSuccess(data));
                return [3 /*break*/, 6];
            case 5:
                err_2 = _a.sent();
                dispatch(loadMessagingUsersFailure(err_2));
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadUsersByAuthIds = function (authIds) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadMessagingUsersInProgress());
                return [4 /*yield*/, userApi.get("/authids/", {
                        params: { authIds: authIds },
                    })];
            case 2:
                response = _a.sent();
                dispatch(loadContractUsersSuccess(response.data));
                return [3 /*break*/, 4];
            case 3:
                err_3 = _a.sent();
                dispatch(loadMessagingUsersFailure(err_3));
                // dispatch(displayAlert(err));
                handleError(err_3);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkGetUserTitle = function (email) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(loadUserInProgress());
        defaultApi
            .get("/usertitle/".concat(email))
            .then(function (res) {
            dispatch(loadUserTitleSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(loadUserFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadInterestedUsers = function (userTitleId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadInterestedUsersInProgress());
        userApi
            .get("/lookingfor/".concat(userTitleId))
            .then(function (res) {
            dispatch(loadInterestedUsersSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(loadInterestedUsersFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadInterestedCowriters = function (userTitleId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadInterestedUsersInProgress());
        userApi
            .get("/lookingfor/".concat(userTitleId, "?cowriters=true"))
            .then(function (res) {
            dispatch(loadInterestedCowritersSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(loadInterestedUsersFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadUserByUserId = function (userId, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    dispatch(loadUserInProgress());
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, userApi.get("/".concat(userId))];
                case 2:
                    res = _a.sent();
                    dispatch(loadUserByUserIdSuccess(res.data));
                    callback(null, res.data);
                    return [3 /*break*/, 4];
                case 3:
                    err_4 = _a.sent();
                    dispatch(loadUserFailure(getErrorMessage(err_4)));
                    handleError(err_4);
                    callback(err_4);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkGetUserByUserId = function (userId, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, userApi.get("/".concat(userId))];
                case 2:
                    res = _a.sent();
                    callback(null, res.data);
                    return [3 /*break*/, 4];
                case 3:
                    err_5 = _a.sent();
                    handleError(err_5);
                    callback(err_5);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkUnLoadUser = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadUserByUserIdSuccess({}));
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadProfileViewsByUserId = function (userId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadProfileViewsInProgress());
        profileViewApi
            .get("/".concat(userId, "/views"))
            .then(function (res) {
            dispatch(loadProfileViewsUserIdSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(loadProfileViewsFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadUserByProjectId = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadUserInProgress());
        userApi
            .get("/pid/".concat(projectId))
            .then(function (res) {
            dispatch(loadUserByProjectIdSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(loadUserFailure(getErrorMessage(err)));
            handleError(err);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadProjectViewsByProjectId = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadProjectViewsInProgress());
        projectViewApi
            .get("/".concat(projectId, "/views"))
            .then(function (res) {
            dispatch(loadProjectViewsByProjectIdSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(loadProjectViewsFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkUpdateUserCoverImage = function (file) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var formData, largeSize, smallSize, largeDataUrl, smallDataUrl, response, filepaths, res, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                if (!file)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                dispatch(updateUserInProgress());
                formData = new FormData();
                formData.append("type", "cover");
                formData.append("user", getUserId(getState()));
                largeSize = parseInt(process.env.LARGE_IMAGE_SIZE);
                smallSize = parseInt(process.env.SMALL_IMAGE_SIZE);
                return [4 /*yield*/, resizeAsync(file, largeSize, largeSize)];
            case 2:
                largeDataUrl = _a.sent();
                return [4 /*yield*/, resizeAsync(file, smallSize, smallSize)];
            case 3:
                smallDataUrl = _a.sent();
                formData.append("file", dataURItoBlob(largeDataUrl));
                formData.append("file", dataURItoBlob(smallDataUrl));
                return [4 /*yield*/, fileApi.post("/upload", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    })];
            case 4:
                response = _a.sent();
                filepaths = response.data.filepaths;
                return [4 /*yield*/, userApi.put("/updatecover", {
                        headers: { "Content-Type": "application/json" },
                        method: "PUT",
                        data: { coverPath: filepaths[0], smallCoverPath: filepaths[1] },
                    })];
            case 5:
                res = _a.sent();
                dispatch(updateUserSuccess(res.data));
                dispatch(loadUserByUserIdSuccess(res.data));
                return [3 /*break*/, 7];
            case 6:
                err_6 = _a.sent();
                dispatch(updateUserFailure(getErrorMessage(err_6)));
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); }; };
export var thunkAddRatingByUser = function (userId, rating) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(addRatingProgress());
        userRatingApi
            .post("/".concat(userId), {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            data: __assign({}, rating),
        })
            .then(function (res) {
            dispatch(addRatingSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(addRatingFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadUserRatingByUserId = function (userId, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!getUserAuthenticated(getState()))
                return [2 /*return*/];
            dispatch(loadUserRatingByUserInProgress());
            userRatingApi
                .get("/".concat(userId, "/ratingByUser"))
                .then(function (res) {
                dispatch(loadUserRatingByUserSuccess(res.data));
                callback(null, res.data);
            })
                .catch(function (err) {
                dispatch(loadUserRatingByUserFailure(getErrorMessage(err)));
                callback(err);
            });
            return [2 /*return*/];
        });
    }); };
};
export var thunkLoadUserRatingAverage = function (userId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadUserRatingAverageInProgress());
        userRatingApi
            .get("/".concat(userId, "/average"))
            .then(function (res) {
            dispatch(loadUserRatingAverageSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(loadUserRatingAverageFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadLawyers = function (searchText, limit, offset, reload) {
    if (searchText === void 0) { searchText = ""; }
    if (limit === void 0) { limit = 10; }
    if (offset === void 0) { offset = 0; }
    if (reload === void 0) { reload = true; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    if (reload)
                        dispatch(loadUsersInProgress());
                    else
                        dispatch(appendUsersInProgress());
                    return [4 /*yield*/, userApi.get("/all/", {
                            params: {
                                searchText: searchText,
                                limit: limit,
                                offset: offset,
                                userTitleIds: [6],
                                languageIds: null,
                                countryIds: null,
                                personalized: false,
                                detailed: true,
                                includeLawyers: true,
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (reload)
                        dispatch(loadLawyersSuccess(response.data));
                    else
                        dispatch(appendLawyersSuccess(response.data));
                    return [3 /*break*/, 4];
                case 3:
                    err_7 = _a.sent();
                    dispatch(loadUsersFailure(getErrorMessage(err_7)));
                    dispatch(displayAlert(err_7));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkCreateUserCoverImages = function (coverImagesArray, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var coverImageIdsToKeep, userId, coverImagesToAdd, err_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                dispatch(updateCoverImagesInProgress());
                coverImageIdsToKeep = getUnEditedObjectIds(coverImagesArray);
                userId = getUserId(getState());
                return [4 /*yield*/, uploadFiles(getEditedObjects(coverImagesArray), userId, "user-cover-images", true)];
            case 1:
                coverImagesToAdd = _a.sent();
                return [4 /*yield*/, userApi.put("/coverimages", {
                        headers: { "Content-Type": "application/json" },
                        method: "PUT",
                        data: {
                            coverImagesToAdd: coverImagesToAdd,
                            coverImageIdsToKeep: coverImageIdsToKeep,
                            userId: userId,
                        },
                    })];
            case 2:
                _a.sent();
                callback();
                dispatch(thunkLoadUserByUserId(userId));
                return [3 /*break*/, 4];
            case 3:
                err_8 = _a.sent();
                getErrorMessage(err_8);
                dispatch(updateCoverImagesFailure());
                callback(err_8);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkUploadUserVideo = function (file) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var userId_1, uploader;
    return __generator(this, function (_a) {
        try {
            dispatch(setProgress(0.1));
            userId_1 = getUserId(getState());
            uploader = new KFilesUploader(userId_1, process.env.STREAM_UPLOAD_URL, process.env.STREAM_UPLOAD_CHUNK);
            uploader.onProgress = function (progress) { return dispatch(setProgress(progress)); };
            uploader.onError = function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    dispatch(setProgress(0));
                    alert("Failed to publish your project");
                    return [2 /*return*/];
                });
            }); };
            uploader.onFinish = function (outputData) { return __awaiter(void 0, void 0, void 0, function () {
                var videoPath;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dispatch(setProgress(0));
                            videoPath = outputData[0].filepath;
                            return [4 /*yield*/, userApi.put("/video", {
                                    headers: { "Content-Type": "application/json" },
                                    method: "PUT",
                                    data: { userId: userId_1, videoPath: videoPath },
                                })];
                        case 1:
                            _a.sent();
                            dispatch(thunkLoadUserByUserId(userId_1));
                            return [2 /*return*/];
                    }
                });
            }); };
            uploader.addFile(file, "p1", "pitch", false);
            uploader.uploadFiles();
        }
        catch (err) {
            getErrorMessage(err);
        }
        return [2 /*return*/];
    });
}); }; };
export var thunkGetDcpSignedUrl = function (contractId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch(loadDcpSignedUrlInProgress());
                return [4 /*yield*/, userApi.get("/dcp/".concat(contractId))];
            case 1:
                res = _a.sent();
                dispatch(loadDcpSignedUrlSuccess(res.data));
                return [3 /*break*/, 3];
            case 2:
                err_9 = _a.sent();
                console.error(err_9);
                dispatch(loadDcpSignedUrlFailure());
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var thunkGetUploadedDcp = function (contractId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                dispatch(loadUploadedDcpInProgress());
                return [4 /*yield*/, userApi.get("/uploadedDcp/".concat(contractId))];
            case 1:
                res = _a.sent();
                dispatch(loadUploadedDcpSuccess(res.data));
                return [3 /*break*/, 3];
            case 2:
                err_10 = _a.sent();
                console.error(err_10);
                dispatch(loadUploadedDcpFailure());
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
export var thunkUploadDcp = function (file, receiverId, contractId, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var userId, uploader;
        return __generator(this, function (_a) {
            try {
                dispatch(setProgress(0.1));
                userId = getUserId(getState());
                file.name = "Dcp_".concat(contractId, "_").concat(receiverId, ".zip");
                uploader = new KFilesUploader(userId, process.env.STREAM_UPLOAD_URL, process.env.STREAM_UPLOAD_CHUNK);
                uploader.onProgress = function (progress) { return dispatch(setProgress(progress)); };
                uploader.onError = function (err) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        alert("Failed to upload DCP!");
                        callback(err);
                        return [2 /*return*/];
                    });
                }); };
                uploader.onFinish = function (outputData) { return __awaiter(void 0, void 0, void 0, function () {
                    var dcpPath, err_11;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                dcpPath = outputData[0].filepath;
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, userApi.post("/dcp", {
                                        headers: { "Content-Type": "application/json" },
                                        data: {
                                            receiverId: receiverId,
                                            contractId: contractId,
                                            dcpPath: dcpPath,
                                            duration: 600000000,
                                        },
                                    })];
                            case 2:
                                _a.sent();
                                dispatch(setProgress(0));
                                dispatch(thunkGetUploadedDcp(contractId));
                                callback(null);
                                return [3 /*break*/, 4];
                            case 3:
                                err_11 = _a.sent();
                                console.error(err_11);
                                dispatch(setProgress(0));
                                callback(err_11);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); };
                uploader.addFile(file, "dcp", "dcp", true);
                uploader.uploadFiles();
            }
            catch (err) {
                getErrorMessage(err);
                callback(err);
            }
            return [2 /*return*/];
        });
    }); };
};
export var thunkLoadShameUsers = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadShameUsersInProgress());
                return [4 /*yield*/, userApi.get("/shameList")];
            case 2:
                response = _a.sent();
                dispatch(loadShameUsersSuccess(response.data));
                return [3 /*break*/, 4];
            case 3:
                err_12 = _a.sent();
                dispatch(loadShameUsersFailure(getErrorMessage(err_12)));
                dispatch(displayAlert(err_12));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
