import * as yup from "yup";
export var knowYouSchema1 = yup.object().shape({
    firstName: yup
        .string()
        .required("First Name is required")
        .nullable()
        .trim(),
    lastName: yup.string().required("Last Name is required").nullable().trim(),
    company: yup
        .string()
        .required("Company name is required")
        .nullable()
        .trim(),
    position: yup
        .string()
        .required("Position name is required")
        .nullable()
        .trim(),
    bio: yup
        .string()
        .test("length", "Max of 60 words allowed", function (val) { return (val === null || val === void 0 ? void 0 : val.trim().split(" ").length) <= 60; })
        // .max(350, "Text should be less than 250 characters")
        .required("Text is required")
        .nullable()
        .trim(),
});
export var knowYouSchema2 = yup.object().shape({
    firstName: yup
        .string()
        .required("First Name is required")
        .nullable()
        .trim(),
    lastName: yup.string().required("Last Name is required").nullable().trim(),
    bio: yup
        .string()
        .test("length", "Max of 60 words allowed", function (val) { return (val === null || val === void 0 ? void 0 : val.trim().split(" ").length) <= 60; })
        // .max(350, "Text should be less than 250 characters")
        .required("Text is required")
        .nullable()
        .trim(),
});
