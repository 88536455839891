var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { Switch, Redirect, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import KNavRoute from "../../base-components/KNavRoute/KNavRoute";
import MarketPage from "../MarketPage/MarketPage";
import NetworkPage from "../NetworkPage/NetworkPage";
import ProfilePage from "../ProfilePage/ProfilePage";
import KNavBar from "../../base-components/KNavBar/KNavBar";
import useOutsideAlert from "../../hooks/useOutsideAlert";
import { thunkConnectKachat, thunkVerify } from "../../redux/thunks/authThunks";
import MessagingPage from "../MessagingPage/MessagingPage";
import { getUserId, getUserAuthenticated, getUser, getChatServiceMessage, getUserFlaggedNum, } from "../../redux/selectors/authSelectors";
import { thunkConnectNotifier, thunkDisconnectNotifier, } from "../../redux/thunks/notificationThunks";
import ErrorPage from "../ErrorPage/ErrorPage";
import UnauthorizedPage from "../UnauthorizedPage/UnauthorizedPage";
import ContractPage from "../ContractPage/ContractPage";
import ContractsPage from "../ContractsPage/ContractsPage";
import NotificationPage from "../NotificationPage/NotificationPage";
import ContractRequestPage from "../ContractRequestPage/ContractRequestPage";
import SubscriptionHistoryPage from "../SubscriptionHistoryPage/SubscriptionHistoryPage";
import PaymentMethodsPage from "../PaymentMethodsPage/PaymentMethodsPage";
import SubscriptionsPage from "../SubscriptionsPage/SubscriptionsPage";
import BoostsPage from "../BoostsPage/BoostsPage";
import BoostPage from "../BoostPage/BoostPage";
import Cookies from "universal-cookie";
import { canBoostProjects, canChooseSubscription, canCreateProjects, canViewContract, canViewMarket, canViewMessaging, canViewMyProjectsPage, canViewNetwork, canViewNotifications, canViewProfile, canViewTransactions, hasAdminAccess, hasPaymentMethods, } from "../../validators/profileValidator";
import { canCreateContract } from "../../validators/contractValidator";
import { thunkIsPaymentNeeded, thunkLoadContracts, } from "../../redux/thunks/contractThunks";
import { getContracts } from "../../redux/selectors/contractSelectors";
import { thunkStopLoading } from "../../redux/thunks/generalThunks";
import TransactionsPage from "../TransactionsPage/TransactionsPage";
import TransactionOptionsPage from "../TransactionOptionsPage/TransactionOptionsPage";
import { thunkIsBoostPaymentNeeded, thunkIsSubscriptionPaymentNeeded, thunkLoadUserBoostedProjects, } from "../../redux/thunks/projectThunks";
import { getUserBoostedProjects } from "../../redux/selectors/projectSelectors";
import ChatUserSelector from "../../components/ChatUserSelector/ChatUserSelector";
import MyProjectsPage from "../MyProjectsPage/MyProjectsPage";
import AccessRequestsPage from "../AccessRequestsPage/AccessRequestsPage";
import ReferalCard from "../../components/ReferalCard/ReferalCard";
import WallOfShamePage from "../WallOfShamePage/WallOfShamePage";
import AdminPage from "../AdminPage/AdminPage";
import KPopup from "../../base-components/KPopup/KPopup";
import { history } from "../../redux/store";
import { useLocation } from "react-router";
import kachat from "kachat";
import "./PlatformPage.css";
var BaseNavBar = function (_a) {
    var contracts = _a.contracts, isAuthenticated = _a.isAuthenticated, onVerify = _a.onVerify, _b = _a.variant, variant = _b === void 0 ? "primary" : _b;
    var cookies = new Cookies();
    var intervalRef = useRef(null);
    var _c = useState([]), listLinksData = _c[0], setListLinksData = _c[1];
    var profileLinksData = [
        { path: "/platform/profile", title: "My Profile" },
    ];
    var myProjectsLinksData = [
        { path: "/platform/myprojects", title: "My Projects" },
    ];
    var marketLinksData = [{ path: "/platform/market", title: "Market" }];
    var networkLinksData = [{ path: "/platform/network", title: "Network" }];
    var messagingLinksData = [
        { path: "/platform/messaging", title: "Messaging" },
    ];
    var contractLinksData = [
        { path: "/platform/contract", title: "Contracts" },
    ];
    var transactionLinksData = [
        { path: "/platform/transaction", title: "Transactions" },
    ];
    var updateLinksAndRoutes = function () { return __awaiter(void 0, void 0, void 0, function () {
        var showMarket, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, canViewMarket()];
                case 1:
                    showMarket = _a.sent();
                    setListLinksData([].concat(canViewProfile() ? profileLinksData : [], canViewMyProjectsPage() ? myProjectsLinksData : [], showMarket ? marketLinksData : [], canViewNetwork() ? networkLinksData : [], canViewMessaging() ? messagingLinksData : [], canViewContract() ? contractLinksData : [], canViewTransactions() ? transactionLinksData : []));
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var lastToken = cookies.get("token");
        var tokenCheck = setInterval(function () {
            var token = cookies.get("token");
            if (token !== lastToken) {
                onVerify(function () {
                    var token = cookies.get("token");
                    lastToken = token;
                    updateLinksAndRoutes();
                });
            }
        }, [2000]);
        return function cleanup() {
            clearInterval(tokenCheck);
        };
    }, []);
    useEffect(function () {
        console.log("Rechecking.."); // hack to reload coorect links
        updateLinksAndRoutes();
    }, [isAuthenticated]);
    useEffect(function () {
        clearInterval(intervalRef.current);
        if (isAuthenticated) {
            // check if user is still verified (to force logout a user)
            if (intervalRef.current)
                clearInterval(intervalRef.current);
            intervalRef.current = setInterval(function () {
                if (isAuthenticated &&
                    1000 * cookies.get("token-expiry") - new Date().getTime() <
                        0)
                    onVerify();
            }, 5000);
        }
        return function cleanup() {
            clearInterval(intervalRef.current);
        };
    }, [isAuthenticated]);
    useEffect(function () {
        updateLinksAndRoutes();
    }, [contracts === null || contracts === void 0 ? void 0 : contracts.length]);
    return (_jsx(_Fragment, { children: _jsx(KNavBar, { variant: variant, listLinksData: listLinksData, listButtonsData: [] }) }));
};
var mapNavStateToProps = function (state) { return ({
    contracts: getContracts(state),
    isAuthenticated: getUserAuthenticated(state),
}); };
var mapNavDispatchToProps = function (dispatch) { return ({
    onVerify: function (callback) { return dispatch(thunkVerify(callback)); },
}); };
export var LoggedInNavBar = connect(mapNavStateToProps, mapNavDispatchToProps)(BaseNavBar);
var PlatformPage = function (_a) {
    var onVerify = _a.onVerify, userId = _a.userId, authUser = _a.authUser, contracts = _a.contracts, userBoosts = _a.userBoosts, flaggedNum = _a.flaggedNum, chatErrorMessage = _a.chatErrorMessage, onLoadContracts = _a.onLoadContracts, onLoadUserBoosts = _a.onLoadUserBoosts, onConnectNotifier = _a.onConnectNotifier, onDisconnectNotifier = _a.onDisconnectNotifier, onConnectChatService = _a.onConnectChatService, onStopLoading = _a.onStopLoading, onCheckPaymentNeeded = _a.onCheckPaymentNeeded, onCheckBoostPaymentNeeded = _a.onCheckBoostPaymentNeeded, onCheckSubscriptionPaymentNeeded = _a.onCheckSubscriptionPaymentNeeded;
    var cookies = new Cookies();
    var location = useLocation();
    var outsideClickRef = useRef(null);
    var query = new URLSearchParams(location.search);
    var _b = useRouteMatch(), path = _b.path, url = _b.url;
    var _c = useState(false), isPaymentNeeded = _c[0], setIsPaymentNeeded = _c[1];
    var _d = useState(new Date().getTime()), timeToVerify = _d[0], setTimeToVerify = _d[1];
    var redirectRouteData = [
        {
            path: "".concat(path),
            component: Redirect,
            props: { to: "/" },
            exact: true,
        },
    ];
    var marketRouteData = [
        {
            path: "".concat(path, "/market"),
            component: MarketPage,
            props: { showMarket: canViewMarket() },
            exact: false,
        },
    ];
    var networkRouteData = [
        {
            path: "".concat(path, "/network"),
            component: NetworkPage,
            props: { showNetwork: canViewNetwork() },
            exact: false,
        },
    ];
    var messagingRouteData = [
        {
            path: "".concat(path, "/messaging"),
            component: MessagingPage,
            props: {},
            exact: true,
        },
        {
            path: "".concat(path, "/messaging/:chId"),
            component: MessagingPage,
            props: {},
            exact: false,
        },
    ];
    var profileRouteData = [
        {
            path: "".concat(path, "/profile"),
            component: ProfilePage,
            props: { userId: userId },
            exact: false,
        },
    ];
    var myProjectsRouteData = [
        {
            path: "".concat(path, "/myprojects"),
            component: MyProjectsPage,
            props: { userId: userId },
            exact: false,
        },
    ];
    var contractRouteData = [
        {
            path: "".concat(path, "/contract"),
            component: ContractsPage,
            props: {},
            exact: true,
        },
        {
            path: "".concat(path, "/contract/:contractId"),
            component: ContractPage,
            props: {},
            exact: false,
        },
    ];
    var contractRequestRouteData = [
        {
            path: "".concat(path, "/contract-request"),
            component: ContractRequestPage,
            props: {},
            exact: false,
        },
    ];
    var transactionRouteData = [
        {
            path: "".concat(path, "/transaction"),
            component: TransactionsPage,
            props: {},
            exact: true,
        },
        {
            path: "".concat(path, "/transaction/:transactionId"),
            component: TransactionOptionsPage,
            props: {},
            exact: false,
        },
    ];
    var wallOfShameRouteData = [
        {
            path: "".concat(path, "/wallOfShame"),
            component: WallOfShamePage,
            props: {},
            exact: false,
        },
    ];
    var notificationRouteData = [
        {
            path: "".concat(path, "/notification"),
            component: NotificationPage,
            props: {},
            exact: false,
        },
    ];
    var errorRouteData = [
        {
            path: "".concat(path, "/error"),
            component: ErrorPage,
            props: {},
            exact: false,
        },
    ];
    var boostRouteData = [
        {
            path: "".concat(path, "/boosts/new"),
            component: BoostPage,
            props: {},
            exact: false,
        },
    ];
    var boostsRouteData = [
        {
            path: "".concat(path, "/boosts"),
            component: BoostsPage,
            props: {},
            exact: false,
        },
    ];
    var subscriptionsRouteData = [
        {
            path: "".concat(path, "/subscriptions"),
            component: SubscriptionsPage,
            props: {},
            exact: false,
        },
    ];
    var subscriptionsHistoryRouteData = [
        {
            path: "".concat(path, "/subscriptions/history"),
            component: SubscriptionHistoryPage,
            props: {},
            exact: false,
        },
    ];
    var paymentMethodsRouteData = [
        {
            path: "".concat(path, "/paymentMethod"),
            component: PaymentMethodsPage,
            props: {},
            exact: false,
        },
    ];
    var accessRequestsRouteData = [
        {
            path: "".concat(path, "/accessRequest"),
            component: AccessRequestsPage,
            props: {},
            exact: false,
        },
    ];
    var adminRouteData = [
        {
            path: "".concat(path, "/admin"),
            component: AdminPage,
            props: {},
            exact: false,
        },
    ];
    var unauthorizedRouteData = [
        {
            path: "".concat(path, "/unauthorized"),
            component: UnauthorizedPage,
            props: {},
            exact: false,
        },
    ];
    var notFoundRouteData = [
        {
            path: "".concat(path, "/*"),
            component: Redirect,
            props: { to: "/notfound" },
            exact: false,
        },
    ];
    var profileLinksData = [{ path: "".concat(url, "/profile"), title: "My Profile" }];
    var myProjectsLinksData = [
        { path: "".concat(url, "/myprojects"), title: "My Projects" },
    ];
    var marketLinksData = [{ path: "".concat(url, "/market"), title: "Market" }];
    var networkLinksData = [{ path: "".concat(url, "/network"), title: "Network" }];
    var messagingLinksData = [
        { path: "".concat(url, "/messaging"), title: "Messaging" },
    ];
    var contractLinksData = [{ path: "".concat(url, "/contract"), title: "Contracts" }];
    var transactionLinksData = [
        { path: "".concat(url, "/transaction"), title: "Transactions" },
    ];
    var listButtonsData = [];
    var _e = useState([]), listLinksData = _e[0], setListLinksData = _e[1];
    var _f = useState([]), listPlatformRoutesData = _f[0], setListPlatformRoutesData = _f[1];
    var updateTimeToVerify = function () {
        var now = new Date().getTime();
        setTimeToVerify(now + 0.5 * (1000 * cookies.get("token-expiry") - now));
    };
    var updateLinksAndRoutes = function () { return __awaiter(void 0, void 0, void 0, function () {
        var showMarket, err_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, canViewMarket()];
                case 1:
                    showMarket = _a.sent();
                    setListLinksData([].concat(canViewProfile() ? profileLinksData : [], canViewMyProjectsPage() ? myProjectsLinksData : [], showMarket ? marketLinksData : [], canViewNetwork() ? networkLinksData : [], canViewMessaging() ? messagingLinksData : [], canViewContract() ? contractLinksData : [], canViewTransactions() ? transactionLinksData : []));
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _a.sent();
                    console.log(err_2);
                    return [3 /*break*/, 3];
                case 3:
                    setListPlatformRoutesData([].concat(redirectRouteData, marketRouteData, networkRouteData, 
                    // canViewMarket() ? marketRouteData : [],
                    // canViewNetwork() ? networkRouteData : [],
                    canViewMessaging() ? messagingRouteData : [], canViewProfile() ? profileRouteData : [], canViewMyProjectsPage() ? myProjectsRouteData : [], canViewContract() ? contractRouteData : [], canCreateContract() ? contractRequestRouteData : [], canViewTransactions() ? transactionRouteData : [], canViewNotifications() ? notificationRouteData : [], canBoostProjects() ? boostRouteData : [], canBoostProjects() ? boostsRouteData : [], canChooseSubscription() ? subscriptionsHistoryRouteData : [], canChooseSubscription() ? subscriptionsRouteData : [], isPaymentNeeded && hasPaymentMethods()
                        ? paymentMethodsRouteData
                        : [], canCreateProjects() ? accessRequestsRouteData : [], hasAdminAccess() ? adminRouteData : [], errorRouteData, wallOfShameRouteData, unauthorizedRouteData, notFoundRouteData));
                    return [2 /*return*/];
            }
        });
    }); };
    var _g = useState(null), chatInterval = _g[0], setChatInterval = _g[1];
    useEffect(function () {
        if (authUser.user_id) {
            var setPaymentNeeded = function (paymentNeeded, err) {
                if (err) {
                    return console.log(err);
                }
                if (paymentNeeded)
                    setIsPaymentNeeded(paymentNeeded);
            };
            onCheckPaymentNeeded(setPaymentNeeded);
            onCheckBoostPaymentNeeded(setPaymentNeeded);
            onCheckSubscriptionPaymentNeeded(setPaymentNeeded);
        }
    }, [authUser]);
    useEffect(function () {
        if (!authUser)
            return;
        if (chatErrorMessage) {
            if (!chatInterval) {
                setChatInterval(setInterval(function () {
                    onConnectChatService(authUser);
                }, 15000));
            }
        }
        else if (chatInterval)
            setChatInterval(clearInterval(chatInterval));
    }, [chatErrorMessage, authUser]);
    useEffect(function () {
        var verify = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, onVerify()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        verify();
        updateTimeToVerify();
        onConnectNotifier();
        onLoadContracts();
        onLoadUserBoosts();
        updateLinksAndRoutes();
        return function cleanup() {
            onDisconnectNotifier();
        };
    }, []);
    useEffect(function () {
        updateLinksAndRoutes();
    }, [contracts === null || contracts === void 0 ? void 0 : contracts.length, userBoosts === null || userBoosts === void 0 ? void 0 : userBoosts.length, flaggedNum, isPaymentNeeded]);
    useOutsideAlert(outsideClickRef, function () { return __awaiter(void 0, void 0, void 0, function () {
        var now;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    now = new Date().getTime();
                    if (!(now > timeToVerify)) return [3 /*break*/, 2];
                    return [4 /*yield*/, onVerify()];
                case 1:
                    _a.sent();
                    updateTimeToVerify();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [timeToVerify]);
    useEffect(function () {
        onStopLoading();
    }, []);
    return (_jsxs("div", __assign({ className: "platformpage-container" }, { children: [_jsx("div", { ref: outsideClickRef }), _jsx(KPopup, __assign({ active: query.get("collaborate") === "true", onClose: function () { return history.push(location.pathname); } }, { children: _jsx(ChatUserSelector, { ownerId: query.get("ownerId"), directorId: query.get("directorId"), producerId: query.get("producerId"), onClose: function () { return history.push(location.pathname); }, onClick: function (user) {
                        var channelKey = "".concat(query.get("projectId"), "_").concat(user.user_kachat_id);
                        kachat.createNewGroupChat(query.get("projectTitle"), channelKey, [user.user_kachat_id]);
                        history.push("/platform/messaging");
                    } }) })), _jsx(KPopup, __assign({ active: query.get("refer") === "true", onClose: function () { return history.push(location.pathname); } }, { children: _jsx(ReferalCard, { onClose: function () { return history.push(location.pathname); } }) })), _jsx(KNavBar, { listLinksData: listLinksData, listButtonsData: listButtonsData }), _jsx("div", __assign({ className: "platformpage-body-container" }, { children: _jsx(Switch, { children: listPlatformRoutesData.map(function (routeData, key) { return (_jsx(KNavRoute, { path: routeData.path, component: routeData.component, componentProps: routeData.props, exact: routeData.exact }, key)); }) }) }))] })));
};
var mapStateToProps = function (state) { return ({
    authUser: getUser(state),
    userId: getUserId(state),
    contracts: getContracts(state),
    chatErrorMessage: getChatServiceMessage(state),
    flaggedNum: getUserFlaggedNum(state),
    userBoosts: getUserBoostedProjects(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onVerify: function (callback) { return dispatch(thunkVerify(callback)); },
    onConnectNotifier: function () { return dispatch(thunkConnectNotifier()); },
    onLoadContracts: function () { return dispatch(thunkLoadContracts()); },
    onLoadUserBoosts: function () { return dispatch(thunkLoadUserBoostedProjects()); },
    onDisconnectNotifier: function () { return dispatch(thunkDisconnectNotifier()); },
    onConnectChatService: function (user) { return dispatch(thunkConnectKachat(user)); },
    onStopLoading: function () { return dispatch(thunkStopLoading()); },
    onCheckPaymentNeeded: function (callback) {
        return dispatch(thunkIsPaymentNeeded(callback));
    },
    onCheckBoostPaymentNeeded: function (callback) {
        return dispatch(thunkIsBoostPaymentNeeded(callback));
    },
    onCheckSubscriptionPaymentNeeded: function (callback) {
        return dispatch(thunkIsSubscriptionPaymentNeeded(callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(PlatformPage);
