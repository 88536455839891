var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { contractCommentsLoadFailure, contractCommentsLoadSuccess, contractLoadFailure, contractLoadSuccess, contractNewCommentsKey, contractNewCommentsLoaded, contractRevsionsLoadSuccess, contractsLoadFailure, contractsLoading, contractsLoadingComplete, contractsLoadSuccess, contractTypesLoaded, requestSignaturesLoaded, signatureRequestLoaded, signatureRequestsByContractLoaded, transactionsLoadAllFailure, transactionsLoadAllSuccess, transactionsLoadFailure, transactionsLoading, transactionsLoadingComplete, transactionsLoadSuccess, transactionStatusSuccess, } from "../actions/contractActions";
import contractApi from "../../apis/contractApi";
import { getAuthId, getUser, getUserAuthenticated, getUserId, getUserTitle, } from "../selectors/authSelectors";
import fileApi from "../../apis/fileApi";
import storageApi, { getSignedUrl } from "../../apis/storageApi";
import { getFullName, isUserLawyer } from "../../tools/userTools";
import { canCreateSignatureRequest } from "../../validators/contractValidator";
import paymentApi from "../../apis/paymentApi";
import { getErrorMessage, handleError } from "../utils";
import { history } from "../store";
import { uploadFiles } from "../../tools/fileTools";
export var thunkLoadContractTypes = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .get("/contracttype/all")
            .then(function (res) {
            dispatch(contractTypesLoaded(res.data));
        })
            .catch(function (err) {
            console.log(err);
            dispatch(contractsLoadingComplete());
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkCreateContract = function (contractData, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var authUser, docProofFile, formData, response, proofPath;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(contractsLoading());
                authUser = getUser(getState());
                docProofFile = contractData.docProofFile;
                if (!docProofFile)
                    alert("Proof document required");
                formData = new FormData();
                formData.append("type", "budget-proof");
                formData.append("user", authUser.user_id);
                formData.append("file", docProofFile);
                return [4 /*yield*/, fileApi.post("/upload", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    })];
            case 1:
                response = _a.sent();
                proofPath = response.data.filepaths[0];
                contractApi
                    .post("/contract", {
                    data: __assign(__assign({ proofPath: proofPath }, contractData), { creatorId: getAuthId(getState()), imagePath: authUser.user_small_image_path }),
                })
                    .then(function (res) {
                    dispatch(contractsLoadingComplete());
                    callback(res.data);
                    // history.push(`/platform/contract/${res.data.id}`);
                })
                    .catch(function (err) {
                    var errMessage = getErrorMessage(err);
                    dispatch(contractsLoadingComplete());
                    callback(null, err);
                    // alert(errMessage);
                });
                return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadContracts = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .get("/contract/".concat(isUserLawyer(getUserTitle(getState())) ? "lawyer" : "user", "/").concat(getAuthId(getState())), {
            params: { limit: 100, offset: 0, searchText: "" },
        })
            .then(function (res) {
            dispatch(contractsLoadSuccess(res.data));
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadFailure());
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadContract = function (contractId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .get("/contract/".concat(contractId))
            .then(function (res) {
            dispatch(contractLoadSuccess(res.data));
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractLoadFailure());
            handleError(err);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadRevisions = function (contractId, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!getUserAuthenticated(getState()))
                return [2 /*return*/];
            dispatch(contractsLoading());
            contractApi
                .get("/revision/contract/".concat(contractId), {
                params: { limit: 100, offset: 0, searchText: "" },
            })
                .then(function (res) {
                dispatch(contractRevsionsLoadSuccess(res.data));
                callback(res.data);
            })
                .catch(function (err) {
                var errMessage = getErrorMessage(err);
                dispatch(contractsLoadingComplete());
                // handleError(err);
            });
            return [2 /*return*/];
        });
    }); };
};
export var thunkClearRevisions = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(contractRevsionsLoadSuccess([]));
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadRevisionComments = function (revisionId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_1, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(contractsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, contractApi.get("/comment/revision/".concat(revisionId), {
                        params: { limit: 100, offset: 0, searchText: "" },
                    })];
            case 2:
                res = _a.sent();
                dispatch(contractCommentsLoadSuccess(res.data));
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_1 = _a.sent();
                errMessage = getErrorMessage(err_1);
                dispatch(contractCommentsLoadFailure());
                alert(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkSendContractComment = function (revisionId, text) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var authUser;
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        authUser = getUser(getState());
        contractApi
            .post("/comment", {
            data: {
                userId: getAuthId(getState()),
                revisionId: revisionId,
                text: text,
                contractApi: contractApi,
                fullName: getFullName(authUser),
                imagePath: authUser.user_small_image_path,
            },
        })
            .then(function (res) {
            dispatch(contractsLoadingComplete());
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
var loadNewComments = function (revisionId, createdAfter, cancelTokenSource, dispatch, scrollRef) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_2;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 4, , 10]);
                console.log("Checking for new comments:", createdAfter);
                return [4 /*yield*/, contractApi.get("/comment/revision/".concat(revisionId, "/new"), {
                        // timeout: 200000,
                        params: {
                            createdAfter: createdAfter,
                        },
                        cancelToken: cancelTokenSource.token,
                    })];
            case 1:
                res = _c.sent();
                // console.log(res.config.headers);
                dispatch(contractNewCommentsLoaded(res.data));
                (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(0, Math.pow(10, 30));
                return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 500); })];
            case 2:
                _c.sent();
                console.log("Calling check for new comments again..");
                return [4 /*yield*/, loadNewComments(revisionId, res.data && res.data.length
                        ? res.data[res.data.length - 1].createdAt
                        : createdAfter, cancelTokenSource, dispatch, scrollRef)];
            case 3:
                _c.sent();
                return [3 /*break*/, 10];
            case 4:
                err_2 = _c.sent();
                getErrorMessage(err_2);
                if (!err_2.response && err_2.message === "cancel-contract-comment")
                    return [2 /*return*/];
                if (!(((_b = err_2.response) === null || _b === void 0 ? void 0 : _b.status) == 502)) return [3 /*break*/, 6];
                return [4 /*yield*/, loadNewComments(revisionId, createdAfter, cancelTokenSource, dispatch, scrollRef)];
            case 5:
                _c.sent();
                return [3 /*break*/, 9];
            case 6: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, 10000); })];
            case 7:
                _c.sent();
                return [4 /*yield*/, loadNewComments(revisionId, createdAfter, cancelTokenSource, dispatch, scrollRef)];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}); };
export var thunkLoadNewComments = function (revisionId, createdAfter, scrollRef, cancelTokenSource) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!getUserAuthenticated(getState()))
                return [2 /*return*/];
            dispatch(contractNewCommentsKey(Math.floor(Math.random() * 1e10)));
            loadNewComments(revisionId, createdAfter, cancelTokenSource, dispatch, scrollRef);
            return [2 /*return*/];
        });
    }); };
};
export var thunkCreateRevision = function (contractId, title, summary, file, callback) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var authId, userId, formData, response, filePath, authUser, res, err_3, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    if (!file)
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    dispatch(contractsLoading());
                    authId = getAuthId(getState());
                    userId = getUserId(getState());
                    formData = new FormData();
                    formData.append("type", "contract");
                    formData.append("user", userId);
                    formData.append("file", file);
                    formData.append("isPrivate", true);
                    return [4 /*yield*/, storageApi.post("/upload", formData, {
                            headers: { "Content-Type": "multipart/form-data" },
                        })];
                case 2:
                    response = _a.sent();
                    filePath = response.data.filepaths[0];
                    authUser = getUser(getState());
                    return [4 /*yield*/, contractApi.post("revision/", {
                            data: {
                                title: title,
                                summary: summary,
                                contractId: contractId,
                                lawyerId: authId,
                                docPath: filePath,
                                fullName: getFullName(authUser),
                                imagePath: authUser.user_small_image_path,
                            },
                        })];
                case 3:
                    res = _a.sent();
                    dispatch(contractsLoadingComplete());
                    dispatch(thunkLoadRevisions(contractId));
                    callback();
                    return [3 /*break*/, 5];
                case 4:
                    err_3 = _a.sent();
                    errMessage = getErrorMessage(err_3);
                    dispatch(contractsLoadingComplete());
                    alert(errMessage);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkCreateSignatureRequest = function (revisionId, docPath, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var signedUrl, authUser;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                return [4 /*yield*/, getSignedUrl(docPath, 200000)];
            case 1:
                signedUrl = _a.sent();
                authUser = getUser(getState());
                dispatch(contractsLoading());
                contractApi
                    .post("/signaturerequest", {
                    data: {
                        revisionId: revisionId,
                        fileUrl: signedUrl,
                        lawyerId: getAuthId(getState()),
                        fullName: getFullName(authUser),
                        imagePath: authUser.user_small_image_path,
                    },
                })
                    .then(function (res) {
                    dispatch(signatureRequestLoaded(res.data));
                    callback();
                })
                    .catch(function (err) {
                    var errMessage = getErrorMessage(err);
                    dispatch(contractsLoadingComplete());
                    alert(errMessage);
                });
                return [2 /*return*/];
        }
    });
}); }; };
export var thunkGetSignatureRequestsByContract = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()) || !canCreateSignatureRequest())
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .get("/signaturerequest/lawyer/".concat(getAuthId(getState())))
            .then(function (res) {
            dispatch(signatureRequestsByContractLoaded(res.data));
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadSignatureRequest = function (contractId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .get("/signaturerequest/contract/".concat(contractId))
            .then(function (res) {
            dispatch(signatureRequestLoaded(res.data));
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadRequestSignatures = function (requestId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .get("/signature/request/".concat(requestId))
            .then(function (res) {
            dispatch(requestSignaturesLoaded(res.data));
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkGetHelloSignUrl = function (signatureId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .get("signature/signurl/".concat(signatureId))
            .then(function (res) {
            dispatch(contractsLoadingComplete());
            callback(res.data.signUrl);
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkCompleteSignature = function (signatureId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var authUser;
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        authUser = getUser(getState());
        contractApi
            .put("signature/sign/".concat(signatureId), {
            data: {
                fullName: getFullName(authUser),
                imagePath: authUser.user_small_image_path,
            },
        })
            .then(function (res) {
            dispatch(contractsLoadingComplete());
            callback();
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkCompleteSignatureRequest = function (signatureRequestId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var authUser;
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        authUser = getUser(getState());
        contractApi
            .put("signaturerequest/complete/".concat(signatureRequestId), {
            data: {
                fullName: getFullName(authUser),
                imagePath: authUser.user_small_image_path,
            },
        })
            .then(function (res) {
            dispatch(signatureRequestLoaded(res.data));
            callback();
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkDeleteSignatureRequest = function (signatureRequestId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .delete("signaturerequest/".concat(signatureRequestId))
            .then(function (res) {
            dispatch(signatureRequestLoaded({}));
            callback();
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkUpdateSignedDoc = function (signatureRequestId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(contractsLoading());
        contractApi
            .put("signaturerequest/signeddoc/".concat(signatureRequestId))
            .then(function (res) {
            var _a;
            dispatch(signatureRequestLoaded(res.data));
            callback((_a = res.data) === null || _a === void 0 ? void 0 : _a.docPath);
        })
            .catch(function (err) {
            var errMessage = getErrorMessage(err);
            dispatch(contractsLoadingComplete());
            alert(errMessage);
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkCreateContractPaymentIntent = function (email, projectName, amount, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_4, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(contractsLoading());
                return [4 /*yield*/, paymentApi.post("/stripe/paymentIntent/contract/".concat(email), {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                        data: {
                            amount: amount,
                            projectName: projectName,
                        },
                    })];
            case 2:
                res = _a.sent();
                dispatch(contractsLoadingComplete());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_4 = _a.sent();
                errMessage = getErrorMessage(err_4);
                dispatch(contractsLoadingComplete());
                console.error(errMessage);
                callback(err_4);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkGetPercentageFee = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_5, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(contractsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, contractApi.get("/contract/fee")];
            case 2:
                res = _a.sent();
                dispatch(contractsLoadingComplete());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_5 = _a.sent();
                callback(null, err_5);
                errMessage = getErrorMessage(err_5);
                dispatch(contractsLoadingComplete());
                history.push("/platform/error");
                console.log(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkIsPaymentNeeded = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_6, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(contractsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, contractApi.get("/contract/needed")];
            case 2:
                res = _a.sent();
                dispatch(contractsLoadingComplete());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_6 = _a.sent();
                callback(null, err_6);
                errMessage = getErrorMessage(err_6);
                dispatch(contractsLoadingComplete());
                history.push("/platform/error");
                console.log(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkGetTransactionPercentageFee = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_7, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(transactionsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, contractApi.get("/transaction/fee")];
            case 2:
                res = _a.sent();
                dispatch(transactionsLoadingComplete());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_7 = _a.sent();
                callback(null, err_7);
                errMessage = getErrorMessage(err_7);
                dispatch(transactionsLoadFailure());
                history.push("/platform/error");
                console.log(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadTransactions = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_8, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(transactionsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, contractApi.get("/transaction/user/".concat(getAuthId(getState())), {
                        params: { limit: 100, offset: 0 },
                    })];
            case 2:
                res = _a.sent();
                dispatch(transactionsLoadSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_8 = _a.sent();
                errMessage = getErrorMessage(err_8);
                dispatch(transactionsLoadFailure());
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkMarkTransactionAsInitiated = function (transactionId, docProofFile, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var userId, proofPath, err_9, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    dispatch(transactionsLoading());
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    userId = getUserId(getState());
                    return [4 /*yield*/, uploadFiles([{ file: docProofFile }], userId, "transaction", false)];
                case 2:
                    proofPath = (_a.sent())[0].path;
                    return [4 /*yield*/, contractApi.put("/transaction/initiate/".concat(transactionId), {
                            data: { proofPath: proofPath },
                        })];
                case 3:
                    _a.sent();
                    dispatch(transactionStatusSuccess());
                    dispatch(callback());
                    return [3 /*break*/, 5];
                case 4:
                    err_9 = _a.sent();
                    errMessage = getErrorMessage(err_9);
                    dispatch(transactionsLoadFailure());
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
};
// for admin use
export var thunkLoadAllTransactions = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_10, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(transactionsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, contractApi.get("/transaction/all", {
                        params: { limit: 1000, offset: 0 },
                    })];
            case 2:
                res = _a.sent();
                dispatch(transactionsLoadAllSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_10 = _a.sent();
                errMessage = getErrorMessage(err_10);
                dispatch(transactionsLoadAllFailure());
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkMarkTransactionAsCompleted = function (transactionId, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var userId, err_11, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    dispatch(transactionsLoading());
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    userId = getUserId(getState());
                    return [4 /*yield*/, contractApi.put("/transaction/complete/".concat(transactionId))];
                case 2:
                    _a.sent();
                    dispatch(transactionStatusSuccess());
                    dispatch(callback());
                    return [3 /*break*/, 4];
                case 3:
                    err_11 = _a.sent();
                    errMessage = getErrorMessage(err_11);
                    dispatch(transactionsLoadFailure());
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkMarkTransactionAsRequested = function (transactionId, callback) {
    if (callback === void 0) { callback = function (f) { return f; }; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var userId, err_12, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    dispatch(transactionsLoading());
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    userId = getUserId(getState());
                    return [4 /*yield*/, contractApi.put("/transaction/request/".concat(transactionId))];
                case 2:
                    _a.sent();
                    dispatch(transactionStatusSuccess());
                    dispatch(callback());
                    return [3 /*break*/, 4];
                case 3:
                    err_12 = _a.sent();
                    errMessage = getErrorMessage(err_12);
                    dispatch(transactionsLoadFailure());
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
