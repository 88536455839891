var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SIGNIN_SUCCESS, SIGNIN_IN_PROGRESS, SIGNIN_FAILURE, UPDATE_USER_TOKEN, UNAUTHENTICATE_USER, CLEAR_USER_ERROR, CLEAR_USER_DATA, SET_TOKEN, SIGNUP_KACHAT_SUCCESS, FORGOT_PASS_SUCCESS, FORGOT_PASS_FAILURE, FORGOT_PASS_IN_PROGRESS, RESET_PASS_SUCCESS, RESET_PASS_IN_PROGRESS, RESET_PASS_FAILURE, CLEAR_USER_SIGNED_UP, STOP_AUTH_LOADING, SET_SIGNUP_INFO, START_AUTH_LOADING, SUBSCRIPTION_PRICES_SUCCESS, LOAD_USER_SUBSCRIPTIONS_SUCCESS, LOAD_USER_SUBSCRIPTION_SUCCESS, LOAD_PAYMENT_METHODS_SUCCESS, LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS, CHAT_CONNECT_FAILURE, CHAT_CONNECT_SUCCESS, } from "../actions/authActions";
import { UPDATE_USER_SUCCESS, UPDATE_USER_IN_PROGRESS, UPDATE_USER_FAILURE, LOAD_PROJECT_BOOSTS_FAILURE, LOAD_PROJECT_BOOSTS_IN_PROGRESS, LOAD_PROJECT_BOOSTS_SUCCESS, } from "../actions/userActions";
import kachat from "kachat";
var userInitialState = {
    data: {},
    token: "",
    expiry: "",
    errorMessage: "",
    currentPlan: "basic",
    chatServiceMessage: "",
    currentSubscription: {},
    defaultPaymentMethod: {},
    projectBoosts: [],
    subscriptions: [],
    paymentMethods: [],
    userSignedUp: false,
    resetPassSuccess: false,
    forgotPassSuccess: false,
    isAuthenticated: false,
    isLoading: false,
    resetExpiry: 0,
    prices: [],
};
var clearingState = {
    data: {},
    token: "",
    expiry: "",
    errorMessage: "",
    currentPlan: "basic",
    chatServiceMessage: "",
    currentSubscription: {},
    defaultPaymentMethod: {},
    projectBoosts: [],
    subscriptions: [],
    paymentMethods: [],
    resetPassSuccess: false,
    forgotPassSuccess: false,
    isAuthenticated: false,
    isLoading: false,
    resetExpiry: 0,
    prices: [],
};
export var user = function (state, action) {
    var _a;
    if (state === void 0) { state = userInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case SET_TOKEN: {
            var token = payload.token;
            return __assign(__assign({}, state), { token: token });
        }
        case SET_SIGNUP_INFO: {
            var user_1 = payload.user;
            return __assign(__assign({}, state), { data: user_1 });
        }
        case SIGNUP_KACHAT_SUCCESS: {
            var user_2 = payload.user;
            return __assign(__assign({}, state), { data: user_2 });
        }
        case SIGNIN_SUCCESS: {
            var data = payload.data;
            return __assign(__assign({}, state), { data: data.user, token: data.token, expiry: data.expiry, isLoading: false, isAuthenticated: true, errorMessage: "" });
        }
        case SIGNIN_IN_PROGRESS: {
            return __assign(__assign({}, state), { data: {}, token: "", expiry: "", isLoading: true, isAuthenticated: false, errorMessage: "" });
        }
        case SIGNIN_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { data: {}, token: "", expiry: "", isLoading: false, isAuthenticated: false, errorMessage: message });
        }
        case FORGOT_PASS_SUCCESS: {
            var data = payload.data;
            return __assign(__assign({}, state), { forgotPassSuccess: true, resetExpiry: data.expiry, isLoading: false, isAuthenticated: false, errorMessage: "" });
        }
        case FORGOT_PASS_IN_PROGRESS: {
            return __assign(__assign({}, state), { data: {}, token: "", expiry: "", isLoading: true, forgotPassSuccess: false, isAuthenticated: false, resetExpiry: 0, errorMessage: "" });
        }
        case FORGOT_PASS_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { data: {}, token: "", expiry: "", isLoading: false, forgotPassSuccess: false, isAuthenticated: false, resetExpiry: 0, errorMessage: message });
        }
        case RESET_PASS_SUCCESS: {
            var data = payload.data;
            return __assign(__assign({}, state), { resetPassSuccess: true, isLoading: false, isAuthenticated: false, errorMessage: "" });
        }
        case RESET_PASS_IN_PROGRESS: {
            return __assign(__assign({}, state), { data: {}, token: "", expiry: "", isLoading: true, resetPassSuccess: false, isAuthenticated: false, errorMessage: "" });
        }
        case RESET_PASS_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { data: {}, token: "", expiry: "", isLoading: false, resetPassSuccess: false, isAuthenticated: false, errorMessage: message });
        }
        case UPDATE_USER_SUCCESS: {
            var user_3 = payload.user;
            return __assign(__assign({}, state), { data: user_3, isLoading: false, errorMessage: "" });
        }
        case UPDATE_USER_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case UPDATE_USER_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case UPDATE_USER_TOKEN: {
            var token = payload.token, expiry = payload.expiry;
            return __assign(__assign({}, state), { token: token, expiry: expiry });
        }
        case UNAUTHENTICATE_USER: {
            kachat.disconnect();
            return __assign(__assign({}, userInitialState), { isAuthenticated: false, token: "", expiry: "" });
        }
        case LOAD_PROJECT_BOOSTS_FAILURE: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case LOAD_PROJECT_BOOSTS_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case LOAD_PROJECT_BOOSTS_SUCCESS: {
            var boosts = payload.boosts;
            return __assign(__assign({}, state), { projectBoosts: boosts, isLoading: false });
        }
        case CLEAR_USER_ERROR: {
            return __assign(__assign({}, state), { errorMessage: "" });
        }
        case CLEAR_USER_DATA: {
            return __assign(__assign({}, state), clearingState);
        }
        case CLEAR_USER_SIGNED_UP: {
            return __assign(__assign({}, state), { userSignedUp: false });
        }
        case START_AUTH_LOADING: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case STOP_AUTH_LOADING: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case SUBSCRIPTION_PRICES_SUCCESS: {
            var prices = payload.prices;
            return __assign(__assign({}, state), { isLoading: false, prices: prices.map(function (price) {
                    return {
                        id: price.id,
                        name: price.nickname,
                        period: price.recurring.interval,
                        amount: price.unit_amount / 100,
                        currency: price.currency,
                    };
                }) });
        }
        case LOAD_USER_SUBSCRIPTION_SUCCESS: {
            var subscription = payload.subscription;
            if (!subscription.id)
                return __assign(__assign({}, state), { isLoading: false, currentPlan: "basic" });
            var startDate = new Date(0), endDate = new Date(0);
            startDate.setSeconds(subscription.start_date);
            endDate.setSeconds(subscription.current_period_end);
            return __assign(__assign({}, state), { isLoading: false, currentSubscription: {
                    id: subscription.id,
                    cancelled: subscription.cancel_at_period_end,
                    amount: subscription.plan.amount / 100,
                    currency: subscription.plan.currency,
                    name: subscription.plan.nickname,
                    startDate: startDate,
                    endDate: endDate,
                }, currentPlan: (_a = subscription.plan.nickname) === null || _a === void 0 ? void 0 : _a.toLowerCase() });
        }
        case LOAD_USER_SUBSCRIPTIONS_SUCCESS: {
            var subscriptions = payload.subscriptions;
            return __assign(__assign({}, state), { isLoading: false, subscriptions: subscriptions
                    ? subscriptions.map(function (subscription) {
                        var startDate = new Date(0), endDate = new Date(0), canceledAt = new Date(0);
                        startDate.setSeconds(subscription.start_date);
                        endDate.setSeconds(subscription.current_period_end);
                        canceledAt.setSeconds(subscription.canceled_at);
                        canceledAt = subscription.canceled_at
                            ? canceledAt
                            : null;
                        return {
                            id: subscription.id,
                            cancelled: subscription.cancel_at_period_end,
                            amount: subscription.plan.amount / 100,
                            currency: subscription.plan.currency,
                            name: subscription.plan.nickname,
                            status: subscription.status,
                            startDate: startDate,
                            endDate: endDate,
                            canceledAt: canceledAt,
                        };
                    })
                    : [] });
        }
        case LOAD_PAYMENT_METHODS_SUCCESS: {
            var paymentMethods = payload.paymentMethods;
            return __assign(__assign({}, state), { isLoading: false, paymentMethods: paymentMethods.map(function (pm) {
                    return {
                        id: pm.id,
                        last4: pm.card.last4,
                        expMonth: pm.card.exp_month,
                        expYear: pm.card.exp_year,
                        brand: pm.card.brand,
                        country: pm.card.country,
                    };
                }) });
        }
        case LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS: {
            var defaultPaymentMethod = payload.defaultPaymentMethod;
            return __assign(__assign({}, state), { isLoading: false, defaultPaymentMethod: defaultPaymentMethod.id
                    ? {
                        id: defaultPaymentMethod.id,
                        last4: defaultPaymentMethod.card.last4,
                        expMonth: defaultPaymentMethod.card.exp_month,
                        expYear: defaultPaymentMethod.card.exp_year,
                        brand: defaultPaymentMethod.card.brand,
                        country: defaultPaymentMethod.card.country,
                    }
                    : {} });
        }
        case CHAT_CONNECT_FAILURE: {
            return __assign(__assign({}, state), { isLoading: false, chatServiceMessage: "Could not connect to the messaging service at this time!" });
        }
        case CHAT_CONNECT_SUCCESS: {
            return __assign(__assign({}, state), { isLoading: false, chatServiceMessage: "" });
        }
        default: {
            return state;
        }
    }
};
