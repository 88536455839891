var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CREATE_DEAL_SUCCESS, CREATE_DEAL_IN_PROGRESS, CREATE_DEAL_FAILURE, UPDATE_NDA_SUCCESS, UPDATE_NDA_IN_PROGRESS, UPDATE_NDA_FAILURE, LOAD_NDAS_SUCCESS, LOAD_NDAS_IN_PROGRESS, LOAD_NDAS_FAILURE, LOAD_DEALS_SUCCESS, LOAD_DEALS_IN_PROGRESS, LOAD_DEALS_FAILURE, STOP_DEALS_LOADING, STOP_NDAS_LOADING, } from "../actions/dealActions";
var dealsInitialState = {
    userDeals: [],
    isLoading: false,
};
export var deals = function (state, action) {
    if (state === void 0) { state = dealsInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case CREATE_DEAL_SUCCESS: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case CREATE_DEAL_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case CREATE_DEAL_FAILURE: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case LOAD_DEALS_SUCCESS: {
            var userDeals = payload.userDeals;
            return __assign(__assign({}, state), { userDeals: userDeals, isLoading: false });
        }
        case LOAD_DEALS_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case LOAD_DEALS_FAILURE: {
            return __assign(__assign({}, state), { userDeals: [], isLoading: false });
        }
        case STOP_DEALS_LOADING: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        default: {
            return state;
        }
    }
};
var ndasInitialState = {
    userNdas: [],
    isLoading: false,
};
export var ndas = function (state, action) {
    if (state === void 0) { state = ndasInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case UPDATE_NDA_SUCCESS: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case UPDATE_NDA_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case UPDATE_NDA_FAILURE: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case LOAD_NDAS_SUCCESS: {
            var userNdas = payload.userNdas;
            return __assign(__assign({}, state), { userNdas: userNdas, isLoading: false });
        }
        case LOAD_NDAS_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case LOAD_NDAS_FAILURE: {
            return __assign(__assign({}, state), { userNdas: [], isLoading: false });
        }
        case STOP_NDAS_LOADING: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        default: {
            return state;
        }
    }
};
