var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import KButton from "../../base-components/KButton/KButton";
import KStars from "../../base-components/KStars/KStars";
import "./RateUser.css";
var RateUser = function (_a) {
    var loadedUserRatingByUserId = _a.loadedUserRatingByUserId, user = _a.user, addRatingByUserRate = _a.addRatingByUserRate, setRatingStars = _a.setRatingStars, description = _a.description;
    var _b = React.useState(0), rating = _b[0], setRating = _b[1];
    return (_jsx("div", __assign({ className: "rateUser-container" }, { children: _jsxs("div", __assign({ className: "rateUser-content" }, { children: [_jsxs("div", __assign({ className: "rateUser-title" }, { children: ["Rate ", user.user_first_name] })), _jsx(KStars, { fontSize: "24px", label: description ||
                        "Share your rating on a scale of one to five based on ".concat(user.user_first_name, "'s projects, connections, and cooperation."), value: loadedUserRatingByUserId, onClick: function (e) {
                        setRating(e);
                    }, fix: "normal" }), _jsx("div", __assign({ className: "KStars-submit-btn" }, { children: _jsx(KButton, { title: "Submit", variant: "primary", backgroundColor: "var(--app-loadeduser-color)", width: "100px", height: "38px", onClick: function () {
                            addRatingByUserRate(user.user_id, {
                                rating: rating,
                            });
                            setRatingStars(false);
                        } }) }))] })) })));
};
export default RateUser;
