var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import { 
// HashRouter as Router,
Route, Switch, useLocation, } from "react-router-dom";
import kachat from "kachat";
import SignupPage from "./pages/SignupPage/SignupPage";
import SigninPage from "./pages/SigninPage/SigninPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import TermsOfServicePage from "./pages/TermsOfServicePage/TermsOfServicePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import ContactUsPage from "./pages/ContactUsPage/ContactUsPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import PlatformPage, { LoggedInNavBar, } from "./pages/PlatformPage/PlatformPage";
import KProgressBar from "./base-components/KProgressBar/KProgressBar";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage";
import { getIsLoading, getColorMode, getPrimaryColor, getProgress, getProgressStatus, } from "./redux/selectors/generalSelectors";
import { IoMdClose as IconClose } from "react-icons/io";
import { AiOutlineThunderbolt as IconBoost } from "react-icons/ai";
import { BiMessageDetail as IconMessaging } from "react-icons/bi";
import { AiOutlineWarning as IconWallOfShame } from "react-icons/ai";
import { IoMdNotificationsOutline as IconNotification } from "react-icons/io";
import { thunkSetColorMode, thunkSetProgressStatus, } from "./redux/thunks/generalThunks";
import { getLoadedUser } from "./redux/selectors/userSelectors";
import AboutPage from "./pages/AboutPage/AboutPage";
import { getChatServiceMessage, getUser, getUserCurrentPlan, } from "./redux/selectors/authSelectors";
import { thunkLoadUserSubscription, thunkSetChatServiceError, thunkVerify, } from "./redux/thunks/authThunks";
import { thunkIsPaymentNeeded } from "./redux/thunks/contractThunks";
import { getNotificationsUnreadCount } from "./redux/selectors/notificationSelectors";
import KCircularProgress from "./base-components/KCircularProgress/KCircularProgress";
import ProfileOptions from "./components/ProfileOptions/ProfileOptions";
import SetupPage from "./pages/SetupPage/SetupPage";
import { canBoostProjects, canChooseSubscription, canViewMessaging, } from "./validators/profileValidator";
import { thunkIsBoostPaymentNeeded, thunkIsSubscriptionPaymentNeeded, } from "./redux/thunks/projectThunks";
import { history } from "./redux/store";
import "./App.css";
var App = function (_a) {
    var isLoading = _a.isLoading, colorMode = _a.colorMode, primaryColor = _a.primaryColor, onColorMode = _a.onColorMode, loadedUser = _a.loadedUser, progressStatus = _a.progressStatus, progress = _a.progress, user = _a.user, onSetProgressStatus = _a.onSetProgressStatus, onLoadSubscription = _a.onLoadSubscription, unreadNotifications = _a.unreadNotifications, onChatServiceError = _a.onChatServiceError, chatServiceError = _a.chatServiceError, onCheckPaymentNeeded = _a.onCheckPaymentNeeded, onCheckBoostPaymentNeeded = _a.onCheckBoostPaymentNeeded, onCheckSubscriptionPaymentNeeded = _a.onCheckSubscriptionPaymentNeeded, subscriptionPlan = _a.subscriptionPlan;
    var location = useLocation();
    var globalMessageRef = useRef(null);
    var _b = useState(false), checked = _b[0], setChecked = _b[1];
    var _c = useState(""), globalMessage = _c[0], setGlobalMessage = _c[1];
    var _d = useState(0), unreadMessages = _d[0], _setUnreadMessages = _d[1];
    var _e = useState("0px"), globalMessageHeight = _e[0], setGlobalMessageHeight = _e[1];
    var _f = useState(false), isPaymentNeeded = _f[0], setIsPaymentNeeded = _f[1];
    var _g = useState(false), displayUserTabbar = _g[0], setDisplayUserTabbar = _g[1];
    var unreadMessagesRef = useRef(unreadMessages);
    var setUnreadMessages = function (data) {
        unreadMessagesRef.current = data;
        _setUnreadMessages(data);
    };
    var setLightMode = function () {
        document.body.style.setProperty("--app-mode", "light");
        document.body.style.setProperty("--app-border", "0.5px solid #ccc2");
        document.body.style.setProperty("--app-border-strong", "0.5px solid #aaa3");
        document.body.style.setProperty("--app-box-shadow", "1px 2px 10px #ccc5");
        document.body.style.setProperty("--app-box-shadow-right", "2px 10px 10px #ccc5");
        document.body.style.setProperty("--app-primary-background-color", "#feffff");
        document.body.style.setProperty("--app-secondary-background-color", "#f5f6f7");
        document.body.style.setProperty("--app-base-secondary-background-color", "#f3f4f5");
        document.body.style.setProperty("--app-tertiary-background-color", "#e5e5e5");
        document.body.style.setProperty("--app-quarternary-background-color", "#fdfdfd");
        document.body.style.setProperty("--app-landing-primary-color", "#f3f4f5");
        document.body.style.setProperty("--app-landing-join-now-color", "#e8f1f0");
        document.body.style.setProperty("--app-landing-find-best-color", "#feffff");
        document.body.style.setProperty("--app-landing-collaborate-color", "#f6f2e7");
        document.body.style.setProperty("--app-landing-guest-color", "#e8f1f0");
        document.body.style.setProperty("--app-landing-experience-color", "#feffff");
        document.body.style.setProperty("--app-no-background-color", "#eee9");
        document.body.style.setProperty("--app-background-hover-color", "#e0e0e055");
        document.body.style.setProperty("--app-font-color", "#333");
        document.body.style.setProperty("--app-font-color-light", "#666");
        document.body.style.setProperty("--app-font-color-lighter", "#999");
        document.body.style.setProperty("--app-font-color-lightest", "#bbb");
        document.body.style.setProperty("--app-shadow-color", "#3333");
        document.body.style.setProperty("--app-button-font-color", "#111");
        document.body.style.setProperty("--app-textfield-error-color", "#d8656522");
        document.body.style.setProperty("--app-chip-background-color", "#fafafa");
        document.body.style.setProperty("--app-chip-border", "1px solid #d5d5d5");
        document.body.style.setProperty("--app-nda-image", "url(/images/Scriptofilm_icon_black_profile.png)");
        document.body.style.setProperty("--app-deal-image", "url(/images/Scriptofilm_icon_black_contract.png)");
        document.body.style.setProperty("--app-scripto-logo", "url(/images/Scriptofilm_logo-01.png)");
        document.body.style.setProperty("--app-scripto-trademark", "url(/images/Scriptofilm_trademark.png)");
        document.body.style.setProperty("--app-jaydreams-logo", "url(/images/jaydreams-logo.png)");
        document.body.style.setProperty("--app-wall-background", "url(/images/wall_white.jpeg)");
        document.body.style.setProperty("--app-settings-section-background-color", "rgba(240,242,245, 0.6)");
    };
    var setDarkMode = function () {
        document.body.style.setProperty("--app-border", "0.5px solid #2225");
        document.body.style.setProperty("--app-border-strong", "0.5px solid #5555");
        document.body.style.setProperty("--app-box-shadow", "1px 2px 10px #1115");
        document.body.style.setProperty("--app-box-shadow-right", "2px 10px 10px #1115");
        document.body.style.setProperty("--app-primary-background-color", "#242526");
        document.body.style.setProperty("--app-secondary-background-color", "#18191a");
        document.body.style.setProperty("--app-base-secondary-background-color", "#18191a");
        document.body.style.setProperty("--app-tertiary-background-color", "#3a3b3c");
        document.body.style.setProperty("--app-quarternary-background-color", "#1b1c1d");
        document.body.style.setProperty("--app-landing-primary-color", "#18191a");
        document.body.style.setProperty("--app-landing-join-now-color", "#242526");
        document.body.style.setProperty("--app-landing-find-best-color", "#18191a");
        document.body.style.setProperty("--app-landing-collaborate-color", "#242526");
        document.body.style.setProperty("--app-landing-guest-color", "#18191a");
        document.body.style.setProperty("--app-landing-experience-color", "#18191a");
        document.body.style.setProperty("--app-no-background-color", "#3333");
        document.body.style.setProperty("--app-background-hover-color", "#40414255");
        document.body.style.setProperty("--app-font-color", "#fff");
        document.body.style.setProperty("--app-font-color-light", "#ccc");
        document.body.style.setProperty("--app-font-color-lighter", "#999");
        document.body.style.setProperty("--app-font-color-lightest", "#666");
        document.body.style.setProperty("--app-shadow-color", "#9993");
        document.body.style.setProperty("--app-button-font-color", "#111");
        document.body.style.setProperty("--app-textfield-error-color", "#d8656522");
        document.body.style.setProperty("--app-chip-background-color", "#272829");
        document.body.style.setProperty("--app-chip-border", "1.5px solid #444");
        document.body.style.setProperty("--app-nda-image", "url(/images/Scriptofilm_icon_white_profile.png)");
        document.body.style.setProperty("--app-deal-image", "url(/images/Scriptofilm_icon_white_contract.png)");
        document.body.style.setProperty("--app-scripto-logo", "url(/images/Scriptofilm_white_logo-01.png)");
        document.body.style.setProperty("--app-scripto-trademark", "url(/images/Scriptofilm_trademark_white.png)");
        document.body.style.setProperty("--app-jaydreams-logo", "url(/images/jaydreams-logo-white.png)");
        document.body.style.setProperty("--app-wall-background", "url(/images/wall_dark.jpeg)");
        document.body.style.setProperty("--app-settings-section-background-color", "#3D4043");
    };
    var onColorModeChange = function (mode) {
        onColorMode(mode);
    };
    var setBackgroundColors = function (colorMode) {
        var title = (primaryColor || "unknown").toLowerCase();
        if (colorMode == "dark") {
            document.body.style.setProperty("--app-secondary-background-color", "var(--app-".concat(title, "-color-darkened)"));
        }
        else if (colorMode == "light") {
            document.body.style.setProperty("--app-secondary-background-color", "var(--app-base-secondary-background-color)");
        }
        else {
            document.body.style.setProperty("--app-secondary-background-color", "var(--app-".concat(title, "-color-lightened)"));
        }
    };
    var handleResize = function () {
        setGlobalMessageHeight("".concat(globalMessageRef.current
            ? globalMessageRef.current.clientHeight - 1
            : 0, "px"));
    };
    useEffect(function () {
        onLoadSubscription();
        var setPaymentNeeded = function (paymentNeeded, err) {
            if (err) {
                return console.log(err);
            }
            if (paymentNeeded)
                setIsPaymentNeeded(paymentNeeded);
        };
        onCheckPaymentNeeded(setPaymentNeeded);
        onCheckBoostPaymentNeeded(setPaymentNeeded);
        onCheckSubscriptionPaymentNeeded(setPaymentNeeded);
    }, [user]);
    useEffect(function () {
        kachat.setBaseUrls(process.env.KACHAT_SOCKET_API, process.env.KACHAT_AUTH_API, process.env.KACHAT_CHAT_API);
        if (user.user_id) {
            console.log("Reconnecting chat: ".concat(user.user_email));
            kachat.reconnect(user.user_email, user.user_kachat_id, function (err) {
                if (err && err !== "user already connected") {
                    console.log(err);
                    console.log("Could not connect to messaging service..");
                    onChatServiceError();
                }
            });
            kachat.onUnreadCountUpdate(function () {
                var convos = Object.values(kachat.convoByChannel);
                setUnreadMessages(convos.reduce(function (a, b) { return a + b.unread; }, 0));
            });
            kachat.onDisconnect(function () {
                // onChatServiceError();
                setTimeout(function () {
                    kachat.reconnect(user.user_email, user.user_kachat_id, function (err) {
                        if (err && err !== "user already connected") {
                            console.log(err);
                            console.log("Could not connect to messaging service..");
                            onChatServiceError();
                        }
                    });
                }, 5000);
                if (window.location.pathname.startsWith("/platform/messaging")) {
                    window.history.replaceState(null, "", "/platform/messaging");
                }
                kachat.onUnreadCountUpdate(function () {
                    var convos = Object.values(kachat.convoByChannel);
                    setUnreadMessages(convos.reduce(function (a, b) { return a + b.unread; }, 0));
                });
            });
        }
        else {
            // invalidate the on disconnect function
            kachat.onDisconnect(function () { });
        }
    }, [user]);
    useEffect(function () {
        var title = "unknown";
        if (loadedUser)
            title = (loadedUser.user_title_name || "unknown").toLowerCase();
        document.body.style.setProperty("--app-loadeduser-color", "var(--app-".concat(title, "-color)"));
    }, [loadedUser]);
    useEffect(function () {
        var title = (primaryColor || "unknown").toLowerCase();
        document.body.style.setProperty("--app-primary-color", "var(--app-".concat(title, "-color)"));
        setBackgroundColors(colorMode);
    }, [primaryColor]);
    useEffect(function () {
        switch (colorMode) {
            case "dark": {
                setDarkMode();
                setChecked(true);
                break;
            }
            case "light": {
                setLightMode();
                setChecked(false);
                break;
            }
            default: {
                setLightMode();
                setChecked(false);
                break;
            }
        }
        setBackgroundColors(colorMode);
    }, [colorMode]);
    useEffect(function () {
        handleResize();
    }, [globalMessage]);
    useEffect(function () {
        if (user.user_id)
            setGlobalMessage(chatServiceError);
        else
            setGlobalMessage("");
    }, [chatServiceError, user]);
    useEffect(function () {
        onSetProgressStatus("Uploading");
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(function () {
        setDisplayUserTabbar(location.pathname && location.pathname !== "/");
    }, [location]);
    return (_jsxs("div", __assign({ className: "app-container", style: {
            marginTop: globalMessageHeight,
        } }, { children: [globalMessage && (_jsxs("div", __assign({ className: "app-alert-section", ref: globalMessageRef }, { children: [globalMessage, _jsx(IconClose, { className: "app-alert-icon", onClick: function () { return setGlobalMessage(""); } })] }))), _jsx(KCircularProgress, { progress: progress, status: progressStatus }), _jsx(KProgressBar, { isLoading: isLoading }), _jsx("div", __assign({ style: {
                    position: "fixed",
                    zIndex: 999,
                    right: "12px",
                    // top: "0px",
                } }, { children: displayUserTabbar && (_jsxs("div", __assign({ className: "app-action-buttons", style: { display: (user === null || user === void 0 ? void 0 : user.user_id) ? "flex" : "none" } }, { children: [_jsx(ProfileOptions, { user: user, colorMode: colorMode, onColorModeChange: onColorModeChange, isPaymentNeeded: isPaymentNeeded }), _jsxs("div", __assign({ style: { display: "flex", marginRight: "-8px" } }, { children: [canChooseSubscription() && (_jsx("div", __assign({ className: "app-stamp-premium ".concat(subscriptionPlan === "premium"
                                        ? "app-stamp-premium-active"
                                        : ""), onClick: function () {
                                        return history.push("/platform/subscriptions");
                                    } }, { children: "Premium" }))), canBoostProjects() && (_jsx("div", __assign({ className: "app-action-icon", onClick: function () {
                                        return history.push("/platform/boosts/new");
                                    }, style: {
                                        marginRight: "14px",
                                        marginLeft: "-3px",
                                    } }, { children: _jsx(IconBoost, {}) }))), _jsx("div", __assign({ className: "app-action-icon app-action-icon-wos", onClick: function () {
                                        return history.push("/platform/wallOfShame");
                                    }, style: {
                                        marginRight: "14px",
                                        marginLeft: "-3px",
                                    } }, { children: _jsx(IconWallOfShame, {}) })), _jsxs("div", __assign({ className: "app-action-icon", onClick: function () {
                                        return history.push("/platform/notification");
                                    } }, { children: [_jsx(IconNotification, {}), _jsx("div", __assign({ className: "app-action-count", style: {
                                                display: unreadNotifications < 1
                                                    ? "none"
                                                    : "flex",
                                            } }, { children: unreadNotifications > 99
                                                ? "99+"
                                                : unreadNotifications }))] })), _jsxs("div", __assign({ className: "app-action-icon", onClick: function () {
                                        return history.push("/platform/messaging");
                                    }, style: {
                                        display: canViewMessaging() && unreadMessages
                                            ? "block"
                                            : "none",
                                        fontSize: "23px",
                                        marginTop: "5px",
                                    } }, { children: [_jsx(IconMessaging, {}), _jsx("div", __assign({ className: "app-action-count", style: {
                                                display: unreadMessages < 1
                                                    ? "none"
                                                    : "flex",
                                            } }, { children: unreadMessages > 99
                                                ? "99+"
                                                : unreadMessages }))] }))] }))] }))) })), displayUserTabbar && user && user.user_id && (_jsx("div", __assign({ style: {
                    position: "absolute",
                    top: "0px",
                    width: "100%",
                } }, { children: _jsx(LoggedInNavBar, { variant: "secondary" }) }))), _jsxs(Switch, { children: [_jsx(Route, { path: "/", component: LandingPage, exact: true }), _jsx(Route, { path: "/about", component: AboutPage }), _jsx(Route, { path: "/signin", component: SigninPage }), _jsx(Route, { path: "/signup", component: SignupPage }), _jsx(Route, { path: "/termsofservice", component: TermsOfServicePage }), _jsx(Route, { path: "/privacypolicy", component: PrivacyPolicyPage }), _jsx(Route, { path: "/contactus", component: ContactUsPage }), _jsx(Route, { path: "/reset", component: ResetPasswordPage }), _jsx(Route, { path: "/forgot", component: ForgotPasswordPage }), _jsx(Route, { path: "/platform", component: PlatformPage }), _jsx(Route, { path: "/setup", component: SetupPage }), _jsx(Route, { path: "/notfound", component: NotFoundPage, exact: true }), _jsx(Route, { path: "*", component: NotFoundPage })] })] })));
};
var mapStateToProps = function (state) { return ({
    chatServiceError: getChatServiceMessage(state),
    progress: getProgress(state),
    progressStatus: getProgressStatus(state),
    user: getUser(state),
    isLoading: getIsLoading(state),
    colorMode: getColorMode(state),
    primaryColor: getPrimaryColor(state),
    loadedUser: getLoadedUser(state),
    unreadNotifications: getNotificationsUnreadCount(state),
    subscriptionPlan: getUserCurrentPlan(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onColorMode: function (colorMode) { return dispatch(thunkSetColorMode(colorMode)); },
    onVerify: function (email, userId) { return dispatch(thunkVerify(email, userId)); },
    onLoadSubscription: function () { return dispatch(thunkLoadUserSubscription()); },
    onChatServiceError: function () { return dispatch(thunkSetChatServiceError()); },
    onSetProgressStatus: function (progressStatus) {
        return dispatch(thunkSetProgressStatus(progressStatus));
    },
    onCheckPaymentNeeded: function (callback) {
        return dispatch(thunkIsPaymentNeeded(callback));
    },
    onCheckBoostPaymentNeeded: function (callback) {
        return dispatch(thunkIsBoostPaymentNeeded(callback));
    },
    onCheckSubscriptionPaymentNeeded: function (callback) {
        return dispatch(thunkIsSubscriptionPaymentNeeded(callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
