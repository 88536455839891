var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from "react-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Integrations } from "@sentry/tracing";
import store, { history } from "./redux/store";
import * as Sentry from "@sentry/react";
import App from "./App.js";
if (JSON.parse(process.env.SENTRY_ENABLED)) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
    });
}
var persistor = persistStore(store);
// UserProfile.setName(Math.floor(Math.random() * 1e10));
// console.log(UserProfile.getName());
// localStorage.setItem("temp-page-should-reload", "true");
ReactDOM.render(_jsx(Provider, __assign({ store: store }, { children: _jsx(PersistGate, __assign({ loading: _jsx("div", { children: "Loading..." }), persistor: persistor }, { children: _jsx(ConnectedRouter, __assign({ history: history }, { children: _jsx(App, {}) })) })) })), document.getElementById("root"));
