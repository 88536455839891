export var SET_TOKEN = "SET_TOKEN";
export var setToken = function (token) { return ({
    type: SET_TOKEN,
    payload: { token: token },
}); };
export var SET_SIGNUP_INFO = "SET_SIGNUP_INFO";
export var setSignupInfo = function (user) { return ({
    type: SET_SIGNUP_INFO,
    payload: { user: user },
}); };
export var SIGNUP_KACHAT_SUCCESS = "SIGNUP_KACHAT_SUCCESS";
export var signupKachatSuccess = function (user) { return ({
    type: SIGNUP_KACHAT_SUCCESS,
    payload: { user: user },
}); };
export var SIGNIN_IN_PROGRESS = "SIGNIN_IN_PROGRESS";
export var signinInProgress = function () { return ({
    type: SIGNIN_IN_PROGRESS,
}); };
export var SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export var signinSuccess = function (data) { return ({
    type: SIGNIN_SUCCESS,
    payload: { data: data },
}); };
export var SIGNIN_FAILURE = "SIGNIN_FAILURE";
export var signinFailure = function (message) { return ({
    type: SIGNIN_FAILURE,
    payload: { message: message },
}); };
export var CHAT_CONNECT_FAILURE = "CHAT_CONNECT_FAILURE";
export var chatConnectFailure = function () { return ({
    type: CHAT_CONNECT_FAILURE,
}); };
export var CHAT_CONNECT_SUCCESS = "CHAT_CONNECT_SUCCESS";
export var chatConnectSuccess = function () { return ({
    type: CHAT_CONNECT_SUCCESS,
}); };
export var FORGOT_PASS_IN_PROGRESS = "FORGOT_PASS_IN_PROGRESS";
export var forgotPassInProgress = function () { return ({
    type: FORGOT_PASS_IN_PROGRESS,
}); };
export var FORGOT_PASS_SUCCESS = "FORGOT_PASS_SUCCESS";
export var forgotPassSuccess = function (data) { return ({
    type: FORGOT_PASS_SUCCESS,
    payload: { data: data },
}); };
export var FORGOT_PASS_FAILURE = "FORGOT_PASS_FAILURE";
export var forgotPassFailure = function (message) { return ({
    type: FORGOT_PASS_FAILURE,
    payload: { message: message },
}); };
export var RESET_PASS_IN_PROGRESS = "RESET_PASS_IN_PROGRESS";
export var resetPassInProgress = function () { return ({
    type: RESET_PASS_IN_PROGRESS,
}); };
export var RESET_PASS_SUCCESS = "RESET_PASS_SUCCESS";
export var resetPassSuccess = function (data) { return ({
    type: RESET_PASS_SUCCESS,
    payload: { data: data },
}); };
export var RESET_PASS_FAILURE = "RESET_PASS_FAILURE";
export var resetPassFailure = function (message) { return ({
    type: RESET_PASS_FAILURE,
    payload: { message: message },
}); };
export var CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export var clearUserError = function () { return ({
    type: CLEAR_USER_ERROR,
}); };
export var UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";
export var updateUserToken = function (token, expiry) { return ({
    type: UPDATE_USER_TOKEN,
    payload: { token: token, expiry: expiry },
}); };
export var UNAUTHENTICATE_USER = "UNAUTHENTICATE_USER";
export var unauthenticateUser = function () { return ({
    type: UNAUTHENTICATE_USER,
}); };
export var CLEAR_USER_DATA = "CLEAR_USER_DATA";
export var clearUserData = function () { return ({
    type: CLEAR_USER_DATA,
}); };
export var CLEAR_USER_SIGNED_UP = "CLEAR_USER_SIGNED_UP";
export var clearUserSignedUp = function () { return ({
    type: CLEAR_USER_SIGNED_UP,
}); };
export var START_AUTH_LOADING = "START_AUTH_LOADING";
export var startAuthLoading = function () { return ({
    type: START_AUTH_LOADING,
}); };
export var STOP_AUTH_LOADING = "STOP_AUTH_LOADING";
export var stopAuthLoading = function () { return ({
    type: STOP_AUTH_LOADING,
}); };
export var SUBSCRIPTION_PRICES_SUCCESS = "SUBSCRIPTION_PRICES_SUCCESS";
export var subscriptionPricesSuccess = function (pricesData) { return ({
    payload: { prices: pricesData === null || pricesData === void 0 ? void 0 : pricesData.prices },
    type: SUBSCRIPTION_PRICES_SUCCESS,
}); };
export var LOAD_USER_SUBSCRIPTION_SUCCESS = "LOAD_USER_SUBSCRIPTION_SUCCESS";
export var loadUserSubscriptionSuccess = function (subscription) { return ({
    payload: { subscription: subscription },
    type: LOAD_USER_SUBSCRIPTION_SUCCESS,
}); };
export var LOAD_USER_SUBSCRIPTIONS_SUCCESS = "LOAD_USER_SUBSCRIPTIONS_SUCCESS";
export var loadUserSubscriptionsSuccess = function (subscriptions) { return ({
    payload: { subscriptions: subscriptions },
    type: LOAD_USER_SUBSCRIPTIONS_SUCCESS,
}); };
export var LOAD_PAYMENT_METHODS_SUCCESS = "LOAD_PAYMENT_METHODS_SUCCESS";
export var loadPaymentMethodsSuccess = function (paymentMethods) { return ({
    payload: { paymentMethods: paymentMethods },
    type: LOAD_PAYMENT_METHODS_SUCCESS,
}); };
export var LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS = "LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS";
export var loadDefaultPaymentMethodSuccess = function (defaultPaymentMethod) { return ({
    payload: { defaultPaymentMethod: defaultPaymentMethod },
    type: LOAD_DEFAULT_PAYMENT_METHOD_SUCCESS,
}); };
