var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BsLightningFill as IconBoost } from "react-icons/bs";
import { BiAlignLeft as IconList } from "react-icons/bi";
import { getCountries, getLanguages, } from "../../redux/selectors/generalSelectors";
import { thunkLoadCountries, thunkLoadLanguages, } from "../../redux/thunks/generalThunks";
import { boostingSchema } from "../../validations/boostingValidation";
import { getUserEmail, getUserProjectBoosts, } from "../../redux/selectors/authSelectors";
import { thunkCreateProjectBoost, thunkCreateProjectBoostPaymentIntent, thunkIsBoostPaymentNeeded, } from "../../redux/thunks/projectThunks";
import ProjectSelector from "../../components/ProjectSelector/ProjectSelector";
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import KTextField from "../../base-components/KTextField/KTextField";
import KSelector from "../../base-components/KSelector/KSelector";
import KButton from "../../base-components/KButton/KButton";
import { history } from "../../redux/store";
import { useFormik } from "formik";
import "./BoostPage.css";
var BoostPage = function (_a) {
    var email = _a.email, countries = _a.countries, languages = _a.languages, userProjectBoosts = _a.userProjectBoosts, onLoadCountries = _a.onLoadCountries, onLoadLanguages = _a.onLoadLanguages, onCreateBoost = _a.onCreateBoost, onCreatePaymentIntent = _a.onCreatePaymentIntent, onCheckIfPaymentNeeded = _a.onCheckIfPaymentNeeded;
    var _b = useState(0), cost = _b[0], setCost = _b[1];
    var _c = useState(0), totalDays = _c[0], setTotalDays = _c[1];
    var _d = useState(null), endDate = _d[0], setEndDate = _d[1];
    var _e = useState(null), startDate = _e[0], setStartDate = _e[1];
    var _f = useState("49%"), costWidth = _f[0], setCostWidth = _f[1];
    var _g = useState(""), viewAllTitle = _g[0], setViewAllTitle = _g[1];
    var _h = useState(null), selectedProject = _h[0], setSelectedProject = _h[1];
    var _j = useState([]), selectedCountries = _j[0], setSelectedCountries = _j[1];
    var _k = useState(false), validateOn = _k[0], setValidateOn = _k[1];
    var _l = useState(boostingSchema([])), boostSchema = _l[0], setBoostSchema = _l[1];
    var _m = useState("Pay & run boost"), runBoostTitle = _m[0], setRunBoostTitle = _m[1];
    var _o = useState("Cost"), boostCostLabel = _o[0], setBoostCostLabel = _o[1];
    var handleResize = function () {
        setCostWidth(window.innerWidth < 500 ? "100%" : "49%");
        setViewAllTitle(window.innerWidth < 500 ? "" : "View All");
    };
    var updateStartDate = function (value) {
        setStartDate(new Date(value).toISOString());
    };
    var updateEndDate = function (value) {
        setEndDate(new Date(value).toISOString());
    };
    var formik = useFormik({
        initialValues: { countries: [], languages: [] },
        validateOnChange: validateOn,
        validateOnBlur: false,
        onSubmit: function (values) {
            if (!selectedProject)
                return;
            onCheckout(values.startDate, values.endDate, values.countries, totalDays, values.countries.length);
        },
        validationSchema: boostSchema,
    });
    useEffect(function () {
        var dateRanges = [];
        if (selectedProject) {
            var filteredBoosts = userProjectBoosts.filter(function (boost) {
                return boost.project_boost_project_id_fk ===
                    selectedProject.project_id;
            });
            dateRanges = filteredBoosts.map(function (boost) { return [
                boost.project_boost_start_date,
                boost.project_boost_end_date,
            ]; });
        }
        setBoostSchema(boostingSchema(dateRanges, startDate));
    }, [selectedProject, startDate]);
    useEffect(function () {
        if (startDate && endDate) {
            setTotalDays(1 +
                (new Date(endDate).getTime() -
                    new Date(startDate).getTime()) /
                    (24 * 60 * 60 * 1000));
        }
    }, [startDate, endDate]);
    useEffect(function () {
        /** TODO: Costs are fetched from the backend at the payment stage.
         * However, they should also be fetched from the backend at this stage as well.
         * At the time being, we make sure to match both as they won't be changed.
         */
        setCost(totalDays * parseInt(process.env.BOOST_SINGLE_DAY_COST || 11));
    }, [totalDays, selectedCountries]);
    useEffect(function () {
        handleResize();
        onLoadCountries();
        onLoadLanguages();
        onCheckIfPaymentNeeded(function (isPaymentNeeded, err) {
            if (err) {
                alert(err);
                return;
            }
            setRunBoostTitle(isPaymentNeeded ? "Pay & run boost" : "Run boost for free!");
            setBoostCostLabel(isPaymentNeeded ? "Cost" : "Cost (offerred by Scriptofilm)");
        });
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    // stripe section
    var _p = useState(""), currency = _p[0], setCurrency = _p[1];
    var _q = useState(""), totalAmount = _q[0], setTotalAmount = _q[1];
    var _r = useState(false), paymentModeOn = _r[0], setPaymentModeOn = _r[1];
    var _s = useState(""), clientSecret = _s[0], setClientSecret = _s[1];
    var _t = useState([]), invoiceData = _t[0], setInvoiceData = _t[1];
    // const [clientSecret, setClientSecret] = useState(
    //     "pi_3KEvfdFoirgnNpJ113nJGAiR_secret_cEDuQjR9FTIoTAYj0a0c9CKeU"
    // );
    var onCheckout = function (startDate, endDate, countries, numOfDays, numOfCountries) {
        if (!email)
            return;
        // check whether payment is needed or not
        onCheckIfPaymentNeeded(function (isPaymentNeeded, err) {
            if (err) {
                alert(err);
                return;
            }
            if (!isPaymentNeeded) {
                // if payment not needed
                onCreateBoost(selectedProject.project_id, startDate, endDate, "", countries.map(function (country) { return country.country_id; }), function (err) {
                    if (err) {
                        alert(err);
                        return;
                    }
                    history.push("/platform/boosts?paymentCompleted=true&amount=".concat(cost, "$&freeMode=true"));
                });
            }
            else {
                // if payment is needed
                onCreatePaymentIntent(email, numOfDays, numOfCountries, function (data) {
                    setCurrency(data.currency);
                    setTotalAmount(data.totalAmount);
                    setInvoiceData(data.invoiceData);
                    onCreateBoost(selectedProject.project_id, startDate, endDate, data.invoiceId, countries.map(function (country) { return country.country_id; }), function (err) {
                        if (err) {
                            alert(err);
                            return;
                        }
                        console.log("Proceeding to checkout..");
                        setClientSecret(data.clientSecret);
                        setPaymentModeOn(true);
                    });
                });
            }
        });
    };
    return (_jsx("div", __assign({ className: "boostpage-container" }, { children: paymentModeOn ? (_jsx(CheckoutPage, { visible: paymentModeOn, clientSecret: clientSecret, onClose: function () {
                formik.resetForm();
                setPaymentModeOn(false);
                setValidateOn(false);
                setSelectedProject(selectedProject);
            }, returnUrl: "".concat(process.env.BOOST_PAYMENT_REDIRECT_URL, "$").concat(totalAmount), invoiceData: invoiceData, totalAmount: totalAmount, currency: currency, email: email })) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "boostpage-title" }, { children: [_jsx("div", __assign({ className: "boostpage-title-icon" }, { children: _jsx(IconBoost, {}) })), _jsx("div", __assign({ className: "boostpage-title-text" }, { children: "Boost project" })), _jsx(KButton, { title: viewAllTitle, variant: "primary", icon: IconList, onClick: function () { return history.push("/platform/boosts"); } })] })), _jsx("div", __assign({ className: "boostpage-info" }, { children: "Boosting your project will put it under the spotlight. We will feature your project in the marketplace based on the target settings you specify below." })), _jsx("div", __assign({ className: "boostpage-wrapper" }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsxs("div", __assign({ className: "boostpage-section-project" }, { children: [_jsx("div", __assign({ className: "boostpage-section-title" }, { children: "Choose your project" })), _jsx("div", __assign({ className: "boostpage-project-container" }, { children: _jsx(ProjectSelector, { onSelectProject: function (project) {
                                                return setSelectedProject(project);
                                            }, backgroundColor: "var(--app-background-hover-color)", validateOn: validateOn, personal: true }) }))] })), _jsxs("div", __assign({ className: "boostpage-section-target" }, { children: [_jsx("div", __assign({ className: "boostpage-section-title" }, { children: "Target audience" })), _jsx("div", __assign({ className: "boostpage-target-container" }, { children: _jsx(KSelector, { identifier: "1", name: "countries", label: "Countries", placeholder: selectedCountries.length
                                                ? "Enter another"
                                                : "All", formik: formik, items: countries, itemKey: "country_id", titleKey: "country_name", width: "100%", onChange: function (items) {
                                                return setSelectedCountries(items);
                                            }, multiple: true }) }))] })), _jsxs("div", __assign({ className: "boostpage-section-duration" }, { children: [_jsx("div", __assign({ className: "boostpage-section-title" }, { children: "Duration" })), _jsx("div", __assign({ className: "boostpage-duration-container" }, { children: _jsxs("div", __assign({ className: "boostpage-duration-start-end" }, { children: [_jsx(KTextField, { name: "startDate", label: "Start Date", type: "date", width: "49%", formik: formik, onChange: function (e) {
                                                        return updateStartDate(e.target.value);
                                                    } }), _jsx(KTextField, { name: "endDate", label: "End Date", type: "date", width: "49%", formik: formik, onChange: function (e) {
                                                        return updateEndDate(e.target.value);
                                                    } })] })) }))] })), _jsxs("div", __assign({ className: "boostpage-section-action" }, { children: [_jsx("div", __assign({ className: "boostpage-section-title" }, { children: "Payment" })), _jsxs("div", __assign({ className: "boostpage-payment-container" }, { children: [_jsxs("div", __assign({ className: "boostpage-duration-days-cost" }, { children: [_jsx(KTextField, { label: "Days", value: totalDays.toLocaleString(), width: "49%", formik: formik, readOnly: true }), _jsx(KTextField, { label: boostCostLabel, value: "$".concat(cost.toLocaleString()), width: "49%", formik: formik, readOnly: true })] })), _jsx("div", __assign({ className: "boostpage-payment-pay-button" }, { children: _jsx(KButton, { title: runBoostTitle, variant: "primary", icon: IconBoost, type: "submit", onClick: function () { return setValidateOn(true); } }) }))] }))] }))] })) }))] })) })));
};
var mapStateToProps = function (state) { return ({
    email: getUserEmail(state),
    countries: getCountries(state),
    languages: getLanguages(state),
    userProjectBoosts: getUserProjectBoosts(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadCountries: function () { return dispatch(thunkLoadCountries()); },
    onLoadLanguages: function () { return dispatch(thunkLoadLanguages()); },
    onCheckIfPaymentNeeded: function (callback) {
        return dispatch(thunkIsBoostPaymentNeeded(callback));
    },
    onCreatePaymentIntent: function (email, numOfDays, numOfCountries, callback) {
        return dispatch(thunkCreateProjectBoostPaymentIntent(email, numOfDays, numOfCountries, callback));
    },
    onCreateBoost: function (projectId, startDate, endDate, invoiceId, countryIds, callback) {
        return dispatch(thunkCreateProjectBoost(projectId, startDate, endDate, invoiceId, countryIds, callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(BoostPage);
