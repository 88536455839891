export var CONTRACT_TYPES_LOADED = "CONTRACT_TYPES_LOADED";
export var contractTypesLoaded = function (contractTypes) { return ({
    type: CONTRACT_TYPES_LOADED,
    payload: { contractTypes: contractTypes },
}); };
export var CONTRACTS_LOADING = "CONTRACTS_LOADING";
export var contractsLoading = function () { return ({
    type: CONTRACTS_LOADING,
    payload: {},
}); };
export var CONTRACTS_LOADING_COMPLETE = "CONTRACTS_LOADING_COMPLETE";
export var contractsLoadingComplete = function () { return ({
    type: CONTRACTS_LOADING_COMPLETE,
}); };
export var CONTRACTS_LOAD_SUCCESS = "CONTRACTS_LOAD_SUCCESS";
export var contractsLoadSuccess = function (contracts) { return ({
    type: CONTRACTS_LOAD_SUCCESS,
    payload: { contracts: contracts },
}); };
export var CONTRACTS_LOAD_FAILURE = "CONTRACTS_LOAD_FAILURE";
export var contractsLoadFailure = function () { return ({
    type: CONTRACTS_LOAD_FAILURE,
}); };
export var CONTRACT_LOAD_SUCCESS = "CONTRACT_LOAD_SUCCESS";
export var contractLoadSuccess = function (contract) { return ({
    type: CONTRACT_LOAD_SUCCESS,
    payload: { contract: contract },
}); };
export var CONTRACT_LOAD_FAILURE = "CONTRACT_LOAD_FAILURE";
export var contractLoadFailure = function () { return ({
    type: CONTRACT_LOAD_FAILURE,
}); };
export var CONTRACT_REVISIONS_LOAD_SUCCESS = "CONTRACT_REVISIONS_LOAD_SUCCESS";
export var contractRevsionsLoadSuccess = function (revisions) { return ({
    type: CONTRACT_REVISIONS_LOAD_SUCCESS,
    payload: { revisions: revisions },
}); };
export var CONTRACT_REVISIONS_LOAD_FAILURE = "CONTRACT_REVISIONS_LOAD_FAILURE";
export var contractRevsionsLoadFailure = function () { return ({
    type: CONTRACT_REVISIONS_LOAD_FAILURE,
}); };
export var CONTRACT_COMMENTS_LOAD_SUCCESS = "CONTRACT_COMMENTS_LOAD_SUCCESS";
export var contractCommentsLoadSuccess = function (comments) { return ({
    type: CONTRACT_COMMENTS_LOAD_SUCCESS,
    payload: { comments: comments },
}); };
export var CONTRACT_COMMENTS_LOAD_FAILURE = "CONTRACT_COMMENTS_LOAD_FAILURE";
export var contractCommentsLoadFailure = function (comments) { return ({
    type: CONTRACT_COMMENTS_LOAD_FAILURE,
    payload: { comments: comments },
}); };
export var CONTRACT_NEW_COMMENTS_LOADED = "CONTRACT_NEW_COMMENTS_LOADED";
export var contractNewCommentsLoaded = function (comments) { return ({
    type: CONTRACT_NEW_COMMENTS_LOADED,
    payload: { comments: comments },
}); };
export var CONTRACT_RENEW_CANCEL_SOURCE = "CONTRACT_RENEW_CANCEL_SOURCE";
export var contractRenewCancelSource = function () { return ({
    type: CONTRACT_RENEW_CANCEL_SOURCE,
}); };
export var SIGNATURE_REQUESTS_BY_CONTRACT_LOADED = "SIGNATURE_REQUESTS_BY_CONTRACT_LOADED";
export var signatureRequestsByContractLoaded = function (requestsByContract) { return ({
    type: SIGNATURE_REQUESTS_BY_CONTRACT_LOADED,
    payload: { requestsByContract: requestsByContract },
}); };
export var SIGNATURE_REQUEST_LOADED = "SIGNATURE_REQUEST_LOADED";
export var signatureRequestLoaded = function (signatureRequest) { return ({
    type: SIGNATURE_REQUEST_LOADED,
    payload: { signatureRequest: signatureRequest },
}); };
export var REQUEST_SIGNATURES_LOADED = "REQUEST_SIGNATURES_LOADED";
export var requestSignaturesLoaded = function (signatures) { return ({
    type: REQUEST_SIGNATURES_LOADED,
    payload: { signatures: signatures },
}); };
export var CONTRACT_NEW_COMMENTS_KEY = "CONTRACT_NEW_COMMENTS_KEY";
export var contractNewCommentsKey = function (key) { return ({
    type: CONTRACT_NEW_COMMENTS_KEY,
    payload: { key: key },
}); };
export var TRANSACTIONS_LOADING_COMPLETE = "TRANSACTIONS_LOADING_COMPLETE";
export var transactionsLoadingComplete = function () { return ({
    type: TRANSACTIONS_LOADING_COMPLETE,
}); };
export var TRANSACTIONS_LOADING = "TRANSACTIONS_LOADING";
export var transactionsLoading = function () { return ({
    type: TRANSACTIONS_LOADING,
    payload: {},
}); };
export var TRANSACTIONS_LOAD_SUCCESS = "TRANSACTIONS_LOAD_SUCCESS";
export var transactionsLoadSuccess = function (transactions) { return ({
    type: TRANSACTIONS_LOAD_SUCCESS,
    payload: { transactions: transactions },
}); };
export var TRANSACTIONS_LOAD_FAILURE = "TRANSACTIONS_LOAD_FAILURE";
export var transactionsLoadFailure = function () { return ({
    type: TRANSACTIONS_LOAD_FAILURE,
}); };
export var TRANSACTION_STATUS_SUCCESS = "TRANSACTION_STATUS_SUCCESS";
export var transactionStatusSuccess = function () { return ({
    type: TRANSACTION_STATUS_SUCCESS,
}); };
export var TRANSACTIONS_LOAD_ALL_SUCCESS = "TRANSACTIONS_LOAD_ALL_SUCCESS";
export var transactionsLoadAllSuccess = function (transactions) { return ({
    type: TRANSACTIONS_LOAD_ALL_SUCCESS,
    payload: { transactions: transactions },
}); };
export var TRANSACTIONS_LOAD_ALL_FAILURE = "TRANSACTIONS_LOAD_ALL_FAILURE";
export var transactionsLoadAllFailure = function () { return ({
    type: TRANSACTIONS_LOAD_ALL_FAILURE,
}); };
