export var getContractTypes = function (state) {
    return state.contract.types;
};
export var getContracts = function (state) {
    var _a;
    return (_a = state.contract) === null || _a === void 0 ? void 0 : _a.data;
};
export var getLoadedContract = function (state) {
    return state.contract.loadedContract;
};
export var getContractRevisions = function (state) {
    return state.contract.revisions;
};
export var getContractComments = function (state) {
    return state.contract.comments;
};
export var getContractCancelSource = function (state) {
    return state.contract.cancelSource;
};
export var getSignatureRequestsByContract = function (state) {
    return state.contract.requestsByContract;
};
export var getLoadedSignatureRequest = function (state) {
    return state.contract.signatureRequest;
};
export var getLoadedSignatures = function (state) {
    return state.contract.signatures;
};
export var getNewCommentsKey = function (state) {
    return state.contract.commentsKey;
};
export var getTransactions = function (state) {
    return state.contract.transactions;
};
export var getAllTransactions = function (state) {
    return state.contract.allTransactions;
};
