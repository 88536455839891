import * as yup from "yup";
import { array, object, number, string } from "yup";
var formSchema = {
    name: yup.string().required("form.required_message"),
};
var fieldsSchema = yup.object().shape({
    test: yup
        .array()
        .of(yup.object().shape(formSchema))
        .required("Must have fields")
        .min(1, "Minimum of 1 field"),
});
var userExperienceNestedSchema = {
    title: string().required("Project Title is required").trim(),
    trailerVideo: string().required("Trailer Video is required").trim(),
    role: string().required("Role is required").trim(),
    budget: number().integer().typeError("Please enter a valid amount"),
    synopsis: string()
        .required("Description is required")
        .max(225, "Description must be at most 225 characters required")
        .nullable()
        .trim(),
};
export var userExperienceSchema = object().shape({
    userExperience: array()
        .of(object().shape(userExperienceNestedSchema))
        .required("Must not be empty"),
});
