import { useEffect } from "react";
var useOutsideAlert = function (ref, onOutsideClick, refsArray) {
    if (refsArray === void 0) { refsArray = []; }
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        };
        document.addEventListener("touchend", handleClickOutside);
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchend", handleClickOutside);
        };
    }, [ref] + refsArray);
};
export default useOutsideAlert;
