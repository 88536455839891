var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import KButton from "../../base-components/KButton/KButton";
import { getProjectSymbolSrc } from "../../tools/projectTools";
import { getUserTitleColor } from "../../tools/userTools";
import "./DealCard.css";
var DealCard = function (_a) {
    var otherUserName = _a.otherUserName, projectName = _a.projectName, projectType = _a.projectType, projectUserName = _a.projectUserName, userTitle = _a.userTitle, requestSent = _a.requestSent, onConfirm = _a.onConfirm, onClose = _a.onClose;
    var projectBlock = (_jsxs("div", __assign({ className: "ndacard-project-section" }, { children: [_jsx("img", { className: "ndacard-project-type-icon", src: getProjectSymbolSrc(projectType) }), _jsxs("div", __assign({ className: "ndacard-project-text-section" }, { children: [_jsx("div", __assign({ className: "ndacard-project-text-title" }, { children: projectName })), _jsxs("div", __assign({ className: "ndacard-project-text-subtitle" }, { children: ["a ", (projectType || "").toLowerCase(), " by", _jsx("a", __assign({ className: "ndacard-user-link", style: { color: getUserTitleColor(userTitle || "") } }, { children: " " + projectUserName }))] }))] }))] })));
    return (_jsx("div", __assign({ className: "dealcard-container" }, { children: requestSent ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "dealcard-info-section" }, { children: [_jsx("div", __assign({ className: "dealcard-title" }, { children: "Deal Requested" })), projectBlock, _jsxs("p", __assign({ className: "dealcard-info-text" }, { children: ["A deal request between you and ", otherUserName, " has been sent."] })), _jsx("p", __assign({ className: "dealcard-info-text" }, { children: "Fee free to contact us for any further inquiries." }))] })), _jsx("div", __assign({ className: "dealcard-action-section" }, { children: _jsx(KButton, { title: "Close", variant: "primary", onClick: onClose }) }))] })) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "dealcard-info-section" }, { children: [_jsx("div", __assign({ className: "dealcard-title" }, { children: "Deal Request" })), projectBlock, _jsx("p", __assign({ className: "dealcard-info-text" }, { children: "Looks like you're interested in closing a deal!" })), _jsxs("p", __assign({ className: "dealcard-info-text" }, { children: ["By clicking on confirm, you'd be acknowledging that you've been in contact with ", otherUserName, " and have agreed to work on a deal. Confirming your action will notify both parties."] }))] })), _jsx("div", __assign({ className: "dealcard-action-section" }, { children: _jsx(KButton, { title: "Confirm", variant: "primary", onClick: onConfirm }) }))] })) })));
};
export default DealCard;
