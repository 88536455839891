import { getValueInCurrency, getMonthYearDate, getImageUrl, getTransparentImagePath, } from "./generalTools";
export var isScriptProject = function (projectType) {
    return (projectType || "script").toLowerCase() == "script";
};
export var isFilmProject = function (projectType) {
    return (projectType || "script").toLowerCase() == "film";
};
export var getProjectSymbolSrc = function (projectType) {
    if (isScriptProject(projectType))
        return "/images/Scriptofilm_icon_colored_project 1.png";
    if (isFilmProject(projectType))
        return "/images/Scriptofilm_icon_colored_project 3.png";
    return getTransparentImagePath();
};
export var getProjectDate = function (project) {
    return getMonthYearDate(project.project_date);
};
export var getProjectAcquisitionBudget = function (project) {
    return getValueInCurrency(project.project_acquisition_budget);
};
export var getProjectProductionBudget = function (project) {
    return getValueInCurrency(project.project_production_budget);
};
export var getProjectImage = function (project) {
    var _a;
    if (project && project.project_coverimage_paths != null) {
        return getImageUrl((_a = project.project_coverimage_paths) === null || _a === void 0 ? void 0 : _a.split(",", 1)[0]);
    }
    return getTransparentImagePath();
};
export var getProjectSmallImage = function (project) {
    var _a;
    if (project && project.project_coverimage_small_paths != null) {
        return getImageUrl((_a = project.project_coverimage_small_paths) === null || _a === void 0 ? void 0 : _a.split(",", 1)[0]);
    }
    return getTransparentImagePath();
};
export var getDefaultCover = function (project) {
    if (!project)
        return getTransparentImagePath();
    return getProjectSymbolSrc(project.project_type_name);
};
