var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { IoMdTrash as IconDelete } from "react-icons/io";
import { IoIosAddCircle as IconAdd } from "react-icons/io";
import { getValueInCurrency } from "../../tools/generalTools";
import KTextField from "../../base-components/KTextField/KTextField";
import KSelector from "../../base-components/KSelector/KSelector";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import KButton from "../../base-components/KButton/KButton";
import "./EditUserLegalitiesPage.css";
var EditUserLegalitiesPage = function (_a) {
    var editUserPopupOnceClosed = _a.editUserPopupOnceClosed, scrollParent = _a.scrollParent, userData = _a.userData, setUserData = _a.setUserData, countries = _a.countries, projectGenres = _a.projectGenres, projectSubtypes = _a.projectSubtypes, onSaveUpdates = _a.onSaveUpdates;
    var width = "380px";
    var maxFundedProjects = 1000;
    var ticketSizes = (process.env.INVESTMENT_TICKET_SIZES || "")
        .split(",")
        .map(function (value, key) {
        return {
            name: getValueInCurrency(value),
            value: parseInt(value),
        };
    });
    var onSubmit = function (data) {
        onSaveUpdates(editUserPopupOnceClosed ? "" : "interests");
    };
    useEffect(function () {
        if (scrollParent.scrollTo)
            scrollParent.scrollTo(0, 0);
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "edituserlegalitiespage-container" }, { children: [_jsx("div", __assign({ className: "edituserlegalitiespage-title" }, { children: "Legalities" })), _jsxs("div", { children: [_jsx("div", __assign({ className: "edituserlegalitiespage-content" }, { children: _jsx("div", __assign({ className: "edituserlegalitiespage-info-section" }, { children: _jsxs("div", __assign({ className: "edituserlegalitiespage-row" }, { children: [_jsx(KTextField, { placeholder: "Legal Name", label: "Legal Name", width: width, marginVertical: "10px", marginRight: "20px", value: userData.legalName || "", onChange: function (e) {
                                                setUserData(__assign(__assign({}, userData), { legalName: e.target.value }));
                                            } }), _jsx(KTextField, { placeholder: "https://", label: "Website", autoCapitalize: "none", width: width, marginVertical: "10px", marginRight: "20px", value: userData.website || "", onChange: function (e) {
                                                setUserData(__assign(__assign({}, userData), { website: e.target.value }));
                                            } })] })) })) })), _jsx("div", __assign({ className: "edituserlegalitiespage-extra-title" }, { children: "Investment" })), _jsx("div", __assign({ className: "edituserlegalitiespage-text" }, { children: "How many projects have you already invested in?" })), _jsx("div", __assign({ className: "edituserlegalitiespage-content" }, { children: _jsxs("div", __assign({ className: "edituserlegalitiespage-info-section" }, { children: [(userData.investmentProjects || []).map(function (project, key) { return (_jsxs("div", __assign({ className: "edituserlegalitiespage-project-box" }, { children: [_jsx("div", __assign({ className: "edituserlegalitiespage-row ".concat(userData.investmentProjects
                                                    .length > 1
                                                    ? "edituserlegalitiespage-delete-on"
                                                    : "") }, { children: _jsx(KTextField, { placeholder: "Project title", label: "", width: "100%", marginVertical: "10px", marginRight: "20px", value: project.title || "", onChange: function (e) {
                                                        project["title"] =
                                                            e.target.value;
                                                        project["isEdited"] = true;
                                                        var updatedArray = __spreadArray([], userData.investmentProjects, true);
                                                        setUserData(__assign(__assign({}, userData), { investmentProjects: updatedArray }));
                                                    } }) })), userData.investmentProjects.length >
                                                1 && (_jsx("div", __assign({ className: "edituserlegalitiespage-delete-button", onClick: function () {
                                                    return setUserData(__assign(__assign({}, userData), { investmentProjects: userData.investmentProjects.filter(function (p) {
                                                            return p.key !==
                                                                project.key;
                                                        }) }));
                                                } }, { children: _jsx(IconDelete, {}) })))] }), key)); }), _jsxs("div", __assign({ className: "edituserlegalitiespage-icon-container", onClick: function () {
                                            if (userData.investmentProjects.length >=
                                                maxFundedProjects)
                                                return alert("No more than ".concat(maxFundedProjects, " projects allowed"));
                                            setUserData(__assign(__assign({}, userData), { investmentProjects: userData.investmentProjects.concat([
                                                    {
                                                        title: "",
                                                        key: 1 +
                                                            Math.max.apply(Math, userData.investmentProjects.map(function (e) { return e.key; })),
                                                    },
                                                ]) }));
                                        } }, { children: [_jsx(IconAdd, { className: "edituserlegalitiespage-icon" }), _jsx("div", __assign({ className: "edituserlegalitiespage-button-text" }, { children: "add another project" }))] }))] })) })), _jsx("div", __assign({ className: "edituserlegalitiespage-extra-text" }, { children: "What were the genres of these movies or series?" })), _jsx("div", __assign({ className: "edituserlegalitiespage-extra-content" }, { children: _jsx("div", __assign({ className: "edituserlegalitiespage-extra-section" }, { children: _jsx("div", __assign({ className: "edituserlegalitiespage-extra-row" }, { children: _jsx(KSelector, { identifier: "2", placeholder: "Genres", label: "", width: "1000px", multiple: true, marginVertical: "10px", marginRight: "20px", items: projectGenres, titleKey: "project_genre_name", scrollParent: scrollParent, itemKey: "project_genre_id", onChange: function (items) {
                                            return setUserData(__assign(__assign({}, userData), { investmentGenres: items }));
                                        }, selectedItems: userData.investmentGenres }) })) })) })), _jsx("div", __assign({ className: "edituserlegalitiespage-checkbox-section" }, { children: projectSubtypes === null || projectSubtypes === void 0 ? void 0 : projectSubtypes.map(function (subtype, key) { return (_jsx(KCheckbox, { title: subtype.project_subtype_name, checked: (userData.investmentTypes || []).includes(subtype.project_subtype_id), onChange: function (e) {
                                    var investmentTypes = userData.investmentTypes || [];
                                    if (e.target.checked) {
                                        investmentTypes =
                                            investmentTypes.concat([
                                                subtype.project_subtype_id,
                                            ]);
                                    }
                                    else {
                                        investmentTypes =
                                            investmentTypes.filter(function (e) {
                                                return e !==
                                                    subtype.project_subtype_id;
                                            });
                                    }
                                    setUserData(__assign(__assign({}, userData), { investmentTypes: investmentTypes }));
                                } }, key)); }) })), _jsx("div", __assign({ className: "edituserlegalitiespage-extra-text" }, { children: "In which countries were these projects produced?" })), _jsx("div", __assign({ className: "edituserlegalitiespage-extra-content" }, { children: _jsx("div", __assign({ className: "edituserlegalitiespage-extra-section" }, { children: _jsx("div", __assign({ className: "edituserlegalitiespage-extra-row" }, { children: _jsx(KSelector, { identifier: "2", placeholder: "Countries", label: "", width: "1000px", multiple: true, marginVertical: "10px", marginRight: "20px", items: countries, titleKey: "country_name", scrollParent: scrollParent, itemKey: "country_id", onChange: function (items) {
                                            return setUserData(__assign(__assign({}, userData), { investmentCountries: items }));
                                        }, selectedItems: userData.investmentCountries }) })) })) })), _jsx("div", __assign({ className: "edituserlegalitiespage-extra-text" }, { children: "What were the ticket sizes of the investments?" })), _jsx("div", __assign({ className: "edituserlegalitiespage-extra-content" }, { children: _jsxs("div", __assign({ className: "edituserlegalitiespage-extra-section" }, { children: [_jsx("div", __assign({ className: "edituserlegalitiespage-extra-row", style: { marginBottom: "20px" } }, { children: _jsx(KSelector, { identifier: "2", placeholder: "Ticket sizes", label: "", width: "1000px", multiple: true, marginVertical: "10px", marginRight: "20px", items: ticketSizes, titleKey: "name", scrollParent: scrollParent, itemKey: "value", onChange: function (items) {
                                                return setUserData(__assign(__assign({}, userData), { investmentTickets: items }));
                                            }, selectedItems: userData.investmentTickets }) })), _jsx(KButton, { title: !editUserPopupOnceClosed
                                            ? "Next | Interests"
                                            : "Save", variant: "primary", marginVertical: "10px", onClick: function () { return onSubmit(); }, type: "submit" })] })) }))] })] })) }));
};
export default EditUserLegalitiesPage;
