var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, createRef, useRef } from "react";
import kachat from "kachat";
import { useHistory, useParams } from "react-router-dom";
import { MdAdd as IconAdd } from "react-icons/md";
import { IoIosArrowBack as IconBack } from "react-icons/io";
import { MdChat as IconChat } from "react-icons/md";
import { MdSend as IconSend } from "react-icons/md";
import { FaSearch as IconSearch } from "react-icons/fa";
import { connect } from "react-redux";
import { getKachatId, getUserTitle, getUser, } from "../../redux/selectors/authSelectors";
import KTextField from "../../base-components/KTextField/KTextField";
import KPopup from "../../base-components/KPopup/KPopup";
import DealCard from "../../components/DealCard/DealCard";
import NdaCard from "../../components/NdaCard/NdaCard";
import { getDateTimeMinute, getLocation, } from "../../tools/generalTools";
import { getMessagingProjects, } from "../../redux/selectors/projectSelectors";
import { thunkLoadProjectsByIds, } from "../../redux/thunks/projectThunks";
import KImage from "../../base-components/KImage/KImage";
import { getUserProfilePic, } from "../../tools/userTools";
import { thunkAddRatingByUser, thunkLoadUserByUserId, thunkLoadUserRatingByUserId, thunkLoadUsersByIds, } from "../../redux/thunks/userThunks";
import { getLoadedUserRatingByUserId, getMessagingUsers, } from "../../redux/selectors/userSelectors";
import { thunkApproveNda, thunkCreateDeal, thunkLoadUserDeals, thunkLoadUserNdas, thunkRejectNdaSignature, thunkRequestNda, thunkSignNda, thunkUpdateNdaTemplate, } from "../../redux/thunks/dealThunks";
import { getUserDeals, getUserNdas } from "../../redux/selectors/dealSelectors";
import { thunkSetChatServiceError } from "../../redux/thunks/authThunks";
import { canCreateContract } from "../../validators/contractValidator";
import { canCreateProjects, canViewMarket, canViewNetwork, } from "../../validators/profileValidator";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import "./MessagingPage.css";
import KTextArea from "../../base-components/KTextArea/KTextArea";
import RateUser from "../../components/RateUser/RateUser";
import { getColorMode } from "../../redux/selectors/generalSelectors";
var Messaging = function (_a) {
    var _b, _c;
    var userId = _a.userId, user = _a.user, userTitle = _a.userTitle, userNdas = _a.userNdas, loadedUsers = _a.loadedUsers, loadedProjects = _a.loadedProjects, onLoadUsers = _a.onLoadUsers, onLoadUser = _a.onLoadUser, userDeals = _a.userDeals, colorMode = _a.colorMode, userRatingByUserId = _a.userRatingByUserId, addRatingByUser = _a.addRatingByUser, onLoadUserRatingByUserId = _a.onLoadUserRatingByUserId, onLoadUserDeals = _a.onLoadUserDeals, onSendNdaRequest = _a.onSendNdaRequest, onSignNda = _a.onSignNda, onLoadProjects = _a.onLoadProjects, onCreateDeal = _a.onCreateDeal, onLoadUserNdas = _a.onLoadUserNdas, onApproveSignedNda = _a.onApproveSignedNda, onRejectSignedNda = _a.onRejectSignedNda, onUpdateNdaTemplate = _a.onUpdateNdaTemplate, onChatServiceError = _a.onChatServiceError;
    var limit = 20;
    var chId = useParams().chId;
    var history = useHistory();
    var messageRef = useRef();
    var inputRef = createRef();
    var hiddenInputRef = createRef();
    var _d = useState(34), textBoxHeight = _d[0], setTextBoxHeight = _d[1];
    var _e = useState(""), message = _e[0], _setMessage = _e[1];
    var _f = useState(""), searchText = _f[0], setSearchText = _f[1];
    var _g = useState([]), projectIds = _g[0], setProjectIds = _g[1];
    var _h = useState([]), kachatIds = _h[0], setKachatIds = _h[1];
    var _j = useState(false), isChatMode = _j[0], setIsChatMode = _j[1];
    var _k = useState(false), ratingMode = _k[0], setIsRatingMode = _k[1];
    var _l = useState(false), isCollaborationMode = _l[0], setIsCollaborationMode = _l[1];
    var _m = useState(0), unreadCollaborations = _m[0], setUnreadCollaborations = _m[1];
    var _o = useState(0), unreadConnections = _o[0], setUnreadConnections = _o[1];
    var _p = useState("-50000px"), textboxImageTop = _p[0], setTextboxImageTop = _p[1];
    var _q = useState(false), showMessagingInfoBox = _q[0], setShowMessagingInfoBox = _q[1];
    var setMessage = function (val) {
        _setMessage(val);
        messageRef.current = val;
    };
    var colorIndicators = {
        0: "#0091D5",
        1: "#B3C100",
        2: "#D32D41",
        3: "#EA6A47",
    };
    var _r = useState(false), shouldScrollToBottom = _r[0], _setShouldScrollToBottom = _r[1];
    var shouldScrollToBottomRef = React.useRef(shouldScrollToBottom);
    var setShouldScrollToBottom = function (data) {
        shouldScrollToBottomRef.current = data;
        _setShouldScrollToBottom(data);
    };
    var _s = useState(false), isConnected = _s[0], _setIsConnected = _s[1];
    var isConnectedRef = React.useRef(isConnected);
    var setIsConnected = function (data) {
        isConnectedRef.current = data;
        _setIsConnected(data);
    };
    var _t = useState(""), channelId = _t[0], _setChannelId = _t[1];
    var channelIdRef = React.useRef(channelId);
    var setChannelId = function (data) {
        channelIdRef.current = data;
        _setChannelId(data);
    };
    var _u = useState(__assign({}, kachat.convoByChannel)), convosInfo = _u[0], _setConvosInfo = _u[1];
    var convosInfoRef = React.useRef(convosInfoRef);
    var setConvosInfo = function (data) {
        convosInfoRef.current = data;
        _setConvosInfo(data);
    };
    var _v = useState(false), isDealPopupOpen = _v[0], setIsDealPopupOpen = _v[1];
    var _w = useState(false), isNdaPopupOpen = _w[0], setIsNdaPopupOpen = _w[1];
    useDocumentTitle("Scriptofilm | Messaging");
    var handleTextboxHeight = function () {
        setTextBoxHeight("auto");
    };
    var getSearchBoxHeight = function () {
        // return window.innerHeight - (window.innerWidth < 800 ? 80 : 160);
        return (window.visualViewport.height -
            (window.innerWidth < 800 ? 80 : window.innerHeight < 800 ? 80 : 260));
    };
    var getChatBoxHeight = function () {
        // return window.innerHeight - (window.innerWidth < 800 ? 0 : 160);
        return (window.visualViewport.height -
            (window.innerWidth < 800 ? 0 : window.innerHeight < 800 ? 0 : 260));
    };
    var getBackgroundImage = function () {
        return "/images/".concat(userTitle === null || userTitle === void 0 ? void 0 : userTitle.toLowerCase(), "-symbol.png");
    };
    var _x = useState(getChatBoxHeight()), chatBoxHeight = _x[0], setChatBoxHeight = _x[1];
    var _y = useState(getSearchBoxHeight()), searchBoxHeight = _y[0], setSearchBoxHeight = _y[1];
    var onRateUser = function () {
        var userId = getUserData(convosInfo[channelId]).user_id;
        if (userId)
            onLoadUser(userId, function (err) {
                if (err)
                    return alert(err);
                onLoadUserRatingByUserId(userId, function (err, rating) {
                    if (err)
                        return alert(err);
                    if (rating === 0)
                        setIsRatingMode(true);
                });
            });
    };
    var sendMessage = function (event) {
        var _a, _b;
        event.preventDefault();
        hiddenInputRef.current.focus();
        if (message) {
            kachat.sendMessage(channelId, message, function (err) {
                setMessage("");
            });
            var messageCount = (_b = (_a = convosInfo[channelId]) === null || _a === void 0 ? void 0 : _a.messages) === null || _b === void 0 ? void 0 : _b.length;
            var countTrigger = parseInt(process.env.MESSAGE_COUNT_RATING_TRIGGER || 10);
            if (messageCount === countTrigger ||
                messageCount === 10 * countTrigger) {
                onRateUser();
                inputRef.current.blur();
                hiddenInputRef.current.blur();
            }
        }
        scrollToBottomOfChat();
    };
    var enableConvo = function (channelName, channelId) {
        if (isConnectedRef.current == false) {
            connectUser();
        }
        handleScroll();
        kachat.getConversation(channelId, function (err, convo) {
            if (err) {
                connectUser();
                return console.log(err);
            }
            window.history.replaceState(null, "", "/platform/messaging/" + channelId);
        });
    };
    var _z = useState([]), disregardProjectIds = _z[0], setDisregardProjectIds = _z[1];
    var _0 = useState([]), disregardKachatIds = _0[0], setDisregardKachatIds = _0[1];
    var updateConvoData = function (convoByChannel, openChannelId) {
        var convos = Object.values(convoByChannel);
        var newKachatIds = convos.map(function (e) { return e.userIds; }).flat();
        var newProjectIds = convos
            .filter(function (e) { return e.key; })
            .map(function (e) { return e.key.split("_")[0]; });
        setChannelId(openChannelId);
        setConvosInfo(__assign({}, convoByChannel));
        setProjectIds(__spreadArray(__spreadArray([], projectIds, true), newProjectIds, true));
        setKachatIds(__spreadArray(__spreadArray([], kachatIds, true), newKachatIds, true));
        setUnreadCollaborations(convos
            .filter(function (e) { return isCollaboration(e); })
            .reduce(function (a, b) { return a + b.unread; }, 0));
        setUnreadConnections(convos
            .filter(function (e) { return !isCollaboration(e); })
            .reduce(function (a, b) { return a + b.unread; }, 0));
        onLoadUsers(newKachatIds.filter(function (e) { return disregardKachatIds.includes(e) === false; }));
        onLoadProjects(newProjectIds.filter(function (e) { return disregardProjectIds.includes(e) === false; }));
        setDisregardProjectIds(disregardProjectIds + newProjectIds);
        setDisregardKachatIds(disregardKachatIds + newKachatIds);
    };
    var isCollaboration = function (convo) {
        return convo && convo.key != null;
    };
    var isOneToOneConvo = function (convo) {
        return convo && convo.key == null;
    };
    var getUserData = function (convo) {
        if (!convo || !convo.userIds)
            return {};
        var kachatId = convo.userIds[0];
        return loadedUsers && loadedUsers[kachatId]
            ? loadedUsers[kachatId]
            : {};
    };
    var getUserDataByMessage = function (message) {
        if (!message || !message.message_user_id_fk)
            return {};
        return loadedUsers && loadedUsers[message.message_user_id_fk]
            ? loadedUsers[message.message_user_id_fk]
            : {};
    };
    var getProjectData = function (key) {
        key = key ? key.split("_")[0] : "";
        return loadedProjects && loadedProjects[key] ? loadedProjects[key] : {};
    };
    var getProjectId = function (convo) {
        if (convo) {
            var project = getProjectData(convo.key);
            return project ? project.project_id : "";
        }
        return "";
    };
    var getProjectName = function (convo) {
        if (convo) {
            var project = getProjectData(convo.key);
            return project ? project.project_title : "";
        }
        return "";
    };
    var hasFullProject = function (convo) {
        if (convo) {
            var project = getProjectData(convo.key);
            return project ? project.hasFullProject : false;
        }
        return false;
    };
    var getProjectType = function (convo) {
        if (convo) {
            var project = getProjectData(convo.key);
            return project ? project.project_type_name : "";
        }
        return "";
    };
    var getProjectUserTitle = function (convo) {
        if (convo) {
            var project = getProjectData(convo.key);
            return project ? project.user_title_name : "";
        }
        return "";
    };
    var getProjectUserName = function (convo) {
        if (convo) {
            var project = getProjectData(convo.key);
            return project
                ? "".concat(project.user_first_name || "", " ").concat(project.user_last_name || "")
                : "";
        }
        return "";
    };
    var getProjectNda = function (convo) {
        if (convo) {
            var userId_1 = getNonOwnerUserId(convo);
            var projectId_1 = getProjectData(convo.key).project_id;
            var projectNdas = userNdas.filter(function (nda) {
                return nda.nda_user_id_fk == userId_1 &&
                    nda.nda_project_id_fk == projectId_1;
            });
            return projectNdas.length ? projectNdas[0] : {};
        }
        return {};
    };
    var getNonOwnerUserId = function (convo) {
        if (convo) {
            return isProjectOwner(convo.key)
                ? getOtherUserId(convo)
                : user.user_id;
        }
        return null;
    };
    var getProjectNdaStatus = function (convo) {
        return getProjectNda(convo).nda_status;
    };
    var isNdaActive = function (convo) {
        return [
            "requested",
            "signature-pending",
            "signature-rejected",
            "signed",
            "approved",
        ].includes(getProjectNdaStatus(convo));
    };
    var isNdaPending = function (convo) {
        return ["signature-pending", "signature-rejected", "signed"].includes(getProjectNdaStatus(convo));
    };
    var isNdaApproved = function (convo) {
        return getProjectNdaStatus(convo) == "approved";
    };
    var getProjectDeal = function (convo) {
        if (convo) {
            var userId_2 = getNonOwnerUserId(convo);
            var projectId_2 = getProjectData(convo.key).project_id;
            var projectDeals = userDeals.filter(function (deal) {
                return deal.deal_user_id_fk == userId_2 &&
                    deal.deal_project_id_fk == projectId_2;
            });
            return projectDeals.length ? projectDeals[0] : {};
        }
        return {};
    };
    var isDealRequested = function (convo) {
        return getProjectDeal(convo).deal_status ? true : false;
    };
    var isProjectOwner = function (key) {
        return user.user_id == getProjectData(key).project_user_id_fk;
    };
    var getProjectNdaFilePath = function (convo) {
        return getProjectNda(convo).nda_path;
    };
    var getProjectSignedNdaFilePath = function (convo) {
        return getProjectNda(convo).nda_signed_path;
    };
    var getNdaIconStatus = function (convo) {
        return isNdaApproved(convo) ? (_jsx("img", { className: "messaging-action-state-image", src: "/images/green-check.png" })) : isNdaPending(convo) ? (_jsx("img", { className: "messaging-action-state-image", src: "/images/pending.png" })) : (_jsx(_Fragment, {}));
    };
    var getDealIconStatus = function (convo) {
        return isDealRequested(convo) ? (_jsx("img", { className: "messaging-action-state-image", src: "/images/green-check.png" })) : (_jsx(_Fragment, {}));
    };
    var getNdaSideIconStatus = function (convo) {
        return isNdaApproved(convo) ? (_jsx("img", { src: "/images/green-check.png" })) : isNdaPending(convo) ? (_jsx("img", { src: "/images/pending.png" })) : (_jsx(_Fragment, {}));
    };
    var getDealSideIconStatus = function (convo) {
        return isDealRequested(convo) ? (_jsx("img", { src: "/images/green-check.png" })) : (_jsx(_Fragment, {}));
    };
    var sendNdaRequest = function (file) {
        if (!file)
            return alert("Please upload an NDA.");
        var userId = getNonOwnerUserId(convosInfo[channelId]);
        var projectId = getProjectData(convosInfo[channelId].key).project_id;
        var projectUserId = getProjectData(convosInfo[channelId].key).project_user_id_fk;
        onSendNdaRequest(userId, projectId, projectUserId, file, channelId);
    };
    var sendSignedNda = function (file) {
        if (!file)
            return alert("Please upload the signed NDA.");
        var userId = getNonOwnerUserId(convosInfo[channelId]);
        var projectId = getProjectData(convosInfo[channelId].key).project_id;
        var projectUserId = getProjectData(convosInfo[channelId].key).project_user_id_fk;
        onSignNda(userId, projectId, projectUserId, file, channelId);
    };
    var rejectSignedNda = function () {
        var userId = getNonOwnerUserId(convosInfo[channelId]);
        var projectId = getProjectData(convosInfo[channelId].key).project_id;
        var projectUserId = getProjectData(convosInfo[channelId].key).project_user_id_fk;
        onRejectSignedNda(userId, projectId, channelId);
    };
    var approveSignedNda = function (giveAccess, expiryDate) {
        var userId = getNonOwnerUserId(convosInfo[channelId]);
        var projectId = getProjectData(convosInfo[channelId].key).project_id;
        onApproveSignedNda(userId, projectId, giveAccess, expiryDate, channelId);
    };
    var updateNdaTemplate = function (file) {
        if (!file)
            return alert("Please upload a new version of the NDA to update.");
        var userId = getNonOwnerUserId(convosInfo[channelId]);
        var projectId = getProjectData(convosInfo[channelId].key).project_id;
        var projectUserId = getProjectData(convosInfo[channelId].key).project_user_id_fk;
        onUpdateNdaTemplate(userId, projectId, projectUserId, file);
    };
    var sendDealRequest = function () {
        var userId = getNonOwnerUserId(convosInfo[channelId]);
        var projectId = getProjectData(convosInfo[channelId].key).project_id;
        onCreateDeal(userId, projectId);
    };
    var getOtherUserName = function (convo) {
        return "".concat(getUserData(convo).user_first_name || "", " ").concat(getUserData(convo).user_last_name || "");
    };
    var getOtherUserNameByMessage = function (message) {
        return "".concat(getUserDataByMessage(message).user_first_name || "", " ").concat(getUserDataByMessage(message).user_last_name || "");
    };
    var getOtherUserId = function (convo) {
        return getUserData(convo).user_id;
    };
    var getOtherUserTitle = function (convo) {
        var title = getUserData(convo).user_title_name || "unknown";
        return title === null || title === void 0 ? void 0 : title.toLowerCase();
    };
    var getOtherUserTitleByMessage = function (message) {
        var title = getUserDataByMessage(message).user_title_name || "unknown";
        return title === null || title === void 0 ? void 0 : title.toLowerCase();
    };
    var getOtherUserTitleColor = function (convo) {
        return "var(--app-".concat(getOtherUserTitle(convo), "-color)");
    };
    var getOtherUserTitleColorByMessage = function (message) {
        return "var(--app-".concat(getOtherUserTitleByMessage(message), "-color)");
    };
    var getOtherUserLocation = function (convo) {
        var userData = getUserData(convo);
        return getLocation(userData.country_name, userData.city_name);
    };
    var scrollToBottomOfChat = function () {
        setTimeout(function () {
            var container = document.getElementsByClassName("messaging-convo-container")[0];
            if (container && container.scrollHeight)
                container.scrollTo(0, container.scrollHeight);
            hideNewMessagesButton();
        }, 0);
    };
    var scrollToChatLocation = function (scrollTop) {
        setTimeout(function () {
            var container = document.getElementsByClassName("messaging-convo-container")[0];
            container.scrollTo(0, scrollTop);
        }, 0);
    };
    var hideNewMessagesButton = function () {
        var _a;
        if ((_a = document.getElementsByClassName("messaging-convo-unread-messages")) === null || _a === void 0 ? void 0 : _a.length)
            document.getElementsByClassName("messaging-convo-unread-messages")[0].style.display = "none";
    };
    var showNewMessagesButton = function () {
        var _a;
        if ((_a = document.getElementsByClassName("messaging-convo-unread-messages")) === null || _a === void 0 ? void 0 : _a.length)
            document.getElementsByClassName("messaging-convo-unread-messages")[0].style.display = "flex";
    };
    var lastDateByChat = {};
    var connectUser = function () {
        kachat.reconnect(user.user_email, user.user_kachat_id, function (err) {
            if (err && err !== "user already connected") {
                console.log("Could not connect to messaging service..");
                window.history.replaceState(null, "", "/platform/messaging");
                onChatServiceError();
                return;
            }
            setIsConnected(true);
            if (chId && chId !== "" && chId !== "undefined")
                enableConvo(null, chId);
            updateConvoData(kachat.convoByChannel, kachat.openChannelId);
        });
        kachat.onConvoUpdate(function (channelId, openChannelId) {
            var _a, _b;
            var lastDate = (_b = (_a = kachat.convoByChannel[openChannelId]) === null || _a === void 0 ? void 0 : _a.messages[0]) === null || _b === void 0 ? void 0 : _b.created_at;
            updateConvoData(kachat.convoByChannel, kachat.openChannelId);
            if (shouldScrollToBottomRef.current)
                scrollToBottomOfChat();
            else if (lastDateByChat[openChannelId] &&
                lastDate &&
                new Date(lastDateByChat[openChannelId]) < new Date(lastDate))
                showNewMessagesButton();
            lastDateByChat[openChannelId] = lastDate;
        });
    };
    var leaveChatMode = function (callback) {
        setChannelId(null);
        setIsChatMode(false);
        setIsDealPopupOpen(false);
        setIsNdaPopupOpen(false);
        kachat.exitConvo(callback);
    };
    var getChatBoxWidth = function () {
        var container = document.getElementsByClassName("messaging-chatbox");
        if (container && container.length)
            return container[0].clientWidth || 80;
        return 80;
    };
    var handleScroll = function () {
        setShouldScrollToBottom(document.getElementsByClassName("messaging-convo-container")[0]
            .scrollHeight -
            document.getElementsByClassName("messaging-convo-container")[0]
                .scrollTop -
            document.getElementsByClassName("messaging-convo-container")[0]
                .clientHeight <
            10 ||
            document.getElementsByClassName("messaging-convo-container")[0]
                .scrollHeight <
                document.getElementsByClassName("messaging-convo-container")[0].clientHeight +
                    10);
    };
    useEffect(function () {
        window.scrollTo(0, 1);
        if (channelIdRef.current)
            setIsChatMode(true);
        if (convosInfoRef.current && channelIdRef.current)
            setIsCollaborationMode(isCollaboration(convosInfoRef.current[channelIdRef.current]));
    }, [channelIdRef.current]);
    useEffect(function () {
        var _a;
        var isSubscribed = true;
        kachat.setMessagesBatchSize(limit);
        handleScroll();
        function handleResize() {
            setShowMessagingInfoBox(canCreateProjects() &&
                window.innerHeight > 800 &&
                window.innerWidth > 800);
            if (document.getElementsByClassName("messaging-chatbox")[0]) {
                setChatBoxHeight(getChatBoxHeight());
                setSearchBoxHeight(getSearchBoxHeight());
                document.body.parentElement.scrollTo(0, 0);
            }
        }
        function onVisibilityChange() {
            console.log(document.hidden, document.visibilityState);
            if (document.hidden == false && isConnectedRef.current == false) {
                window.history.replaceState(null, "", "/platform/messaging");
                leaveChatMode();
                connectUser();
            }
        }
        var reloadDealsAndNdas = setInterval(function () {
            // reload deals and ndas every 15 seconds
            // @TODO: make use of long polling or server sent events
            // onLoadUserDeals(user.user_id, true);
            onLoadUserNdas(user.user_id, true);
        }, 15000);
        var resizeInterval = setInterval(handleResize, 50);
        document.addEventListener("visibilitychange", onVisibilityChange, false);
        (_a = document
            .getElementsByClassName("messaging-convo-container")[0]) === null || _a === void 0 ? void 0 : _a.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleResize);
        kachat.onDisconnect(function () {
            if (isSubscribed)
                setIsConnected(false);
            if (isSubscribed)
                leaveChatMode(function () {
                    return window.history.replaceState(null, "", "/platform/messaging");
                });
            console.log("Chat disconnect event received");
            // onChatServiceError();
            // connectUser();
            // kachat.onConvoUpdate((channelId, openChannelId) => {
            //     updateConvoData(kachat.convoByChannel, kachat.openChannelId);
            //     scrollToBottomOfChat();
            // });
        });
        onLoadUserDeals(user.user_id);
        onLoadUserNdas(user.user_id);
        leaveChatMode();
        connectUser();
        return function cleanup() {
            var _a;
            isSubscribed = false;
            document.removeEventListener("visibilitychange", onVisibilityChange);
            (_a = document
                .getElementsByClassName("messaging-convo-container")[0]) === null || _a === void 0 ? void 0 : _a.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
            window.updateConvoData = function () { };
            clearInterval(reloadDealsAndNdas);
            clearInterval(resizeInterval);
            leaveChatMode();
        };
    }, []);
    // useEffect(() => {
    //     handleTextboxHeight();
    // }, [message]);
    useEffect(function () {
        var _a;
        if (textBoxHeight === "auto")
            ;
        setTextBoxHeight("".concat(Math.min(((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.scrollHeight) + 3, 53), "px"));
    }, [textBoxHeight]);
    useEffect(function () {
        if (window.location.pathname == "/platform/messaging")
            leaveChatMode();
    }, [window.location.pathname]);
    useEffect(function () {
        setShowMessagingInfoBox(canCreateProjects());
    }, []);
    return (_jsxs("div", __assign({ className: "messaging-container" }, { children: [_jsx(KPopup, __assign({ active: ratingMode, onClose: function () { return setIsRatingMode(false); } }, { children: _jsx(RateUser, { loadedUserRatingByUserId: userRatingByUserId, user: getUserData(convosInfo[channelId]), addRatingByUserRate: function (user, rating) {
                        addRatingByUser(user, rating);
                    }, setRatingStars: setIsRatingMode }) })), _jsxs("div", __assign({ className: "messaging-title" }, { children: [_jsx("div", __assign({ className: "messaging-title-icon" }, { children: _jsx(IconChat, {}) })), _jsx("div", __assign({ className: "messaging-title-text" }, { children: "Contacts" }))] })), _jsxs("div", __assign({ className: "messaging-wrapper" }, { children: [_jsxs("div", __assign({ className: "messaging-chatbox ".concat(isChatMode ? "messaging-chat-mode" : ""), style: {
                            height: "".concat(chatBoxHeight, "px"),
                            maxHeight: "".concat(chatBoxHeight, "px"),
                            minHeight: "".concat(chatBoxHeight, "px"),
                        } }, { children: [_jsxs("div", __assign({ className: "messaging-chatbox-top" }, { children: [_jsxs("div", __assign({ className: "messaging-top-info-section" }, { children: [_jsx("div", __assign({ className: "messaging-back-icon", style: {
                                                    display: isChatMode ? "flex" : "none",
                                                }, onClick: function () {
                                                    leaveChatMode();
                                                    window.history.replaceState(null, "", "/platform/messaging");
                                                } }, { children: _jsx(IconBack, {}) })), _jsx("div", __assign({ className: "messaging-add-chat" }, { children: channelId ? (_jsxs("div", __assign({ className: "messaging-chat-info", onClick: function () {
                                                        if (!isCollaboration(convosInfo[channelId])) {
                                                            if (canViewNetwork())
                                                                history.push("/platform/network/user/".concat(getUserData(convosInfo[channelId]).user_id));
                                                        }
                                                        else {
                                                            if (canViewMarket())
                                                                history.push("/platform/market/project/".concat(getProjectId(convosInfo[channelId])));
                                                        }
                                                    } }, { children: [_jsx("div", __assign({ className: "messaging-convo-title-dp", style: {
                                                                borderColor: getOtherUserTitleColor(convosInfo[channelId]),
                                                            } }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(getUserData(convosInfo[channelId]).user_small_image_path), imgSrc: getUserProfilePic(getUserData(convosInfo[channelId]).user_image_path) }) })), _jsx("div", __assign({ className: "messaging-chat-info-summary" }, { children: _jsxs("div", __assign({ className: "messaging-convo-info-data" }, { children: [_jsx("p", __assign({ className: "messaging-convo-main-title" }, { children: _jsx("b", { children: isCollaboration(convosInfo[channelId])
                                                                                ? getProjectName(convosInfo[channelId])
                                                                                : getOtherUserName(convosInfo[channelId]) }) })), _jsx("p", __assign({ className: "messaging-convo-main-subtitle" }, { children: isCollaboration(convosInfo[channelId])
                                                                            ? "@ " +
                                                                                getOtherUserName(convosInfo[channelId])
                                                                            : getOtherUserLocation(convosInfo[channelId]) })), _jsx("p", __assign({ className: "messaging-convo-main-subtitle" }, { children: getOtherUserTitle(convosInfo[channelId]) }))] })) }))] }))) : (_jsx("div", __assign({ className: "messaging-chat-starter-container", style: {
                                                        backgroundColor: colorMode !== "dark"
                                                            ? "#ddd4"
                                                            : "",
                                                    } }, { children: (!isCollaborationMode &&
                                                        canViewNetwork()) ||
                                                        !canViewMarket() ? (_jsxs("div", __assign({ className: "messaging-chat-starter", onClick: function () {
                                                            return history.push("/platform/network");
                                                        } }, { children: [_jsx("div", __assign({ className: "messaging-add-icon" }, { children: _jsx(IconAdd, {}) })), _jsx("div", __assign({ className: "messaging-add-text" }, { children: "Add people to connect with" }))] }))) : (_jsxs("div", __assign({ className: "messaging-chat-starter", onClick: function () {
                                                            return history.push("/platform/market");
                                                        } }, { children: [_jsx("div", __assign({ className: "messaging-add-icon" }, { children: _jsx(IconAdd, {}) })), _jsx("div", __assign({ className: "messaging-add-text" }, { children: "Add projects to collaborate on" }))] }))) }))) }))] })), channelId &&
                                        isCollaboration(convosInfo[channelId]) &&
                                        convosInfo[channelId].messages.length ? (_jsxs("div", __assign({ className: "messaging-top-actions-section" }, { children: [isProjectOwner(convosInfo[channelId].key) ||
                                                isNdaActive(convosInfo[channelId]) ? (_jsx("div", __assign({ className: "messaging-top-action", onClick: function () { return setIsNdaPopupOpen(true); } }, { children: _jsxs("div", { children: [_jsx("img", { className: "messaging-top-action-image messaging-nda-image" }), _jsx("p", { children: "NDA" }), getNdaIconStatus(convosInfo[channelId])] }) }))) : (_jsx(_Fragment, {})), canCreateContract() ? (_jsx("div", __assign({ className: "messaging-top-action", onClick: function () {
                                                    return history.push("/platform/contract-request?projectId=".concat(getProjectId(convosInfo[channelId])));
                                                } }, { children: _jsxs("div", { children: [_jsx("img", { className: "messaging-top-action-image messaging-deal-image" }), _jsx("p", __assign({ style: { marginLeft: "0px" } }, { children: "DEAL" })), getDealIconStatus(convosInfo[channelId])] }) }))) : (_jsx(_Fragment, {}))] }))) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "messaging-chatbox-middle" }, { children: [_jsx("div", __assign({ className: "messaging-intro-logo-container", style: { display: isChatMode ? "none" : "flex" } }, { children: _jsx("div", __assign({ className: "messaging-intro-logo", style: {
                                                width: "".concat(1 * getChatBoxWidth(), "px"),
                                                height: "".concat(1 * getChatBoxHeight(), "px"),
                                            } }, { children: _jsx(KImage, { smallImgSrc: "/images/transparent.png", imgSrc: getBackgroundImage() }) })) })), _jsxs("div", __assign({ className: "messaging-convo-container", style: {
                                            maxHeight: "calc(100% - 16px)",
                                            overflowY: "scroll",
                                            borderRadius: "10px",
                                            padding: "8px",
                                        } }, { children: [_jsx("div", __assign({ className: "messaging-convo-unread-messages", style: {
                                                    color: getOtherUserTitleColor(convosInfo[channelId]),
                                                }, onClick: function () { return scrollToBottomOfChat(); } }, { children: _jsx("div", { children: "New Messages" }) })), _jsx("div", __assign({ className: "messaging-convo-loadmore", style: {
                                                    display: ((_c = (_b = convosInfo[channelId]) === null || _b === void 0 ? void 0 : _b.messages) === null || _c === void 0 ? void 0 : _c.length) >= limit
                                                        ? "flex"
                                                        : "none",
                                                    color: getOtherUserTitleColor(convosInfo[channelId]),
                                                }, onClick: function () {
                                                    var oldScrollHeight = document.getElementsByClassName("messaging-convo-container")[0].scrollHeight;
                                                    kachat.loadMoreMessages(channelId, function (err, convo) {
                                                        if (err) {
                                                            connectUser();
                                                            return console.log(err);
                                                        }
                                                        var newScrollHeight = document.getElementsByClassName("messaging-convo-container")[0].scrollHeight;
                                                        scrollToChatLocation(newScrollHeight -
                                                            oldScrollHeight);
                                                    });
                                                } }, { children: _jsx("div", { children: "Load More" }) })), (channelId
                                                ? convosInfo[channelId]
                                                    ? convosInfo[channelId].messages
                                                    : []
                                                : [])
                                                .slice()
                                                .reverse()
                                                .map(function (message, key) {
                                                var _a, _b;
                                                return (_jsx("div", __assign({ className: "messaging-conversation", style: {
                                                        maxWidth: "80%",
                                                        float: parseInt(message.message_user_id_fk) == parseInt(userId)
                                                            ? "right"
                                                            : "left",
                                                        marginTop: message.message_user_id_fk ==
                                                            convosInfo[channelId].messages
                                                                .slice()
                                                                .reverse()[key - 1 < 0 ? 0 : key - 1].message_user_id_fk
                                                            ? "0px"
                                                            : "4px",
                                                        clear: "both",
                                                        backgroundColor: parseInt(message.message_user_id_fk) == parseInt(userId)
                                                            ? "#ddd"
                                                            : // getOtherUserTitleColor(
                                                                //       convosInfo[channelId]
                                                                //   )
                                                                getOtherUserTitleColorByMessage(message),
                                                        borderTopRightRadius: parseInt(message.message_user_id_fk) == parseInt(userId) &&
                                                            message.message_user_id_fk ==
                                                                (convosInfo[channelId].messages
                                                                    .slice()
                                                                    .reverse()[key - 1] || {}).message_user_id_fk
                                                            ? "4px"
                                                            : "",
                                                        borderTopLeftRadius: parseInt(message.message_user_id_fk) != parseInt(userId) &&
                                                            message.message_user_id_fk ==
                                                                (convosInfo[channelId].messages
                                                                    .slice()
                                                                    .reverse()[key - 1] || {}).message_user_id_fk
                                                            ? "4px"
                                                            : "",
                                                        borderBottomRightRadius: parseInt(message.message_user_id_fk) == parseInt(userId) &&
                                                            message.message_user_id_fk ==
                                                                (convosInfo[channelId].messages
                                                                    .slice()
                                                                    .reverse()[key + 1] || {}).message_user_id_fk
                                                            ? "4px"
                                                            : "",
                                                        borderBottomLeftRadius: parseInt(message.message_user_id_fk) != parseInt(userId) &&
                                                            message.message_user_id_fk ==
                                                                (convosInfo[channelId].messages
                                                                    .slice()
                                                                    .reverse()[key + 1] || {}).message_user_id_fk
                                                            ? "4px"
                                                            : "",
                                                    } }, { children: _jsxs("div", { children: [_jsxs("div", __assign({ style: {
                                                                    display: "table",
                                                                    width: "100%",
                                                                } }, { children: [_jsxs("p", __assign({ style: {
                                                                            color: parseInt(message.message_user_id_fk) ==
                                                                                parseInt(userId)
                                                                                ? "#777c"
                                                                                : "#fffa",
                                                                            fontSize: "9px",
                                                                            fontWeight: "500",
                                                                            float: "left",
                                                                            margin: "8px 0px 0px 0px",
                                                                        } }, { children: [" ", getDateTimeMinute(message.created_at), " "] })), _jsxs("p", __assign({ style: {
                                                                            display: parseInt(message.message_user_id_fk) ==
                                                                                parseInt(userId)
                                                                                ? "block"
                                                                                : "none",
                                                                            fontWeight: "800",
                                                                            color: getOtherUserTitleColor(convosInfo[channelId]),
                                                                            fontSize: "9px",
                                                                            float: "right",
                                                                            margin: "8px 0px 0px 10px",
                                                                        } }, { children: [" ", message.message_status.toUpperCase(), " "] })), _jsxs("div", __assign({ style: {
                                                                            display: parseInt(message.message_user_id_fk) ==
                                                                                parseInt(userId)
                                                                                ? "none"
                                                                                : "flex",
                                                                            alignItems: "center",
                                                                            fontWeight: "600",
                                                                            color: "#fffc",
                                                                            fontSize: "9px",
                                                                            float: "right",
                                                                            margin: "8px 0px 0px 10px",
                                                                        } }, { children: [" ", "~".concat(getOtherUserNameByMessage(message)), ((_b = (_a = convosInfo[channelId]) === null || _a === void 0 ? void 0 : _a.userIds) === null || _b === void 0 ? void 0 : _b.length) >
                                                                                1 && (_jsx("div", { style: {
                                                                                    width: "8px",
                                                                                    height: "8px",
                                                                                    backgroundColor: colorIndicators[convosInfo[channelId].userIds.indexOf("".concat(message.message_user_id_fk))],
                                                                                    borderRadius: "50%",
                                                                                    marginLeft: "7px",
                                                                                } }))] }))] })), _jsxs("p", __assign({ style: {
                                                                    color: parseInt(message.message_user_id_fk) == parseInt(userId)
                                                                        ? "#555"
                                                                        : "#fff",
                                                                    margin: "5px 0px 8px 0px",
                                                                } }, { children: [" ", message.message_text] }))] }) }), key));
                                            })] }))] })), _jsxs("div", __assign({ className: "messaging-chatbox-bottom", style: { display: channelId ? "flex" : "none" } }, { children: [_jsxs("div", __assign({ className: "messaging-textbox", style: { position: "relative" } }, { children: [_jsx("div", __assign({ className: "messaging-textbox-image", style: {
                                                    position: "absolute",
                                                    opacity: "1",
                                                    top: textboxImageTop,
                                                    zIndex: "1000000",
                                                    width: "100%",
                                                } }, { children: _jsx(KTextArea, { inputRef: hiddenInputRef, placeholder: "Type a message", borderRadius: "15px", labelMargin: "0px", width: "100%", height: textBoxHeight, borderColor: "var(--app-tertiary-background-color)", backgroundColor: "var(--app-tertiary-background-color)", fontSize: "16px", value: messageRef === null || messageRef === void 0 ? void 0 : messageRef.current, onKeyDown: function (event) {
                                                        if (event.keyCode === 13) {
                                                            event.preventDefault();
                                                            sendMessage(event);
                                                        }
                                                    }, onFocus: function () {
                                                        setTextboxImageTop("0px");
                                                        setTimeout(function () {
                                                            scrollToBottomOfChat();
                                                        }, 300);
                                                    }, onBlurCapture: function () {
                                                        return setTextboxImageTop("-50000px");
                                                    }, onChange: function (event) {
                                                        return setMessage(event.target.value);
                                                    } }) })), _jsx(KTextArea, { inputRef: inputRef, placeholder: "Type a message", borderRadius: "15px", labelMargin: "0px", width: "100%", height: textBoxHeight, borderColor: "var(--app-tertiary-background-color)", backgroundColor: "var(--app-tertiary-background-color)", fontSize: "16px", value: messageRef === null || messageRef === void 0 ? void 0 : messageRef.current, onFocus: function (event) {
                                                    hiddenInputRef.current.focus();
                                                }, onKeyDown: function (event) {
                                                    if (event.keyCode === 13) {
                                                        event.preventDefault();
                                                        sendMessage();
                                                    }
                                                }, onChange: function (event) {
                                                    return setMessage(event.target.value);
                                                } })] })), _jsx("div", __assign({ className: "messaging-send-icon", onClick: sendMessage }, { children: _jsx(IconSend, {}) }))] }))] })), _jsx(KPopup, __assign({ active: isDealPopupOpen, onClose: function () { return setIsDealPopupOpen(false); } }, { children: _jsx("div", __assign({ className: "messaging-popup-container" }, { children: _jsx(DealCard, { otherUserName: getOtherUserName(convosInfo[channelId]), projectName: getProjectName(convosInfo[channelId]), projectType: getProjectType(convosInfo[channelId]), projectUserName: getProjectUserName(convosInfo[channelId]), userTitle: getProjectUserTitle(convosInfo[channelId]), requestSent: isDealRequested(convosInfo[channelId]), onConfirm: function () { return sendDealRequest(); }, onClose: function () { return setIsDealPopupOpen(false); } }) })) })), _jsx(KPopup, __assign({ active: isNdaPopupOpen, onClose: function () { return setIsNdaPopupOpen(false); } }, { children: _jsx("div", __assign({ className: "messaging-popup-container" }, { children: _jsx(NdaCard, { otherUserName: getOtherUserName(convosInfo[channelId]), hasFullProject: hasFullProject(convosInfo[channelId]), projectName: getProjectName(convosInfo[channelId]), projectType: getProjectType(convosInfo[channelId]), userTitle: getProjectUserTitle(convosInfo[channelId]), projectUserName: getProjectUserName(convosInfo[channelId]), isProjectOwner: isProjectOwner((convosInfo[channelId] || {}).key), ndaStatus: getProjectNdaStatus(convosInfo[channelId]), ndaPath: getProjectNdaFilePath(convosInfo[channelId]), signedNdaPath: getProjectSignedNdaFilePath(convosInfo[channelId]), onShareNda: function (file) { return sendNdaRequest(file); }, onSignNda: function (file) { return sendSignedNda(file); }, onApproveNda: function (giveAccess, expiryDate) {
                                    return approveSignedNda(giveAccess, expiryDate);
                                }, onRejectNda: function () { return rejectSignedNda(); }, onUpdateNda: function (file) { return updateNdaTemplate(file); }, onClose: function () { return setIsNdaPopupOpen(false); } }) })) })), _jsxs("div", __assign({ className: "messaging-searchbox ".concat(isChatMode ? "messaging-chat-mode" : ""), style: {
                            height: "".concat(searchBoxHeight, "px"),
                            maxHeight: "".concat(searchBoxHeight, "px"),
                        } }, { children: [_jsxs("div", __assign({ className: "messaging-searchform" }, { children: [_jsx("div", __assign({ className: "messaging-search-icon-add", onClick: function () {
                                            return history.push(isCollaborationMode
                                                ? "/platform/market"
                                                : "/platform/network");
                                        } }, { children: _jsx(IconAdd, {}) })), _jsx("div", __assign({ className: "messaging-search-icon" }, { children: _jsx(IconSearch, {}) })), _jsx(KTextField, { placeholder: "Search conversations", labelMargin: "0px", width: "100%", height: "var(--messaging-textfield-height)", borderColor: "var(--app-primary-background-color)", backgroundColor: "var(--app-primary-background-color)", value: searchText, onChange: function (event) {
                                            return setSearchText(event.target.value);
                                        } })] })), showMessagingInfoBox ? (_jsx("div", __assign({ className: "messaging-info-box" }, { children: "Instead of sharing confidential information through emails and attachments, we encourage you to upload your script or movie on your project page, in the locked section." }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "messaging-searchdata", style: {
                                    height: "calc(100% - var(--messaging-textfield-height) - var(--messaging-section-spacing)".concat(showMessagingInfoBox ? " - 97px" : "", ")"),
                                } }, { children: [_jsxs("div", __assign({ className: "messaging-searchdata-top" }, { children: [_jsxs("div", __assign({ className: "messaging-searchdata-tab ".concat(isCollaborationMode
                                                    ? ""
                                                    : "messaging-searchdata-tab-active"), onClick: function () {
                                                    setIsCollaborationMode(false);
                                                    leaveChatMode();
                                                    window.history.replaceState(null, "", "/platform/messaging");
                                                } }, { children: [_jsx("div", { children: " My Connections " }), _jsx("div", __assign({ className: "messaging-collaborations-unread", style: {
                                                            display: unreadConnections > 0
                                                                ? "flex"
                                                                : "none",
                                                        } }, { children: unreadConnections }))] })), _jsxs("div", __assign({ className: "messaging-searchdata-tab ".concat(isCollaborationMode
                                                    ? "messaging-searchdata-tab-active"
                                                    : ""), onClick: function () {
                                                    setIsCollaborationMode(true);
                                                    leaveChatMode();
                                                    window.history.replaceState(null, "", "/platform/messaging");
                                                } }, { children: [_jsx("div", { children: " My Collaborations " }), _jsx("div", __assign({ className: "messaging-collaborations-unread", style: {
                                                            display: unreadCollaborations > 0
                                                                ? "flex"
                                                                : "none",
                                                        } }, { children: unreadCollaborations }))] }))] })), _jsxs("div", __assign({ className: "messaging-searchdata-middle" }, { children: [_jsx("div", __assign({ style: {
                                                    display: isCollaborationMode
                                                        ? "block"
                                                        : "none",
                                                } }, { children: Object.values(convosInfo).filter(function (e) {
                                                    return isCollaboration(e);
                                                }).length === 0 ? (_jsx("div", __assign({ className: "messaging-nodata-box" }, { children: "You currently have no collaboration chats. Go ahead and start a new one. In here, you will find all of your collaboration chats regarding specific projects." }))) : (_jsx(_Fragment, { children: Object.values(convosInfo)
                                                        .filter(function (e) { return isCollaboration(e); })
                                                        .sort(function (a, b) {
                                                        return new Date(a.updated_at || new Date()) >
                                                            new Date(b.updated_at || new Date())
                                                            ? -1
                                                            : 1;
                                                    })
                                                        .map(function (convo, key) { return (_jsxs("div", __assign({ className: "messaging-convo-info", onClick: function () {
                                                            return enableConvo(convo.name, convo.id);
                                                        }, style: {
                                                            display: searchText == "" ||
                                                                (getProjectName(convo) || convo.name)
                                                                    .toLowerCase()
                                                                    .includes(searchText.toLowerCase()) ||
                                                                getOtherUserName(convo)
                                                                    .toLowerCase()
                                                                    .includes(searchText.toLowerCase())
                                                                ? "flex"
                                                                : "none",
                                                            backgroundColor: channelId ==
                                                                convo.id
                                                                ? "var(--app-tertiary-background-color)"
                                                                : "",
                                                        } }, { children: [_jsx("div", __assign({ className: "messaging-convo-info-dp", style: {
                                                                    borderColor: getOtherUserTitleColor(convo),
                                                                } }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(getUserData(convo)
                                                                        .user_small_image_path), imgSrc: getUserProfilePic(getUserData(convo)
                                                                        .user_image_path), rounded: true }) })), _jsxs("div", __assign({ className: "messaging-convo-info-data" }, { children: [_jsxs("p", __assign({ className: "messaging-convo-title" }, { children: [_jsx("b", { children: getProjectName(convo) || convo.name }), " | " +
                                                                                (getProjectData(convo.key)
                                                                                    .project_type_name ||
                                                                                    "").toLowerCase()] })), _jsxs("p", __assign({ className: "messaging-convo-collab-on" }, { children: ["@", " ", getOtherUserName(convo)] })), _jsxs("p", __assign({ className: "messaging-convo-last-message" }, { children: [" ", (convo.messages
                                                                                ? convo.messages
                                                                                    .length
                                                                                : 0) > 0
                                                                                ? convo
                                                                                    .messages[0]
                                                                                    .message_text
                                                                                : "", " "] })), _jsxs("p", __assign({ className: "messaging-convo-timestamp" }, { children: [" ", getDateTimeMinute(convo.updated_at), " "] }))] })), convo.unread > 0 ? (_jsxs("div", __assign({ className: "messaging-unread-count", style: {
                                                                    backgroundColor: getOtherUserTitleColor(convo),
                                                                } }, { children: [_jsx("b", { children: convo.unread }), " "] }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "messaging-convo-sidenotes-section " }, { children: [_jsxs("div", __assign({ className: "messaging-convo-sidenotes-note", style: {
                                                                            display: getProjectNda(convo).nda_status
                                                                                ? "flex"
                                                                                : "none",
                                                                        } }, { children: [getNdaSideIconStatus(convo), _jsx("div", { children: "NDA" })] })), _jsxs("div", __assign({ className: "messaging-convo-sidenotes-note", style: {
                                                                            display: getProjectDeal(convo)
                                                                                .deal_status
                                                                                ? "flex"
                                                                                : "none",
                                                                        } }, { children: [getDealSideIconStatus(convo), _jsx("div", { children: "DEAL" })] }))] }))] }), key)); }) })) })), _jsx("div", __assign({ style: {
                                                    display: isCollaborationMode
                                                        ? "none"
                                                        : "block",
                                                } }, { children: Object.values(convosInfo).filter(function (e) {
                                                    return isOneToOneConvo(e);
                                                }).length === 0 ? (_jsx("div", __assign({ className: "messaging-nodata-box" }, { children: "You currently have no connection chats. Go ahead and start a new one. In here, you will find all of your connection chats regarding specific people." }))) : (_jsx(_Fragment, { children: Object.values(convosInfo)
                                                        .filter(function (e) { return isOneToOneConvo(e); })
                                                        .sort(function (a, b) {
                                                        return new Date(a.updated_at || new Date()) >
                                                            new Date(b.updated_at || new Date())
                                                            ? -1
                                                            : 1;
                                                    })
                                                        .map(function (convo, key) { return (_jsxs("div", __assign({ className: "messaging-convo-info", onClick: function () {
                                                            return enableConvo(convo.name, convo.id);
                                                        }, style: {
                                                            display: searchText == "" ||
                                                                (getProjectName(convo) || convo.name)
                                                                    .toLowerCase()
                                                                    .includes(searchText.toLowerCase()) ||
                                                                getOtherUserName(convo)
                                                                    .toLowerCase()
                                                                    .includes(searchText.toLowerCase())
                                                                ? "flex"
                                                                : "none",
                                                            backgroundColor: channelId ==
                                                                convo.id
                                                                ? "var(--app-tertiary-background-color)"
                                                                : "",
                                                        } }, { children: [_jsx("div", __assign({ className: "messaging-convo-info-dp", style: {
                                                                    overflow: "hidden",
                                                                    borderColor: getOtherUserTitleColor(convo),
                                                                } }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(getUserData(convo)
                                                                        .user_small_image_path), imgSrc: getUserProfilePic(getUserData(convo)
                                                                        .user_image_path), rounded: true }) })), _jsxs("div", __assign({ className: "messaging-convo-info-data" }, { children: [_jsxs("p", __assign({ className: "messaging-convo-title" }, { children: [_jsx("b", { children: getOtherUserName(convo) }), " | " +
                                                                                getOtherUserTitle(convo)] })), _jsxs("p", __assign({ className: "messaging-convo-last-message" }, { children: [" ", (convo.messages
                                                                                ? convo.messages
                                                                                    .length
                                                                                : 0) > 0
                                                                                ? convo
                                                                                    .messages[0]
                                                                                    .message_text
                                                                                : "", " "] })), _jsxs("p", __assign({ className: "messaging-convo-timestamp" }, { children: [" ", getDateTimeMinute(convo.updated_at), " "] }))] })), convo.unread > 0 ? (_jsxs("div", __assign({ className: "messaging-unread-count", style: {
                                                                    backgroundColor: getOtherUserTitleColor(convo),
                                                                } }, { children: [_jsx("b", { children: convo.unread }), " "] }))) : (_jsx(_Fragment, {}))] }), key)); }) })) }))] }))] }))] }))] }))] })));
};
var mapStateToProps = function (state) { return ({
    user: getUser(state),
    userId: getKachatId(state),
    userTitle: getUserTitle(state),
    loadedUsers: getMessagingUsers(state),
    loadedProjects: getMessagingProjects(state),
    userDeals: getUserDeals(state),
    userNdas: getUserNdas(state),
    userRatingByUserId: getLoadedUserRatingByUserId(state),
    colorMode: getColorMode(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadUser: function (userId, callback) {
        return dispatch(thunkLoadUserByUserId(userId, callback));
    },
    onLoadUsers: function (kachatIds) { return dispatch(thunkLoadUsersByIds(kachatIds)); },
    onLoadProjects: function (projectIds) {
        return dispatch(thunkLoadProjectsByIds(projectIds));
    },
    onCreateDeal: function (userId, projectId) {
        return dispatch(thunkCreateDeal(userId, projectId));
    },
    onLoadUserDeals: function (userId, silent) {
        if (silent === void 0) { silent = false; }
        return dispatch(thunkLoadUserDeals(userId, silent));
    },
    onLoadUserNdas: function (userId, silent) {
        if (silent === void 0) { silent = false; }
        return dispatch(thunkLoadUserNdas(userId, silent));
    },
    onSendNdaRequest: function (userId, projectId, projectUserId, ndaFile, channelId) {
        return dispatch(thunkRequestNda(userId, projectId, projectUserId, ndaFile, channelId));
    },
    onSignNda: function (userId, projectId, projectUserId, signedNdaFile, channelId) {
        return dispatch(thunkSignNda(userId, projectId, projectUserId, signedNdaFile, channelId));
    },
    onUpdateNdaTemplate: function (userId, projectId, projectUserId, ndaFile) {
        return dispatch(thunkUpdateNdaTemplate(userId, projectId, projectUserId, ndaFile));
    },
    onRejectSignedNda: function (userId, projectId, channelId) {
        return dispatch(thunkRejectNdaSignature(userId, projectId, channelId));
    },
    onApproveSignedNda: function (userId, projectId, giveAccess, expiryDate, channelId) {
        return dispatch(thunkApproveNda(userId, projectId, giveAccess, expiryDate, channelId));
    },
    onChatServiceError: function () { return dispatch(thunkSetChatServiceError()); },
    addRatingByUser: function (userId, rating) {
        return dispatch(thunkAddRatingByUser(userId, rating));
    },
    onLoadUserRatingByUserId: function (userId, callback) {
        return dispatch(thunkLoadUserRatingByUserId(userId, callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(Messaging);
