var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef, useCallback, useLayoutEffect, } from "react";
import Cropper from "react-easy-crop";
import KButton from "../../base-components/KButton/KButton";
import { getImageUrl } from "../../tools/generalTools";
import KSlider from "../../base-components/KSlider/KSlider";
import { getCroppedImg, getDataUrl, validateImageSize, } from "../../imaging/imageTransform";
import { resize, dataURItoBlob } from "../../imaging/imageTransform";
import "./EditCropImage.css";
var EditCropImage = function (_a) {
    var aspectRatio = _a.aspectRatio, cropShape = _a.cropShape, image = _a.image, onSaveImage = _a.onSaveImage, _b = _a.minAllowedSize, minAllowedSize = _b === void 0 ? process.env.IMAGE_MIN_ALLOWED_SIZE || 50000 : _b, _c = _a.minAllowedWidth, minAllowedWidth = _c === void 0 ? process.env.IMAGE_MIN_ALLOWED_WIDTH || 200 : _c, _d = _a.minAllowedHeight, minAllowedHeight = _d === void 0 ? process.env.IMAGE_MIN_ALLOWED_HEIGHT || 200 : _d;
    var imageSize = parseInt(process.env.LARGE_IMAGE_SIZE);
    var _e = useState(""), upImg = _e[0], setUpImg = _e[1];
    var _f = useState(false), noImg = _f[0], setNoImg = _f[1];
    var inputRef = useRef(null);
    var _g = useState({
        x: 10,
        y: 10,
    }), crop = _g[0], setCrop = _g[1];
    var _h = useState(null), completedCrop = _h[0], setCompletedCrop = _h[1];
    var _j = useState(1), zoom = _j[0], setZoom = _j[1];
    var _k = useState(null), croppedImage = _k[0], setCroppedImage = _k[1];
    useLayoutEffect(function () {
        if (Object.keys(image).length !== 0) {
            if (image.startsWith("data")) {
                setUpImg(image);
            }
            else {
                var dataImg = getDataUrl(getImageUrl(image));
                dataImg
                    .then(function (res) {
                    setUpImg(res);
                })
                    .catch(function (e) {
                    console.log(e);
                });
            }
        }
        else {
            setNoImg(true);
            inputRef.current.click();
        }
    }, []);
    var uploadImage = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var file, reader_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setNoImg(false);
                    return [4 /*yield*/, event.target.files[0]];
                case 1:
                    file = _a.sent();
                    if (!file)
                        return [2 /*return*/];
                    _a.label = 2;
                case 2:
                    _a.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, validateImageSize(file, minAllowedSize, minAllowedWidth, minAllowedHeight)];
                case 3:
                    _a.sent();
                    reader_1 = new FileReader();
                    reader_1.addEventListener("load", function () {
                        setUpImg(reader_1.result);
                    });
                    resize(file, imageSize, imageSize, 1, function (dataUrl, coverInfoKey) {
                        var resizedFile = dataURItoBlob(dataUrl);
                        reader_1.readAsDataURL(resizedFile);
                    });
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    alert(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var saveImage = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            onSaveImage(file);
            return [2 /*return*/];
        });
    }); };
    var onCropComplete = useCallback(function (croppedArea, croppedAreaPixels) {
        setCompletedCrop(croppedAreaPixels);
    }, []);
    useEffect(function () {
        if (upImg && completedCrop) {
            var croppedImageDone = getCroppedImg(upImg, completedCrop);
            croppedImageDone
                .then(function (res) {
                setCroppedImage(res);
            })
                .catch(function (e) {
                console.log(e);
            });
        }
    }, [upImg, completedCrop]);
    return (_jsx("div", __assign({ className: "editcropimage-container" }, { children: _jsxs("div", __assign({ className: "editcropimage-content" }, { children: [_jsx("nav", __assign({ className: "editcropimage-nav" }, { children: _jsx("div", __assign({ className: "editcropimage-title" }, { children: "Edit Image" })) })), noImg ? (_jsx("div", __assign({ className: "editcropimage-no-img" }, { children: _jsx("div", { children: "No Photo Selected" }) }))) : (_jsxs("div", { children: [_jsx("div", __assign({ className: "editcropimage-image" }, { children: _jsx(Cropper, { image: upImg, crop: crop, zoom: zoom, onCropChange: setCrop, onCropComplete: onCropComplete, onZoomChange: setZoom, aspect: aspectRatio, cropShape: cropShape }) })), _jsx("div", __assign({ className: "editcropimage-zoom" }, { children: _jsx(KSlider, { label: "Zoom", value: zoom, min: 1, max: 1.75, step: 0.025, onChange: function (e) { return setZoom(e); }, backgroundColor: "var(--app-loadeduser-color)" }) }))] })), _jsxs("div", __assign({ className: "editcropimage-actions" }, { children: [noImg ? (_jsx(_Fragment, {})) : (_jsx("div", __assign({ className: "editcropimage-btns" }, { children: _jsx(KButton, { title: "Save Photo", variant: "primary", backgroundColor: "var(--app-loadeduser-color)", width: "100px", height: "38px", onClick: function () { return saveImage(croppedImage); } }) }))), _jsx("div", __assign({ className: "editcropimage-btns" }, { children: _jsxs("div", { children: [_jsx("input", { type: "file", ref: inputRef, className: "usersummarycard-cover-input", id: "usersummarycard-cover-input", accept: "image/*", onChange: function (event) { return uploadImage(event); } }), _jsx(KButton, { onClick: function () { return inputRef.current.click(); }, title: "Choose Photo", variant: "primary", outline: true, color: "var(--app-loadeduser-color)", width: "100px", height: "38px" })] }) }))] }))] })) })));
};
export default EditCropImage;
