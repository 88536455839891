var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { BiLock as IconLock } from "react-icons/bi";
import { BsFillExclamationCircleFill as IconWarning } from "react-icons/bs";
import { thunkAcceptAccessRequest, thunkLoadUserAccessRequests, thunkRejectAccessRequest, } from "../../redux/thunks/projectThunks";
import { thunkLoadUserSubscriptions } from "../../redux/thunks/authThunks";
import { getUserSubscriptions } from "../../redux/selectors/authSelectors";
import { getLocation, getReadableDate } from "../../tools/generalTools";
import { getAccessRequests } from "../../redux/selectors/projectSelectors";
import { getFullName, getUserProfilePic, getUserTitleColor, } from "../../tools/userTools";
import { getLoadedUserRatingAverage } from "../../redux/selectors/userSelectors";
import { thunkLoadUserRatingAverage } from "../../redux/thunks/userThunks";
import FullAccessCard from "../../components/FullAccessCard/FullAccessCard";
import KButton from "../../base-components/KButton/KButton";
import KImage from "../../base-components/KImage/KImage";
import KPopup from "../../base-components/KPopup/KPopup";
import "./AccessRequestsPage.css";
var AccessRequestCard = function (_a) {
    var accessRequest = _a.accessRequest, _b = _a.onViewRequest, onViewRequest = _b === void 0 ? function (f) { return f; } : _b, _c = _a.onRejectRequest, onRejectRequest = _c === void 0 ? function (f) { return f; } : _c;
    var userColor = getUserTitleColor(accessRequest.user_title_name);
    return (_jsxs("div", __assign({ className: "accessrequestcard-container" }, { children: [_jsxs("div", __assign({ className: "accessrequestcard-image-section" }, { children: [_jsx("div", __assign({ className: "accessrequestcard-image", style: {
                            borderColor: userColor,
                        } }, { children: _jsx(KImage, { rounded: true, smallImgSrc: getUserProfilePic(accessRequest === null || accessRequest === void 0 ? void 0 : accessRequest.user_small_image_path), imgSrc: getUserProfilePic(accessRequest === null || accessRequest === void 0 ? void 0 : accessRequest.user_image_path), alt: "profile-pic" }) })), _jsx("div", __assign({ className: "accessrequestcard-image-title", style: {
                            color: userColor,
                        } }, { children: accessRequest.user_title_name }))] })), _jsxs("div", __assign({ className: "accessrequestcard-info-section" }, { children: [_jsxs("div", __assign({ className: "accessrequestcard-info-top" }, { children: [_jsx("div", __assign({ className: "accessrequestcard-title" }, { children: getFullName(accessRequest) })), _jsx("div", __assign({ className: "accessrequestcard-subtitle" }, { children: getLocation(accessRequest.country_name, accessRequest.city_name) }))] })), _jsxs("div", __assign({ className: "accessrequestcard-info-middle" }, { children: [_jsx("div", __assign({ className: "accessrequestcard-message-holder" }, { children: _jsxs("div", __assign({ className: "accessrequestcard-message" }, { children: ["full project access request on", _jsx("br", {}), " ", _jsxs("i", { children: ["\"", accessRequest.project_title, "\""] })] })) })), _jsxs("div", __assign({ className: "accessrequestcard-date" }, { children: ["requested on", " ", getReadableDate(accessRequest.request_date)] }))] })), _jsx("div", __assign({ className: "accessrequestcard-info-bottom" }, { children: accessRequest.project_access_request_status ===
                            "pending" ? (_jsxs(_Fragment, { children: [_jsx(KButton, { title: "view", width: "100px", paddingVertical: "5px", variant: "primary", onClick: function () { return onViewRequest(accessRequest); }, backgroundColor: userColor }), _jsx(KButton, { title: "reject", width: "100px", paddingVertical: "5px", variant: "primary", onClick: function () { return onRejectRequest(accessRequest); }, color: userColor, outline: true })] })) : (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "accessrequestcard-status" }, { children: accessRequest.project_access_request_status })) })) }))] }))] })));
};
var AccessRequestsPage = function (_a) {
    var accessRequests = _a.accessRequests, loadedUserRating = _a.loadedUserRating, onLoadUserRating = _a.onLoadUserRating, onLoadAccessRequests = _a.onLoadAccessRequests, onRejectAccessRequest = _a.onRejectAccessRequest, onAcceptAccessRequest = _a.onAcceptAccessRequest;
    var _b = useState(false), viewModeOn = _b[0], setViewModeOn = _b[1];
    var _c = useState({}), selectedRequest = _c[0], setSelectedRequest = _c[1];
    var onRejectClicked = function (request) {
        if (confirm("Are you sure you'd like to reject access to this user?"))
            onRejectAccessRequest(request.project_id, request.user_id, function () {
                return setViewModeOn(false);
            });
    };
    var onAcceptClicked = function (request, expiryDate) {
        if (confirm("Are you sure you'd like to give access to this user?"))
            onAcceptAccessRequest(request.project_id, request.user_id, expiryDate, function () { return setViewModeOn(false); });
    };
    useEffect(function () {
        onLoadAccessRequests();
    }, []);
    return (_jsxs("div", __assign({ className: "accessrequestspage-container" }, { children: [_jsx(KPopup, __assign({ active: viewModeOn, onClose: function () { return setViewModeOn(false); } }, { children: _jsx(FullAccessCard, { isRequest: false, projectName: selectedRequest.project_title, userTitle: selectedRequest.user_user_title_name, requestingUser: selectedRequest || {}, requestingUserRating: loadedUserRating, requestDate: selectedRequest.request_date, requestStatus: selectedRequest.project_access_request_status, requestMessage: selectedRequest.project_access_request_message, signedNdaPath: selectedRequest.signedNdaPath, onAllowAccess: function (expiryDate) {
                        return onAcceptClicked(selectedRequest, expiryDate);
                    }, onRejectAccess: function () { return onRejectClicked(selectedRequest); } }) })), _jsxs("div", __assign({ className: "accessrequestspage-title" }, { children: [_jsx("div", __assign({ className: "accessrequestspage-title-icon" }, { children: _jsx(IconLock, {}) })), _jsx("div", __assign({ className: "accessrequestspage-title-text" }, { children: "Project access requests" }))] })), _jsx("div", { children: accessRequests.length ? (_jsxs(_Fragment, { children: [accessRequests.filter(function (req) {
                            return req.project_access_request_status === "pending";
                        }).length ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "accessrequestspage-section-title" }, { children: "Pending Requests" })), _jsx("div", __assign({ className: "accessrequestspage-wrapper" }, { children: accessRequests
                                        .filter(function (req) {
                                        return req.project_access_request_status ===
                                            "pending";
                                    })
                                        .map(function (accessRequest, key) { return (_jsx("div", __assign({ className: "accessrequestspage-request-box" }, { children: _jsx(AccessRequestCard, { accessRequest: accessRequest, onViewRequest: function (request) {
                                                setSelectedRequest(request);
                                                setViewModeOn(true);
                                                onLoadUserRating(request.user_id);
                                            }, onRejectRequest: onRejectClicked }) }), key)); }) }))] })) : (_jsx(_Fragment, {})), accessRequests.filter(function (req) {
                            return req.project_access_request_status !== "pending";
                        }).length ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "accessrequestspage-section-title" }, { children: "Handled Requests" })), _jsx("div", __assign({ className: "accessrequestspage-wrapper" }, { children: accessRequests
                                        .filter(function (req) {
                                        return req.project_access_request_status !==
                                            "pending";
                                    })
                                        .map(function (accessRequest, key) { return (_jsx("div", __assign({ className: "accessrequestspage-request-box" }, { children: _jsx(AccessRequestCard, { accessRequest: accessRequest, onViewRequest: function (request) {
                                                setSelectedRequest(request);
                                                setViewModeOn(true);
                                            }, onRejectRequest: onRejectClicked }) }), key)); }) }))] })) : (_jsx(_Fragment, {}))] })) : (_jsxs("div", __assign({ className: "accessrequestspage-no-revisions" }, { children: [_jsx("div", __assign({ className: "accessrequestspage-no-revisions-icon" }, { children: _jsx(IconWarning, {}) })), _jsx("div", __assign({ className: "accessrequestspage-no-revisions-text" }, { children: "You will receive a notification from users who would like to review your script or movie that was uploaded in the locked section." }))] }))) })] })));
};
var mapStateToProps = function (state) { return ({
    subscriptions: getUserSubscriptions(state),
    accessRequests: getAccessRequests(state),
    loadedUserRating: getLoadedUserRatingAverage(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadUserSubscriptions: function () { return dispatch(thunkLoadUserSubscriptions()); },
    onLoadAccessRequests: function (pending) {
        if (pending === void 0) { pending = false; }
        return dispatch(thunkLoadUserAccessRequests(pending));
    },
    onRejectAccessRequest: function (projectId, userId, callback) {
        return dispatch(thunkRejectAccessRequest(projectId, userId, callback));
    },
    onAcceptAccessRequest: function (projectId, userId, expiryDate, callback) {
        return dispatch(thunkAcceptAccessRequest(projectId, userId, expiryDate, callback));
    },
    onLoadUserRating: function (userId) { return dispatch(thunkLoadUserRatingAverage(userId)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(AccessRequestsPage);
