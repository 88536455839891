var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isUserScriptwriter } from "../../tools/userTools";
import { getProjectSymbolSrc } from "../../tools/projectTools";
import KButton from "../../base-components/KButton/KButton";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import KTextField from "../../base-components/KTextField/KTextField";
import KRadioButton from "../../base-components/KRadioButton/KRadioButton";
import { isAuthUserScriptwriter } from "../../validators/profileValidator";
import { getProjectTypes, getProjectSubtypes, getUserTitles, } from "../../redux/selectors/generalSelectors";
import { thunkLoadProjectTypes, thunkLoadProjectSubtypes, thunkLoadUserTitles, } from "../../redux/thunks/generalThunks";
import "./EditProjectBasicsPage.css";
var EditProjectBasicsPage = function (_a) {
    var projectTitle = _a.projectTitle, userTitle = _a.userTitle, project = _a.project, onSave = _a.onSave, onChange = _a.onChange, userTitles = _a.userTitles, projectTypes = _a.projectTypes, projectSubtypes = _a.projectSubtypes, loadUserTitles = _a.loadUserTitles, loadProjectTypes = _a.loadProjectTypes, loadProjectSubtypes = _a.loadProjectSubtypes, isEditMode = _a.isEditMode, onPublish = _a.onPublish, onUpdate = _a.onUpdate;
    var _b = useState(projectTitle), title = _b[0], setTitle = _b[1];
    var _c = useState(""), productionBudget = _c[0], setProductionBudget = _c[1];
    var _d = useState(""), acquisitionBudget = _d[0], setAcquisitionBudget = _d[1];
    var _e = useState(""), investmentBudget = _e[0], setInvestmentBudget = _e[1];
    var _f = useState(""), financingBudget = _f[0], setFinancingBudget = _f[1];
    var _g = useState(false), lookingForBuyer = _g[0], setLookingForBuyer = _g[1];
    var _h = useState(false), lookingForProducer = _h[0], setLookingForProducer = _h[1];
    var _j = useState(false), lookingForInvestor = _j[0], setLookingForInvestor = _j[1];
    var _k = useState(true), openForCollaboration = _k[0], setOpenForCollaboration = _k[1];
    var _l = useState(""), projectSubtypeId = _l[0], setProjectSubtypeId = _l[1];
    var _m = useState(""), projectTypeId = _m[0], setProjectTypeId = _m[1];
    var _o = useState(""), projectType = _o[0], setProjectType = _o[1];
    var _p = useState(""), projectSubtype = _p[0], setProjectSubtype = _p[1];
    var _q = useState(true), openForSale = _q[0], setOpenForSale = _q[1];
    var onProjectTypeChange = function (projectTypeId) {
        var projectType = projectTypes.find(function (type) { return type.project_type_id == projectTypeId; });
        if (projectType)
            setProjectType(projectType.project_type_name);
        setProjectTypeId(projectTypeId);
    };
    var onProjectSubtypeChange = function (projectSubtypeId) {
        var projectSubtype = projectSubtypes.find(function (type) { return type.project_subtype_id == projectSubtypeId; });
        if (projectSubtype)
            setProjectSubtype(projectSubtype.project_subtype_name);
        setProjectSubtypeId(projectSubtypeId);
    };
    var getUserTitleId = function (userTitle) {
        var title = userTitles.find(function (title) {
            return title.user_title_name.toLowerCase() == userTitle.toLowerCase();
        });
        if (title)
            return title.user_title_id;
    };
    var getSelectedInterestIds = function () {
        var selectedInterestIds = [];
        if (lookingForProducer)
            selectedInterestIds.push(getUserTitleId("producer"));
        if (lookingForInvestor)
            selectedInterestIds.push(getUserTitleId("investor"));
        if (lookingForBuyer)
            selectedInterestIds.push(getUserTitleId("buyer"));
        return selectedInterestIds;
    };
    var getData = function () {
        return {
            title: title,
            acquisitionBudget: acquisitionBudget,
            openForCollaboration: openForCollaboration,
            openForSale: openForSale,
            lookingForBuyer: lookingForBuyer,
            lookingForProducer: lookingForProducer,
            lookingForInvestor: lookingForInvestor,
            productionBudget: productionBudget,
            projectType: projectType,
            projectTypeId: projectTypeId,
            projectSubtype: projectSubtype,
            projectSubtypeId: projectSubtypeId,
            financingBudget: financingBudget,
            investmentBudget: investmentBudget,
            projectUserInterestIds: getSelectedInterestIds(),
        };
    };
    useEffect(function () {
        onChange(getData());
    }, [
        title,
        acquisitionBudget,
        lookingForBuyer,
        lookingForInvestor,
        lookingForProducer,
        openForCollaboration,
        openForSale,
        productionBudget,
        projectType,
        projectTypeId,
        projectSubtype,
        projectSubtypeId,
        financingBudget,
        investmentBudget,
    ]);
    useEffect(function () {
        var projectType = projectTypes.find(function (type) { return type.project_type_name == "Script"; });
        var projectSubtype = projectSubtypes.find(function (type) { return type.project_subtype_name == "Movie"; });
        if (projectType)
            onProjectTypeChange(projectType.project_type_id);
        if (projectSubtype)
            onProjectSubtypeChange(projectSubtype.project_subtype_id);
    }, [projectTypes.length, projectSubtypes.length]);
    useEffect(function () {
        window.scrollTo(0, 0);
        loadUserTitles();
        loadProjectTypes();
        loadProjectSubtypes();
        if (typeof project.title !== "undefined")
            setTitle(project.title);
        if (typeof project.acquisitionBudget !== "undefined")
            setAcquisitionBudget(project.acquisitionBudget);
        if (typeof project.financingBudget !== "undefined")
            setFinancingBudget(project.financingBudget);
        if (typeof project.investmentBudget !== "undefined")
            setInvestmentBudget(project.investmentBudget);
        if (typeof project.lookingForBuyer !== "undefined")
            setLookingForBuyer(project.lookingForBuyer);
        if (typeof project.lookingForProducer !== "undefined")
            setLookingForProducer(project.lookingForProducer);
        if (typeof project.lookingForInvestor !== "undefined")
            setLookingForInvestor(project.lookingForInvestor);
        if (typeof project.openForCollaboration !== "undefined")
            setOpenForCollaboration(project.openForCollaboration);
        if (typeof project.openForSale !== "undefined")
            setOpenForSale(project.openForSale);
        if (typeof project.productionBudget !== "undefined")
            setProductionBudget(project.productionBudget);
        if (typeof project.projectTypeId !== "undefined")
            onProjectTypeChange(project.projectTypeId);
        if (typeof project.projectSubtypeId !== "undefined")
            onProjectSubtypeChange(project.projectSubtypeId);
    }, [project]);
    var onSubmit = function () {
        onSave(__assign(__assign({}, getData()), { saved: true }), "details");
    };
    return (_jsxs("div", __assign({ className: "editprojectbasicspage-container" }, { children: [_jsx("div", __assign({ className: "editprojectbasicspage-header-1" }, { children: "Let's create your project pitch page" })), _jsx("p", __assign({ className: "editprojectbasicspage-text" }, { children: "Filling the information below will allow you to create an exciting project page that will entice collaborations." })), _jsx("div", __assign({ className: "editprojectbasicspage-flex" }, { children: _jsx(KTextField, { placeholder: "Project title", width: "400px", marginVertical: "10px", value: title || "", backgroundColor: "var(--app-primary-background-color)", onChange: function (event) { return setTitle(event.target.value); } }) })), _jsx("div", __assign({ className: "editprojectbasicspage-header-2" }, { children: "What's your project type" })), _jsxs("div", __assign({ style: { display: isUserScriptwriter(userTitle) ? "none" : "" } }, { children: [_jsx("div", __assign({ className: "editprojectbasicspage-checkbox-container", onChange: function (event) {
                            return onProjectTypeChange(event.target.value);
                        } }, { children: projectTypes.map(function (type, key) { return (_jsx(KRadioButton, { color: "#555", marginRight: "24px", name: "type", value: type.project_type_id, title: type.project_type_name, imgSrc: getProjectSymbolSrc(type.project_type_name), selected: projectTypeId == type.project_type_id }, key)); }) })), _jsx("div", { className: "editprojectbasicspage-separator" })] })), _jsx("div", __assign({ className: "editprojectbasicspage-checkbox-container", onChange: function (event) { return onProjectSubtypeChange(event.target.value); } }, { children: projectSubtypes.map(function (subtype, key) { return (_jsx(KRadioButton, { color: "#555", marginRight: "24px", name: "subtype", value: subtype.project_subtype_id, title: subtype.project_subtype_name, selected: projectSubtypeId == subtype.project_subtype_id }, key)); }) })), _jsx("div", __assign({ className: "editprojectbasicspage-header-2" }, { children: "What are you looking for" })), _jsxs("div", __assign({ className: "editprojectbasicspage-checkbox-container" }, { children: [_jsx(KCheckbox, { title: "Producer", color: "var(--app-producer-color)", marginRight: "24px", onChange: function (event) {
                            return setLookingForProducer(event.target.checked);
                        }, checked: lookingForProducer }), _jsx(KCheckbox, { title: "Investor", color: "var(--app-investor-color)", marginRight: "24px", onChange: function (event) {
                            return setLookingForInvestor(event.target.checked);
                        }, checked: lookingForInvestor }), _jsx(KCheckbox, { title: "Buyer", color: "var(--app-buyer-color)", marginRight: "24px", onChange: function (event) {
                            return setLookingForBuyer(event.target.checked);
                        }, checked: lookingForBuyer })] })), _jsx("div", __assign({ className: "editprojectbasicspage-flex", 
                // style={{ display: isScriptProject(projectType) ? "none" : "" }}
                style: { display: lookingForProducer ? "" : "none" } }, { children: _jsx(KTextField, { placeholder: "Production budget (USD)", width: "200px", marginVertical: "10px", onChange: function (event) {
                        return setProductionBudget(event.target.value);
                    }, label: "Production budget (USD)", type: "number", backgroundColor: "var(--app-primary-background-color)", value: productionBudget || "" }) })), _jsx("div", __assign({ className: "editprojectbasicspage-flex", style: {
                    display: !lookingForInvestor || isAuthUserScriptwriter()
                        ? "none"
                        : "",
                } }, { children: _jsx(KTextField, { placeholder: "Investment budget (USD)", width: "200px", marginVertical: "10px", onChange: function (event) {
                        return setInvestmentBudget(event.target.value);
                    }, label: "Investment budget (USD)", type: "number", backgroundColor: "var(--app-primary-background-color)", value: investmentBudget || "" }) })), _jsx("div", __assign({ className: "editprojectbasicspage-flex", style: { display: lookingForBuyer ? "" : "none" } }, { children: _jsx(KTextField, { placeholder: "Acquisition budget (USD)", width: "200px", marginVertical: "10px", onChange: function (event) {
                        return setAcquisitionBudget(event.target.value);
                    }, label: "Acquisition budget (USD)", type: "number", backgroundColor: "var(--app-primary-background-color)", value: acquisitionBudget || "" }) })), _jsx("div", __assign({ className: "editprojectbasicspage-header-2" }, { children: "Open for" })), _jsx(KCheckbox, { title: "Collaboration", color: "#555", marginTop: "12px", disabled: true, onChange: function (event) {
                    return setOpenForCollaboration(event.target.checked);
                }, checked: openForCollaboration }), _jsx(KCheckbox, { title: "Sale", color: "#555", marginRight: "24px", disabled: true, onChange: function (event) { return setOpenForSale(event.target.checked); }, checked: openForSale }), _jsxs("div", __assign({ className: "editprojectbasicspage-actions" }, { children: [_jsx(KButton, { title: "Next", variant: "primary", onClick: onSubmit }), isEditMode ? (_jsx(KButton, { title: "Update", variant: "primary", onClick: onUpdate, outline: true })) : (_jsx(_Fragment, {})
                    // <KButton
                    //     title="Publish"
                    //     variant="primary"
                    //     onClick={onPublish}
                    //     outline
                    // />
                    )] }))] })));
};
var mapStateToProps = function (state) { return ({
    projectTypes: getProjectTypes(state),
    projectSubtypes: getProjectSubtypes(state),
    userTitles: getUserTitles(state),
}); };
var dispatchStateToProps = function (dispatch) { return ({
    loadProjectTypes: function () { return dispatch(thunkLoadProjectTypes()); },
    loadProjectSubtypes: function () { return dispatch(thunkLoadProjectSubtypes()); },
    loadUserTitles: function () { return dispatch(thunkLoadUserTitles()); },
}); };
export default connect(mapStateToProps, dispatchStateToProps)(EditProjectBasicsPage);
