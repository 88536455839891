var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { RESET_NOTIFICATIONS, UPDATE_NOTIFICATIONS, UPDATE_NOTIFICATION, ADD_NOTIFICATIONS, NOTIFICATIONS_LOADING, NOTIFICATIONS_LOADING_COMPLETE, } from "../actions/notificationActions";
var notificationsInitialState = {
    data: [],
    unreadCount: 0,
    isLoading: false,
};
export var notification = function (state, action) {
    if (state === void 0) { state = notificationsInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case RESET_NOTIFICATIONS: {
            var notifications = payload.notifications;
            if (!notifications)
                return notificationsInitialState;
            return {
                data: notifications.data,
                isLoading: false,
                unreadCount: notifications.unreadCount || 0,
            };
        }
        case ADD_NOTIFICATIONS: {
            var notifications = payload.notifications;
            if (!notifications || !notifications.data) {
                return __assign(__assign({}, state), { isLoading: false });
            }
            return __assign(__assign({}, state), { isLoading: false, data: __spreadArray(__spreadArray([], state.data, true), notifications.data, true) });
        }
        case UPDATE_NOTIFICATIONS: {
            var notifications = payload.notifications;
            return __assign(__assign({}, state), { isLoading: false, data: __spreadArray(__spreadArray([], notifications.data, true), state.data, true), unreadCount: notifications.unreadCount || 0 });
        }
        case UPDATE_NOTIFICATION: {
            var notification_1 = payload.notification;
            var data = state.data;
            var index = data.findIndex(function (entry) { return entry.id == notification_1.id; });
            var wasRead = data[index].clearedAt ||
                data[index].deletedAt ||
                data[index].seenAt
                ? true
                : false;
            var isRead = notification_1.clearedAt ||
                notification_1.deletedAt ||
                notification_1.seenAt
                ? true
                : false;
            var addCount = wasRead === isRead ? 0 : isRead ? -1 : 1;
            if (notification_1.clearedAt)
                data.splice(index, 1);
            else
                data[index] = notification_1;
            return __assign(__assign({}, state), { data: data, isLoading: false, unreadCount: parseInt(state.unreadCount) + addCount });
        }
        case NOTIFICATIONS_LOADING: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case NOTIFICATIONS_LOADING_COMPLETE: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        default: {
            return state;
        }
    }
};
