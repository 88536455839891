var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { getFormikErrorMessage } from "../../tools/generalTools";
import KCheckbox from "../KCheckbox/KCheckbox";
import "./KTextField.css";
var KTextField = function (_a) {
    var name = _a.name, label = _a.label, placeholder = _a.placeholder, _b = _a.onChange, onChange = _b === void 0 ? function (f) { return f; } : _b, width = _a.width, height = _a.height, labelMargin = _a.labelMargin, value = _a.value, marginVertical = _a.marginVertical, marginHorizontal = _a.marginHorizontal, marginRight = _a.marginRight, borderless = _a.borderless, marginLeft = _a.marginLeft, title = _a.title, pattern = _a.pattern, onKeyUp = _a.onKeyUp, onFocus = _a.onFocus, borderRadius = _a.borderRadius, backgroundColor = _a.backgroundColor, autoCapitalize = _a.autoCapitalize, onClick = _a.onClick, borderColor = _a.borderColor, onBlurCapture = _a.onBlurCapture, onBlur = _a.onBlur, inputRef = _a.inputRef, onKeyDown = _a.onKeyDown, fontSize = _a.fontSize, _c = _a.type, type = _c === void 0 ? "text" : _c, _d = _a.required, required = _d === void 0 ? false : _d, extra = _a.extra, errorMessage = _a.errorMessage, errorMessageFontSize = _a.errorMessageFontSize, _e = _a.defaultBorderColor, defaultBorderColor = _e === void 0 ? null : _e, _f = _a.readOnly, readOnly = _f === void 0 ? false : _f, formik = _a.formik, imgSrc = _a.imgSrc;
    var _g = useState(false), checked = _g[0], setChecked = _g[1];
    var styleContainer = {
        "--ktextfield-width": width || "200px",
        "--ktextfield-height": height || "42px",
        "--ktextfield-label-margin": labelMargin || "5px",
        "--ktextfield-border-radius": borderless ? "0" : "8px",
        "--ktextfield-border": borderless ? "0" : "",
        "--ktextfield-border-radius": borderRadius || "8px",
        "--ktextfield-margin-vertical": marginVertical || "0px",
        "--ktextfield-margin-horizontal": marginHorizontal || "0px",
        "--ktextfield-margin-right": marginRight || "0px",
        "--ktextfield-margin-left": marginLeft || "0px",
        "--ktextfield-font-size-regular": fontSize || "var(--app-font-size-regular)",
        "--ktextfield-background-color": backgroundColor || "var(--app-tertiary-background-color)",
        "--ktextfield-border-color": borderColor || "var(--app-primary-color)",
        "--ktextfield-default-border-color": defaultBorderColor || "var(--ktextfield-background-color)",
    };
    errorMessage = errorMessage || getFormikErrorMessage(formik, name);
    var errorOrNot = errorMessage ? "err" : "";
    return (_jsx("div", __assign({ className: "ktextfield-container", style: styleContainer }, { children: _jsxs("div", __assign({ className: "ktextfield-wrapper " + errorOrNot }, { children: [_jsx("label", __assign({ className: "ktextfield-label" }, { children: label })), _jsx("input", __assign({ style: {
                        paddingRight: imgSrc ? "var(--ktextfield-height)" : "",
                    }, className: "ktextfield-input", autoCapitalize: autoCapitalize, required: required, ref: inputRef, onKeyUp: onKeyUp, name: name, type: type === "password"
                        ? checked
                            ? "text"
                            : "password"
                        : type, readOnly: readOnly, title: title, onClick: onClick, placeholder: placeholder, onFocus: onFocus, onKeyPress: function (event) {
                        if (type != "number")
                            return;
                        if (event.which < 48 || event.which > 57)
                            event.preventDefault();
                    }, onBlurCapture: onBlurCapture, onChange: function (e) {
                        formik === null || formik === void 0 ? void 0 : formik.handleChange(e);
                        onChange(e);
                    }, onKeyDown: onKeyDown, pattern: pattern }, extra, (typeof value !== "undefined" ? { value: value } : {}))), errorMessage ? (_jsx("div", __assign({ className: "errorMsg" }, { children: errorMessage || "" }))) : (_jsx(_Fragment, {})), type === "password" ? (_jsx("div", __assign({ className: "ktextfield-icon-box" }, { children: _jsx(KCheckbox, { title: "Show Password", variant: "loaded-user", checked: checked, fontSize: "var(--app-font-size-small-1)", onChange: function () {
                            setChecked(!checked);
                        } }) }))) : (_jsx(_Fragment, {})), imgSrc && (_jsx("div", __assign({ className: "ktextfield-icon" }, { children: _jsx("img", { src: imgSrc }) })))] })) })));
};
export default KTextField;
