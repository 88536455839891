import axios from "axios";
import store from "../redux/store";
import { getToken } from "../redux/selectors/authSelectors";
var paymentApi = axios.create({
    baseURL: process.env.PAYMENT_SERVICE_API,
    responseType: "json",
});
paymentApi.defaults.timeout = parseInt(process.env.SCRIPTO_API_TIMEOUT) * 1000;
paymentApi.interceptors.request.use(function (config) {
    config.headers.Authorization =
        process.env.SCRIPTOs_API_KEY + "~ATK~" + getToken(store.getState());
    return config;
});
export default paymentApi;
