var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements, } from "@stripe/react-stripe-js";
import { connect } from "react-redux";
import KButton from "../../base-components/KButton/KButton";
import { IoIosCloseCircle as IconClose } from "react-icons/io";
import { HiCreditCard as IconCard } from "react-icons/hi";
import { getColorMode } from "../../redux/selectors/generalSelectors";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getUser, getUserDefaultPaymentMethod, } from "../../redux/selectors/authSelectors";
import { getFullName } from "../../tools/userTools";
import { thunkStartLoading, thunkStopLoading, } from "../../redux/thunks/generalThunks";
import { thunkLoadDefaultPaymentMethod } from "../../redux/thunks/authThunks";
import CardBox from "../../components/CardBox/CardBox";
import "./CheckoutPage.css";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
var stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
var CheckoutForm = function (_a) {
    var fullName = _a.fullName, email = _a.email, returnUrl = _a.returnUrl, passedClientSecret = _a.passedClientSecret, defaultPaymentMethod = _a.defaultPaymentMethod, onStartLoading = _a.onStartLoading, onStopLoading = _a.onStopLoading;
    var stripe = useStripe();
    var elements = useElements();
    var _b = useState(null), message = _b[0], setMessage = _b[1];
    var _c = useState(false), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(false), useDefaultCard = _d[0], setUseDefaultCard = _d[1];
    useEffect(function () {
        if (!stripe) {
            return;
        }
        var clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
        if (!clientSecret) {
            return;
        }
        stripe.retrievePaymentIntent(clientSecret).then(function (_a) {
            var paymentIntent = _a.paymentIntent;
            switch (paymentIntent.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var paymentIntent, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    if (!stripe || !elements) {
                        // Stripe.js has not yet loaded.
                        // Make sure to disable form submission until Stripe.js has loaded.
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    onStartLoading();
                    if (!useDefaultCard) return [3 /*break*/, 2];
                    return [4 /*yield*/, stripe.confirmCardPayment(passedClientSecret, { payment_method: defaultPaymentMethod.id })];
                case 1:
                    paymentIntent = (_a.sent()).paymentIntent;
                    if (paymentIntent.status === "succeeded") {
                        window.location.href = returnUrl;
                    }
                    else {
                        setMessage("An unexpected error occured.");
                    }
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, stripe.confirmPayment({
                        elements: elements,
                        confirmParams: {
                            // Make sure to change this to your payment completion page
                            return_url: returnUrl,
                            receipt_email: email,
                            save_payment_method: true,
                        },
                        billing_details: {
                            name: fullName,
                        },
                        save_payment_method: true,
                        setup_future_usage: "off_session",
                    })];
                case 3:
                    error = (_a.sent()).error;
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Otherwise, your customer will be redirected to
                    // your `return_url`. For some payment methods like iDEAL, your customer will
                    // be redirected to an intermediate site first to authorize the payment, then
                    // redirected to the `return_url`.
                    if (error.type === "card_error" ||
                        error.type === "validation_error") {
                        setMessage(error.message);
                    }
                    else {
                        setMessage("An unexpected error occured.");
                    }
                    _a.label = 4;
                case 4:
                    setIsLoading(false);
                    onStopLoading();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        setUseDefaultCard(defaultPaymentMethod.id ? true : false);
    }, [defaultPaymentMethod]);
    return (_jsx(_Fragment, { children: stripe && elements && (_jsxs("form", __assign({ id: "payment-form", onSubmit: handleSubmit }, { children: [useDefaultCard && defaultPaymentMethod.id ? (_jsx(CardBox, { width: "100%", maxWidth: "400px", paymentMethod: defaultPaymentMethod, isDefault: true })) : (_jsx(_Fragment, {})), useDefaultCard ? (_jsx(_Fragment, {})) : (_jsx(PaymentElement, { id: "payment-element" })), defaultPaymentMethod.id ? (_jsx("div", __assign({ className: "checkoutpage-cardbox-button-text ".concat(useDefaultCard
                        ? ""
                        : "checkoutpage-cardbox-button-default"), onClick: function () { return setUseDefaultCard(!useDefaultCard); } }, { children: useDefaultCard
                        ? "Click to use a new card"
                        : "Click to use your default card" }))) : (_jsx(_Fragment, {})), _jsx(KButton, { type: "submit", title: "Pay now", variant: "primary", marginVertical: "18px", onClick: function (f) { return f; }, disabled: isLoading || !stripe || !elements }), message && _jsx("div", __assign({ id: "payment-message" }, { children: message }))] }))) }));
};
var CheckoutPage = function (_a) {
    var email = _a.email, authUser = _a.authUser, colorMode = _a.colorMode, clientSecret = _a.clientSecret, returnUrl = _a.returnUrl, invoiceData = _a.invoiceData, totalAmount = _a.totalAmount, currency = _a.currency, onStartLoading = _a.onStartLoading, onStopLoading = _a.onStopLoading, defaultPaymentMethod = _a.defaultPaymentMethod, onLoadDefaultPaymentMethod = _a.onLoadDefaultPaymentMethod, _b = _a.onClose, onClose = _b === void 0 ? function (f) { return f; } : _b, _c = _a.visible, visible = _c === void 0 ? false : _c;
    var _d = useState(null), options = _d[0], setOptions = _d[1];
    var updateOptions = function () {
        var style = getComputedStyle(document.body);
        var appearance = {
            theme: "flat",
            variables: {
                colorDanger: "#cc0033",
                fontWeightNormal: "400",
                fontSizeSm: style.getPropertyValue("--app-font-size-small-2"),
                focusOutline: "1.5px solid ".concat(style.getPropertyValue("--app-primary-color")),
                focusBoxShadow: "none",
                fontFamily: "Sohne, system-ui, sans-serif",
                fontWeightNormal: "500",
                borderRadius: "8px",
                colorBackground: style.getPropertyValue("--app-primary-background-color"),
                colorPrimary: style.getPropertyValue("--app-primary-color"),
                colorPrimaryText: "#1A1B25",
                colorText: "white",
                colorTextSecondary: style.getPropertyValue("--app-font-color-light"),
                colorTextPlaceholder: style.getPropertyValue("--app-font-color-light"),
                colorIconTab: "white",
                colorLogo: "dark",
            },
            rules: {
                ".Input": {
                    padding: "13px",
                    backgroundColor: "var(--colorBackground)",
                    fontSize: style.getPropertyValue("--app-font-size-regular"),
                    color: style.getPropertyValue("--app-font-color"),
                    marginBottom: "10px",
                },
                ".Input--invalid": {
                    boxShadow: "0 0 0 1px var(--colorDanger)",
                    backgroundColor: style.getPropertyValue("--app-textfield-error-color"),
                },
                ".Label": {
                    color: style.getPropertyValue("--app-font-color-light"),
                    fontSize: style.getPropertyValue("--app-font-size-small-2"),
                    marginBottom: "6px",
                    fontWeight: "400",
                },
            },
        };
        setOptions({ clientSecret: clientSecret, appearance: appearance });
    };
    useEffect(function () {
        updateOptions();
    }, [colorMode]);
    useEffect(function () {
        onLoadDefaultPaymentMethod();
    }, []);
    return (_jsx(_Fragment, { children: visible && clientSecret && options && (_jsxs("div", __assign({ className: "checkoutform-container" }, { children: [_jsxs("div", __assign({ className: "checkoutform-title" }, { children: [_jsx("div", __assign({ className: "checkoutform-title-icon" }, { children: _jsx(IconCard, {}) })), _jsx("div", __assign({ className: "checkoutform-title-text" }, { children: "Checkout" })), _jsx(IconClose, { className: "checkoutform-icon-close", onClick: function () { return onClose(); } })] })), invoiceData && (_jsxs("div", __assign({ className: "checkoutpage-info" }, { children: [_jsx("div", __assign({ className: "checkoutpage-info-title" }, { children: "Summary" })), _jsx("div", __assign({ className: "checkoutpage-items" }, { children: invoiceData.map(function (item, key) {
                                var _a, _b;
                                return (_jsxs("div", __assign({ className: "checkoutpage-item-box" }, { children: [_jsx("div", __assign({ className: "checkoutpage-item-info" }, { children: item.name })), _jsxs("div", __assign({ className: "checkoutpage-item-info" }, { children: [item.unitAmount, " ", (_a = item.currency) === null || _a === void 0 ? void 0 : _a.toUpperCase(), "/UNIT"] })), _jsxs("div", __assign({ className: "checkoutpage-item-info" }, { children: ["x", item.quantity] })), _jsxs("div", __assign({ className: "checkoutpage-item-info" }, { children: [item.amount, " ", (_b = item.currency) === null || _b === void 0 ? void 0 : _b.toUpperCase()] }))] }), key));
                            }) })), _jsxs("div", __assign({ className: "checkoutpage-total" }, { children: [_jsx("div", { children: "Total" }), _jsxs("div", { children: [totalAmount, " ", currency === null || currency === void 0 ? void 0 : currency.toUpperCase()] })] }))] }))), visible && clientSecret && options && (_jsx(Elements, __assign({ options: options, stripe: stripePromise }, { children: _jsx(CheckoutForm, { email: email, returnUrl: returnUrl, defaultPaymentMethod: defaultPaymentMethod, passedClientSecret: clientSecret, fullName: getFullName(authUser), onStartLoading: onStartLoading, onStopLoading: onStopLoading }) })))] }))) }));
};
var mapStateToProps = function (state) { return ({
    defaultPaymentMethod: getUserDefaultPaymentMethod(state),
    colorMode: getColorMode(state),
    authUser: getUser(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onStartLoading: function () { return dispatch(thunkStartLoading()); },
    onStopLoading: function () { return dispatch(thunkStopLoading()); },
    onLoadDefaultPaymentMethod: function () { return dispatch(thunkLoadDefaultPaymentMethod()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
