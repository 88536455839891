var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { contactUsInProgress, contactUsSuccess, contactUsFailure, clearSuccessData, } from "../actions/emailActions";
import { getErrorMessage } from "../utils";
import emailApi from "../../apis/emailApi";
import { getUser } from "../selectors/authSelectors";
export var thunkContactUs = function (contactUsBody) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(contactUsInProgress());
        emailApi
            .post("/contactus", {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            data: __assign({}, contactUsBody),
        })
            .then(function (res) {
            dispatch(contactUsSuccess(res.data));
        })
            .catch(function (err) {
            dispatch(contactUsFailure(getErrorMessage(err)));
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkRecommendLawyer = function (lawyerName, lawyerEmail, lawyerPhone, userName, userEmail, callback) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(contactUsInProgress());
            emailApi
                .post("/recommendlawyer", {
                headers: { "Content-Type": "application/json" },
                method: "POST",
                data: {
                    lawyerName: lawyerName,
                    lawyerEmail: lawyerEmail,
                    lawyerPhone: lawyerPhone,
                    userName: userName,
                    userEmail: userEmail,
                },
            })
                .then(function (res) {
                callback();
                dispatch(contactUsSuccess(res.data));
            })
                .catch(function (err) {
                dispatch(contactUsFailure(getErrorMessage(err)));
            });
            return [2 /*return*/];
        });
    }); };
};
export var thunkReferFriends = function (friends) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var user, res, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            dispatch(contactUsInProgress());
                            user = getUser(getState());
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, emailApi.post("/referfriends", {
                                    data: {
                                        userName: "".concat(user.user_first_name, " ").concat(user.user_last_name),
                                        userEmail: user.user_email,
                                        friends: friends,
                                    },
                                })];
                        case 2:
                            res = _a.sent();
                            dispatch(contactUsSuccess(res.data));
                            resolve(res.data);
                            return [3 /*break*/, 4];
                        case 3:
                            err_1 = _a.sent();
                            dispatch(contactUsFailure(getErrorMessage(err_1)));
                            reject(err_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); }; };
export var thunkInviteUser = function (email, informEmail, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(void 0, void 0, void 0, function () {
                var user, res, err_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            user = getUser(getState());
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, emailApi.post("/invite", {
                                    data: {
                                        email: email,
                                        informEmail: informEmail,
                                    },
                                })];
                        case 2:
                            res = _a.sent();
                            // dispatch(contactUsSuccess(res.data));
                            resolve(res.data);
                            return [3 /*break*/, 4];
                        case 3:
                            err_2 = _a.sent();
                            // dispatch(contactUsFailure(getErrorMessage(err)));
                            reject(err_2);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); })];
    });
}); }; };
export var thunkClearSuccess = function () { return function (dispatch, getState) {
    dispatch(clearSuccessData());
}; };
