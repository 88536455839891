var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./KSwitch.css";
var KSwitch = function (_a) {
    var checked = _a.checked, onChange = _a.onChange;
    return (_jsxs("label", __assign({ className: "kswitch-container" }, { children: [_jsx("input", { className: "kswitch-input", type: "checkbox", checked: checked, onChange: onChange }), _jsx("span", { className: "kswitch-slider" })] })));
};
export default KSwitch;
