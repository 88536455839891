import axios from "axios";
import urljoin from "url-join";
import store from "../redux/store";
import { getToken } from "../redux/selectors/authSelectors";
var storageResource;
switch (process.env.STORAGE_TYPE) {
    case "FileSystem":
        storageResource = "file";
        break;
    case "CloudStorage":
        storageResource = "storage";
        break;
    default:
        storageResource = "file";
}
var FILE_API_URL = urljoin(process.env.SCRIPTO_API_URL, "scripto/api/v0/".concat(storageResource, "/"));
var fileApi = axios.create({ baseURL: FILE_API_URL });
fileApi.interceptors.request.use(function (config) {
    config.headers.Authorization =
        process.env.SCRIPTO_API_KEY + "~ATK~" + getToken(store.getState());
    return config;
});
var fileBaseUrl, videoBaseUrl, downladBaseUrl;
switch (process.env.STORAGE_TYPE) {
    case "FileSystem":
        fileBaseUrl = urljoin(FILE_API_URL, "download");
        videoBaseUrl = urljoin(FILE_API_URL, "video");
        downladBaseUrl = urljoin(FILE_API_URL, "download");
        break;
    case "CloudStorage":
        fileBaseUrl = urljoin(FILE_API_URL, "stream");
        videoBaseUrl = urljoin(FILE_API_URL, "stream");
        downladBaseUrl = urljoin(FILE_API_URL, "download");
        break;
    default:
        fileBaseUrl = urljoin(FILE_API_URL, "download");
        videoBaseUrl = urljoin(FILE_API_URL, "video");
        downladBaseUrl = urljoin(FILE_API_URL, "download");
}
export var getFileUrl = function (relativePath) {
    return urljoin(fileBaseUrl, relativePath);
};
export var getVideoUrl = function (relativePath) {
    return urljoin(videoBaseUrl, relativePath);
};
export var getStorageVideoUrl = function (relativePath) {
    return urljoin(process.env.SCRIPTO_API_URL, "scripto/api/v0/storage/stream", relativePath);
};
export var getDownloadLinkUrl = function (relativePath, duration) {
    if (duration === void 0) { duration = 60000; }
    if (storageResource === "storage")
        return urljoin(downladBaseUrl, duration.toString(), relativePath);
    return urljoin(downladBaseUrl, relativePath);
};
export default fileApi;
