var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { IoIosCloseCircle as IconClose } from "react-icons/io";
import "./PaymentCompletePage.css";
var PaymentCompletePage = function (_a) {
    var _b = _a.amount, amount = _b === void 0 ? "$0" : _b, _c = _a.description, description = _c === void 0 ? "" : _c, _d = _a.feesTitle, feesTitle = _d === void 0 ? "Paid Fees" : _d, _e = _a.title, title = _e === void 0 ? "Thank you for your payment" : _e, _f = _a.onClose, onClose = _f === void 0 ? function (f) { return f; } : _f;
    var location = useLocation();
    return (_jsx("div", __assign({ className: "paymentcompletepage-container" }, { children: _jsxs("div", __assign({ className: "paymentcompletepage-box" }, { children: [_jsx(IconClose, { className: "paymentcompletepage-icon-close", onClick: function () { return onClose(); } }), _jsxs("div", __assign({ className: "paymentcompletepage-title" }, { children: [_jsx("img", { className: "paymentcompletepage-logo", alt: "Scriptofilm" }), _jsxs("div", __assign({ className: "paymentcompletepage-title-box" }, { children: [_jsx("div", __assign({ className: "paymentcompletepage-title-text" }, { children: title })), _jsxs("div", __assign({ className: "paymentcompletepage-title-info" }, { children: [feesTitle, " ", amount] }))] })), _jsx("div", __assign({ className: "paymentcompletepage-description" }, { children: description }))] }))] })) })));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({}); };
export default connect(mapStateToProps, mapDispatchToProps)(PaymentCompletePage);
