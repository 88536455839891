var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { clearProjectInProcess, clearProjectsData, clearProject, createProjectFailure, createProjectSuccess, createProjectInProgress, updateProjectInProcess, loadProjectFailure, loadProjectSuccess, loadProjectInProgress, loadMarketProjectsFailure, loadMarketProjectsSuccess, loadMarketProjectsInProgress, loadProjectsFailure, loadProjectsSuccess, loadProjectsInProgress, deleteProjectInProgress, deleteProjectSuccess, deleteProjectFailure, updateProjectInProgress, updateProjectSuccess, updateProjectFailure, loadMessagingProjectsInProgress, loadMessagingProjectsSuccess, loadMessagingProjectsFailure, appendMarketProjectsSuccess, loadBoostedMarketProjectsInProgress, loadBoostedMarketProjectsSuccess, loadBoostedMarketProjectsFailure, appendMarketProjectsInProgress, createProjectBoostInProgress, createProjectBoostFailure, createProjectBoostSuccess, accessRequestInProgress, accessRequestSuccess, accessRequestFailure, accessRequestsSuccess, accessStatusChangeSuccess, loadCollaborationProjectsInProgress, loadCollaborationProjectsSuccess, loadCollaborationProjectFailure, loadCollaborationUsersInProgress, loadCollaborationUsersSuccess, loadCollaborationUsersFailure, loadProjectCharactersInProgress, loadProjectCharactersSuccess, loadProjectCharactersFailure, loadProjectMembersInProgress, loadProjectMembersSuccess, loadProjectMembersFailure, loadProjectShootingSetInProgress, loadProjectShootingSetSuccess, loadProjectShootingSetFailure, loadProjectVideoSceneInProgress, loadProjectVideoSceneSuccess, loadProjectVideoSceneFailure, loadInterestedProjectsInProgress, loadInterestedProjectsSuccess, loadInterestedProjectsFailure, updateProjectStatusSuccess, projectsLoading, projectsLoadingComplete, loadUserBoostedProjectsInProgress, loadUserBoostedProjectsFailure, loadUserBoostedProjectsSuccess, } from "../actions/projectActions";
import fileApi from "../../apis/fileApi";
import projectApi from "../../apis/projectApi";
import { getUser, getUserAuthenticated, getUserId, } from "../selectors/authSelectors";
import { getProjectInProcess } from "../selectors/projectSelectors";
import { displayAlert, getErrorMessage, handleError } from "../utils";
import { getUserProjectInterests } from "../selectors/userSelectors";
import boostApi from "../../apis/boostApi";
import paymentApi from "../../apis/paymentApi";
import { getEditedObjects, getUnEditedObjectIds, uploadFiles, } from "../../tools/fileTools";
import projectViewApi from "../../apis/projectViewApi";
import { history } from "../store";
import subscriptionApi from "../../apis/subscriptionApi";
export var thunkLoadProjects = function (userId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectsInProgress());
                return [4 /*yield*/, projectApi.get("/".concat(userId))];
            case 2:
                response = _a.sent();
                dispatch(loadProjectsSuccess(response.data));
                return [3 /*break*/, 4];
            case 3:
                err_1 = _a.sent();
                dispatch(loadProjectsFailure(err_1));
                handleError(err_1);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectsByIds = function (projectIds) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadMessagingProjectsInProgress());
                return [4 /*yield*/, projectApi.get("/pids/", {
                        params: { project_ids: projectIds },
                    })];
            case 2:
                response = _a.sent();
                dispatch(loadMessagingProjectsSuccess(response.data));
                return [3 /*break*/, 4];
            case 3:
                err_2 = _a.sent();
                dispatch(loadMessagingProjectsFailure(err_2));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadMarketProjects = function (searchText, limit, offset, reload, searchOptions, lookingfor) {
    if (limit === void 0) { limit = 10; }
    if (offset === void 0) { offset = 0; }
    if (reload === void 0) { reload = true; }
    if (searchOptions === void 0) { searchOptions = {}; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_3;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _k.label = 1;
                case 1:
                    _k.trys.push([1, 3, , 4]);
                    if (reload)
                        dispatch(loadMarketProjectsInProgress());
                    else
                        dispatch(appendMarketProjectsInProgress());
                    return [4 /*yield*/, projectApi.get("/all/", {
                            params: {
                                searchText: searchText,
                                limit: limit,
                                offset: offset,
                                lookingfor: lookingfor,
                                projectTypeIds: ((_a = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.projectTypeIds) === null || _a === void 0 ? void 0 : _a.length)
                                    ? JSON.stringify(searchOptions.projectTypeIds)
                                    : null,
                                projectSubtypeIds: ((_b = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.projectSubtypeIds) === null || _b === void 0 ? void 0 : _b.length)
                                    ? JSON.stringify(searchOptions.projectSubtypeIds)
                                    : null,
                                projectKindIds: ((_c = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.projectKindIds) === null || _c === void 0 ? void 0 : _c.length)
                                    ? JSON.stringify(searchOptions.projectKindIds)
                                    : null,
                                projectGenreIds: ((_d = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.projectGenreIds) === null || _d === void 0 ? void 0 : _d.length)
                                    ? JSON.stringify(searchOptions.projectGenreIds)
                                    : null,
                                countryIds: ((_e = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.countryIds) === null || _e === void 0 ? void 0 : _e.length)
                                    ? JSON.stringify(searchOptions.countryIds)
                                    : null,
                                languageIds: ((_f = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.languageIds) === null || _f === void 0 ? void 0 : _f.length)
                                    ? JSON.stringify(searchOptions.languageIds)
                                    : null,
                                openForSale: ((_g = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.sale_collaboration) === null || _g === void 0 ? void 0 : _g.includes(1))
                                    ? true
                                    : null,
                                openForCollaboration: ((_h = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.sale_collaboration) === null || _h === void 0 ? void 0 : _h.includes(2)) || null,
                                minAcquisitionBudget: (searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.min_acquisition_budget) || null,
                                maxAcquisitionBudget: (searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.max_acquisition_budget) || null,
                                minProductionBudget: (searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.min_production_budget) || null,
                                maxProductionBudget: (searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.max_production_budget) || null,
                                personalized: ((_j = searchOptions === null || searchOptions === void 0 ? void 0 : searchOptions.personalized) === null || _j === void 0 ? void 0 : _j[0]) || null,
                                projectInterests: JSON.stringify(getUserProjectInterests(getState())),
                            },
                        })];
                case 2:
                    response = _k.sent();
                    if (reload)
                        dispatch(loadMarketProjectsSuccess(response.data));
                    else
                        dispatch(appendMarketProjectsSuccess(response.data));
                    return [3 /*break*/, 4];
                case 3:
                    err_3 = _k.sent();
                    dispatch(loadMarketProjectsFailure(err_3));
                    dispatch(displayAlert(err_3));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkLoadBoostedMarketProjects = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadBoostedMarketProjectsInProgress());
                return [4 /*yield*/, projectApi.get("/all/", {
                        params: { limit: 1, offset: 50 * Math.random() },
                    })];
            case 2:
                response = _a.sent();
                dispatch(loadBoostedMarketProjectsSuccess(response.data));
                return [3 /*break*/, 4];
            case 3:
                err_4 = _a.sent();
                dispatch(loadBoostedMarketProjectsFailure(err_4));
                dispatch(displayAlert(err_4));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProject = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectInProgress());
                return [4 /*yield*/, projectApi.get("/pid/".concat(projectId))];
            case 2:
                response = _a.sent();
                dispatch(loadProjectSuccess(response.data));
                return [3 /*break*/, 4];
            case 3:
                err_5 = _a.sent();
                dispatch(loadProjectFailure(err_5));
                handleError(err_5);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkUpdateProjectInProcess = function (project) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var projectInProcess;
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        projectInProcess = getProjectInProcess(getState());
        dispatch(updateProjectInProcess(__assign(__assign({}, projectInProcess), project)));
        return [2 /*return*/];
    });
}); }; };
export var thunkCreateProject = function (project) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var userId, coverImagesArray, moodImagesArray, storyImagesArray, synopsisFilesArray, treatmentFilesArray, shootingSetArray, charactersArray, actorsArray, crewsArray, date, response, createdProject, err_6;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _e.label = 1;
            case 1:
                _e.trys.push([1, 12, , 13]);
                userId = getUserId(getState());
                dispatch(createProjectInProgress());
                return [4 /*yield*/, uploadFiles(project.coverInfoArray || [], userId, "cover-images", true)];
            case 2:
                coverImagesArray = _e.sent();
                return [4 /*yield*/, uploadFiles(project.moodboardArray || [], userId, "moodboard-images", true)];
            case 3:
                moodImagesArray = _e.sent();
                return [4 /*yield*/, uploadFiles(project.storyboardArray || [], userId, "storyboard-images", true)];
            case 4:
                storyImagesArray = _e.sent();
                return [4 /*yield*/, uploadFiles(project.synopsisFiles || [], userId, "synopsis-files", false)];
            case 5:
                synopsisFilesArray = _e.sent();
                return [4 /*yield*/, uploadFiles(project.treatmentFiles || [], userId, "treatment-files", false)];
            case 6:
                treatmentFilesArray = _e.sent();
                return [4 /*yield*/, uploadFiles(project.shootingSetArray || [], userId, "shooting-set", true)];
            case 7:
                shootingSetArray = _e.sent();
                return [4 /*yield*/, uploadFiles(project.characters || [], userId, "characters", true)];
            case 8:
                charactersArray = _e.sent();
                return [4 /*yield*/, uploadFiles((project.actors || []).filter(function (e) { return e.member_name !== "" && e.member_role !== ""; }), userId, "actors", true)];
            case 9:
                actorsArray = _e.sent();
                return [4 /*yield*/, uploadFiles((project.crewMembers || []).filter(function (e) { return e.member_name !== "" && e.member_role !== ""; }), userId, "crews", true)];
            case 10:
                crewsArray = _e.sent();
                date = (project.year || new Date().getFullYear()) +
                    "-" +
                    (project.month || new Date().getMonth() + 1) +
                    "-02";
                return [4 /*yield*/, projectApi.post("/", {
                        headers: { "Content-Type": "application/json" },
                        method: "POST",
                        data: {
                            title: project.title || "",
                            synopsis: project.synopsis || "",
                            date: date,
                            treatment: project.treatment,
                            financingBudget: project.financingBudget || null,
                            productionBudget: project.productionBudget || null,
                            userId: userId,
                            acquisitionBudget: project.acquisitionBudget || null,
                            pages: project.pages || null,
                            investmentBudget: project.investmentBudget || null,
                            openForSale: project.openForSale,
                            openForCollaboration: project.openForCollaboration,
                            duration: project.duration || null,
                            typeId: project.projectTypeId,
                            subtypeId: project.projectSubtypeId,
                            countryId: project.country
                                ? project.country.country_id || null
                                : null,
                            languageId: project.language
                                ? project.language.language_id || null
                                : null,
                            kindId: project.kind
                                ? project.kind.project_kind_id || null
                                : null,
                            genreId: project.genre
                                ? project.genre.project_genre_id || null
                                : null,
                            stageId: project.stage
                                ? project.stage.project_stage_id || null
                                : null,
                            projectUserInterestIds: project.projectUserInterestIds || [],
                            presalesArray: project.presalesArray || [],
                            fundingArray: project.fundingArray || [],
                            fullProjectPath: project.fullProjectPath || "",
                            productionCompanyName: project.productionCompanyName || "",
                            productionCompanyWebsite: project.productionCompanyWebsite || "",
                            coProductionCompanyName: project.coProductionCompanyName || "",
                            coProductionCompanyWebsite: project.coProductionCompanyWebsite || "",
                            directorId: ((_a = project.director) === null || _a === void 0 ? void 0 : _a.user_id) || null,
                            producerId: ((_b = project.producer) === null || _b === void 0 ? void 0 : _b.user_id) || null,
                            directorName: ((_c = project.director) === null || _c === void 0 ? void 0 : _c.name) || "",
                            producerName: ((_d = project.producer) === null || _d === void 0 ? void 0 : _d.name) || "",
                            coverImagesArray: coverImagesArray,
                            moodImagesArray: moodImagesArray,
                            storyImagesArray: storyImagesArray,
                            synopsisFilesArray: synopsisFilesArray,
                            treatmentFilesArray: treatmentFilesArray,
                            videoSceneArray: project.videoSceneArray,
                            shootingSetArray: shootingSetArray,
                            charactersArray: charactersArray,
                            actorsArray: actorsArray,
                            crewsArray: crewsArray,
                        },
                    })];
            case 11:
                response = _e.sent();
                createdProject = response.data;
                dispatch(createProjectSuccess(createdProject));
                return [2 /*return*/, createdProject.project_id];
            case 12:
                err_6 = _e.sent();
                dispatch(createProjectFailure(err_6));
                dispatch(displayAlert(err_6));
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}); }; };
export var thunkUpdateProject = function (project) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var userId, coverImagesArrayToAdd, moodImagesArrayToAdd, storyImagesArrayToAdd, synopsisFilesArrayToAdd, treatmentFilesArrayToAdd, shootingSetArrayToAdd, charactersArrayToAdd, actorsArrayToAdd, crewsArrayToAdd, coverImageIdsToKeep, moodImageIdsToKeep, storyImageIdsToKeep, synopsisFileIdsToKeep, treatmentFileIdsToKeep, videoSceneIdsToKeep, shootingSetIdsToKeep, characterIdsToKeep, actorIdsToKeep, crewIdsToKeep, videoSceneArrayToAdd, date, response, err_7;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _e.label = 1;
            case 1:
                _e.trys.push([1, 12, , 13]);
                userId = getUserId(getState());
                dispatch(updateProjectInProgress());
                return [4 /*yield*/, uploadFiles(getEditedObjects(project.coverInfoArray), userId, "cover-images", true)];
            case 2:
                coverImagesArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects(project.moodboardArray), userId, "moodboard-images", true)];
            case 3:
                moodImagesArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects(project.storyboardArray), userId, "storyboard-images", true)];
            case 4:
                storyImagesArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects(project.synopsisFiles), userId, "synopsis-files", false)];
            case 5:
                synopsisFilesArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects(project.treatmentFiles), userId, "treatment-files", false)];
            case 6:
                treatmentFilesArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects(project.shootingSetArray), userId, "shooting-set", true)];
            case 7:
                shootingSetArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects(project.characters), userId, "characters", true)];
            case 8:
                charactersArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects((project.actors || []).filter(function (e) { return e.member_name !== "" && e.member_role !== ""; })), userId, "actors", true)];
            case 9:
                actorsArrayToAdd = _e.sent();
                return [4 /*yield*/, uploadFiles(getEditedObjects((project.crewMembers || []).filter(function (e) { return e.member_name !== "" && e.member_role !== ""; })), userId, "crews", true)];
            case 10:
                crewsArrayToAdd = _e.sent();
                coverImageIdsToKeep = getUnEditedObjectIds(project.coverInfoArray);
                moodImageIdsToKeep = getUnEditedObjectIds(project.moodboardArray);
                storyImageIdsToKeep = getUnEditedObjectIds(project.storyboardArray);
                synopsisFileIdsToKeep = getUnEditedObjectIds(project.synopsisFiles);
                treatmentFileIdsToKeep = getUnEditedObjectIds(project.treatmentFiles);
                videoSceneIdsToKeep = getUnEditedObjectIds(project.videoSceneArray);
                shootingSetIdsToKeep = getUnEditedObjectIds(project.shootingSetArray);
                characterIdsToKeep = getUnEditedObjectIds(project.characters);
                actorIdsToKeep = getUnEditedObjectIds(project.actors);
                crewIdsToKeep = getUnEditedObjectIds(project.crewMembers);
                videoSceneArrayToAdd = (project.editedVideoScenesArray || []).map(function (e) {
                    return {
                        path: e.path,
                        title: e.title,
                        description: e.description,
                    };
                });
                date = (project.year || new Date().getFullYear()) +
                    "-" +
                    (project.month || new Date().getMonth() + 1) +
                    "-02";
                return [4 /*yield*/, projectApi.put("/", {
                        headers: { "Content-Type": "application/json" },
                        method: "PUT",
                        data: {
                            id: project.id,
                            title: project.title || "",
                            synopsis: project.synopsis || "",
                            treatment: project.treatment,
                            financingBudget: project.financingBudget || null,
                            productionBudget: project.productionBudget || null,
                            userId: userId,
                            date: date,
                            acquisitionBudget: project.acquisitionBudget || null,
                            pages: project.pages || null,
                            investmentBudget: project.investmentBudget || null,
                            openForSale: project.openForSale,
                            openForCollaboration: project.openForCollaboration,
                            duration: project.duration || null,
                            typeId: project.projectTypeId,
                            subtypeId: project.projectSubtypeId,
                            countryId: project.country
                                ? project.country.country_id || null
                                : null,
                            languageId: project.language
                                ? project.language.language_id || null
                                : null,
                            kindId: project.kind
                                ? project.kind.project_kind_id || null
                                : null,
                            genreId: project.genre
                                ? project.genre.project_genre_id || null
                                : null,
                            stageId: project.stage
                                ? project.stage.project_stage_id || null
                                : null,
                            coverImageIdsToKeep: coverImageIdsToKeep,
                            moodImageIdsToKeep: moodImageIdsToKeep,
                            storyImageIdsToKeep: storyImageIdsToKeep,
                            synopsisFileIdsToKeep: synopsisFileIdsToKeep,
                            treatmentFileIdsToKeep: treatmentFileIdsToKeep,
                            videoSceneIdsToKeep: videoSceneIdsToKeep,
                            shootingSetIdsToKeep: shootingSetIdsToKeep,
                            characterIdsToKeep: characterIdsToKeep,
                            actorIdsToKeep: actorIdsToKeep,
                            crewIdsToKeep: crewIdsToKeep,
                            coverImagesArrayToAdd: coverImagesArrayToAdd,
                            moodImagesArrayToAdd: moodImagesArrayToAdd,
                            storyImagesArrayToAdd: storyImagesArrayToAdd,
                            synopsisFilesArrayToAdd: synopsisFilesArrayToAdd,
                            treatmentFilesArrayToAdd: treatmentFilesArrayToAdd,
                            videoSceneArrayToAdd: videoSceneArrayToAdd,
                            shootingSetArrayToAdd: shootingSetArrayToAdd,
                            charactersArrayToAdd: charactersArrayToAdd,
                            actorsArrayToAdd: actorsArrayToAdd,
                            crewsArrayToAdd: crewsArrayToAdd,
                            presalesArray: project.presalesArray || [],
                            projectUserInterestIds: project.projectUserInterestIds || [],
                            fundingArray: project.fundingArray || [],
                            fullProjectPath: project.fullProjectPath || "",
                            productionCompanyName: project.productionCompanyName || "",
                            productionCompanyWebsite: project.productionCompanyWebsite || "",
                            coProductionCompanyName: project.coProductionCompanyName || "",
                            coProductionCompanyWebsite: project.coProductionCompanyWebsite || "",
                            directorId: ((_a = project.director) === null || _a === void 0 ? void 0 : _a.user_id) || null,
                            producerId: ((_b = project.producer) === null || _b === void 0 ? void 0 : _b.user_id) || null,
                            directorName: ((_c = project.director) === null || _c === void 0 ? void 0 : _c.name) || "",
                            producerName: ((_d = project.producer) === null || _d === void 0 ? void 0 : _d.name) || "",
                        },
                    })];
            case 11:
                response = _e.sent();
                dispatch(updateProjectSuccess(response.data));
                return [2 /*return*/, response.data.project_id];
            case 12:
                err_7 = _e.sent();
                dispatch(updateProjectFailure(err_7));
                dispatch(displayAlert(err_7));
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}); }; };
export var thunkDeleteProject = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(deleteProjectInProgress());
                return [4 /*yield*/, projectApi.delete("/".concat(projectId || ""))];
            case 2:
                response = _a.sent();
                dispatch(deleteProjectSuccess(response.data));
                return [3 /*break*/, 4];
            case 3:
                err_8 = _a.sent();
                dispatch(deleteProjectFailure(err_8));
                dispatch(displayAlert(err_8));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkChangeProjectStatus = function (projectId, isClosed) {
    if (isClosed === void 0) { isClosed = false; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var err_9;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(updateProjectInProgress());
                    return [4 /*yield*/, projectApi.put("/status/".concat(projectId || ""), {
                            data: {
                                isClosed: isClosed,
                            },
                        })];
                case 2:
                    _a.sent();
                    dispatch(updateProjectStatusSuccess(isClosed));
                    return [3 /*break*/, 4];
                case 3:
                    err_9 = _a.sent();
                    dispatch(updateProjectFailure(err_9));
                    dispatch(displayAlert(err_9));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkClearProject = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        dispatch(clearProject());
        return [2 /*return*/];
    });
}); }; };
export var thunkClearProjectsData = function () { return function (dispatch, getState) {
    dispatch(clearProjectsData());
}; };
export var thunkClearProjectInProcess = function () { return function (dispatch, getState) {
    dispatch(clearProjectInProcess());
}; };
export var thunkLoadBoostedProjects = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_10, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 7]);
                return [4 /*yield*/, boostApi.get("/projects", {
                        params: { countryId: getUser(getState()).user_country_id_fk },
                    })];
            case 2:
                res = _a.sent();
                if (!(res.data.length > 0)) return [3 /*break*/, 3];
                dispatch(loadBoostedMarketProjectsSuccess(res.data));
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, projectApi.get("/all/", {
                    params: { limit: 2, offset: 5 * Math.random() },
                })];
            case 4:
                res = _a.sent();
                dispatch(loadBoostedMarketProjectsSuccess(res.data));
                _a.label = 5;
            case 5: return [3 /*break*/, 7];
            case 6:
                err_10 = _a.sent();
                errMessage = getErrorMessage(err_10);
                console.error(errMessage);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadUserBoostedProjects = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_11, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadUserBoostedProjectsInProgress());
                return [4 /*yield*/, boostApi.get("/user", {
                        params: { userId: getUserId(getState()) },
                    })];
            case 2:
                res = _a.sent();
                dispatch(loadUserBoostedProjectsSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_11 = _a.sent();
                dispatch(loadUserBoostedProjectsFailure());
                errMessage = getErrorMessage(err_11);
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkCreateProjectBoost = function (projectId, startDate, endDate, invoiceId, countryIds, callback) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_12, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(createProjectBoostInProgress());
                    return [4 /*yield*/, boostApi.post("/", {
                            headers: { "Content-Type": "application/json" },
                            method: "POST",
                            data: { projectId: projectId, startDate: startDate, endDate: endDate, invoiceId: invoiceId, countryIds: countryIds },
                        })];
                case 2:
                    res = _a.sent();
                    dispatch(createProjectBoostSuccess());
                    callback(null);
                    return [3 /*break*/, 4];
                case 3:
                    err_12 = _a.sent();
                    errMessage = getErrorMessage(err_12);
                    dispatch(createProjectBoostFailure());
                    console.error(errMessage);
                    callback(err_12);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkCreateProjectBoostPaymentIntent = function (email, numOfDays, numOfCountries, callback) {
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_13, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(createProjectBoostInProgress());
                    return [4 /*yield*/, paymentApi.post("/stripe/paymentIntent/boost/".concat(email), {
                            headers: { "Content-Type": "application/json" },
                            method: "POST",
                            data: {
                                numOfDays: numOfDays,
                                numOfCountries: numOfCountries,
                            },
                        })];
                case 2:
                    res = _a.sent();
                    dispatch(createProjectBoostSuccess());
                    callback(res.data);
                    return [3 /*break*/, 4];
                case 3:
                    err_13 = _a.sent();
                    errMessage = getErrorMessage(err_13);
                    dispatch(createProjectBoostFailure());
                    console.error(errMessage);
                    callback(err_13);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkRequestProjectAccess = function (data, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var projectId, ndaFile, signedNdaFile, optionalMessage, projectUserId, saveFiles, ndaPath, signedNdaPath, formData, response, res, err_14, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                projectId = data.projectId, ndaFile = data.ndaFile, signedNdaFile = data.signedNdaFile, optionalMessage = data.optionalMessage, projectUserId = data.projectUserId, saveFiles = data.saveFiles;
                if (saveFiles && (!ndaFile || !signedNdaFile)) {
                    alert("Required files missing");
                    return [2 /*return*/];
                }
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 5, , 6]);
                dispatch(accessRequestInProgress());
                ndaPath = void 0, signedNdaPath = void 0;
                if (!saveFiles) return [3 /*break*/, 3];
                formData = new FormData();
                formData.append("type", "nda");
                formData.append("user", projectUserId);
                formData.append("file", ndaFile);
                formData.append("file", signedNdaFile);
                return [4 /*yield*/, fileApi.post("/upload", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    })];
            case 2:
                response = _a.sent();
                ndaPath = response.data.filepaths[0];
                signedNdaPath = response.data.filepaths[1];
                _a.label = 3;
            case 3: return [4 /*yield*/, projectApi.post("/accessRequest/".concat(projectId, "/user/").concat(getUserId(getState())), {
                    headers: { "Content-Type": "application/json" },
                    method: "POST",
                    data: { ndaPath: ndaPath, signedNdaPath: signedNdaPath, message: optionalMessage },
                })];
            case 4:
                res = _a.sent();
                dispatch(accessRequestSuccess(res.data));
                callback();
                return [3 /*break*/, 6];
            case 5:
                err_14 = _a.sent();
                errMessage = getErrorMessage(err_14);
                dispatch(accessRequestFailure());
                console.error(errMessage);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadUserCurrentAccessRequest = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_15, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(accessRequestInProgress());
                return [4 /*yield*/, projectApi.get("/accessRequest/".concat(projectId, "/user/").concat(getUserId(getState()), "/active"))];
            case 2:
                res = _a.sent();
                dispatch(accessRequestSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_15 = _a.sent();
                errMessage = getErrorMessage(err_15);
                dispatch(accessRequestFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadUserAccessRequests = function (pending) {
    if (pending === void 0) { pending = false; }
    return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
        var res, err_16, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getUserAuthenticated(getState()))
                        return [2 /*return*/];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    dispatch(accessRequestInProgress());
                    return [4 /*yield*/, projectApi.get("/accessRequest/user/".concat(getUserId(getState())))];
                case 2:
                    res = _a.sent();
                    dispatch(accessRequestsSuccess(res.data));
                    return [3 /*break*/, 4];
                case 3:
                    err_16 = _a.sent();
                    errMessage = getErrorMessage(err_16);
                    dispatch(accessRequestFailure());
                    console.error(errMessage);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
};
export var thunkRejectAccessRequest = function (projectId, userId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_17, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(accessRequestInProgress());
                return [4 /*yield*/, projectApi.put("/rejectAccess/".concat(projectId, "/user/").concat(userId))];
            case 2:
                res = _a.sent();
                dispatch(accessStatusChangeSuccess(res.data));
                callback();
                return [3 /*break*/, 4];
            case 3:
                err_17 = _a.sent();
                errMessage = getErrorMessage(err_17);
                dispatch(accessRequestFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkAcceptAccessRequest = function (projectId, userId, expiryDate, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_18, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(accessRequestInProgress());
                return [4 /*yield*/, projectApi.put("/allowAccess/".concat(projectId, "/user/").concat(userId), {
                        headers: { "Content-Type": "application/json" },
                        method: "PUT",
                        data: { expiryDate: expiryDate },
                    })];
            case 2:
                res = _a.sent();
                dispatch(accessStatusChangeSuccess(res.data));
                callback();
                return [3 /*break*/, 4];
            case 3:
                err_18 = _a.sent();
                errMessage = getErrorMessage(err_18);
                dispatch(accessRequestFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkAccessFullProjectUrl = function (projectId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_19, errMessage;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, projectApi.get("/accessUrl/".concat(projectId, "/user/").concat(getUserId(getState())))];
            case 2:
                res = _b.sent();
                callback((_a = res.data) === null || _a === void 0 ? void 0 : _a.signedUrl);
                return [3 /*break*/, 4];
            case 3:
                err_19 = _b.sent();
                errMessage = getErrorMessage(err_19);
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadCurrentNda = function (projectId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_20, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, projectApi.get("/nda/".concat(projectId, "/user/").concat(getUserId(getState())))];
            case 2:
                res = _a.sent();
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_20 = _a.sent();
                errMessage = getErrorMessage(err_20);
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadCollaborationProjects = function (authId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_21, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadCollaborationProjectsInProgress());
                return [4 /*yield*/, projectApi.get("/collaboration/user/".concat(authId))];
            case 2:
                res = _a.sent();
                dispatch(loadCollaborationProjectsSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_21 = _a.sent();
                errMessage = getErrorMessage(err_21);
                dispatch(loadCollaborationProjectFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadCollaborationUsers = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_22, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadCollaborationUsersInProgress());
                return [4 /*yield*/, projectApi.get("/collaboration/".concat(projectId))];
            case 2:
                res = _a.sent();
                dispatch(loadCollaborationUsersSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_22 = _a.sent();
                errMessage = getErrorMessage(err_22);
                dispatch(loadCollaborationUsersFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectVideoScenes = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_23, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectVideoSceneInProgress());
                return [4 /*yield*/, projectApi.get("/videoScene/project/".concat(projectId))];
            case 2:
                res = _a.sent();
                dispatch(loadProjectVideoSceneSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_23 = _a.sent();
                errMessage = getErrorMessage(err_23);
                dispatch(loadProjectVideoSceneFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectShootingSets = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_24, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectShootingSetInProgress());
                return [4 /*yield*/, projectApi.get("/shootingSet/project/".concat(projectId))];
            case 2:
                res = _a.sent();
                dispatch(loadProjectShootingSetSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_24 = _a.sent();
                errMessage = getErrorMessage(err_24);
                dispatch(loadProjectShootingSetFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectCharacters = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_25, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectCharactersInProgress());
                return [4 /*yield*/, projectApi.get("/character/project/".concat(projectId))];
            case 2:
                res = _a.sent();
                dispatch(loadProjectCharactersSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_25 = _a.sent();
                errMessage = getErrorMessage(err_25);
                dispatch(loadProjectCharactersFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadProjectMembers = function (projectId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_26, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                dispatch(loadProjectMembersInProgress());
                return [4 /*yield*/, projectApi.get("/member/project/".concat(projectId))];
            case 2:
                res = _a.sent();
                dispatch(loadProjectMembersSuccess(res.data));
                return [3 /*break*/, 4];
            case 3:
                err_26 = _a.sent();
                errMessage = getErrorMessage(err_26);
                dispatch(loadProjectMembersFailure());
                console.error(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkLoadInterestedProjects = function (userTitleId) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!getUserAuthenticated(getState()))
            return [2 /*return*/];
        dispatch(loadInterestedProjectsInProgress());
        projectApi
            .get("/lookingfor/".concat(userTitleId))
            .then(function (res) {
            dispatch(loadInterestedProjectsSuccess(res.data));
        })
            .catch(function (err) {
            getErrorMessage(err);
            dispatch(loadInterestedProjectsFailure());
        });
        return [2 /*return*/];
    });
}); }; };
export var thunkLoadProjectUserViews = function (projectId, callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_27;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()) || !projectId)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, projectViewApi.get("/".concat(projectId, "/userViews/").concat(getUserId(getState())))];
            case 2:
                res = _a.sent();
                callback(null, res.data);
                return [3 /*break*/, 4];
            case 3:
                err_27 = _a.sent();
                console.error(err_27);
                callback(err_27);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkIsBoostPaymentNeeded = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_28, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(projectsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, boostApi.get("/paymentNeeded")];
            case 2:
                res = _a.sent();
                dispatch(projectsLoadingComplete());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_28 = _a.sent();
                callback(null, err_28);
                errMessage = getErrorMessage(err_28);
                dispatch(projectsLoadingComplete());
                history.push("/platform/error");
                console.log(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
export var thunkIsSubscriptionPaymentNeeded = function (callback) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var res, err_29, errMessage;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!getUserAuthenticated(getState()))
                    return [2 /*return*/];
                dispatch(projectsLoading());
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, subscriptionApi.get("/paymentNeeded")];
            case 2:
                res = _a.sent();
                dispatch(projectsLoadingComplete());
                callback(res.data);
                return [3 /*break*/, 4];
            case 3:
                err_29 = _a.sent();
                callback(null, err_29);
                errMessage = getErrorMessage(err_29);
                dispatch(projectsLoadingComplete());
                history.push("/platform/error");
                console.log(errMessage);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
