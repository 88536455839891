var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef } from "react";
import CoverImageUploader from "../../components/CoverImageUploader/CoverImageUploader";
import KTextArea from "../../base-components/KTextArea/KTextArea";
import KButton from "../../base-components/KButton/KButton";
import KFlex from "../../base-components/KFlex/KFlex";
import ProjectFilesUploader from "../../components/ProjectFilesUploader/ProjectFilesUploader";
import VideosUploader from "../../components/VideosUploader/VideosUploader";
import { isScriptProject, isFilmProject } from "../../tools/projectTools";
import { getFileSizeInBytes } from "../../tools/generalTools";
import { MdFileUpload as IconUpload } from "react-icons/md";
import { IoIosAddCircle as IconAdd } from "react-icons/io";
import { IoMdTrash as IconDelete } from "react-icons/io";
import { MdEdit as IconEdit } from "react-icons/md";
import { BiLock as IconLock } from "react-icons/bi";
import { getUserProfilePic } from "../../tools/userTools";
import KTextField from "../../base-components/KTextField/KTextField";
import { dataURItoBlob, resize } from "../../imaging/imageTransform";
import KImage from "../../base-components/KImage/KImage";
import EditCropImage from "../EditCropImage/EditCropImage";
import KPopup from "../../base-components/KPopup/KPopup";
import "./EditProjectContentPage.css";
var EditProjectContentPage = function (_a) {
    var _b, _c;
    var userId = _a.userId, project = _a.project, onSave = _a.onSave, onChange = _a.onChange, isEditMode = _a.isEditMode, onPublish = _a.onPublish, onUpdate = _a.onUpdate, _d = _a.hasNext, hasNext = _d === void 0 ? false : _d, _e = _a.nextPage, nextPage = _e === void 0 ? "content" : _e;
    var fullProjectRef = useRef(null);
    var imageSize = parseInt(process.env.LARGE_IMAGE_SIZE);
    var maxCharacters = parseInt(process.env.PROJECT_MAX_CHARACTERS) || 10;
    var _f = useState(""), synopsis = _f[0], setSynopsis = _f[1];
    var _g = useState(""), treatment = _g[0], setTreatment = _g[1];
    var _h = useState([]), moodboardArray = _h[0], setMoodboardArray = _h[1];
    var _j = useState([]), storyboardArray = _j[0], setStoryboardArray = _j[1];
    var _k = useState([]), shootingSetArray = _k[0], setShootingSetArray = _k[1];
    var _l = useState([]), videoSceneArray = _l[0], setVideoSceneArray = _l[1];
    var _m = useState([]), coverInfoArray = _m[0], setCoverInfoArray = _m[1];
    var _o = useState([]), treatmentFiles = _o[0], setTreatmentFiles = _o[1];
    var _p = useState([]), synopsisFiles = _p[0], setSynopsisFiles = _p[1];
    var _q = useState(null), fullProjectFile = _q[0], setFullProjectFile = _q[1];
    var _r = useState("Treatment"), treatmentTitle = _r[0], setTreatmentTitle = _r[1];
    var _s = useState(false), cropModeOn = _s[0], setCropModeOn = _s[1];
    var _t = useState(), selectedImage = _t[0], setSelectedImage = _t[1];
    var _u = useState(), selectedKey = _u[0], setSelectedKey = _u[1];
    var _v = useState([
        { key: 1, name: "", brief: "" },
    ]), characters = _v[0], _setCharacters = _v[1];
    var setCharacters = function (updatedCharacters) {
        onCharactersUpdate(updatedCharacters);
        _setCharacters(updatedCharacters);
    };
    var updateCharacterImage = function (file, key) { return __awaiter(void 0, void 0, void 0, function () {
        var character;
        return __generator(this, function (_a) {
            character = characters.find(function (c) { return c.key === key; });
            character["src"] = URL.createObjectURL(file);
            character["file"] = file;
            character["isEdited"] = true;
            resize(file, imageSize, imageSize, 0, function (dataUrl, passedKey) {
                character["src"] = dataUrl;
                character["file"] = dataURItoBlob(dataUrl);
            });
            onCharactersUpdate(characters);
            setCropModeOn(false);
            return [2 /*return*/];
        });
    }); };
    var onSubmit = function () {
        onSave(__assign(__assign({}, getData()), { saved: true }), nextPage);
        window.scrollTo(0, 0);
    };
    var getData = function () {
        return {
            synopsis: synopsis,
            treatment: treatment,
            fullProjectFile: fullProjectFile,
            treatmentFiles: treatmentFiles,
            synopsisFiles: synopsisFiles,
            coverInfoArray: coverInfoArray,
            moodboardArray: moodboardArray,
            storyboardArray: storyboardArray,
            shootingSetArray: shootingSetArray,
            videoSceneArray: videoSceneArray,
            characters: characters,
        };
    };
    var onCoverInfoUpdate = function (updatedArray) {
        setCoverInfoArray(updatedArray);
        onChange(__assign(__assign({}, getData()), { coverInfoArray: updatedArray }));
    };
    var onMoodboardUpdate = function (updatedArray) {
        setMoodboardArray(updatedArray);
        onChange(__assign(__assign({}, getData()), { moodboardArray: updatedArray }));
    };
    var onStoryboardUpdate = function (updatedArray) {
        setStoryboardArray(updatedArray);
        onChange(__assign(__assign({}, getData()), { storyboardArray: updatedArray }));
    };
    var onShootingSetUpdate = function (updatedArray) {
        setShootingSetArray(updatedArray);
        onChange(__assign(__assign({}, getData()), { shootingSetArray: updatedArray }));
    };
    var onVideoScenesUpdate = function (updatedArray) {
        setVideoSceneArray(updatedArray);
        onChange(__assign(__assign({}, getData()), { videoSceneArray: updatedArray }));
    };
    var onSynopsisFilesUpdate = function (updatedArray) {
        setSynopsisFiles(updatedArray);
        onChange(__assign(__assign({}, getData()), { synopsisFiles: updatedArray }));
    };
    var onTreatmentFilesUpdate = function (updatedArray) {
        setTreatmentFiles(updatedArray);
        onChange(__assign(__assign({}, getData()), { treatmentFiles: updatedArray }));
    };
    var onCharactersUpdate = function (updatedArray) {
        onChange(__assign(__assign({}, getData()), { characters: updatedArray }));
    };
    useEffect(function () {
        var _a;
        setTreatmentTitle(project.projectSubtype &&
            ((_a = project.projectSubtype) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "series"
            ? "Episodes Overview"
            : "Treatment");
    }, [project === null || project === void 0 ? void 0 : project.projectSubtype]);
    useEffect(function () {
        window.scrollTo(0, 0);
        if (typeof project.synopsis !== "undefined")
            setSynopsis(project.synopsis);
        if (typeof project.treatment !== "undefined")
            setTreatment(project.treatment);
        if (typeof project.synopsisFiles !== "undefined")
            setSynopsisFiles(project.synopsisFiles);
        if (typeof project.treatmentFiles !== "undefined")
            setTreatmentFiles(project.treatmentFiles);
        if (typeof project.coverInfoArray !== "undefined")
            setCoverInfoArray(project.coverInfoArray);
        if (typeof project.moodboardArray !== "undefined")
            setMoodboardArray(project.moodboardArray);
        if (typeof project.storyboardArray !== "undefined")
            setStoryboardArray(project.storyboardArray);
        if (typeof project.shootingSetArray !== "undefined")
            setShootingSetArray(project.shootingSetArray);
        if (typeof project.videoSceneArray !== "undefined")
            setVideoSceneArray(project.videoSceneArray);
        if (typeof project.characters !== "undefined" &&
            project.characters.length)
            setCharacters(project.characters);
    }, [project]);
    useEffect(function () {
        onChange(__assign({}, getData()));
    }, [synopsis, treatment, fullProjectFile]);
    return (_jsxs("div", __assign({ className: "editprojectcontentpage-container" }, { children: [_jsx(KPopup, __assign({ active: cropModeOn, onClose: function () { return setCropModeOn(false); } }, { children: _jsx("div", __assign({ className: "edituserbasicpage-cropper-container" }, { children: cropModeOn && (_jsx(EditCropImage, { image: selectedImage || {}, aspectRatio: 1, cropShape: "round", onSaveImage: function (img) {
                            return updateCharacterImage(img, selectedKey);
                        } })) })) })), _jsx("div", __assign({ className: "editprojectcontentpage-header" }, { children: "Let's add some content to your project" })), _jsxs("div", { children: [_jsxs("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: ["Full", " ", isFilmProject(project.projectType) ? "Film" : "Script", _jsx(IconLock, { className: "editprojectcontentpage-lock-icon" })] })), _jsx("p", __assign({ className: "editprojectcontentpage-text", style: { maxWidth: "500px" } }, { children: "No one will be able to access this data without your approval. Any user interested in viewing your project will have to sign an online NDA." })), _jsxs("div", __assign({ className: "editprojectcontentpage-fullproject-container" }, { children: [_jsx("input", { style: {
                                    opacity: 0,
                                    zIndex: -1,
                                    position: "absolute",
                                }, type: "file", ref: fullProjectRef, accept: isScriptProject(project.projectType)
                                    ? ".doc,.docx,application/pdf"
                                    : "video/mp4", onChange: function (event) {
                                    if (event.target.files) {
                                        var file = event.target.files[0];
                                        setFullProjectFile(file);
                                    }
                                } }), _jsx("div", __assign({ className: "editprojectcontentpage-fullproject-info" }, { children: fullProjectFile || project.fullProjectPath ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "editprojectcontentpage-fullproject-title" }, { children: (fullProjectFile === null || fullProjectFile === void 0 ? void 0 : fullProjectFile.name) ||
                                                (((_b = project.fullProjectPath) === null || _b === void 0 ? void 0 : _b.split("-").length) > 1
                                                    ? (_c = project.fullProjectPath) === null || _c === void 0 ? void 0 : _c.replace(/^.*[\\\/]/, "").split("-").slice(1).join("-")
                                                    : project.fullProjectPath.replace(/^.*[\\\/]/, "")) })), _jsx("div", __assign({ className: "editprojectcontentpage-fullproject-subtitle" }, { children: fullProjectFile
                                                ? getFileSizeInBytes(fullProjectFile === null || fullProjectFile === void 0 ? void 0 : fullProjectFile.size)
                                                : "" }))] })) : (_jsx("div", __assign({ className: "editprojectcontentpage-fullproject-noproject" }, { children: _jsx("div", __assign({ className: "editprojectcontentpage-fullproject-noproject-text" }, { children: "No project uploaded" })) }))) })), _jsxs("div", __assign({ className: "editprojectcontentpage-fullproject-upload", onClick: function () {
                                    if (fullProjectRef.current)
                                        fullProjectRef.current.click();
                                } }, { children: [_jsx(IconUpload, { className: "editprojectcontentpage-upload-icon" }), _jsxs("p", __assign({ className: "editprojectcontentpage-upload-title" }, { children: ["upload full", " ", isFilmProject(project.projectType)
                                                ? "film"
                                                : "script"] }))] }))] }))] }), _jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: "Cover Image" })), _jsx("p", __assign({ className: "editprojectcontentpage-text" }, { children: "Add one cover image that relates to your concept." })), _jsx(CoverImageUploader, { max: "1", adjustable: true, uploadTitle: "upload a cover image", uploadSubtitle: "jpg/png", infoArray: project.coverInfoArray, onChange: onCoverInfoUpdate }), _jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: "Synopsis" })), _jsxs("p", __assign({ className: "editprojectcontentpage-text", style: { marginBottom: "10px" } }, { children: ["All attachments will be accessible to any user through your project's page. ", _jsx("br", {}), "To control who can access your data, please add your files in the locked section above."] })), _jsx(KFlex, { component: _jsx(KTextArea, { label: "", value: synopsis || "", backgroundColor: "var(--app-primary-background-color)", onChange: function (event) { return setSynopsis(event.target.value); }, placeholder: "Start typing your synopsis.." }) }), _jsx(ProjectFilesUploader, { files: project.synopsisFiles, onChange: onSynopsisFilesUpdate }), _jsxs("div", __assign({ style: {
                    display: isScriptProject(project.projectType) ? "" : "none",
                } }, { children: [_jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: treatmentTitle })), _jsxs("p", __assign({ className: "editprojectcontentpage-text", style: { marginBottom: "10px" } }, { children: ["All attachments will be accessible to any user through your project's page. ", _jsx("br", {}), "To control who can access your data, please add your files in the locked section above."] })), _jsx(KFlex, { component: _jsx(KTextArea, { label: "", value: treatment || "", backgroundColor: "var(--app-primary-background-color)", onChange: function (event) {
                                return setTreatment(event.target.value);
                            }, placeholder: "Start typing your ".concat(treatmentTitle === null || treatmentTitle === void 0 ? void 0 : treatmentTitle.toLowerCase(), "..") }) }), _jsx(ProjectFilesUploader, { files: project.treatmentFiles, onChange: onTreatmentFilesUpdate })] })), _jsxs("div", __assign({ style: {
                    display: isScriptProject(project.projectType) ? "" : "", // characters for both scripts and films for now
                } }, { children: [_jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: "Characters" })), _jsx("p", __assign({ className: "editprojectcontentpage-text", style: { marginBottom: "-10px" } }, { children: "Add in some of your project's characters." })), _jsxs("div", __assign({ className: "editprojectcontentpage-characters-wrapper" }, { children: [characters.map(function (character, key) { return (_jsx("div", __assign({ className: "editprojectcontentpage-character" }, { children: _jsxs("div", __assign({ className: "charactercard-container" }, { children: [_jsxs("div", __assign({ className: "charactercard-info-section" }, { children: [_jsxs("div", __assign({ className: "charactercard-image-container" }, { children: [_jsx(KImage, { imgSrc: character.src
                                                                ? character.src
                                                                : getUserProfilePic(character.imagePath), smallImgSrc: character.src
                                                                ? character.src
                                                                : getUserProfilePic(character.smallImagePath), rounded: true }), _jsx("div", __assign({ className: "charactercard-image-edit-button", onClick: function () {
                                                                setCropModeOn(true);
                                                                setSelectedKey(character.key);
                                                                setSelectedImage(character.path ||
                                                                    character.src);
                                                            } }, { children: _jsx(IconEdit, {}) }))] })), _jsx("div", __assign({ className: "charactercard-name-container" }, { children: _jsx(KTextField, { placeholder: "Character name", backgroundColor: "var(--app-primary-background-color)", value: character.name, onChange: function (e) {
                                                            character["name"] =
                                                                e.target.value;
                                                            character["isEdited"] = true;
                                                            var updatedArray = __spreadArray([], characters, true);
                                                            onCharactersUpdate(updatedArray);
                                                        }, width: "100%" }) }))] })), _jsxs("div", __assign({ className: "charactercard-brief-section" }, { children: [_jsx(KTextArea, { placeholder: "Enter character brief..", backgroundColor: "var(--app-primary-background-color)", value: character.brief, onChange: function (e) {
                                                        character["brief"] = e.target.value;
                                                        character["isEdited"] = true;
                                                        var updatedArray = __spreadArray([], characters, true);
                                                        onCharactersUpdate(updatedArray);
                                                    }, height: "134px" }), characters.length > 1 && (_jsx("div", __assign({ className: "charactercard-image-delete-button", onClick: function () {
                                                        return setCharacters(__spreadArray([], characters.filter(function (c) {
                                                            return c.key !==
                                                                character.key;
                                                        }), true));
                                                    } }, { children: _jsx(IconDelete, {}) })))] }))] })) }), key)); }), _jsxs("div", __assign({ className: "editprojectcontentpage-icon-container", onClick: function () {
                                    if (characters.length >= maxCharacters)
                                        return alert("No more than ".concat(maxCharacters, " characters allowed"));
                                    setCharacters(__spreadArray(__spreadArray([], characters, true), [
                                        {
                                            name: "",
                                            brief: "",
                                            key: 1 +
                                                Math.max.apply(Math, characters.map(function (e) { return e.key; })),
                                        },
                                    ], false));
                                } }, { children: [_jsx(IconAdd, { className: "editprojectcontentpage-icon" }), _jsx("div", __assign({ className: "editprojectcontentpage-button-text" }, { children: "add another character" }))] }))] }))] })), _jsxs("div", __assign({ style: {
                    display: isScriptProject(project.projectType) ? "" : "none",
                } }, { children: [_jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: "Moodboard" })), _jsx("p", __assign({ className: "editprojectcontentpage-text" }, { children: "Upload some images for your project's moodboard." })), _jsx(CoverImageUploader, { max: 10, textPlaceholder: "Caption", uploadSubtitle: "jpg/png - 10 max", uploadTitle: "upload moodboard images", infoArray: project.moodboardArray, onChange: onMoodboardUpdate })] })), _jsxs("div", __assign({ style: {
                    display: isFilmProject(project.projectType) ? "" : "none",
                } }, { children: [_jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: "Storyboard" })), _jsx("p", __assign({ className: "editprojectcontentpage-text" }, { children: "Upload some images for your project's storyboard." })), _jsx(CoverImageUploader, { max: 10, textPlaceholder: "Caption", uploadSubtitle: "jpg/png - 10 max", uploadTitle: "upload storyboard images", infoArray: project.storyboardArray, onChange: onStoryboardUpdate })] })), _jsxs("div", __assign({ style: {
                    display: isFilmProject(project.projectType) ? "" : "none",
                } }, { children: [_jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: "Video scenes" })), _jsx("p", __assign({ className: "editprojectcontentpage-text" }, { children: "Add in some of your film's video scenes." })), _jsx(VideosUploader, { max: 5, adjustable: true, accept: "video/*", textPlaceholder: "Caption", uploadSubtitle: "video mp4 - 5 max", uploadTitle: "upload video scenes", infoArray: project.videoSceneArray, onChange: onVideoScenesUpdate })] })), _jsxs("div", __assign({ style: {
                    display: isFilmProject(project.projectType) ? "" : "none",
                } }, { children: [_jsx("div", __assign({ className: "editprojectcontentpage-header-2" }, { children: "Shooting set" })), _jsx("p", __assign({ className: "editprojectcontentpage-text" }, { children: "Add in some of your film's shooting sets." })), _jsx(CoverImageUploader, { max: 10, adjustable: true, aspectRatio: 16 / 9, textPlaceholder: "Caption", uploadSubtitle: "jpg/png - 10 max", uploadTitle: "upload shooting set images", infoArray: project.shootingSetArray, onChange: onShootingSetUpdate })] })), _jsxs("div", __assign({ className: "editprojectcontentpage-actions" }, { children: [hasNext ? (_jsx(KButton, { title: "Next", variant: "primary", onClick: onSubmit })) : (_jsx(_Fragment, {})), isEditMode ? (_jsx(KButton, { title: "Update", variant: "primary", onClick: onUpdate, outline: hasNext })) : hasNext ? (_jsx(_Fragment, {})) : (_jsx(KButton, { title: "Publish", variant: "primary", onClick: onPublish, outline: hasNext }))] }))] })));
};
export default EditProjectContentPage;
