var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import KButton from "../../base-components/KButton/KButton";
import { MdFileUpload as IconUpload } from "react-icons/md";
import { IoMdDownload as IconDownload } from "react-icons/io";
import { getProjectSymbolSrc } from "../../tools/projectTools";
import { getUserTitleColor } from "../../tools/userTools";
import { getDownloadLinkUrl } from "../../apis/fileApi";
import { getFileObjectFromURL } from "../../tools/fileTools";
import KTextField from "../../base-components/KTextField/KTextField";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import { getDayMonthYearDate } from "../../tools/generalTools";
import "./NdaCard.css";
var NdaCard = function (_a) {
    var otherUserName = _a.otherUserName, projectName = _a.projectName, projectType = _a.projectType, projectUserName = _a.projectUserName, ndaPath = _a.ndaPath, signedNdaPath = _a.signedNdaPath, userTitle = _a.userTitle, ndaStatus = _a.ndaStatus, isProjectOwner = _a.isProjectOwner, onClose = _a.onClose, onShareNda = _a.onShareNda, onSignNda = _a.onSignNda, onApproveNda = _a.onApproveNda, onUpdateNda = _a.onUpdateNda, onRejectNda = _a.onRejectNda, hasFullProject = _a.hasFullProject;
    var now = new Date();
    now.setDate(now.getDate() + 5);
    var defaultNdaFilePath = "/documents/nda.pdf";
    var _b = useState(""), ndaFile = _b[0], setNdaFile = _b[1];
    var _c = useState(""), newNdaFile = _c[0], setNewNdaFile = _c[1];
    var _d = useState(false), validateOn = _d[0], setValidateOn = _d[1];
    var _e = useState(false), uploadVisible = _e[0], setUploadVisible = _e[1];
    var _f = useState(false), giveProjectAccess = _f[0], setGiveProjectAccess = _f[1];
    var _g = useState(""), dateErrorMessage = _g[0], setDateErrorMessage = _g[1];
    var _h = useState(getDayMonthYearDate(now)), expiryDate = _h[0], setExpiryDate = _h[1];
    var uploadFile = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var file;
        return __generator(this, function (_a) {
            file = event.target.files[0];
            setNdaFile(file);
            return [2 /*return*/];
        });
    }); };
    var uploadNewFile = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var file;
        return __generator(this, function (_a) {
            file = event.target.files[0];
            setNewNdaFile(file);
            return [2 /*return*/];
        });
    }); };
    var onApproveNdaClicked = function () {
        if (giveProjectAccess && dateErrorMessage) {
            if (validateOn)
                alert("Required data missing");
            else
                setValidateOn(true);
            return;
        }
        onApproveNda(giveProjectAccess, expiryDate);
    };
    useEffect(function () {
        var minDate = new Date();
        minDate.setDate(minDate.getDate() + 1);
        minDate.setHours(0);
        minDate.setMinutes(0);
        minDate.setSeconds(0);
        minDate.setMilliseconds(0);
        var maxDate = new Date();
        maxDate.setDate(maxDate.getDate() + 7);
        maxDate.setHours(0);
        maxDate.setMinutes(0);
        maxDate.setSeconds(0);
        maxDate.setMilliseconds(0);
        if (new Date(expiryDate) < minDate) {
            setDateErrorMessage("Expiry date should be at least one day in the future");
        }
        else if (new Date(expiryDate) > maxDate) {
            setDateErrorMessage("Max allowed expiry date is 7 days from now");
        }
        else {
            setDateErrorMessage("");
        }
    }, [expiryDate, validateOn]);
    var projectBlock = (_jsxs("div", __assign({ className: "ndacard-project-section" }, { children: [_jsx("img", { className: "ndacard-project-type-icon", src: getProjectSymbolSrc(projectType) }), _jsxs("div", __assign({ className: "ndacard-project-text-section" }, { children: [_jsx("div", __assign({ className: "ndacard-project-text-title" }, { children: projectName })), _jsxs("div", __assign({ className: "ndacard-project-text-subtitle" }, { children: ["a ", (projectType || "").toLowerCase(), " by", _jsx("a", __assign({ className: "ndacard-user-link", style: { color: getUserTitleColor(userTitle || "") } }, { children: " " + projectUserName }))] }))] }))] })));
    switch (ndaStatus) {
        case "signature-pending":
        case "signature-rejected":
            return isProjectOwner === true ? (_jsxs("div", __assign({ className: "ndacard-container" }, { children: [_jsxs("div", __assign({ className: "ndacard-info-section" }, { children: [_jsx("div", __assign({ className: "ndacard-title" }, { children: "NDA signature pending" })), projectBlock, _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Your part is done!" })), _jsxs("p", __assign({ className: "ndacard-info-text" }, { children: ["We've informed ", otherUserName, " of your", " ", ndaStatus == "signature-rejected"
                                        ? " rejection and are waiting for their signature."
                                        : " request and are waiting for the signed NDA."] })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "You can always update the version of your NDA if needed." })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Feel free to contact us for any other inquiries." })), _jsxs("div", __assign({ className: "ndacard-sharing-section" }, { children: [_jsxs("div", __assign({ className: "ndacard-sharing-subsection" }, { children: [_jsxs("div", __assign({ className: "ndacard-action-box" }, { children: [_jsx("img", { className: "ndacard-action-image ndacard-image-nda" }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "NDA" }))] })), _jsxs("div", __assign({ className: "ndacard-action-box ndacard-action-box-clickable" }, { children: [_jsx(IconDownload, { className: "ndacard-action-icon" }), _jsx("a", { className: "ndacard-download", href: ndaPath
                                                            ? getDownloadLinkUrl(ndaPath)
                                                            : "", target: "_blank", download: true }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "DOWNLOAD" }))] }))] })), _jsxs("div", __assign({ className: "ndacard-sharing-subsection" }, { children: [_jsxs("div", __assign({ className: "ndacard-action-box" }, { children: [_jsx("img", { className: "ndacard-action-image ndacard-image-nda" }), _jsx("img", { className: "ndacard-action-check", src: "/images/green-check.png", style: {
                                                            display: newNdaFile
                                                                ? "block"
                                                                : "none",
                                                        } }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "UPDATED NDA" }))] })), _jsxs("div", __assign({ className: "ndacard-action-box ndacard-action-box-clickable" }, { children: [_jsx("input", { type: "file", className: "ndacard-upload-input", accept: ".doc,.docx,application/pdf", onChange: function (event) {
                                                            return uploadNewFile(event);
                                                        } }), _jsx(IconUpload, { className: "ndacard-action-icon" }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "UPLOAD" }))] }))] }))] }))] })), _jsxs("div", __assign({ className: "ndacard-action-section" }, { children: [_jsx(KButton, { title: "Update", variant: "primary", onClick: function () { return onUpdateNda(newNdaFile); } }), _jsx(KButton, { title: "Close", variant: "primary", onClick: onClose, outline: true })] }))] }))) : (_jsxs("div", __assign({ className: "ndacard-container" }, { children: [_jsxs("div", __assign({ className: "ndacard-info-section" }, { children: [_jsx("div", __assign({ className: "ndacard-title" }, { children: ndaStatus == "signature-rejected"
                                    ? "NDA signature rejected"
                                    : "NDA signature request" })), projectBlock, ndaStatus == "signature-rejected" ? (_jsxs(_Fragment, { children: [_jsxs("p", __assign({ className: "ndacard-info-text" }, { children: ["Looks like ", otherUserName, " has rejected the current version of the signed NDA!"] })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Please make sure to upload an updated version of the signed NDA." }))] })) : (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "ndacard-info-text" }, { children: "A non disclosure agreement has been shared and requires your signature." })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Please download the NDA and make sure to sign it before uploading it back. Once you do so, confirm your action to send back the signed version of the NDA." }))] })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Feel free to contact us for any other inquiries." })), _jsxs("div", __assign({ className: "ndacard-sharing-section" }, { children: [_jsxs("div", __assign({ className: "ndacard-sharing-subsection" }, { children: [_jsxs("div", __assign({ className: "ndacard-action-box" }, { children: [_jsx("img", { className: "ndacard-action-image ndacard-image-nda" }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "NDA" }))] })), _jsxs("div", __assign({ className: "ndacard-action-box ndacard-action-box-clickable" }, { children: [_jsx(IconDownload, { className: "ndacard-action-icon" }), _jsx("a", { className: "ndacard-download", href: ndaPath
                                                            ? getDownloadLinkUrl(ndaPath)
                                                            : "", target: "_blank", download: true }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "DOWNLOAD" }))] }))] })), _jsxs("div", __assign({ className: "ndacard-sharing-subsection" }, { children: [_jsxs("div", __assign({ className: "ndacard-action-box" }, { children: [_jsx("img", { className: "ndacard-action-image ndacard-image-nda" }), _jsx("img", { className: "ndacard-action-check", src: "/images/green-check.png", style: {
                                                            display: ndaFile ? "block" : "none",
                                                        } }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "SIGNED NDA" }))] })), _jsxs("div", __assign({ className: "ndacard-action-box ndacard-action-box-clickable" }, { children: [_jsx("input", { type: "file", className: "ndacard-upload-input", accept: ".doc,.docx,application/pdf", onChange: function (event) { return uploadFile(event); } }), _jsx(IconUpload, { className: "ndacard-action-icon" }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "UPLOAD" }))] }))] }))] }))] })), _jsx("div", __assign({ className: "ndacard-action-section" }, { children: _jsx(KButton, { title: "Sign", variant: "primary", onClick: function () { return onSignNda(ndaFile); } }) }))] })));
        case "signed":
            return isProjectOwner === true ? (_jsxs("div", __assign({ className: "ndacard-container" }, { children: [_jsxs("div", __assign({ className: "ndacard-info-section" }, { children: [_jsx("div", __assign({ className: "ndacard-title" }, { children: "NDA signed" })), projectBlock, _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "A non disclosure agreement has been shared and signed!" })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Please verify the signed version that can be downloaded by clicking on the download button below. You can then either approve the signed version or reject it by requesting the other party to upload a different version." })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Feel free to contact us for any other inquiries." })), _jsxs("div", __assign({ className: "ndacard-sharing-section" }, { children: [_jsxs("div", __assign({ className: "ndacard-action-box" }, { children: [_jsx("img", { className: "ndacard-action-image ndacard-image-nda" }), _jsx("img", { className: "ndacard-action-check", src: "/images/green-check.png" }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "NDA" }))] })), _jsxs("div", __assign({ className: "ndacard-action-box ndacard-action-box-clickable" }, { children: [_jsx(IconDownload, { className: "ndacard-action-icon" }), _jsx("a", { className: "ndacard-download", href: signedNdaPath
                                                    ? getDownloadLinkUrl(signedNdaPath)
                                                    : "", target: "_blank", download: true }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "DOWNLOAD" }))] }))] })), hasFullProject ? (_jsxs("div", __assign({ className: "ndacard-give-access" }, { children: [_jsx(KCheckbox, { title: "Give access to your full project", color: "var(--app-primary-color)", fontSize: "var(--app-font-size-small-1)", checked: giveProjectAccess, onChange: function () {
                                            return setGiveProjectAccess(!giveProjectAccess);
                                        }, marginRight: "12px" }), giveProjectAccess ? (_jsx(KTextField, { errorMessage: validateOn ? dateErrorMessage : "", value: expiryDate, onChange: function (e) {
                                            return setExpiryDate(e.target.value);
                                        }, label: "Access expiry date", type: "date" })) : (_jsx(_Fragment, {}))] }))) : (_jsx(_Fragment, {}))] })), _jsxs("div", __assign({ className: "ndacard-action-section" }, { children: [_jsx(KButton, { title: "Approve", variant: "primary", onClick: onApproveNdaClicked }), _jsx(KButton, { title: "Reject", variant: "primary", onClick: onRejectNda, outline: true })] }))] }))) : (_jsx("div", __assign({ className: "ndacard-container" }, { children: _jsxs("div", __assign({ className: "ndacard-info-section" }, { children: [_jsx("div", __assign({ className: "ndacard-title" }, { children: "NDA approval pending" })), projectBlock, _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Your part is done!" })), _jsxs("p", __assign({ className: "ndacard-info-text" }, { children: ["We've informed ", otherUserName, " and are waiting for their approval."] })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Feel free to contact us for any other inquiries." })), _jsx("div", __assign({ className: "ndacard-action-section" }, { children: _jsx(KButton, { title: "Close", variant: "primary", onClick: onClose }) }))] })) })));
        case "approved":
            return (_jsxs("div", __assign({ className: "ndacard-container" }, { children: [_jsxs("div", __assign({ className: "ndacard-info-section" }, { children: [_jsx("div", __assign({ className: "ndacard-title" }, { children: "NDA signed & approved" })), projectBlock, _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "A non disclosure agreement has been signed and approved!" })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "A copy of the signed version can be downloaded at any time by clicking on the download button below." })), _jsxs("div", __assign({ className: "ndacard-sharing-section" }, { children: [_jsxs("div", __assign({ className: "ndacard-action-box" }, { children: [_jsx("img", { className: "ndacard-action-image ndacard-image-nda" }), _jsx("img", { className: "ndacard-action-check", src: "/images/green-check.png" }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "NDA" }))] })), _jsxs("div", __assign({ className: "ndacard-action-box ndacard-action-box-clickable" }, { children: [_jsx(IconDownload, { className: "ndacard-action-icon" }), _jsx("a", { className: "ndacard-download", href: signedNdaPath
                                                    ? getDownloadLinkUrl(signedNdaPath)
                                                    : "", target: "_blank", download: true }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "DOWNLOAD" }))] }))] }))] })), _jsx("div", __assign({ className: "ndacard-action-section" }, { children: _jsx(KButton, { title: "Close", variant: "primary", onClick: onClose }) }))] })));
        default:
            return isProjectOwner === true ? (_jsxs("div", __assign({ className: "ndacard-container" }, { children: [_jsxs("div", __assign({ className: "ndacard-info-section" }, { children: [_jsx("div", __assign({ className: "ndacard-title" }, { children: "Share NDA" })), projectBlock, _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Protect your project by sharing a non disclosure agreement." })), _jsxs("p", __assign({ className: "ndacard-info-text" }, { children: ["You could use our default", " ", _jsx("a", __assign({ className: "ndacard-ref", href: defaultNdaFilePath }, { children: "NDA" })), " ", "template or upload a customized NDA that fits your needs. Once confirmed, we'll be sending it to", " ", otherUserName, " and asking for their signature."] })), _jsx(KCheckbox, { title: "Upload a custom NDA", color: "var(--app-primary-color)", fontSize: "var(--app-font-size-small-1)", checked: uploadVisible, onChange: function () { return setUploadVisible(!uploadVisible); }, marginRight: "12px" }), _jsxs("div", __assign({ className: "ndacard-sharing-section", style: { display: uploadVisible ? "flex" : "none" } }, { children: [_jsxs("div", __assign({ className: "ndacard-action-box" }, { children: [_jsx("img", { className: "ndacard-action-image ndacard-image-nda" }), _jsx("img", { className: "ndacard-action-check", src: "/images/green-check.png", style: {
                                                    display: ndaFile ? "block" : "none",
                                                } }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "NDA" }))] })), _jsxs("div", __assign({ className: "ndacard-action-box ndacard-action-box-clickable" }, { children: [_jsx("input", { type: "file", className: "ndacard-upload-input", accept: ".doc,.docx,application/pdf", onChange: function (event) { return uploadFile(event); } }), _jsx(IconUpload, { className: "ndacard-action-icon" }), _jsx("p", __assign({ className: "ndacard-action-title" }, { children: "UPLOAD" }))] }))] }))] })), _jsx("div", __assign({ className: "ndacard-action-section" }, { children: _jsx(KButton, { title: "Share", variant: "primary", onClick: function () {
                                uploadVisible
                                    ? onShareNda(ndaFile)
                                    : getFileObjectFromURL(defaultNdaFilePath, function (file) {
                                        onShareNda(file);
                                    });
                            } }) }))] }))) : (_jsxs("div", __assign({ className: "ndacard-container" }, { children: [_jsxs("div", __assign({ className: "ndacard-info-section" }, { children: [_jsx("div", __assign({ className: "ndacard-title" }, { children: "Share NDA" })), projectBlock, _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Looks like you're trying to share an NDA." })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "This procedure should be initiated from the project owner's side. Please feel free to message the owner and ask for them to share the NDA with you." })), _jsx("p", __assign({ className: "ndacard-info-text" }, { children: "Feel free to contact us for any other inquiries." }))] })), _jsx("div", __assign({ className: "ndacard-action-section" }, { children: _jsx(KButton, { title: "Close", variant: "primary", onClick: onClose }) }))] })));
    }
};
export default NdaCard;
