import { history } from "./store";
export var getErrorMessage = function (err) {
    try {
        console.error(err);
        if (err.response && err.response.data.message) {
            if (err.response.data.error) {
                console.error(err.response.data.error);
                return (err.response.data.error.message || err.response.data.message);
            }
            return err.response.data.message;
        }
        if (err.message)
            return err.message;
    }
    catch (err) {
        return "Error processing request";
    }
};
export var displayAlert = function (message) { return function () {
    console.error(message);
    alert(message);
}; };
export var handleError = function (err) {
    try {
        console.error(err, err.code, err.message);
        if (err.message === "Network Error") {
            history.push("/platform/error");
            return err.message;
        }
        if (err.response && err.response.data.message) {
            if (err.response.data.error) {
                console.error(err.response.data.error);
                return (err.response.data.error.message || err.response.data.message);
            }
            if (err.response.data.code === 13)
                history.push("/platform/unauthorized");
            else
                history.push("/platform/error");
            return err.response.data.message;
        }
        if (err.message)
            return err.message;
    }
    catch (err) {
        return "Error processing request";
    }
};
