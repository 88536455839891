var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { getLocation, getReadableDate } from "../../tools/generalTools";
import { getUserProfilePic } from "../../tools/userTools";
import KImage from "../../base-components/KImage/KImage";
import "./WallUserCard.css";
var WallUserCard = function (_a) {
    var user = _a.user;
    var _b = useState({}), styleContainer = _b[0], setStyleContainer = _b[1];
    useEffect(function () {
        setStyleContainer({
            "--wallusercard-primary-color": "var(--app-".concat(user.user_title_name.toLowerCase() || "unknown", "-color"),
        });
    }, []);
    return (_jsxs("div", __assign({ className: "wallusercard-container", style: styleContainer }, { children: [_jsx("div", __assign({ className: "wallusercard-image-section" }, { children: _jsxs("div", __assign({ className: "wallusercard-image-wrapper" }, { children: [_jsx("div", __assign({ className: "wallusercard-image-container" }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(user.user_small_image_path), imgSrc: getUserProfilePic(user.user_image_path), alt: "profile-pic" }) })), _jsx("div", __assign({ className: "wallusercard-image-caption" }, { children: user.user_title_name || "unknown" }))] })) })), _jsx("div", __assign({ className: "wallusercard-info-section" }, { children: _jsxs("div", __assign({ className: "wallusercard-info-wrapper" }, { children: [_jsx("div", __assign({ className: "wallusercard-username-section" }, { children: _jsxs("div", __assign({ className: "wallusercard-username" }, { children: [user.user_first_name, " ", user.user_last_name] })) })), _jsx("div", __assign({ className: "wallusercard-maininfo-section" }, { children: _jsx("div", __assign({ className: "wallusercard-userinfo" }, { children: getLocation(user.country_name, user.city_name) })) })), _jsx("div", __assign({ className: "wallusercard-extrainfo-section" }, { children: _jsx("div", __assign({ className: "wallusercard-username" }, { children: getReadableDate(user.created_at) })) }))] })) }))] })));
};
export default WallUserCard;
