var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef, createRef } from "react";
import { MdTextsms as IconCollaborate } from "react-icons/md";
import { BsFillCaretDownFill as IconMore } from "react-icons/bs";
import { MdEdit as IconEdit } from "react-icons/md";
import { IoMdEye as IconView } from "react-icons/io";
import KImage from "../../base-components/KImage/KImage";
import KButton from "../../base-components/KButton/KButton";
import KCheckbox from "../../base-components/KCheckbox/KCheckbox";
import { Twitter, LinkedIn, YouTube } from "@material-ui/icons";
import { CgPlayStopO as IconStop } from "react-icons/cg";
import KStars from "../../base-components/KStars/KStars";
import { ImPlay2 as IconPlay } from "react-icons/im";
import Icon from "@material-ui/core/Icon";
import { formFullUrl, getImageUrl, getLocation, numberToString, } from "../../tools/generalTools";
import { getUserProfilePic, isUserBuyer, isUserFilmmaker, isUserInvestor, isUserLawyer, } from "../../tools/userTools";
import { connect } from "react-redux";
import EditCropImage from "../../pages/EditCropImage/EditCropImage";
import RateUser from "../RateUser/RateUser";
import KPopup from "../../base-components/KPopup/KPopup";
import { getLoadedProfileViews, getLoadedUser, getLoadedUserRatingAverage, getLoadedUserRatingByUserId, } from "../../redux/selectors/userSelectors";
import { thunkAddRatingByUser, thunkCreateUserCoverImages, thunkUpdateUserCoverImage, thunkUploadUserVideo, } from "../../redux/thunks/userThunks";
import KCarousel from "../../base-components/KCarousel/KCarousel";
import { getProjectTypes, getUserTitles, } from "../../redux/selectors/generalSelectors";
import CoverImageUploader from "../CoverImageUploader/CoverImageUploader";
import { getStorageVideoUrl } from "../../apis/fileApi";
import "./UserSummaryCard.css";
var UserSummaryCard = function (_a) {
    var _b, _c;
    var isEditable = _a.isEditable, onEdit = _a.onEdit, isReported = _a.isReported, user = _a.user, allProjectTypes = _a.allProjectTypes, allUserTitles = _a.allUserTitles, _d = _a.onCollaborate, onCollaborate = _d === void 0 ? function (f) { return f; } : _d, _e = _a.onReport, onReport = _e === void 0 ? function (f) { return f; } : _e, _f = _a.projectCount, projectCount = _f === void 0 ? 0 : _f, onUploadVideo = _a.onUploadVideo, updateUserCover = _a.updateUserCover, loadedUser = _a.loadedUser, loadedProfileViews = _a.loadedProfileViews, addRatingByUser = _a.addRatingByUser, onCreateCoverImages = _a.onCreateCoverImages, loadedUserRatingByUserId = _a.loadedUserRatingByUserId, loadedUserRatingAverage = _a.loadedUserRatingAverage;
    var videoUploadRef = useRef(null);
    var _g = useState(false), isReady = _g[0], setIsReady = _g[1];
    var _h = useState(), pitchVideo = _h[0], setPitchVideoFile = _h[1];
    var _j = useState(false), videoModeOn = _j[0], setVideoModeOn = _j[1];
    var _k = useState(""), userLocation = _k[0], setUserLocation = _k[1];
    var _l = useState(""), userLanguages = _l[0], setUserLanguages = _l[1];
    var _m = useState([]), coverImagesArray = _m[0], setCoverImagesArray = _m[1];
    var _o = useState(false), cropCoverImage = _o[0], setCropCoverImage = _o[1];
    var _p = useState(false), editBuyerCovers = _p[0], setEditBuyerCovers = _p[1];
    var _q = useState(false), ratingsStars = _q[0], setRatingStars = _q[1];
    var _r = useState(false), buyerClass = _r[0], setBuyerClass = _r[1];
    var _s = useState(false), isBuyer = _s[0], setIsBuyer = _s[1];
    var onUpdateCoverImages = function () {
        onCreateCoverImages(coverImagesArray, function (err) {
            if (err)
                return alert(err);
            setEditBuyerCovers(false);
        });
    };
    var uploadClick = function () {
        setCropCoverImage(true);
    };
    var uploadVideoClick = function () {
        var _a;
        (_a = videoUploadRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var onEditBuyerCovers = function () {
        setEditBuyerCovers(true);
    };
    var saveCoverImage = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateUserCover(file)
                        .then(function () {
                        setCropCoverImage(false);
                    })
                        .catch(function (e) {
                        console.log(e);
                    })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        setIsReady(user.user_id ? true : false);
        if (user.user_languages) {
            var languages = "";
            var languagesArray = user.user_languages.slice(0, 2);
            for (var i = 0; i < languagesArray.length; i++) {
                languages += languagesArray[i].language_code + " | ";
            }
            setUserLanguages(languages.slice(0, languages.length - 2));
        }
        setUserLocation(getLocation(user.country_name, user.city_name));
    }, [user]);
    useEffect(function () {
        setIsBuyer(isUserBuyer(user.user_title_name));
        setBuyerClass(isUserBuyer(user.user_title_name) ? "usersummarycard-buyer" : "");
        var coverImages = [];
        if (user.user_coverimage_paths) {
            var coverImageKey = 0;
            var ids = user.user_coverimage_ids.split(",");
            var titles = user.user_coverimage_titles.split(",");
            var imagePaths = user.user_coverimage_paths.split(",");
            var smallImagePaths = user.user_coverimage_paths.split(",");
            for (var i = 0; i < imagePaths.length; i++) {
                coverImageKey += 1;
                coverImages.push({
                    key: coverImageKey,
                    fileInput: createRef(),
                    src: getImageUrl(imagePaths[i]),
                    path: imagePaths[i],
                    smallPath: smallImagePaths[i],
                    title: titles[i],
                    id: ids[i],
                    isEdited: false,
                });
            }
        }
        setCoverImagesArray(coverImages);
    }, [user]);
    return (_jsx(_Fragment, { children: isReady ? (_jsxs("div", __assign({ className: "usersummarycard-container" }, { children: [_jsx("input", { style: {
                        opacity: 0,
                        zIndex: -1,
                        position: "absolute",
                    }, type: "file", ref: videoUploadRef, accept: "video/mp4", onChange: function (event) {
                        if (event.target.files) {
                            var file = event.target.files[0];
                            setPitchVideoFile(file);
                            onUploadVideo(file);
                        }
                    } }), _jsx(KPopup, __assign({ active: editBuyerCovers, onClose: function () { return setEditBuyerCovers(false); } }, { children: _jsxs("div", __assign({ className: "usersummarycard-edit-buyer-covers" }, { children: [_jsx("div", __assign({ className: "usersummarycard-edit-buyer-title" }, { children: "Edit your catalog" })), _jsx("div", __assign({ className: "usersummarycard-edit-buyer-wrapper" }, { children: _jsx(CoverImageUploader, { max: "6", adjustable: true, aspectRatio: 4 / 3, textPlaceholder: "Description", uploadTitle: "upload catalog images", uploadSubtitle: "jpg/png - 6 max", infoArray: coverImagesArray, onChange: function (newArray) {
                                        return setCoverImagesArray(newArray);
                                    } }) })), _jsx("div", __assign({ className: "usersummarycard-edit-buyer-action" }, { children: _jsx(KButton, { title: "Save", variant: "primary", onClick: onUpdateCoverImages }) }))] })) })), _jsx(KPopup, __assign({ active: cropCoverImage, onClose: function () { return setCropCoverImage(false); } }, { children: cropCoverImage && (_jsx(EditCropImage, { image: loadedUser.user_cover_path || {}, aspectRatio: 4 / 3, cropShape: "rect", onSaveImage: function (img) { return saveCoverImage(img); } })) })), _jsx(KPopup, __assign({ active: ratingsStars, onClose: function () { return setRatingStars(false); } }, { children: _jsx("div", { children: _jsx(RateUser, { loadedUserRatingByUserId: loadedUserRatingByUserId, user: user, addRatingByUserRate: function (user, rating) {
                                addRatingByUser(user, rating);
                            }, setRatingStars: setRatingStars }) }) })), _jsxs("div", __assign({ className: "usersummarycard-main-info-container ".concat(buyerClass) }, { children: [!isBuyer ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "usersummarycard-profile-pic" }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(user.user_small_image_path), imgSrc: getUserProfilePic(user.user_image_path), alt: "profile-pic" }) })), _jsx("div", __assign({ className: "usersummarycard-edit-icon-container", onClick: onEdit, style: {
                                        display: "".concat(isEditable ? "block" : "none"),
                                    } }, { children: _jsx(IconEdit, { className: "usersummarycard-edit-icon" }) })), _jsx("div", __assign({ className: "usersummarycard-verified-icon", style: {
                                        display: "".concat(user.user_verified == 1
                                            ? "block"
                                            : "none"),
                                    } }, { children: _jsx("img", { width: "26px", height: "26px", src: "/images/green-shield-check.png" }) }))] })) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "usersummarycard-name" }, { children: [user.user_first_name, " ", user.user_last_name] })), _jsxs("div", __assign({ className: "usersummarycard-views-wrapper" }, { children: [loadedProfileViews !== 0 ? (_jsxs("div", __assign({ className: "usersummarycard-views-section" }, { children: [_jsx("div", __assign({ className: "usersummarycard-views-text" }, { children: numberToString(loadedProfileViews) ||
                                                0 })), _jsx(IconView, { className: "usersummarycard-views-icon" })] }))) : (_jsx(_Fragment, {})), isUserLawyer(user.user_title_name) ? (_jsx(_Fragment, {})) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "usersummarycard-action-buttons", style: {
                                                display: isEditable
                                                    ? "none"
                                                    : "flex",
                                            } }, { children: [_jsx(KButton, { title: "Collaborate", icon: IconCollaborate, variant: "loadeduser", onClick: onCollaborate }), _jsx(KButton, { title: "More", icon: IconMore, variant: "loadeduser", outline: true, iconRight: true, listItems: [
                                                        {
                                                            title: "Report" +
                                                                "".concat(isReported
                                                                    ? "ed"
                                                                    : ""),
                                                            onClick: onReport,
                                                            disabled: isReported,
                                                        },
                                                    ] })] })), _jsx("div", __assign({ className: "usersummarycard-action-button" }, { children: isEditable ? (_jsx(_Fragment, {})) : (_jsx(KButton, { title: "", icon: IconMore, variant: "loadeduser", iconRight: true, listItems: [
                                                    {
                                                        title: "Collaborate",
                                                        onClick: onCollaborate,
                                                    },
                                                    // {
                                                    //     title: "Track",
                                                    //     onClick: () =>
                                                    //         alert("Feature coming soon"),
                                                    // },
                                                    {
                                                        title: "Report" +
                                                            "".concat(isReported
                                                                ? "ed"
                                                                : ""),
                                                        onClick: onReport,
                                                        disabled: isReported,
                                                    },
                                                ] })) }))] }))] }))] })), isBuyer ? (_jsxs("div", __assign({ className: "usersummarycard-profile-buyer-container" }, { children: [_jsx("div", __assign({ className: "usersummarycard-profile-pic-buyer" }, { children: _jsx(KImage, { smallImgSrc: getUserProfilePic(user.user_small_image_path), imgSrc: getUserProfilePic(user.user_image_path), alt: "profile-pic" }) })), _jsx("div", __assign({ className: "usersummarycard-edit-icon-buyer", onClick: onEdit, style: {
                                display: "".concat(isEditable ? "block" : "none"),
                            } }, { children: _jsx(IconEdit, { className: "usersummarycard-edit-icon" }) })), _jsx("div", __assign({ className: "usersummarycard-verified-icon-buyer", style: {
                                display: "".concat(user.user_verified == 1
                                    ? "block"
                                    : "none"),
                            } }, { children: _jsx("img", { width: "26px", height: "26px", src: "/images/green-shield-check.png" }) })), _jsxs("div", __assign({ className: "usersummarycard-buyer-name" }, { children: [user.user_first_name, " ", user.user_last_name] }))] }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "usersummarycard-card-container" }, { children: [_jsxs("div", __assign({ className: "usersummarycard-header-section" }, { children: [_jsx("img", { className: "usersummarycard-icon", alt: "user-title", src: "/images/".concat((user.user_title_name || "unknown").toLowerCase(), "-symbol.png") }), _jsx("div", __assign({ className: "usersummarycard-header-info" }, { children: user.user_title_name })), _jsx("div", __assign({ className: "usersummarycard-header-info usersummarycard-details" }, { children: userLocation })), _jsx("div", __assign({ className: "usersummarycard-header-info usersummarycard-details" }, { children: userLanguages })), _jsxs("div", __assign({ className: "usersummarycard-social-box" }, { children: [_jsx("a", __assign({ className: "usersummarycard-social-icon", style: {
                                                display: user.user_detail_twitter_url
                                                    ? ""
                                                    : "none",
                                            }, href: formFullUrl(user.user_detail_twitter_url), target: "_blank" }, { children: _jsx(Icon, { component: Twitter, color: "inherit", fontSize: "small" }) })), _jsx("a", __assign({ className: "usersummarycard-social-icon", style: {
                                                display: user.user_detail_youtube_url
                                                    ? ""
                                                    : "none",
                                            }, href: formFullUrl(user.user_detail_youtube_url), target: "_blank" }, { children: _jsx(Icon, { component: YouTube, color: "inherit", fontSize: "small" }) })), _jsx("a", __assign({ className: "usersummarycard-social-icon", style: {
                                                display: user.user_detail_linkedin_url
                                                    ? ""
                                                    : "none",
                                            }, href: formFullUrl(user.user_detail_linkedin_url), target: "_blank" }, { children: _jsx(Icon, { component: LinkedIn, color: "inherit", fontSize: "small" }) }))] }))] })), isBuyer && (_jsxs("div", __assign({ className: "usersummarycard-catalog-section" }, { children: [_jsx("div", __assign({ className: "usersummarycard-catalog-noimages" }, { children: "no catalog images" })), _jsx("div", __assign({ className: "usersummarycard-buyer-uploadcover", style: {
                                        display: isEditable ? "block" : "none",
                                    } }, { children: _jsx(KButton, { title: "Edit", onClick: function () { return onEditBuyerCovers(); } }) })), _jsx("div", __assign({ className: "usersummarycard-catalog-image usersummarycard-catalog-image-1" }, { children: _jsx(KCarousel, { children: (coverImagesArray || []).map(function (coverImage, key) { return (_jsx("div", __assign({ className: "usersummarycard-catalog-image usersummarycard-catalog-image-1" }, { children: _jsx(KImage, { smallImgSrc: getImageUrl(coverImage.smallPath), imgSrc: getImageUrl(coverImage.path), alt: "cover-image" }) }), key)); }) }) })), _jsx("div", __assign({ className: "usersummarycard-catalog-image usersummarycard-catalog-image-2" }, { children: _jsx(KCarousel, { children: (coverImagesArray || [])
                                            .rotate(1)
                                            .map(function (coverImage, key) { return (_jsx("div", __assign({ className: "usersummarycard-catalog-image usersummarycard-catalog-image-2" }, { children: _jsx(KImage, { smallImgSrc: getImageUrl(coverImage.smallPath), imgSrc: getImageUrl(coverImage.path), alt: "cover-image" }) }), key)); }) }) })), _jsx("div", __assign({ className: "usersummarycard-catalog-image usersummarycard-catalog-image-3" }, { children: _jsx(KCarousel, { children: (coverImagesArray || [])
                                            .rotate(1)
                                            .map(function (coverImage, key) { return (_jsx("div", __assign({ className: "usersummarycard-catalog-image usersummarycard-catalog-image-3" }, { children: _jsx(KImage, { smallImgSrc: getImageUrl(coverImage.smallPath), imgSrc: getImageUrl(coverImage.path), alt: "cover-image" }) }), key)); }) }) }))] }))), _jsxs("div", __assign({ className: "usersummarycard-body-section ".concat(buyerClass) }, { children: [!isBuyer ? (_jsxs("div", __assign({ className: "usersummarycard-image-section" }, { children: [_jsx("div", __assign({ className: "usersummarycard-no-cover-title" }, { children: "no cover image" })), user.user_video_path ? (_jsx("div", __assign({ className: "usersummarycard-icon-play", onClick: function () { return setVideoModeOn(true); } }, { children: _jsx(IconPlay, {}) }))) : (_jsx(_Fragment, {})), videoModeOn && user.user_video_path ? (_jsxs("div", __assign({ className: "usersummarycard-video-container" }, { children: [_jsx("div", __assign({ className: "usersummarycard-icon-stop", onClick: function () {
                                                        return setVideoModeOn(false);
                                                    } }, { children: _jsx(IconStop, {}) })), _jsxs("video", __assign({ controls: true, autoPlay: true, playsInline: true, width: "100%", height: "100%" }, { children: [_jsx("source", { src: getStorageVideoUrl(user.user_video_path) + "#t=0.1", type: "video/mp4" }), "Your browser does not support the video tag."] }))] }))) : (_jsx(_Fragment, {})), _jsx("div", __assign({ className: "usersummarycard-uploadcover", style: {
                                                display: isEditable
                                                    ? "block"
                                                    : "none",
                                            } }, { children: isUserFilmmaker(user.user_title_name) ? (_jsx(KButton, { title: "Edit", listItems: [
                                                    {
                                                        title: "+ Cover image",
                                                        onClick: function () {
                                                            return uploadClick();
                                                        },
                                                    },
                                                    {
                                                        title: "+ Pitch video",
                                                        onClick: function () {
                                                            return uploadVideoClick();
                                                        },
                                                    },
                                                ] })) : (_jsx(KButton, { title: "Edit", onClick: function () { return uploadClick(); } })) })), _jsx(KImage, { smallImgSrc: getImageUrl(user.user_small_cover_path), imgSrc: getImageUrl(user.user_cover_path), alt: "cover-image" })] }))) : (_jsx(_Fragment, {})), _jsxs("div", __assign({ className: "usersummarycard-content-section ".concat(buyerClass) }, { children: [_jsxs("div", __assign({ className: "usersummarycard-bio-section ".concat(buyerClass) }, { children: [_jsx("div", __assign({ className: "usersummarycard-bio ".concat(buyerClass) }, { children: user.user_bio ||
                                                        "User bio goes here.." })), _jsx("div", __assign({ className: "usersummarycard-ratingaverage" }, { children: _jsx(KStars, { value: loadedUserRatingAverage || 0, fix: "fixed", fontSize: "16px" }) }))] })), _jsxs("div", __assign({ className: "usersummarycard-info-section", style: {
                                                display: isUserLawyer(user.user_title_name || "")
                                                    ? "none"
                                                    : "flex",
                                            } }, { children: [_jsxs("div", __assign({ className: "usersummarycard-networking-section ".concat(buyerClass) }, { children: [_jsx("img", { className: "usersummarycard-networking-icon", alt: "networking", src: "/images/scripto-types.png" }), isUserBuyer(loadedUser.user_title_name) ||
                                                            isUserInvestor(loadedUser.user_title_name) ? (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "usersummarycard-info-title" }, { children: "Interested in" })), _jsx("div", __assign({ className: "usersummarycard-info-content" }, { children: (((_b = user
                                                                        .user_project_interests) === null || _b === void 0 ? void 0 : _b.length)
                                                                        ? user.user_project_interests
                                                                        : allProjectTypes || []).map(function (interest, key) {
                                                                        var _a;
                                                                        return (_jsx("div", __assign({ className: "usersummarycard-interest" }, { children: _jsx(KCheckbox, { title: interest.project_type_name +
                                                                                    "s", color: "var(--app-".concat(((_a = interest.project_type_name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                                                                                    "script"
                                                                                    ? "scriptwriter"
                                                                                    : "filmmaker", "-color)"), defaultChecked: true, round: true, disabled: true }) }), key));
                                                                    }) }))] })) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "usersummarycard-info-title" }, { children: "Networking with" })), _jsx("div", __assign({ className: "usersummarycard-info-content" }, { children: (((_c = user.user_interests) === null || _c === void 0 ? void 0 : _c.length)
                                                                        ? user.user_interests
                                                                        : allUserTitles || []).map(function (interest, key) {
                                                                        var _a;
                                                                        return (_jsx("div", __assign({ className: "usersummarycard-interest" }, { children: _jsx(KCheckbox, { title: interest.user_title_name +
                                                                                    "s", color: "var(--app-".concat((_a = interest.user_title_name) === null || _a === void 0 ? void 0 : _a.toLowerCase(), "-color)"), defaultChecked: true, round: true, disabled: true }) }), key));
                                                                    }) }))] }))] })), _jsxs("div", __assign({ className: "usersummarycard-stats-section" }, { children: [_jsxs("div", __assign({ className: "usersummarycard-stats-wrapper" }, { children: [_jsx("div", __assign({ className: "usersummarycard-stats-text" }, { children: (user.user_awards || [])
                                                                        .length == 1
                                                                        ? "awards"
                                                                        : "awards" })), _jsx("div", __assign({ className: "usersummarycard-stats-number" }, { children: (user.user_awards || [])
                                                                        .length }))] })), _jsxs("div", __assign({ className: "usersummarycard-stats-wrapper" }, { children: [_jsx("div", __assign({ className: "usersummarycard-stats-text" }, { children: projectCount == 1
                                                                        ? "project"
                                                                        : "projects" })), _jsx("div", __assign({ className: "usersummarycard-stats-number" }, { children: projectCount }))] }))] }))] }))] }))] }))] }))] }))) : (_jsx(_Fragment, {})) }));
};
var mapStateToProps = function (state) { return ({
    loadedUser: getLoadedUser(state),
    loadedProfileViews: getLoadedProfileViews(state),
    loadedUserRatingByUserId: getLoadedUserRatingByUserId(state),
    loadedUserRatingAverage: getLoadedUserRatingAverage(state),
    allUserTitles: getUserTitles(state),
    allProjectTypes: getProjectTypes(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onUploadVideo: function (file) { return dispatch(thunkUploadUserVideo(file)); },
    updateUserCover: function (file) { return dispatch(thunkUpdateUserCoverImage(file)); },
    addRatingByUser: function (userId, rating) {
        return dispatch(thunkAddRatingByUser(userId, rating));
    },
    onCreateCoverImages: function (coverImagesArray, callback) {
        return dispatch(thunkCreateUserCoverImages(coverImagesArray, callback));
    },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UserSummaryCard);
