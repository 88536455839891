var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from "react-router-dom";
import ProjectCard from "../ProjectCard/ProjectCard";
import KImage from "../../base-components/KImage/KImage";
import { getProjectSymbolSrc } from "../../tools/projectTools";
import { getTransparentImagePath } from "../../tools/generalTools";
import { collaborate } from "../../pages/ProjectPage/ProjectPage";
import "./FilmsList.css";
var FilmsList = function (_a) {
    var basePath = _a.basePath, currentUserId = _a.currentUserId, emptyTitle = _a.emptyTitle, loadedUser = _a.loadedUser, authUserId = _a.authUserId, _b = _a.projects, projects = _b === void 0 ? [] : _b;
    var history = useHistory();
    return (_jsxs("div", __assign({ className: "filmslist-container" }, { children: [_jsx("div", __assign({ className: "filmslist-data-container", style: { display: projects.length > 0 ? "block" : "none" } }, { children: projects.map(function (project, key) { return (_jsx("div", __assign({ className: "filmslist-projectcard" }, { children: _jsx(ProjectCard, { project: project, authUserId: currentUserId, href: "".concat(basePath, "/project/").concat(project.project_id), onViewClick: function () {
                            history.push("".concat(basePath, "/project/").concat(project.project_id));
                        }, onCollaborate: function () { return collaborate(project); }, isEditable: authUserId == project.project_user_id_fk }) }), key)); }) })), _jsxs("div", __assign({ className: "filmslist-empty-title", style: { display: projects.length == 0 ? "flex" : " none" } }, { children: [_jsx("div", __assign({ className: "scriptslist-empty-image" }, { children: _jsx(KImage, { smallImgSrc: getTransparentImagePath(), imgSrc: getProjectSymbolSrc("film") }) })), emptyTitle] }))] })));
};
export default FilmsList;
