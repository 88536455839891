var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import PropTypes from "prop-types";
import { FaSearch as IconAdd } from "react-icons/fa";
import KButton from "../../base-components/KButton/KButton";
import "./SearchNetworkForm.css";
var SearchNetworkForm = function (_a) {
    var _b = _a.placeholder, placeholder = _b === void 0 ? "Search" : _b, onClick = _a.onClick, onChange = _a.onChange;
    var _c = useState(""), searchText = _c[0], setSearchText = _c[1];
    var addCharToText = function (event) {
        if (event.keyCode === 13)
            onSubmit(); // 13: enter key code
    };
    var onSubmit = function () {
        // setSearchText("");
        onClick(searchText);
    };
    var onSearchChange = function (event) {
        setSearchText(event.target.value);
        onChange(event.target.value);
    };
    return (_jsxs("div", __assign({ className: "searchnetworkform-container" }, { children: [_jsx("div", __assign({ className: "searchnetworkform-text-container" }, { children: _jsx("textarea", { className: "searchnetworkform-textarea", maxLength: "100", value: searchText, onKeyUp: function (event) { return addCharToText(event); }, placeholder: placeholder, onKeyDown: function (event) {
                        if (event.keyCode == 13)
                            event.preventDefault();
                    }, onChange: onSearchChange, rows: "1" }) })), _jsx("div", __assign({ className: "searchnetworkform-button" }, { children: _jsx(KButton, { title: "Search", icon: IconAdd, variant: "primary", borderRadius: "var(--searchnetworkform-border-radius)", onClick: function (event) { return onSubmit(event); } }) }))] })));
};
SearchNetworkForm.propTypes = {
    onClick: PropTypes.func.isRequired,
};
SearchNetworkForm.defaultProps = {
    onClick: function () { return alert("Feature coming soon"); },
    onChange: function (f) { return f; },
};
export default SearchNetworkForm;
