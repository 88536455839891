var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SET_COLOR_MODE, SET_PRIMARY_COLOR, LOAD_USER_TITLES_SUCCESS, LOAD_USER_TITLES_IN_PROGRESS, LOAD_USER_TITLES_FAILURE, LOAD_PROJECT_GENRES_IN_PROGRESS, LOAD_PROJECT_GENRES_SUCCESS, LOAD_PROJECT_GENRES_FAILURE, LOAD_PROJECT_TYPES_SUCCESS, LOAD_PROJECT_TYPES_IN_PROGRESS, LOAD_PROJECT_TYPES_FAILURE, LOAD_PROJECT_SUBTYPES_SUCCESS, LOAD_PROJECT_SUBTYPES_IN_PROGRESS, LOAD_PROJECT_SUBTYPES_FAILURE, LOAD_PROJECT_KINDS_SUCCESS, LOAD_PROJECT_KINDS_IN_PROGRESS, LOAD_PROJECT_KINDS_FAILURE, LOAD_PROJECT_STAGES_SUCCESS, LOAD_PROJECT_STAGES_IN_PROGRESS, LOAD_PROJECT_STAGES_FAILURE, LOAD_LANGUAGES_SUCCESS, LOAD_LANGUAGES_IN_PROGRESS, LOAD_LANGUAGES_FAILURE, LOAD_COUNTRIES_SUCCESS, LOAD_COUNTRIES_IN_PROGRESS, LOAD_COUNTRIES_FAILURE, LOAD_CITIES_SUCCESS, LOAD_CITIES_IN_PROGRESS, LOAD_CITIES_FAILURE, STOP_GENERAL_LOADING, START_GENERAL_LOADING, SET_PROGRESS, SET_PROGRESS_STATUS, } from "../actions/generalActions";
var generalInitialState = {
    colorMode: "color",
    primaryColor: "unknown",
    userTitles: [],
    projectTypes: [],
    projectSubtypes: [],
    projectKinds: [],
    projectGenres: [],
    projectStages: [],
    languages: [],
    countries: [],
    cities: [],
    progress: 0,
    progressStatus: "",
    isLoading: false,
};
export var general = function (state, action) {
    if (state === void 0) { state = generalInitialState; }
    var type = action.type, payload = action.payload;
    switch (type) {
        case SET_COLOR_MODE: {
            var colorMode = payload.colorMode;
            return __assign(__assign({}, state), { colorMode: colorMode });
        }
        case SET_PRIMARY_COLOR: {
            var primaryColor = payload.primaryColor;
            return __assign(__assign({}, state), { primaryColor: primaryColor });
        }
        case LOAD_USER_TITLES_SUCCESS: {
            var userTitles = payload.userTitles;
            return __assign(__assign({}, state), { userTitles: userTitles, isLoading: false, errorMessage: "" });
        }
        case LOAD_USER_TITLES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_USER_TITLES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_PROJECT_TYPES_SUCCESS: {
            var projectTypes = payload.projectTypes;
            return __assign(__assign({}, state), { projectTypes: projectTypes, isLoading: false, errorMessage: "" });
        }
        case LOAD_PROJECT_TYPES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_PROJECT_TYPES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_PROJECT_SUBTYPES_SUCCESS: {
            var projectSubtypes = payload.projectSubtypes;
            return __assign(__assign({}, state), { projectSubtypes: projectSubtypes, isLoading: false, errorMessage: "" });
        }
        case LOAD_PROJECT_SUBTYPES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_PROJECT_SUBTYPES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_PROJECT_KINDS_SUCCESS: {
            var projectKinds = payload.projectKinds;
            return __assign(__assign({}, state), { projectKinds: projectKinds, isLoading: false, errorMessage: "" });
        }
        case LOAD_PROJECT_KINDS_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_PROJECT_KINDS_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_PROJECT_GENRES_SUCCESS: {
            var projectGenres = payload.projectGenres;
            return __assign(__assign({}, state), { projectGenres: projectGenres, isLoading: false, errorMessage: "" });
        }
        case LOAD_PROJECT_GENRES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_PROJECT_GENRES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_PROJECT_STAGES_SUCCESS: {
            var projectStages = payload.projectStages;
            return __assign(__assign({}, state), { projectStages: projectStages, isLoading: false, errorMessage: "" });
        }
        case LOAD_PROJECT_STAGES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_PROJECT_STAGES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_COUNTRIES_SUCCESS: {
            var countries = payload.countries;
            return __assign(__assign({}, state), { countries: countries, isLoading: false, errorMessage: "" });
        }
        case LOAD_COUNTRIES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_LANGUAGES_SUCCESS: {
            var languages = payload.languages;
            return __assign(__assign({}, state), { languages: languages, isLoading: false, errorMessage: "" });
        }
        case LOAD_LANGUAGES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_LANGUAGES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_COUNTRIES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case LOAD_CITIES_SUCCESS: {
            var cities = payload.cities;
            return __assign(__assign({}, state), { cities: cities, isLoading: false, errorMessage: "" });
        }
        case LOAD_CITIES_IN_PROGRESS: {
            return __assign(__assign({}, state), { isLoading: true, errorMessage: "" });
        }
        case LOAD_CITIES_FAILURE: {
            var message = payload.message;
            return __assign(__assign({}, state), { isLoading: false, errorMessage: message });
        }
        case STOP_GENERAL_LOADING: {
            return __assign(__assign({}, state), { isLoading: false });
        }
        case START_GENERAL_LOADING: {
            return __assign(__assign({}, state), { isLoading: true });
        }
        case SET_PROGRESS: {
            var progress = payload.progress;
            return __assign(__assign({}, state), { progress: progress });
        }
        case SET_PROGRESS_STATUS: {
            var progressStatus = payload.progressStatus;
            return __assign(__assign({}, state), { progressStatus: progressStatus });
        }
        default: {
            return state;
        }
    }
};
