import axios from "axios";
import store from "../redux/store";
import { getToken } from "../redux/selectors/authSelectors";
/**
 * This has been added to temporarily support free subscriptions without stripe.
 * This file will have to be removed along all of its related files once done with this temporary phase.
 */
var subscriptionApi = axios.create({
    baseURL: "".concat(process.env.SCRIPTO_API_URL, "/scripto/api/v0/subscription/"),
    responseType: "json",
});
subscriptionApi.defaults.timeout =
    parseInt(process.env.SCRIPTO_API_TIMEOUT) * 1000;
subscriptionApi.interceptors.request.use(function (config) {
    config.headers.Authorization =
        process.env.SCRIPTO_API_KEY + "~ATK~" + getToken(store.getState());
    return config;
});
export default subscriptionApi;
