import { useRef, useEffect } from "react";
var useDocumentTitle = function (title, prevailOnUnmount) {
    if (prevailOnUnmount === void 0) { prevailOnUnmount = false; }
    var defaultTitle = useRef(document.title);
    useEffect(function () {
        document.title = title;
    }, [title]);
    useEffect(function () { return function () {
        if (!prevailOnUnmount)
            document.title = defaultTitle.current;
    }; }, []);
};
export default useDocumentTitle;
