var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect, useRef } from "react";
import kachat from "kachat";
import { useLocation } from "react-router";
import { connect } from "react-redux";
import { IoIosGlobe as IconNetwork } from "react-icons/io";
import { IoIosAdd as IconAdd } from "react-icons/io";
import { IoIosRemove as IconRemove } from "react-icons/io";
import { thunkLoadUsers } from "../../redux/thunks/userThunks";
import SearchNetworkForm from "../../components/SearchNetworkForm/SearchNetworkForm";
import { loadLookingForUserSelectedSearchOptions, loadUserSelectedSearchOptions, setNetworkPath, } from "../../redux/actions/userActions";
import FilteringCard from "../../components/FilteringCard/FilteringCard";
import useInfiniteLoading, { infiniteKeys, infiniteLoadingReload, } from "../../hooks/useInfiniteLoading";
import { getUserId } from "../../redux/selectors/authSelectors";
import KSwitch from "../../base-components/KSwitch/KSwitch";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import UserCard from "../../components/UserCard/UserCard";
import { getLookingForSelectedSearchOptions, getNetworkPath, getSelectedSearchOptions, getUsers, } from "../../redux/selectors/userSelectors";
import { getCountries, getLanguages, getUserTitles, } from "../../redux/selectors/generalSelectors";
import ExtraInfo from "../ExtraInfo/ExtraInfo";
import { history } from "../../redux/store";
import "./UsersPage.css";
var UsersPage = function (_a) {
    var users = _a.users, currentUserId = _a.currentUserId, onLoadUsers = _a.onLoadUsers, userTitles = _a.userTitles, languages = _a.languages, countries = _a.countries, networkPath = _a.networkPath, selectedLookingForSearchOptions = _a.selectedLookingForSearchOptions, onLoadSelectedSearchOptions = _a.onLoadSelectedSearchOptions, onLoadLookingForSelectedSearchOptions = _a.onLoadLookingForSelectedSearchOptions, selectedSearchOptions = _a.selectedSearchOptions, onSetNetworkPath = _a.onSetNetworkPath;
    var limit = 20;
    var location = useLocation();
    var query = new URLSearchParams(location.search);
    var _b = useState(false), isReady = _b[0], setIsReady = _b[1];
    var _c = useState(false), showFilters = _c[0], setShowFilters = _c[1];
    var _d = useState(false), showFiltersButton = _d[0], setShowFiltersButton = _d[1];
    var _e = useState(""), lookingForTitle = _e[0], setLookingForTitle = _e[1];
    var lookingfor = query.get("lookingfor") && parseInt(query.get("lookingfor"))
        ? parseInt(query.get("lookingfor"))
        : null;
    var cowriters = query.get("cowriters")
        ? query.get("cowriters") === "true"
        : false;
    var emptySearchOptions = {
        userTitleIds: [],
        countryIds: [],
        languageIds: [],
        personalized: [false],
    };
    var loadInitialSearchOptions = function () {
        if (lookingfor) {
            if (Object.keys(selectedLookingForSearchOptions).length) {
                if (cowriters)
                    selectedLookingForSearchOptions.searchOptions.userTitleIds =
                        [1];
                return selectedLookingForSearchOptions.searchOptions;
            }
        }
        else if (Object.keys(selectedSearchOptions).length)
            return selectedSearchOptions.searchOptions;
        return emptySearchOptions;
    };
    var _f = useState(""), searchText = _f[0], _setSearchText = _f[1];
    var _g = useState([]), filteringData = _g[0], _setFilteringData = _g[1];
    var _h = useState(loadInitialSearchOptions()), searchOptions = _h[0], _setSearchOptions = _h[1];
    var searchTextRef = useRef(searchText);
    var searchOptionsRef = useRef(searchOptions);
    var filteringDataRef = useRef(filteringData);
    var setSearchText = function (data) {
        searchTextRef.current = data;
        _setSearchText(data);
    };
    var setSearchOptions = function (data) {
        searchOptionsRef.current = data;
        _setSearchOptions(data);
    };
    var setFilteringData = function (data) {
        filteringDataRef.current = data;
        _setFilteringData(data);
    };
    var handleResize = function () {
        if (window.innerWidth < 700) {
            setShowFiltersButton(true);
        }
        else {
            setShowFiltersButton(false);
        }
    };
    var onSearchOptionsChange = function (dataTitle, itemValue, itemTitle) {
        var _a, _b, _c;
        if (itemTitle === void 0) { itemTitle = ""; }
        var dataToSet;
        if (searchOptions[dataTitle].includes(itemValue)) {
            dataToSet = searchOptions[dataTitle].filter(function (entry) { return entry !== itemValue; });
        }
        else {
            dataToSet = __spreadArray(__spreadArray([], searchOptions[dataTitle], true), [itemValue], false);
        }
        var filteredData = filteringData.map(function (item) {
            if (item.data.searchOption === dataTitle) {
                item.data.content.map(function (entry) {
                    if (entry[itemTitle] === itemValue) {
                        entry.checked = !entry.checked;
                        return entry;
                    }
                    return entry;
                });
            }
            return item;
        });
        setFilteringData(filteredData);
        setSearchOptions(__assign(__assign({}, searchOptions), (_a = {}, _a[dataTitle] = dataToSet, _a)));
        if (lookingfor)
            onLoadLookingForSelectedSearchOptions(__assign(__assign({}, searchOptions), (_b = {}, _b[dataTitle] = dataToSet, _b)));
        else
            onLoadSelectedSearchOptions(__assign(__assign({}, searchOptions), (_c = {}, _c[dataTitle] = dataToSet, _c)));
    };
    var onClearSectionItems = function (dataTitle) {
        var _a, _b;
        var clearedSection = filteringData.map(function (item) {
            if (item.data.searchOption === dataTitle) {
                item.isOpen = false;
                item.data.content.map(function (entry) { return (entry.checked = false); });
                return item;
            }
            return item;
        });
        setFilteringData(clearedSection);
        onLoadSelectedSearchOptions(__assign(__assign({}, searchOptions), (_a = {}, _a[dataTitle] = [], _a)));
        setSearchOptions(__assign(__assign({}, searchOptions), (_b = {}, _b[dataTitle] = [], _b)));
    };
    var onApply = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    window.scrollTo(0, 0);
                    setLastTriggerOffset(0);
                    return [4 /*yield*/, onLoadUsers(searchText, limit, 0, true, searchOptions, lookingfor)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var markCheckedValues = function (objArr, searchOption, itemValueTitle) {
        for (var item in objArr) {
            if (searchOptions[searchOption].includes(objArr[item][itemValueTitle])) {
                objArr[item].checked = true;
            }
            else {
                objArr[item].checked = false;
            }
        }
        return objArr;
    };
    var updateFilteringData = function () {
        var initialData = [
            // ...filteringData,
            {
                isOpen: searchOptions.userTitleIds.length,
                title: "Title",
                data: {
                    searchOption: "userTitleIds",
                    content: markCheckedValues(userTitles.sort(function (a, b) {
                        return a.user_title_id < b.user_title_id ? -1 : 1;
                    }), "userTitleIds", "user_title_id"),
                    itemTitle: "user_title_name",
                    itemValueTitle: "user_title_id",
                },
            },
            {
                isOpen: searchOptions.languageIds.length &&
                    !searchOptions.userTitleIds.length,
                title: "Language",
                data: {
                    searchOption: "languageIds",
                    content: markCheckedValues(languages, "languageIds", "language_id"),
                    itemTitle: "language_name",
                    itemValueTitle: "language_id",
                },
            },
            {
                isOpen: searchOptions.countryIds.length &&
                    !searchOptions.languageIds.length &&
                    !searchOptions.userTitleIds.length,
                title: "Country",
                data: {
                    searchOption: "countryIds",
                    content: markCheckedValues(countries, "countryIds", "country_id"),
                    itemTitle: "country_name",
                    itemValueTitle: "country_id",
                },
            },
        ];
        setFilteringData(initialData);
    };
    useEffect(function () {
        updateFilteringData();
    }, [searchOptions]);
    useDocumentTitle("Scriptofilm | Network");
    var _j = useState(0), lastTriggerOffset = _j[0], _setLastTriggerOffset = _j[1];
    var lastTriggerOffsetRef = React.useRef(lastTriggerOffset);
    var setLastTriggerOffset = function (data) {
        lastTriggerOffsetRef.current = data;
        _setLastTriggerOffset(data);
    };
    var onClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    window.scrollTo(0, 0);
                    setLastTriggerOffset(0);
                    return [4 /*yield*/, onLoadUsers(searchText, limit, 0, true, searchOptions, lookingfor)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSearchChange = function (text) {
        setSearchText(text);
    };
    var getUsers = function () {
        return (users || []).filter(function (user) { return user.user_id !== currentUserId; });
    };
    var shouldDisplay = function (user) {
        // if (searchText) {
        //   const username = user.user_first_name + " " + user.user_last_name;
        //   if (username.toLowerCase().indexOf(searchText.toLowerCase()) == -1) {
        //     return false;
        //   }
        // }
        return true;
    };
    var getCount = function () {
        if (searchText) {
            var filteredUsers = getUsers().filter(function (user) {
                return shouldDisplay(user);
            });
            return filteredUsers.length;
        }
        return getUsers().length;
    };
    useEffect(function () {
        updateFilteringData();
    }, []);
    useEffect(function () {
        var isSubscribed = true;
        if (isSubscribed)
            setIsReady(true);
        window.addEventListener("resize", handleResize);
        handleResize();
        var fullPath = location.pathname + (location.search || "");
        if (networkPath !== fullPath) {
            infiniteLoadingReload(infiniteKeys.NETWORK);
            onSetNetworkPath(fullPath);
        }
        return function () {
            isSubscribed = false;
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    useEffect(function () {
        var _a;
        setLookingForTitle((_a = userTitles.find(function (t) { return t.user_title_id === lookingfor; })) === null || _a === void 0 ? void 0 : _a.user_title_name);
    }, [userTitles.length]);
    // infinite loading section
    var scrollRef = useRef(null);
    var onLoadMore = function (count, offset) {
        onLoadUsers(searchTextRef.current, count, offset, false, __assign({}, searchOptionsRef.current), lookingfor);
    };
    var onFirstLoad = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onLoadUsers(searchTextRef.current, limit, 0, true, __assign({}, searchOptionsRef.current), lookingfor)];
                case 1:
                    _a.sent();
                    setIsReady(true);
                    return [2 /*return*/];
            }
        });
    }); };
    useInfiniteLoading(infiniteKeys.NETWORK, scrollRef, users.length, onLoadMore, limit, onFirstLoad);
    return (_jsx("div", __assign({ className: "userspage-container", ref: scrollRef }, { children: isReady == true ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "userspage-title" }, { children: [_jsx("div", __assign({ className: "userspage-title-icon" }, { children: _jsx(IconNetwork, {}) })), _jsx("div", __assign({ className: "userspage-title-text" }, { children: lookingfor
                                ? cowriters
                                    ? "Connect with co-writers"
                                    : "Looking for ".concat(lookingForTitle, "s")
                                : "Connect with the global film industry network" }))] })), _jsx("div", __assign({ className: "userspage-searchform" }, { children: _jsx(SearchNetworkForm, { placeholder: "Search the industry network", onClick: onClick, onChange: onSearchChange }) })), showFiltersButton && (_jsxs("div", __assign({ className: "userspage-advanced-filters", onClick: function () { return setShowFilters(!showFilters); } }, { children: [_jsx("div", { children: showFilters ? _jsx(IconRemove, {}) : _jsx(IconAdd, {}) }), _jsx("div", { children: "Advanced filters" })] }))), _jsxs("div", __assign({ className: "userspage-sections" }, { children: [_jsx("div", __assign({ className: "userspage-main-section" }, { children: _jsxs("div", __assign({ className: "userspage-users-container" }, { children: [getUsers().map(function (user, key) { return (_jsx("div", __assign({ className: "userspage-usercard", style: {
                                            display: shouldDisplay(user)
                                                ? "block"
                                                : "none",
                                        } }, { children: _jsx(UserCard, { user: user, onCollaborate: function () {
                                                kachat.createNewChat(user.user_kachat_id);
                                                history.push("/platform/messaging");
                                            } }) }), key)); }), _jsx("div", __assign({ className: "userspage-empty-title", style: {
                                            display: getCount() == 0 ? "flex" : " none",
                                        } }, { children: "no users found" }))] })) })), _jsx("div", __assign({ className: "userspage-secondary-section", style: {
                                display: !showFiltersButton ||
                                    (showFiltersButton && showFilters)
                                    ? "block"
                                    : "none",
                            } }, { children: _jsx("div", __assign({ className: "userspage-secondary-section-first-part" }, { children: _jsxs("div", __assign({ className: "userspage-filtering-card", style: {
                                        display: !showFiltersButton ||
                                            (showFiltersButton && showFilters)
                                            ? "flex"
                                            : "none",
                                        flexDirection: !showFiltersButton ||
                                            (showFiltersButton && showFilters)
                                            ? "column"
                                            : null,
                                    } }, { children: [_jsxs("div", __assign({ className: "userspage-personalized" }, { children: [_jsx("p", __assign({ className: "userspage-personalized-text" }, { children: "Turning on the personalized option would enable the filtering of the data based on your profile settings." })), _jsxs("div", __assign({ className: "userspage-personalized-content" }, { children: [_jsx(KSwitch, { onChange: function () {
                                                                setSearchOptions(__assign(__assign({}, emptySearchOptions), { personalized: [
                                                                        !searchOptions
                                                                            .personalized[0],
                                                                    ] }));
                                                                if (lookingfor)
                                                                    onLoadLookingForSelectedSearchOptions(__assign(__assign({}, emptySearchOptions), { personalized: [
                                                                            !searchOptions
                                                                                .personalized[0],
                                                                        ] }));
                                                                else
                                                                    onLoadSelectedSearchOptions(__assign(__assign({}, emptySearchOptions), { personalized: [
                                                                            !searchOptions
                                                                                .personalized[0],
                                                                        ] }));
                                                            }, checked: searchOptions
                                                                .personalized[0] }), _jsx("span", { children: "Enable personalized search" })] }))] })), _jsx(FilteringCard, { items: filteringData, onFilteringOptionsChange: onSearchOptionsChange, selectedItems: searchOptions, onClearSectionItems: onClearSectionItems, onApply: onApply }), _jsxs("div", __assign({ className: "userspage-extrainfo" }, { children: [_jsx(ExtraInfo, {}), " "] }))] })) })) }))] }))] })) : (_jsx(_Fragment, {})) })));
};
var mapStateToProps = function (state) { return ({
    userTitles: getUserTitles(state),
    languages: getLanguages(state),
    countries: getCountries(state),
    selectedSearchOptions: getSelectedSearchOptions(state),
    selectedLookingForSearchOptions: getLookingForSelectedSearchOptions(state),
    users: getUsers(state),
    currentUserId: getUserId(state),
    networkPath: getNetworkPath(state),
}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLoadUsers: function (text, limit, offset, reload, appliedSearchOption, lookingfor) {
        return dispatch(thunkLoadUsers(text, limit, offset, reload, appliedSearchOption, lookingfor));
    },
    onLoadSelectedSearchOptions: function (selectedSearchOptions) {
        return dispatch(loadUserSelectedSearchOptions(selectedSearchOptions));
    },
    onLoadLookingForSelectedSearchOptions: function (selectedSearchOptions) {
        return dispatch(loadLookingForUserSelectedSearchOptions(selectedSearchOptions));
    },
    onSetNetworkPath: function (path) { return dispatch(setNetworkPath(path)); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
