var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useHistory } from "react-router-dom";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import "../join-now/join-now.css";
export function JoinNow() {
    var history = useHistory();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "join-now__section section" }, { children: [_jsxs("div", __assign({ className: "join-now__sections" }, { children: [_jsxs("div", { children: [_jsx("img", { src: "/images/Scriptofilm_icon_colored_scriptwriter.png" }), _jsx("p", { children: "Scriptwriters" })] }), _jsxs("div", { children: [_jsx("img", { src: "/images/Scriptofilm_icon_colored_filmmaker.png" }), _jsx("p", { children: "Filmmakers" })] }), _jsxs("div", { children: [_jsx("img", { src: "/images/Scriptofilm_icon_colored_producer.png" }), _jsx("p", { children: "Producers" })] }), _jsxs("div", { children: [_jsx("img", { src: "/images/Scriptofilm_icon_colored_investor.png" }), _jsx("p", { children: "Investors" })] }), _jsxs("div", { children: [_jsx("img", { src: "/images/Scriptofilm_icon_colored_buyer.png" }), _jsx("p", { children: "Buyers" })] }), _jsxs("div", { children: [_jsx("img", { src: "/images/Scriptofilm_icon_colored_community.png" }), _jsx("p", { children: "Lawyers" })] })] })), _jsxs(Box, __assign({ display: "flex", alignItems: "center", className: "join-now__action-section cursor" }, { children: [_jsx(Icon, { component: ArrowForward, color: "inherit", fontSize: "large" }), _jsx("a", __assign({ onClick: function () { return history.push("/signin"); } }, { children: _jsx("span", __assign({ className: "join-now__action-title" }, { children: "Join now" })) }))] }))] })) }));
}
export default JoinNow;
