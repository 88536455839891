import bigInt from "big-integer";
import store from "../redux/store";
import { getFlaggedNum } from "../redux/selectors/authSelectors";
export var hasPermission = function (permission) {
    try {
        var flaggedNum = getFlaggedNum(store.getState());
        if (!permission)
            return false;
        var bitVal = bigInt(flaggedNum).toString(2);
        if (permission - 1 > bitVal.length)
            return false;
        var reversedBits = bitVal.split("").reverse().join("");
        return reversedBits[permission - 1] == "1";
    }
    catch (err) {
        console.error(err);
        return false;
    }
};
