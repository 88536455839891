var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { parse, stringify } from "flatted";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, createTransform } from "redux-persist";
import { projects } from "./reducers/projectReducers";
import { general } from "./reducers/generalReducers";
import { email } from "./reducers/emailReducers";
import { users } from "./reducers/userReducers";
import { user } from "./reducers/authReducers";
import { deals, ndas } from "./reducers/dealReducers";
import { notification } from "./reducers/notificationReducers";
import { contract } from "./reducers/contractReducers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
export var history = createBrowserHistory();
export var transformCircular = createTransform(function (inboundState, key) { return stringify(inboundState); }, function (outboundState, key) { return parse(outboundState); });
var persistConfig = {
    key: "root",
    storage: storage,
    stateReconciler: autoMergeLevel2,
    transforms: [transformCircular],
};
var reducers = {
    general: general,
    projects: projects,
    users: users,
    user: user,
    deals: deals,
    ndas: ndas,
    email: email,
    notification: notification,
    contract: contract,
};
var rootReducer = function (history) {
    return combineReducers(__assign({ router: connectRouter(history) }, reducers));
};
var persistedReducer = function (history) {
    return persistReducer(persistConfig, rootReducer(history));
};
var checkIfAuthenticated = function (_a) {
    var getState = _a.getState, dispatch = _a.dispatch;
    return function (next) {
        return function (action) {
            // @TODO: add thunk middlewares as required
            // console.log(action.type);
            next(action);
        };
    };
};
export var configureStore = function (preloadedState) {
    var store = createStore(persistedReducer(history), // root reducer with router state
    preloadedState, composeWithDevTools(applyMiddleware(routerMiddleware(history), // for dispatching history actions
    thunk
    // ... other middlewares ...
    )));
    return store;
};
var store = configureStore();
export default store;
