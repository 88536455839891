var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useRef } from "react";
import { connect } from "react-redux";
import KImage from "../../base-components/KImage/KImage";
import { getUserProfilePic } from "../../tools/userTools";
import useOutsideAlert from "../../hooks/useOutsideAlert";
import { thunkLogout } from "../../redux/thunks/authThunks";
import { history } from "../../redux/store";
import "./ProfileOptions.css";
import { canCreateProjects, hasAdminAccess, hasPaymentMethods, } from "../../validators/profileValidator";
import KToggle from "../../base-components/KToggle/KToggle";
var ProfileOptions = function (_a) {
    var user = _a.user, colorMode = _a.colorMode, onColorModeChange = _a.onColorModeChange, onLogout = _a.onLogout, isPaymentNeeded = _a.isPaymentNeeded;
    var profileOptionsImg = useRef(null);
    var profileOptionsRef = useRef(null);
    var _b = useState(false), settingsDropdownOpen = _b[0], setSettingsDropdownOpen = _b[1];
    var goTo = function (path, state) {
        if (state === void 0) { state = null; }
        setSettingsDropdownOpen(function (dd) { return !dd; });
        state ? history.push({ pathname: path, state: state }) : history.push(path);
    };
    useOutsideAlert(profileOptionsRef, function () { return setSettingsDropdownOpen(false); });
    if (!(user === null || user === void 0 ? void 0 : user.user_id)) {
        return _jsx(_Fragment, {});
    }
    return ((user === null || user === void 0 ? void 0 : user.user_id) && (_jsxs("div", __assign({ className: "profileoptions-settings-dropdown", ref: profileOptionsRef }, { children: [_jsxs("div", __assign({ style: {
                    display: settingsDropdownOpen ? "block" : "none",
                }, className: "profileoptions-settings-container" }, { children: [_jsx("div", __assign({ className: "profileoptions-settings-section" }, { children: _jsx("div", __assign({ className: "profileoptions-settings-section-rounded" }, { children: _jsxs("div", __assign({ className: "profileoptions-userimg-container" }, { children: [_jsx("div", __assign({ className: "profileoptions-userimg" }, { children: _jsx(KImage, { rounded: true, smallImgSrc: getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_small_image_path), imgSrc: getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_image_path), alt: "profile-pic" }) })), _jsxs("div", __assign({ onClick: function () { return goTo("/platform/profile"); } }, { children: [_jsxs("p", __assign({ className: "profileoptions-user-fullname" }, { children: [user === null || user === void 0 ? void 0 : user.user_first_name, " ", user === null || user === void 0 ? void 0 : user.user_last_name] })), _jsx("p", __assign({ className: "profileoptions-view-profile-text" }, { children: "View your profile" }))] }))] })) })) })), _jsxs("div", __assign({ className: "profileoptions-settings-section" }, { children: [hasAdminAccess() && (_jsx("p", __assign({ className: "profileoptions-settings-link", onClick: function () { return goTo("/platform/admin"); } }, { children: "Admin panel" }))), _jsx("p", __assign({ className: "profileoptions-settings-link", onClick: function () {
                                    return goTo("/platform/profile", {
                                        editProfile: true,
                                    });
                                } }, { children: "Edit your profile" })), isPaymentNeeded && hasPaymentMethods() && (_jsx("p", __assign({ className: "profileoptions-settings-link", onClick: function () { return goTo("/platform/paymentMethod"); } }, { children: "Payment methods" }))), canCreateProjects() && (_jsx("p", __assign({ className: "profileoptions-settings-link", onClick: function () { return goTo("/platform/accessRequest"); } }, { children: "Project access" }))), _jsx("p", __assign({ className: "profileoptions-settings-link", onClick: function () {
                                    goTo("".concat(window.location.pathname, "?refer=true"));
                                } }, { children: "Refer friends" })), _jsx("p", __assign({ className: "profileoptions-settings-link", onClick: function () { return goTo("/contactus"); } }, { children: "Contact us" }))] })), _jsx("div", __assign({ className: "profileoptions-settings-section" }, { children: _jsx("div", __assign({ className: "profileoptions-settings-section-rounded-x", style: { padding: "3px 10px" } }, { children: _jsxs("div", __assign({ className: "profileoptions-mode-switch-container" }, { children: [_jsx("span", __assign({ style: {
                                            color: "var(--app-font-color-light)",
                                            fontSize: "var(--app-font-size-large-1)",
                                            marginLeft: "6px",
                                        } }, { children: "Display" })), _jsx(KToggle, { initialLabel: colorMode, 
                                        // labels={["color", "dark", "light"]}
                                        labels: ["color", "dark"], onChange: function (val) {
                                            onColorModeChange(val);
                                        } })] })) })) })), _jsx("div", __assign({ className: "profileoptions-settings-section", style: {
                            borderBottom: 0,
                            paddingBottom: "3px",
                        } }, { children: _jsx("p", __assign({ className: "profileoptions-settings-link", onClick: function () {
                                setSettingsDropdownOpen(function (dd) { return !dd; });
                                onLogout();
                            } }, { children: "Log out" })) })), _jsxs("p", __assign({ className: "profileoptions-settings-footer" }, { children: ["Scriptofilm @", new Date().getFullYear(), ". All rights reserved"] }))] })), _jsx("div", __assign({ ref: profileOptionsImg, style: { width: "100%", height: "100%", cursor: "pointer" }, onClick: function () { return setSettingsDropdownOpen(function (dd) { return !dd; }); } }, { children: _jsx(KImage, { rounded: true, width: "36px", height: "36px", smallImgSrc: getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_small_image_path), imgSrc: getUserProfilePic(user === null || user === void 0 ? void 0 : user.user_image_path), alt: "profile-pic" }) }))] }))));
};
var mapStateToProps = function (state) { return ({}); };
var mapDispatchToProps = function (dispatch) { return ({
    onLogout: function () { return dispatch(thunkLogout()); },
}); };
export default connect(mapStateToProps, mapDispatchToProps)(ProfileOptions);
