var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import KImage from "../../base-components/KImage/KImage";
import KClampBox from "../../base-components/KClampBox/KClampBox";
import { AiFillLeftCircle as IconLeft } from "react-icons/ai";
import { AiFillRightCircle as IconRight } from "react-icons/ai";
import { getImageUrl } from "../../tools/generalTools";
import "./SingleImageCard.css";
var SingleImageCard = function (_a) {
    var title = _a.title, _b = _a.pathKey, pathKey = _b === void 0 ? "" : _b, _c = _a.smallPathKey, smallPathKey = _c === void 0 ? "" : _c, _d = _a.titlePathKey, titlePathKey = _d === void 0 ? "" : _d, _e = _a.images, images = _e === void 0 ? [] : _e;
    var _f = useState(1), totalPages = _f[0], setTotalPages = _f[1];
    var _g = useState(1), currentPage = _g[0], setCurrentPage = _g[1];
    var _h = useState([]), imagesArray = _h[0], setImagesArray = _h[1];
    var setPage = function (page) {
        setImagesArray(images.slice(page - 1, page));
        setCurrentPage(page);
    };
    var nextPage = function () {
        setPage(currentPage == totalPages ? 1 : currentPage + 1);
    };
    var prevPage = function () {
        setPage(currentPage == 1 ? totalPages : currentPage - 1);
    };
    useEffect(function () {
        setTotalPages(Math.ceil(images.length));
        setPage(1);
    }, [images.length]);
    var getSmallImageSrc = function (image) {
        return getImageUrl(image ? image[smallPathKey] : "");
    };
    var getImageSrc = function (image) {
        return getImageUrl(image ? image[pathKey] : "");
    };
    return (_jsx("div", __assign({ className: "singleimagecard-container" }, { children: totalPages ? (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "singleimagecard-header" }, { children: ["_", title] })), _jsx("div", __assign({ className: "singleimagecard-images-container" }, { children: _jsx("div", __assign({ className: "singleimagecard-section-1" }, { children: _jsx("div", __assign({ className: "singleimagecard-section-1-1" }, { children: _jsx(KImage, { backgroundColor: "var(--app-primary-background-color)", smallImgSrc: getSmallImageSrc(imagesArray[0]), imgSrc: getImageSrc(imagesArray[0]) }) })) })) })), _jsxs("div", __assign({ className: "singleimagecard-info-section" }, { children: [_jsx("div", __assign({ className: "singleimagecard-info-text-section" }, { children: _jsx(KClampBox, { lineClamp: "2", text: images[currentPage - 1]
                                    ? images[currentPage - 1][titlePathKey] || " "
                                    : " " }) })), _jsxs("div", __assign({ className: "singleimagecard-actions-section", style: {
                                display: totalPages > 1 ? "block" : "none",
                            } }, { children: [_jsxs("div", __assign({ className: "singleimagecard-actions-text" }, { children: [currentPage, " / ", totalPages] })), _jsxs("div", __assign({ className: "singleimagecard-actions-container" }, { children: [_jsx(IconLeft, { className: "singleimagecard-actions-icon", onClick: prevPage }), _jsx(IconRight, { className: "singleimagecard-actions-icon", onClick: nextPage })] }))] }))] }))] })) : (_jsx(_Fragment, {})) })));
};
SingleImageCard.propTypes = {};
SingleImageCard.defaultProps = {};
export default SingleImageCard;
